import { getI18n } from 'react-i18next'
import { string, number, object } from 'yup'

const addressRegex = /^0x[a-fA-F0-9]{40}$/

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    name: string().required(t('validation.Required to fill out')),
    address: string()
      .required(t('validation.Required to fill out'))
      .matches(addressRegex, t('validation.Invalid wallet address')),
    description: string().required(t('validation.Required to fill out')),
    network: string().required(t('validation.Required to fill out')),
    category: string().required(t('validation.Required to fill out')),
    amountJUNA: number()
      .required(t('validation.Required to fill out'))
      .positive(t('validation.Number cannot be negative')),
    country: string().required(t('validation.Required to fill out')),
  })
}
