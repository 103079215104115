import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={24} cy={24} fill="#F3F3F8" r={24} />
    <mask
      height={24}
      id="mask0_2150_76304"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={12}
      y={12}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={12} y={12} />
    </mask>
    <g mask="url(#mask0_2150_76304)">
      <path
        d="M24 23.7002C23.0333 23.7002 22.2083 23.3585 21.525 22.6752C20.8417 21.9919 20.5 21.1669 20.5 20.2002C20.5 19.2335 20.8417 18.4085 21.525 17.7252C22.2083 17.0419 23.0333 16.7002 24 16.7002C24.9667 16.7002 25.7917 17.0419 26.475 17.7252C27.1583 18.4085 27.5 19.2335 27.5 20.2002C27.5 21.1669 27.1583 21.9919 26.475 22.6752C25.7917 23.3585 24.9667 23.7002 24 23.7002ZM18.025 31.3002C17.5917 31.3002 17.2293 31.1545 16.938 30.8632C16.646 30.5712 16.5 30.2169 16.5 29.8002V29.0752C16.5 28.5919 16.6333 28.1419 16.9 27.7252C17.1667 27.3085 17.525 26.9835 17.975 26.7502C18.9583 26.2669 19.9543 25.9042 20.963 25.6622C21.971 25.4209 22.9833 25.3002 24 25.3002C25.0167 25.3002 26.0293 25.4209 27.038 25.6622C28.046 25.9042 29.0417 26.2669 30.025 26.7502C30.475 26.9835 30.8333 27.3085 31.1 27.7252C31.3667 28.1419 31.5 28.5919 31.5 29.0752V29.8002C31.5 30.2169 31.354 30.5712 31.062 30.8632C30.7707 31.1545 30.4083 31.3002 29.975 31.3002H18.025Z"
        fill="#8388AA"
      />
    </g>
  </svg>
)
const Memo = memo(SvgUser)
export default Memo
