import styled from 'styled-components'
import { BaseInput } from 'ui/BaseInput/BaseInput'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  width: 100%;
  border-radius: 0px 0px 6px 6px;
  position: absolute;
  top: 90%;
  left: 0;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.line};
  box-shadow: ${({ theme }) => theme.common.boxShadow};
`

export const Label = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
  padding: 6px;
`

export const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.color.white};
  outline: none;
  border: none;
  padding: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
  cursor: pointer;
  transition: color 0.3s;
  width: max-content;

  &:hover {
    color: ${({ theme }) => theme.color.mirageLight};
  }
`

export const Input = styled(BaseInput)`
  border-radius: 6px;
  border: none;
  box-shadow: none;
  outline: none;

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }

  input {
    color: ${({ error, theme }) =>
      error ? theme.color.red : theme.color.black};
    padding: 7px 0;
    outline: none;
    border: none;

    font-size: 12px;
    font-weight: 700;
    line-height: 12px;

    &::placeholder {
      color: ${({ theme }) => theme.color.mirageDark};
    }
  }
`
