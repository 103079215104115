import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      height={20}
      id="mask0_1425_15481"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={20}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={20} width={20} />
    </mask>
    <g mask="url(#mask0_1425_15481)">
      <path
        d="M5.83374 17.5C5.37541 17.5 4.98318 17.3369 4.65707 17.0108C4.33041 16.6842 4.16707 16.2917 4.16707 15.8333V5H3.33374V3.33333H7.50041V2.5H12.5004V3.33333H16.6671V5H15.8337V15.8333C15.8337 16.2917 15.6707 16.6842 15.3446 17.0108C15.0179 17.3369 14.6254 17.5 14.1671 17.5H5.83374ZM14.1671 5H5.83374V15.8333H14.1671V5ZM7.50041 14.1667H9.16707V6.66667H7.50041V14.1667ZM10.8337 14.1667H12.5004V6.66667H10.8337V14.1667Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgDelete)
export default Memo
