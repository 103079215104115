import { useDrop } from 'react-dnd'
import { AtomAction } from 'api/omni/model'
import { FCWithChildren } from 'types/app'
import * as S from './style'

type DropProps = {
  setBoardElement: (value: AtomAction) => void
}

export const Drop: FCWithChildren<DropProps> = (props) => {
  const { children, setBoardElement } = props

  const [{ isOver }, drop] = useDrop(
    {
      accept: 'box',
      drop: (atom: AtomAction) => setBoardElement(atom),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [setBoardElement]
  )

  return (
    <S.Wrapper ref={drop}>
      {children}
      {isOver && <S.HighlightLine />}
    </S.Wrapper>
  )
}
