import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgContract = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 14 16"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.5 0C1.86042 0 1.23066 0.235326 0.756661 0.679898C0.281189 1.12398 0 1.74081 0 2.4V13.6C0 14.2595 0.281463 14.8766 0.757356 15.3208C1.2297 15.7624 1.85849 16 2.5 16H11.5C12.1415 16 12.7702 15.7625 13.2425 15.3209C13.7185 14.8767 14 14.2596 14 13.6V6V5.9C14 5.63128 13.8919 5.37387 13.6999 5.18579L8.7007 0.286548C8.52027 0.109316 8.2729 0 8 0H2.5ZM7 2H2.5C2.34416 2 2.21049 2.05824 2.12463 2.13889L2.12205 2.14132L2.12204 2.14131C2.0343 2.22314 2 2.31779 2 2.4V13.6C2 13.6822 2.0343 13.7769 2.12204 13.8587L2.12325 13.8598C2.21083 13.9418 2.34578 14 2.5 14H11.5C11.6542 14 11.7892 13.9418 11.8768 13.8598L11.878 13.8587C11.9657 13.7769 12 13.6822 12 13.6V7H8C7.44772 7 7 6.55228 7 6V2ZM10.6529 5L9 3.38014V5H10.6529Z"
      fill="#8388AA"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgContract)
export default Memo
