import { getI18n } from 'react-i18next'
import { object, string, number } from 'yup'

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    category: string().required(t('validation.Required to fill out')),
    name: string().required(t('validation.Required to fill out')),
    description: string().required(t('validation.Required to fill out')),
    amountjuna: number()
      .required(t('validation.Required to fill out'))
      .positive(t('validation.Number cannot be negative')),
  })
}
