import { useMemo } from 'react'
import { selectAuthStatus } from 'store/auth/authorization.selectors'
import { setAuthStatus } from 'store/auth/authorization.slice'
import { AuthStatus } from 'types/app'
import { useDispatch } from '../useDispatch/useDispatch'
import { useSelector } from '../useSelector/useSelector'

type UseAuthStatus = () => {
  authStatus: AuthStatus
  setAuthStatus: (authStatus: AuthStatus) => void
}

export const useAuthStatus: UseAuthStatus = () => {
  const authStatus = useSelector(selectAuthStatus)

  const dispatch = useDispatch()

  return useMemo(
    () => ({
      authStatus,
      setAuthStatus: (authStatus: AuthStatus) => {
        dispatch(setAuthStatus(authStatus))
      },
    }),
    [authStatus, dispatch]
  )
}
