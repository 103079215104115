import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAtomAtomContractList } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { SvgBackArrrow } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Atom, TreeNavigator } from './components'
import * as S from './style'
import { ConstructedContractType } from './types'
import { checkIfValidUUID } from './utils/checkIfValidUUID'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Contract Constructor',
    url: AppRoutes.Constructor,
  },
  {
    label: 'Constructed Contract',
    url: '',
  },
]

export const ConstructedContract: FC = () => {
  const [currentContract, setCurrentContract] =
    useState<ConstructedContractType | null>(null)

  const { contractId } = useParams()

  const {
    data: contract,
    isLoading: isContractLoading,
    isSuccess: isContractLoaded,
  } = useAtomAtomContractList(
    {},
    {
      query: {
        enabled: Boolean(contractId),
        onSuccess: (contract: ConstructedContractType) => {
          setCurrentContract(contract)
        },
      },
    }
  )

  const handleReturnToParentContract = () => {
    if (!currentContract?.parentContract) {
      return
    }

    setCurrentContract(currentContract.parentContract)
  }

  const atomId = useMemo(() => {
    if (currentContract) {
      return Object.keys(currentContract).find((key) => checkIfValidUUID(key))
    }
  }, [currentContract])

  if (isContractLoading) {
    return <Loader />
  }

  if (isContractLoaded && currentContract && atomId) {
    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={currentContract.contractName}
      >
        <S.Wrapper>
          <TreeNavigator
            treeData={contract}
            updateContractNav={setCurrentContract}
          />
          <S.ContractWrapper>
            {currentContract.parentContract && (
              <S.ParentContractBlock onClick={handleReturnToParentContract}>
                <SvgBackArrrow />
                <S.Title>{currentContract.parentContract.contractName}</S.Title>
              </S.ParentContractBlock>
            )}
            <S.ContractInfoWrapper>
              <S.Title>{currentContract.contractName}</S.Title>
              <Atom
                atomId={atomId}
                currentContract={currentContract}
                updateCurrentContract={setCurrentContract}
              />
            </S.ContractInfoWrapper>
          </S.ContractWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
