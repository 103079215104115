import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Stepper } from 'ui/Stepper/Stepper'
import * as S from './style'

const steps = [
  {
    title: 'Accept',
    label: 'Разрешить списать комиссию за регистрацию',
    step: 1,
  },
  {
    title: 'Register',
    label: 'Зарегистрироваться как Legal oracle',
    step: 2,
  },
]

export const StatusBlock: FC = () => {
  const { t } = useTranslation('pageLegalGateAuth')

  return (
    <S.Card>
      <S.Title>{t('Status')}</S.Title>
      <Stepper activeStep={1} steps={steps} vertical />
    </S.Card>
  )
}
