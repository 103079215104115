import { FC, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccount, useNetwork, useSignTypedData } from 'wagmi'
import {
  useAtomAtomContractRetrieve,
  useAtomAtomContractAcceptCreate,
  useAtomAtomContractUpdate,
  useAtomCategoryList,
  useAtomAtomContractRejectCreate,
  useAtomAtomContractDeployCreate,
  useAtomAtomContractStatusUpdate,
} from 'api/omni/endpoints'
import {
  AtomMember,
  GetContractTree,
  SetStatusStatusEnum,
} from 'api/omni/model'
import { AppRoutes } from 'constants/app'

import { useClipboard, useTokenBalance } from 'hooks'
import {
  getForwarderAddress,
  getJunaTokenAddress,
} from 'libs/addresses/addresses'
import {
  ConstructorAtomTextarea,
  useConstructorContext,
} from 'libs/constructor'
import { ConstructorSelect } from 'libs/constructor/components/ConstructorSelect/ConstructorSelect'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { SvgBackArrrow, SvgCheckCircle, SvgTemplate } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { shortenAddress } from 'utils/shortenAddress'
import { toHumanNumber } from 'utils/toHumanNumber'
import { Atom, PublishSwitch, SignatureBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Contract Constructor',
    url: AppRoutes.Constructor,
  },
  {
    label: 'Constructed Contract',
    url: '',
  },
]

const statusesDictionary = {
  canceled: 'Rejected',
  pending: 'Pending',
  accepted: 'Accepted',
} as const

export const ConstructedContractV2: FC = () => {
  const { signTypedDataAsync } = useSignTypedData()

  const { chain } = useNetwork()

  const navigate = useNavigate()

  const { contractId } = useParams()

  const { address } = useAccount()

  const copyToClipboard = useClipboard()

  const {
    loadContracts,
    currentContract: contextCurrentContract,
    getContract,
    navigateToContract,
  } = useConstructorContext()

  const {
    isLoading: isContractLoading,
    isSuccess: isContractLoaded,
    refetch: refetchContractInfo,
    data: currentContract,
  } = useAtomAtomContractRetrieve(contractId!, {
    query: {
      enabled: Boolean(contractId),
      select: useCallback(
        (currentContract: GetContractTree) => {
          const contractData = currentContract.contracts[0]
          loadContracts({
            contracts: currentContract.contracts,
            currentContractId: contractId!,
          })
          return contractData
        },
        [contractId, loadContracts]
      ),
    },
  })

  const contractBalance = useTokenBalance(
    getJunaTokenAddress(chain!.id),
    currentContract?.address,
    chain?.id
  )

  const { data: categories, isSuccess: isCategoriesLoaded } =
    useAtomCategoryList()

  const { mutate: accept } = useAtomAtomContractAcceptCreate({
    mutation: {
      onSuccess: (data) => {
        //тут обработать успешную подпись
      },
    },
  })

  const { mutate: update } = useAtomAtomContractUpdate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success!',
          message: 'You have been successfully saved contract',
        })
      },
    },
  })

  const { mutate: reject } = useAtomAtomContractRejectCreate()

  const { mutate: deplpy } = useAtomAtomContractDeployCreate()

  const { mutate: updateStatus } = useAtomAtomContractStatusUpdate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success!',
          message: 'The contract status was successfully changed',
        })

        refetchContractInfo()
      },
    },
  })

  const handleCopyTemplate = useCallback(() => {
    navigate(`/contract-constructor-v2/${contractId}`)
  }, [navigate, contractId])

  const updateContractData = useMemo(() => {
    if (!contextCurrentContract.categoryId) {
      return
    }

    return {
      categoryId: contextCurrentContract.categoryId,
      description: contextCurrentContract.description,
      name: contextCurrentContract.name,
      isPublishedTemplate: contextCurrentContract.isPublishedTemplate,
    }
  }, [contextCurrentContract])

  const handleSaveTemplate = useCallback(() => {
    if (!updateContractData) {
      return
    }

    update({
      id: contextCurrentContract.id,
      data: updateContractData,
    })
  }, [contextCurrentContract.id, update, updateContractData])

  const handleAccept = async () => {
    if (!contractId || !chain) {
      return
    }

    const signed = await signTypedDataAsync({
      domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
      types: {
        Form: [],
      },
      value: {},
    })

    accept({
      id: contractId,
      data: {
        dataSig: signed,
        dataUnsig: {
          primaryType: 'Form',
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            Form: [],
          },
          message: {},
        },
      },
    })
  }

  const handleReject = useCallback(
    (comment: string) => {
      if (!contractId) {
        return
      }

      reject({
        id: contractId,
        data: {
          comment,
        },
      })

      refetchContractInfo()
    },
    [contractId, refetchContractInfo, reject]
  )

  const handleDeploy = useCallback(() => {
    if (!contractId) {
      return
    }

    deplpy({
      id: contractId,
    })

    refetchContractInfo()
  }, [contractId, deplpy, refetchContractInfo])

  const handleChangeStatusBtnClick = useCallback(
    (status: SetStatusStatusEnum) => {
      if (!contractId) {
        return
      }

      updateStatus({
        id: contractId,
        data: {
          status,
        },
      })
    },
    [contractId, updateStatus]
  )

  const isInitiator = useMemo<boolean>(() => {
    const { initiatorAddress } = currentContract || {}
    if (!initiatorAddress || !address) {
      return false
    }
    return initiatorAddress.toLowerCase() === address.toLowerCase()
  }, [address, currentContract])

  const statusButtons = useMemo(() => {
    if (!currentContract) {
      return
    }

    switch (true) {
      case isInitiator && currentContract.status === 'draft':
        return (
          <S.ActionButton
            onClick={() => handleChangeStatusBtnClick('on_approval')}
          >
            To approval
          </S.ActionButton>
        )
      case isInitiator && currentContract.status === 'on_approval':
        return (
          <S.ActionButton onClick={() => handleChangeStatusBtnClick('draft')}>
            To draft
          </S.ActionButton>
        )
    }
  }, [currentContract, handleChangeStatusBtnClick, isInitiator])

  const handleCopyContractAddress = () => {
    if (!currentContract?.address) {
      return
    }

    copyToClipboard(currentContract.address, () => {
      notify.success({
        title: 'Success',
        message: 'Contract address has been copied',
      })
    })
  }

  const parentContract = useMemo(() => {
    if (!contextCurrentContract) {
      return null
    }

    const { parentId } = contextCurrentContract

    if (parentId) {
      return getContract(parentId)
    }

    return null
  }, [contextCurrentContract, getContract])

  const handleReturnToParentContract = () => {
    const { parentId } = contextCurrentContract

    if (parentId) {
      navigateToContract({ contractId: parentId })
    }
  }

  if (isContractLoading) {
    return <Loader />
  }

  if (isContractLoaded && currentContract && contractId) {
    const contractMember = currentContract.members?.find(
      (member: AtomMember) => member.address === address
    )

    const isSignButtonsDisplayed =
      contractMember &&
      contractMember.status === 'pending' &&
      currentContract.status === 'on_approval'

    const isDeployButtonDisplayed =
      currentContract.status === 'on_approval' &&
      currentContract.members?.every(
        (member: AtomMember) => member.status === 'accepted'
      )

    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={contextCurrentContract.name}
      >
        {parentContract && (
          <S.ReturnCard onClick={handleReturnToParentContract}>
            <SvgBackArrrow />
            <S.Title>{parentContract.name}</S.Title>
          </S.ReturnCard>
        )}
        <S.Wrapper>
          <S.Content>
            <S.Title>{contextCurrentContract.name}</S.Title>

            {contextCurrentContract.atoms.map(
              ({ atomActionId, inputs, id }) => (
                <Atom
                  key={atomActionId}
                  atomActionId={atomActionId}
                  contractId={contractId}
                  id={id}
                  inputs={inputs}
                />
              )
            )}
            <S.ActionContainer>
              {contextCurrentContract.type === 'contract' && statusButtons}
              {contextCurrentContract.type === 'template' && (
                <>
                  <S.ActionButton onClick={handleCopyTemplate}>
                    <SvgTemplate />
                    Copy this template
                  </S.ActionButton>

                  {isInitiator && (
                    <S.ActionButton onClick={handleSaveTemplate}>
                      Save template
                    </S.ActionButton>
                  )}
                </>
              )}

              {isSignButtonsDisplayed && (
                <SignatureBlock
                  onAccept={handleAccept}
                  onReject={handleReject}
                />
              )}
              {isDeployButtonDisplayed && (
                <S.ActionButton onClick={handleDeploy}>
                  <SvgCheckCircle />
                  Deploy
                </S.ActionButton>
              )}
            </S.ActionContainer>
          </S.Content>
          <S.RightColumn>
            {contextCurrentContract.type === 'template' ? (
              <>
                <S.Label>
                  If you want to publish your template type description of your
                  template and select category
                </S.Label>
                <ConstructorAtomTextarea
                  disabled={!isInitiator}
                  name="description"
                  placeholder="Type any description..."
                />
                {isCategoriesLoaded && (
                  <ConstructorSelect
                    editorProps={{
                      options: categories,
                      getOptionValue: (category) => category.id!,
                      getOptionLabel: (category) => category.name,
                      value: categories.find(
                        (category) =>
                          category.id === contextCurrentContract.categoryId
                      ),
                      isDisabled: !isInitiator,
                    }}
                    name="categoryId"
                  />
                )}
                {isInitiator && (
                  <S.PublishWrapper>
                    <PublishSwitch />
                    <S.WideActionButton onClick={handleSaveTemplate}>
                      Save
                    </S.WideActionButton>
                  </S.PublishWrapper>
                )}
              </>
            ) : (
              <>
                {(contextCurrentContract.status === 'finished' ||
                  contextCurrentContract.status === 'execution') &&
                  contextCurrentContract.address && (
                    <S.RightColumnItem>
                      <S.Label>Contract address</S.Label>
                      <S.RightColumnAddressWrapper>
                        <S.RightColumnLabel>
                          {shortenAddress(contextCurrentContract.address)}
                        </S.RightColumnLabel>
                        <S.CopyIcon onClick={handleCopyContractAddress} />
                      </S.RightColumnAddressWrapper>
                    </S.RightColumnItem>
                  )}
                {contractBalance &&
                  (contextCurrentContract.status === 'finished' ||
                    contextCurrentContract.status === 'execution') && (
                    <>
                      <S.RightColumnItem>
                        <S.Label>Contract balance</S.Label>
                        <S.RightColumnLabel>
                          {toHumanNumber(contractBalance)} JUNA
                        </S.RightColumnLabel>
                      </S.RightColumnItem>
                      <S.RightColumnItem>
                        <S.Label>Dispute Status</S.Label>
                        <S.RightColumnLabel>
                          {(contextCurrentContract as any).executionStatus !==
                          'dispute'
                            ? 'Active'
                            : 'Dispute'}
                        </S.RightColumnLabel>
                      </S.RightColumnItem>
                    </>
                  )}

                {contextCurrentContract.members &&
                  contextCurrentContract.members.length > 0 && (
                    <S.RightColumnItem>
                      <S.Label>Members</S.Label>
                      {contextCurrentContract.members
                        .filter((member) => member.type === 'member')
                        .map((member) => (
                          <>
                            <S.RightColumnLabel>
                              {shortenAddress(member.address)}
                            </S.RightColumnLabel>
                            <S.StatusLabel
                              isRejected={member.status === 'canceled'}
                            >
                              {statusesDictionary[member.status]}{' '}
                              {member.status === 'canceled' && member.comment
                                ? '- '
                                : ''}
                              {member.comment}
                            </S.StatusLabel>
                          </>
                        ))}
                      <S.Label>Legal Gates</S.Label>
                      {contextCurrentContract.members
                        .filter((member) => member.type === 'legalgate')
                        .map((member) => (
                          <>
                            <S.RightColumnLabel>
                              {shortenAddress(member.address)}
                            </S.RightColumnLabel>
                            <S.StatusLabel
                              isRejected={member.status === 'canceled'}
                            >
                              {statusesDictionary[member.status]}{' '}
                              {member.status === 'canceled' && member.comment
                                ? '- '
                                : ''}
                              {member.comment}
                            </S.StatusLabel>
                          </>
                        ))}
                    </S.RightColumnItem>
                  )}
              </>
            )}
          </S.RightColumn>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
