import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgEthLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 16 24"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5347_11271)" id="Frame">
      <g id="Layer_x0020_1">
        <g id="_1421394342400">
          <g id="Group">
            <path
              d="M7.99781 0L7.83679 0.546928V16.4161L7.99781 16.5767L15.364 12.2225L7.99781 0Z"
              fill="#343434"
              id="Vector"
            />
            <path
              d="M7.9978 0L0.63147 12.2225L7.9978 16.5767V8.87428V0Z"
              fill="#8C8C8C"
              id="Vector_2"
            />
            <path
              d="M7.99785 17.9713L7.9071 18.082V23.7348L7.99785 23.9997L15.3685 13.6194L7.99785 17.9713Z"
              fill="#3C3C3B"
              id="Vector_3"
            />
            <path
              d="M7.9978 23.9997V17.9713L0.63147 13.6194L7.9978 23.9997Z"
              fill="#8C8C8C"
              id="Vector_4"
            />
            <path
              d="M7.9978 16.5767L15.3639 12.2225L7.9978 8.87427V16.5767Z"
              fill="#141414"
              id="Vector_5"
            />
            <path
              d="M0.63147 12.2225L7.9978 16.5767V8.87427L0.63147 12.2225Z"
              fill="#393939"
              id="Vector_6"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5347_11271">
        <rect
          fill="white"
          height={24}
          transform="translate(0.63147)"
          width={14.737}
        />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgEthLogo)
export default Memo
