import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
`

export const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const TextInputValue = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
