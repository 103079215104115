import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={22}
    viewBox="0 0 22 22"
    width={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M0 11.001C0 4.92569 4.92472 0.000976562 11 0.000976562C17.0753 0.000976562 22 4.92569 22 11.001C22 17.0763 17.0753 22.001 11 22.001C4.92472 22.001 0 17.0763 0 11.001ZM11 2.00098C6.02928 2.00098 2 6.03026 2 11.001C2 15.9717 6.02928 20.001 11 20.001C15.9707 20.001 20 15.9717 20 11.001C20 6.03026 15.9707 2.00098 11 2.00098ZM11 6.00098C11.5523 6.00098 12 6.44869 12 7.00098V10.001H15C15.5523 10.001 16 10.4487 16 11.001C16 11.5533 15.5523 12.001 15 12.001H12V15.001C12 15.5533 11.5523 16.001 11 16.001C10.4477 16.001 10 15.5533 10 15.001V12.001H7C6.44772 12.001 6 11.5533 6 11.001C6 10.4487 6.44772 10.001 7 10.001H10V7.00098C10 6.44869 10.4477 6.00098 11 6.00098Z"
      fill="#1B1F3B"
      fillOpacity={0.4}
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgPlus)
export default Memo
