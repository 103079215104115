import { useCallback } from 'react'
import { ContractFactory, ContractInterface } from 'ethers'
import { useSigner } from 'wagmi'
import {
  Contract as ContractType,
  ContractAbiItem,
  ContractData,
} from 'api/omni/model'

const getArrayByAbi = (abi: ContractAbiItem[], args: ContractData | any) => {
  const contructorAbi = abi.filter(({ type }) => type === 'constructor')

  return (contructorAbi[0].inputs as []).map((entry: any) => {
    switch (entry.type) {
      case 'address':
      case 'string':
        return args[entry.name].toString()
      default:
        return args[entry.name]
    }
  })
}

const argToConstructorArg = (args: ContractData | any, key: string) => {
  switch (key) {
    case 'members':
      return args[key].map((entry: any) => entry.address)
    default:
      return args[key]
  }
}

const argsToConstructorArgs = (args: ContractData | any) => {
  return Object.keys(args).reduce((acc: typeof args, key: string) => {
    acc[key] = argToConstructorArg(args, key)

    return acc
  }, {})
}

export const useDeploy = () => {
  const { data: signer } = useSigner()

  return useCallback(
    async (contract: ContractType, args: ContractData) => {
      const abi = contract.abi
      const contractFactory = new ContractFactory(
        abi as ContractInterface,
        contract.bytecode,
        signer!
      )

      return await contractFactory.deploy(
        ...getArrayByAbi(abi, argsToConstructorArgs(args))
      )
    },
    [signer]
  )
}
