import styled from 'styled-components'
import { SvgClose } from 'ui/icons'

export const WrapModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(55, 45, 76, 0.8);
`

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gallery};
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Close = styled(SvgClose)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.color.gray};
  }
`
