import React from 'react'
import { ToastOptions, toast as toastify } from 'react-toastify'
import { Toast, ToastProps } from 'ui/Toast/Toast'

type Notify = (params: {
  message: string
  title?: string
  options?: ToastOptions
}) => void

export const notify: Record<ToastProps['type'], Notify> = {
  error: ({ options, ...toastProps }) => {
    toastify(<Toast type="error" {...toastProps} />, options)
  },
  info: ({ options, ...toastProps }) => {
    toastify(<Toast type="info" {...toastProps} />, options)
  },
  success: ({ options, ...toastProps }) => {
    toastify(<Toast type="success" {...toastProps} />, options)
  },
}
