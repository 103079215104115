import { ApiOpenAIRoutes } from 'constants/api'
import { AXIOS_OPENAI_INSTANCE } from 'libs/axios-instance/axios-instance'

const TOKEN_AI = 'Bearer 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'

export const generateAiText = async (prompt: string) => {
  const { data } = await AXIOS_OPENAI_INSTANCE.post(
    ApiOpenAIRoutes.Completions,
    {
      model: 'text-davinci-003',
      prompt: prompt,
      max_tokens: 100,
      temperature: 0,
    },
    {
      headers: {
        Authorization: TOKEN_AI,
      },
    }
  )

  return data
}
