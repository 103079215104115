import { FC } from 'react'
import * as S from './style'

type BalanceBadgeProps = {
  balance?: string
}

export const BalanceBadge: FC<BalanceBadgeProps> = ({ balance }) => {
  return (
    <S.Container>
      {balance ? balance : 0} <S.JunaIcon />
    </S.Container>
  )
}
