import { combineReducers } from '@reduxjs/toolkit'
import { StoreNameSpace } from 'constants/app'
import { applicationSlice } from './application/application.slice'
import { authorizationSlice } from './auth/authorization.slice'
import { pinSlice } from './pin/pin.slice'
import { userSlice } from './user/user.slice'

export const rootReducer = combineReducers({
  [StoreNameSpace.Application]: applicationSlice.reducer,
  [StoreNameSpace.Authorization]: authorizationSlice.reducer,
  [StoreNameSpace.Pin]: pinSlice.reducer,
  [StoreNameSpace.User]: userSlice.reducer,
})
