import { createSlice } from '@reduxjs/toolkit'
import { Lg } from 'api/omni/model/lg'
import { StoreNameSpace } from 'constants/app'

type InitialState = {
  isProviderModalVisible: boolean
  isNetworkModalVisible: boolean
  isProfileModalVisible: boolean
  legalGate: Lg | null
  isLegalGate: boolean
}

const initialState: InitialState = {
  isProviderModalVisible: false,
  isProfileModalVisible: false,
  isNetworkModalVisible: false,
  legalGate: null,
  isLegalGate: false,
}

export const applicationSlice = createSlice({
  name: StoreNameSpace.Application,
  initialState,
  reducers: {
    toggleProviderModal: (state) => {
      state.isProviderModalVisible = !state.isProviderModalVisible
    },
    toggleNetworkModal: (state) => {
      state.isNetworkModalVisible = !state.isNetworkModalVisible
    },
    toggleProfileModal: (state) => {
      state.isProfileModalVisible = !state.isProfileModalVisible
    },
    setLegalGate: (state, { payload }) => {
      state.legalGate = payload
    },
    resetLegalGate: (state) => {
      state.legalGate = null
    },
    setIsLegalGate: (state, { payload }) => {
      state.isLegalGate = payload
    },
  },
})

export const {
  toggleProviderModal,
  toggleProfileModal,
  toggleNetworkModal,
  setLegalGate,
  resetLegalGate,
  setIsLegalGate,
} = applicationSlice.actions
