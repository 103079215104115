import { Navigate, useRoutes } from 'react-router-dom'
import { AppRoutes, SettingsPageInnerRoutes } from 'constants/app'
import { Layout } from 'features/Layout/Layout'
import { ProtectedRoute } from 'features/ProtectedRoute/ProtectedRoute'
import { useAuthStatus } from 'hooks'
import { Ad } from './Ad/Ad'
import { AddAds } from './AddAds/AddAds'
import { AddProject } from './AddProject/AddProject'
import { AddEdit } from './AdEdit/AdEdit'
import { Ads } from './Ads/Ads'
import { Auth } from './Auth/Auth'
import { BankDashboard } from './BankDashboard/BankDashboard'
import { BankDashboardDeposits } from './BankDashboardDeposits/BankDashboardDeposits'
import { BankDashboardLoans } from './BankDashboardLoans/BankDashboardLoans'
import { BankViewCreditContract } from './BankViewCreditContract/BankViewCreditContract'
import { BankViewDepositContract } from './BankViewDepositContract/BankViewDepositContract'
import { Catalog } from './Catalog/Catalog'
import { ConstructedContract } from './ConstructedContract/ConstructedContract'
import { ConstructedContractPageV2 } from './ConstructedContractV2'
import { Contract } from './Contract/Contract'
import { ContractConstructorPageV2 } from './ContractConstructorV2'
import { ContractCreate } from './ContractCreate/ContractCreate'
import { ContractProcess } from './ContractProcess/ContractProcess'
import { Contracts } from './Contracts/Contracts'
import { Credit } from './Credit/Credit'
import { CreditCreate } from './CreditCreate/CreditCreate'
import { CreditReserve } from './CreditReserve/CreditReserve'
import { Dashboard } from './Dashboard/Dashboard'
import { Deposit } from './Deposit/Deposit'
import { DepositCreate } from './DepositCreate/DepositCreate'
import { DepositReserve } from './DepositReserve/DepositReserve'
import { DocViewer } from './DocViewer/DocViewer'
import { Faucet } from './Faucet/Faucet'
import { Finance } from './Finance/Finance'
import { Home } from './Home/Home'
import { JunaSimpleV2 } from './JunaSimpleV2/JunaSimpleV2'
import { JunaSimpleV3 } from './JunaSimpleV3/JunaSimpleV3'
import { JunaSimpleV4 } from './JunaSimpleV4/JunaSimpleV4'
import { JunaSimpleV5 } from './JunaSimpleV5/JunaSimpleV5'
import { Launchpad } from './Launchpad/Launchpad'
import { LegalGate } from './LegalGate/LegalGate'
import { LegalGate2 } from './LegalGate2/LegalGate2'
import { LegalGateAuth } from './LegalGateAuth/LegalGateAuth'
import { LegalGateDashboard } from './LegalGateDashboard/LegalGateDashboard'
import { LegalGatesCatalog } from './LegalGatesCatalog/LegalGatesCatalog'
import { MyContacts } from './MyContacts/MyContacts'
import { MyLoans } from './MyLoans/MyLoans'
import { NotFound } from './NotFound/NotFound'
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy'
import { Project } from './Project/Project'
import { ReserveSystem } from './ReserveSystem/ReserveSystem'
import { MyProfile, Pin, PinApproveLanding } from './Settings/pages'
import { Settings } from './Settings/Settings'
import { Tender } from './Tender/Tender'
import { TransactionCreate } from './TransactionCreate/TransactionCreate'
import { Transfer } from './Transfer/Transfer'
import { UserDashboard } from './UserDashboard/UserDashboard'
import { Voting } from './Voting/Voting'
import { VotingCreate } from './VotingCreate/VotingCreate'
import { VotingResults } from './VotingResults/VotingResults'
import { GovernorVotings } from './Votings/GovernorVotings'

export const PagesConfig = () => {
  const { authStatus } = useAuthStatus()

  return useRoutes([
    {
      element: <Layout />,
      path: AppRoutes.Main,
      children: [
        {
          element: <NotFound />,
          path: '*',
        },
        {
          element: authStatus === 'authorized' ? <UserDashboard /> : <Home />,
          path: AppRoutes.Main,
        },
        {
          element: <UserDashboard />,
          path: AppRoutes.UserDashboard,
        },
        {
          element: <Catalog />,
          path: AppRoutes.Catalog,
        },
        {
          element: (
            <ProtectedRoute>
              <Contracts />
            </ProtectedRoute>
          ),
          path: AppRoutes.Contracts,
        },
        {
          element: (
            <ProtectedRoute>
              <ContractCreate />
            </ProtectedRoute>
          ),
          path: AppRoutes.ContractCreate,
        },
        {
          element: <Faucet />,
          path: AppRoutes.Faucet,
        },
        {
          element: <Transfer />,
          path: AppRoutes.Transfer,
        },
        {
          element: <ContractCreate />,
          path: `${AppRoutes.ContractCreate}/:createId`,
        },
        {
          element: <LegalGatesCatalog />,
          path: AppRoutes.LegalGatesCatalog,
        },
        {
          element: <LegalGate />,
          path: `${AppRoutes.LegalGatesCatalog}/:legalGateId`,
        },
        {
          element: <LegalGateAuth />,
          path: AppRoutes.LegalGateAuth,
        },
        {
          element: <Contract />,
          path: `${AppRoutes.Contracts}/:contractId`,
        },
        {
          element: <DocViewer />,
          path: `${AppRoutes.DocViewer}`,
        },
        {
          element: <ContractProcess />,
          path: `${AppRoutes.Contracts}/:contractId/process/:selectorFn`,
        },
        {
          element: <Auth />,
          path: AppRoutes.Auth,
        },
        {
          path: AppRoutes.Settings,
          element: (
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={`${AppRoutes.Settings}/${SettingsPageInnerRoutes.Profile}`}
                />
              ),
            },
            {
              path: SettingsPageInnerRoutes.Pin,
              element: <Pin />,
            },
            {
              path: `${SettingsPageInnerRoutes.PinApproveLanding}/:approveType/:session/:code`,
              element: <PinApproveLanding />,
            },
            {
              path: SettingsPageInnerRoutes.Profile,
              element: <MyProfile />,
            },
            {
              path: SettingsPageInnerRoutes.Contacts,
              element: <MyProfile />,
            },
          ],
        },
        {
          element: <MyContacts />,
          path: AppRoutes.MyContacts,
        },
        {
          element: <Launchpad />,
          path: AppRoutes.Launchpad,
        },
        {
          element: <MyLoans />,
          path: AppRoutes.MyLoans,
        },

        {
          element: <AddProject />,
          path: AppRoutes.ProjectAdd,
        },
        {
          element: <Project />,
          path: AppRoutes.Project,
        },

        {
          element: <Ads />,
          path: AppRoutes.Marketplace,
        },
        {
          element: <Ad />,
          path: `${AppRoutes.Marketplace}/:adId`,
        },
        {
          element: <AddEdit />,
          path: `${AppRoutes.Marketplace}/:adId/edit`,
        },
        {
          element: <AddAds />,
          path: AppRoutes.AdsAdd,
        },
        {
          element: <Tender />,
          path: AppRoutes.Tender,
        },
        {
          element: (
            <ProtectedRoute>
              <JunaSimpleV2 />
            </ProtectedRoute>
          ),
          path: AppRoutes.SimpleV2,
        },
        {
          element: (
            <ProtectedRoute>
              <JunaSimpleV3 />
            </ProtectedRoute>
          ),
          path: AppRoutes.Simple,
        },
        {
          element: (
            <ProtectedRoute roleGuard={['GOVERNOR']}>
              <GovernorVotings />
            </ProtectedRoute>
          ),
          path: AppRoutes.GovernorVoting,
        },
        {
          element: <VotingCreate />,
          path: AppRoutes.VotingAdd,
        },
        {
          element: <Voting />,
          path: `${AppRoutes.Voting}/:proposalId`,
        },
        {
          element: <VotingResults />,
          path: `${AppRoutes.VotingResults}`,
        },
        {
          element: <Dashboard />,
          path: AppRoutes.Dashboard,
        },
        {
          element: <TransactionCreate />,
          path: `${AppRoutes.Contracts}/:contractId/transaction-create`,
        },
        {
          element: (
            <ProtectedRoute>
              <ContractConstructorPageV2 />
            </ProtectedRoute>
          ),
          path: AppRoutes.Constructor,
        },
        {
          element: (
            <ProtectedRoute>
              <ContractConstructorPageV2 />
            </ProtectedRoute>
          ),
          path: '/contract-constructor-v2/:contractId',
        },
        {
          element: <ConstructedContract />,
          path: `${AppRoutes.ConstructedContract}/:contractId`,
        },
        {
          element: (
            <ProtectedRoute>
              <ConstructedContractPageV2 />
            </ProtectedRoute>
          ),
          path: `${AppRoutes.AtomContract}/:contractId`,
        },
        {
          element: <PrivacyPolicy />,
          path: AppRoutes.Privacy,
        },
        {
          element: <JunaSimpleV4 />,
          path: AppRoutes.SimpleV4,
        },
        {
          element: <JunaSimpleV5 />,
          path: AppRoutes.SimpleV5,
        },
        {
          element: <LegalGate2 />,
          path: `${AppRoutes.LegalGate}/:contractId`,
        },
        {
          element: <LegalGateDashboard />,
          path: AppRoutes.LegalGateDashboard,
        },
        {
          element: <Deposit />,
          path: `${AppRoutes.Deposit}/:depositId`,
        },
        {
          element: <Finance />,
          path: AppRoutes.Finance,
        },
        {
          element: <BankDashboard />,
          path: AppRoutes.BankDashboard,
        },
        {
          element: <DepositCreate />,
          path: AppRoutes.DepositCreate,
        },
        {
          element: <DepositReserve />,
          path: AppRoutes.DepositReserve,
        },
        {
          element: <CreditReserve />,
          path: AppRoutes.CreditReserve,
        },
        {
          element: <BankViewDepositContract />,
          path: `${AppRoutes.BankViewDepositContract}/:depositId`,
        },
        {
          element: <CreditCreate />,
          path: AppRoutes.CreditCreate,
        },
        {
          element: <Credit />,
          path: `${AppRoutes.Credit}/:creditId`,
        },
        {
          element: <BankViewCreditContract />,
          path: `${AppRoutes.BankViewCreditContract}/:creditId`,
        },
        {
          element: <ReserveSystem />,
          path: `${AppRoutes.ReserveSystem}/:bankId`,
        },
        {
          element: <BankDashboardLoans />,
          path: AppRoutes.BankDashboardLoans,
        },
        {
          element: <BankDashboardDeposits />,
          path: AppRoutes.BankDashboardDeposits,
        },
      ],
    },
  ])
}
