import { FC } from 'react'
import { SvgAddressAtomVariable } from 'ui/icons'
import * as S from './style'

type SelectedVariableBlockProps = {
  name: string
  value: string
  onRemove: () => void
}

export const SelectedVariableBlock: FC<SelectedVariableBlockProps> = (
  props
) => {
  const { name, value, onRemove } = props

  return (
    <S.Wrapper>
      <S.VariableBlock>
        <SvgAddressAtomVariable />
        {name}
      </S.VariableBlock>
      <S.ValueBlock>
        {value} <S.CloseIcon onClick={onRemove} />
      </S.ValueBlock>
    </S.Wrapper>
  )
}
