import { ReactElement } from 'react'
import { FCWithChildren } from 'types/app'
import Container from 'ui/Container/Container'
import { Breadcrumbs, BreadcrumbsProps, PageTitle } from './components'
import * as S from './style'

type PageWrapperProps = {
  breadcrumbs: BreadcrumbsProps['breadcrumbs']
  title: string | ReactElement
  actions?: ReactElement
}

const PageWrapper: FCWithChildren<PageWrapperProps> = (props) => {
  const { breadcrumbs, title, actions, children } = props

  return (
    <Container>
      <S.Wrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <S.FlexWrapper>
          {typeof title === 'string' ? <PageTitle>{title}</PageTitle> : title}
          {actions && <S.ActionsWrapper>{actions}</S.ActionsWrapper>}
        </S.FlexWrapper>
        {children}
      </S.Wrapper>
    </Container>
  )
}

export { PageWrapper, PageTitle }
