import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'
import { Status } from './types'

type StatusChipProps = {
  status: Status
}

export const StatusChip: FC<StatusChipProps> = (props) => {
  const { status } = props

  const { t } = useTranslation('pageConstructedContract')

  const statusLabelsDictionary = {
    active: t('ACTIVE'),
    pending: t('PENDING'),
  }

  return (
    <S.Wrapper>
      <S.Label status={status}>{statusLabelsDictionary[status]}</S.Label>
      <S.Circle status={status} />
    </S.Wrapper>
  )
}
