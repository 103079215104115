import React, { useCallback, useState } from 'react'
import { FCWithChildren } from 'types/app'

import * as S from './style'

type CollapsedBlockProps = {
  title: string
  rightElement?: JSX.Element
  onCollapse?: (isCollapsed: boolean) => void
  hint?: React.ReactElement
  hintId?: string
}

export const CollapsedBlock: FCWithChildren<CollapsedBlockProps> = (props) => {
  const { title, rightElement, onCollapse, children, hintId, hint } = props
  const [isCollapsed, setCollapsed] = useState(false)

  const handleClick = useCallback(() => {
    setCollapsed((prev) => !prev)
    onCollapse?.(!isCollapsed)
  }, [setCollapsed, onCollapse, isCollapsed])

  return (
    <S.Container>
      {hintId && <S.HintContainer id={hintId}>{hint}</S.HintContainer>}
      <S.Header
        data-tooltip-id={hintId}
        data-tooltip-place="left"
        onClick={handleClick}
      >
        <S.Title>
          {title}
          {!isCollapsed ? <S.ChevronDown /> : <S.ChevronUp />}
        </S.Title>
        {rightElement}
      </S.Header>
      {isCollapsed && children}
    </S.Container>
  )
}
