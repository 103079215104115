import {
  useCallback,
  ChangeEvent,
  useMemo,
  forwardRef,
  MouseEvent,
} from 'react'
import { useParams } from 'react-router-dom'
import {
  ConstructorInput,
  ConstructorInputProps,
} from 'libs/constructor/components'
import { FieldDataType } from '../../context/hooks/useConstructorState/types'
import { useConstructorContext } from '../../hooks/useConstructorContext/useConstructorContext'

export type ConstructorFieldProps = Omit<FieldDataType, 'value'> & {
  placeholder?: string
  editorProps?: Omit<
    ConstructorInputProps,
    'name' | 'onChange' | 'value' | 'placeholder'
  >
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  className?: string
  onClick?: (evt: MouseEvent<HTMLInputElement>) => void
  atomId?: string
}

export const ConstructorField = forwardRef<
  HTMLInputElement,
  ConstructorFieldProps
>((props, ref) => {
  const {
    name,
    placeholder,
    editorProps,
    onChange,
    className,
    onClick,
    atomId,
  } = props

  const { setFieldValue, getValue } = useConstructorContext()

  const { contractId } = useParams()

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setFieldValue({
        name,
        value: evt.target.value,
        atomId,
      })

      onChange?.(evt)
    },
    [name, setFieldValue, onChange, atomId]
  )

  const fieldValue = useMemo(() => {
    const value = getValue({
      name,
      atomId,
    })

    if (typeof value === 'string') {
      return value
    }

    return ''
  }, [atomId, getValue, name])

  return (
    <ConstructorInput
      ref={ref}
      className={className}
      isRequired={Boolean(contractId) && !Boolean(fieldValue)}
      name={name}
      onChange={handleChange}
      onClick={onClick}
      placeholder={placeholder}
      value={fieldValue}
      {...editorProps}
    />
  )
})
