import { FC } from 'react'
import { signTypedData } from '@wagmi/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useNetwork } from 'wagmi'
import {
  useProposalsProposalList,
  useProposalsVoteCreate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { getForwarderAddress } from 'libs/addresses/addresses'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { SvgAddIcon } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { VotingCard } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Voting Proposal for RS',
    url: AppRoutes.GovernorVoting,
  },
]

export const GovernorVotings: FC = () => {
  const { t } = useTranslation('pageProposals')

  const navigate = useNavigate()

  const { chain } = useNetwork()

  const {
    data: votings,
    isLoading: isVotingLoading,
    isSuccess: isVotingSuccess,
  } = useProposalsProposalList()

  const { mutate: addVote } = useProposalsVoteCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Vote added'),
          title: t('Vote added'),
        })
      },
    },
  })

  const handleVoteClick = async (id: string) => {
    if (!chain?.id) {
      return
    }

    const signed = await signTypedData({
      domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
      types: {
        Form: [],
      },
      value: {},
    })

    addVote({ data: { proposal: id, sig: signed } })
  }

  if (isVotingLoading) {
    return <Loader />
  }

  if (isVotingSuccess) {
    const finishedVotingsAmount = votings.results.filter(
      ({ status }) => status === 'finished'
    ).length

    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={t('Voting for changes in Reserve System')}
      >
        <S.Wrapper>
          <S.SuggestBlock>
            <S.SuggestLabel>{t('You can suggest a vote')}</S.SuggestLabel>
            <S.SuggestButton
              onClick={() => navigate(AppRoutes.VotingAdd)}
              variant="red"
            >
              {t('Creating a vote')} <SvgAddIcon />
            </S.SuggestButton>
            <S.VerticalLine />
            <S.SuggestButton
              onClick={() => navigate(AppRoutes.VotingResults)}
              variant="red"
            >
              {t('View results')}{' '}
              <S.SuggestButtonChip>{finishedVotingsAmount}</S.SuggestButtonChip>
            </S.SuggestButton>
          </S.SuggestBlock>

          <S.CardsWrapper>
            {votings.results.map((voting) => (
              <VotingCard
                key={voting.id}
                handleVoteClick={handleVoteClick}
                voting={voting}
              />
            ))}
          </S.CardsWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
