import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProposalStatusEnum } from 'api/omni/model'
import { Chip } from 'ui/Chip/Chip'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

const VOTES_GOAL = 800

type VotingHeaderProps = {
  name: string
  address: string
  status: ProposalStatusEnum
  votesCount: number
}

const StatusDictionary: Record<ProposalStatusEnum, string> = {
  in_progress: 'In progress',
  finished: 'Closed',
}

export const VotingHeader: FC<VotingHeaderProps> = (props) => {
  const { name, address, status, votesCount } = props

  const { t } = useTranslation('pageProposals')

  const votesProgress = useMemo(() => {
    return Math.floor((votesCount / VOTES_GOAL) * 100)
  }, [votesCount])

  return (
    <S.Wrapper>
      <S.InfoWrapper>
        <S.AuthorWrapper>
          <S.AuthorAvatarBlock>D</S.AuthorAvatarBlock>
          <S.AuthorInfoWrapper>
            <Chip variant="gray">{shortenAddress(address)}</Chip>
            <S.Text>{name}</S.Text>
          </S.AuthorInfoWrapper>
        </S.AuthorWrapper>
        <S.StatusWrapper>
          <S.Label>{t('Status')}</S.Label>
          <S.Text>{StatusDictionary[status]}</S.Text>
        </S.StatusWrapper>
      </S.InfoWrapper>
      <S.ProgressBlockWrapper>
        <S.ProgressTopWrappper>
          <S.ProgressVotesCountWrapper>
            <S.Text>{t('Votes')}</S.Text>
            <S.VotesChip isActive>{votesCount}</S.VotesChip>
          </S.ProgressVotesCountWrapper>
          <S.ProgressVotesCountWrapper>
            <S.Text>{t('Remained')}</S.Text>
            <S.VotesChip>{VOTES_GOAL - votesCount}</S.VotesChip>
          </S.ProgressVotesCountWrapper>
        </S.ProgressTopWrappper>
        <S.ProgressBar>
          <S.ProgressLine width={votesProgress} />
        </S.ProgressBar>
      </S.ProgressBlockWrapper>
    </S.Wrapper>
  )
}
