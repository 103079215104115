import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgOptimism = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2171_89191)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FF0420"
      />
      <path
        d="M8.50209 15.1893C7.78756 15.1893 7.20215 15.0212 6.74582 14.685C6.29548 14.3427 6.07031 13.8564 6.07031 13.2259C6.07031 13.0938 6.08534 12.9317 6.11534 12.7395C6.19343 12.3072 6.3045 11.7878 6.4486 11.1814C6.85689 9.53019 7.91068 8.70459 9.60993 8.70459C10.0723 8.70459 10.4866 8.78264 10.8528 8.93873C11.2191 9.08883 11.5073 9.31702 11.7175 9.62326C11.9276 9.92345 12.0327 10.2837 12.0327 10.704C12.0327 10.8301 12.0177 10.9892 11.9877 11.1814C11.8976 11.7158 11.7895 12.2352 11.6634 12.7395C11.4533 13.5621 11.09 14.1776 10.5736 14.5859C10.0572 14.9882 9.36676 15.1893 8.50209 15.1893ZM8.62818 13.8924C8.96447 13.8924 9.24964 13.7933 9.48383 13.5952C9.72402 13.397 9.89515 13.0938 9.99719 12.6855C10.1353 12.1211 10.2404 11.6287 10.3125 11.2084C10.3365 11.0823 10.3485 10.9532 10.3485 10.8211C10.3485 10.2747 10.0632 10.0015 9.49286 10.0015C9.15657 10.0015 8.86838 10.1006 8.62818 10.2987C8.39404 10.4969 8.2259 10.8001 8.12385 11.2084C8.01575 11.6107 7.90766 12.1031 7.79961 12.6855C7.77556 12.8056 7.76356 12.9317 7.76356 13.0638C7.76356 13.6162 8.0518 13.8924 8.62818 13.8924Z"
        fill="white"
      />
      <path
        d="M12.447 15.0996C12.381 15.0996 12.3299 15.0786 12.2939 15.0365C12.2638 14.9885 12.2549 14.9345 12.2669 14.8744L13.5098 9.02009C13.5218 8.95404 13.5548 8.89999 13.6088 8.85795C13.6629 8.81595 13.7199 8.79492 13.78 8.79492H16.1757C16.8422 8.79492 17.3766 8.93302 17.7789 9.20921C18.1872 9.48545 18.3914 9.88471 18.3914 10.4071C18.3914 10.5572 18.3734 10.7133 18.3373 10.8754C18.1872 11.566 17.884 12.0763 17.4277 12.4066C16.9773 12.7368 16.3589 12.9019 15.5723 12.9019H14.3564L13.9421 14.8744C13.9301 14.9405 13.897 14.9945 13.843 15.0365C13.789 15.0786 13.7319 15.0996 13.6719 15.0996H12.447ZM15.6354 11.659C15.8875 11.659 16.1067 11.59 16.2928 11.4519C16.485 11.3138 16.6111 11.1156 16.6711 10.8574C16.6891 10.7553 16.6981 10.6653 16.6981 10.5872C16.6981 10.4131 16.6471 10.281 16.545 10.191C16.4429 10.0949 16.2688 10.0469 16.0226 10.0469H14.9418L14.5996 11.659H15.6354Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2171_89191">
        <rect fill="white" height={24} width={24} />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgOptimism)
export default Memo
