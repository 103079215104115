import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  useAtomAtomActionContractCallRetrieve,
  useFinanceContractRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { LeftSideInfo, ContractInfo, CreditBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Bank Dashboard',
    url: AppRoutes.BankDashboard,
  },
  {
    label: 'Credit',
    url: AppRoutes.BankViewCreditContract,
  },
]

export const BankViewCreditContract: FC = () => {
  const { creditId } = useParams()

  const {
    data: credit,
    isLoading: isCreditLoading,
    isSuccess: isCreditLoaded,
    refetch: refetchCreditInfo,
  } = useFinanceContractRetrieve(creditId!, {
    query: {
      enabled: Boolean(creditId),
    },
  })

  const {
    data: creditStatusAction,
    isLoading: isCreditStatusLoading,
    isSuccess: isCreditStatusLoaded,
  } = useAtomAtomActionContractCallRetrieve(
    credit?.atomContract.atoms[0].id!,
    {
      action: 'credit_status',
    },
    {
      query: {
        enabled: !!credit,
      },
    }
  )

  const creditStatus = useMemo(() => {
    if (!credit || !creditStatusAction?.data) {
      return
    }

    const creditAtomContractStatus = credit.atomContract.status

    return creditAtomContractStatus !== 'execution'
      ? creditAtomContractStatus
      : (creditStatusAction.data.output as string)
  }, [credit, creditStatusAction])

  if (isCreditLoading || isCreditStatusLoading) {
    return <Loader />
  }

  if (isCreditLoaded && isCreditStatusLoaded && creditStatus) {
    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={
          creditStatus !== 'on_approval'
            ? 'Incoming credit request'
            : 'Withdrawal: Transfer to client'
        }
      >
        <S.Wrapper>
          {creditStatus === 'on_approval' ? (
            <LeftSideInfo
              amount={credit.amount}
              chain={credit.atomContract.chainId}
              contractId={credit.atomContract.id}
              interestRate={credit.interestedRate}
              issuerAddress={credit.atomContract.initiatorAddress}
              refetchCreditInfo={refetchCreditInfo}
            />
          ) : (
            <CreditBlock
              amount={credit.amount}
              atomId={credit.atomContract.atoms[0].id}
              creditStatus={creditStatus}
              interestedRate={credit.interestedRate}
              issuerAddress={credit.atomContract.initiatorAddress}
            />
          )}

          {creditStatus === 'on_approval' ? (
            <ContractInfo
              contractId={credit.atomContract.id}
              createdAt={credit.createdAt}
              status={creditStatus}
            />
          ) : (
            <ContractInfo
              amount={credit.amount}
              chain={credit.atomContract.chainId}
              contractId={credit.atomContract.id}
              createdAt={credit.createdAt}
              interestedRate={credit.interestedRate}
              status={creditStatus}
            />
          )}
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
