import { FC, Fragment, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ActionInputElement } from 'api/omni/model'
import {
  ConstructorVariableBlock,
  useConstructorContext,
  ConstructorAddressInput,
} from 'libs/constructor'
import { checkIfValidUUID } from 'utils/checkIfValidUUID'
import { InputState } from '../types'
import { ChildContractBlock } from './components'

type AddressAtomInputsProps = {
  atomId: string
  placeholder: string
  canBeVariable?: boolean
  actionElements?: ActionInputElement[]
  atomName: string
  state: string
}

export const AddressAtomInputs: FC<AddressAtomInputsProps> = (props) => {
  const {
    atomId,
    placeholder,
    canBeVariable,
    actionElements,
    atomName,
    state,
  } = props

  const { contractId } = useParams()

  const {
    createChildContract,
    setFieldValue,
    currentContract,
    getValue,
    removeContract,
  } = useConstructorContext()

  const handleCreateChildContractClick = useCallback(() => {
    const childContractId = createChildContract()

    setFieldValue({
      atomId,
      name: atomName,
      value: childContractId,
      contractId: currentContract.id,
    })
  }, [atomId, atomName, createChildContract, setFieldValue, currentContract])

  const currentActionElements = useMemo(() => {
    return actionElements?.map((action) => {
      switch (action.action) {
        case 'create_child_contract':
          return {
            ...action,
            onClick: () => handleCreateChildContractClick(),
          }
        default:
          return action
      }
    })
  }, [actionElements, handleCreateChildContractClick])

  const currentAddressInputValue = useMemo(() => {
    const value = getValue({
      atomId,
      name: atomName,
    })

    if (typeof value === 'string') {
      return value
    }

    return ''
  }, [atomId, atomName, getValue])

  const isAddressValueContract = useMemo(() => {
    return checkIfValidUUID(currentAddressInputValue)
  }, [currentAddressInputValue])

  const handleChildContractRemove = useCallback(
    (contractId: string) => {
      removeContract({ contractId })

      setFieldValue({
        atomId,
        name: atomName,
        value: '',
      })
    },
    [atomId, atomName, removeContract, setFieldValue]
  )

  if (state === InputState.HIDDEN) {
    return null
  }

  console.log(state, atomName)

  return (
    <Fragment>
      {isAddressValueContract ? (
        <ChildContractBlock
          childContractId={currentAddressInputValue}
          onRemove={handleChildContractRemove}
        />
      ) : (
        <ConstructorAddressInput
          actionElements={
            state === InputState.EDITABLE ? currentActionElements : []
          }
          atomId={atomId}
          atomName={atomName}
          disabled={state !== InputState.EDITABLE}
          isRequired={Boolean(contractId) && !Boolean(currentAddressInputValue)}
          placeholder={placeholder}
        />
      )}

      {canBeVariable && state === InputState.EDITABLE && (
        <ConstructorVariableBlock atomFieldName={atomName} atomId={atomId} />
      )}
    </Fragment>
  )
}
