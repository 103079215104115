import { FC } from 'react'
import { useAtomAtomActionRetrieve } from 'api/omni/endpoints'
import { ConstructedContractType } from 'pages/ConstructedContract/types'
import { Loader } from 'ui/Loader/Loader'
import { StatusChip, AtomDetail } from './components'
import * as S from './style'

type AtomProps = {
  atomId: string
  currentContract: ConstructedContractType
  updateCurrentContract: (
    updatedCurrentContract: ConstructedContractType
  ) => void
}

export const Atom: FC<AtomProps> = (props) => {
  const { atomId, currentContract, updateCurrentContract } = props

  const {
    data: atom,
    isLoading: isAtomLoading,
    isSuccess: isAtomLoaded,
  } = useAtomAtomActionRetrieve(
    atomId,
    { contract: '' },
    {
      query: {
        enabled: Boolean(atomId),
      },
    }
  )

  if (isAtomLoading) {
    return <Loader />
  }

  if (isAtomLoaded) {
    return (
      <S.Wrapper>
        <S.AtomHead>
          <S.Title>{atom.name}</S.Title>
          <StatusChip status={currentContract.address ? 'active' : 'pending'} />
        </S.AtomHead>
        <S.Line />
        {atom.inputs.map((input) => {
          const currentContractFields = currentContract[atomId]

          const currentInput =
            currentContractFields &&
            Object.entries(currentContractFields).find(
              ([fieldName, fieldValue]) =>
                fieldName === input.name && Boolean(fieldValue)
            )

          if (!currentInput) {
            return null
          }

          const [currentInputName, currentInputValue] = currentInput

          return (
            <AtomDetail
              key={currentInputName}
              label={input.label as string}
              onChildContractClick={(childContract) =>
                updateCurrentContract({
                  ...childContract,
                  parentContract: { ...currentContract },
                })
              }
              value={currentInputValue!}
            />
          )
        })}
      </S.Wrapper>
    )
  }

  return null
}
