import styled from 'styled-components'
import { Button as UiButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  text-align: center;
`

export const Title = styled.div`
  font-weight: 800;
  width: 100%;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.color.green};
`

export const Button = styled(UiButton)`
  max-width: 250px;
  width: 100%;
  margin: auto;
  padding: 12px 24px;
`
