import { ChangeEvent, FC, useCallback, useState, useMemo } from 'react'
import { ethers } from 'ethers'
import { useContract, useNetwork, useSigner } from 'wagmi'
import { useAtomAtomActionContractProcessCreate } from 'api/omni/endpoints'
import ERC20 from 'constants/abi/ERC20.json'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { Chip } from 'ui/Chip/Chip'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type DepositBlockProps = {
  interestedRate: number
  amount: number
  issuerAddress: string
  atomId: string
  depositStatus: string
}

const DEFAULT_DAYS_PER_YEAR = 365
const calculateInterestValue = (
  interestRate: number,
  amount: number,
  days: number
) => {
  return (1 + (days / DEFAULT_DAYS_PER_YEAR) * (interestRate / 100)) * amount
}

export const DepositBlock: FC<DepositBlockProps> = (props) => {
  const { interestedRate, amount, issuerAddress, atomId, depositStatus } = props

  const [transferAmountValue, setTransferAmountValue] = useState<string>('')

  const { chain } = useNetwork()

  const { data: signer } = useSigner()

  const contract = useContract({
    address: getJunaTokenAddress(chain?.id),
    abi: ERC20,
    signerOrProvider: signer,
  })

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const handleTransferInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setTransferAmountValue(evt.target.value)
    },
    []
  )

  const earningAmount = useMemo(() => {
    return calculateInterestValue(interestedRate, amount, 7)
  }, [amount, interestedRate])

  const handleBtnClick = useCallback(async () => {
    const parsedAmount = ethers.utils.parseEther(transferAmountValue.toString())
    let tx

    try {
      tx = await contract?.transfer(issuerAddress, parsedAmount)
    } catch {
      notify.error({
        title: 'Something went wrong',
        message: 'Something went wrong',
      })
    }

    callAction({
      id: atomId,
      data: {
        action: 'call_return_juna',
        inputs: [
          {
            name: 'tx_hash',
            value: tx.hash,
          },
          {
            name: 'address',
            value: issuerAddress,
          },
          {
            name: 'amount',
            value: transferAmountValue,
          },
        ],
      },
    })
  }, [atomId, callAction, contract, issuerAddress, transferAmountValue])

  return (
    <S.Wrapper>
      <S.HeaderBlock>
        <S.ColWrapper>
          <S.Title>Deposited</S.Title>
          <S.AmountWrapper>
            <SvgJunaToken />
            <S.AmountLabel>{amount}</S.AmountLabel>
          </S.AmountWrapper>
        </S.ColWrapper>
        <S.ColWrapper>
          <S.Title>Return client</S.Title>
          <Chip variant="gradient">{earningAmount} JUNA</Chip>
        </S.ColWrapper>
      </S.HeaderBlock>
      {depositStatus === 'claim_requested' && (
        <>
          <S.FieldWrapper>
            <S.FieldLabel>Enter Juna amount to return deposit</S.FieldLabel>
            <Input
              onChange={handleTransferInputChange}
              placeholder="Amount"
              value={transferAmountValue}
            />
          </S.FieldWrapper>
          <Button onClick={handleBtnClick} variant="red">
            Transfer to customer
          </Button>
        </>
      )}
    </S.Wrapper>
  )
}
