import styled from 'styled-components'
import { Card as UiCard } from 'ui/Card/Card'
import { SvgClose } from 'ui/icons'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    justify-content: space-between;
    gap: 24px;

    ${media(size.tablet)} {
      justify-content: center;
      flex-direction: column;
      gap: 24px;
    }
  `
)

export const LeftSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
    max-width: 815px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

     ${media(size.tablet)} {
       max-width: 100%;
     }

    ${media(size.mobile)} {
      gap: 24px;
    }
  `
)

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
`

export const Card = styled(UiCard)`
  padding: 24px;
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 12px 12px 6px;
`

export const ActionCard = styled.div`
  cursor: grab;
  flex-direction: row;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 6px;
  filter: drop-shadow(-2px 0px 3px rgba(0, 0, 0, 0.12));
  &:hover {
    background-color: ${({ theme }) => theme.color.mirageLight};
  }
`

export const ContractNameCard = styled(UiCard)`
  padding: 16px;
  margin-bottom: 24px;
`

export const DragDropBlock = styled.div`
  padding: 24px;
  border: 1px dashed ${({ theme }) => theme.color.mirageLight};
  color: ${({ theme }) => theme.color.mirageDark};
  text-align: center;
  border-radius: 12px;
`

export const RightSideWrapper = styled.div(
  ({ theme: { media, size, color } }) => `
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 12px;
    position: relative;

   ${media(size.tablet)} {
       max-width: 100%;
       margin-bottom: 32px;
     }
  `
)

export const CloseIcon = styled(SvgClose)`
  text-align: center;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.color.gray};
  }
`

export const ReturnCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`

export const AtomsCard = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
`

export const ActionTitle = styled.div`
  font-size: 15px;
  font-weigth: 500;
  color: ${({ theme }) => theme.color.black};
`

export const ActionDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const DragElement = styled.div`
  background-color: ${({ theme }) => theme.color.mirageLight};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  align-items: center;
`

export const StickyActions = styled.div<{ isSticked: boolean }>`
  position: fixed;
  bottom: 0;
  height: 84px;
  background-color: white;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.line};
  opacity: ${({ isSticked }) => (isSticked ? 1 : 0)};

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.2s ease;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`

export const ActionButton = styled.div`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const WrapperActions = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 168px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
