import { StoreNameSpace } from 'constants/app'
import { RootState } from 'types/store'

export const isWalletProviderModalVisible = (state: RootState) =>
  state[StoreNameSpace.Application].isProviderModalVisible

export const isWalletNetworkModalVisible = (state: RootState) =>
  state[StoreNameSpace.Application].isNetworkModalVisible

export const isWalletProfileModalVisible = (state: RootState) =>
  state[StoreNameSpace.Application].isProfileModalVisible

export const selectLegalGate = (state: RootState) =>
  state[StoreNameSpace.Application].legalGate

export const isLegalGate = (state: RootState) =>
  state[StoreNameSpace.Application].isLegalGate
