import styled from 'styled-components'
import { Input as UIInput } from 'ui/Input/Input'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  min-width: 100%;
  position: relative;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: none;

  opacity: 1;
`

export const Input = styled(UIInput)`
  border-radius: 6px;
  border: none;
  box-shadow: none;
  outline: none;

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }

  input {
    color: ${({ error, theme }) =>
      error ? theme.color.red : theme.color.black};
    padding: 7px 0;
    outline: none;
    border: none;

    &::placeholder {
      color: ${({ theme }) => theme.color.mirageDark};
    }
  }
`

export const FilterChipBlock = styled.div`
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.mirageLight};
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`
