import { StoreNameSpace } from 'constants/app'
import { RootState } from 'types/store'

export const selectUserDataLoadStatus = (state: RootState) =>
  state[StoreNameSpace.User].userDataLoadStatus
export const selectPinSet = (state: RootState) =>
  state[StoreNameSpace.User].pinSet
export const selectUserData = (state: RootState) =>
  state[StoreNameSpace.User].userData
export const selectRoles = (state: RootState) =>
  state[StoreNameSpace.User].roles
