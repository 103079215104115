import { useState, FC, useCallback } from 'react'
import { FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import {
  usePlatformLegalgateList,
  usePlatformLegalgateCreate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { notify } from 'libs/notify/notify'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { LegalGateForm, SummaryBlock, StatusBlock } from './components'
import * as S from './style'
import { FormValues } from './types'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Legal Gates Catalog',
    url: AppRoutes.LegalGatesCatalog,
  },
  {
    label: 'Sign up as Legal Gate',
    url: AppRoutes.LegalGateAuth,
  },
]

export const LegalGateAuth: FC = () => {
  const [isSignUpMode, setIsSignUpMode] = useState<boolean>(true)

  const { t } = useTranslation('pageLegalGateAuth')

  const { address } = useAccount()

  const {
    data: legalGate,
    isLoading: isLegalGateLoading,
    isSuccess: isLegalGateSuccess,
  } = usePlatformLegalgateList(
    { address },
    {
      query: {
        onSuccess: (data) => {
          if (data.results.length > 0) {
            setIsSignUpMode(false)
          }
        },
      },
    }
  )

  const { mutate, isLoading: isSignedLegalGate } = usePlatformLegalgateCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Legal Gate created'),
          title: t('Legal Gate created'),
        })
      },
    },
  })

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    (values: FormValues) => {
      const { category, amountJUNA, network, country, ...otherValues } = values

      mutate({
        data: {
          ...otherValues,
          price: amountJUNA,
          chains: [Number(network)],
          categories: [Number(category)],
          jurisdictions: [Number(country)],
          chainIdFee: Number(network),
        },
      })
    },
    [mutate]
  )

  if (isLegalGateLoading || isSignedLegalGate) {
    return <Loader />
  }

  if (isLegalGateSuccess) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Sign up as Legal Gate')}>
        <S.Wrapper>
          <LegalGateForm
            handleSubmit={handleSubmit}
            isSignUpMode={isSignUpMode}
            legalGate={legalGate.results}
          />
          {isSignUpMode ? <SummaryBlock /> : <StatusBlock />}
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
