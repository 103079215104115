import { FC, useCallback } from 'react'
import { utils } from 'ethers'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSwitchNetwork, useNetwork, useAccount } from 'wagmi'
import { useNetworkTxSendFrCreate } from 'api/omni/endpoints'
import { Contract } from 'api/omni/model'
import { FormInput } from 'features/FormFileds'
import { useSignTypedData } from 'hooks'
import { getForwarderAddress } from 'libs/addresses/addresses'
import {
  buildRequest,
  EIP712Domain,
  Types,
} from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { FORM_ID } from 'pages/ContractProcess/constants'
import * as S from './style'
import { ContextDynamicFieldRuntype, InputRuntype } from './types'
import { getValidationSchema } from './validationSchema'

export type FormValues = {
  [key: string]: string
}

type ProcessFormProps = {
  data: Contract
  selectorFn: string
}

export const ProcessForm: FC<ProcessFormProps> = (props) => {
  const { data, selectorFn } = props

  const { t } = useTranslation('pageContractProcess')

  const { switchNetwork } = useSwitchNetwork()

  const { address } = useAccount()

  const { chain } = useNetwork()

  const { getNonce, signTypedData } = useSignTypedData(
    getForwarderAddress(chain?.id)
  )

  const { mutate } = useNetworkTxSendFrCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Transaction has been sended'),
          title: t('Transaction has been sended'),
        })
      },
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (chain?.id === data.chainId) {
        const coder = new utils.AbiCoder()
        const valuesArr = Object.values(values)
        const inputs = (data.context![selectorFn] as any).inputs
        const abiArr = Object.keys(inputs).reduce((acc, input) => {
          acc.push(inputs[input].type)
          return acc
        }, [] as string[])
        const fnData = coder.encode(abiArr, valuesArr).slice(2)

        const input = {
          data: selectorFn + fnData,
          to: data.address,
        }

        const nonce = await getNonce()
        const request = buildRequest(nonce, input, address)
        const signed = await signTypedData({
          value: request,
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: Types,
        })

        mutate({
          data: {
            blockchain: chain.id,
            data: request,
            dataSig: signed,
          },
        })

        return
      }

      switchNetwork?.(data.chainId)
    },
    [
      address,
      chain,
      data,
      getNonce,
      mutate,
      selectorFn,
      signTypedData,
      switchNetwork,
    ]
  )

  if (data.context) {
    const selectorContext = ContextDynamicFieldRuntype.check(
      data.context[selectorFn]
    )

    const getInitialValues = () => {
      const initialValues: FormValues = {}

      if (selectorContext.inputs) {
        Object.entries(selectorContext.inputs).forEach(([fieldName]) => {
          initialValues[fieldName] = ''
        })
      }

      return initialValues
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(selectorContext.inputs)}
      >
        {({ handleSubmit }) => {
          const { ...dynamicFields } = selectorContext.inputs

          return (
            <S.Container>
              <S.Title>{selectorContext.name}</S.Title>
              <S.Description>{selectorContext.context}</S.Description>
              <form id={FORM_ID} onSubmit={handleSubmit}>
                <S.ExtendedFieldsContainer>
                  {Object.entries(dynamicFields).map(
                    ([fieldName, fieldData]) => {
                      const { name: placeholder } =
                        InputRuntype.check(fieldData)

                      return (
                        <FormInput
                          key={fieldName}
                          name={fieldName}
                          placeholder={placeholder}
                        />
                      )
                    }
                  )}
                </S.ExtendedFieldsContainer>
              </form>
            </S.Container>
          )
        }}
      </Formik>
    )
  }

  return null
}
