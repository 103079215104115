import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${media(size.mobile)} {
      position: relative;
    }
`
)

export const AuthBlockWrapper = styled.div(
  ({ theme: { media, size, common, color } }) => `
    border-radius: 12px;
    box-shadow: ${common.boxShadow};
    background-color: ${color.white};
    max-width: 572px;
    width: 100%;

    ${media(size.mobile)} {
      margin: 24px;
    }
`
)

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`

export const Title = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const StepperWrapper = styled.div`
  padding: 24px;
`
