import styled from 'styled-components'
import { SvgAtomClose } from 'ui/icons'

export const Wrapper = styled.div`
  width: 100%;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.color.line};
  background-color: ${({ theme }) => theme.color.mirageLight};
  transition: opacity 0.3s;
`

export const Title = styled.h4`
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.color.black};
`

export const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  transition: 0.3s;
  path {
    fill: ${({ theme }) => theme.color.black};
  }

  &:hover {
    opacity: 0.7;
  }
`
