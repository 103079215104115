import { getI18n } from 'react-i18next'
import { ContractItem } from 'api/omni/model'

/**
 * Generate string from contract with all roles by address
 * @param contract - object of contract
 * @param address - user address
 * @example
 * formatRole(contract, address)
 * @returns {string} all roles
 */
export const formatRole = (contract: ContractItem, address: string) => {
  const { t } = getI18n()

  const Roles = {
    initiator: t('roles.Initiator'),
    member: t('roles.Member'),
    legalGate: t('roles.Legal Gate'),
  } as const

  const roles: string[] = []
  const formatedAddress = address.toLowerCase()

  if (contract.initiatorAddress === formatedAddress) {
    roles.push(Roles.initiator)
  }
  if (contract.members.find((member) => member.address === formatedAddress)) {
    roles.push(Roles.member)
  }

  return roles.join(', ')
}
