import { FC } from 'react'
import Tree, { TreeNode, TreeProps } from 'rc-tree'
import 'rc-tree/assets/index.css'
import { ConstructedContractType } from 'pages/ConstructedContract/types'
import { checkIfValidUUID } from 'pages/ConstructedContract/utils/checkIfValidUUID'
import * as S from './style'

type TreeNavigatorProps = {
  treeData: ConstructedContractType
  updateContractNav: (currentContract: ConstructedContractType) => void
}

type ContractTreeType = {
  contractName: string
  children: ContractTreeType[]
}

const buildTree = (treeItem: ConstructedContractType) => {
  return Object.entries(treeItem).reduce<ContractTreeType>(
    (result, [treeItemKey, treeItemValue]) => {
      const isAtom = checkIfValidUUID(treeItemKey)

      const atomContract = (treeItemValue as ConstructedContractType)
        ?.destinationAddress

      if (isAtom && typeof atomContract === 'object') {
        result.children.push(buildTree(atomContract as ConstructedContractType))
      }

      return result
    },
    {
      contractName: treeItem.contractName,
      children: [],
    }
  )
}

const getExpandedKeys = (treeData: ContractTreeType[]) => {
  return treeData.reduce<string[]>((contractNames, contract) => {
    contractNames.push(contract.contractName)

    if (contract.children.length > 0) {
      contractNames.push(...getExpandedKeys(contract.children))
    }
    return contractNames
  }, [])
}

export const TreeNavigator: FC<TreeNavigatorProps> = (props) => {
  const { treeData, updateContractNav } = props

  const nestedItems = [buildTree(treeData)]

  const expandedKeys = getExpandedKeys(nestedItems)

  const renderTreeNodes = (treeNodes: ContractTreeType[]) => {
    return treeNodes.map((treeNode) => {
      if (treeNode.children.length) {
        return (
          <TreeNode
            key={treeNode.contractName}
            icon={<S.ContractIcon />}
            title={treeNode.contractName}
          >
            {renderTreeNodes(treeNode.children)}
          </TreeNode>
        )
      }

      return (
        <TreeNode
          key={treeNode.contractName}
          icon={<S.ContractIcon />}
          title={treeNode.contractName}
        />
      )
    })
  }

  const onTreeNodeSelect: TreeProps['onSelect'] = (selectedKeys) => {
    const [selectedContractName] = selectedKeys

    let parentContract: ConstructedContractType

    const getCurrentContract = (
      contractData: ConstructedContractType,
      targetContractName: string
    ): ConstructedContractType | null => {
      if (typeof contractData !== 'object' || contractData === null) {
        return null
      }

      // если проверяемый объект является контрактом не равным тому что мы ищем - ложим в родителя
      if (
        contractData.hasOwnProperty('contractName') &&
        contractData.contractName !== targetContractName
      ) {
        parentContract = { ...contractData, parentContract }
      }

      // если проверяемый объект равен тому что мы ищем - возвращаем его
      if (contractData.contractName === targetContractName) {
        return { ...contractData, parentContract }
      }

      // в ином случае рекурсивно перебираем по остальным свойствам, и возвращаем нужный объект
      for (const key in contractData) {
        const nestedObject = getCurrentContract(
          contractData[key] as ConstructedContractType,
          targetContractName
        )

        if (nestedObject !== null) {
          return nestedObject
        }
      }

      return null
    }

    if (selectedContractName) {
      const currentContract = getCurrentContract(
        treeData,
        selectedContractName as string
      )

      if (currentContract) {
        updateContractNav(currentContract)
      }
    }
  }

  return (
    <S.Wrapper>
      <S.Title>Contracts Tree</S.Title>
      <S.TreeWrapper>
        <Tree
          autoExpandParent={true}
          expandedKeys={expandedKeys}
          onSelect={onTreeNodeSelect}
          showIcon={true}
          switcherIcon={false}
        >
          {renderTreeNodes(nestedItems)}
        </Tree>
      </S.TreeWrapper>
    </S.Wrapper>
  )
}
