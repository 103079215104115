import { FC } from 'react'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { Button } from 'ui/Button/Button'
import * as S from './style'

type ContractInfoProps = {
  createdAt: string
  contractChainId: number
}

export const ContractInfo: FC<ContractInfoProps> = (props) => {
  const { createdAt, contractChainId } = props

  const { contractId } = useParams()

  const navigate = useNavigate()

  const handleViewBtnClick = () => {
    navigate(`${AppRoutes.AtomContract}/${contractId}`)
  }

  return (
    <S.Wrapper>
      <S.Title>Contract Information</S.Title>
      <S.ListWrapper>
        <S.ListItem>
          <S.ListItemLabel>Chain</S.ListItemLabel>
          <S.DashedLine />
          <S.ListItemLabel>{Chains[contractChainId].name}</S.ListItemLabel>
        </S.ListItem>

        <S.ListItem>
          <S.ListItemLabel>Created At</S.ListItemLabel>
          <S.DashedLine />
          <S.ListItemLabel>
            {dayjs(createdAt).format('DD.MM.YYYY')}
          </S.ListItemLabel>
        </S.ListItem>
      </S.ListWrapper>
      <Button onClick={handleViewBtnClick}>View contract</Button>
    </S.Wrapper>
  )
}
