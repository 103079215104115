import { rgba } from 'polished'
import styled from 'styled-components'
import { CountColor } from './types'

export const Wrapper = styled.div(
  ({ theme: { media, size, color, common } }) => `
  display: flex;
  align-items: center;
  padding: 0px 16px;
  box-shadow: 0px 2px 3px ${common.boxShadow};
  border: 1px solid ${color.line};
  background-color: ${color.white};
  border-radius: 12px;
  width: 100%;

  ${media(size.tablet)} {
    padding: 16px;
    flex-wrap: wrap;
  }
`
)

export const TabWrapper = styled.div<{ isSelected: boolean }>(
  ({ theme: { media, size, color }, isSelected }) => `
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  cursor: pointer;
  background-color: ${isSelected ? color.line : color.white};

  &:hover {
    background-color: ${!isSelected && rgba(color.bg, 0.5)};
  }

  ${media(size.tablet)} {
    border-radius: 12px;
    width: 50%;
  }

  ${media(size.mobile)} {
    width: 100%;
  }
`
)

export const Label = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const CountBlock = styled.div<{ color?: CountColor }>`
  padding: 2px 9px;
  border-radius: 30px;
  background-color: ${({ theme, color }) =>
    color ? theme.color[color] : 'none'};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.white};
`
