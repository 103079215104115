import { FC, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useNetwork, useAccount } from 'wagmi'
import { useAtomConstructCreate, useBanksBankList } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { useTokenBalance } from 'hooks'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { notify } from 'libs/notify/notify'
import { getCreditContract } from 'pages/CreditCreate/creditContract'
import { Button } from 'ui/Button/Button'
import { Chip } from 'ui/Chip/Chip'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import { Loader } from 'ui/Loader/Loader'
import {
  PlateOption,
  SelectPlatesBlock,
} from 'ui/SelectPlatesBlock/SelectPlatesBlock'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import { toHumanNumber } from 'utils/toHumanNumber'
import * as S from './style'

const DEFAULT_DAYS_PER_YEAR = 365
const DEFAULT_TERM_DAYS = 7

const calculateRepayment = (
  interestRate: number,
  amount: number,
  days: number
) => {
  return (1 + (interestRate * days) / (100 * DEFAULT_DAYS_PER_YEAR)) * amount
}

export const CreditForm: FC = () => {
  const [selectedBankOption, setSelectedBankOption] = useState<PlateOption>()
  const [amountValue, setAmountValue] = useState<string>('')

  const { address } = useAccount()

  const { t } = useTranslation('pageDepositCreate')

  const { chain } = useNetwork()

  const navigate = useNavigate()

  const balance = useTokenBalance(getJunaTokenAddress(chain?.id), address)

  const {
    data: banks,
    isLoading: isBanksLoading,
    isSuccess: isBanksLoaded,
  } = useBanksBankList(
    {},
    {
      query: {
        onSuccess: (data) => {
          const curerentBank = data.results[0]

          setSelectedBankOption({
            label: curerentBank.name!,
            value: curerentBank.address,
            description: `${curerentBank.creditInterestRate}% interest on credit`,
          })
        },
      },
    }
  )

  const { mutate: constructContract } = useAtomConstructCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: 'Credit request have been sended',
          title: 'Success!',
        })

        navigate(AppRoutes.Finance)
      },
    },
  })

  const currentRadioButtons = useMemo(() => {
    if (!banks) {
      return
    }

    return banks.results.map((bank) => ({
      label: bank.name!,
      value: bank.address,
      description: `${bank.creditInterestRate}% interest on credit`,
    }))
  }, [banks])

  const currentBank = useMemo(() => {
    if (!banks || !selectedBankOption) {
      return
    }

    return banks.results.find(
      (bank) => bank.address === selectedBankOption.value
    )
  }, [banks, selectedBankOption])

  const handleSubmit = () => {
    if (!address || !currentBank || !chain) {
      return
    }

    if (currentBank?.creditInterestRate) {
      const currentCreditContract = getCreditContract({
        userAddress: address,
        bankAddress: currentBank.address,
        amountJuna: amountValue,
        chainId: chain.id,
        interestedRate: currentBank.creditInterestRate.toString(),
        finishedAt: dayjs().add(DEFAULT_TERM_DAYS, 'days').toISOString(),
      })

      constructContract({
        data: {
          contracts: [currentCreditContract],
        },
      })
    }
  }

  if (isBanksLoading) {
    return <Loader />
  }

  if (isBanksLoaded && currentRadioButtons && selectedBankOption) {
    return (
      <S.Wrapper>
        <S.ColumnFlexWrapper>
          <S.Title>{t('Balance')}</S.Title>
          <S.BalanceWrapper>
            <SvgJunaToken />
            <S.BalanceLabel>{toHumanNumber(balance)}</S.BalanceLabel>
          </S.BalanceWrapper>
        </S.ColumnFlexWrapper>
        <S.Form>
          <S.FormFielsWrapper>
            <S.FormFieldWrapper>
              <S.FieldLabel>{t('Chain Type')}</S.FieldLabel>
              <Input value={chain?.id && Chains[chain.id].name} />
            </S.FormFieldWrapper>
            <S.FormFieldWrapper>
              <S.FieldLabel>{t('Token/Currency')}</S.FieldLabel>
              <Input value={'Juna'} />
            </S.FormFieldWrapper>
          </S.FormFielsWrapper>
          <S.ColumnFlexWrapper>
            <S.FieldLabel>{t('Bank')}</S.FieldLabel>
            <SelectPlatesBlock
              onChange={setSelectedBankOption}
              options={currentRadioButtons}
              selectedOption={selectedBankOption}
            />
          </S.ColumnFlexWrapper>
          <S.ColumnFlexWrapper>
            <S.FieldLabel>{t('Enter Juna amount to credit')}</S.FieldLabel>
            <Input
              onChange={(evt) => setAmountValue(evt.target.value)}
              value={amountValue}
            />
          </S.ColumnFlexWrapper>
          <S.ColumnFlexWrapper>
            <S.FieldLabel>{t('Repayment')}</S.FieldLabel>
            {currentBank && (
              <Chip variant="gradient">
                {formatNumberWithSeparators(
                  calculateRepayment(
                    Number(currentBank.creditInterestRate),
                    parseFloat(amountValue) || 0,
                    DEFAULT_TERM_DAYS
                  )
                )}{' '}
                JUNA
              </Chip>
            )}
          </S.ColumnFlexWrapper>
          <Button onClick={handleSubmit} variant="red">
            {t('Send for approval')}
          </Button>
        </S.Form>
      </S.Wrapper>
    )
  }

  return null
}
