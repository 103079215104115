import { FC } from 'react'
import { Proposal } from 'api/omni/model'
import { ResultItem } from './components/ResultItem/ResultItem'
import * as S from './style'

type ResultsBodyProps = {
  votings: Proposal[]
}

export const ResultsBody: FC<ResultsBodyProps> = (props) => {
  const { votings } = props

  return (
    <S.Wrapper>
      {votings.map((result) => (
        <ResultItem key={result.id} result={result} />
      ))}
    </S.Wrapper>
  )
}
