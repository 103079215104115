import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: inherit;
  justify-content: space-between;
  cursor: pointer;
`

export const LanguageLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`

export const Label = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
`
