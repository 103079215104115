import styled from 'styled-components'

export const Wrapper = styled.div<{ bgImage?: string | null }>`
  width: 100%;
  height: 363px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-direction: column;
  border-radius: 12px;
  background: ${({ theme, bgImage }) =>
    bgImage ? `center no-repeat url(${bgImage})` : theme.color.white};
  background-size: cover;
  padding: 12px;
`

export const UploadBlock = styled.div`
  border-radius: 12px;
  border: 1px dashed ${({ theme }) => theme.color.line};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HiddenInput = styled.input`
  display: none;
`
