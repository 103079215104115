import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#372D4C"
      fillOpacity={0.7}
      height={2}
      rx={1}
      width={12}
      x={2}
      y={7}
    />
  </svg>
)
const Memo = memo(SvgMinus)
export default Memo
