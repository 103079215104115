import styled from 'styled-components'
import { ConstructorInput } from 'libs/constructor/components'
import { SvgAtomClose } from 'ui/icons'

export const FileContainer = styled.div`
  background-color: ${({ theme }) => theme.color.mirageLight};
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const InfoContainer = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.color.mirageDark};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Label = styled.div`
  font-size: 10px;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.mirageDark};
  &:hover {
    fill: ${({ theme }) => theme.color.black};
  }
`

export const FileInputContainer = styled(ConstructorInput)`
  display: none;
`
