import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TopBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const CardsWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  ${media(size.tablet)} {
    justify-content: center;
  }
`
)

export const DepositCardBorder = styled.div`
  width: 24%;
  min-width: 267px;
  height: 381px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
`

export const CreateBlock = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  width: 100%;
  height: 100%;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.color.gray};
  text-align: center;
`

export const CreateBlockBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`

export const CreateDepositImage = styled.img`
  padding-top: 24px;
`

export const OperationsBlock = styled(Card)`
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`

export const OperationsLabel = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  color: ${({ theme }) => theme.color.gray};
`

export const OperationButton = styled(Button)`
  border-radius: 12px;
  padding: 9px 16px;
`
