import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgJunaSimpleFast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#1B1F3B" fillOpacity={0.05} height={48} rx={24} width={48} />
    <path
      clipRule="evenodd"
      d="M17 16C15.8954 16 15 16.8954 15 18V26C15 27.1046 15.8954 28 17 28H19V24C19 21.7909 20.7909 20 23 20H27V18C27 16.8954 26.1046 16 25 16H17ZM29 20V18C29 15.7909 27.2091 14 25 14H17C14.7909 14 13 15.7909 13 18V26C13 28.2091 14.7909 30 17 30H19V32C19 34.2091 20.7909 36 23 36H31C33.2091 36 35 34.2091 35 32V24C35 21.7909 33.2091 20 31 20H29ZM27 22H26.0931L27 23.0203V22ZM23.6848 22H23C22.781 22 22.5702 22.0352 22.373 22.1002L26.8998 26.627C26.9648 26.4298 27 26.219 27 26V25.7296L23.6848 22ZM21.1002 23.373C21.0352 23.5702 21 23.781 21 24V24.7272L24.2728 28H25C25.219 28 25.4298 27.9648 25.627 27.8998L21.1002 23.373ZM21 27.2728V28H21.7272L21 27.2728ZM21 30H25C27.2091 30 29 28.2091 29 26V22H31C32.1046 22 33 22.8954 33 24V32C33 33.1046 32.1046 34 31 34H23C21.8954 34 21 33.1046 21 32V30Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgJunaSimpleFast)
export default Memo
