import styled from 'styled-components'
import { SvgDisconnect } from 'ui/icons'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
`

export const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 38px;
  color: ${({ theme }) => theme.color.black};
  background: ${({ theme }) => theme.color.lightGray};
  height: 27px;
  border: none;
`

export const DisconnectButton = styled(SvgDisconnect)`
  cursor: pointer;
`
