import styled from 'styled-components'

export const AddressLabel = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: 12px;
`
export const BalanceContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: background 0.5s ease-out;
  &:hover {
    background: ${({ theme }) => theme.color.bg};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.black};
  font-size: 18px;
  font-weight: 600;
`

export const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.black};
`

export const HintContainer = styled.div`
  max-width: 250px;
`

export const BalanceLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
