import { FC, useState } from 'react'
import { SvgMinus, SvgSimplePlus } from 'ui/icons'
import { SubmenuLink } from '../../../../types'
import { MobileMenuLink } from '../MobileMenuLink/MobileMenuLink'
import * as S from './style'

type MobileSubmenuProps = {
  navLink: SubmenuLink
  toggleMobileMenu?: () => void
}

export const MobileSubmenu: FC<MobileSubmenuProps> = (props) => {
  const { navLink, toggleMobileMenu } = props
  const [isMobileSubmenuMenuOpen, setIsMobileSubmenuMenuOpen] =
    useState<boolean>(false)

  const toggleSubmenu = () => {
    setIsMobileSubmenuMenuOpen((prevOpen) => !prevOpen)
  }

  return (
    <>
      <MobileMenuLink as="button" onClick={toggleSubmenu}>
        {isMobileSubmenuMenuOpen ? <SvgMinus /> : <SvgSimplePlus />}{' '}
        {navLink.label}
      </MobileMenuLink>
      {isMobileSubmenuMenuOpen && (
        <S.MobileSubmenu>
          {navLink.sublinks.map((sublink) => {
            if (sublink.hidden) {
              return null
            }

            return (
              <MobileMenuLink
                key={sublink.label}
                $isSubmenuLink={true}
                onClick={toggleMobileMenu}
                to={sublink.to}
              >
                {sublink.label}
              </MobileMenuLink>
            )
          })}
        </S.MobileSubmenu>
      )}
    </>
  )
}
