import styled from 'styled-components'
import { Button } from 'ui/Button/Button'

export const LinkWrapper = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 6px 0;
  }
`

export const FeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
`

export const FeeTitle = styled.div`
  color: ${({ theme }) => theme.color.gray};
`
export const FeeLabel = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
`

export const IconButton = styled(Button)`
  width: auto;
  padding: 8px;
`
