import { useCallback, useMemo } from 'react'
import { ExternalProvider } from '@ethersproject/providers'
import { ethers } from 'ethers'
import Web3Token from 'web3-token'
import { MAX_TOKEN_LIFETIME } from 'constants/app'
import { useWeb3TokenLifetime } from 'hooks/useWeb3Token/useWeb3TokenLifetime/useWeb3TokenLifetime'
import {
  dropAuthToken,
  dropTokenTimestamp,
  getAuthToken,
  storeAuthToken,
} from 'libs/auth-storage/auth-storage'

type UseWeb3Token = {
  onTokenApplied: () => void
  onTokenDeleted: () => void
}

export const useWeb3Token = ({
  onTokenApplied,
  onTokenDeleted,
}: UseWeb3Token) => {
  const {
    createWeb3TokenTimestamp,
    startWeb3TokenExpirationTimeout,
    clearWeb3TokenExpirationTimeout,
    checkIsWeb3TokenExpired,
  } = useWeb3TokenLifetime()

  const deleteWeb3Token = useCallback(() => {
    clearWeb3TokenExpirationTimeout()
    dropAuthToken()
    dropTokenTimestamp()

    onTokenDeleted()
  }, [clearWeb3TokenExpirationTimeout, onTokenDeleted])

  const receiveWeb3Token = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum as ExternalProvider
    )
    const signer = provider.getSigner()
    const token = await Web3Token.sign(
      async (msg: string) => await signer.signMessage(msg),
      {
        expires_in: MAX_TOKEN_LIFETIME,
      }
    )

    storeAuthToken(token)
    createWeb3TokenTimestamp()

    startWeb3TokenExpirationTimeout(() => {
      deleteWeb3Token()
      receiveWeb3Token()
    })

    onTokenApplied()
  }, [
    createWeb3TokenTimestamp,
    deleteWeb3Token,
    onTokenApplied,
    startWeb3TokenExpirationTimeout,
  ])

  const setupWeb3Token = useCallback(async () => {
    const token = getAuthToken()

    if (!token || checkIsWeb3TokenExpired()) {
      await receiveWeb3Token()
      return
    }

    startWeb3TokenExpirationTimeout(() => {
      deleteWeb3Token()
      receiveWeb3Token()
    })

    onTokenApplied()
  }, [
    checkIsWeb3TokenExpired,
    startWeb3TokenExpirationTimeout,
    onTokenApplied,
    receiveWeb3Token,
    deleteWeb3Token,
  ])

  return useMemo(
    () => ({
      deleteWeb3Token,
      setupWeb3Token,
    }),
    [deleteWeb3Token, setupWeb3Token]
  )
}
