import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`

export const SwitchItem = styled.div<{ isActive?: boolean }>`
  padding: 6px 12px;
  border-radius: ${({ isActive }) => !isActive && '6px'};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.mirageLight : theme.color.white};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
