import { createContext, useCallback, useState } from 'react'
import { ContactsModal } from 'modals/ContactsModal/ContactsModal'
import { FCWithChildren } from 'types/app'

export type DisplayMode = 'selectContact' | 'addContact'

type OpenContactModal = (args: {
  contactInputValue?: string
  displayMode?: DisplayMode
  onAddressSelect: (address: string) => void
}) => void

type ContactsContextProps = {
  openContactModal: OpenContactModal
}

const DEFAULT_DISPLAY_MODE: DisplayMode = 'selectContact'

export const ContactsModalContext = createContext({} as ContactsContextProps)

export const ContactsModalProvider: FCWithChildren = (props) => {
  const { children } = props

  const [isVisible, setVisible] = useState<boolean>(false)

  const [displayMode, setDisplayMode] =
    useState<DisplayMode>(DEFAULT_DISPLAY_MODE)

  const [handleAddressSelect, setHandleAddressSelect] =
    useState<(address: string) => void>()

  const [contactInputValue, setContactInputValue] = useState<string>('')

  const openModal = useCallback<OpenContactModal>(
    ({
      onAddressSelect,
      displayMode = DEFAULT_DISPLAY_MODE,
      contactInputValue = '',
    }) => {
      setVisible(true)
      setDisplayMode(displayMode)
      setHandleAddressSelect(() => onAddressSelect)
      setContactInputValue(contactInputValue)
    },
    []
  )

  const closeModal = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <ContactsModalContext.Provider value={{ openContactModal: openModal }}>
      {children}
      {isVisible && (
        <ContactsModal
          contactInputValue={contactInputValue}
          displayMode={displayMode}
          onAddressSelect={handleAddressSelect!}
          onClose={closeModal}
          setDisplayMode={setDisplayMode}
        />
      )}
    </ContactsModalContext.Provider>
  )
}
