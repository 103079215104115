import styled from 'styled-components'

export const Link = styled.a`
  color: ${({ theme }) => theme.color.blue};
  border-bottom: 1px dashed ${({ theme }) => theme.color.blue};
  cursor: pointer;
  width: max-content;

  &:hover {
    color: ${({ theme }) => theme.color.cobalt};
    border-bottom: 1px dashed ${({ theme }) => theme.color.cobalt};
  }
`
