import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 40px 30px;
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 12px;
`

export const Label = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`

export const ValueLabel = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  color: ${({ theme }) => theme.color.black};
`
