import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import {
  usePlatformAdsList,
  usePlatformAdsCategoryList,
} from 'api/omni/endpoints'
import { AdsCategory } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { useDebounce } from 'hooks'
import { CategoriesList } from 'ui/CategoriesList/CategoriesList'
import { Loader } from 'ui/Loader/Loader'
import { Overlay } from 'ui/Overlay/Overlay'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { AdItem, PriceSortBlock, SearchBlock } from './components'
import * as S from './style'
import { NormalizedCategory, PriceSort, PriceSortOption } from './types'

const ITEMS_ON_PAGE = 10

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Marketplace',
    url: AppRoutes.Marketplace,
  },
]

const PriceSorts = {
  asc: 'amountjuna',
  desc: '-amountjuna',
} as const

export const Ads: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentCategory, setCurrentCategory] = useState<number | string>()
  const [currentPriceSort, setCurrentPriceSort] = useState<PriceSort>()
  const [searchedAdValue, setSearchedAdValue] = useState<string>('')

  const debouncedSearchValue = useDebounce(searchedAdValue)

  const { t } = useTranslation('pageAds')

  const navigate = useNavigate()

  const { data: categories, isSuccess: isCategoriesLoaded } =
    usePlatformAdsCategoryList(
      {},
      {
        query: {
          select: useCallback((data: AdsCategory[]) => {
            const normalizedData = data.reduce<NormalizedCategory[]>(
              (acc, current) => {
                const existedCategory = acc.find(({ id }) => id === current.id)

                if (existedCategory) {
                  return acc
                }

                if (!current.parentId) {
                  acc.push({ ...current })
                  return acc
                }

                const parent = acc.find(({ id }) => id === current.parentId)

                if (parent) {
                  parent.subcategories = parent.subcategories || []
                  parent.subcategories.push(current)

                  return acc
                }

                if (!parent) {
                  const dataParent = data.find(
                    ({ id }) => id === current.parentId
                  )

                  if (dataParent) {
                    acc.push({ ...dataParent, subcategories: [current] })
                  }
                }

                return acc
              },
              []
            )

            return normalizedData.sort((aCategory, bCategory) => {
              const aSubcategories = aCategory.subcategories?.length || 0
              const bSubcategories = bCategory.subcategories?.length || 0

              return bSubcategories - aSubcategories
            })
          }, []),
        },
      }
    )

  const {
    data: ads,
    isLoading: isAdsLoading,
    isSuccess: isAdsSuccess,
    isFetching: isAdsFetching,
  } = usePlatformAdsList(
    {
      page: currentPage,
      category: currentCategory as number,
      ordering: currentPriceSort && PriceSorts[currentPriceSort],
      name: debouncedSearchValue,
    },
    { query: { keepPreviousData: true } }
  )

  const handlePlaceAnAdClick = () => {
    navigate(AppRoutes.AdsAdd)
  }

  const totalPages = ads && Math.floor(ads.results.length / ITEMS_ON_PAGE)

  const handlePriceSortChange = useCallback(
    (priceSort: SingleValue<PriceSortOption>) => {
      setCurrentPriceSort(priceSort?.value)
    },
    []
  )

  return (
    <PageWrapper
      actions={
        <S.ButtonWrapper>
          <S.ActionText>Publish your item on the marketplace</S.ActionText>
          <S.Button onClick={handlePlaceAnAdClick} variant="red">
            {t('Place an ad')}
          </S.Button>
        </S.ButtonWrapper>
      }
      breadcrumbs={breadcrumbs}
      title={t('Marketplace')}
    >
      <S.Wrapper>
        <SearchBlock
          onPriceSortChange={handlePriceSortChange}
          onSearchChange={setSearchedAdValue}
        />
        <S.PageContent>
          <S.Sort>
            {/* <EmploymentSortBlock /> */}

            {isCategoriesLoaded && (
              <S.SortWrapper>
                <S.FilterWrapper>
                  <S.SortText>Filter</S.SortText>
                  <CategoriesList
                    activeCategory={currentCategory}
                    categories={categories}
                    onCategoryChange={setCurrentCategory}
                  />
                </S.FilterWrapper>
                <S.FilterWrapper>
                  <S.SortText>Price</S.SortText>
                  <PriceSortBlock />
                </S.FilterWrapper>
                <S.ResetButton variant="transparent">
                  {t('Reset all')}
                </S.ResetButton>
              </S.SortWrapper>
            )}
          </S.Sort>
          {isAdsLoading && <Loader />}
          {isAdsSuccess && (
            <Overlay isLoading={isAdsFetching}>
              {ads.results.length > 0 ? (
                <S.ContentWrapper>
                  <S.ItemsWrapper>
                    {ads.results.map(
                      ({
                        name,
                        description,
                        id,
                        address,
                        amountjuna,
                        image,
                      }) => (
                        <AdItem
                          key={id}
                          adId={id}
                          address={address}
                          amountJuna={amountjuna}
                          description={description}
                          image={image}
                          name={name}
                        />
                      )
                    )}
                  </S.ItemsWrapper>
                  {totalPages && totalPages > 1 && (
                    <S.PaginationWrapper>
                      <S.TotalItemsLabel>
                        {t('Total items', { count: ads.count })}
                      </S.TotalItemsLabel>
                      <Pagination
                        currentPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        totalPages={totalPages}
                      />
                    </S.PaginationWrapper>
                  )}
                </S.ContentWrapper>
              ) : (
                <S.Label>{t('No ads found')}</S.Label>
              )}
            </Overlay>
          )}
        </S.PageContent>
      </S.Wrapper>
    </PageWrapper>
  )
}
