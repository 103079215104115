import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisconnect } from 'wagmi'
import { AuthStepper } from 'features/AuthStepper/AuthStepper'
import { useAuthStatus, useProviderModal } from 'hooks'
import { SvgArrowRight } from 'ui/icons'
import { UserInfo } from '../'
import { NavLink } from '../../types'
import { MobileMenuLink } from './components/MobileMenuLink/MobileMenuLink'
import { MobileSubmenu } from './components/MobileSubmenu/MobileSubmenu'
import * as S from './style'

type MobileMenuProps = {
  className?: string
  navLinks: NavLink[]
  toggleMobileMenu?: () => void
}

export const MobileMenu: FC<MobileMenuProps> = (props) => {
  const { className, navLinks, toggleMobileMenu } = props

  const { t } = useTranslation('featureLayout')

  const { authStatus } = useAuthStatus()

  const { disconnect } = useDisconnect()

  const { toggleProviderModal } = useProviderModal()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <S.Wrapper className={className}>
      {authStatus === 'authorized' ? (
        <S.Nav>
          {navLinks.map((navLink) => {
            switch (navLink.role) {
              case 'link': {
                return (
                  <MobileMenuLink
                    key={navLink.label}
                    onClick={toggleMobileMenu}
                    to={navLink.to}
                  >
                    <SvgArrowRight /> {navLink.label}
                  </MobileMenuLink>
                )
              }
              case 'submenu': {
                return (
                  <MobileSubmenu
                    key={navLink.label}
                    navLink={navLink}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                )
              }
              default: {
                throw new Error()
              }
            }
          })}
        </S.Nav>
      ) : (
        <S.StepperWrapper>
          <AuthStepper authStatus={authStatus} />
        </S.StepperWrapper>
      )}

      <S.BottomBlockWrapper>
        {authStatus !== 'not-authorized' ? (
          <UserInfo onClick={disconnect} />
        ) : (
          <S.StyledButton onClick={toggleProviderModal}>
            {t('Connect wallet')}
          </S.StyledButton>
        )}
      </S.BottomBlockWrapper>
    </S.Wrapper>
  )
}
