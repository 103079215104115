import { FCWithChildren } from 'types/app'
import * as S from './style'

type LinkProps = {
  onClick: () => void
}

export const Link: FCWithChildren<LinkProps> = ({ children, onClick }) => {
  const handleClick = (evt: any) => {
    evt.preventDefault()
    onClick()
  }

  return <S.Link onClick={handleClick}>{children}</S.Link>
}
