import { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { useConstructorContext } from '../../hooks'
import { useAutosizeTextArea } from './libs/hooks'
import * as S from './style'

type ConstructorAtomTextareaProps = {
  atomId?: string
  name: string
  placeholder: string
  disabled?: boolean
  editorProps?: Omit<HTMLTextAreaElement, 'name' | 'placeholder'>
}

export const ConstructorAtomTextarea: FC<ConstructorAtomTextareaProps> = (
  props
) => {
  const { atomId, name, placeholder, disabled = false } = props
  const { setFieldValue, getValue, constructorState } = useConstructorContext()

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      setFieldValue({
        atomId,
        name,
        value: evt.target.value,
      })
    },
    [atomId, name, setFieldValue]
  )

  const value = useMemo(() => {
    const atomTextareaValue = getValue({
      name,
      atomId,
      contractId: constructorState.currentContractId,
    })

    if (typeof atomTextareaValue === 'string') {
      return atomTextareaValue
    }

    return ''
  }, [atomId, constructorState.currentContractId, getValue, name])

  const textAreaRef = useAutosizeTextArea(value)

  return (
    <S.Textarea
      ref={textAreaRef}
      autoFocus
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
    />
  )
}
