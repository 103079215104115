import { forwardRef } from 'react'
import { createPortal } from 'react-dom'
import { SimpleLink } from '../../../../types'
import { MenuLink } from '../MenuLink/MenuLink'
import * as S from './style'

type SubmenuLinksProps = {
  sublinks: SimpleLink[]
  onMouseEnter: () => void
  onMouseLeave: () => void
  onClick: () => void
  $isSubmenuOpen: boolean
  top: number
  left: number
}

type SubmenuLinksRef = HTMLDivElement

export const SubmenuLinks = forwardRef<SubmenuLinksRef, SubmenuLinksProps>(
  (props, ref) => {
    return createPortal(
      <S.SubMenu {...props} ref={ref}>
        {props.sublinks.map((sublink) => {
          if (sublink.hidden) {
            return null
          }
          return (
            <MenuLink key={sublink.label} to={sublink.to}>
              {sublink.label}
            </MenuLink>
          )
        })}
      </S.SubMenu>,
      document.body
    )
  }
)
