import { rgba } from 'polished'
import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
`

export const PlateOption = styled(Card)<{ isSelected: boolean }>(
  ({ theme: { media, size, color }, isSelected }) => `
  width: 49%;
  display: flex;
  padding: 18px;
  align-items: center;
  gap: 12px;
  background-color: ${isSelected ? color.red : color.white};
  cursor: ${isSelected ? 'auto' : 'pointer'};
  transition: all 0.3s;
  border-radius: 12px;

  h6 {
    color: ${isSelected ? color.white : color.black};
  }

  span {
    color: ${isSelected ? color.white : rgba(color.black, 0.5)};
  }

  ${media(size.mobile)} {
    width: 100%;
  }
`
)

export const Title = styled.h6`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`

export const Label = styled.span`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`

export const SelectPoint = styled.div<{ isSelected: boolean }>`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.red : theme.color.white};
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `4px solid ${theme.color.white}`
      : `1px solid ${theme.color.line}`};
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
