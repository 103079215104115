import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlatformContactList } from 'api/omni/endpoints'
import { Loader } from 'ui/Loader/Loader'
import { Pagination } from 'ui/Pagination/Pagination'
import * as S from './style'

const ITEMS_ON_PAGE = 12

type SelectContactProps = {
  onAddressSelect: (address: string) => void
  onClose: () => void
}

export const SelectContact: FC<SelectContactProps> = (props) => {
  const { onAddressSelect, onClose } = props

  const [currentPage, setCurrentPage] = useState<number>(1)

  const { t } = useTranslation('modalContactsModal')

  const { data, isLoading, isSuccess } = usePlatformContactList({
    page: currentPage,
    size: ITEMS_ON_PAGE,
  })

  const handleContactClick = (address: string) => () => {
    onAddressSelect?.(address)
    onClose()
  }

  const totalPages = useMemo(() => {
    if (data?.results) {
      return Math.ceil(data.results.length / ITEMS_ON_PAGE)
    }
  }, [data])

  return (
    <S.Wrapper>
      <S.Body>
        {isLoading && <Loader />}
        {isSuccess &&
          (!data.results.length ? (
            <S.EmptyContactsList>
              {t("You don't have contacts")}
            </S.EmptyContactsList>
          ) : (
            <>
              {data.results.map(({ name, address }) => (
                <S.Contact key={name} onClick={handleContactClick(address)}>
                  <S.Content>
                    <S.Wallet>{name}</S.Wallet>
                  </S.Content>
                </S.Contact>
              ))}
              {totalPages && totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  onChangePage={(page) => setCurrentPage(page)}
                  totalPages={totalPages}
                />
              )}
            </>
          ))}
      </S.Body>
    </S.Wrapper>
  )
}
