import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { ThemeContext } from 'styled-components'

export const useMatchMedia = () => {
  const { size } = useContext(ThemeContext)

  const mediaQueryList = useMemo(
    () => ({
      desktop: window.matchMedia(`(min-width: ${size.tablet + 1}px)`),
      mobile: window.matchMedia(`(max-width: ${size.mobile}px)`),
      tablet: window.matchMedia(
        `(max-width: ${size.tablet}px) and (min-width: ${size.mobile + 1}px)`
      ),
    }),
    [size]
  )

  const getValue = useCallback(() => {
    return {
      isDesktop: mediaQueryList.desktop.matches,
      isMobile: mediaQueryList.mobile.matches,
      isTablet: mediaQueryList.tablet.matches,
    }
  }, [mediaQueryList])

  const [value, setValue] = useState(getValue)

  useLayoutEffect(() => {
    const handler = () => setValue(getValue)
    Object.values(mediaQueryList).forEach((mediaQuery) =>
      mediaQuery.addEventListener('change', handler)
    )
    return () =>
      Object.values(mediaQueryList).forEach((mediaQuery) =>
        mediaQuery.removeEventListener('change', handler)
      )
  }, [getValue, mediaQueryList])

  return value
}
