import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MenuLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  white-space: nowrap;
  font-size: 16px;
  color: ${({ theme }) => theme.color.comet};
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  border: 0;
  padding: 12px 20px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.black};
  }

  &:focus {
    outline: none;
  }
`
