import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgJunaToken = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="url(#paint0_linear_5373_17813)" height={16} rx={8} width={16} />
    <path
      d="M7.27955 11.816C6.10622 11.816 5.28489 11.3431 4.81555 10.3973L6.08489 9.67201C6.31955 10.1413 6.71777 10.376 7.27955 10.376C7.66355 10.376 7.95155 10.2871 8.14355 10.1093C8.34266 9.92446 8.44222 9.66846 8.44222 9.34135V4.20001H9.90355V9.34135C9.90355 10.1236 9.65822 10.7316 9.16755 11.1653C8.684 11.5991 8.05466 11.816 7.27955 11.816Z"
      fill="white"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_5373_17813"
        x1={8}
        x2={8}
        y1={0}
        y2={16}
      >
        <stop stopColor="#3A41EB" />
        <stop offset={1} stopColor="#D66FF6" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgJunaToken)
export default Memo
