import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNetwork, useSignTypedData } from 'wagmi'
import {
  useAtomAtomContractAcceptCreate,
  useAtomAtomContractRejectCreate,
} from 'api/omni/endpoints'
import { getForwarderAddress } from 'libs/addresses/addresses'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { Button } from 'ui/Button/Button'
import { Input } from 'ui/Input/Input'
import * as S from './style'

export const SignBlock: FC = () => {
  const [rejectCommentValue, setRejectCommentValue] = useState<string>('')

  const { contractId } = useParams()

  const { chain } = useNetwork()

  const { signTypedDataAsync } = useSignTypedData()

  const { mutate: accept } = useAtomAtomContractAcceptCreate()

  const { mutate: reject } = useAtomAtomContractRejectCreate()

  const handleRejectInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setRejectCommentValue(evt.target.value)
    },
    []
  )

  const handleAccept = async () => {
    if (!chain || !contractId) {
      return
    }

    const signed = await signTypedDataAsync({
      domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
      types: {
        Form: [],
      },
      value: {},
    })

    accept({
      id: contractId,
      data: {
        dataSig: signed,
        dataUnsig: {
          primaryType: 'Form',
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            Form: [],
          },
          message: {},
        },
      },
    })
  }

  const handleReject = () => {
    if (!contractId) {
      return
    }

    reject({
      id: contractId,
      data: {
        comment: rejectCommentValue,
      },
    })
  }

  return (
    <S.Wrapper>
      <Button onClick={handleAccept} variant="red">
        Sign and approve
      </Button>
      <S.Line />
      <S.FieldWrapper>
        <S.FieldLabel>Type reject comment</S.FieldLabel>
        <Input
          onChange={handleRejectInputChange}
          placeholder=".."
          value={rejectCommentValue}
        />
      </S.FieldWrapper>
      <S.RejectButton onClick={handleReject}>Reject</S.RejectButton>
    </S.Wrapper>
  )
}
