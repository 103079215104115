import { FC } from 'react'
import { LaunchpadRequest } from 'api/omni/model'
import { TenderCard } from './components'
import * as S from './style'

type TednersProps = {
  tenders: LaunchpadRequest[]
}

export const Tenders: FC<TednersProps> = (props) => {
  const { tenders } = props

  return (
    <S.Wrapper>
      {tenders.map(({ id, name, description }) => (
        <TenderCard key={id} description={description} id={id} name={name} />
      ))}
    </S.Wrapper>
  )
}
