import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Value = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.color.gray};
  font-weight: 400;
`

export const Label = styled.span`
  font-size: 32px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.gray};
  font-weight: 600;
`

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.black};
  margin-top: 7px;
`

export const Card = styled.div(
  ({ theme: { media, size, color } }) => `
  
  background-color: ${color.white};
  width: 100%;
  border: 1px solid ${color.line};
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }
  &:hover ${Title} {
    color: ${color.red};
  }

  ${media(size.tablet)} {
    padding: 16px;
  }
  `
)

export const StyledLink = styled(Link)`
  text-decoration: none;
`
