import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Contract } from 'api/omni/model'
import { Button } from 'ui/Button/Button'
import { InfoBlock } from '../InfoBlock/InfoBlock'
import * as S from './style'

type LoansProps = {
  loans: Contract[]
}

export const Loans: FC<LoansProps> = (props) => {
  const { loans } = props

  const { t } = useTranslation('pageMyLoans')

  const navigate = useNavigate()

  return (
    <S.Wrapper>
      <S.Title>{t('Loans')}</S.Title>
      <S.LoansWrapper>
        {loans.map(({ name, data, id }) => {
          const amount = data?.amountJuna as string

          return (
            <InfoBlock key={id} amount={amount} loanId={id} title={name!} />
          )
        })}
      </S.LoansWrapper>
      <Button onClick={() => navigate('/take-a-loan')} variant="red">
        {t('Take a loan')}
      </Button>
    </S.Wrapper>
  )
}
