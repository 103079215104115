import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 280px;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gallery};
  padding: 16px 24px;
`

export const Body = styled.div`
  width: 100%;
  padding: 24px 12px;
`

export const Provider = styled.a`
  width: 100%;
  display: block;
  font-size: 14px;
  cursor: pointer;
  padding: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.color.red};
    border-radius: 12px;
    color: ${({ theme }) => theme.color.white};
    box-shadow: 0px 2px 3px rgba(129, 129, 182, 0.25);
  }
`

export const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Wallet = styled.div`
  font-size: 15px;
  font-weight: 300;
`
