import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ChainId } from 'constants/blockchain'
import { Addresses } from 'constants/contracts'
import { useTokenBalance } from 'hooks'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import { toHumanNumber } from 'utils/toHumanNumber'
import { ContractCard } from './components/ContractCard/ContractCard'
import * as S from './style'

const ADDRESSES = {
  Founder: '0xd3ab056d1b2a582059b15b8505b1efa545260703',
}

export const Dashboard: FC = () => {
  const { t } = useTranslation('pageDashboard')
  const founder = useTokenBalance(
    Addresses.junaToken[ChainId.ETH],
    ADDRESSES.Founder,
    ChainId.ETH
  )

  return (
    <S.LaunchpadContainer>
      <S.Wrapper>
        <S.Title>{t('Dashboard')}</S.Title>
        <S.ItemsContainer>
          <ContractCard country="42" id={1} title="Users" />
          <ContractCard
            country={`${formatNumberWithSeparators(10000000)} JUNA`}
            id={2}
            title="Reservation System"
          />
          <ContractCard
            country={`${formatNumberWithSeparators(10000000)} JUNA`}
            id={3}
            title="Launchpad"
          />
          <ContractCard
            country={`${toHumanNumber(founder)} JUNA`}
            id={4}
            title="Founder Wallet"
          />
          <ContractCard country="0 JUNA" id={5} title="Bank" />
          <ContractCard country="2.5 %" id={5} title="System Interest Rate" />
          <ContractCard country="2.0 %" id={5} title="System Deposit Rate" />
          <ContractCard
            country="0.1"
            id={5}
            title="Reserve requirement ratio"
          />
          <ContractCard
            country="0.25"
            id={5}
            title="Reserve system loan ratio"
          />
          <ContractCard
            country="0.25"
            id={5}
            title="Reserve system coverage ratio"
          />
          <ContractCard
            country="0.1"
            id={5}
            title="Common Equity Ratio Treshold"
          />
        </S.ItemsContainer>
      </S.Wrapper>
    </S.LaunchpadContainer>
  )
}
