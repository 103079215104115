import { useReducer, useMemo, Reducer, useCallback } from 'react'
import { useNetwork } from 'wagmi'
import { generateUUID } from 'libs/constructor/context/utils/generateContractUUID'
import { ConstructorContextProps } from '../../ConstructorContext'
import { ConstructorState, ActionType, ContractType } from './types'

const createInitialState = (createParams: {
  contractId: string
  chainId: number
}): ConstructorState => ({
  contracts: [
    {
      name: '',
      id: createParams.contractId,
      parentId: null,
      chainId: createParams.chainId,
      atoms: [],
      variables: [],
      events: [],
      members: [],
      type: 'contract',
      workspaces: [],
    },
  ],
  currentContractId: createParams.contractId,
})

const reducer: Reducer<ConstructorState, ActionType> = (
  state,
  { type, payload }
) => {
  switch (type) {
    case 'loadContracts': {
      const { contracts, currentContractId } = payload

      return {
        contracts,
        currentContractId,
      }
    }
    case 'loadTemplateContracts':
      const { contracts, currentContractId } = payload
      let newCurrentContractId = currentContractId

      const newContracts = contracts.map((contract) => {
        const id = generateUUID()

        if (contract.id === currentContractId) {
          newCurrentContractId = id
        }

        const newAtoms = contract.atoms.map((atom) => {
          return {
            ...atom,
            id: generateUUID(),
          }
        })

        return {
          ...contract,
          atoms: newAtoms,
          id,
        }
      })

      return {
        contracts: newContracts,
        currentContractId: newCurrentContractId,
      }
    case 'setFieldValue':
      const { name, value, atomId, inputName, contractId } = payload

      //TODO: подумать как избежать тройного мапа ;)
      if (atomId) {
        const updatedContracts = state.contracts.map((contract) => {
          if (contract.id === contractId || state.currentContractId) {
            const updatedAtoms = contract.atoms.map((atom) => {
              if (atom.id === atomId) {
                const updatedInputs = atom.inputs.map((input) => {
                  switch (true) {
                    //если в пэйлоад был передан имя конкретного инпута, значит мы
                    //хотим задать значение вложенному инпуту
                    case inputName && inputName === input.name:
                      return {
                        ...input,
                        inputs: [
                          {
                            name,
                            value,
                            inputs: [],
                          },
                        ],
                      }
                    //устанавливаем значение инпуту верхнего уровня
                    case input.name === name:
                      return {
                        ...input,
                        value,
                      }
                    default:
                      return input
                  }
                })

                return {
                  ...atom,
                  inputs: updatedInputs,
                  //TODO: поправить после обновления на беке
                } as any
              }

              return atom
            })

            return {
              ...contract,
              atoms: updatedAtoms,
            }
          }

          return contract
        })

        return {
          ...state,
          contracts: updatedContracts,
        }
      }

      const updatedContracts = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          return {
            ...contract,
            [name]: value,
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: updatedContracts,
      }

    case 'createChildContract':
      const { childContractId, chainId } = payload

      const childContract: ContractType = {
        name: '',
        id: childContractId,
        parentId: state.currentContractId,
        chainId,
        atoms: [],
        variables: [],
        events: [],
        members: [],
        workspaces: [],
        type: 'contract',
      }

      return {
        ...state,
        contracts: [...state.contracts, childContract],
        currentContractId: childContractId,
      }
    case 'navigateToContract':
      const isContractExist = state.contracts.some(
        ({ id }) => id === payload.contractId
      )

      console.log(isContractExist)

      return {
        ...state,
        currentContractId: isContractExist
          ? payload.contractId
          : state.currentContractId,
      }
    case 'removeContract':
      const deletedContractIds: string[] = []

      const filteredContracts = state.contracts.filter((contract) => {
        if (
          contract.id === payload.contractId ||
          (contract.parentId && deletedContractIds.includes(contract.parentId))
        ) {
          deletedContractIds.push(contract.id)

          return false
        }

        return true
      })

      return {
        ...state,
        contracts: filteredContracts,
      }
    case 'addAtom':
      const { index: atomArrIndex, ...payloadAtomParams } = payload

      const contractsWithAddedAtom = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          const newAtomId = generateUUID()

          const getUpdatedAtoms = () => {
            const atoms = contract.atoms

            const newAtom = {
              id: newAtomId,
              ...payloadAtomParams,
            }

            if (atomArrIndex) {
              return [
                ...atoms.slice(0, atomArrIndex),
                newAtom,
                ...atoms.slice(atomArrIndex),
              ]
            }

            return [...atoms, newAtom]
          }

          if (payload.events) {
            const currentAtomEvents = payload.events.map((event) => ({
              ...event,
              atomId: newAtomId,
            }))

            return {
              ...contract,
              atoms: getUpdatedAtoms(),
              events: [...contract.events, ...currentAtomEvents],
            }
          }

          return {
            ...contract,
            atoms: getUpdatedAtoms(),
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithAddedAtom,
      }
    case 'setAtomValue':
      const contractsWithNewAtomValue = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          const updatedAtoms = contract.atoms.map((atom) => {
            if (atom.id === payload.atomId) {
              if (payload.inputName) {
                const updatedInputs = atom.inputs.map((input) => {
                  return input.name === payload.inputName
                    ? {
                        ...input,
                        [payload.name]: payload.value,
                      }
                    : input
                })

                return {
                  ...atom,
                  inputs: updatedInputs,
                }
              }

              return {
                ...atom,
                [payload.name]: payload.value,
              }
            }

            return atom
          })

          return {
            ...contract,
            atoms: updatedAtoms,
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithNewAtomValue,
      }
    case 'removeAtom':
      const contractsWithoutRemovedAtom = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          const currentEvent = contract.events.find(
            ({ atomId }: any) => atomId === payload.atomId
          )

          const currentAtomMember = contract.members?.find(
            ({ atomId }: any) => atomId === payload.atomId
          )

          const updatedAtoms = contract.atoms
            .filter(({ id }) => id !== payload.atomId)
            .map((atom) => {
              if (currentEvent) {
                return {
                  ...atom,
                  inputs: atom.inputs.map((input) => {
                    if (input.value === currentEvent.name) {
                      return { ...input, value: '' }
                    }

                    return {
                      ...input,
                      inputs: input.inputs!.map((nestedInput) => {
                        return {
                          ...nestedInput,
                          value: '',
                        }
                      }),
                    }
                  }),
                }
              }

              if (currentAtomMember) {
                return {
                  ...atom,
                  inputs: atom.inputs.map((input) => {
                    if (input.value === currentAtomMember.address) {
                      return { ...input, value: '' }
                    }

                    return {
                      ...input,
                      inputs: input.inputs!.map((nestedInput) => {
                        return {
                          ...nestedInput,
                          value: '',
                        }
                      }),
                    }
                  }),
                }
              }

              return atom
            })

          return {
            ...contract,
            atoms: updatedAtoms,
            events: contract.events.filter(
              // TODO: после обновления модели на беке перегенерировать слой
              // апи и удалить any
              ({ atomId }: any) => atomId !== payload.atomId
            ),
            members: contract.members?.filter(
              ({ atomId }: any) => atomId !== payload.atomId
            ),
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithoutRemovedAtom,
      }
    case 'createVariable':
      const contractsWithCreatedVariable = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          return {
            ...contract,
            variables: [
              ...contract.variables,
              {
                ...payload,
              },
            ],
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithCreatedVariable,
      }
    case 'changeVariableName':
      const contractsWithChangedVariables = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          return {
            ...contract,
            variables: contract.variables.map((variable) => {
              if (variable.ref === payload.variableRef) {
                return {
                  ...variable,
                  name: payload.value,
                }
              }

              return variable
            }),
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithChangedVariables,
      }
    case 'removeVariable':
      const contractsWithFilteredVariables = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          return {
            ...contract,
            variables: contract.variables.filter(
              ({ ref }) => ref !== payload.variableRef
            ),
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithFilteredVariables,
      }
    case 'changeContractsType':
      const templatedContracts: ContractType[] = state.contracts.map(
        (contract) => {
          return {
            ...contract,
            type: payload,
          }
        }
      )

      return {
        ...state,
        contracts: templatedContracts,
      }
    case 'addMember':
      const contractsWithAddedMember = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          if (contract.members) {
            const isMemberExist = contract.members.some(
              (member: any) => member.atomId === payload.atomId
            )

            const currentMembers = !isMemberExist
              ? [...contract.members, { ...payload }]
              : [...contract.members]

            return {
              ...contract,
              members: currentMembers,
            }
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithAddedMember,
      }
    case 'changeMemberValue':
      const contractsWithChangedMemberValue = state.contracts.map(
        (contract) => {
          if (contract.id === state.currentContractId) {
            return {
              ...contract,
              members: contract.members?.map((member: any) => {
                if (member.atomId === payload.atomId) {
                  return {
                    ...member,
                    address: payload.address,
                  }
                }

                return member
              }),
            }
          }

          return contract
        }
      )

      return {
        ...state,
        contracts: contractsWithChangedMemberValue,
      }
    case 'addWorkspace':
      const contractsWithAddedWorkspace = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId && contract.workspaces) {
          return {
            ...contract,
            workspaces: [...contract.workspaces, payload],
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithAddedWorkspace,
      }

    case 'removeWorkspace':
      const contractsWithRemovedWorkspace = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId && contract.workspaces) {
          return {
            ...contract,
            workspaces: contract.workspaces.filter(
              (workspace) => workspace !== payload
            ),
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithRemovedWorkspace,
      }
    case 'updateAtoms':
      const contractsWithUpdatedContracts = state.contracts.map((contract) => {
        if (contract.id === state.currentContractId) {
          return {
            ...contract,
            atoms: payload,
          }
        }

        return contract
      })

      return {
        ...state,
        contracts: contractsWithUpdatedContracts,
      }
  }
}

export const useConstructorState = () => {
  const { chain } = useNetwork()

  const [state, dispatch] = useReducer(
    reducer,
    createInitialState({
      contractId: generateUUID(),
      chainId: chain!.id,
    })
  )

  const stateMethods = useMemo(() => {
    const setFieldValue: ConstructorContextProps['setFieldValue'] = (
      payload
    ) => {
      dispatch({ type: 'setFieldValue', payload })
    }

    const navigateToContract: ConstructorContextProps['navigateToContract'] = (
      payload
    ) => {
      dispatch({ type: 'navigateToContract', payload })
    }

    const removeContract: ConstructorContextProps['removeContract'] = (
      payload
    ) => {
      dispatch({ type: 'removeContract', payload })
    }

    const addAtom: ConstructorContextProps['addAtom'] = (payload) => {
      dispatch({ type: 'addAtom', payload })
    }

    const removeAtom: ConstructorContextProps['removeAtom'] = (payload) => {
      dispatch({ type: 'removeAtom', payload })
    }

    const createVariable: ConstructorContextProps['createVariable'] = (
      payload
    ) => {
      dispatch({ type: 'createVariable', payload })
    }

    const changeVariableName: ConstructorContextProps['changeVariableName'] = (
      payload
    ) => {
      dispatch({ type: 'changeVariableName', payload })
    }

    const removeVariable: ConstructorContextProps['removeVariable'] = (
      payload
    ) => {
      dispatch({ type: 'removeVariable', payload })
    }

    const changeContractsType: ConstructorContextProps['changeContractsType'] =
      (payload) => {
        dispatch({ type: 'changeContractsType', payload })
      }

    const loadTemplateContracts: ConstructorContextProps['loadTemplateContracts'] =
      (payload) => {
        dispatch({ type: 'loadTemplateContracts', payload })
      }

    const addMember: ConstructorContextProps['addMember'] = (payload) => {
      dispatch({ type: 'addMember', payload })
    }

    const changeMemberValue: ConstructorContextProps['changeMemberValue'] = (
      payload
    ) => {
      dispatch({ type: 'changeMemberValue', payload })
    }

    const setAtomValue: ConstructorContextProps['setAtomValue'] = (payload) => {
      dispatch({ type: 'setAtomValue', payload })
    }

    const loadContracts: ConstructorContextProps['loadContracts'] = (
      payload
    ) => {
      dispatch({ type: 'loadContracts', payload })
    }

    const addWorkspace: ConstructorContextProps['addWorkspace'] = (payload) => {
      dispatch({ type: 'addWorkspace', payload })
    }

    const removeWorkspace: ConstructorContextProps['removeWorkspace'] = (
      payload
    ) => {
      dispatch({ type: 'removeWorkspace', payload })
    }

    const updateAtoms: ConstructorContextProps['updateAtoms'] = (payload) => {
      dispatch({ type: 'updateAtoms', payload })
    }

    return {
      setFieldValue,
      navigateToContract,
      removeContract,
      addAtom,
      removeAtom,
      createVariable,
      changeVariableName,
      removeVariable,
      changeContractsType,
      loadTemplateContracts,
      addMember,
      changeMemberValue,
      setAtomValue,
      loadContracts,
      addWorkspace,
      removeWorkspace,
      updateAtoms,
    }
  }, [])

  const createChildContract = useCallback(() => {
    const childContractId = generateUUID()

    if (!chain) {
      return ''
    }

    dispatch({
      type: 'createChildContract',
      payload: { childContractId, chainId: chain.id },
    })

    return childContractId
  }, [chain])

  const getContract = useCallback(
    (contractId: string) => {
      const currentContract = state.contracts.find(
        ({ id }) => contractId === id
      )

      return currentContract!
    },
    [state.contracts]
  )

  return {
    state,
    createChildContract,
    getContract,
    currentContract: getContract(state.currentContractId),
    ...stateMethods,
  }
}
