import { FC } from 'react'
import * as S from './style'

type RateCardProps = {
  rate: number
  name: string
}

export const RateCard: FC<RateCardProps> = (props) => {
  const { rate, name } = props

  return (
    <S.Wrapper>
      <S.Title>{rate}%</S.Title>
      <S.Description>{name}</S.Description>
    </S.Wrapper>
  )
}
