import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgActionContractCreatedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F6F9" height={40} rx={20} width={40} />
    <mask
      height={25}
      id="mask0_5575_16654"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={8}
      y={8}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={8} y={8.5} />
    </mask>
    <g mask="url(#mask0_5575_16654)">
      <path
        d="M17 17.5V15.5H26V17.5H17ZM17 20.5V18.5H26V20.5H17ZM20 30.5H14C13.1667 30.5 12.4583 30.2083 11.875 29.625C11.2917 29.0417 11 28.3333 11 27.5V24.5H14V10.5H29V19.525C28.6667 19.4917 28.3292 19.5042 27.9875 19.5625C27.6458 19.6208 27.3167 19.725 27 19.875V12.5H16V24.5H22L20 26.5H13V27.5C13 27.7833 13.0958 28.0208 13.2875 28.2125C13.4792 28.4042 13.7167 28.5 14 28.5H20V30.5ZM22 30.5V27.425L27.525 21.925C27.675 21.775 27.8417 21.6667 28.025 21.6C28.2083 21.5333 28.3917 21.5 28.575 21.5C28.775 21.5 28.9667 21.5375 29.15 21.6125C29.3333 21.6875 29.5 21.8 29.65 21.95L30.575 22.875C30.7083 23.025 30.8125 23.1917 30.8875 23.375C30.9625 23.5583 31 23.7417 31 23.925C31 24.1083 30.9667 24.2958 30.9 24.4875C30.8333 24.6792 30.725 24.85 30.575 25L25.075 30.5H22ZM23.5 29H24.45L27.475 25.95L27.025 25.475L26.55 25.025L23.5 28.05V29ZM27.025 25.475L26.55 25.025L27.475 25.95L27.025 25.475Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgActionContractCreatedIcon)
export default Memo
