import { ChangeEvent, FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/Button/Button'
import * as S from './style'

type ImageUploaderProps = {
  value?: string | null
  onChange: (file: File) => void
  name?: string
}

export const ImageUploader: FC<ImageUploaderProps> = (props) => {
  const { value, onChange, name } = props

  const { t } = useTranslation('featureImageUploader')

  const [uploadedImageURL, setUploadedImageURL] = useState<string>('')

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files === null) {
      return
    }

    const file = evt.target.files[0]

    onChange(file)

    const imageURL = URL.createObjectURL(file)

    setUploadedImageURL(imageURL)
  }

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }

  return (
    <S.Wrapper bgImage={uploadedImageURL || value}>
      <S.UploadBlock>
        <S.HiddenInput
          ref={hiddenFileInput}
          accept=".png,.jpeg"
          name={name}
          onChange={handleChange}
          type="file"
        />
        <Button onClick={handleClick} variant="red">
          {t('Upload image')}
        </Button>
      </S.UploadBlock>
    </S.Wrapper>
  )
}
