import { FC } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useMatchMedia } from 'hooks'
import { SvgUser } from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type CommentProps = {
  id: number
  address: string
  startedAt: string
  comment: string
  setReplyToId?: (value: number) => void
}

export const Comment: FC<CommentProps> = (props) => {
  const { id, address, startedAt, comment, setReplyToId } = props

  const { isMobile } = useMatchMedia()

  const { t } = useTranslation('pageLegalGate')

  return (
    <S.CommentWrapper>
      <S.CommentFlexWrapper>
        <SvgUser />
        <S.Content>
          <S.CommentHeader>
            <S.AutorAddress>
              {isMobile ? shortenAddress(address) : address}
            </S.AutorAddress>
            <S.StartedAt>{dayjs(startedAt).fromNow()}</S.StartedAt>
          </S.CommentHeader>
          <p>{comment}</p>
          {setReplyToId && (
            <S.ReplyButton onClick={() => setReplyToId(id)}>
              {t('Reply to')}
            </S.ReplyButton>
          )}
        </S.Content>
      </S.CommentFlexWrapper>
    </S.CommentWrapper>
  )
}
