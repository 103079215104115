import { VotingParametersGroup } from './typings'

export const votingParametersGroups: VotingParametersGroup[] = [
  {
    title: 'Reserve System',
    params: [
      {
        id: 'sysir',
        name: 'System Interest Rate',
        symbol: 'SYSIR',
      },
      {
        id: 'sysdr',
        name: 'System Deposit Rate',
        symbol: 'SYSDR',
      },
    ],
  },
  {
    title: 'System Bank & Balance Sheet',
    params: [
      {
        id: 'sbaf',
        name: 'System Bank Appear Factor',
        symbol: 'SBAF',
      },
    ],
  },
  {
    title: 'Reservation mechanics & capital adequacy',
    params: [
      {
        id: 'rr',
        name: 'Reserve requirement ratio',
        symbol: 'RR',
      },
      {
        id: 'syslr',
        name: 'Reserve system loan ratio',
        symbol: 'SYSLR',
      },
      {
        id: 'syscr',
        name: 'Reserve system coverage ratio',
        symbol: 'SYSCR',
      },
      {
        id: 'bm',
        name: 'Bank Multipicator',
        symbol: 'BM',
      },
      {
        id: 'bceT1T',
        name: 'Common Equity Ratio Treshold ',
        symbol: 'BCET1T',
      },
    ],
  },
]
