import { rgba } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media(size.tablet)} {
    flex-direction: column;
    gap: 12px;
  }
`
)

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`

export const AuthorAvatarBlock = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightGray};
  color: ${({ theme }) => theme.color.gray};
`

export const AuthorWrapper = styled(InfoWrapper)`
  gap: 12px;
`

export const AuthorInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Text = styled.span`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const StatusWrapper = styled(AuthorInfoWrapper)`
  gap: 6px;
`

export const Label = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.color.gray};
`

export const ProgressBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 424px;
  width: 100%;
`

export const ProgressTopWrappper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ProgressVotesCountWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

export const VotesChip = styled.div<{ isActive?: boolean }>`
  border-radius: 10px;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.red : rgba(theme.color.red, 0.4)};
  color: ${({ theme }) => theme.color.white};
`

export const ProgressBar = styled.div`
  width: 100%;
  border-radius: 12px;
  height: 18px;
  background-color: ${({ theme }) => rgba(theme.color.red, 0.4)};
`

export const ProgressLine = styled.div<{ width: number }>`
  width: 100%;
  border-radius: 12px;
  height: 18px;
  background-color: ${({ theme }) => theme.color.red};
  width: ${({ width }) => `${width}%`};
`
