import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import {
  usePlatformLegalgatecategoryList,
  usePlatformJurisdictionList,
  usePlatformLegalgateList,
} from 'api/omni/endpoints'
import { LegalGateCategory, Jurisdiction } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { Loader } from 'ui/Loader/Loader'
import { Overlay } from 'ui/Overlay/Overlay'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { LegalGateItem } from './components'
import * as S from './style'

const ITEMS_ON_PAGE = 10

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Legal Gates Catalog',
    url: AppRoutes.LegalGatesCatalog,
  },
]

type RatingSort = 'asc' | 'desc'

type RatingSortOption = {
  label: string
  value: RatingSort
}

export const LegalGatesCatalog = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentCategory, setCurrentCategory] =
    useState<LegalGateCategory | null>()
  const [currentJurisdiction, setCurrentJurisdiction] =
    useState<Jurisdiction | null>()
  const [currentRatingSort, setCurrentRatingSort] = useState<RatingSort>()

  const { t } = useTranslation('pageLegalGateCatalog')

  const ratingSortOptions: RatingSortOption[] = useMemo(
    () => [
      {
        label: t('Rating: lower first'),
        value: 'asc',
      },
      {
        label: t('Rating: highest first'),
        value: 'desc',
      },
    ],
    [t]
  )

  const RatingSorts = {
    asc: 'rating',
    desc: '-rating',
  } as const

  const navigate = useNavigate()

  const { data: categories, isLoading: isCategoriesLoading } =
    usePlatformLegalgatecategoryList()

  const { data: jurisdictions, isLoading: isJurisdictionLoading } =
    usePlatformJurisdictionList()

  const {
    data: legalGates,
    isLoading: isLegalGatesLoading,
    isSuccess: isLegalGatesSucess,
    isFetching: isLegalGatesFetching,
  } = usePlatformLegalgateList(
    {
      categoryId: currentCategory?.id,
      jurisdictionId: currentJurisdiction?.id,
      ordering: currentRatingSort && RatingSorts[currentRatingSort],
    },
    { query: { keepPreviousData: true } }
  )

  const handleCategoryChange = useCallback(
    (category: SingleValue<LegalGateCategory>) => setCurrentCategory(category),
    [setCurrentCategory]
  )

  const handleJurisdictionChange = useCallback(
    (jurisdiction: SingleValue<Jurisdiction>) =>
      setCurrentJurisdiction(jurisdiction),
    [setCurrentJurisdiction]
  )

  const handleResetAllClick = useCallback(() => {
    setCurrentCategory(null)
    setCurrentJurisdiction(null)
  }, [setCurrentCategory])

  const handleSignUpBtnClick = () => {
    navigate(AppRoutes.LegalGateAuth)
  }

  const handleRatingSortChange = useCallback(
    (ratingSort: SingleValue<RatingSortOption>) => {
      setCurrentRatingSort(ratingSort?.value)
    },
    []
  )

  if (isLegalGatesLoading) {
    return <Loader />
  }

  if (isLegalGatesSucess) {
    const totalPages = legalGates.results.length / ITEMS_ON_PAGE

    return (
      <PageWrapper
        actions={
          <Button onClick={handleSignUpBtnClick} variant="red">
            {t('Sign Up as Legal Gate')}
          </Button>
        }
        breadcrumbs={breadcrumbs}
        title={t('Legal Gates')}
      >
        <S.Wrapper>
          <S.SearchWrapper>
            <S.Select
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => `${option.id}`}
              isDisabled={isJurisdictionLoading}
              isLoading={isJurisdictionLoading}
              onChange={handleJurisdictionChange}
              options={jurisdictions}
              placeholder={t('Country')}
              value={currentJurisdiction}
            />
            <S.Select
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => `${option.id}`}
              isDisabled={isCategoriesLoading}
              isLoading={isCategoriesLoading}
              onChange={handleCategoryChange}
              options={categories}
              placeholder={t('Categories')}
              value={currentCategory}
            />
            <S.Button onClick={handleResetAllClick}>{t('Reset All')}</S.Button>
          </S.SearchWrapper>
          <S.ContentWrapper>
            {/* <S.SortWrapper>
                  <S.Label>{t('Sort')}:</S.Label>
                  <S.SortSelect />
                </S.SortWrapper>
              */}
            <S.RatingSelect
              onChange={handleRatingSortChange}
              options={ratingSortOptions}
              placeholder={t('Best match')}
            />
            <Overlay isLoading={isLegalGatesFetching}>
              <S.ItemsWrapper>
                {legalGates.results.map(
                  ({ id, name, description, rating, price, contracts }) => (
                    <LegalGateItem
                      key={id}
                      address={
                        contracts.length > 0 ? contracts[0].address : undefined
                      }
                      contracts={contracts}
                      description={description!}
                      id={id}
                      name={name}
                      price={price!}
                      rating={rating!}
                    />
                  )
                )}
              </S.ItemsWrapper>
              {totalPages && totalPages > 10 && (
                <S.PaginationWrapper>
                  <S.TotalItemsLabel>
                    {t('Total items', { totalPages })}
                  </S.TotalItemsLabel>
                  <Pagination
                    currentPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    totalPages={totalPages}
                  />
                </S.PaginationWrapper>
              )}
            </Overlay>
          </S.ContentWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
