import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`

export const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px 32px;
`

export const FormsWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 40px;
`
