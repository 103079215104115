import { getI18n } from 'react-i18next'
import { number, string, object } from 'yup'

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    email: string().email().required(t('validation.Required to fill out')),
    pin: number()
      .positive(t('validation.Number cannot be negative'))
      .required(t('validation.Required to fill out')),
  })
}
