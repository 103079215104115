import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { usePin } from 'hooks'
import { useAuthStatus } from 'hooks/useAuthStatus/useAuthStatus'
import { selectRoles } from 'store/user/user.selectors'
import { FCWithChildren } from 'types/app'
import { Roles } from 'types/roles'
import * as S from './style'

type TProtectedRoute = FCWithChildren<{
  roleGuard?: Roles[]
  routeToRedirect?: string
}>

export const ProtectedRoute: TProtectedRoute = (props) => {
  const { children, roleGuard, routeToRedirect = AppRoutes.Main } = props

  const { pinNeedsCheck } = usePin()

  const location = useLocation()

  const navigate = useNavigate()

  const { authStatus } = useAuthStatus()

  const roles: Roles[] = useSelector(selectRoles)

  if (authStatus !== 'authorized') {
    return <Navigate replace state={{ from: location }} to={AppRoutes.Auth} />
  }

  if (roleGuard) {
    if (!roles.some((role) => roleGuard.includes(role))) {
      navigate(routeToRedirect)
    }
  }

  if (pinNeedsCheck) {
    return (
      <S.PinGuardWrapper>
        <S.PinGuardContainer>
          <S.PinGuard />
        </S.PinGuardContainer>
      </S.PinGuardWrapper>
    )
  }

  return <>{children}</>
}
