import styled from 'styled-components'
import { Chip as UIChip } from 'ui/Chip/Chip'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  justify-content: space-between;

  ${media(size.tablet)} {
    flex-direction: column;
    gap: 12px;
  }
`
)

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Label = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`

export const Title = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.black};
  max-width: 280px;
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ParamRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ParamRowLabel = styled.span(
  ({ theme: { media, size, color } }) => `
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 193px;
  color: ${color.black};

  ${media(size.mobile)} {
    display: none;
  }
`
)

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 212px;
`

export const Chip = styled(UIChip)`
  min-width: 62px;
  width: 62px;
  border-radius: 6px;
`
