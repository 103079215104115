import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media(size.tablet)} {
    padding: 12px;
    gap: 12px;
  }
`
)

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const Button = styled(UIButton)(
  ({ theme: { media, size } }) => `
  width: max-content;
  margin-left: auto;

  ${media(size.mobile)} {
    width: 100%;
    margin: 0;
  }
`
)

export const VoteTakenButton = styled(UIButton)`
  width: 159px;
  padding: 6px 12px;
  font-weight: 700;
  font-size: 16px;
  background-color: #6f74ef;
  background: linear-gradient(91deg, #ca48f4 6.95%, #6f74ef 93.18%);
  margin-left: auto;

  &:disabled {
    border: 0;
    color: ${({ theme }) => theme.color.white};
    cursor: default;
  }

  svg path {
    fill: ${({ theme }) => theme.color.white};
  }
`
