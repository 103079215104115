import styled from 'styled-components'
import { Chip as UIChip } from 'ui/Chip/Chip'
import { SvgDownArrow } from 'ui/icons'

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.mirageLight};
`

export const DateWrapper = styled.div`
  width: 100px;
`

export const VotesWrapper = styled.div`
  width: 70px;
  margin-right: 36px;
`

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const Text = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.black};
`

export const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ParamValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Chip = styled(UIChip)`
  font-weight: 400;
  border-radius: 6px;
  color: ${({ theme }) => theme.color.black};
  width: 60px;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ResultChip = styled(UIChip)`
  width: 100px;
  border-radius: 6px;
`

export const SvgUpArrow = styled(SvgDownArrow)`
  transform: rotate(180deg);
`
