import { FC, useState, useCallback, useMemo, ChangeEvent } from 'react'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { type SearchFiltersType } from 'pages/Contracts/types'
import { shortenAddress } from 'utils/shortenAddress'
import { Filters, StatusSelectDropdown, AddressDropdown } from './components'
import * as S from './style'

type SearchBlockProps = {
  searchFilters: SearchFiltersType
  updateSearchFilters: (searchFilters: SearchFiltersType) => void
}

const FiltersLabelDictionary: Record<keyof SearchFiltersType, string> = {
  search: 'Search',
  status: 'Status',
  member: 'Member',
  legalGate: 'Legal Gate',
} as const

export const ContractStatusesDictionary: Record<
  AtomAtomContractListStatus,
  string
> = {
  draft: 'Draft',
  on_approval: 'On approval',
  on_deployment: 'On deployment',
  execution: 'Execution',
  finished: 'Completed',
} as const

export const SearchBlock: FC<SearchBlockProps> = (props) => {
  const { searchFilters, updateSearchFilters } = props

  const [currentDropdownFilterMode, setCurrentDropdownFilterMode] = useState<
    keyof SearchFiltersType | null
  >(null)

  const handleDeleteFilter = useCallback(
    (filter: keyof SearchFiltersType) => {
      updateSearchFilters({ ...searchFilters, [filter]: '' })

      setCurrentDropdownFilterMode(null)
    },
    [searchFilters, updateSearchFilters]
  )

  const handleInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = evt.target

      if (name === 'member' || name === 'legalGate') {
        if (!currentDropdownFilterMode) {
          return
        }

        if (!value) {
          handleDeleteFilter(currentDropdownFilterMode)

          return
        }
      }

      updateSearchFilters({
        ...searchFilters,
        [name]: value,
      })
    },
    [
      currentDropdownFilterMode,
      handleDeleteFilter,
      searchFilters,
      updateSearchFilters,
    ]
  )

  const handleSelectStatusFilter = useCallback(
    (status: AtomAtomContractListStatus) => {
      if (!currentDropdownFilterMode) {
        return
      }

      updateSearchFilters({
        ...searchFilters,
        status,
      })
    },
    [currentDropdownFilterMode, searchFilters, updateSearchFilters]
  )

  const currentDropdownContent = useMemo(() => {
    if (!currentDropdownFilterMode) {
      return null
    }

    const handleCloseDropdownContent = () => setCurrentDropdownFilterMode(null)

    if (currentDropdownFilterMode === 'status') {
      return (
        <StatusSelectDropdown
          onClose={handleCloseDropdownContent}
          onDelete={() => handleDeleteFilter(currentDropdownFilterMode)}
          onSelect={handleSelectStatusFilter}
        />
      )
    }

    if (
      currentDropdownFilterMode === 'member' ||
      currentDropdownFilterMode === 'legalGate'
    ) {
      return (
        <AddressDropdown
          addressType={
            currentDropdownFilterMode === 'member' ? 'member' : 'legalGate'
          }
          onChange={handleInputChange}
          onClose={handleCloseDropdownContent}
          onDelete={() => handleDeleteFilter(currentDropdownFilterMode)}
          value={
            currentDropdownFilterMode === 'member'
              ? searchFilters.member
              : searchFilters.legalGate
          }
        />
      )
    }
  }, [
    currentDropdownFilterMode,
    handleSelectStatusFilter,
    handleDeleteFilter,
    handleInputChange,
    searchFilters,
  ])

  return (
    <S.Wrapper>
      <S.InputContainer>
        {Object.entries(searchFilters)
          .map(([filterKey, filterValue]) => {
            if (!filterValue || filterKey === 'search') {
              return null
            }

            const currentFilterValue =
              filterKey === 'status'
                ? ContractStatusesDictionary[
                    filterValue as AtomAtomContractListStatus
                  ]
                : shortenAddress(filterValue)

            return (
              <S.FilterChipBlock key={filterKey}>
                {FiltersLabelDictionary[filterKey as keyof SearchFiltersType]}:{' '}
                {currentFilterValue}
              </S.FilterChipBlock>
            )
          })
          .reverse()}
        <S.Input
          name="search"
          onChange={handleInputChange}
          placeholder="Find contracts"
        />
        {currentDropdownContent}
      </S.InputContainer>
      <Filters
        onClick={setCurrentDropdownFilterMode}
        searchFilters={searchFilters}
      />
    </S.Wrapper>
  )
}
