import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthContext } from 'context'
import { NetworkModal, useNetworkModal } from 'modals/NetworkModal'
import { ProviderModal, useProviderModal } from 'modals/ProviderModal'
import { Loader } from 'ui/Loader/Loader'
import { Footer, Header } from './components'
import * as S from './style'

declare global {
  interface Window {
    isFooterHidden: boolean
  }
}

export const Layout: FC = () => {
  const { isProviderModalVisible, toggleProviderModal } = useProviderModal()

  const { isNetworkModalVisible, toggleNetworkModal } = useNetworkModal()

  const { isWalletConnecting } = useAuthContext()

  return (
    <>
      <S.Wrapper>
        <Header />
        <S.ContentWrapper>
          {isWalletConnecting ? <Loader /> : <Outlet />}
        </S.ContentWrapper>
        {!window.isFooterHidden && <Footer />}
      </S.Wrapper>
      {isProviderModalVisible && (
        <ProviderModal onClose={toggleProviderModal} />
      )}
      {isNetworkModalVisible && <NetworkModal onClose={toggleNetworkModal} />}
    </>
  )
}
