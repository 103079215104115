import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Card as UICard } from 'ui/Card/Card'

export const Card = styled(UICard)`
  height: 381px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.white};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 16px 24px;
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
`

export const Image = styled.img`
  flex-shrink: 0;
  width: 100%;
`

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  ${media(size.mobile)} {
    flex-direction: column;
    text-align: center;
  }
`
)

export const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const TextBlock = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`

export const Title = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Description = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 131%;
  color: ${({ theme }) => theme.color.gray};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Label = styled.span<{ isBold?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  font-size: ${({ isBold }) => (isBold ? '18px' : '14px')};
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  padding: ${({ isBold }) => (!isBold ? '4px 10px' : 0)};
  border-radius: ${({ isBold }) => (!isBold ? '6px' : 0)};
  background: ${({ isBold, theme }) =>
    !isBold ? theme.color.lightGray : 'transparent'}; ;
`
