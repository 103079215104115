import { rgba } from 'polished'
import styled, { DefaultTheme, keyframes } from 'styled-components'
import { Status } from './types'

const getCurrentStatusColor = (theme: DefaultTheme, status: Status) => {
  switch (status) {
    case 'active':
      return theme.color.green
    case 'pending':
      return theme.color.orange
  }
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const Label = styled.span<{ status: Status }>`
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  color: ${({ status, theme }) => getCurrentStatusColor(theme, status)};
`

export const glowing = keyframes`
  0% {
    transform: scale(1);
    opacity: .25
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: .25;
  }
`

export const Circle = styled.div<{ status: Status }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme, status }) =>
    rgba(getCurrentStatusColor(theme, status), 0.26)};
  animation: ${glowing} 2s linear infinite;
`
