import { FC } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormInput } from 'features/FormFileds'
import * as S from './styles'
import { getValidationSchema } from './validationSchema'

type FormValues = {
  email: string
  pin: string
}

type PinSetupFormProps = {
  isLoading: boolean
  onSubmit: (values: FormValues) => void
}

const initialValues: FormValues = {
  email: '',
  pin: '',
}

export const PinSetupForm: FC<PinSetupFormProps> = (props) => {
  const { isLoading, onSubmit } = props

  const { t } = useTranslation('pageSettings')

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={getValidationSchema()}
    >
      {({ handleSubmit }) => (
        <S.Wrapper>
          <S.Label>{t('PIN is not active')}</S.Label>
          <S.Form onSubmit={handleSubmit}>
            <FormInput name="email" placeholder={t('Email')} />
            <FormInput name="pin" placeholder={t('PIN code')} />
            <S.Button isLoading={isLoading} type="submit" variant="red">
              {t('Set PIN code')}
            </S.Button>
          </S.Form>
        </S.Wrapper>
      )}
    </Formik>
  )
}
