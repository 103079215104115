import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import * as S from './style'

type ErrorBlockType = {
  message: string
}

export const ErrorTextBlock = (props: ErrorBlockType) => {
  const { message } = props

  const navigate = useNavigate()

  const handleBackBtnClick = () => {
    navigate(AppRoutes.Catalog)
  }

  return (
    <S.ErrorTextMock>
      <S.Title>ERROR</S.Title>
      <S.Message>{message}</S.Message>
      <S.Button onClick={handleBackBtnClick} variant="red">
        Back To Catalog
      </S.Button>
    </S.ErrorTextMock>
  )
}
