import { ReactElement } from 'react'
import { theme } from 'assets/style/theme'
import { SvgDanger, SvgInfo, SvgSuccessfully } from 'ui/icons'
import { ToastType } from './types'

export const ToastIcon: Record<ToastType, ReactElement> = {
  error: <SvgDanger />,
  info: <SvgInfo />,
  success: <SvgSuccessfully />,
} as const

export const ToastColor: Record<ToastType, string> = {
  error: theme.color.red,
  info: theme.color.blue,
  success: theme.color.green,
} as const
