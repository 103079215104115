import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.bg};
  }
`

export const Label = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black};
`
