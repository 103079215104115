import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StoreNameSpace } from 'constants/app'
import { AuthStatus } from 'types/app'

type InitialState = {
  authStatus: AuthStatus
}

const initialState: InitialState = {
  authStatus: 'not-authorized',
}

export const authorizationSlice = createSlice({
  name: StoreNameSpace.Authorization,
  initialState,
  reducers: {
    setAuthStatus: (state, { payload }: PayloadAction<AuthStatus>) => {
      state.authStatus = payload
    },
  },
})

export const { setAuthStatus } = authorizationSlice.actions
