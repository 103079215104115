import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 40px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 12px;
`

export const Title = styled.h5`
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Label = styled.span<{ isBold?: boolean }>`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ isBold }) => (isBold ? '18px' : '16px')};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
`

export const AdHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BottomItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
