import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const StyledCard = styled(Card)(
  ({ theme: { media, size } }) => `
    padding: 24px;
    
    ${media(size.tablet)} {
      margin-bottom: 0;
    }
`
)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const DetailsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const LegalGateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const LegalGateDescripion = styled.p`
  margin: 4px 0px 16px 0px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
`
