export type SignatureMock = {
  name: string
  wallet: string
  status: 'expected' | 'signed' | 'refused'
}

export const signaturesMock: SignatureMock[] = [
  {
    name: 'Иван Иванов',
    wallet: '0х0003...3141',
    status: 'signed',
  },
  {
    name: 'Иван Иванов',
    wallet: '0х0003...2341',
    status: 'expected',
  },
  {
    name: 'Иван Иванов',
    wallet: '0х0003...3541',
    status: 'refused',
  },
  {
    name: 'Иван Иванов',
    wallet: '0х0003...3641',
    status: 'signed',
  },
]

export const milestoneListMock = [
  {
    label: 'Оплата',
    details: ['Внесение средств в контракт – Покупатель'],
  },
  {
    label: 'Доставка',
    details: [
      'Подтверждение передачи груза – Продавец',
      'Подтверждение страхования груза – Продавец',
    ],
  },
  {
    label: 'Получение',
    details: [
      'Подтверждение доставки груза – Legal oracle ',
      'Подтверждение получения груза – Покупатель',
    ],
  },
  {
    label: 'Завершение',
    details: [
      'Сторона А не имеет притензий – Продавец',
      'Сторона Б не имеет притензий – Покупатель',
      'Юрист подтверждает исполнение контракта – Legal oracle ',
    ],
  },
]

export const detailsMock = {
  title: 'Название контракта',
  description:
    'Впишите в поле слово “Доставлен”, если служба доставки сообщила о прибытии заказа к месту выдачи. Впишите в поле слово “Доставлен”, если служба доставки сообщила о прибытии заказа к месту выдачи. Впишите в поле слово “Доставлен”, если служба доставки сообщила о прибытии заказа к месту выдачи',
  legalGateDescription:
    'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.',
  details: [
    [
      {
        label: 'Имя продавца',
        value: 'Иван Иванов',
      },
      {
        label: 'Адрес продавца',
        value: 'Россия, г. Новосибирск, ул. Красный',
      },
    ],
    [
      {
        label: 'Имя покупателя',
        value: 'Иван Иванов',
      },
      {
        label: 'Адрес покупателя',
        value: 'Россия, г. Новосибирск, ул. Красный',
      },
    ],
    [
      {
        label: 'Адрес назначения платежа',
        value: '0x9995b3b0a62a1e823ed314ba5aef8318bf28413f',
      },
    ],
    [
      {
        label: 'Сумма контракта в токенах Juna',
        value: '1000',
      },
      {
        label: 'In USD',
        value: '23 500 $',
      },
    ],
    [
      {
        label: 'Хеш документ в сети IPFS',
        value: '5dsgvJGnvAfiR3K6HCBc4hcokSfmjj',
      },
      {
        label: 'Country',
        value: 'United Kingdom',
      },
    ],
  ],
  legalGate: [
    {
      label: 'Компания',
      value: 'KPMG',
    },
    {
      label: 'Сумма вознаграждения',
      value: 'United Kingdom',
    },
    {
      label: 'In USD',
      value: '123 $',
    },
  ],
}
