import * as S from './style'

type DetailsItemProps = {
  details: {
    label: string
    value: string
  }[]
}

export const DetailsItem = (props: DetailsItemProps) => {
  const { details } = props

  return (
    <S.DetailsWrapper>
      {details.map(({ label, value }) => (
        <S.DetailWrapper key={label}>
          <S.Label>{label}</S.Label>
          <S.DashedLine />
          <S.Label isBold>{value}</S.Label>
        </S.DetailWrapper>
      ))}
    </S.DetailsWrapper>
  )
}
