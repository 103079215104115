import { FC } from 'react'
import { UserInfo } from '../../types/userInfo'
import * as S from './style'

type UserAddressDropdownListProps = {
  usersInfo: UserInfo[]
  onSelect: (userInfo: UserInfo) => void
}

export const UserAddressDropdownList: FC<UserAddressDropdownListProps> = (
  props
) => {
  const { usersInfo, onSelect } = props

  return (
    <S.Wrapper>
      <S.Label>Select address of user</S.Label>
      {usersInfo.map((userInfo) => (
        <S.Option key={userInfo.id} onClick={() => onSelect(userInfo)}>
          {userInfo.label}: {userInfo.address}
        </S.Option>
      ))}
    </S.Wrapper>
  )
}
