import { getI18n } from 'react-i18next'
import { string, object } from 'yup'

const addressRegex = /^0x[a-fA-F0-9]{40}$/

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    contractAddress: string()
      //@ts-ignore
      .required(t('validation.Enter wallet address'))
      .matches(addressRegex, t('validation.Invalid wallet address')),
    action: string().required(t('validation.Required to fill out')),
  })
}
