import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgStatsReceived = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="white" height={24} rx={8} width={24} />
    <mask
      height={24}
      id="mask0_6437_19113"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={24} width={24} />
    </mask>
    <g mask="url(#mask0_6437_19113)">
      <path
        d="M12 15.2L15.2 12L14.08 10.88L12.8 12.16V8.8H11.2V12.16L9.92 10.88L8.8 12L12 15.2ZM12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20Z"
        fill="#4D9BFF"
      />
    </g>
  </svg>
)
const Memo = memo(SvgStatsReceived)
export default Memo
