import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 398px;
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.color.lightGray};
  border-radius: 12px;
`

export const List = styled.div`
  max-height: 200px;
  min-height: 100px;
  position: relative;
  padding-right: 12px;
  overflow-y: scroll;
  scrollbar-color: ${({ theme }) => theme.color.lightGray};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.lightGray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.line};
    border-radius: 4px;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  margin-bottom: 5px;
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
  text-align: left;
`
