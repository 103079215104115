import { FC, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate, createSearchParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import {
  usePlatformAdsRetrieve,
  usePlatformAdsCategoryList,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { SvgAdBackArrow, SvgJunaToken } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import MOCK_AD_IMAGE from './assets/mockAdImage.png'
import { InfoBlock } from './components'
import * as S from './style'

export const Ad: FC = () => {
  const { t } = useTranslation('pageAd')

  const { address } = useAccount()

  const { adId } = useParams()

  const navigate = useNavigate()

  const {
    data: ad,
    isLoading: isAdLoading,
    isSuccess: isAdSuccess,
  } = usePlatformAdsRetrieve(Number(adId))

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = usePlatformAdsCategoryList()

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Marketplace',
        url: AppRoutes.Marketplace,
      },
    ]

    if (ad) {
      return [
        ...initialBreadcrumbs,
        {
          label: ad.name,
          url: `${AppRoutes.Marketplace}/${ad.id}`,
        },
      ]
    }

    return initialBreadcrumbs
  }, [ad])

  const hadnleMakeADealClick = useCallback(() => {
    if (adId) {
      navigate({
        pathname: AppRoutes.SimpleV5,
        search: `${createSearchParams({ adId })}`,
      })
    }
  }, [adId, navigate])

  const handleOnEditClick = useCallback(() => {
    if (adId) {
      navigate({
        pathname: `${AppRoutes.Marketplace}/${adId}/edit`,
      })
    }
  }, [adId, navigate])

  const isAuthor = ad?.address === address?.toLowerCase()

  if (isAdLoading || isCategoriesLoading) {
    return <Loader />
  }

  if (isAdSuccess && isCategoriesSuccess) {
    const currentCategory = categories.find(
      (category) => category.id === ad.category
    )

    console.log(Boolean(ad.image))

    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={ad.name}>
        <S.Wrapper>
          <S.BackButton onClick={() => navigate(AppRoutes.Marketplace)}>
            <SvgAdBackArrow /> {t('Back to marketplace')}
          </S.BackButton>
          <S.ContentWrapper>
            <S.ColumnWrapper>
              <S.Image
                alt="ad_image"
                isMock={!Boolean(ad.image)}
                src={ad.image || MOCK_AD_IMAGE}
              />
              {isAuthor ? (
                <S.Button onClick={handleOnEditClick} variant="red">
                  {t('Edit ad')}
                </S.Button>
              ) : (
                <S.Button onClick={hadnleMakeADealClick} variant="red">
                  {t('Make a deal')} <SvgJunaToken /> {ad.amountjuna}
                </S.Button>
              )}
            </S.ColumnWrapper>

            <S.ColumnWrapper>
              <InfoBlock
                address={ad.address}
                amount={ad.amountjuna}
                category={currentCategory?.name}
                description={ad.description}
                title={ad.name}
              />
            </S.ColumnWrapper>
          </S.ContentWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
