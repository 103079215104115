import { FC, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlatformContactList } from 'api/omni/endpoints'
import { type Contact } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { ContactsForm } from './components'
import * as S from './style'
// import { Contact } from './types'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'My contacts',
    url: AppRoutes.Settings,
  },
]

const ITEMS_ON_PAGE = 12

export const MyContacts: FC = () => {
  const [selectedContact, setSelectedContact] = useState<Contact>()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { t } = useTranslation('pageMyContracts')

  const { data, isLoading, isSuccess } = usePlatformContactList({
    page: currentPage,
    size: ITEMS_ON_PAGE,
  })

  const handleContactClick = useCallback(
    (contact: Contact) => () => {
      setSelectedContact(contact)
    },
    [setSelectedContact]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    const totalPages = Math.ceil(data.results.length / ITEMS_ON_PAGE)

    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('My contacts')}>
        <S.Wrapper>
          <S.MenuWrapper>
            {data.results.length > 0 ? (
              data.results.map((contact) => (
                <S.StyledButton
                  onClick={handleContactClick(contact)}
                  variant="transparent"
                >
                  {contact.name}
                </S.StyledButton>
              ))
            ) : (
              <S.NotFoundText>{t('No contracts')}</S.NotFoundText>
            )}
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                totalPages={totalPages}
              />
            )}
          </S.MenuWrapper>
          {selectedContact && (
            <ContactsForm selectedContact={selectedContact} />
          )}
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
