import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  padding: 24px;
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled.h4`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Button = styled(UIButton)`
  max-width: 125px;
`
