import styled from 'styled-components'
import { default as UIContainer } from 'ui/Container/Container'

export const Container = styled(UIContainer)`
  padding-top: 32px;
  flex-direction: column;
`

export const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 32px;
  margin-top: 32px;
  ${({ theme: { media, size } }) => media(size.tablet)} {
    grid-template-columns: 100%;
    justify-content: normal;
    gap: 24px;
  }
`
