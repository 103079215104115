import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Proposal } from 'api/omni/model'
import { votingParametersGroups } from 'pages/Votings/constants'
import { Chip } from 'ui/Chip/Chip'
import { SvgCheckCircle } from 'ui/icons'
import { VotingParam, VotingHeader } from './components'
import * as S from './style'

export type VotingCardProps = {
  voting: Proposal
  handleVoteClick: (id: string) => void
}

export const VotingCard: FC<VotingCardProps> = (props) => {
  const { voting, handleVoteClick } = props
  const [isVoteTaken, setIsVoteTaken] = useState<boolean>(voting.isVoted)

  const { t } = useTranslation('pageProposals')

  const handleAddVoteClick = () => {
    handleVoteClick(voting.id)
    setIsVoteTaken(voting.isVoted)
  }

  return (
    <S.Wrapper>
      <Chip variant="gray">{voting.startDate}</Chip>
      <VotingHeader
        address={voting.address}
        name={voting.name}
        status={voting.status}
        votesCount={voting.votesCount}
      />
      {votingParametersGroups.map((paramGroup, idx) => (
        <Fragment key={idx}>
          <VotingParam paramGroup={paramGroup} value={voting.params} />
          <S.Line />
        </Fragment>
      ))}
      {isVoteTaken ? (
        <S.VoteTakenButton disabled>
          <SvgCheckCircle /> {t('Vote is taken')}
        </S.VoteTakenButton>
      ) : (
        <S.Button onClick={handleAddVoteClick} variant="red">
          {t('Vote for')}
        </S.Button>
      )}
    </S.Wrapper>
  )
}
