import { FC, Fragment, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormValues } from '../../VotingCreate'
import { VotingParam } from './components'
import * as S from './style'

export const VotingCreateFormBody: FC = () => {
  const { t } = useTranslation('pageAddVoting')

  const { handleSubmit } = useFormikContext<FormValues>()

  const mockParams = useMemo(
    () => [
      {
        title: t('Reserve System'),
        params: [
          {
            name: 'System Interest Rate',
            symbol: 'SYSIR',
            value: '0.025',
          },
          {
            name: 'System Deposit Rate',
            symbol: 'SYSDR',
            value: '0.025',
          },
        ],
      },
      {
        title: t('System Bank & Balance Sheet'),
        params: [
          {
            name: 'System Bank Appear Factor',
            symbol: 'SBAF',
            value: '1300',
          },
        ],
      },
      {
        title: t('Reservation mechanics & capital adequacy'),
        params: [
          {
            name: 'Reserve requirement ratio',
            symbol: 'RR',
            value: '0.100',
          },
          {
            name: 'Reserve system loan ratio',
            symbol: 'SYSLR',
            value: '0.250',
          },
          {
            name: 'Reserve system coverage ratio',
            symbol: 'SYSCR',
            value: '0.250',
          },
          {
            name: 'Bank Multipicator',
            symbol: 'BM',
            value: '0.500',
          },
          {
            name: 'Common Equity Ratio Treshold ',
            symbol: 'BCET1T',
            value: '0.100',
          },
        ],
      },
    ],
    [t]
  )

  return (
    <S.Wrapper>
      {mockParams.map((param, idx) => (
        <Fragment key={idx}>
          <VotingParam params={param.params} title={param.title} />
          <S.Line />
        </Fragment>
      ))}
      <S.Button onClick={handleSubmit} variant="red">
        {t('Send proposal')}
      </S.Button>
    </S.Wrapper>
  )
}
