import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  min-width: 280px;
  width: 280px;
  gap: 2px;
`

export const WorkspaceItem = styled.div<{ isActive?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.black};
  padding: 12px;
  border-radius: 8px;
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  transition: background-color 0.3s;
  background-color: ${({ isActive, theme }) => isActive && theme.color.bg};

  &:hover {
    background-color: ${({ theme }) => theme.color.bg};
  }
`
