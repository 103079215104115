import { FCWithChildren } from 'types/app'
import * as S from './style'

type FieldWrapperProps = {
  className?: string
}

export const FieldWrapper: FCWithChildren<FieldWrapperProps> = (props) => {
  const { children, className } = props

  return <S.Wrapper className={className}>{children}</S.Wrapper>
}
