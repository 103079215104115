import styled from 'styled-components'
import Container from 'ui/Container/Container'

export const LaunchpadContainer = styled(Container)(
  ({ theme: { media, size } }) => `
  padding: 66px 150px;

  ${media(size.tablet)} {
    padding: 24px 16px;
  }
`
)

export const Wrapper = styled.div`
  margin-top: 44px;
`

export const Title = styled.div`
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 32px;
`

export const ItemsContainer = styled.div(
  ({ theme: { media, size } }) => `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;


  ${media(size.tablet)} {
    grid-template-columns: repeat(1, 1fr);
  }
  `
)
