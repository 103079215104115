import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAddressAtomVariable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={14}
    viewBox="0 0 14 14"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8 1C8 0.447715 8.44772 0 9 0H13C13.5523 0 14 0.447715 14 1V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5V3.41421L6.37411 9.04011C5.98358 9.43063 5.35042 9.43063 4.95989 9.04011C4.56937 8.64958 4.56937 8.01642 4.95989 7.62589L10.5858 2H9C8.44772 2 8 1.55228 8 1ZM2.333 4C2.24468 4 2.15998 4.03508 2.09753 4.09753C2.03508 4.15998 2 4.24468 2 4.333V11.6665C2.00014 11.7549 2.03531 11.8397 2.09783 11.9022C2.1604 11.9648 2.24525 11.9999 2.33375 12H9.66625C9.75475 11.9999 9.8396 11.9648 9.90217 11.9022C9.96475 11.8396 9.99993 11.7547 10 11.6663V7.667C10 7.11472 10.4477 6.667 11 6.667C11.5523 6.667 12 7.11472 12 7.667V11.667C11.9995 12.2854 11.7537 12.8791 11.3164 13.3164C10.8791 13.7537 10.2862 13.9995 9.66775 14H2.333C1.71459 13.9995 1.12089 13.7537 0.683612 13.3164C0.246331 12.8791 0.000463665 12.2862 2.98023e-07 11.6677L0 4.333C0 3.71425 0.245797 3.12084 0.68332 2.68332C1.12084 2.2458 1.71425 2 2.333 2H6.333C6.88528 2 7.333 2.44772 7.333 3C7.333 3.55228 6.88528 4 6.333 4H2.333Z"
      fill="#1B1F3B"
      fillOpacity={0.2}
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgAddressAtomVariable)
export default Memo
