import { FC } from 'react'
import { SvgLanguage, SvgDownArrow } from 'ui/icons'
import * as S from './style'

const LANGUAGE = 'ENG'

type LanguageButtonProps = {
  className?: string
}

export const LanguageButton: FC<LanguageButtonProps> = (props) => {
  const { className } = props

  return (
    <S.Wrapper className={className}>
      <S.LanguageLabelWrapper>
        <SvgLanguage />
        <S.Label>{LANGUAGE}</S.Label>
      </S.LanguageLabelWrapper>
      <SvgDownArrow />
    </S.Wrapper>
  )
}
