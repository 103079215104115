import { FCWithChildren } from 'types/app'
import * as S from './style'

type ContainerProps = {
  className?: string
}

const Container: FCWithChildren<ContainerProps> = (props) => {
  const { children, className } = props

  return <S.Wrapper className={className}>{children}</S.Wrapper>
}

export default Container
