import { getI18n } from 'react-i18next'
import { number, string, object, AnySchema, array } from 'yup'
import { DynamicFieldType } from 'pages/ContractCreate/types'

const addressRegex = /^0x[a-fA-F0-9]{40}$/

type DynamicFieldsType = {
  [key: string]: unknown | any[]
}

export const getValidationSchema = (dynamicFields: DynamicFieldsType) => {
  const { t } = getI18n()

  const schema: Record<string, AnySchema> = {
    amountJuna: number()
      .required(t('validation.Required to fill out'))
      .positive(t('validation.Number cannot be negative')),
    name: string().required(t('validation.Required to fill out')),
    description: string().required(t('validation.Required to fill out')),
    members: array()
      .of(
        object().shape({
          name: string().required(t('validation.Required to fill out')),
          address: string()
            .required(t('validation.Required to fill out'))
            .matches(addressRegex, t('validation.Invalid wallet address')),
        })
      )
      .required(t('validation.Required to fill out')),
  }

  Object.entries(dynamicFields).forEach(([fieldName, fieldData]) => {
    const { type } = fieldData as DynamicFieldType

    switch (type) {
      case 'string':
        schema[fieldName] = string().required(
          t('validation.Required to fill out')
        )
        break
      case 'number':
        schema[fieldName] = number()
          .required(t('validation.Required to fill out'))
          .positive(t('validation.Number cannot be negative'))
        break
      case 'address':
        schema[fieldName] = string()
          .required(t('validation.Required to fill out'))
          .matches(addressRegex, t('validation.Invalid wallet address'))
        break
      default:
        break
    }
  })

  return object().shape(schema)
}
