import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AppRoutes } from 'constants/app'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { DepositForm, InfoBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Finance',
    url: AppRoutes.Finance,
  },
  {
    label: 'Create Deposit',
    url: AppRoutes.DepositCreate,
  },
]

export const DepositCreate: FC = () => {
  const { t } = useTranslation('pageDepositCreate')

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={t('Deposit contract')}>
      <S.Wrapper>
        <DepositForm />
        <InfoBlock />
      </S.Wrapper>
    </PageWrapper>
  )
}
