import { FC, useMemo } from 'react'
import * as S from './style'
import { ProgressBlockType } from './types'

type ProgressBlockProps = {
  progressBlockType: ProgressBlockType
  amount: number
  maxAmount: number
}

export const ProgressBlock: FC<ProgressBlockProps> = (props) => {
  const { progressBlockType, amount, maxAmount } = props

  const progressPercent = useMemo(() => {
    return Math.floor(amount * 100) / maxAmount
  }, [amount, maxAmount])

  return (
    <S.Wrapper>
      <S.InfoBlocksWrapper>
        <S.InfoBlock>
          <S.Label>{progressBlockType === 'bcet1' ? 'BCET1' : 'BRR'}</S.Label>
          <S.StatChip isActive progressBlockType={progressBlockType}>
            {maxAmount}
          </S.StatChip>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Label>{progressBlockType === 'bcet1' ? 'BCET1T' : 'CBRR'}</S.Label>
          <S.StatChip progressBlockType={progressBlockType}>
            {amount}
          </S.StatChip>
        </S.InfoBlock>
      </S.InfoBlocksWrapper>
      <S.ProgressBar progressBlockType={progressBlockType}>
        <S.ProgressLine progressPercent={progressPercent} />
      </S.ProgressBar>
    </S.Wrapper>
  )
}
