import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Workspace } from 'api/omni/model'
import * as S from './style'

type WorkspacesListProps = {
  workspaces: Workspace[]
  activeWorkspace: Workspace | null
  onClick: (workspace: Workspace | null) => void
}

export const WorkspacesList: FC<WorkspacesListProps> = (props) => {
  const { workspaces, onClick, activeWorkspace } = props

  const { t } = useTranslation('pageContracts')

  return (
    <S.Wrapper>
      <S.WorkspaceItem
        isActive={!activeWorkspace}
        onClick={() => onClick(null)}
      >
        {t('All')}
      </S.WorkspaceItem>
      {workspaces.map((workspace) => (
        <S.WorkspaceItem
          key={workspace.id}
          isActive={activeWorkspace?.id === workspace.id}
          onClick={() => onClick(workspace)}
        >
          {workspace.name}
        </S.WorkspaceItem>
      ))}
    </S.Wrapper>
  )
}
