import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    justify-content: space-between;
    gap: 24px;

    ${media(size.tablet)} {
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    }
`
)

export const LeftSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;

    ${media(size.tablet)} {
      width: 100%;
    }

    ${media(size.mobile)} {
      gap: 24px;
    }
`
)

export const RightSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 32px;

    ${media(size.tablet)} {
      width: 100%;
      margin-bottom: 32px;
    }
`
)

export const Label = styled.span`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
`

export const AdsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Button = styled(UIButton)`
  width: 50%;
`
