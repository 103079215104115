import { FC, useCallback, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useIsMutating, useMutation } from 'react-query'
import { useNetwork } from 'wagmi'
import { Lg } from 'api/omni/model'
import { generateAiText } from 'api/openai'
import { ContactsModalContext } from 'context'
import { ChooseLegalGate } from 'features/ChooseLegalGate/ChooseLegalGate'
import { FormInput, FormTextarea } from 'features/FormFileds'
import { FORM_ID } from 'pages/JunaSimpleV2/constants'
import { MUTATION_CONTRACT_CREATE_KEY } from 'pages/JunaSimpleV3/constants'
import { FormValues } from 'pages/JunaSimpleV5/types'
import { Button } from 'ui/Button/Button'
import { SvgContacts, SvgPlus } from 'ui/icons'
import * as S from './style'

export const JunaSimpleForm: FC = () => {
  const { t } = useTranslation('pageJunaSimpleV5')

  const { chain } = useNetwork()

  const { values, handleSubmit, setFieldValue } = useFormikContext<FormValues>()

  const { openContactModal } = useContext(ContactsModalContext)

  const { mutate: generateConditions, isLoading: isTextGenerated } =
    useMutation(
      ['generateOpenAiText'],
      (prompt: string) => generateAiText(prompt),
      {
        onSuccess: (data) => {
          const generatedText = data.choices[0].text.replace(/\n/g, '')

          setFieldValue('description', generatedText)
        },
      }
    )

  const isContractCreating = Boolean(
    useIsMutating({
      mutationKey: MUTATION_CONTRACT_CREATE_KEY,
    })
  )

  const handleLegalGateSelect = useCallback(
    (_: number, legalGate: Lg) => {
      const legalGateContract = legalGate.contracts.find(
        (contract) => contract.chainId === chain?.id
      )

      if (legalGateContract) {
        setFieldValue('legalGateAddress', legalGateContract.address)
      }
    },
    [chain, setFieldValue]
  )

  return (
    <S.Form id={FORM_ID} onSubmit={handleSubmit}>
      <S.StepWrapper>
        <S.Label isBold>
          {t('Step')} 1:{' '}
          {t(
            'Type a short title for the contract so you can easily find it later'
          )}
        </S.Label>
        <FormInput name="contractName" placeholder={t('Contract name')} />
      </S.StepWrapper>
      <S.Label isBold>
        {t('Step')} 2 {t('Select your counterpart')}
      </S.Label>
      <S.Label>
        {t('Click on the button ‘Contacts’')}
        <br />
        {t(
          'You can copy your counterpart’s wallet address if this is the first time you deal with them'
        )}
      </S.Label>
      <FormInput
        editorProps={{
          leftButtons: [
            {
              icon: values.partnerAddress ? <SvgPlus /> : <SvgContacts />,
              onClick: () =>
                openContactModal({
                  onAddressSelect: (address) =>
                    setFieldValue('partnerAddress', address),
                  displayMode: values.partnerAddress
                    ? 'addContact'
                    : 'selectContact',
                  contactInputValue: values.partnerAddress,
                }),
              hoverText: values.partnerAddress
                ? t('Add to contacts')
                : t('Select contact'),
            },
          ],
        }}
        name="partnerAddress"
        placeholder={t(
          'Select your counterpart or copy and paste the wallet address here'
        )}
      />
      <S.Label>
        {t(
          'You can use our AI helper to generate the text of the contract based on what you have put above. Add details you need. You can later edit the text below.'
        )}
      </S.Label>
      <S.DescriptionWrapper>
        <FormInput name="contractName" placeholder={t('Contract name')} />
        <S.Button
          isLoading={isTextGenerated}
          onClick={() => generateConditions(values.contractName)}
          variant="red"
        >
          {t('Generate text')}
        </S.Button>
      </S.DescriptionWrapper>
      <S.Label>
        {t(
          'Use simple and actionable terms that are easy to check. Numbers, dates and specifics make it better. For example: Seller agrees to sell and deliver one hundred coffee bags within one month of signing the contract. Buyes agrees to pay for the reasonable quality goods on the day of the delivery to the buyer'
        )}
      </S.Label>
      <FormTextarea name="description" placeholder={t('Contract conditions')} />
      <S.Label>{t('Counterpart pays on complion of the contract:')}</S.Label>
      <S.ShortenedFormInput
        editorProps={{
          type: 'number',
        }}
        name="amountJUNA"
        placeholder={t('Counterpart pays on complion')}
      />
      <ChooseLegalGate
        key={'legalGate'}
        legalGateForm={{
          name: 'Legal Gate',
          context: 'Select your legal gate',
        }}
        legalGateIndex={0}
        onLegalGateSelect={handleLegalGateSelect}
      />
      <Button
        form={FORM_ID}
        isLoading={isContractCreating}
        type="submit"
        variant="red"
      >
        {t('Finish and send for approval to a partner')}
      </Button>
    </S.Form>
  )
}
