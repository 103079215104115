import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

export const PriceSortBlock: FC = () => {
  const { t } = useTranslation('pageAds')

  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <S.Wrapper ref={wrapperRef}>
      <S.FlexWrapper>
        <S.AmountInput onChange={() => {}} placeholder={t('min')} />
        <S.AmountInput onChange={() => {}} placeholder={t('max')} />
      </S.FlexWrapper>
    </S.Wrapper>
  )
}
