import styled from 'styled-components'
import { Button } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  margin-top: 1px;
  padding: 12px;
  margin-bottom: 24px;
`

export const Nav = styled.nav`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: auto;
`

export const BottomBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
`

export const StyledButton = styled(Button)`
  margin: 0 auto;
  width: 65%;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepperWrapper = styled.div`
  width: max-content;
  margin-top: auto;
`
