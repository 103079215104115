import { ChangeEvent, FC, useCallback, useState, useMemo } from 'react'
import { ethers } from 'ethers'
import { useContract, useNetwork, useSigner } from 'wagmi'
import { useAtomAtomActionContractProcessCreate } from 'api/omni/endpoints'
import ERC20 from 'constants/abi/ERC20.json'
import { useSignTypedData } from 'hooks'
import {
  getForwarderAddress,
  getJunaTokenAddress,
} from 'libs/addresses/addresses'
import {
  EIP712Domain,
  Types,
  buildRequest,
} from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { Chip } from 'ui/Chip/Chip'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type DepositBlockProps = {
  status: string
  interestedRate: number
  amount: number
  issuerAddress: string
  atomId: string
}

export const CreditBlock: FC<DepositBlockProps> = (props) => {
  const { status, interestedRate, amount, issuerAddress, atomId } = props

  const [transferAmountValue, setTransferAmountValue] = useState<string>('')

  const { chain } = useNetwork()

  const { data: signer } = useSigner()

  const contract = useContract({
    address: getJunaTokenAddress(chain?.id),
    abi: ERC20,
    signerOrProvider: signer,
  })

  const { signTypedData, getNonce } = useSignTypedData(
    getForwarderAddress(chain?.id)
  )

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const handleTransferInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setTransferAmountValue(evt.target.value)
    },
    []
  )

  const loanInterestAmount = useMemo(() => {
    return (amount / 100) * interestedRate
  }, [amount, interestedRate])

  const handleBtnClick = useCallback(async () => {
    const parsedAmount = ethers.utils.parseEther(transferAmountValue.toString())

    try {
      await contract?.transfer(issuerAddress, parsedAmount)
    } catch {
      notify.error({
        title: 'Something went wrong',
        message: 'Something went wrong',
      })
    }

    const input = await contract?.populateTransaction.transfer(
      issuerAddress,
      parsedAmount
    )

    const nonce = await getNonce()
    const signed = await signTypedData({
      value: buildRequest(nonce, input, issuerAddress),
      domain: EIP712Domain(getForwarderAddress(chain?.id), chain?.id),
      types: Types,
    })

    callAction({
      id: atomId,
      data: {
        action: 'call_return_juna',
        inputs: [
          {
            name: 'tx_hash',
            value: signed,
          },
          {
            name: 'to',
            value: issuerAddress,
          },
          {
            name: 'amount',
            value: transferAmountValue,
          },
        ],
      },
    })
  }, [
    atomId,
    callAction,
    chain?.id,
    contract,
    getNonce,
    issuerAddress,
    signTypedData,
    transferAmountValue,
  ])

  return (
    <S.Wrapper>
      <S.HeaderBlock>
        <S.ColWrapper>
          <S.Title>Credited</S.Title>
          <S.AmountWrapper>
            <SvgJunaToken />
            <S.AmountLabel>{amount}</S.AmountLabel>
          </S.AmountWrapper>
        </S.ColWrapper>
        <S.ColWrapper>
          <S.Title>Loan interest</S.Title>
          <Chip variant="gradient">{loanInterestAmount.toFixed(2)} JUNA</Chip>
        </S.ColWrapper>
      </S.HeaderBlock>

      {status === 'in_progress' && (
        <S.FieldWrapper>
          <S.FieldLabel>Enter Juna amount to return credit</S.FieldLabel>
          <Input
            onChange={handleTransferInputChange}
            placeholder="Amount"
            value={transferAmountValue}
          />
        </S.FieldWrapper>
      )}
      {status === 'in_progress' && (
        <Button onClick={handleBtnClick} variant="red">
          Transfer to bank
        </Button>
      )}
    </S.Wrapper>
  )
}
