import { FC } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { usePin } from 'hooks'
import { FormInput } from '../FormFileds'
import * as S from './styles'

type PinGuardProps = {
  className?: string
}

type FormValues = {
  pin: string
}

const initialValues: FormValues = {
  pin: '',
}

export const PinGuard: FC<PinGuardProps> = (props) => {
  const { className } = props

  const { checkPin, pinChecking, pinCheckFailed } = usePin()

  // todo: move translations to features directory
  const { t } = useTranslation('pageSettings')

  const handleFormikSubmit: FormikConfig<FormValues>['onSubmit'] = ({
    pin,
  }) => {
    checkPin(pin)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormikSubmit}>
      {({ handleSubmit }) => (
        <S.Wrapper className={className}>
          <S.Label>{t('Enter PIN code')}</S.Label>
          <S.Form onSubmit={handleSubmit}>
            <FormInput name="pin" placeholder={t('PIN code')} />
            <S.Button isLoading={pinChecking} type="submit">
              {t('Check PIN')}
            </S.Button>
            {pinCheckFailed && <S.ErrorText>Pin check failed</S.ErrorText>}
          </S.Form>
        </S.Wrapper>
      )}
    </Formik>
  )
}
