import { ChangeEvent, FC, useState, useCallback } from 'react'
import { ConstructorInput } from 'libs/constructor'
import { SvgCheckCircle } from 'ui/icons'
import * as S from './style'

type SignatureBlockProps = {
  onAccept: () => void
  onReject: (comment: string) => void
}

export const SignatureBlock: FC<SignatureBlockProps> = (props) => {
  const { onAccept, onReject } = props

  const [commentValue, setCommentValue] = useState<string>('')

  const [isCommentInputDisplayed, setIsCommentInputDisplayed] =
    useState<boolean>(false)

  const toggleCommentInput = () => {
    setIsCommentInputDisplayed((prev) => !prev)
  }

  const handleCommentInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setCommentValue(evt.target.value)
    },
    []
  )

  const handleReject = (comment: string) => {
    onReject(comment)
  }

  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        <S.ActionButton onClick={onAccept}>
          <SvgCheckCircle />
          Approve and sign contract
        </S.ActionButton>
        <S.RejectDropButton
          isActive={isCommentInputDisplayed}
          onClick={toggleCommentInput}
        >
          Reject
        </S.RejectDropButton>
      </S.ButtonsWrapper>

      {isCommentInputDisplayed && (
        <>
          <ConstructorInput
            onChange={handleCommentInputChange}
            placeholder="Type reject comment"
            value={commentValue}
          />
          <S.ActionButton isReject onClick={() => handleReject(commentValue)}>
            Reject
          </S.ActionButton>
        </>
      )}
    </S.Wrapper>
  )
}
