import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { Button } from './components/VotingCard/style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%:
`

export const SuggestBlock = styled(Card)`
  width: max-content;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
`

export const SuggestLabel = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.color.gray};
`

export const SuggestButton = styled(Button)`
  border-radius: 12px;
  padding: 8px 16px;
  max-height: 36px;
`

export const SuggestButtonChip = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.red};
  padding: 1px 6px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VerticalLine = styled.div`
  width: 1px;
  height: 35px;
  background-color: ${({ theme }) => theme.color.line};
`
