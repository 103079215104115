import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ActionButton = styled.div<{
  disabled?: boolean
  selected?: boolean
}>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  transition: opacity 0.3s;
  cursor: ${({ disabled, selected }) =>
    disabled ? 'now-allowed' : selected ? 'auto' : 'pointer'};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.mirageLight : theme.color.white};

  &:hover {
    opacity: ${({ disabled, selected }) => (disabled || selected ? 1 : 0.5)};
  }
`
