import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  min-width: 280px;
  width: 280px;
`

export const WorkspaceItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.black};
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.bg};
  }
`
