import { FC } from 'react'
import * as S from './style'

type ContractCardProps = {
  className?: string
  title: string
  country: string
  id: number
}

export const ContractCard: FC<ContractCardProps> = ({
  className,
  title,
  country,
}) => {
  return (
    <S.Card className={className}>
      <S.Option>
        <S.Label>{country}</S.Label>
      </S.Option>
      <S.Title>{title}</S.Title>
    </S.Card>
  )
}
