import { FC, useContext, useState, useCallback, useMemo } from 'react'
import { BigNumber, ethers, utils } from 'ethers'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAccount, useContract, useNetwork, useSigner } from 'wagmi'
import { useNetworkTxSendFrCreate } from 'api/omni/endpoints'
import ERC20 from 'constants/abi/ERC20.json'
import { ApprovalState } from 'constants/blockchain'
import { ContactsModalContext } from 'context'
import { FormInput, FormSelect } from 'features/FormFileds'
import { GuardedButton } from 'features/GuardedButton/GuardedButton'
import { useSignTypedData, useTokenBalance, useApprove } from 'hooks'
import {
  getForwarderAddress,
  getJunaTokenAddress,
  getRelayerAddress,
} from 'libs/addresses/addresses'
import {
  buildRequest,
  EIP712Domain,
  Types,
} from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { SvgContacts, SvgPlus } from 'ui/icons'
import { Link } from 'ui/Link/Link'
import { ToggleButton } from 'ui/ToggleButton/ToggleButton'
import { toHumanNumber } from 'utils/toHumanNumber'
import * as S from './style'
import { getValidationSchema } from './validationSchema'

export type FormValues = {
  address: string
  amount: string
  network: string
  token: string
}

const selectNetworkOptions = [
  {
    value: 97,
    label: 'BSC Testnet',
  },
]

export const TransferForm: FC = () => {
  const [estimationGas, setEstimationGas] = useState(BigNumber.from(0))

  const [searchParam] = useSearchParams()

  const { t } = useTranslation('pageTransfer')

  const { openContactModal } = useContext(ContactsModalContext)

  const { address, isConnected } = useAccount()

  const { chain } = useNetwork()

  const { data: signer } = useSigner()

  const contract = useContract({
    address: getJunaTokenAddress(chain?.id),
    abi: ERC20,
    signerOrProvider: signer,
  })

  const { signTypedData, getNonce } = useSignTypedData(
    getForwarderAddress(chain?.id)
  )

  const balance = useTokenBalance(getJunaTokenAddress(chain?.id), address)

  const { approvalState, approve } = useApprove(
    getJunaTokenAddress(chain?.id),
    ethers.constants.WeiPerEther,
    getRelayerAddress(chain?.id)
  )

  const { mutate, isLoading: isJunaSending } = useNetworkTxSendFrCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Transaction has been sended'),
          title: t('Transaction has been sended'),
        })
      },
    },
  })

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values: FormValues) => {
      if (chain?.nativeCurrency) {
        if (values.token === chain.nativeCurrency.name) {
          const amount = ethers.utils.parseEther(values.amount.toString())
          try {
            await contract?.transfer(values.address, amount)
          } catch {
            notify.error({
              title: t('Something went wrong'),
              message: t('Something went wrong'),
            })
          }

          return
        }

        const amount = ethers.utils.parseEther(values.amount.toString())
        const input = await contract?.populateTransaction.transfer(
          values.address,
          amount
        )

        setEstimationGas(
          await contract!.estimateGas.transfer(values.address, amount, {
            from: address,
          })
        )

        const nonce = await getNonce()
        const signed = await signTypedData({
          value: buildRequest(nonce, input, address),
          domain: EIP712Domain(getForwarderAddress(chain?.id), chain?.id),
          types: Types,
        })

        mutate({
          data: {
            data: buildRequest(nonce, input, address),
            dataSig: signed,
            blockchain: chain!.id,
          },
        })
      }
    },
    [address, chain, contract, getNonce, mutate, signTypedData, t]
  )

  const toggleButtonOptions = useMemo(
    () =>
      chain?.nativeCurrency && [
        {
          label: 'JUNA',
          value: 'juna',
        },
        {
          label: chain.nativeCurrency.symbol,
          value: chain.nativeCurrency.name,
        },
      ],
    [chain]
  )

  const initialValues: FormValues = {
    address: searchParam.get('to') || '',
    amount: '',
    network: '',
    token: 'juna',
  }

  const isApproved = approvalState === ApprovalState.APPROVED

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema()}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <S.Wrapper>
              <FormInput
                editorProps={{
                  disabled: !isApproved,
                  buttons: [
                    {
                      icon: values.address ? <SvgPlus /> : <SvgContacts />,
                      onClick: () =>
                        openContactModal({
                          onAddressSelect: (address) =>
                            setFieldValue('address', address),
                          displayMode: values.address
                            ? 'addContact'
                            : 'selectContact',
                          contactInputValue: values.address,
                        }),
                      hoverText: values.address
                        ? t('Add to contacts')
                        : t('Select contact'),
                    },
                  ],
                }}
                name="address"
                placeholder={t('To whom')}
              />
              <FormInput
                editorProps={{
                  type: 'number',
                  disabled: !isApproved,
                  min: 0,
                }}
                name="amount"
                placeholder="0.0"
              />
              {isConnected && (
                <S.LinkWrapper>
                  <Link
                    onClick={() =>
                      setFieldValue('amount', ethers.utils.formatEther(balance))
                    }
                  >
                    {toHumanNumber(balance)} JUNA
                  </Link>
                </S.LinkWrapper>
              )}

              <FormSelect
                editorProps={{
                  isDisabled: !isApproved,
                  options: selectNetworkOptions,
                  placeholder: t('Network'),
                  onChange: (option) => setFieldValue('network', option?.value),
                  getOptionLabel: (option) => option.label,
                  getOptionValue: (option) => `${option.value}`,
                  value: selectNetworkOptions.find(
                    (network) => network.value === Number(values.network)
                  ),
                }}
                name="network"
              />
              {estimationGas && !estimationGas.eq(0) && (
                <S.FeeContainer>
                  <S.FeeTitle>{t('Transaction fee')}:</S.FeeTitle>
                  <S.FeeLabel>
                    {utils.formatEther(estimationGas)} JUNA
                  </S.FeeLabel>
                </S.FeeContainer>
              )}
              {isConnected && toggleButtonOptions && (
                <ToggleButton
                  isDisabled={!isApproved}
                  onChange={(value) => setFieldValue('token', value)}
                  options={toggleButtonOptions}
                  value={values.token}
                />
              )}
              {isApproved ? (
                <GuardedButton
                  isLoading={isJunaSending}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {t('Send')} JUNA
                </GuardedButton>
              ) : (
                <GuardedButton onClick={approve} type="button">
                  {t('Approve')}
                </GuardedButton>
              )}
            </S.Wrapper>
          </form>
        )
      }}
    </Formik>
  )
}
