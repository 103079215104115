import { FC } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormValues } from 'pages/TransactionCreate/types'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type TransactionDetailsProps = {
  contractName?: string
  actionOptions: {
    label: string
    value: string
  }[]
  actionInputs?: {
    label: string
    name: string
  }[]
}

export const TransactionDetails: FC<TransactionDetailsProps> = (props) => {
  const { actionOptions, actionInputs, contractName } = props

  const { t } = useTranslation('pageTransactionCreate')

  const { values } = useFormikContext<FormValues>()

  const { contractAddress, action, inputs, ...dynamicFields } = values

  const currentAction = actionOptions.find(
    ({ value }) => value === values.action
  )

  return (
    <S.Wrapper>
      <S.Title>{t('Transaction Details')}</S.Title>
      <S.DetailsWrapper>
        <S.DetailWrapper>
          <S.Label>{t('Contract')}</S.Label>
          <S.DashedLine />
          <S.Label isBold>{contractName}</S.Label>
        </S.DetailWrapper>
        <S.DetailWrapper>
          <S.Label>{t('Address of contract')}</S.Label>
          <S.DashedLine />
          <S.Label isBold>{shortenAddress(contractAddress)}</S.Label>
        </S.DetailWrapper>
      </S.DetailsWrapper>
      <S.Line />
      <S.DetailsWrapper>
        <S.DetailWrapper>
          <S.Label>{t('Action')}</S.Label>
          <S.DashedLine />
          <S.Label isBold>{currentAction?.label}</S.Label>
        </S.DetailWrapper>
        {Object.keys(dynamicFields).length > 0 &&
          Object.entries(dynamicFields).map(([fieldName, fieldData]) => {
            const currentInputValue = actionInputs?.find(
              ({ name }) => name === fieldName
            )?.label

            return (
              <S.DetailWrapper key={fieldName}>
                <S.Label>{currentInputValue}</S.Label>
                <S.DashedLine />
                <S.Label isBold>{`${fieldData}`}</S.Label>
              </S.DetailWrapper>
            )
          })}
      </S.DetailsWrapper>
    </S.Wrapper>
  )
}
