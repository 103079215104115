import { useContract as useContractNative } from 'wagmi'
import ERC20 from 'constants/abi/ERC20.json'
import { useProviderOrSigner } from 'hooks'

export const useContract = (
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  const contract = useContractNative<typeof ERC20>({
    address: address ? address : '',
    abi: ABI,
    signerOrProvider: providerOrSigner,
  })

  return contract
}

export const useTokenContract = (
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) => {
  return useContract(tokenAddress, ERC20, withSignerIfPossible)
}
