import { forwardRef, ChangeEvent, useCallback } from 'react'
import { BaseInput, BaseInputProps } from 'ui/BaseInput/BaseInput'
import * as S from './style'

import { InputButton } from './types'

export type InputProps = BaseInputProps & {
  buttons?: InputButton[]
  leftButtons?: InputButton[]
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    onChange,
    disabled,
    type = 'text',
    buttons,
    max,
    min,
    leftButtons,
    error,
  } = props

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      const numValue = Number(evt.target.value)

      if (typeof min === 'number' && numValue < min) {
        evt.target.value = ''
      }

      if (typeof max === 'number' && numValue > max) {
        evt.target.value = max.toString()
      }
    }

    onChange?.(evt)
  }

  const getRenderedButtons = useCallback(
    (buttons: InputButton[]) => {
      return buttons.map(
        (
          {
            onClick,
            icon,
            hoverText,
            disabled: buttonDisabled = false,
            visible = true,
            text,
            variant = 'transparent',
          },
          idx
        ) =>
          visible && (
            <S.FocusIconButton
              key={idx}
              disabled={buttonDisabled || disabled}
              error={error}
              onClick={onClick}
              variant={variant}
            >
              {icon}
              {hoverText && <S.LabelButton>{hoverText}</S.LabelButton>}
              {text}
            </S.FocusIconButton>
          )
      )
    },
    [disabled, error]
  )

  return (
    <S.Wrapper>
      <>{leftButtons && getRenderedButtons(leftButtons)}</>
      <BaseInput ref={ref} {...props} onChange={handleChange}>
        <>{buttons && getRenderedButtons(buttons)}</>
      </BaseInput>
    </S.Wrapper>
  )
})
