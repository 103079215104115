import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import {
  usePlatformContractList,
  usePlatformContracttxList,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { Loans, Transactions } from './components'
import * as S from './style'

const ITEMS_ON_PAGE = 6

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'My contacts',
    url: AppRoutes.Settings,
  },
]

export const MyLoans: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { t } = useTranslation('pageMyLoans')

  const { address } = useAccount()

  const {
    data: loans,
    isLoading: isLoansLoading,
    isSuccess: isLoansSuccess,
  } = usePlatformContractList({ contractTypeId: 1 })

  const {
    data: transactions,
    isLoading: isTransactionsLoading,
    isSuccess: isTransactionsSuccess,
  } = usePlatformContracttxList(
    {
      contractTypeId: 1,
      fromAdr: address,
    },
    {
      query: {
        enabled: !!address,
      },
    }
  )

  if (isLoansLoading || isTransactionsLoading) {
    return <Loader />
  }

  if (isLoansSuccess && isTransactionsSuccess) {
    const totalPages = Math.ceil(transactions.length / ITEMS_ON_PAGE)

    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Finance Dashboard')}>
        <S.Wrapper>
          <Loans loans={loans.results} />
          <S.RightSideWrapper>
            <Transactions transactions={transactions} />
            {totalPages > 1 && (
              <S.PaginationWrapper>
                <S.TotalItemsLabel>
                  {t('Total items', { count: transactions.length })}
                </S.TotalItemsLabel>
                <Pagination
                  currentPage={currentPage}
                  onChangePage={(page) => setCurrentPage(page)}
                  totalPages={totalPages}
                />
              </S.PaginationWrapper>
            )}
          </S.RightSideWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
