import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      height={20}
      id="mask0_1425_15477"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={20}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={20} width={20} />
    </mask>
    <g mask="url(#mask0_1425_15477)">
      <path
        d="M4.16667 15.8341H5.33333L12.5208 8.64665L11.3542 7.47998L4.16667 14.6675V15.8341ZM16.0833 7.43831L12.5417 3.93831L13.7083 2.77165C14.0278 2.4522 14.4203 2.29248 14.8858 2.29248C15.3508 2.29248 15.7431 2.4522 16.0625 2.77165L17.2292 3.93831C17.5486 4.25776 17.7153 4.64331 17.7292 5.09498C17.7431 5.54609 17.5903 5.93137 17.2708 6.25081L16.0833 7.43831ZM14.875 8.66748L6.04167 17.5008H2.5V13.9591L11.3333 5.12581L14.875 8.66748Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgEdit)
export default Memo
