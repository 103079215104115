import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { SvgContract } from 'ui/icons'

export const TreeWrapper = styled(Card)`
  padding: 24px;
  width: 100%;
  min-width: 320px;

  .rc-tree-title {
    color: ${({ theme }) => theme.color.gray};
    margin-left: 12px;
    font-size: 14px;
  }

  .rc-tree-treenode-switcher-open {
    span {
      color: ${({ theme }) => theme.color.black};
    }
    margin: 4px 0;
  }

  .rc-tree-node-content-wrapper {
    display: flex;
    align-items: center;
  }

  .rc-tree-switcher {
    display: none;
    background-image: none;
  }

  .rc-tree-treenode {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  .rc-tree-node-selected {
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
`

export const Title = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: 900;
`

export const ContractIcon = styled(SvgContract)`
  background-color: white;
`
