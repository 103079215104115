import { FC, useMemo, useState } from 'react'
import { ContractContext } from 'api/omni/model'
import { MilestoneList } from 'ui/MilestoneList/MilestoneList'

type MilestoneWrapperProps = {
  context: ContractContext | any
  contractId: number
  nextAction?: string
}

const getProcessLink = (contractId: number, selector: string) => {
  return `/contracts/${contractId}/process/${selector}`
}

export const MilestoneWrapper: FC<MilestoneWrapperProps> = (props) => {
  const { context: contractContext, contractId, nextAction } = props

  const [activeItem, setActiveItem] = useState<number>()

  const generatedList = useMemo(
    () =>
      contractContext.path.map((item: string, idx: number) => {
        const { name, context } = contractContext[item]

        if (item === nextAction) {
          setActiveItem(idx + 1)
        }

        return {
          label: name,
          details: [context],
          link: getProcessLink(contractId, item),
        }
      }),
    [contractContext, contractId, nextAction]
  )

  return <MilestoneList activeItem={activeItem} list={generatedList} />
}
