import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 24px;
  border-radius: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`
