import { FC, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { AdsCategory } from 'api/omni/model'
import { FormValues } from 'pages/AddAds/types'
import { AddressChip } from 'ui/AddressChip/AddressChip'
import { SvgJunaToken } from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type AdsDetailsProps = {
  categories: AdsCategory[]
  address: string
}

export const AdsDetails: FC<AdsDetailsProps> = (props) => {
  const { categories, address } = props

  const { t } = useTranslation('pageAdEdit')

  const { values, errors } = useFormikContext<FormValues>()

  const { name, description, amountjuna, category } = values

  //TODO получать текущую категорию из апи
  const currentCategory = useMemo(() => {
    return categories.filter(
      (categoryItem) => categoryItem.id === Number(category)
    )
  }, [categories, category])

  return (
    <S.Wrapper>
      <S.AdHeader>
        {!errors.name && name && <S.Title>{name}</S.Title>}
        <AddressChip address={shortenAddress(address)} />
      </S.AdHeader>

      <S.DescriptionWrapper>
        <S.Label isBold>{t('Description')}</S.Label>
        {!errors.description && description && <S.Label>{description}</S.Label>}
      </S.DescriptionWrapper>

      <S.BottomBlock>
        <S.BottomItemWrapper>
          <S.Label isBold>{t('Amount')}</S.Label>
          <S.AmountWrapper>
            <SvgJunaToken />
            {!errors.amountjuna && Number(amountjuna) > 0 && (
              <S.Label>{formatNumberWithSeparators(amountjuna)}</S.Label>
            )}
          </S.AmountWrapper>
        </S.BottomItemWrapper>

        <S.BottomItemWrapper>
          <S.Label isBold>{t('Category')}</S.Label>
          {!errors.category && category && (
            <S.Label>{currentCategory[0].name}</S.Label>
          )}
        </S.BottomItemWrapper>
      </S.BottomBlock>
    </S.Wrapper>
  )
}
