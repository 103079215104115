import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1425_15926)">
      <path
        d="M16 3.0401C15.405 3.3011 14.771 3.4741 14.11 3.5581C14.79 3.1521 15.309 2.5141 15.553 1.7451C14.919 2.1231 14.219 2.3901 13.473 2.5391C12.871 1.8981 12.013 1.5011 11.077 1.5011C9.261 1.5011 7.799 2.9751 7.799 4.7821C7.799 5.0421 7.821 5.2921 7.875 5.5301C5.148 5.3971 2.735 4.0901 1.114 2.0991C0.831 2.5901 0.665 3.1521 0.665 3.7571C0.665 4.8931 1.25 5.9001 2.122 6.4831C1.595 6.4731 1.078 6.3201 0.64 6.0791C0.64 6.0891 0.64 6.1021 0.64 6.1151C0.64 7.7091 1.777 9.0331 3.268 9.3381C3.001 9.4111 2.71 9.4461 2.408 9.4461C2.198 9.4461 1.986 9.4341 1.787 9.3901C2.212 10.6891 3.418 11.6441 4.852 11.6751C3.736 12.5481 2.319 13.0741 0.785 13.0741C0.516 13.0741 0.258 13.0621 0 13.0291C1.453 13.9661 3.175 14.5011 5.032 14.5011C11.068 14.5011 14.368 9.5011 14.368 5.1671C14.368 5.0221 14.363 4.8821 14.356 4.7431C15.007 4.2811 15.554 3.7041 16 3.0401Z"
        fill="#8388AA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1425_15926">
        <rect
          fill="white"
          height={16}
          transform="translate(0 0.00109863)"
          width={16}
        />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgTwitter)
export default Memo
