import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'constants/app'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'

import * as S from './style'

export const EcosystemBlock: FC = () => {
  const navigate = useNavigate()

  const handleClickMore = useCallback(() => {
    navigate(AppRoutes.Dashboard)
  }, [navigate])

  return (
    <S.Container>
      <S.Header>Juna Ecosystem</S.Header>
      <S.List>
        <S.Item>
          <S.Label>Users</S.Label>
          <S.Label>42</S.Label>
        </S.Item>
        <S.Item>
          <S.Label>Reservation system</S.Label>
          <S.Label>{formatNumberWithSeparators(10000000)} JUNA</S.Label>
        </S.Item>
        <S.Item>
          <S.Label>Launchpad</S.Label>
          <S.Label>{formatNumberWithSeparators(10000000)} JUNA</S.Label>
        </S.Item>
        <S.Item>
          <S.Label>Bank</S.Label>
          <S.Label>{formatNumberWithSeparators(0)} JUNA</S.Label>
        </S.Item>
      </S.List>
      <S.MoreLink onClick={handleClickMore}>More</S.MoreLink>
    </S.Container>
  )
}
