import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgCopyAtomContractAddress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={17}
    viewBox="0 0 16 17"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4620_96176)">
      <path
        clipRule="evenodd"
        d="M2.11276 2.61276C2.18497 2.54056 2.28289 2.5 2.385 2.5H8.615C8.71711 2.5 8.81503 2.54056 8.88724 2.61276C8.95944 2.68496 9 2.78289 9 2.885V3.577C9 4.12928 9.44771 4.577 10 4.577C10.5523 4.577 11 4.12928 11 3.577V2.885C11 2.25246 10.7487 1.64582 10.3014 1.19855C9.85418 0.751276 9.24754 0.5 8.615 0.5H2.385C1.75246 0.5 1.14582 0.751276 0.69855 1.19855C0.251276 1.64582 0 2.25246 0 2.885V9.115C0 9.74754 0.251276 10.3542 0.69855 10.8014C1.14582 11.2487 1.75246 11.5 2.385 11.5H3.077C3.62928 11.5 4.077 11.0523 4.077 10.5C4.077 9.94771 3.62928 9.5 3.077 9.5H2.385C2.28289 9.5 2.18496 9.45944 2.11276 9.38724C2.04056 9.31503 2 9.21711 2 9.115V2.885C2 2.78289 2.04056 2.68497 2.11276 2.61276ZM7.385 5.5C6.06772 5.5 5 6.56772 5 7.885V14.115C5 15.4323 6.06772 16.5 7.385 16.5H13.615C14.9323 16.5 16 15.4323 16 14.115V7.885C16 6.56772 14.9323 5.5 13.615 5.5H7.385ZM7 7.885C7 7.67229 7.17229 7.5 7.385 7.5H13.615C13.8277 7.5 14 7.67229 14 7.885V14.115C14 14.3277 13.8277 14.5 13.615 14.5H7.385C7.17229 14.5 7 14.3277 7 14.115V7.885Z"
        fill="#1B1F3B"
        fillOpacity={0.2}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_4620_96176">
        <rect
          fill="white"
          height={16}
          transform="translate(0 0.5)"
          width={16}
        />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgCopyAtomContractAddress)
export default Memo
