import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 32px;
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 28px;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const FieldLabel = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray};
`

export const RejectButton = styled(Button)`
  color: ${({ theme }) => theme.color.red};
`
