import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 15px 16px;
  display: flex;
  align-items: center;
  gap: 24px;
`

export const BalanceWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const Title = styled.h4`
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
  padding: 0;
`

export const BalanceLabel = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
`

export const AmountWrapper = styled(BalanceWrapper)`
  gap: 4px;
`

export const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const StatInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StatLabel = styled.span`
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const StatValueLabel = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
`
