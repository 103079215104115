import { getI18n } from 'react-i18next'
import { AnySchema, object, string, number } from 'yup'
import { InputRuntype, Input } from './types'

const addressRegex = /^0x[a-fA-F0-9]{40}$/

type DynamicFieldsType = Input[] | { [key: string]: unknown }

export const getValidationSchema = (inputs?: DynamicFieldsType) => {
  const { t } = getI18n()

  const schema: Record<string, AnySchema> = {}

  if (inputs) {
    Object.entries(inputs).forEach(([fieldName, fieldData]) => {
      const { type } = InputRuntype.check(fieldData)

      switch (type) {
        case 'string':
          schema[fieldName] = string().required(
            t('validation.Required to fill out')
          )
          break
        case 'uint':
          schema[fieldName] = number()
            .required(t('validation.Required to fill out'))
            .positive(t('validation.Number cannot be negative'))
          break
        case 'address':
          schema[fieldName] = string()
            .required(t('validation.Required to fill out'))
            .matches(addressRegex, t('validation.Invalid wallet address'))
          break
        default:
          break
      }
    })
  }

  return object().shape(schema)
}
