import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionMeta, SingleValue } from 'react-select'
import { PriceSortOption } from 'pages/Ads/types'
import { SvgSearch } from 'ui/icons'
import * as S from './style'

type SearchBlockProps = {
  onSearchChange: (searchedAdValue: string) => void
  onPriceSortChange: (
    value: SingleValue<PriceSortOption>,
    actionMeta: ActionMeta<PriceSortOption>
  ) => void
}

export const SearchBlock: FC<SearchBlockProps> = (props) => {
  const { onSearchChange, onPriceSortChange } = props

  const { t } = useTranslation('pageAds')

  const priseSortOptions: PriceSortOption[] = useMemo(
    () => [
      {
        label: t('Price: low to high'),
        value: 'asc',
      },
      {
        label: t('Price: high to low'),
        value: 'desc',
      },
    ],
    [t]
  )

  const handeSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(evt.target.value)
  }

  return (
    <S.Wrapper>
      <S.SearchInput
        buttons={[{ icon: <SvgSearch /> }]}
        onChange={handeSearchChange}
        placeholder={t('Find it')}
      />
      <S.SortWrapper>
        <S.SortSelect onChange={onPriceSortChange} options={priseSortOptions} />
      </S.SortWrapper>
    </S.Wrapper>
  )
}
