import { createElement, useMemo, useCallback, MouseEvent, FC } from 'react'
// @ts-ignore
import MersenneTwister from '@dsibilly/mersenne-twister'
import Color from 'color'
import * as S from './style'

const shapeCount = 4
const diameter = 40
const shapeArr = Array.from(new Array(shapeCount).keys())

const colors = [
  '#01888C', // teal
  '#FC7500', // bright orange
  '#034F5D', // dark teal
  '#F73F01', // orangered
  '#FC1960', // magenta
  '#C7144C', // raspberry
  '#F3C100', // goldenrod
  '#1598F2', // lightning blue
  '#2465E1', // sail blue
  '#F19E02', // gold
]

const wobble = 30

const hueShift = (colors: string[], generator: any) => {
  const amount = generator.random() * 30 - wobble / 2
  return colors.map(function (hex) {
    const color = Color(hex)
    color.rotate(amount)
    return color.hex()
  })
}

const genColor = (colors: string[], generator: any) => {
  const rand = generator.random()
  const idx = Math.floor(colors.length * rand)
  const color = colors.splice(idx, 1)[0]
  return color
}

const genShape = (
  remainingColors: any,
  diameter: number,
  i: number,
  total: number,
  generator: any
) => {
  const center = diameter / 2

  const firstRot = generator.random()
  const angle = Math.PI * 2 * firstRot
  const velocity =
    (diameter / total) * generator.random() + (i * diameter) / total

  const tx = Math.cos(angle) * velocity
  const ty = Math.sin(angle) * velocity

  const translate = 'translate(' + tx + ' ' + ty + ')'

  // Third random is a shape rotation on top of all of that.
  const secondRot = generator.random()
  const rot = firstRot * 360 + secondRot * 180
  const rotate = 'rotate(' + rot.toFixed(1) + ' ' + center + ' ' + center + ')'
  const transform = translate + ' ' + rotate
  const fill = genColor(remainingColors, generator)

  const shape = createElement('rect', {
    x: 0,
    y: 0,
    width: diameter.toString(),
    height: diameter.toString(),
    transform,
    fill,
    key: i,
  })

  return shape
}

type AvatarProps = {
  address: string
  className?: string
  onClick?: () => void
}

export const Avatar: FC<AvatarProps> = (props) => {
  const { address, className, onClick } = props

  const seed = useMemo(() => address.replace(/\D/g, '').slice(0, 10), [address])

  const generator = useMemo(() => new MersenneTwister(Number(seed)), [seed])

  const remainingColors = useMemo(
    () => hueShift(colors.slice(), generator),
    [generator]
  )
  const shapes = useMemo(() => {
    return shapeArr.map((i) =>
      genShape(remainingColors, diameter, i, shapeCount - 1, generator)
    )
  }, [remainingColors, generator])
  const bgColor = useMemo(
    () => genColor(remainingColors, generator),
    [remainingColors, generator]
  )

  const handleClick = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault()
      onClick?.()
    },
    [onClick]
  )

  return (
    <S.Wrapper>
      <S.Container
        background={bgColor}
        className={className}
        onClick={handleClick}
      >
        <svg height="40" width="40" x="0" y="0">
          {shapes}
        </svg>
      </S.Container>
    </S.Wrapper>
  )
}
