import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 41px 32px;
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 12px;
`

export const Title = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
  padding: 0;
`

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const AmountLabel = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FieldLabel = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`
