import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { VotingParametersGroup } from 'pages/Votings/typings'
import { SvgGreenCheck } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type VotingParamProps = {
  paramGroup: VotingParametersGroup
  value?: Record<string, unknown>
}

export const VotingParam: FC<VotingParamProps> = (props) => {
  const { paramGroup, value } = props

  const { t } = useTranslation('pageAddVoting')

  return (
    <S.Wrapper>
      <S.LeftSideWrapper>
        <S.Label>{t('parameter group')}</S.Label>
        <S.Title>{paramGroup.title}</S.Title>
      </S.LeftSideWrapper>
      <S.RightSideWrapper>
        {paramGroup.params.map((param, idx) => {
          if (!value || !value[param.id]) {
            return null
          }
          const paramValue = value[param.id]

          if (
            typeof paramValue !== 'string' &&
            typeof paramValue !== 'number'
          ) {
            throw new Error('Voting param value is not string or number')
          }
          return (
            <S.ParamRowWrapper key={idx}>
              <S.Chip variant="gray">{param.symbol}</S.Chip>
              <S.ParamRowLabel>{param.name}</S.ParamRowLabel>
              <S.FieldWrapper>
                <Input disabled value={paramValue} />
              </S.FieldWrapper>
              <S.FieldWrapper>
                <Input
                  buttons={[
                    {
                      icon: <SvgGreenCheck />,
                    },
                  ]}
                  value={paramValue}
                />
              </S.FieldWrapper>
            </S.ParamRowWrapper>
          )
        })}
      </S.RightSideWrapper>
    </S.Wrapper>
  )
}
