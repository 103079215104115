import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 163px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  background-color: #edf5fd;
  padding: 28px 20px;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.color.line};
  border-radius: 12px;
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.color.black};
  font-size: 20px;
  font-weight: 800;
  margin: 0;
`

export const Description = styled.p`
  max-width: 92px;
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`
