import { ChainId } from './blockchain'

export type Address = {
  [chainId: number]: string
}

export const Addresses = {
  junaToken: {
    [ChainId.BSC_TESTNET]: '0x0302A7391d1A5B6e42E68aCA687A415CE16675BB',
    [ChainId.ETH]: '0x71AB148D352FF835216a2324FC0e65969adC568a',
  },
  forwarder: {
    [ChainId.BSC_TESTNET]: '0xAae55eaC69720FF3BB4ce732c51D4BB3Aeb65286',
  },
  relayer: {
    [ChainId.BSC_TESTNET]: '0xFfF6B5Dd1F1452857C5f24b2f76eCA46B737ceb2',
  },
}

export const JUNA_SIMPLE_CONTRACT_ID = 1
