import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  CONTRACTS_BASE_URL,
  OMNI_BASE_URL,
  OPENAI_BASE_URL,
} from 'constants/api'
import { getAuthToken } from 'libs/auth-storage/auth-storage'

export const AXIOS_MARKETPLACE_INSTANCE = axios.create({
  baseURL: CONTRACTS_BASE_URL,
})

export const AXIOS_OMNI_INSTANCE = axios.create({
  baseURL: OMNI_BASE_URL,
})

export const AXIOS_OPENAI_INSTANCE = axios.create({
  baseURL: OPENAI_BASE_URL,
})

const SLEEP_TIME = 600 //ms

const sleep = () =>
  new Promise((res) => {
    setTimeout(res, SLEEP_TIME)
  })

AXIOS_OMNI_INSTANCE.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getAuthToken()
  config.headers = {
    ...(token && {
      'Web3-Token-Authorization': `${token}`,
    }),
  }

  return config
})

AXIOS_OMNI_INSTANCE.interceptors.response.use(
  async (response: AxiosResponse) => {
    await sleep()
    return response
  }
)
