import styled from 'styled-components'
import { BaseInput } from 'ui/BaseInput/BaseInput'

export const InputContainer = styled(BaseInput)<{ isRequired?: boolean }>`
  border-radius: 6px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.red : theme.color.mirageDark)};
  box-shadow: none;
  background: ${({ theme, disabled, isRequired }) =>
    disabled
      ? theme.color.mirageLight
      : isRequired
      ? theme.color.lightYellow
      : theme.color.white};

  input {
    color: ${({ error, theme }) =>
      error ? theme.color.red : theme.color.black};
    padding: 7px 0;
    background: ${({ theme, disabled, isRequired }) =>
      disabled
        ? theme.color.mirageLight
        : isRequired
        ? theme.color.lightYellow
        : theme.color.white};

    &::placeholder {
      color: ${({ theme }) => theme.color.mirageDark};
    }
  }
`

export const ActionElementButton = styled.div`
  cursor: pointer;
  padding: 7px 12px;
  text-wrap: nowrap;
  border-left: 1px solid ${({ theme }) => theme.color.mirageDark};
  &:hover {
    background-color: ${({ theme }) => theme.color.mirageLight};
  }
`

export const ActionElementsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
