import { FC } from 'react'
import * as S from './style'

type InfoBlockProps = {
  amount: number
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
  const { amount } = props

  return (
    <S.Wrapper>
      <S.Title>Information</S.Title>
      <S.ListWrapper>
        <S.ListItem>
          <S.ListItemLabel>Deposited</S.ListItemLabel>
          <S.DashedLine />
          <S.ListItemLabel>{amount} JUNA</S.ListItemLabel>
        </S.ListItem>
      </S.ListWrapper>
    </S.Wrapper>
  )
}
