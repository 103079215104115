import { FC } from 'react'
import dayjs from 'dayjs'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { Project } from 'api/omni/model'
import { AppRoutes, projectStatusLabels } from 'constants/app'
import PLACEHOLDER_IMAGE from './assets/placeholderImage.png'
import * as S from './style'

//TODO: поменять тип (не наследовать)
type ProjectCardProps = Omit<Project, 'logo' | 'approved'>

const placeholderData = {
  ratio: 100,
  price: 10000,
  access: 'Public',
  network: 'OP Mainnet',
  date: dayjs(Date.now()).format('Do MMM, YYYY'),
}

export const ProjectCardV2: FC<ProjectCardProps> = (props) => {
  const { name, status, amountJuna, id } = props

  const cardData = [
    {
      label: 'Total Funds',
      value: `${amountJuna} JUNA`,
    },
    {
      label: 'Ratio',
      value: `${placeholderData.ratio} JUNA`,
    },
    {
      label: 'Price',
      value: `${placeholderData.price} JUNA`,
    },
    {
      label: 'Access',
      value: placeholderData.access,
    },
    {
      label: 'Network',
      value: placeholderData.network,
    },
    {
      label: 'Date',
      value: placeholderData.date,
    },
  ]

  const navigate = useNavigate()

  const handleTitleClick = () => {
    navigate({
      pathname: AppRoutes.Project,
      search: `${createSearchParams({
        projectId: `${id}`,
      })}`,
    })
  }

  return (
    <S.Card>
      <S.HeaderContainer>
        <S.TagsContainer>
          <S.StatusTag>{projectStatusLabels[status]}</S.StatusTag>
          <S.ProjectIcon />
        </S.TagsContainer>
        <S.Title onClick={handleTitleClick}>{name}</S.Title>
        <S.ImageContainer>
          <img alt="placeholder" src={PLACEHOLDER_IMAGE} />
        </S.ImageContainer>
      </S.HeaderContainer>
      <S.ContentContainer>
        <S.DaysContainer>
          <S.DaysTag>30</S.DaysTag> days before launch
        </S.DaysContainer>
        {cardData.map((el) => (
          <S.ContentItem>
            <S.PropertyName>{el.label}</S.PropertyName>
            <S.DashedLine />
            <S.PropertyValue>{el.value}</S.PropertyValue>
          </S.ContentItem>
        ))}
      </S.ContentContainer>
    </S.Card>
  )
}
