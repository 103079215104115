import { useTranslation } from 'react-i18next'
import { AppRoutes } from 'constants/app'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { TransferForm } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Transfer JUNA',
    url: AppRoutes.Transfer,
  },
]

export const Transfer = () => {
  const { t } = useTranslation('pageTransfer')

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={`${t('Transfer')} JUNA`}>
      <S.TransferCard>
        <S.Header>
          <S.Title>{t('Transfer')}</S.Title>
        </S.Header>
        <TransferForm />
      </S.TransferCard>
    </PageWrapper>
  )
}
