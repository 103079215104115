import styled from 'styled-components'
import { PinGuard as PinGuardComponent } from 'features/PinGuard/PinGuard'
// todo: Container should use named export
import Container from 'ui/Container/Container'

export const PinGuardWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PinGuardContainer = styled(Container)`
  display: flex;
  justify-content: center;
`

export const PinGuard = styled(PinGuardComponent)`
  flex-shrink: 0;
  max-width: 400px;
  width: 100%;
`
