import { ChangeEvent, FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutsideClick } from 'hooks'
import * as S from './style'

type AddressDropdownProps = {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  onDelete: () => void
  addressType: 'member' | 'legalGate'
  value: string
}

export const AddressDropdown: FC<AddressDropdownProps> = (props) => {
  const { onChange, onClose, onDelete, addressType, value } = props

  const { t } = useTranslation('pageContracts')

  const addressInputRef = useRef<HTMLDivElement>(null)

  useOutsideClick(addressInputRef, onClose)

  return (
    <S.Wrapper ref={addressInputRef}>
      <S.Label>
        {t('Type address', {
          addressType: addressType === 'member' ? t('member') : t('legal gate'),
        })}
      </S.Label>
      <S.Input
        name={addressType}
        onChange={(evt) => onChange(evt)}
        placeholder="0xED2d....A1c8"
        value={value}
      />
      <S.DeleteButton onClick={onDelete}>{t('Delete filter')}</S.DeleteButton>
    </S.Wrapper>
  )
}
