import styled from 'styled-components'
import { ConstructorInput } from 'libs/constructor'
import { SvgAtomClose, SvgTemplate } from 'ui/icons'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
`

export const FileWrapper = styled.div<{ isRequired?: boolean }>`
  background-color: ${({ theme, isRequired }) =>
    isRequired ? theme.color.lightYellow : theme.color.mirageLight};
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const InfoWrapper = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.color.mirageDark};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.mirageDark};
  &:hover {
    fill: ${({ theme }) => theme.color.black};
  }
`

export const FileInputWrapper = styled(ConstructorInput)`
  display: none;
`

export const UploadedSignatureFileWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const UploadedFileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const UploadedFileNameWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-size: ${({ isBold }) => (isBold ? '15px' : '12px')};
  font-weight: 500;
  line-height: 12px;
  color: ${({ theme, isBold }) =>
    isBold ? theme.color.black : theme.color.mirageDark};
`

export const ActionButton = styled.div<{ isReject?: boolean }>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid
    ${({ theme, isReject }) => (isReject ? theme.color.red : theme.color.line)};
  color: ${({ theme, isReject }) =>
    isReject ? theme.color.red : theme.color.black};
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const FileIcon = styled(SvgTemplate)`
  path {
    fill: ${({ theme }) => theme.color.mirageDark};
  }
`

export const FileInputContainer = styled(ConstructorInput)`
  display: none;
`
