import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.41764 2.47245C7.60963 2.20303 8.85678 2.32615 9.97308 2.82344C10.4776 3.04818 11.0687 2.8214 11.2935 2.31691C11.5182 1.81242 11.2914 1.22126 10.7869 0.996519C9.27667 0.323724 7.58938 0.157155 5.97672 0.521656C4.36405 0.886158 2.91242 1.7622 1.83832 3.01912C0.764215 4.27605 0.125198 5.84651 0.0165713 7.49628C-0.0920556 9.14605 0.335527 10.7867 1.23555 12.1737C2.13557 13.5606 3.45981 14.6194 5.01076 15.1922C6.56171 15.765 8.25627 15.8211 9.84171 15.3521C11.4272 14.8832 12.8185 13.9143 13.8083 12.5899C14.7981 11.2656 15.3333 9.65677 15.334 8.00342V7.38998C15.334 6.83769 14.8863 6.38998 14.334 6.38998C13.7817 6.38998 13.334 6.83769 13.334 7.38998V8.00253C13.3334 9.22443 12.9378 10.4139 12.2063 11.3926C11.4747 12.3715 10.4463 13.0876 9.27442 13.4343C8.10256 13.7809 6.85003 13.7394 5.70366 13.316C4.55729 12.8927 3.57849 12.11 2.91325 11.0849C2.248 10.0598 1.93196 8.8471 2.01225 7.62768C2.09254 6.40827 2.56486 5.24747 3.35878 4.31843C4.15269 3.38938 5.22565 2.74187 6.41764 2.47245ZM15.0414 3.37671C15.4318 2.98601 15.4315 2.35285 15.0408 1.9625C14.6501 1.57215 14.0169 1.57244 13.6266 1.96314L7.66667 7.92839L6.3741 6.63582C5.98358 6.24529 5.35041 6.24529 4.95989 6.63582C4.56936 7.02634 4.56936 7.65951 4.95989 8.05003L6.95989 10.05C7.14748 10.2376 7.40192 10.343 7.66722 10.3429C7.93251 10.3429 8.18691 10.2374 8.37442 10.0497L15.0414 3.37671Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgCheckCircle)
export default Memo
