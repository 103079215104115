import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useProviderModal } from 'hooks'
import * as S from './style'

export const ConnectWalletBlock: FC = () => {
  const { t } = useTranslation('pageHome')

  const { toggleProviderModal } = useProviderModal()

  return (
    <S.Wrapper>
      <S.Label>{t('Please connect your wallet to begin')}</S.Label>
      <S.Button onClick={toggleProviderModal} variant="red">
        {t('Connect wallet')}
      </S.Button>
    </S.Wrapper>
  )
}
