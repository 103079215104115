import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from 'ethers'
import { useTokenContract } from 'hooks/useContract/useContract'

export const useAllowance = (
  token?: string,
  owner?: string,
  spender?: string
) => {
  const contract = useTokenContract(token, false)
  const [allowance, setAllowance] = useState<BigNumber>()
  const loadAllowance = useCallback(async () => {
    if (owner && spender) {
      const result = await contract?.allowance(owner, spender)
      setAllowance(result)
    }
  }, [contract, owner, spender])

  useEffect(() => {
    loadAllowance()
  }, [loadAllowance])

  return allowance
}
