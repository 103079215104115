import { FC } from 'react'
import { AppRoutes } from 'constants/app'
import { SvgToken } from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type AdItemProps = {
  name: string
  description: string
  adId: number
  rating?: string
  address: string | null
  amountJuna?: number
  image?: string | null
}

export const AdItem: FC<AdItemProps> = (props) => {
  const { name, description, adId, address, amountJuna, image } = props

  return (
    <S.Card>
      {image && (
        <S.ImageWrapper>
          <S.Image src={image} />
        </S.ImageWrapper>
      )}
      <S.Info>
        <S.TopBlock>
          <S.TextBlock>
            <S.Title to={`${AppRoutes.Marketplace}/${adId}`}>{name}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TextBlock>
        </S.TopBlock>
        <S.BottomBlock>
          {address && <S.Label>{shortenAddress(address)}</S.Label>}
          <S.Label isBold>
            <SvgToken /> {amountJuna} JUNA
          </S.Label>
        </S.BottomBlock>
      </S.Info>
    </S.Card>
  )
}
