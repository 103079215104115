import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgDeposit, SvgLoan, SvgTokenBlue } from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import * as S from './style'

type BalanceCardProps = {
  loansAmount: number
  depositsAmount: number
  ownFunds: number
}

export const BalanceCard: FC<BalanceCardProps> = (props) => {
  const { loansAmount, depositsAmount, ownFunds } = props

  const { t } = useTranslation('pageBankDashboard')

  return (
    <S.BalanceCard>
      <S.BalanceCardWrapper>
        <S.BalanceCardContent>
          <S.BalanceCardTitle>{t('Own Funds')}</S.BalanceCardTitle>
          <S.BalanceCardSubtitle>
            <SvgTokenBlue /> {formatNumberWithSeparators(ownFunds)}
          </S.BalanceCardSubtitle>
        </S.BalanceCardContent>
        <S.BalanceCardContent>
          <S.BalanceCardTextWrapper>
            <SvgLoan />
            <S.BalanceCardTextContainer>
              <S.BalanceCardTextTitle>
                {t('loans issued')}
              </S.BalanceCardTextTitle>
              <S.BalanceCardTextSubtitle>
                {formatNumberWithSeparators(loansAmount || 0)}
              </S.BalanceCardTextSubtitle>
            </S.BalanceCardTextContainer>
          </S.BalanceCardTextWrapper>
          <S.BalanceCardTextWrapper>
            <SvgDeposit />
            <S.BalanceCardTextContainer>
              <S.BalanceCardTextTitle>
                {t('deposits taken')}
              </S.BalanceCardTextTitle>
              <S.BalanceCardTextSubtitle>
                {formatNumberWithSeparators(depositsAmount || 0)}
              </S.BalanceCardTextSubtitle>
            </S.BalanceCardTextContainer>
          </S.BalanceCardTextWrapper>
        </S.BalanceCardContent>
      </S.BalanceCardWrapper>
    </S.BalanceCard>
  )
}
