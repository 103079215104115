import { useCallback } from 'react'
import { FCWithChildren } from 'types/app'

import * as S from './style'

type LegalGateButtonProps = {
  onClick: () => void
}

export const LegalGateButton: FCWithChildren<LegalGateButtonProps> = ({
  children,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <S.LegalGateButton onClick={handleClick}>
      <S.CloseIcon />
      {children}
    </S.LegalGateButton>
  )
}
