import { useMemo } from 'react'
import { useDispatch, useSelector } from 'hooks'
import { isWalletNetworkModalVisible } from 'store/application/application.selectors'
import { toggleNetworkModal } from 'store/application/application.slice'

type UseNetworkModal = () => {
  isNetworkModalVisible: boolean
  toggleNetworkModal: () => void
}

export const useNetworkModal: UseNetworkModal = () => {
  const dispatch = useDispatch()
  const isVisible = useSelector(isWalletNetworkModalVisible)

  return useMemo(
    () => ({
      isNetworkModalVisible: isVisible,
      toggleNetworkModal: () => dispatch(toggleNetworkModal()),
    }),
    [dispatch, isVisible]
  )
}
