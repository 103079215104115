import { FC } from 'react'
import { useField } from 'formik'
import { ImageUploader } from 'ui/ImageUploader/ImageUploader'
import { FieldWrapper, Error } from '../components'

type FormImageUploaderProps = {
  name: string
  imageValue?: string | null
}

export const FormImageUploader: FC<FormImageUploaderProps> = (props) => {
  const { name, imageValue } = props

  const [field, meta, helpers] = useField(name)

  return (
    <FieldWrapper>
      <ImageUploader
        name={field.name}
        onChange={helpers.setValue}
        value={imageValue}
      />
      <Error message={meta.error} />
    </FieldWrapper>
  )
}
