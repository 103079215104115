import { FC, useCallback } from 'react'
import {
  useAtomAtomActionContractAbiList,
  useAtomAtomActionContractProcessCreate,
} from 'api/omni/endpoints'
import { ActionButton } from 'api/omni/model'
import { useConstructorContext } from 'libs/constructor'

import { notify } from 'libs/notify/notify'
import * as S from './style'

type EmptyAtomInputsProps = {
  id: string
  atomName: string
  buttons: ActionButton[]
}

export const EmptyAtomInputs: FC<EmptyAtomInputsProps> = (props) => {
  const { id: atomId, buttons } = props

  const { currentContract } = useConstructorContext()

  const { data: abi } = useAtomAtomActionContractAbiList(atomId)

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const handleActionButtonClick = useCallback(
    (actionName: string) => {
      const currentAtom = currentContract.atoms.find(({ id }) => atomId === id)

      if (!currentAtom) {
        return
      }

      const abiAction = abi?.find((item) => item.action === actionName)

      const actionInputs = currentAtom.inputs.filter(({ name }) =>
        abiAction?.inputs.find((item) => item.name === name)
      )

      callAction({
        id: atomId,
        data: {
          inputs: actionInputs,
          action: actionName,
        },
      })
    },
    [atomId, callAction, currentContract.atoms, abi]
  )

  return (
    <S.Wrapper>
      {buttons.map((button) => (
        <S.ActionButton
          key={button.name}
          onClick={() => handleActionButtonClick(button.action)}
        >
          {button.label}
        </S.ActionButton>
      ))}
    </S.Wrapper>
  )
}
