import { FC, useState, memo } from 'react'
import { NormalizedCategory } from 'pages/Ads/types'
import { SvgDownArrow } from 'ui/icons'
import * as S from './style'

type CategoryProps = {
  name: string
  id: number | string
  subcategories?: NormalizedCategory[]
  onCategoryChange: (categoryId: number | string) => void
  activeCategory?: number | string
}

export const Category: FC<CategoryProps> = memo((props) => {
  const { name, id, subcategories, onCategoryChange, activeCategory } = props

  const [isSubcategoriesDisplayed, setIsSubcategoriesDisplayed] =
    useState<boolean>(false)

  const toggleSubcategoriesDisplayMode = () => {
    setIsSubcategoriesDisplayed((prev) => !prev)
  }

  return (
    <S.Wrapper>
      <S.FlexWrapper>
        <S.Title
          isActive={id === activeCategory}
          onClick={() => onCategoryChange(id)}
        >
          {name}
        </S.Title>
        {subcategories && (
          <S.Button
            isSubcategoriesDisplayed={isSubcategoriesDisplayed}
            onClick={toggleSubcategoriesDisplayMode}
            variant="transparent"
          >
            <SvgDownArrow />
          </S.Button>
        )}
      </S.FlexWrapper>
      {isSubcategoriesDisplayed &&
        subcategories?.map((subCategory) => (
          <S.SubcategoryLabel
            key={subCategory.id}
            isActive={subCategory.id === activeCategory}
            onClick={() => onCategoryChange(subCategory.id)}
          >
            {subCategory.name}
          </S.SubcategoryLabel>
        ))}
    </S.Wrapper>
  )
})
