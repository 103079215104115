import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { Chip as UIChip } from 'ui/Chip/Chip'

export const TableCard = styled(Card)`
  border-radius: 12px;
  box-shadow: none;
  padding-bottom: 10px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`

export const TableCardTitle = styled.h3`
  color: ${({ theme }) => theme.color.black};
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 6px;
  padding-left: 24px;
`

export const TableWrapper = styled.div`
  width: 100%;
  max-height: 784px;
  overflow-y: scroll;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.white};
  }

  ::-webkit-scrollbar-thumb {
    background: #eee;
    border-radius: 31px;
  }
`

export const TableContent = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableBody = styled.tbody`
  width: 100%;
`

export const TableRow = styled.tr`
  width: 100%;

  &:hover {
    background-color: #f7f8f9;
    cursor: pointer;
    border-radius: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #efeff5;
  }
`

export const TableHeader = styled.th`
  color: ${({ theme }) => theme.color.gray};
  font-size: 13px;
  font-weight: 400;
  text-align: start;
  padding: 12px 13px;
  border-bottom: 1px solid #efeff5;
`

export const TableData = styled.td`
  color: ${({ theme }) => theme.color.black};
  font-size: 14px;
  font-weight: 500;
  padding: 16px 13px;
`

export const TableDataAddress = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-size: 13px;
  padding: 2px 10px;
  border-radius: 6px;
  background: #eee;
`

export const ChipWrapper = styled.div`
  padding: 16px 0 16px 13px;
`

export const Chip = styled(UIChip)`
  max-width: max-content;
  border-radius: 6px;
`

export const TableDataStatus = styled.span<{ status: string }>`
  color: ${({ status, theme }) =>
    status === 'payment' ? '#2BBE5D' : theme.color.red};
  font-size: 13px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 6px;
  background: ${({ status }) =>
    status === 'payment' ? 'rgba(43, 190, 93, 0.20)' : '#FFEFED'};
`

export const NotFoundItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.black};
  font-size: 14px;
  font-weight: 500;
  padding: 16px 13px 0;
`
