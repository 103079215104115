import { FC } from 'react'
import { Step } from './components'
import * as S from './style'
import { StepperProps } from './types'

export const Stepper: FC<StepperProps> = (props) => {
  const { steps, activeStep, className, vertical = false } = props

  return (
    <S.StepperWrapper className={className} vertical={vertical}>
      {steps.map(({ label, step, title, onClick }, idx) => {
        const isCompleted = activeStep > step
        const isCurrent = activeStep === step

        const withButtons = steps.some((step) => step.onClick !== undefined)

        if (idx === steps.length - 1 || vertical) {
          return (
            <Step
              key={step}
              isCompleted={isCompleted}
              isCurrent={isCurrent}
              label={label}
              onClick={onClick}
              step={step}
              title={title}
              vertical
              withButtons={withButtons}
            />
          )
        }

        return (
          <S.StepWrapper key={step}>
            <Step
              isCompleted={isCompleted}
              isCurrent={isCurrent}
              label={label}
              onClick={onClick}
              step={step}
              title={title}
              vertical
            />
            <S.StepLine isCompleted={isCompleted} isCurrent={isCurrent} />
          </S.StepWrapper>
        )
      })}
    </S.StepperWrapper>
  )
}
