import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { Input } from 'ui/Input/Input'

export const Container = styled(Card)`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const FieldsContainer = styled.div`
  width: 100%;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme, isBold }) =>
    isBold ? theme.color.black : theme.color.gray};
`

export const ExtendedFieldsContainer = styled(FieldsContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const UsersFieldsContainer = styled(ExtendedFieldsContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const NumberInputs = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ShortenedInput = styled(Input)`
  input {
    max-width: 387px;
    width: 100%;
  }
`

export const CountInUsdLabel = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
