import { ReactElement, useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FCWithChildren } from 'types/app'
import { Card } from 'ui/Card/Card'
import { ModalTitle } from './components'
import * as S from './style'

type ModalProps = {
  className?: string
  onClose: () => void
  title: string | ReactElement
}

const PORTAL_TARGET = 'portal'

const portalElement = document.getElementById(PORTAL_TARGET) as HTMLElement

const Modal: FCWithChildren<ModalProps> = (props) => {
  const { children, className, onClose, title } = props

  const outsideRef = useRef(null)

  const handleWrapperClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (evt.target === outsideRef.current) {
      onClose()
    }
  }

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return ReactDOM.createPortal(
    <S.WrapModal ref={outsideRef} onClick={handleWrapperClick}>
      <Card className={className}>
        <S.Header>
          {typeof title === 'string' ? <ModalTitle>{title}</ModalTitle> : title}
          <S.Close onClick={onClose} />
        </S.Header>
        {children}
      </Card>
    </S.WrapModal>,
    portalElement
  )
}

export { Modal, ModalTitle }
