import {
  ChangeEvent,
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import {
  useAtomWorkspaceCreate,
  useAtomWorkspaceList,
} from 'api/omni/endpoints'
import { Workspace } from 'api/omni/model'
import { useOutsideClick } from 'hooks'
import { useConstructorContext } from 'libs/constructor/hooks'
import { WorkspaceSelect } from './components'
import * as S from './style'

export const ConstructorWorkSpacesInput: FC = () => {
  const [createdWorkspaces, setCreatedWorkspaces] = useState<Workspace[]>([])
  const [typedWorkspaceName, setTypedWorkspaceName] = useState<string>('')
  const [isWorkspacesSelectDisplayed, setIsWorkspacesSelectDisplayed] =
    useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const inputWrapperRef = useRef<HTMLDivElement>(null)

  const { addWorkspace, removeWorkspace } = useConstructorContext()

  const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setTypedWorkspaceName(evt.target.value)
  }, [])

  useOutsideClick(inputWrapperRef, () => setIsWorkspacesSelectDisplayed(false))

  const { data: workspaces } = useAtomWorkspaceList()

  const { mutate: createWorkspace, isLoading: isWorkspaceCreating } =
    useAtomWorkspaceCreate({
      mutation: {
        onSuccess: (data) => {
          addWorkspace(data.id)
          setCreatedWorkspaces((prev) => [...prev, data])
          setTypedWorkspaceName('')
        },
      },
    })

  const toggleWorkspaceSelectList = useCallback(() => {
    setIsWorkspacesSelectDisplayed((prev) => !prev)
  }, [])

  const handleEnterKeyPress = useCallback(
    (evt: KeyboardEvent) => {
      if (
        evt.key === 'Enter' &&
        typedWorkspaceName !== '' &&
        !isWorkspaceCreating
      ) {
        createWorkspace({
          data: {
            name: typedWorkspaceName,
          },
        })
      }
    },
    [createWorkspace, isWorkspaceCreating, typedWorkspaceName]
  )

  useEffect(() => {
    const inputElement = inputRef.current

    if (inputElement) {
      inputElement.addEventListener('keydown', handleEnterKeyPress)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleEnterKeyPress)
      }
    }
  }, [handleEnterKeyPress])

  const handleRemoveWorkspaceClick = useCallback(
    (workspaceId: string) => {
      setCreatedWorkspaces((prev) =>
        prev.filter(({ id }) => id !== workspaceId)
      )
      removeWorkspace(workspaceId)
    },
    [removeWorkspace]
  )

  const handleWorkspaceListItemSelect = useCallback(
    (workspace: Workspace) => {
      addWorkspace(workspace.id)

      setCreatedWorkspaces((prev) => [...prev, workspace])

      toggleWorkspaceSelectList()
    },
    [addWorkspace, toggleWorkspaceSelectList]
  )

  return (
    <S.WorkspaceContainer ref={inputWrapperRef}>
      <S.Label>You can add your contract to workspace</S.Label>
      <S.Wrapper isLoading={isWorkspaceCreating}>
        {createdWorkspaces.length > 0 && (
          <S.WorkspacesWrapper>
            {createdWorkspaces.map((workspace) => (
              <S.WorkspaceBlock key={workspace.id}>
                {workspace.name}
                <S.CloseIcon
                  onClick={() => handleRemoveWorkspaceClick(workspace.id)}
                />
              </S.WorkspaceBlock>
            ))}
          </S.WorkspacesWrapper>
        )}

        <S.InputContainer
          ref={inputRef}
          onChange={handleChange}
          onFocus={toggleWorkspaceSelectList}
          placeholder="Type workspace name"
          value={typedWorkspaceName}
        />
      </S.Wrapper>
      {isWorkspacesSelectDisplayed && workspaces && (
        <WorkspaceSelect
          onSelect={handleWorkspaceListItemSelect}
          workspaces={workspaces}
        />
      )}
    </S.WorkspaceContainer>
  )
}
