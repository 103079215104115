import { FC, useState, useMemo, useCallback } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useAtomCategoryList, useAtomTemplatesList } from 'api/omni/endpoints'
import { Category } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { notify } from 'libs/notify/notify'
import { CategoriesList } from 'ui/CategoriesList/CategoriesList'
import { Loader } from 'ui/Loader/Loader'
import { Overlay } from 'ui/Overlay/Overlay'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { CatalogCard } from './components'
import * as S from './style'

const ITEMS_ON_PAGE = 9

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Contracts Catalog',
    url: AppRoutes.Catalog,
  },
]

export const Catalog: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [currentCategory, setCurrentCategory] = useState<Category>()

  const { t } = useTranslation('pageCatalog')

  const {
    data: atomContracts,
    isLoading: isAtomContractsLoading,
    isFetching: isAtomContractsFetching,
    isSuccess: isAtomContractsLoaded,
  } = useAtomTemplatesList(
    {
      page: currentPage,
      size: ITEMS_ON_PAGE,
      category: currentCategory?.id,
    },
    {
      query: {
        keepPreviousData: true,
        onError: (err: AxiosError) => {
          return notify.error({
            message: err.message,
            title: err.name,
          })
        },
      },
    }
  )

  const { data: categories, isSuccess: isCategoriesLoaded } =
    useAtomCategoryList()

  const handleCategoryChange = useCallback(
    (categoryId: string | number) => {
      const selectedCategory = categories?.find(
        (category) => category.id === categoryId
      )

      if (selectedCategory) {
        setCurrentCategory(selectedCategory)
      }
    },
    [categories]
  )

  const totalPages = useMemo(() => {
    if (!isAtomContractsLoaded) {
      return
    }

    return atomContracts.count / ITEMS_ON_PAGE
  }, [atomContracts, isAtomContractsLoaded])

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={t('Contracts Catalog')}>
      <S.Wrapper>
        <S.SearchWrapper>
          {isCategoriesLoaded && (
            <S.CategoryContainer>
              <S.CardTitle>Categories</S.CardTitle>
              <CategoriesList
                categories={categories.map((category) => ({
                  id: category.id!,
                  name: category.name,
                }))}
                onCategoryChange={handleCategoryChange}
              />
            </S.CategoryContainer>
          )}
        </S.SearchWrapper>
        {isAtomContractsLoading && <Loader />}
        {isAtomContractsLoaded && (
          <Overlay isLoading={isAtomContractsFetching}>
            <S.RightSideWrapper>
              <S.CardsWrapper>
                {atomContracts.results.map(
                  ({ name, description, id, countReferences }) => (
                    <CatalogCard
                      key={id}
                      contractId={id}
                      countReferences={countReferences}
                      description={description!}
                      title={name}
                    />
                  )
                )}
                {atomContracts.results.length === 0 && (
                  <S.NoContractsWrapper>
                    <S.Label>No contracts</S.Label>
                  </S.NoContractsWrapper>
                )}
              </S.CardsWrapper>
              {!!totalPages && totalPages > 1 && (
                <S.PaginationWrapper>
                  <S.TotalItemsLabel>
                    {t('Total items', { count: atomContracts.count })}
                  </S.TotalItemsLabel>
                  <Pagination
                    currentPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    totalPages={totalPages}
                  />
                </S.PaginationWrapper>
              )}
            </S.RightSideWrapper>
          </Overlay>
        )}
      </S.Wrapper>
    </PageWrapper>
  )
}
