/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Juna API
 * JUNA: decentralized system of economic relations.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useMutation } from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query'
import type {
  TokenRefresh,
  TokenRequest,
  TokenRefreshRequest,
  Web3ConfirmRequest,
  Web3InitRequest,
  IsNeedAction,
  AccountPinChangeCreate204,
  ChangePinRequest,
  CheckPinResponse,
  PinRequest,
  AccountPinResetFinishCreate204,
  FinishPinResetRequest,
  SessionResponse,
  InitPinResetRequest,
  User,
  FinishPinSetupRequest,
  InitPinRequest,
  AccountPinSetResendCreate204,
  ResendPinSetupEmailRequest,
  PaginatedUserList,
  AccountUsersListParams,
  UserUpdate,
  UserUpdateRequest,
  PatchedUserUpdateRequest,
  SetReferrerRequest,
  Whoami,
  AtomAction,
  AbiResponseRow,
  AtomActionCallResult,
  AtomAtomActionContractCallRetrieveParams,
  AtomActionProcessResult,
  AtomActionCallRequest,
  AtomAtomActionRetrieveParams,
  PaginatedContractItemViewList,
  AtomAtomContractListParams,
  GetContractTree,
  ContractItem,
  AtomContractUpdateRequest,
  PatchedAtomContractUpdateRequest,
  AcceptContractRequest,
  RejectRequest,
  PaginatedContractItemList,
  SetStatusRequest,
  AtomAtomContractStatusUpdateParams,
  PaginatedAACDocList,
  AtomAtomDocumentListParams,
  AACDoc,
  AACDocCreateRequest,
  SignFileRequest,
  Category,
  ConstructResponse,
  ConstructorRequest,
  PaginatedContractLogList,
  AtomCtrLogsListParams,
  Document,
  DocumentCreateRequest,
  StatusCountRow,
  PaginatedTemplateContractItemViewList,
  AtomTemplatesListParams,
  WhookRequest,
  Workspace,
  WorkspaceRequest,
  WorkspaceAmountStats,
  PaginatedBankList,
  BanksBankListParams,
  Bank,
  BankUpdate,
  BankUpdateRequest,
  PatchedBankUpdateRequest,
  BankBalanceSheet,
  SimpleError,
  ClaimDepositFromRsRequest,
  CreditToRsRequest,
  CreditReturnToRsRequest,
  DepositToRsRequest,
  OwnBankInfo,
  PaginatedOwnBankTransactionList,
  BanksOwnTransactionListParams,
  PaginatedBankTransactionList,
  FinanceContractListParams,
  BankTransactionDetailed,
  BankTransSummary,
  Blockchain,
  Bridge,
  ForwarderRelayerContract,
  JunaToken,
  NetworkJunatokenListParams,
  Relayer,
  CallResult,
  CallRequest,
  EstimationResponse,
  EstimateRequest,
  TxData,
  SendTxRequest,
  SendFRTxRequest,
  Validator,
  PaginatedAdList,
  PlatformAdsListParams,
  Ad,
  AdCreateRequest,
  AdsCategory,
  PlatformAdsCategoryListParams,
  AdImage,
  PlatformAdsImageListParams,
  AdImageRequest,
  AdRequest,
  PatchedAdRequest,
  PlatformBankListParams,
  PaginatedContactList,
  PlatformContactListParams,
  Contact,
  ContactCreateRequest,
  ContactRequest,
  PatchedContactRequest,
  PhoneContactSyncRequest,
  ContactSyncStatus,
  PaginatedContractList,
  PlatformContractListParams,
  Contract,
  CreateContractRequest,
  PatchedUpdateContractRequest,
  TxHashRequest,
  ContractProcessRequest,
  ContractTransaction,
  PlatformContracttxListParams,
  Jurisdiction,
  PaginatedLaunchpadRequestList,
  PlatformLaunchpadRequestsListParams,
  LaunchpadRequestDetailed,
  PaginatedLGList,
  PlatformLegalgateListParams,
  Lg,
  LGCreateRequest,
  LegalGateCategory,
  PaginatedLegalGateCommentList,
  PlatformLegalgatecommentListParams,
  LegalGateComment,
  LegalGateCommentCreateRequest,
  PaginatedLGContractList,
  PlatformLegalgatecontractListParams,
  PaginatedProjectList,
  PlatformProjectListParams,
  Project,
  ProjectCreateRequest,
  Statistics,
  PlatformStatsStatusRetrieveParams,
  PaginatedStoryList,
  PlatformStoryListParams,
  Story,
  FaucetRequest,
  PaginatedProposalList,
  ProposalsProposalListParams,
  Proposal,
  ProposalRequest,
  Vote,
  VoteRequest,
  SchemaRetrieve200One,
  SchemaRetrieve200Two,
  SchemaRetrieve200Three,
  SchemaRetrieve200Four,
  SchemaRetrieveParams,
} from '../model'
import { customFormData } from '../mutator/custom-form-data'
import { omnniAxiosMutator } from '../mutator/omni-axios-mutator'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * Obtain JWT token
 */
export const accountAuthObtainTokenCreate = (
  tokenRequest: TokenRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TokenRefresh>(
    {
      url: `/account/auth/obtain-token/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRequest,
    },
    options
  )
}

export type AccountAuthObtainTokenCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountAuthObtainTokenCreate>>
>
export type AccountAuthObtainTokenCreateMutationBody = TokenRequest
export type AccountAuthObtainTokenCreateMutationError = unknown

export const useAccountAuthObtainTokenCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountAuthObtainTokenCreate>>,
    TError,
    { data: TokenRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountAuthObtainTokenCreate>>,
    { data: TokenRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountAuthObtainTokenCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountAuthObtainTokenCreate>>,
    TError,
    { data: TokenRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Refresh JWT token
 */
export const accountAuthRefreshTokenCreate = (
  tokenRefreshRequest: TokenRefreshRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TokenRefresh>(
    {
      url: `/account/auth/refresh-token/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRefreshRequest,
    },
    options
  )
}

export type AccountAuthRefreshTokenCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountAuthRefreshTokenCreate>>
>
export type AccountAuthRefreshTokenCreateMutationBody = TokenRefreshRequest
export type AccountAuthRefreshTokenCreateMutationError = unknown

export const useAccountAuthRefreshTokenCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountAuthRefreshTokenCreate>>,
    TError,
    { data: TokenRefreshRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountAuthRefreshTokenCreate>>,
    { data: TokenRefreshRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountAuthRefreshTokenCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountAuthRefreshTokenCreate>>,
    TError,
    { data: TokenRefreshRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Confirm w3 auth session
 */
export const accountAuthWeb3ConfirmCreate = (
  web3ConfirmRequest: Web3ConfirmRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TokenRefresh>(
    {
      url: `/account/auth/web3/confirm/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: web3ConfirmRequest,
    },
    options
  )
}

export type AccountAuthWeb3ConfirmCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountAuthWeb3ConfirmCreate>>
>
export type AccountAuthWeb3ConfirmCreateMutationBody = Web3ConfirmRequest
export type AccountAuthWeb3ConfirmCreateMutationError = unknown

export const useAccountAuthWeb3ConfirmCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountAuthWeb3ConfirmCreate>>,
    TError,
    { data: Web3ConfirmRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountAuthWeb3ConfirmCreate>>,
    { data: Web3ConfirmRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountAuthWeb3ConfirmCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountAuthWeb3ConfirmCreate>>,
    TError,
    { data: Web3ConfirmRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Init w3 auth session
 */
export const accountAuthWeb3InitCreate = (
  web3InitRequest: Web3InitRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    {
      url: `/account/auth/web3/init/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: web3InitRequest,
    },
    options
  )
}

export type AccountAuthWeb3InitCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountAuthWeb3InitCreate>>
>
export type AccountAuthWeb3InitCreateMutationBody = Web3InitRequest
export type AccountAuthWeb3InitCreateMutationError = unknown

export const useAccountAuthWeb3InitCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountAuthWeb3InitCreate>>,
    TError,
    { data: Web3InitRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountAuthWeb3InitCreate>>,
    { data: Web3InitRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountAuthWeb3InitCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountAuthWeb3InitCreate>>,
    TError,
    { data: Web3InitRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Check is user have some actions to do
 */
export const accountIsNeedActionRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<IsNeedAction>(
    { url: `/account/is-need-action/`, method: 'get', signal },
    options
  )
}

export const getAccountIsNeedActionRetrieveQueryKey = () => [
  `/account/is-need-action/`,
]

export type AccountIsNeedActionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountIsNeedActionRetrieve>>
>
export type AccountIsNeedActionRetrieveQueryError = unknown

export const useAccountIsNeedActionRetrieve = <
  TData = Awaited<ReturnType<typeof accountIsNeedActionRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountIsNeedActionRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAccountIsNeedActionRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountIsNeedActionRetrieve>>
  > = ({ signal }) => accountIsNeedActionRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof accountIsNeedActionRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Change pin deletion
 */
export const accountPinChangeCreate = (
  changePinRequest: ChangePinRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<AccountPinChangeCreate204>(
    {
      url: `/account/pin/change/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changePinRequest,
    },
    options
  )
}

export type AccountPinChangeCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinChangeCreate>>
>
export type AccountPinChangeCreateMutationBody = ChangePinRequest
export type AccountPinChangeCreateMutationError = unknown

export const useAccountPinChangeCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinChangeCreate>>,
    TError,
    { data: ChangePinRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinChangeCreate>>,
    { data: ChangePinRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinChangeCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinChangeCreate>>,
    TError,
    { data: ChangePinRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Check pin view
 */
export const accountPinCheckCreate = (
  pinRequest: PinRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<CheckPinResponse>(
    {
      url: `/account/pin/check/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: pinRequest,
    },
    options
  )
}

export type AccountPinCheckCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinCheckCreate>>
>
export type AccountPinCheckCreateMutationBody = PinRequest
export type AccountPinCheckCreateMutationError = unknown

export const useAccountPinCheckCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinCheckCreate>>,
    TError,
    { data: PinRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinCheckCreate>>,
    { data: PinRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinCheckCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinCheckCreate>>,
    TError,
    { data: PinRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Finish pin deletion
 */
export const accountPinResetFinishCreate = (
  finishPinResetRequest: FinishPinResetRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<AccountPinResetFinishCreate204>(
    {
      url: `/account/pin/reset/finish/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: finishPinResetRequest,
    },
    options
  )
}

export type AccountPinResetFinishCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinResetFinishCreate>>
>
export type AccountPinResetFinishCreateMutationBody = FinishPinResetRequest
export type AccountPinResetFinishCreateMutationError = unknown

export const useAccountPinResetFinishCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinResetFinishCreate>>,
    TError,
    { data: FinishPinResetRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinResetFinishCreate>>,
    { data: FinishPinResetRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinResetFinishCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinResetFinishCreate>>,
    TError,
    { data: FinishPinResetRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Init pin deletion
 */
export const accountPinResetInitCreate = (
  initPinResetRequest: InitPinResetRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<SessionResponse>(
    {
      url: `/account/pin/reset/init/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: initPinResetRequest,
    },
    options
  )
}

export type AccountPinResetInitCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinResetInitCreate>>
>
export type AccountPinResetInitCreateMutationBody = InitPinResetRequest
export type AccountPinResetInitCreateMutationError = unknown

export const useAccountPinResetInitCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinResetInitCreate>>,
    TError,
    { data: InitPinResetRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinResetInitCreate>>,
    { data: InitPinResetRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinResetInitCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinResetInitCreate>>,
    TError,
    { data: InitPinResetRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Finish Set pin view
 */
export const accountPinSetFinishCreate = (
  finishPinSetupRequest: FinishPinSetupRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<User>(
    {
      url: `/account/pin/set/finish/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: finishPinSetupRequest,
    },
    options
  )
}

export type AccountPinSetFinishCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinSetFinishCreate>>
>
export type AccountPinSetFinishCreateMutationBody = FinishPinSetupRequest
export type AccountPinSetFinishCreateMutationError = unknown

export const useAccountPinSetFinishCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinSetFinishCreate>>,
    TError,
    { data: FinishPinSetupRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinSetFinishCreate>>,
    { data: FinishPinSetupRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinSetFinishCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinSetFinishCreate>>,
    TError,
    { data: FinishPinSetupRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Init Set pin view
 */
export const accountPinSetInitCreate = (
  initPinRequest: InitPinRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<SessionResponse>(
    {
      url: `/account/pin/set/init/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: initPinRequest,
    },
    options
  )
}

export type AccountPinSetInitCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinSetInitCreate>>
>
export type AccountPinSetInitCreateMutationBody = InitPinRequest
export type AccountPinSetInitCreateMutationError = unknown

export const useAccountPinSetInitCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinSetInitCreate>>,
    TError,
    { data: InitPinRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinSetInitCreate>>,
    { data: InitPinRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinSetInitCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinSetInitCreate>>,
    TError,
    { data: InitPinRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Finish Set pin view
 */
export const accountPinSetResendCreate = (
  resendPinSetupEmailRequest: ResendPinSetupEmailRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<AccountPinSetResendCreate204>(
    {
      url: `/account/pin/set/resend/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resendPinSetupEmailRequest,
    },
    options
  )
}

export type AccountPinSetResendCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountPinSetResendCreate>>
>
export type AccountPinSetResendCreateMutationBody = ResendPinSetupEmailRequest
export type AccountPinSetResendCreateMutationError = unknown

export const useAccountPinSetResendCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountPinSetResendCreate>>,
    TError,
    { data: ResendPinSetupEmailRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountPinSetResendCreate>>,
    { data: ResendPinSetupEmailRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountPinSetResendCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountPinSetResendCreate>>,
    TError,
    { data: ResendPinSetupEmailRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * User
 */
export const accountUsersList = (
  params?: AccountUsersListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedUserList>(
    { url: `/account/users/`, method: 'get', params, signal },
    options
  )
}

export const getAccountUsersListQueryKey = (
  params?: AccountUsersListParams
) => [`/account/users/`, ...(params ? [params] : [])]

export type AccountUsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersList>>
>
export type AccountUsersListQueryError = unknown

export const useAccountUsersList = <
  TData = Awaited<ReturnType<typeof accountUsersList>>,
  TError = unknown
>(
  params?: AccountUsersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof accountUsersList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountUsersListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountUsersList>>
  > = ({ signal }) => accountUsersList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof accountUsersList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * User
 */
export const accountUsersRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<User>(
    { url: `/account/users/${id}/`, method: 'get', signal },
    options
  )
}

export const getAccountUsersRetrieveQueryKey = (id: number) => [
  `/account/users/${id}/`,
]

export type AccountUsersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersRetrieve>>
>
export type AccountUsersRetrieveQueryError = unknown

export const useAccountUsersRetrieve = <
  TData = Awaited<ReturnType<typeof accountUsersRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof accountUsersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountUsersRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountUsersRetrieve>>
  > = ({ signal }) => accountUsersRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof accountUsersRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * User
 */
export const accountUsersUpdate = (
  id: number,
  userUpdateRequest: UserUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<UserUpdate>(
    {
      url: `/account/users/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: userUpdateRequest,
    },
    options
  )
}

export type AccountUsersUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersUpdate>>
>
export type AccountUsersUpdateMutationBody = UserUpdateRequest
export type AccountUsersUpdateMutationError = unknown

export const useAccountUsersUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountUsersUpdate>>,
    TError,
    { id: number; data: UserUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountUsersUpdate>>,
    { id: number; data: UserUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return accountUsersUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountUsersUpdate>>,
    TError,
    { id: number; data: UserUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * User
 */
export const accountUsersPartialUpdate = (
  id: number,
  patchedUserUpdateRequest: PatchedUserUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<UserUpdate>(
    {
      url: `/account/users/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedUserUpdateRequest,
    },
    options
  )
}

export type AccountUsersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersPartialUpdate>>
>
export type AccountUsersPartialUpdateMutationBody = PatchedUserUpdateRequest
export type AccountUsersPartialUpdateMutationError = unknown

export const useAccountUsersPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountUsersPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountUsersPartialUpdate>>,
    { id: number; data: PatchedUserUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return accountUsersPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountUsersPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Set referrer for user
---
 */
export const accountUsersReferrerCreate = (
  setReferrerRequest: SetReferrerRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<User>(
    {
      url: `/account/users/referrer/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setReferrerRequest,
    },
    options
  )
}

export type AccountUsersReferrerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersReferrerCreate>>
>
export type AccountUsersReferrerCreateMutationBody = SetReferrerRequest
export type AccountUsersReferrerCreateMutationError = unknown

export const useAccountUsersReferrerCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountUsersReferrerCreate>>,
    TError,
    { data: SetReferrerRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountUsersReferrerCreate>>,
    { data: SetReferrerRequest }
  > = (props) => {
    const { data } = props ?? {}

    return accountUsersReferrerCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof accountUsersReferrerCreate>>,
    TError,
    { data: SetReferrerRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get self
---
 */
export const accountUsersWhoamiRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Whoami>(
    { url: `/account/users/whoami/`, method: 'get', signal },
    options
  )
}

export const getAccountUsersWhoamiRetrieveQueryKey = () => [
  `/account/users/whoami/`,
]

export type AccountUsersWhoamiRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountUsersWhoamiRetrieve>>
>
export type AccountUsersWhoamiRetrieveQueryError = unknown

export const useAccountUsersWhoamiRetrieve = <
  TData = Awaited<ReturnType<typeof accountUsersWhoamiRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountUsersWhoamiRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAccountUsersWhoamiRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountUsersWhoamiRetrieve>>
  > = ({ signal }) => accountUsersWhoamiRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof accountUsersWhoamiRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * AtomAction view set.
---
 */
export const atomAtomActionList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AtomAction[]>(
    { url: `/atom/atom-action/`, method: 'get', signal },
    options
  )
}

export const getAtomAtomActionListQueryKey = () => [`/atom/atom-action/`]

export type AtomAtomActionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomActionList>>
>
export type AtomAtomActionListQueryError = unknown

export const useAtomAtomActionList = <
  TData = Awaited<ReturnType<typeof atomAtomActionList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof atomAtomActionList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomAtomActionListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomActionList>>
  > = ({ signal }) => atomAtomActionList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomActionList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Get ABI
---
 */
export const atomAtomActionContractAbiList = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AbiResponseRow[]>(
    { url: `/atom/atom-action-contract/${id}/abi/`, method: 'get', signal },
    options
  )
}

export const getAtomAtomActionContractAbiListQueryKey = (id: string) => [
  `/atom/atom-action-contract/${id}/abi/`,
]

export type AtomAtomActionContractAbiListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomActionContractAbiList>>
>
export type AtomAtomActionContractAbiListQueryError = unknown

export const useAtomAtomActionContractAbiList = <
  TData = Awaited<ReturnType<typeof atomAtomActionContractAbiList>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomActionContractAbiList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomActionContractAbiListQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomActionContractAbiList>>
  > = ({ signal }) => atomAtomActionContractAbiList(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomActionContractAbiList>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Call AtomActionContract
---
 */
export const atomAtomActionContractCallRetrieve = (
  id: string,
  params: AtomAtomActionContractCallRetrieveParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AtomActionCallResult>(
    {
      url: `/atom/atom-action-contract/${id}/call/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getAtomAtomActionContractCallRetrieveQueryKey = (
  id: string,
  params: AtomAtomActionContractCallRetrieveParams
) => [`/atom/atom-action-contract/${id}/call/`, ...(params ? [params] : [])]

export type AtomAtomActionContractCallRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomActionContractCallRetrieve>>
>
export type AtomAtomActionContractCallRetrieveQueryError = unknown

export const useAtomAtomActionContractCallRetrieve = <
  TData = Awaited<ReturnType<typeof atomAtomActionContractCallRetrieve>>,
  TError = unknown
>(
  id: string,
  params: AtomAtomActionContractCallRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomActionContractCallRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getAtomAtomActionContractCallRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomActionContractCallRetrieve>>
  > = ({ signal }) =>
    atomAtomActionContractCallRetrieve(id, params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomActionContractCallRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Process AtomActionContract
---
 */
export const atomAtomActionContractProcessCreate = (
  id: string,
  atomActionCallRequest: AtomActionCallRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<AtomActionProcessResult>(
    {
      url: `/atom/atom-action-contract/${id}/process/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: atomActionCallRequest,
    },
    options
  )
}

export type AtomAtomActionContractProcessCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomActionContractProcessCreate>>
>
export type AtomAtomActionContractProcessCreateMutationBody =
  AtomActionCallRequest
export type AtomAtomActionContractProcessCreateMutationError = unknown

export const useAtomAtomActionContractProcessCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomActionContractProcessCreate>>,
    TError,
    { id: string; data: AtomActionCallRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomActionContractProcessCreate>>,
    { id: string; data: AtomActionCallRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomActionContractProcessCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomActionContractProcessCreate>>,
    TError,
    { id: string; data: AtomActionCallRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AtomAction view set.
---
 */
export const atomAtomActionRetrieve = (
  id: string,
  params: AtomAtomActionRetrieveParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AtomAction>(
    { url: `/atom/atom-action/${id}/`, method: 'get', params, signal },
    options
  )
}

export const getAtomAtomActionRetrieveQueryKey = (
  id: string,
  params: AtomAtomActionRetrieveParams
) => [`/atom/atom-action/${id}/`, ...(params ? [params] : [])]

export type AtomAtomActionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomActionRetrieve>>
>
export type AtomAtomActionRetrieveQueryError = unknown

export const useAtomAtomActionRetrieve = <
  TData = Awaited<ReturnType<typeof atomAtomActionRetrieve>>,
  TError = unknown
>(
  id: string,
  params: AtomAtomActionRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomActionRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomActionRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomActionRetrieve>>
  > = ({ signal }) => atomAtomActionRetrieve(id, params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomActionRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get all contracts which was created by user
 */
export const atomAtomContractList = (
  params?: AtomAtomContractListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedContractItemViewList>(
    { url: `/atom/atom-contract/`, method: 'get', params, signal },
    options
  )
}

export const getAtomAtomContractListQueryKey = (
  params?: AtomAtomContractListParams
) => [`/atom/atom-contract/`, ...(params ? [params] : [])]

export type AtomAtomContractListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractList>>
>
export type AtomAtomContractListQueryError = unknown

export const useAtomAtomContractList = <
  TData = Awaited<ReturnType<typeof atomAtomContractList>>,
  TError = unknown
>(
  params?: AtomAtomContractListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomContractList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomContractListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomContractList>>
  > = ({ signal }) => atomAtomContractList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomContractList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve contract with children
 */
export const atomAtomContractRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<GetContractTree>(
    { url: `/atom/atom-contract/${id}/`, method: 'get', signal },
    options
  )
}

export const getAtomAtomContractRetrieveQueryKey = (id: string) => [
  `/atom/atom-contract/${id}/`,
]

export type AtomAtomContractRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractRetrieve>>
>
export type AtomAtomContractRetrieveQueryError = unknown

export const useAtomAtomContractRetrieve = <
  TData = Awaited<ReturnType<typeof atomAtomContractRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomContractRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomContractRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomContractRetrieve>>
  > = ({ signal }) => atomAtomContractRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomContractRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * AtomContract view set.
---
 */
export const atomAtomContractUpdate = (
  id: string,
  atomContractUpdateRequest: AtomContractUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ContractItem>(
    {
      url: `/atom/atom-contract/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: atomContractUpdateRequest,
    },
    options
  )
}

export type AtomAtomContractUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractUpdate>>
>
export type AtomAtomContractUpdateMutationBody = AtomContractUpdateRequest
export type AtomAtomContractUpdateMutationError = unknown

export const useAtomAtomContractUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractUpdate>>,
    TError,
    { id: string; data: AtomContractUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractUpdate>>,
    { id: string; data: AtomContractUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomContractUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractUpdate>>,
    TError,
    { id: string; data: AtomContractUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AtomContract view set.
---
 */
export const atomAtomContractPartialUpdate = (
  id: string,
  patchedAtomContractUpdateRequest: PatchedAtomContractUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ContractItem>(
    {
      url: `/atom/atom-contract/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAtomContractUpdateRequest,
    },
    options
  )
}

export type AtomAtomContractPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractPartialUpdate>>
>
export type AtomAtomContractPartialUpdateMutationBody =
  PatchedAtomContractUpdateRequest
export type AtomAtomContractPartialUpdateMutationError = unknown

export const useAtomAtomContractPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractPartialUpdate>>,
    TError,
    { id: string; data: PatchedAtomContractUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractPartialUpdate>>,
    { id: string; data: PatchedAtomContractUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomContractPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractPartialUpdate>>,
    TError,
    { id: string; data: PatchedAtomContractUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AtomContract view set.
---
 */
export const atomAtomContractDestroy = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/atom/atom-contract/${id}/`, method: 'delete' },
    options
  )
}

export type AtomAtomContractDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractDestroy>>
>

export type AtomAtomContractDestroyMutationError = unknown

export const useAtomAtomContractDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractDestroy>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractDestroy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomAtomContractDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractDestroy>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Member accepts contract.
---
 */
export const atomAtomContractAcceptCreate = (
  id: string,
  acceptContractRequest: AcceptContractRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ContractItem>(
    {
      url: `/atom/atom-contract/${id}/accept/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: acceptContractRequest,
    },
    options
  )
}

export type AtomAtomContractAcceptCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractAcceptCreate>>
>
export type AtomAtomContractAcceptCreateMutationBody = AcceptContractRequest
export type AtomAtomContractAcceptCreateMutationError = unknown

export const useAtomAtomContractAcceptCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractAcceptCreate>>,
    TError,
    { id: string; data: AcceptContractRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractAcceptCreate>>,
    { id: string; data: AcceptContractRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomContractAcceptCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractAcceptCreate>>,
    TError,
    { id: string; data: AcceptContractRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AtomContract view set.
---
 */
export const atomAtomContractDeployCreate = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/atom/atom-contract/${id}/deploy/`, method: 'post' },
    options
  )
}

export type AtomAtomContractDeployCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractDeployCreate>>
>

export type AtomAtomContractDeployCreateMutationError = unknown

export const useAtomAtomContractDeployCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractDeployCreate>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractDeployCreate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomAtomContractDeployCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractDeployCreate>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Member reject contract.
---
 */
export const atomAtomContractRejectCreate = (
  id: string,
  rejectRequest: RejectRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ContractItem>(
    {
      url: `/atom/atom-contract/${id}/reject/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: rejectRequest,
    },
    options
  )
}

export type AtomAtomContractRejectCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractRejectCreate>>
>
export type AtomAtomContractRejectCreateMutationBody = RejectRequest
export type AtomAtomContractRejectCreateMutationError = unknown

export const useAtomAtomContractRejectCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractRejectCreate>>,
    TError,
    { id: string; data: RejectRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractRejectCreate>>,
    { id: string; data: RejectRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomContractRejectCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractRejectCreate>>,
    TError,
    { id: string; data: RejectRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AtomContract view set.
---
 */
export const atomAtomContractStatusUpdate = (
  id: string,
  setStatusRequest: SetStatusRequest,
  params?: AtomAtomContractStatusUpdateParams,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<PaginatedContractItemList>(
    {
      url: `/atom/atom-contract/${id}/status/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: setStatusRequest,
      params,
    },
    options
  )
}

export type AtomAtomContractStatusUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractStatusUpdate>>
>
export type AtomAtomContractStatusUpdateMutationBody = SetStatusRequest
export type AtomAtomContractStatusUpdateMutationError = unknown

export const useAtomAtomContractStatusUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractStatusUpdate>>,
    TError,
    {
      id: string
      data: SetStatusRequest
      params?: AtomAtomContractStatusUpdateParams
    },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractStatusUpdate>>,
    {
      id: string
      data: SetStatusRequest
      params?: AtomAtomContractStatusUpdateParams
    }
  > = (props) => {
    const { id, data, params } = props ?? {}

    return atomAtomContractStatusUpdate(id, data, params, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractStatusUpdate>>,
    TError,
    {
      id: string
      data: SetStatusRequest
      params?: AtomAtomContractStatusUpdateParams
    },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Legal gate terminates contract.
---
 */
export const atomAtomContractTerminateCreate = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ContractItem>(
    { url: `/atom/atom-contract/${id}/terminate/`, method: 'post' },
    options
  )
}

export type AtomAtomContractTerminateCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomContractTerminateCreate>>
>

export type AtomAtomContractTerminateCreateMutationError = unknown

export const useAtomAtomContractTerminateCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomContractTerminateCreate>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomContractTerminateCreate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomAtomContractTerminateCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomContractTerminateCreate>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentList = (
  params?: AtomAtomDocumentListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedAACDocList>(
    { url: `/atom/atom-document/`, method: 'get', params, signal },
    options
  )
}

export const getAtomAtomDocumentListQueryKey = (
  params?: AtomAtomDocumentListParams
) => [`/atom/atom-document/`, ...(params ? [params] : [])]

export type AtomAtomDocumentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentList>>
>
export type AtomAtomDocumentListQueryError = unknown

export const useAtomAtomDocumentList = <
  TData = Awaited<ReturnType<typeof atomAtomDocumentList>>,
  TError = unknown
>(
  params?: AtomAtomDocumentListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomDocumentList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomDocumentListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomDocumentList>>
  > = ({ signal }) => atomAtomDocumentList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomDocumentList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentCreate = (
  aACDocCreateRequest: AACDocCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(aACDocCreateRequest)
  return omnniAxiosMutator<AACDoc>(
    {
      url: `/atom/atom-document/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type AtomAtomDocumentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentCreate>>
>
export type AtomAtomDocumentCreateMutationBody = AACDocCreateRequest
export type AtomAtomDocumentCreateMutationError = unknown

export const useAtomAtomDocumentCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomDocumentCreate>>,
    TError,
    { data: AACDocCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomDocumentCreate>>,
    { data: AACDocCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return atomAtomDocumentCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomDocumentCreate>>,
    TError,
    { data: AACDocCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AACDoc>(
    { url: `/atom/atom-document/${id}/`, method: 'get', signal },
    options
  )
}

export const getAtomAtomDocumentRetrieveQueryKey = (id: string) => [
  `/atom/atom-document/${id}/`,
]

export type AtomAtomDocumentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentRetrieve>>
>
export type AtomAtomDocumentRetrieveQueryError = unknown

export const useAtomAtomDocumentRetrieve = <
  TData = Awaited<ReturnType<typeof atomAtomDocumentRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomAtomDocumentRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomAtomDocumentRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomAtomDocumentRetrieve>>
  > = ({ signal }) => atomAtomDocumentRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomAtomDocumentRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentDestroy = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/atom/atom-document/${id}/`, method: 'delete' },
    options
  )
}

export type AtomAtomDocumentDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentDestroy>>
>

export type AtomAtomDocumentDestroyMutationError = unknown

export const useAtomAtomDocumentDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomDocumentDestroy>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomDocumentDestroy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomAtomDocumentDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomDocumentDestroy>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentRejectCreate = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<AACDoc>(
    { url: `/atom/atom-document/${id}/reject/`, method: 'post' },
    options
  )
}

export type AtomAtomDocumentRejectCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentRejectCreate>>
>

export type AtomAtomDocumentRejectCreateMutationError = unknown

export const useAtomAtomDocumentRejectCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomDocumentRejectCreate>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomDocumentRejectCreate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomAtomDocumentRejectCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomDocumentRejectCreate>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AACDoc viewset
 */
export const atomAtomDocumentSignCreate = (
  id: string,
  signFileRequest: SignFileRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(signFileRequest)
  return omnniAxiosMutator<AACDoc>(
    {
      url: `/atom/atom-document/${id}/sign/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type AtomAtomDocumentSignCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomAtomDocumentSignCreate>>
>
export type AtomAtomDocumentSignCreateMutationBody = SignFileRequest
export type AtomAtomDocumentSignCreateMutationError = unknown

export const useAtomAtomDocumentSignCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomAtomDocumentSignCreate>>,
    TError,
    { id: string; data: SignFileRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomAtomDocumentSignCreate>>,
    { id: string; data: SignFileRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return atomAtomDocumentSignCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomAtomDocumentSignCreate>>,
    TError,
    { id: string; data: SignFileRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const atomCategoryList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Category[]>(
    { url: `/atom/category/`, method: 'get', signal },
    options
  )
}

export const getAtomCategoryListQueryKey = () => [`/atom/category/`]

export type AtomCategoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomCategoryList>>
>
export type AtomCategoryListQueryError = unknown

export const useAtomCategoryList = <
  TData = Awaited<ReturnType<typeof atomCategoryList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof atomCategoryList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomCategoryListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomCategoryList>>
  > = ({ signal }) => atomCategoryList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomCategoryList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Create Atom Contracts with constructor.
 */
export const atomConstructCreate = (
  constructorRequest: ConstructorRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<ConstructResponse>(
    {
      url: `/atom/construct/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: constructorRequest,
    },
    options
  )
}

export type AtomConstructCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomConstructCreate>>
>
export type AtomConstructCreateMutationBody = ConstructorRequest
export type AtomConstructCreateMutationError = unknown

export const useAtomConstructCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomConstructCreate>>,
    TError,
    { data: ConstructorRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomConstructCreate>>,
    { data: ConstructorRequest }
  > = (props) => {
    const { data } = props ?? {}

    return atomConstructCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomConstructCreate>>,
    TError,
    { data: ConstructorRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const atomCtrLogsList = (
  params?: AtomCtrLogsListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedContractLogList>(
    { url: `/atom/ctr-logs/`, method: 'get', params, signal },
    options
  )
}

export const getAtomCtrLogsListQueryKey = (params?: AtomCtrLogsListParams) => [
  `/atom/ctr-logs/`,
  ...(params ? [params] : []),
]

export type AtomCtrLogsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomCtrLogsList>>
>
export type AtomCtrLogsListQueryError = unknown

export const useAtomCtrLogsList = <
  TData = Awaited<ReturnType<typeof atomCtrLogsList>>,
  TError = unknown
>(
  params?: AtomCtrLogsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomCtrLogsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomCtrLogsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof atomCtrLogsList>>> = ({
    signal,
  }) => atomCtrLogsList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomCtrLogsList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Atom contract document view set.
---
 */
export const atomDocumentList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Document[]>(
    { url: `/atom/document/`, method: 'get', signal },
    options
  )
}

export const getAtomDocumentListQueryKey = () => [`/atom/document/`]

export type AtomDocumentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomDocumentList>>
>
export type AtomDocumentListQueryError = unknown

export const useAtomDocumentList = <
  TData = Awaited<ReturnType<typeof atomDocumentList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof atomDocumentList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomDocumentListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomDocumentList>>
  > = ({ signal }) => atomDocumentList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomDocumentList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Atom contract document view set.
---
 */
export const atomDocumentCreate = (
  documentCreateRequest: DocumentCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(documentCreateRequest)
  return omnniAxiosMutator<Document>(
    {
      url: `/atom/document/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type AtomDocumentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomDocumentCreate>>
>
export type AtomDocumentCreateMutationBody = DocumentCreateRequest
export type AtomDocumentCreateMutationError = unknown

export const useAtomDocumentCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomDocumentCreate>>,
    TError,
    { data: DocumentCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomDocumentCreate>>,
    { data: DocumentCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return atomDocumentCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomDocumentCreate>>,
    TError,
    { data: DocumentCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Atom contract document view set.
---
 */
export const atomDocumentRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Document>(
    { url: `/atom/document/${id}/`, method: 'get', signal },
    options
  )
}

export const getAtomDocumentRetrieveQueryKey = (id: string) => [
  `/atom/document/${id}/`,
]

export type AtomDocumentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomDocumentRetrieve>>
>
export type AtomDocumentRetrieveQueryError = unknown

export const useAtomDocumentRetrieve = <
  TData = Awaited<ReturnType<typeof atomDocumentRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomDocumentRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomDocumentRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomDocumentRetrieve>>
  > = ({ signal }) => atomDocumentRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomDocumentRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Contract statistics by status
---
 */
export const atomStatsStatusRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<StatusCountRow>(
    { url: `/atom/stats/status/`, method: 'get', signal },
    options
  )
}

export const getAtomStatsStatusRetrieveQueryKey = () => [`/atom/stats/status/`]

export type AtomStatsStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomStatsStatusRetrieve>>
>
export type AtomStatsStatusRetrieveQueryError = unknown

export const useAtomStatsStatusRetrieve = <
  TData = Awaited<ReturnType<typeof atomStatsStatusRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof atomStatsStatusRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomStatsStatusRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomStatsStatusRetrieve>>
  > = ({ signal }) => atomStatsStatusRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomStatsStatusRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const atomTemplatesList = (
  params?: AtomTemplatesListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedTemplateContractItemViewList>(
    { url: `/atom/templates/`, method: 'get', params, signal },
    options
  )
}

export const getAtomTemplatesListQueryKey = (
  params?: AtomTemplatesListParams
) => [`/atom/templates/`, ...(params ? [params] : [])]

export type AtomTemplatesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomTemplatesList>>
>
export type AtomTemplatesListQueryError = unknown

export const useAtomTemplatesList = <
  TData = Awaited<ReturnType<typeof atomTemplatesList>>,
  TError = unknown
>(
  params?: AtomTemplatesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomTemplatesList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomTemplatesListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomTemplatesList>>
  > = ({ signal }) => atomTemplatesList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomTemplatesList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Webhook view
 */
export const atomWhookCreate = (
  whookRequest: WhookRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    {
      url: `/atom/whook/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: whookRequest,
    },
    options
  )
}

export type AtomWhookCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomWhookCreate>>
>
export type AtomWhookCreateMutationBody = WhookRequest
export type AtomWhookCreateMutationError = unknown

export const useAtomWhookCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomWhookCreate>>,
    TError,
    { data: WhookRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomWhookCreate>>,
    { data: WhookRequest }
  > = (props) => {
    const { data } = props ?? {}

    return atomWhookCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomWhookCreate>>,
    TError,
    { data: WhookRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const atomWorkspaceList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Workspace[]>(
    { url: `/atom/workspace/`, method: 'get', signal },
    options
  )
}

export const getAtomWorkspaceListQueryKey = () => [`/atom/workspace/`]

export type AtomWorkspaceListQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomWorkspaceList>>
>
export type AtomWorkspaceListQueryError = unknown

export const useAtomWorkspaceList = <
  TData = Awaited<ReturnType<typeof atomWorkspaceList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof atomWorkspaceList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAtomWorkspaceListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomWorkspaceList>>
  > = ({ signal }) => atomWorkspaceList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomWorkspaceList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const atomWorkspaceCreate = (
  workspaceRequest: WorkspaceRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Workspace>(
    {
      url: `/atom/workspace/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: workspaceRequest,
    },
    options
  )
}

export type AtomWorkspaceCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomWorkspaceCreate>>
>
export type AtomWorkspaceCreateMutationBody = WorkspaceRequest
export type AtomWorkspaceCreateMutationError = unknown

export const useAtomWorkspaceCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomWorkspaceCreate>>,
    TError,
    { data: WorkspaceRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomWorkspaceCreate>>,
    { data: WorkspaceRequest }
  > = (props) => {
    const { data } = props ?? {}

    return atomWorkspaceCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomWorkspaceCreate>>,
    TError,
    { data: WorkspaceRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const atomWorkspaceDestroy = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/atom/workspace/${id}/`, method: 'delete' },
    options
  )
}

export type AtomWorkspaceDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof atomWorkspaceDestroy>>
>

export type AtomWorkspaceDestroyMutationError = unknown

export const useAtomWorkspaceDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof atomWorkspaceDestroy>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof atomWorkspaceDestroy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return atomWorkspaceDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof atomWorkspaceDestroy>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

export const atomWorkspaceAmountStatsRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<WorkspaceAmountStats>(
    { url: `/atom/workspace/${id}/amount-stats/`, method: 'get', signal },
    options
  )
}

export const getAtomWorkspaceAmountStatsRetrieveQueryKey = (id: string) => [
  `/atom/workspace/${id}/amount-stats/`,
]

export type AtomWorkspaceAmountStatsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof atomWorkspaceAmountStatsRetrieve>>
>
export type AtomWorkspaceAmountStatsRetrieveQueryError = unknown

export const useAtomWorkspaceAmountStatsRetrieve = <
  TData = Awaited<ReturnType<typeof atomWorkspaceAmountStatsRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof atomWorkspaceAmountStatsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAtomWorkspaceAmountStatsRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof atomWorkspaceAmountStatsRetrieve>>
  > = ({ signal }) =>
    atomWorkspaceAmountStatsRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof atomWorkspaceAmountStatsRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const banksBankList = (
  params?: BanksBankListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedBankList>(
    { url: `/banks/bank/`, method: 'get', params, signal },
    options
  )
}

export const getBanksBankListQueryKey = (params?: BanksBankListParams) => [
  `/banks/bank/`,
  ...(params ? [params] : []),
]

export type BanksBankListQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksBankList>>
>
export type BanksBankListQueryError = unknown

export const useBanksBankList = <
  TData = Awaited<ReturnType<typeof banksBankList>>,
  TError = unknown
>(
  params?: BanksBankListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksBankList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBanksBankListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof banksBankList>>> = ({
    signal,
  }) => banksBankList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof banksBankList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const banksBankRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Bank>(
    { url: `/banks/bank/${id}/`, method: 'get', signal },
    options
  )
}

export const getBanksBankRetrieveQueryKey = (id: string) => [
  `/banks/bank/${id}/`,
]

export type BanksBankRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksBankRetrieve>>
>
export type BanksBankRetrieveQueryError = unknown

export const useBanksBankRetrieve = <
  TData = Awaited<ReturnType<typeof banksBankRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksBankRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBanksBankRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksBankRetrieve>>
  > = ({ signal }) => banksBankRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof banksBankRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const banksBankUpdate = (
  id: string,
  bankUpdateRequest: BankUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<BankUpdate>(
    {
      url: `/banks/bank/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: bankUpdateRequest,
    },
    options
  )
}

export type BanksBankUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankUpdate>>
>
export type BanksBankUpdateMutationBody = BankUpdateRequest
export type BanksBankUpdateMutationError = unknown

export const useBanksBankUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankUpdate>>,
    TError,
    { id: string; data: BankUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankUpdate>>,
    { id: string; data: BankUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankUpdate>>,
    TError,
    { id: string; data: BankUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const banksBankPartialUpdate = (
  id: string,
  patchedBankUpdateRequest: PatchedBankUpdateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<BankUpdate>(
    {
      url: `/banks/bank/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedBankUpdateRequest,
    },
    options
  )
}

export type BanksBankPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankPartialUpdate>>
>
export type BanksBankPartialUpdateMutationBody = PatchedBankUpdateRequest
export type BanksBankPartialUpdateMutationError = unknown

export const useBanksBankPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankPartialUpdate>>,
    TError,
    { id: string; data: PatchedBankUpdateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankPartialUpdate>>,
    { id: string; data: PatchedBankUpdateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankPartialUpdate>>,
    TError,
    { id: string; data: PatchedBankUpdateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const banksBankBalanceSheetRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<BankBalanceSheet>(
    { url: `/banks/bank/${id}/balance-sheet/`, method: 'get', signal },
    options
  )
}

export const getBanksBankBalanceSheetRetrieveQueryKey = (id: string) => [
  `/banks/bank/${id}/balance-sheet/`,
]

export type BanksBankBalanceSheetRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksBankBalanceSheetRetrieve>>
>
export type BanksBankBalanceSheetRetrieveQueryError = unknown

export const useBanksBankBalanceSheetRetrieve = <
  TData = Awaited<ReturnType<typeof banksBankBalanceSheetRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksBankBalanceSheetRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getBanksBankBalanceSheetRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksBankBalanceSheetRetrieve>>
  > = ({ signal }) => banksBankBalanceSheetRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof banksBankBalanceSheetRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const banksBankClaimDepositFromRsCreate = (
  id: string,
  claimDepositFromRsRequest: ClaimDepositFromRsRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Bank>(
    {
      url: `/banks/bank/${id}/claimDepositFromRs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: claimDepositFromRsRequest,
    },
    options
  )
}

export type BanksBankClaimDepositFromRsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankClaimDepositFromRsCreate>>
>
export type BanksBankClaimDepositFromRsCreateMutationBody =
  ClaimDepositFromRsRequest
export type BanksBankClaimDepositFromRsCreateMutationError = SimpleError

export const useBanksBankClaimDepositFromRsCreate = <
  TError = SimpleError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankClaimDepositFromRsCreate>>,
    TError,
    { id: string; data: ClaimDepositFromRsRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankClaimDepositFromRsCreate>>,
    { id: string; data: ClaimDepositFromRsRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankClaimDepositFromRsCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankClaimDepositFromRsCreate>>,
    TError,
    { id: string; data: ClaimDepositFromRsRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const banksBankCreditFromRsCreate = (
  id: string,
  creditToRsRequest: CreditToRsRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Bank>(
    {
      url: `/banks/bank/${id}/creditFromRs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: creditToRsRequest,
    },
    options
  )
}

export type BanksBankCreditFromRsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankCreditFromRsCreate>>
>
export type BanksBankCreditFromRsCreateMutationBody = CreditToRsRequest
export type BanksBankCreditFromRsCreateMutationError = SimpleError

export const useBanksBankCreditFromRsCreate = <
  TError = SimpleError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankCreditFromRsCreate>>,
    TError,
    { id: string; data: CreditToRsRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankCreditFromRsCreate>>,
    { id: string; data: CreditToRsRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankCreditFromRsCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankCreditFromRsCreate>>,
    TError,
    { id: string; data: CreditToRsRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const banksBankCreditReturnToRsCreate = (
  id: string,
  creditReturnToRsRequest: CreditReturnToRsRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Bank>(
    {
      url: `/banks/bank/${id}/creditReturnToRs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: creditReturnToRsRequest,
    },
    options
  )
}

export type BanksBankCreditReturnToRsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankCreditReturnToRsCreate>>
>
export type BanksBankCreditReturnToRsCreateMutationBody =
  CreditReturnToRsRequest
export type BanksBankCreditReturnToRsCreateMutationError = SimpleError

export const useBanksBankCreditReturnToRsCreate = <
  TError = SimpleError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankCreditReturnToRsCreate>>,
    TError,
    { id: string; data: CreditReturnToRsRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankCreditReturnToRsCreate>>,
    { id: string; data: CreditReturnToRsRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankCreditReturnToRsCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankCreditReturnToRsCreate>>,
    TError,
    { id: string; data: CreditReturnToRsRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const banksBankDepositToRsCreate = (
  id: string,
  depositToRsRequest: DepositToRsRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Bank>(
    {
      url: `/banks/bank/${id}/depositToRs/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: depositToRsRequest,
    },
    options
  )
}

export type BanksBankDepositToRsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksBankDepositToRsCreate>>
>
export type BanksBankDepositToRsCreateMutationBody = DepositToRsRequest
export type BanksBankDepositToRsCreateMutationError = SimpleError

export const useBanksBankDepositToRsCreate = <
  TError = SimpleError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksBankDepositToRsCreate>>,
    TError,
    { id: string; data: DepositToRsRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksBankDepositToRsCreate>>,
    { id: string; data: DepositToRsRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return banksBankDepositToRsCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof banksBankDepositToRsCreate>>,
    TError,
    { id: string; data: DepositToRsRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get own bank info
 */
export const banksOwnBankRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<OwnBankInfo>(
    { url: `/banks/own/bank/`, method: 'get', signal },
    options
  )
}

export const getBanksOwnBankRetrieveQueryKey = () => [`/banks/own/bank/`]

export type BanksOwnBankRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksOwnBankRetrieve>>
>
export type BanksOwnBankRetrieveQueryError = SimpleError

export const useBanksOwnBankRetrieve = <
  TData = Awaited<ReturnType<typeof banksOwnBankRetrieve>>,
  TError = SimpleError
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof banksOwnBankRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBanksOwnBankRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksOwnBankRetrieve>>
  > = ({ signal }) => banksOwnBankRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof banksOwnBankRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const banksOwnTransactionList = (
  params?: BanksOwnTransactionListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedOwnBankTransactionList>(
    { url: `/banks/own/transaction/`, method: 'get', params, signal },
    options
  )
}

export const getBanksOwnTransactionListQueryKey = (
  params?: BanksOwnTransactionListParams
) => [`/banks/own/transaction/`, ...(params ? [params] : [])]

export type BanksOwnTransactionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksOwnTransactionList>>
>
export type BanksOwnTransactionListQueryError = unknown

export const useBanksOwnTransactionList = <
  TData = Awaited<ReturnType<typeof banksOwnTransactionList>>,
  TError = unknown
>(
  params?: BanksOwnTransactionListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksOwnTransactionList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getBanksOwnTransactionListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksOwnTransactionList>>
  > = ({ signal }) => banksOwnTransactionList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof banksOwnTransactionList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const financeContractList = (
  params?: FinanceContractListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedBankTransactionList>(
    { url: `/finance/contract/`, method: 'get', params, signal },
    options
  )
}

export const getFinanceContractListQueryKey = (
  params?: FinanceContractListParams
) => [`/finance/contract/`, ...(params ? [params] : [])]

export type FinanceContractListQueryResult = NonNullable<
  Awaited<ReturnType<typeof financeContractList>>
>
export type FinanceContractListQueryError = unknown

export const useFinanceContractList = <
  TData = Awaited<ReturnType<typeof financeContractList>>,
  TError = unknown
>(
  params?: FinanceContractListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof financeContractList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFinanceContractListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof financeContractList>>
  > = ({ signal }) => financeContractList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof financeContractList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const financeContractRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<BankTransactionDetailed>(
    { url: `/finance/contract/${id}/`, method: 'get', signal },
    options
  )
}

export const getFinanceContractRetrieveQueryKey = (id: string) => [
  `/finance/contract/${id}/`,
]

export type FinanceContractRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof financeContractRetrieve>>
>
export type FinanceContractRetrieveQueryError = unknown

export const useFinanceContractRetrieve = <
  TData = Awaited<ReturnType<typeof financeContractRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof financeContractRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFinanceContractRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof financeContractRetrieve>>
  > = ({ signal }) => financeContractRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof financeContractRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const financeContractSummaryRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<BankTransSummary>(
    { url: `/finance/contract/summary/`, method: 'get', signal },
    options
  )
}

export const getFinanceContractSummaryRetrieveQueryKey = () => [
  `/finance/contract/summary/`,
]

export type FinanceContractSummaryRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof financeContractSummaryRetrieve>>
>
export type FinanceContractSummaryRetrieveQueryError = unknown

export const useFinanceContractSummaryRetrieve = <
  TData = Awaited<ReturnType<typeof financeContractSummaryRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof financeContractSummaryRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFinanceContractSummaryRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof financeContractSummaryRetrieve>>
  > = ({ signal }) => financeContractSummaryRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof financeContractSummaryRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Blockchain view set.
---
 */
export const networkBlockchainList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Blockchain[]>(
    { url: `/network/blockchain/`, method: 'get', signal },
    options
  )
}

export const getNetworkBlockchainListQueryKey = () => [`/network/blockchain/`]

export type NetworkBlockchainListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkBlockchainList>>
>
export type NetworkBlockchainListQueryError = unknown

export const useNetworkBlockchainList = <
  TData = Awaited<ReturnType<typeof networkBlockchainList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof networkBlockchainList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNetworkBlockchainListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkBlockchainList>>
  > = ({ signal }) => networkBlockchainList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkBlockchainList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Blockchain view set.
---
 */
export const networkBlockchainRetrieve = (
  chainId: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Blockchain>(
    { url: `/network/blockchain/${chainId}/`, method: 'get', signal },
    options
  )
}

export const getNetworkBlockchainRetrieveQueryKey = (chainId: number) => [
  `/network/blockchain/${chainId}/`,
]

export type NetworkBlockchainRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkBlockchainRetrieve>>
>
export type NetworkBlockchainRetrieveQueryError = unknown

export const useNetworkBlockchainRetrieve = <
  TData = Awaited<ReturnType<typeof networkBlockchainRetrieve>>,
  TError = unknown
>(
  chainId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof networkBlockchainRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getNetworkBlockchainRetrieveQueryKey(chainId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkBlockchainRetrieve>>
  > = ({ signal }) => networkBlockchainRetrieve(chainId, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkBlockchainRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!chainId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Bridge view set.
 */
export const networkBridgeList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Bridge[]>(
    { url: `/network/bridge/`, method: 'get', signal },
    options
  )
}

export const getNetworkBridgeListQueryKey = () => [`/network/bridge/`]

export type NetworkBridgeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkBridgeList>>
>
export type NetworkBridgeListQueryError = unknown

export const useNetworkBridgeList = <
  TData = Awaited<ReturnType<typeof networkBridgeList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof networkBridgeList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNetworkBridgeListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkBridgeList>>
  > = ({ signal }) => networkBridgeList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkBridgeList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * ForwarderRelayerContract view set.
 */
export const networkFrcontractsList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<ForwarderRelayerContract[]>(
    { url: `/network/frcontracts/`, method: 'get', signal },
    options
  )
}

export const getNetworkFrcontractsListQueryKey = () => [`/network/frcontracts/`]

export type NetworkFrcontractsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkFrcontractsList>>
>
export type NetworkFrcontractsListQueryError = unknown

export const useNetworkFrcontractsList = <
  TData = Awaited<ReturnType<typeof networkFrcontractsList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof networkFrcontractsList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNetworkFrcontractsListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkFrcontractsList>>
  > = ({ signal }) => networkFrcontractsList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkFrcontractsList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * JunaToken view set.
 */
export const networkJunatokenList = (
  params?: NetworkJunatokenListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<JunaToken[]>(
    { url: `/network/junatoken/`, method: 'get', params, signal },
    options
  )
}

export const getNetworkJunatokenListQueryKey = (
  params?: NetworkJunatokenListParams
) => [`/network/junatoken/`, ...(params ? [params] : [])]

export type NetworkJunatokenListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkJunatokenList>>
>
export type NetworkJunatokenListQueryError = unknown

export const useNetworkJunatokenList = <
  TData = Awaited<ReturnType<typeof networkJunatokenList>>,
  TError = unknown
>(
  params?: NetworkJunatokenListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof networkJunatokenList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getNetworkJunatokenListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkJunatokenList>>
  > = ({ signal }) => networkJunatokenList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkJunatokenList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Relayer view set.
 */
export const networkRelayerList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Relayer[]>(
    { url: `/network/relayer/`, method: 'get', signal },
    options
  )
}

export const getNetworkRelayerListQueryKey = () => [`/network/relayer/`]

export type NetworkRelayerListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkRelayerList>>
>
export type NetworkRelayerListQueryError = unknown

export const useNetworkRelayerList = <
  TData = Awaited<ReturnType<typeof networkRelayerList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof networkRelayerList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNetworkRelayerListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkRelayerList>>
  > = ({ signal }) => networkRelayerList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkRelayerList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Вызов функции на чтение в смарт-контракте.
---
 */
export const networkTxCallCreate = (
  callRequest: CallRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<CallResult>(
    {
      url: `/network/tx/call/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: callRequest,
    },
    options
  )
}

export type NetworkTxCallCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof networkTxCallCreate>>
>
export type NetworkTxCallCreateMutationBody = CallRequest
export type NetworkTxCallCreateMutationError = unknown

export const useNetworkTxCallCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof networkTxCallCreate>>,
    TError,
    { data: CallRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof networkTxCallCreate>>,
    { data: CallRequest }
  > = (props) => {
    const { data } = props ?? {}

    return networkTxCallCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof networkTxCallCreate>>,
    TError,
    { data: CallRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Оценка транзакции на количество используемого газа.
---
 */
export const networkTxEstimateCreate = (
  estimateRequest: EstimateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<EstimationResponse>(
    {
      url: `/network/tx/estimate/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateRequest,
    },
    options
  )
}

export type NetworkTxEstimateCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof networkTxEstimateCreate>>
>
export type NetworkTxEstimateCreateMutationBody = EstimateRequest
export type NetworkTxEstimateCreateMutationError = unknown

export const useNetworkTxEstimateCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof networkTxEstimateCreate>>,
    TError,
    { data: EstimateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof networkTxEstimateCreate>>,
    { data: EstimateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return networkTxEstimateCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof networkTxEstimateCreate>>,
    TError,
    { data: EstimateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Отправка транзакции напрямую в блокчейн без использования Forwarder-Relayer.
 */
export const networkTxSendCreate = (
  sendTxRequest: SendTxRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TxData>(
    {
      url: `/network/tx/send/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendTxRequest,
    },
    options
  )
}

export type NetworkTxSendCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof networkTxSendCreate>>
>
export type NetworkTxSendCreateMutationBody = SendTxRequest
export type NetworkTxSendCreateMutationError = unknown

export const useNetworkTxSendCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof networkTxSendCreate>>,
    TError,
    { data: SendTxRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof networkTxSendCreate>>,
    { data: SendTxRequest }
  > = (props) => {
    const { data } = props ?? {}

    return networkTxSendCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof networkTxSendCreate>>,
    TError,
    { data: SendTxRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Отправка транзакции напрямую в блокчейн с использованием Forwarder-Relayer.
---
 */
export const networkTxSendFrCreate = (
  sendFRTxRequest: SendFRTxRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TxData>(
    {
      url: `/network/tx/send-fr/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendFRTxRequest,
    },
    options
  )
}

export type NetworkTxSendFrCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof networkTxSendFrCreate>>
>
export type NetworkTxSendFrCreateMutationBody = SendFRTxRequest
export type NetworkTxSendFrCreateMutationError = unknown

export const useNetworkTxSendFrCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof networkTxSendFrCreate>>,
    TError,
    { data: SendFRTxRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof networkTxSendFrCreate>>,
    { data: SendFRTxRequest }
  > = (props) => {
    const { data } = props ?? {}

    return networkTxSendFrCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof networkTxSendFrCreate>>,
    TError,
    { data: SendFRTxRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Validator view set.
 */
export const networkValidatorList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Validator[]>(
    { url: `/network/validator/`, method: 'get', signal },
    options
  )
}

export const getNetworkValidatorListQueryKey = () => [`/network/validator/`]

export type NetworkValidatorListQueryResult = NonNullable<
  Awaited<ReturnType<typeof networkValidatorList>>
>
export type NetworkValidatorListQueryError = unknown

export const useNetworkValidatorList = <
  TData = Awaited<ReturnType<typeof networkValidatorList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof networkValidatorList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNetworkValidatorListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof networkValidatorList>>
  > = ({ signal }) => networkValidatorList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof networkValidatorList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformAdsList = (
  params?: PlatformAdsListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedAdList>(
    { url: `/platform/ads/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformAdsListQueryKey = (params?: PlatformAdsListParams) => [
  `/platform/ads/`,
  ...(params ? [params] : []),
]

export type PlatformAdsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsList>>
>
export type PlatformAdsListQueryError = unknown

export const usePlatformAdsList = <
  TData = Awaited<ReturnType<typeof platformAdsList>>,
  TError = unknown
>(
  params?: PlatformAdsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformAdsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPlatformAdsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof platformAdsList>>> = ({
    signal,
  }) => platformAdsList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformAdsList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformAdsCreate = (
  adCreateRequest: AdCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(adCreateRequest)
  return omnniAxiosMutator<Ad>(
    {
      url: `/platform/ads/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformAdsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsCreate>>
>
export type PlatformAdsCreateMutationBody = AdCreateRequest
export type PlatformAdsCreateMutationError = unknown

export const usePlatformAdsCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformAdsCreate>>,
    TError,
    { data: AdCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformAdsCreate>>,
    { data: AdCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformAdsCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformAdsCreate>>,
    TError,
    { data: AdCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformAdsCategoryList = (
  params?: PlatformAdsCategoryListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AdsCategory[]>(
    { url: `/platform/ads-category/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformAdsCategoryListQueryKey = (
  params?: PlatformAdsCategoryListParams
) => [`/platform/ads-category/`, ...(params ? [params] : [])]

export type PlatformAdsCategoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsCategoryList>>
>
export type PlatformAdsCategoryListQueryError = unknown

export const usePlatformAdsCategoryList = <
  TData = Awaited<ReturnType<typeof platformAdsCategoryList>>,
  TError = unknown
>(
  params?: PlatformAdsCategoryListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformAdsCategoryList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformAdsCategoryListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformAdsCategoryList>>
  > = ({ signal }) => platformAdsCategoryList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformAdsCategoryList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * AdImage
 */
export const platformAdsImageList = (
  params?: PlatformAdsImageListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<AdImage[]>(
    { url: `/platform/ads-image/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformAdsImageListQueryKey = (
  params?: PlatformAdsImageListParams
) => [`/platform/ads-image/`, ...(params ? [params] : [])]

export type PlatformAdsImageListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsImageList>>
>
export type PlatformAdsImageListQueryError = unknown

export const usePlatformAdsImageList = <
  TData = Awaited<ReturnType<typeof platformAdsImageList>>,
  TError = unknown
>(
  params?: PlatformAdsImageListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformAdsImageList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformAdsImageListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformAdsImageList>>
  > = ({ signal }) => platformAdsImageList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformAdsImageList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * AdImage
 */
export const platformAdsImageCreate = (
  adImageRequest: AdImageRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(adImageRequest)
  return omnniAxiosMutator<AdImage>(
    {
      url: `/platform/ads-image/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformAdsImageCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsImageCreate>>
>
export type PlatformAdsImageCreateMutationBody = AdImageRequest
export type PlatformAdsImageCreateMutationError = unknown

export const usePlatformAdsImageCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformAdsImageCreate>>,
    TError,
    { data: AdImageRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformAdsImageCreate>>,
    { data: AdImageRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformAdsImageCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformAdsImageCreate>>,
    TError,
    { data: AdImageRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * AdImage
 */
export const platformAdsImageDestroy = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/platform/ads-image/${id}/`, method: 'delete' },
    options
  )
}

export type PlatformAdsImageDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsImageDestroy>>
>

export type PlatformAdsImageDestroyMutationError = unknown

export const usePlatformAdsImageDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformAdsImageDestroy>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformAdsImageDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return platformAdsImageDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformAdsImageDestroy>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformAdsRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Ad>(
    { url: `/platform/ads/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformAdsRetrieveQueryKey = (id: number) => [
  `/platform/ads/${id}/`,
]

export type PlatformAdsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsRetrieve>>
>
export type PlatformAdsRetrieveQueryError = unknown

export const usePlatformAdsRetrieve = <
  TData = Awaited<ReturnType<typeof platformAdsRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformAdsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPlatformAdsRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformAdsRetrieve>>
  > = ({ signal }) => platformAdsRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformAdsRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const platformAdsUpdate = (
  id: number,
  adRequest: AdRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(adRequest)
  return omnniAxiosMutator<Ad>(
    {
      url: `/platform/ads/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformAdsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsUpdate>>
>
export type PlatformAdsUpdateMutationBody = AdRequest
export type PlatformAdsUpdateMutationError = unknown

export const usePlatformAdsUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformAdsUpdate>>,
    TError,
    { id: number; data: AdRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformAdsUpdate>>,
    { id: number; data: AdRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformAdsUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformAdsUpdate>>,
    TError,
    { id: number; data: AdRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformAdsPartialUpdate = (
  id: number,
  patchedAdRequest: PatchedAdRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(patchedAdRequest)
  return omnniAxiosMutator<Ad>(
    {
      url: `/platform/ads/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformAdsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformAdsPartialUpdate>>
>
export type PlatformAdsPartialUpdateMutationBody = PatchedAdRequest
export type PlatformAdsPartialUpdateMutationError = unknown

export const usePlatformAdsPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformAdsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAdRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformAdsPartialUpdate>>,
    { id: number; data: PatchedAdRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformAdsPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformAdsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAdRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformBankList = (
  params?: PlatformBankListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedBankList>(
    { url: `/platform/bank/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformBankListQueryKey = (
  params?: PlatformBankListParams
) => [`/platform/bank/`, ...(params ? [params] : [])]

export type PlatformBankListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformBankList>>
>
export type PlatformBankListQueryError = unknown

export const usePlatformBankList = <
  TData = Awaited<ReturnType<typeof platformBankList>>,
  TError = unknown
>(
  params?: PlatformBankListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformBankList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPlatformBankListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformBankList>>
  > = ({ signal }) => platformBankList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformBankList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformBankRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Bank>(
    { url: `/platform/bank/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformBankRetrieveQueryKey = (id: number) => [
  `/platform/bank/${id}/`,
]

export type PlatformBankRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformBankRetrieve>>
>
export type PlatformBankRetrieveQueryError = unknown

export const usePlatformBankRetrieve = <
  TData = Awaited<ReturnType<typeof platformBankRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformBankRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPlatformBankRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformBankRetrieve>>
  > = ({ signal }) => platformBankRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformBankRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const platformContactList = (
  params?: PlatformContactListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedContactList>(
    { url: `/platform/contact/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformContactListQueryKey = (
  params?: PlatformContactListParams
) => [`/platform/contact/`, ...(params ? [params] : [])]

export type PlatformContactListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContactList>>
>
export type PlatformContactListQueryError = unknown

export const usePlatformContactList = <
  TData = Awaited<ReturnType<typeof platformContactList>>,
  TError = unknown
>(
  params?: PlatformContactListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformContactList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContactListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContactList>>
  > = ({ signal }) => platformContactList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContactList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformContactCreate = (
  contactCreateRequest: ContactCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contact>(
    {
      url: `/platform/contact/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contactCreateRequest,
    },
    options
  )
}

export type PlatformContactCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactCreate>>
>
export type PlatformContactCreateMutationBody = ContactCreateRequest
export type PlatformContactCreateMutationError = unknown

export const usePlatformContactCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactCreate>>,
    TError,
    { data: ContactCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactCreate>>,
    { data: ContactCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformContactCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactCreate>>,
    TError,
    { data: ContactCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformContactRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Contact>(
    { url: `/platform/contact/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformContactRetrieveQueryKey = (id: string) => [
  `/platform/contact/${id}/`,
]

export type PlatformContactRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContactRetrieve>>
>
export type PlatformContactRetrieveQueryError = unknown

export const usePlatformContactRetrieve = <
  TData = Awaited<ReturnType<typeof platformContactRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformContactRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContactRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContactRetrieve>>
  > = ({ signal }) => platformContactRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContactRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const platformContactUpdate = (
  id: string,
  contactRequest: ContactRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contact>(
    {
      url: `/platform/contact/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: contactRequest,
    },
    options
  )
}

export type PlatformContactUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactUpdate>>
>
export type PlatformContactUpdateMutationBody = ContactRequest
export type PlatformContactUpdateMutationError = unknown

export const usePlatformContactUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactUpdate>>,
    TError,
    { id: string; data: ContactRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactUpdate>>,
    { id: string; data: ContactRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContactUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactUpdate>>,
    TError,
    { id: string; data: ContactRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformContactPartialUpdate = (
  id: string,
  patchedContactRequest: PatchedContactRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contact>(
    {
      url: `/platform/contact/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedContactRequest,
    },
    options
  )
}

export type PlatformContactPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactPartialUpdate>>
>
export type PlatformContactPartialUpdateMutationBody = PatchedContactRequest
export type PlatformContactPartialUpdateMutationError = unknown

export const usePlatformContactPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactPartialUpdate>>,
    TError,
    { id: string; data: PatchedContactRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactPartialUpdate>>,
    { id: string; data: PatchedContactRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContactPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactPartialUpdate>>,
    TError,
    { id: string; data: PatchedContactRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformContactDestroy = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/platform/contact/${id}/`, method: 'delete' },
    options
  )
}

export type PlatformContactDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactDestroy>>
>

export type PlatformContactDestroyMutationError = unknown

export const usePlatformContactDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactDestroy>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactDestroy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return platformContactDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactDestroy>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Deletes all phone group contacts for user
 */
export const platformContactErasePhoneCreate = (
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/platform/contact/erase-phone/`, method: 'post' },
    options
  )
}

export type PlatformContactErasePhoneCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactErasePhoneCreate>>
>

export type PlatformContactErasePhoneCreateMutationError = unknown

export const usePlatformContactErasePhoneCreate = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactErasePhoneCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactErasePhoneCreate>>,
    TVariables
  > = () => {
    return platformContactErasePhoneCreate(requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactErasePhoneCreate>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Sync phone group contacts for user
 */
export const platformContactSyncPhoneCreate = (
  phoneContactSyncRequest: PhoneContactSyncRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    {
      url: `/platform/contact/sync-phone/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: phoneContactSyncRequest,
    },
    options
  )
}

export type PlatformContactSyncPhoneCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContactSyncPhoneCreate>>
>
export type PlatformContactSyncPhoneCreateMutationBody = PhoneContactSyncRequest
export type PlatformContactSyncPhoneCreateMutationError = unknown

export const usePlatformContactSyncPhoneCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContactSyncPhoneCreate>>,
    TError,
    { data: PhoneContactSyncRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContactSyncPhoneCreate>>,
    { data: PhoneContactSyncRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformContactSyncPhoneCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContactSyncPhoneCreate>>,
    TError,
    { data: PhoneContactSyncRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Sync phone group contacts for user
 */
export const platformContactSyncStatusRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<ContactSyncStatus>(
    { url: `/platform/contact/sync-status/`, method: 'get', signal },
    options
  )
}

export const getPlatformContactSyncStatusRetrieveQueryKey = () => [
  `/platform/contact/sync-status/`,
]

export type PlatformContactSyncStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContactSyncStatusRetrieve>>
>
export type PlatformContactSyncStatusRetrieveQueryError = unknown

export const usePlatformContactSyncStatusRetrieve = <
  TData = Awaited<ReturnType<typeof platformContactSyncStatusRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof platformContactSyncStatusRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContactSyncStatusRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContactSyncStatusRetrieve>>
  > = ({ signal }) => platformContactSyncStatusRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContactSyncStatusRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Contract viewset
---
 */
export const platformContractList = (
  params?: PlatformContractListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedContractList>(
    { url: `/platform/contract/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformContractListQueryKey = (
  params?: PlatformContractListParams
) => [`/platform/contract/`, ...(params ? [params] : [])]

export type PlatformContractListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContractList>>
>
export type PlatformContractListQueryError = unknown

export const usePlatformContractList = <
  TData = Awaited<ReturnType<typeof platformContractList>>,
  TError = unknown
>(
  params?: PlatformContractListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformContractList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContractListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContractList>>
  > = ({ signal }) => platformContractList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContractList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Contract instance creation.
---
Header `Content-Type: multipart/form-data` must be set to upload file
 */
export const platformContractCreate = (
  createContractRequest: CreateContractRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(createContractRequest)
  return omnniAxiosMutator<Contract>(
    {
      url: `/platform/contract/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformContractCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractCreate>>
>
export type PlatformContractCreateMutationBody = CreateContractRequest
export type PlatformContractCreateMutationError = unknown

export const usePlatformContractCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractCreate>>,
    TError,
    { data: CreateContractRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractCreate>>,
    { data: CreateContractRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformContractCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractCreate>>,
    TError,
    { data: CreateContractRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Contract viewset
---
 */
export const platformContractRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Contract>(
    { url: `/platform/contract/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformContractRetrieveQueryKey = (id: number) => [
  `/platform/contract/${id}/`,
]

export type PlatformContractRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContractRetrieve>>
>
export type PlatformContractRetrieveQueryError = unknown

export const usePlatformContractRetrieve = <
  TData = Awaited<ReturnType<typeof platformContractRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformContractRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContractRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContractRetrieve>>
  > = ({ signal }) => platformContractRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContractRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Contract instance update.
---
Header `Content-Type: multipart/form-data` must be set to upload file
 */
export const platformContractPartialUpdate = (
  id: number,
  patchedUpdateContractRequest: PatchedUpdateContractRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(patchedUpdateContractRequest)
  return omnniAxiosMutator<Contract>(
    {
      url: `/platform/contract/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformContractPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractPartialUpdate>>
>
export type PlatformContractPartialUpdateMutationBody =
  PatchedUpdateContractRequest
export type PlatformContractPartialUpdateMutationError = unknown

export const usePlatformContractPartialUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractPartialUpdate>>,
    TError,
    { id: number; data: PatchedUpdateContractRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractPartialUpdate>>,
    { id: number; data: PatchedUpdateContractRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContractPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractPartialUpdate>>,
    TError,
    { id: number; data: PatchedUpdateContractRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Contract viewset
---
 */
export const platformContractDestroy = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<void>(
    { url: `/platform/contract/${id}/`, method: 'delete' },
    options
  )
}

export type PlatformContractDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractDestroy>>
>

export type PlatformContractDestroyMutationError = unknown

export const usePlatformContractDestroy = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractDestroy>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return platformContractDestroy(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractDestroy>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Member or Legal Gate accepts contract.
---
 */
export const platformContractAcceptCreate = (
  id: number,
  acceptContractRequest: AcceptContractRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(acceptContractRequest)
  return omnniAxiosMutator<Contract>(
    {
      url: `/platform/contract/${id}/accept/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformContractAcceptCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractAcceptCreate>>
>
export type PlatformContractAcceptCreateMutationBody = AcceptContractRequest
export type PlatformContractAcceptCreateMutationError = unknown

export const usePlatformContractAcceptCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractAcceptCreate>>,
    TError,
    { id: number; data: AcceptContractRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractAcceptCreate>>,
    { id: number; data: AcceptContractRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContractAcceptCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractAcceptCreate>>,
    TError,
    { id: number; data: AcceptContractRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Move to `assurance` status
---
 */
export const platformContractAssuranceCreate = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contract>(
    { url: `/platform/contract/${id}/assurance/`, method: 'post' },
    options
  )
}

export type PlatformContractAssuranceCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractAssuranceCreate>>
>

export type PlatformContractAssuranceCreateMutationError = unknown

export const usePlatformContractAssuranceCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractAssuranceCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractAssuranceCreate>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return platformContractAssuranceCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractAssuranceCreate>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Member or Legal Gate declines contract.
---
 */
export const platformContractDeclineCreate = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contract>(
    { url: `/platform/contract/${id}/decline/`, method: 'post' },
    options
  )
}

export type PlatformContractDeclineCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractDeclineCreate>>
>

export type PlatformContractDeclineCreateMutationError = unknown

export const usePlatformContractDeclineCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractDeclineCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractDeclineCreate>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return platformContractDeclineCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractDeclineCreate>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Deploy contract
---
Initiator of contract deploys it into blockchain.
 */
export const platformContractDeployCreate = (
  id: number,
  txHashRequest: TxHashRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(txHashRequest)
  return omnniAxiosMutator<TxData>(
    {
      url: `/platform/contract/${id}/deploy/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformContractDeployCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractDeployCreate>>
>
export type PlatformContractDeployCreateMutationBody = TxHashRequest
export type PlatformContractDeployCreateMutationError = unknown

export const usePlatformContractDeployCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractDeployCreate>>,
    TError,
    { id: number; data: TxHashRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractDeployCreate>>,
    { id: number; data: TxHashRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContractDeployCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractDeployCreate>>,
    TError,
    { id: number; data: TxHashRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Move to `draft` status
---
 */
export const platformContractDraftCreate = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Contract>(
    { url: `/platform/contract/${id}/draft/`, method: 'post' },
    options
  )
}

export type PlatformContractDraftCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractDraftCreate>>
>

export type PlatformContractDraftCreateMutationError = unknown

export const usePlatformContractDraftCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractDraftCreate>>,
    TError,
    { id: number },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractDraftCreate>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return platformContractDraftCreate(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractDraftCreate>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Process contract
---
Member of contract call its method.
 */
export const platformContractProcessCreate = (
  id: number,
  contractProcessRequest: ContractProcessRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(contractProcessRequest)
  return omnniAxiosMutator<TxData>(
    {
      url: `/platform/contract/${id}/process/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformContractProcessCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformContractProcessCreate>>
>
export type PlatformContractProcessCreateMutationBody = ContractProcessRequest
export type PlatformContractProcessCreateMutationError = unknown

export const usePlatformContractProcessCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformContractProcessCreate>>,
    TError,
    { id: number; data: ContractProcessRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformContractProcessCreate>>,
    { id: number; data: ContractProcessRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return platformContractProcessCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformContractProcessCreate>>,
    TError,
    { id: number; data: ContractProcessRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformContracttxList = (
  params?: PlatformContracttxListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<ContractTransaction[]>(
    { url: `/platform/contracttx/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformContracttxListQueryKey = (
  params?: PlatformContracttxListParams
) => [`/platform/contracttx/`, ...(params ? [params] : [])]

export type PlatformContracttxListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformContracttxList>>
>
export type PlatformContracttxListQueryError = unknown

export const usePlatformContracttxList = <
  TData = Awaited<ReturnType<typeof platformContracttxList>>,
  TError = unknown
>(
  params?: PlatformContracttxListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformContracttxList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformContracttxListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformContracttxList>>
  > = ({ signal }) => platformContracttxList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformContracttxList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Jurisdiction
---
 */
export const platformJurisdictionList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Jurisdiction[]>(
    { url: `/platform/jurisdiction/`, method: 'get', signal },
    options
  )
}

export const getPlatformJurisdictionListQueryKey = () => [
  `/platform/jurisdiction/`,
]

export type PlatformJurisdictionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformJurisdictionList>>
>
export type PlatformJurisdictionListQueryError = unknown

export const usePlatformJurisdictionList = <
  TData = Awaited<ReturnType<typeof platformJurisdictionList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof platformJurisdictionList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformJurisdictionListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformJurisdictionList>>
  > = ({ signal }) => platformJurisdictionList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformJurisdictionList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformLaunchpadRequestsList = (
  params?: PlatformLaunchpadRequestsListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedLaunchpadRequestList>(
    { url: `/platform/launchpad-requests/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformLaunchpadRequestsListQueryKey = (
  params?: PlatformLaunchpadRequestsListParams
) => [`/platform/launchpad-requests/`, ...(params ? [params] : [])]

export type PlatformLaunchpadRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLaunchpadRequestsList>>
>
export type PlatformLaunchpadRequestsListQueryError = unknown

export const usePlatformLaunchpadRequestsList = <
  TData = Awaited<ReturnType<typeof platformLaunchpadRequestsList>>,
  TError = unknown
>(
  params?: PlatformLaunchpadRequestsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLaunchpadRequestsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLaunchpadRequestsListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLaunchpadRequestsList>>
  > = ({ signal }) =>
    platformLaunchpadRequestsList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLaunchpadRequestsList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformLaunchpadRequestsRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<LaunchpadRequestDetailed>(
    { url: `/platform/launchpad-requests/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformLaunchpadRequestsRetrieveQueryKey = (id: number) => [
  `/platform/launchpad-requests/${id}/`,
]

export type PlatformLaunchpadRequestsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLaunchpadRequestsRetrieve>>
>
export type PlatformLaunchpadRequestsRetrieveQueryError = unknown

export const usePlatformLaunchpadRequestsRetrieve = <
  TData = Awaited<ReturnType<typeof platformLaunchpadRequestsRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLaunchpadRequestsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLaunchpadRequestsRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLaunchpadRequestsRetrieve>>
  > = ({ signal }) =>
    platformLaunchpadRequestsRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLaunchpadRequestsRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * LegalGate view set
---
 */
export const platformLegalgateList = (
  params?: PlatformLegalgateListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedLGList>(
    { url: `/platform/legalgate/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformLegalgateListQueryKey = (
  params?: PlatformLegalgateListParams
) => [`/platform/legalgate/`, ...(params ? [params] : [])]

export type PlatformLegalgateListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgateList>>
>
export type PlatformLegalgateListQueryError = unknown

export const usePlatformLegalgateList = <
  TData = Awaited<ReturnType<typeof platformLegalgateList>>,
  TError = unknown
>(
  params?: PlatformLegalgateListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLegalgateList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLegalgateListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLegalgateList>>
  > = ({ signal }) => platformLegalgateList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLegalgateList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * LegalGate view set
---
 */
export const platformLegalgateCreate = (
  lGCreateRequest: LGCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Lg>(
    {
      url: `/platform/legalgate/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lGCreateRequest,
    },
    options
  )
}

export type PlatformLegalgateCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgateCreate>>
>
export type PlatformLegalgateCreateMutationBody = LGCreateRequest
export type PlatformLegalgateCreateMutationError = unknown

export const usePlatformLegalgateCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformLegalgateCreate>>,
    TError,
    { data: LGCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformLegalgateCreate>>,
    { data: LGCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformLegalgateCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformLegalgateCreate>>,
    TError,
    { data: LGCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * LegalGate view set
---
 */
export const platformLegalgateRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Lg>(
    { url: `/platform/legalgate/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformLegalgateRetrieveQueryKey = (id: number) => [
  `/platform/legalgate/${id}/`,
]

export type PlatformLegalgateRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgateRetrieve>>
>
export type PlatformLegalgateRetrieveQueryError = unknown

export const usePlatformLegalgateRetrieve = <
  TData = Awaited<ReturnType<typeof platformLegalgateRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLegalgateRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLegalgateRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLegalgateRetrieve>>
  > = ({ signal }) => platformLegalgateRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLegalgateRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * LegalGateCategory
---
 */
export const platformLegalgatecategoryList = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<LegalGateCategory[]>(
    { url: `/platform/legalgatecategory/`, method: 'get', signal },
    options
  )
}

export const getPlatformLegalgatecategoryListQueryKey = () => [
  `/platform/legalgatecategory/`,
]

export type PlatformLegalgatecategoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgatecategoryList>>
>
export type PlatformLegalgatecategoryListQueryError = unknown

export const usePlatformLegalgatecategoryList = <
  TData = Awaited<ReturnType<typeof platformLegalgatecategoryList>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof platformLegalgatecategoryList>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLegalgatecategoryListQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLegalgatecategoryList>>
  > = ({ signal }) => platformLegalgatecategoryList(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLegalgatecategoryList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Comment view set
---
 */
export const platformLegalgatecommentList = (
  params?: PlatformLegalgatecommentListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedLegalGateCommentList>(
    { url: `/platform/legalgatecomment/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformLegalgatecommentListQueryKey = (
  params?: PlatformLegalgatecommentListParams
) => [`/platform/legalgatecomment/`, ...(params ? [params] : [])]

export type PlatformLegalgatecommentListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgatecommentList>>
>
export type PlatformLegalgatecommentListQueryError = unknown

export const usePlatformLegalgatecommentList = <
  TData = Awaited<ReturnType<typeof platformLegalgatecommentList>>,
  TError = unknown
>(
  params?: PlatformLegalgatecommentListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLegalgatecommentList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLegalgatecommentListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLegalgatecommentList>>
  > = ({ signal }) =>
    platformLegalgatecommentList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLegalgatecommentList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Comment view set
---
 */
export const platformLegalgatecommentCreate = (
  legalGateCommentCreateRequest: LegalGateCommentCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<LegalGateComment>(
    {
      url: `/platform/legalgatecomment/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: legalGateCommentCreateRequest,
    },
    options
  )
}

export type PlatformLegalgatecommentCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgatecommentCreate>>
>
export type PlatformLegalgatecommentCreateMutationBody =
  LegalGateCommentCreateRequest
export type PlatformLegalgatecommentCreateMutationError = unknown

export const usePlatformLegalgatecommentCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformLegalgatecommentCreate>>,
    TError,
    { data: LegalGateCommentCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformLegalgatecommentCreate>>,
    { data: LegalGateCommentCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformLegalgatecommentCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformLegalgatecommentCreate>>,
    TError,
    { data: LegalGateCommentCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformLegalgatecontractList = (
  params?: PlatformLegalgatecontractListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedLGContractList>(
    { url: `/platform/legalgatecontract/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformLegalgatecontractListQueryKey = (
  params?: PlatformLegalgatecontractListParams
) => [`/platform/legalgatecontract/`, ...(params ? [params] : [])]

export type PlatformLegalgatecontractListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformLegalgatecontractList>>
>
export type PlatformLegalgatecontractListQueryError = unknown

export const usePlatformLegalgatecontractList = <
  TData = Awaited<ReturnType<typeof platformLegalgatecontractList>>,
  TError = unknown
>(
  params?: PlatformLegalgatecontractListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformLegalgatecontractList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformLegalgatecontractListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformLegalgatecontractList>>
  > = ({ signal }) =>
    platformLegalgatecontractList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformLegalgatecontractList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformProjectList = (
  params?: PlatformProjectListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedProjectList>(
    { url: `/platform/project/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformProjectListQueryKey = (
  params?: PlatformProjectListParams
) => [`/platform/project/`, ...(params ? [params] : [])]

export type PlatformProjectListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformProjectList>>
>
export type PlatformProjectListQueryError = unknown

export const usePlatformProjectList = <
  TData = Awaited<ReturnType<typeof platformProjectList>>,
  TError = unknown
>(
  params?: PlatformProjectListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformProjectList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformProjectListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformProjectList>>
  > = ({ signal }) => platformProjectList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformProjectList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformProjectCreate = (
  projectCreateRequest: ProjectCreateRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  const formData = customFormData(projectCreateRequest)
  return omnniAxiosMutator<Project>(
    {
      url: `/platform/project/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export type PlatformProjectCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformProjectCreate>>
>
export type PlatformProjectCreateMutationBody = ProjectCreateRequest
export type PlatformProjectCreateMutationError = unknown

export const usePlatformProjectCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformProjectCreate>>,
    TError,
    { data: ProjectCreateRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformProjectCreate>>,
    { data: ProjectCreateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformProjectCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformProjectCreate>>,
    TError,
    { data: ProjectCreateRequest },
    TContext
  >(mutationFn, mutationOptions)
}

export const platformProjectRetrieve = (
  id: number,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Project>(
    { url: `/platform/project/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformProjectRetrieveQueryKey = (id: number) => [
  `/platform/project/${id}/`,
]

export type PlatformProjectRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformProjectRetrieve>>
>
export type PlatformProjectRetrieveQueryError = unknown

export const usePlatformProjectRetrieve = <
  TData = Awaited<ReturnType<typeof platformProjectRetrieve>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformProjectRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformProjectRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformProjectRetrieve>>
  > = ({ signal }) => platformProjectRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformProjectRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const platformStatisticsRetrieve = (
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Statistics>(
    { url: `/platform/statistics/`, method: 'get', signal },
    options
  )
}

export const getPlatformStatisticsRetrieveQueryKey = () => [
  `/platform/statistics/`,
]

export type PlatformStatisticsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformStatisticsRetrieve>>
>
export type PlatformStatisticsRetrieveQueryError = unknown

export const usePlatformStatisticsRetrieve = <
  TData = Awaited<ReturnType<typeof platformStatisticsRetrieve>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof platformStatisticsRetrieve>>,
    TError,
    TData
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformStatisticsRetrieveQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformStatisticsRetrieve>>
  > = ({ signal }) => platformStatisticsRetrieve(requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformStatisticsRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Contract statistics by status
---
 */
export const platformStatsStatusRetrieve = (
  params?: PlatformStatsStatusRetrieveParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<StatusCountRow>(
    { url: `/platform/stats/status/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformStatsStatusRetrieveQueryKey = (
  params?: PlatformStatsStatusRetrieveParams
) => [`/platform/stats/status/`, ...(params ? [params] : [])]

export type PlatformStatsStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformStatsStatusRetrieve>>
>
export type PlatformStatsStatusRetrieveQueryError = unknown

export const usePlatformStatsStatusRetrieve = <
  TData = Awaited<ReturnType<typeof platformStatsStatusRetrieve>>,
  TError = unknown
>(
  params?: PlatformStatsStatusRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformStatsStatusRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformStatsStatusRetrieveQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformStatsStatusRetrieve>>
  > = ({ signal }) =>
    platformStatsStatusRetrieve(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformStatsStatusRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformStoryList = (
  params?: PlatformStoryListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedStoryList>(
    { url: `/platform/story/`, method: 'get', params, signal },
    options
  )
}

export const getPlatformStoryListQueryKey = (
  params?: PlatformStoryListParams
) => [`/platform/story/`, ...(params ? [params] : [])]

export type PlatformStoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformStoryList>>
>
export type PlatformStoryListQueryError = unknown

export const usePlatformStoryList = <
  TData = Awaited<ReturnType<typeof platformStoryList>>,
  TError = unknown
>(
  params?: PlatformStoryListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformStoryList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformStoryListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformStoryList>>
  > = ({ signal }) => platformStoryList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformStoryList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

export const platformStoryRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Story>(
    { url: `/platform/story/${id}/`, method: 'get', signal },
    options
  )
}

export const getPlatformStoryRetrieveQueryKey = (id: string) => [
  `/platform/story/${id}/`,
]

export type PlatformStoryRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof platformStoryRetrieve>>
>
export type PlatformStoryRetrieveQueryError = unknown

export const usePlatformStoryRetrieve = <
  TData = Awaited<ReturnType<typeof platformStoryRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof platformStoryRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPlatformStoryRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof platformStoryRetrieve>>
  > = ({ signal }) => platformStoryRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof platformStoryRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Faucet view
---
 */
export const platformTxFaucetCreate = (
  faucetRequest: FaucetRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<TxData>(
    {
      url: `/platform/tx/faucet/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: faucetRequest,
    },
    options
  )
}

export type PlatformTxFaucetCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformTxFaucetCreate>>
>
export type PlatformTxFaucetCreateMutationBody = FaucetRequest
export type PlatformTxFaucetCreateMutationError = unknown

export const usePlatformTxFaucetCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformTxFaucetCreate>>,
    TError,
    { data: FaucetRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformTxFaucetCreate>>,
    { data: FaucetRequest }
  > = (props) => {
    const { data } = props ?? {}

    return platformTxFaucetCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof platformTxFaucetCreate>>,
    TError,
    { data: FaucetRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Proposal viewset
 */
export const proposalsProposalList = (
  params?: ProposalsProposalListParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<PaginatedProposalList>(
    { url: `/proposals/proposal/`, method: 'get', params, signal },
    options
  )
}

export const getProposalsProposalListQueryKey = (
  params?: ProposalsProposalListParams
) => [`/proposals/proposal/`, ...(params ? [params] : [])]

export type ProposalsProposalListQueryResult = NonNullable<
  Awaited<ReturnType<typeof proposalsProposalList>>
>
export type ProposalsProposalListQueryError = unknown

export const useProposalsProposalList = <
  TData = Awaited<ReturnType<typeof proposalsProposalList>>,
  TError = unknown
>(
  params?: ProposalsProposalListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof proposalsProposalList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getProposalsProposalListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof proposalsProposalList>>
  > = ({ signal }) => proposalsProposalList(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof proposalsProposalList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Proposal viewset
 */
export const proposalsProposalCreate = (
  proposalRequest: ProposalRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Proposal>(
    {
      url: `/proposals/proposal/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: proposalRequest,
    },
    options
  )
}

export type ProposalsProposalCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposalsProposalCreate>>
>
export type ProposalsProposalCreateMutationBody = ProposalRequest
export type ProposalsProposalCreateMutationError = unknown

export const useProposalsProposalCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposalsProposalCreate>>,
    TError,
    { data: ProposalRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposalsProposalCreate>>,
    { data: ProposalRequest }
  > = (props) => {
    const { data } = props ?? {}

    return proposalsProposalCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof proposalsProposalCreate>>,
    TError,
    { data: ProposalRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Proposal viewset
 */
export const proposalsProposalRetrieve = (
  id: string,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<Proposal>(
    { url: `/proposals/proposal/${id}/`, method: 'get', signal },
    options
  )
}

export const getProposalsProposalRetrieveQueryKey = (id: string) => [
  `/proposals/proposal/${id}/`,
]

export type ProposalsProposalRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof proposalsProposalRetrieve>>
>
export type ProposalsProposalRetrieveQueryError = unknown

export const useProposalsProposalRetrieve = <
  TData = Awaited<ReturnType<typeof proposalsProposalRetrieve>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof proposalsProposalRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getProposalsProposalRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof proposalsProposalRetrieve>>
  > = ({ signal }) => proposalsProposalRetrieve(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof proposalsProposalRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Vote viewset
 */
export const proposalsVoteCreate = (
  voteRequest: VoteRequest,
  options?: SecondParameter<typeof omnniAxiosMutator>
) => {
  return omnniAxiosMutator<Vote>(
    {
      url: `/proposals/vote/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: voteRequest,
    },
    options
  )
}

export type ProposalsVoteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposalsVoteCreate>>
>
export type ProposalsVoteCreateMutationBody = VoteRequest
export type ProposalsVoteCreateMutationError = unknown

export const useProposalsVoteCreate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposalsVoteCreate>>,
    TError,
    { data: VoteRequest },
    TContext
  >
  request?: SecondParameter<typeof omnniAxiosMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposalsVoteCreate>>,
    { data: VoteRequest }
  > = (props) => {
    const { data } = props ?? {}

    return proposalsVoteCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof proposalsVoteCreate>>,
    TError,
    { data: VoteRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * OpenApi3 schema for this API. Format can be selected via content negotiation.

- YAML: application/vnd.oai.openapi
- JSON: application/vnd.oai.openapi+json
 */
export const schemaRetrieve = (
  params?: SchemaRetrieveParams,
  options?: SecondParameter<typeof omnniAxiosMutator>,
  signal?: AbortSignal
) => {
  return omnniAxiosMutator<
    | SchemaRetrieve200One
    | SchemaRetrieve200Two
    | SchemaRetrieve200Three
    | SchemaRetrieve200Four
  >({ url: `/schema/`, method: 'get', params, signal }, options)
}

export const getSchemaRetrieveQueryKey = (params?: SchemaRetrieveParams) => [
  `/schema/`,
  ...(params ? [params] : []),
]

export type SchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof schemaRetrieve>>
>
export type SchemaRetrieveQueryError = unknown

export const useSchemaRetrieve = <
  TData = Awaited<ReturnType<typeof schemaRetrieve>>,
  TError = unknown
>(
  params?: SchemaRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof schemaRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof omnniAxiosMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSchemaRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schemaRetrieve>>> = ({
    signal,
  }) => schemaRetrieve(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof schemaRetrieve>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}
