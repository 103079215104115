import { FC } from 'react'
import { ToastIcon } from './constants'
import * as S from './style'
import { ToastType } from './types'

export type ToastProps = {
  message: string
  title?: string
  type: ToastType
}

export const Toast: FC<ToastProps> = (props) => {
  const { message, title, type = 'info' } = props

  return (
    <S.Wrapper>
      <S.Status toastType={type}>{<S.Icon>{ToastIcon[type]}</S.Icon>}</S.Status>
      <S.MessageWrapper>
        <S.Title toastType={type}>{title}</S.Title>
        <S.Message>{message}</S.Message>
      </S.MessageWrapper>
    </S.Wrapper>
  )
}
