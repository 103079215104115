import styled from 'styled-components'
import { SvgAtomClose } from 'ui/icons'

export const Wrapper = styled.div<{ isButton: boolean }>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  transition: opacity 0.3s;
  cursor: ${({ isButton }) => isButton && 'pointer'};

  &:hover {
    opacity: ${({ isButton }) => isButton && '0.6'};
  }
`

export const Label = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const VariableInput = styled.input`
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  width: 155px;

  &::placeholder {
    color: ${({ theme }) => theme.color.mirageDark};
  }
`

export const DeleteIcon = styled(SvgAtomClose)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.black};

  path {
    fill-opacity: 1;
  }
`
