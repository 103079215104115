import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 398px;
  padding: 24px;
`

export const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.color.lightGray};
  border-radius: 12px;
`
