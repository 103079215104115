import { Record, String, Array, Number, Dictionary, Unknown } from 'runtypes'

export const PathRuntype = Record({
  name: String,
  context: String,
  access: Array(
    Record({
      type: String,
      idx: Number,
    })
  ),
})

export const ContractContextRuntype = Record({
  path: Array(String),
  constructor: Record({
    amountJUNA: Record({ type: String }),
    legalGates: Record({
      count: Number,
      data: Array(
        Record({
          context: String,
          name: String,
        })
      ),
    }),
    legalGatesRewards: Array(Dictionary(Unknown, String)),
    members: Record({
      count: Number,
      data: Array(
        Record({
          context: String,
          name: String,
        })
      ),
    }),
  }),
}).And(Dictionary(Unknown, String))
