import styled from 'styled-components'

export const Nav = styled.nav(
  ({ theme: { media, size } }) => `
     display: flex;
     align-items: center;
 
     ${media(size.tablet)} {
       display: none;
     }
 `
)
