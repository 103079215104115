import { rgba } from 'polished'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const StyledCard = styled(Card)(
  ({ theme: { media, size } }) => `
    padding: 40px 24px 24px;
    max-width: 267px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 357px;
    cursor: pointer;

    ${media(size.tablet)} {
      max-width: 224px;
    } 

    ${media(size.mobile)} {
      max-height: 329px;
      height: 100%;
      max-width: 100%;
    } 
`
)

export const Title = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.color.black};
  margin: 0px 0px 36px 0px;
  padding: 0;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 5;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
`

export const UsedCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const UsedCount = styled.div`
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  background-color: ${({ theme }) => rgba(theme.color.gray, 0.16)};
  color: ${({ theme }) => theme.color.black};
  padding: 0px 8px;
`

export const StyledButton = styled(Button)(
  ({ theme: { media, size } }) => `
    width: 214px;
    margin-bottom: 16px;
    
    ${media(size.tablet)} {
      width: 180px;
    }

    ${media(size.mobile)} {
      width: 100%;
      position: relative;
    } 
`
)

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
`

export const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Preview = styled.div`
  text-align: center;
`
