import { FC } from 'react'
import { default as Viewer, DocViewerRenderers } from 'react-doc-viewer'

import * as S from './style'

export const DocViewer: FC = () => {
  return (
    <S.Container>
      <Viewer
        documents={[
          { uri: 'https://filesamples.com/samples/document/docx/sample4.docx' },
        ]}
        pluginRenderers={DocViewerRenderers}
        style={{
          height: '100%',
        }}
      />
    </S.Container>
  )
}
