import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgActionMockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#1B1F3B" fillOpacity={0.05} height={40} rx={20} width={40} />
    <path
      clipRule="evenodd"
      d="M20 11C20 10.4477 20.4477 10 21 10H25C25.7957 10 26.5587 10.3161 27.1213 10.8787C27.6839 11.4413 28 12.2043 28 13V27C28 27.7957 27.6839 28.5587 27.1213 29.1213C26.5587 29.6839 25.7957 30 25 30H21C20.4477 30 20 29.5523 20 29C20 28.4477 20.4477 28 21 28H25C25.2652 28 25.5196 27.8946 25.7071 27.7071C25.8946 27.5196 26 27.2652 26 27V13C26 12.7348 25.8946 12.4804 25.7071 12.2929C25.5196 12.1054 25.2652 12 25 12H21C20.4477 12 20 11.5523 20 11ZM15.2929 14.2929C15.6834 13.9024 16.3166 13.9024 16.7071 14.2929L21.7071 19.2929C22.0976 19.6834 22.0976 20.3166 21.7071 20.7071L16.7071 25.7071C16.3166 26.0976 15.6834 26.0976 15.2929 25.7071C14.9024 25.3166 14.9024 24.6834 15.2929 24.2929L18.5858 21H9C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19H18.5858L15.2929 15.7071C14.9024 15.3166 14.9024 14.6834 15.2929 14.2929Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgActionMockIcon)
export default Memo
