import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

export const Label = styled.span`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
`

export const Button = styled.button<{ selected?: boolean }>`
  border: none;
  outline: none;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  padding: 4px 12px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.mirageLight : theme.color.white};
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.mirageLight};
  }
`
