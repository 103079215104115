import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 24 20"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5001 18.9998V16.9553C22.5001 16.0493 22.2046 15.1703 21.6601 14.4533C21.1226 13.7411 20.3622 13.2295 19.5001 12.9998M16.0006 1.13027C16.8618 1.34967 17.6255 1.84974 18.1709 2.5515C18.7163 3.25327 19.0124 4.11673 19.0124 5.00552C19.0124 5.89432 18.7163 6.75778 18.1709 7.45955C17.6255 8.16131 16.8618 8.66138 16.0006 8.88077M16.5001 18.9998V17.0003C16.5053 15.9523 16.1129 14.9413 15.4021 14.1713C15.064 13.8046 14.6541 13.5113 14.1979 13.3098C13.7416 13.1082 13.2488 13.0027 12.7501 12.9998H5.25005C4.25555 12.9998 3.30155 13.4213 2.59805 14.1713C1.88718 14.9413 1.49479 15.9523 1.50005 17.0003V18.9998H16.5001ZM9.00005 8.99927C9.53182 9.00957 10.0603 8.91373 10.5546 8.71735C11.0489 8.52097 11.4991 8.22799 11.8788 7.85556C12.2585 7.48313 12.5601 7.03872 12.766 6.54832C12.972 6.05793 13.078 5.5314 13.078 4.99952C13.078 4.46765 12.972 3.94112 12.766 3.45072C12.5601 2.96033 12.2585 2.51592 11.8788 2.14349C11.4991 1.77106 11.0489 1.47808 10.5546 1.2817C10.0603 1.08532 9.53182 0.989475 9.00005 0.999773C7.95267 1.02006 6.95502 1.45037 6.22148 2.19826C5.48793 2.94615 5.07701 3.95194 5.07701 4.99952C5.07701 6.04711 5.48793 7.05289 6.22148 7.80078C6.95502 8.54867 7.95267 8.97899 9.00005 8.99927Z"
      stroke="#1B1F3B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.4}
      strokeWidth={2}
    />
  </svg>
)
const Memo = memo(SvgContacts)
export default Memo
