import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DisplayMode } from 'context/ContactsModalContext/ContactsModalContext'
import { Modal, ModalTitle } from 'ui/Modal/Modal'
import { SelectContact, AddContact } from './components'
import * as S from './style'

type ContactsModalProps = {
  contactInputValue: string
  displayMode: DisplayMode
  onAddressSelect: (address: string) => void
  onClose: () => void
  setDisplayMode: (displayMode: DisplayMode) => void
}

export const ContactsModal: FC<ContactsModalProps> = (props) => {
  const {
    contactInputValue,
    displayMode,
    onAddressSelect,
    onClose,
    setDisplayMode,
  } = props

  const { t } = useTranslation('modalContactsModal')

  const title = useMemo(() => {
    switch (displayMode) {
      case 'selectContact':
        return 'Contacts'
      case 'addContact':
        return (
          <S.ModalTitleWrapper>
            <S.BackBtn onClick={() => setDisplayMode('selectContact')} />
            <ModalTitle>{t('Add Contact')}</ModalTitle>
          </S.ModalTitleWrapper>
        )
    }
  }, [displayMode, setDisplayMode, t])

  return (
    <Modal onClose={onClose} title={title}>
      {displayMode === 'selectContact' && (
        <SelectContact onAddressSelect={onAddressSelect!} onClose={onClose} />
      )}
      {displayMode === 'addContact' && (
        <AddContact
          contactInputValue={contactInputValue}
          onAddressSelect={onAddressSelect!}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}
