import { rgba } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.mirageLight};
  width: max-content;
`

export const Label = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => rgba(theme.color.black, 0.5)};
`
