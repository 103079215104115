import { TFunction } from 'i18next'
import { AtomAtomContractListStatus, ContractLogData } from 'api/omni/model'
import {
  SvgActionContractCreatedIcon,
  SvgActionMemberAddedIcon,
  SvgAadocSignedIcon,
  SvgAadocUploadedIcon,
  SvgMemberAcceptedIcon,
  SvgMemberRejectedIcon,
  SvgStatusChangedIcon,
  SvgContractDeployedIcon,
} from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'

export const getActionLabelDataDictionary = (t: TFunction) => {
  const ContractStatusesDictionary: Record<AtomAtomContractListStatus, string> =
    {
      draft: t('Draft'),
      on_approval: t('On approval'),
      on_deployment: t('On deployment'),
      execution: t('Execution'),
      finished: t('Completed'),
    }

  return {
    aadoc_rejected: {
      label: t('You rejected to sign the document'),
      description: () => t('Document has been rejected by member'),
      icon: null,
    },
    aadoc_signed: {
      label: t('You signed the document'),
      description: () => t('Document has been signed by member'),
      icon: <SvgAadocSignedIcon />,
    },
    aadoc_uploaded: {
      label: t("You've uploaded a document"),
      description: () => t('Document has been uploaded by member'),
      icon: <SvgAadocUploadedIcon />,
    },
    contract_created: {
      label: t('You created a contract'),
      description: (args: ContractLogData) =>
        t('Contract has been created by member', {
          initiatorAddress: args.initiatorAddress
            ? shortenAddress(args.initiatorAddress as string)
            : '',
        }),
      icon: <SvgActionContractCreatedIcon />,
    },
    contract_deployed: {
      label: t('You deployed the contract'),
      description: () => t('Contract has been deployed by member'),
      icon: <SvgContractDeployedIcon />,
    },
    member_added: {
      label: t('You added to the contract as member'),
      description: (args: ContractLogData) =>
        t('Added a new member to the contract', {
          addedMemberAddress: args.addedMemberAddress
            ? shortenAddress(args.addedMemberAddress as string)
            : '',
        }),
      icon: <SvgActionMemberAddedIcon />,
    },
    member_accepted: {
      label: t('You signed a contract'),
      description: () => t('Contract has been signed by member'),
      icon: <SvgMemberAcceptedIcon />,
    },
    member_rejected: {
      label: t('You rejected to sign the contract'),
      description: () => t('Contract has been rejected by member'),
      icon: <SvgMemberRejectedIcon />,
    },
    status_changed: {
      label: t('You change the status of the contract'),
      description: (args: ContractLogData) =>
        t('Contract status has been changed', {
          statusBefore:
            ContractStatusesDictionary[
              args.statusBefore as AtomAtomContractListStatus
            ],
          statusAfter:
            ContractStatusesDictionary[
              args.statusAfter as AtomAtomContractListStatus
            ],
        }),
      icon: <SvgStatusChangedIcon />,
    },
    bank_deposit_claimed: {
      description: (args: ContractLogData) => t('Bank deposit claimed'),
      icon: null,
    },
    bank_deposit_claim_requested: {
      description: (args: ContractLogData) => t('Deposit refund requested'),
      icon: null,
    },
    bank_deposit: {
      description: (args: ContractLogData) => t('Deposited'),
      icon: null,
    },
    execution_status_changed: {
      description: (args: ContractLogData) => {
        if (args.newExecutionStatus === 'dispute') {
          return t('The user started a dispute', {
            changedBy: shortenAddress(args.changedBy as string),
          })
        } else {
          return t('The user closed a dispute', {
            changedBy: shortenAddress(args.changedBy as string),
          })
        }
      },
      icon: null,
    },
    bank_credit: {
      description: (args: ContractLogData) => {
        return t('The bank issued a loan', args)
      },
      icon: null,
    },
  }
}
