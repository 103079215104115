import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px;
  width: 572px;
`

export const EmptyContractsList = styled.div`
  padding-top: 24px;
  text-align: center;
`

export const ContractsContainer = styled.div`
  margin-top: 22px;
`

export const Contracts = styled.div`
  margin-bottom: 12px;
`

export const ContractWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding: 12px;

  &:hover {
    background: ${({ theme }) => theme.color.lightGray};
    border-radius: 12px;
  }
`

export const ContractName = styled.div`
  font-weight: 700;
`
