import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  gap: 24px;

  ${media(size.mobile)} {
    flex-direction: column;
  }
`
)
