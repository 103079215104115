import { FC } from 'react'

import { Workspace } from 'api/omni/model'
import * as S from './style'

type WorkspaceSelectProps = {
  workspaces: Workspace[]
  onSelect: (workspace: Workspace) => void
}

export const WorkspaceSelect: FC<WorkspaceSelectProps> = (props) => {
  const { workspaces, onSelect } = props

  return (
    <S.Wrapper>
      <S.Label>Select workspace</S.Label>
      {workspaces.map((workspace) => (
        <S.Option key={workspace.id} onClick={() => onSelect(workspace)}>
          {workspace.name}
        </S.Option>
      ))}
    </S.Wrapper>
  )
}
