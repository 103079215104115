import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    max-width: 1440px;
    width: 100%;
    padding: 0 150px;
    margin: 0 auto;
    
    ${media(size.tablet)} {
      padding: 0 24px;
    }
    
    ${media(size.mobile)} {
      padding: 0 16px;
    }
`
)
