import styled from 'styled-components'
import { FormInput } from 'features/FormFileds'
import { Button } from 'ui/Button/Button'
import { SvgFolder } from 'ui/icons'

export const FieldsContainer = styled.div`
  width: 100%;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line};
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme, isBold }) =>
    isBold ? theme.color.black : theme.color.gray};
`

export const ExtendedFieldsContainer = styled(FieldsContainer)`
  padding: 24px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:last-child {
    margin-bottom: 24px;
  }
`

export const Sample = styled.a`
  display: block;
  margin: 12px 0px 24px 0px;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.color.blue};
`

export const UsersFieldsContainer = styled(ExtendedFieldsContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const UserFidelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const AddressInput = styled(FormInput)`
  flex: 1;
`

export const FolderButton = styled(Button)`
  padding: 12px;
`

export const FolderIcon = styled(SvgFolder)`
  cursor: pointer;
`

export const NumberInputs = styled.div`
  display: flex;
  gap: 24px;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 46px;
`

export const ShortenedFormInput = styled(FormInput)`
  max-width: 387px;
  width: 100%;
`

export const CountInUsdLabel = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const UploadFileField = styled.div(
  ({ theme: { media, size } }) => `
      width: 100%;
      display: flex;
      gap: 8px;
      margin-top: 24px;

      ${media(size.mobile)} {
        flex-direction: column;
      }
`
)

export const UploadInput = styled(FormInput)`
  flex: 1;
`

export const UploadButton = styled(Button)`
  color: ${({ theme }) => theme.color.white};
  font-size: 15px;
  padding: 18px 57px;
  background: ${({ theme }) => theme.color.red};
`

export const ErrorMessageText = styled.span`
  color: ${({ theme }) => theme.color.red};
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
