import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type InfoBlockProps = {
  amount: number
  status: string
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
  const { amount, status } = props

  const { t } = useTranslation('pageProject')

  return (
    <S.Wrapper>
      <S.Title>{t('Information')}</S.Title>
      <S.InfoOption>
        <S.Label>{t('Status')}</S.Label>
        <S.DashedLine />
        <S.Label isBold>{status}</S.Label>
      </S.InfoOption>
      <S.InfoOption>
        <S.Label>{t('Amount')}</S.Label>
        <S.DashedLine />
        <S.Label isBold>{amount} JUNA</S.Label>
      </S.InfoOption>
    </S.Wrapper>
  )
}
