import {
  forwardRef,
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactElement,
  MouseEvent,
  FocusEventHandler,
} from 'react'

import * as S from './style'

export type BaseInputProps = {
  className?: string
  disabled?: boolean
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  value?: string | number
  error?: boolean
  placeholder?: string
  name?: string
  type?: HTMLInputTypeAttribute
  max?: number
  min?: number
  label?: string
  children?: ReactElement
  onClick?: (evt: MouseEvent<HTMLInputElement>) => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (props, ref) => {
    const {
      onChange,
      className,
      disabled,
      value,
      error,
      placeholder,
      name,
      type = 'text',
      max,
      min,
      label,
      children,
      onClick,
      onFocus,
      onBlur,
    } = props

    return (
      <S.InputContainer
        className={className}
        disabled={disabled}
        error={error}
        hasValue={!!value}
      >
        <S.InputWrapper>
          {label && <S.InputLabel htmlFor={name}>{label}</S.InputLabel>}
          <S.Input
            ref={ref}
            disabled={disabled}
            error={error}
            max={max}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            placeholder={!label ? placeholder : ''}
            type={type}
            value={value}
          />
        </S.InputWrapper>
        {children}
      </S.InputContainer>
    )
  }
)
