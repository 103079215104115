import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAadocUploadedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F6F9" height={40} rx={20} width={40} />
    <mask
      height={24}
      id="mask0_5575_16700"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={8}
      y={8}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={8} y={8} />
    </mask>
    <g mask="url(#mask0_5575_16700)">
      <path
        d="M19 24V15.85L16.4 18.45L15 17L20 12L25 17L23.6 18.45L21 15.85V24H19ZM14 28C13.45 28 12.9792 27.8042 12.5875 27.4125C12.1958 27.0208 12 26.55 12 26V23H14V26H26V23H28V26C28 26.55 27.8042 27.0208 27.4125 27.4125C27.0208 27.8042 26.55 28 26 28H14Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
)
const Memo = memo(SvgAadocUploadedIcon)
export default Memo
