import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 5px;
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
  text-decoration: none;
`
