import { FC } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useProposalsProposalCreate } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { notify } from 'libs/notify/notify'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { VotingCreateFormHeader, VotingCreateFormBody } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Voting proposal for RS',
    url: AppRoutes.GovernorVoting,
  },
]

export type FormValues = {
  name: string
  startDate: string
  endDate: string
  params: {
    SYSIR: string
    SYSDR: string
    SBAF: string
    RR: string
    SYSLR: string
    BLL: string
    SYSCR: string
    BM: string
    BCET1T: string
  }
}

export const VotingCreate: FC = () => {
  const { t } = useTranslation('pageAddVoting')

  const navigate = useNavigate()

  const initialValues: FormValues = {
    name: '',
    startDate: '',
    endDate: '',
    params: {
      SYSIR: '0',
      SYSDR: '0',
      SBAF: '0',
      RR: '0',
      SYSLR: '0',
      BLL: '0',
      SYSCR: '0',
      BM: '0',
      BCET1T: '0',
    },
  }

  const { mutate } = useProposalsProposalCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: 'Voting successfully created',
          title: 'Success!',
        })

        navigate(AppRoutes.VotingResults)
      },
    },
  })

  const handleSubmit: FormikConfig<FormValues>['onSubmit'] = (values) => {
    mutate({ data: values })
  }

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs}
      title={t('Voting proposal for Reserve System')}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <S.Wrapper>
          <VotingCreateFormHeader />
          <VotingCreateFormBody />
        </S.Wrapper>
      </Formik>
    </PageWrapper>
  )
}
