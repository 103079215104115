import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgBscLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#F3BA2F"
    />
    <path
      clipRule="evenodd"
      d="M15.112 13.2761L16.9221 15.081L12.0015 19.9965L7.08599 15.081L8.89613 13.2761L12.0015 16.3814L15.112 13.2761ZM12.0015 10.1656L13.8376 12.0017L12.0015 13.8379L10.1705 12.0069V12.0017L10.493 11.6792L10.6491 11.5232L12.0015 10.1656ZM5.81161 10.1916L7.62175 12.0017L5.81161 13.8067L4.00146 11.9965L5.81161 10.1916ZM18.1913 10.1916L20.0015 12.0017L18.1913 13.8067L16.3812 11.9965L18.1913 10.1916ZM12.0015 4.00171L16.9169 8.91718L15.1068 10.7273L12.0015 7.61679L8.89613 10.7221L7.08599 8.91718L12.0015 4.00171Z"
      fill="#131415"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgBscLogo)
export default Memo
