import styled, { DefaultTheme } from 'styled-components'
import { ConstructorInput } from 'libs/constructor/components'
import { SvgAtomClose } from 'ui/icons'

const getFileContainerBgColor = (
  theme: DefaultTheme,
  required?: boolean,
  disabled?: boolean
) => {
  switch (true) {
    case disabled:
      return theme.color.whisper
    case required:
      return theme.color.lightYellow
    default:
      return theme.color.mirageLight
  }
}

export const FileContainer = styled.div<{
  isRequired?: boolean
  disabled?: boolean
}>`
  background-color: ${({ theme, isRequired, disabled }) =>
    getFileContainerBgColor(theme, isRequired, disabled)};
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  max-width: 790px;
`

export const InfoContainer = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.color.mirageDark};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-wrap: wrap;
`

export const Label = styled.div`
  font-size: 10px;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.mirageDark};
  &:hover {
    fill: ${({ theme }) => theme.color.black};
  }
`

export const FileInputContainer = styled(ConstructorInput)`
  display: none;
`
