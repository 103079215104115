import { FC, useMemo } from 'react'
import { ContractContext } from 'api/omni/model'
import { MilestoneList } from 'ui/MilestoneList/MilestoneList'
import { PathRuntype, ContractContextRuntype } from './types'

type MilestoneWrapperProps = {
  context: ContractContext
}

export const MilestoneWrapper: FC<MilestoneWrapperProps> = (props) => {
  const { context } = props

  const contractContext = ContractContextRuntype.check(context)

  const generatedList = useMemo(() => {
    return contractContext.path.map((item) => {
      const { name, context } = PathRuntype.check(contractContext[item])

      return {
        label: name,
        details: [context],
      }
    })
  }, [contractContext])

  return <MilestoneList list={generatedList} />
}
