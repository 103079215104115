import styled from 'styled-components'
import { Button as UiButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const Label = styled.label`
  font-weight: 800;
  font-size: 16px;
  color: ${({ theme }) => theme.color.green};
`

export const Button = styled(UiButton)`
  max-width: 200px;
  width: 100%;
`
