import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  ${media(size.tablet)} {
    flex-direction: column;
  }
`
)
