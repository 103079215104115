import { useCallback, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { useAccount } from 'wagmi'
import { ApprovalState } from 'constants/blockchain'
import { useAllowance, useTokenContract } from 'hooks'
import { notify } from 'libs/notify/notify'

export const useApprove = (
  token?: string,
  amountToApprove?: BigNumber,
  spender?: string
) => {
  const { address } = useAccount()
  const currentAllowance = useAllowance(token, address, spender)
  const contract = useTokenContract(token, true)

  const approvalState = useMemo(() => {
    if (!amountToApprove || !currentAllowance) {
      return ApprovalState.UNKNOWN
    }
    if (currentAllowance?.lt(amountToApprove)) {
      return ApprovalState.NOT_APPROVED
    }

    return ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance])

  const approve = useCallback(() => {
    if (!token) {
      notify.error({
        title: 'Error',
        message: 'No token',
      })
      return
    }

    if (!amountToApprove) {
      notify.error({
        title: 'Error',
        message: 'Missing amount to approve',
      })
      return
    }

    if (!spender) {
      notify.error({
        title: 'Error',
        message: 'No spender',
      })
      return
    }

    contract?.approve(spender, amountToApprove.toString())
  }, [token, amountToApprove, spender, contract])

  return { approvalState, approve }
}
