import { FC } from 'react'
import { useConstructorContext } from 'libs/constructor'
import { SvgContract } from 'ui/icons'
import * as S from './style'

type ChildContractBlockProps = {
  childContractId: string
  onRemove: (contractId: string) => void
}

export const ChildContractBlock: FC<ChildContractBlockProps> = (props) => {
  const { childContractId, onRemove } = props

  const { getContract, navigateToContract } = useConstructorContext()

  const childContract = getContract(childContractId)
  console.log(childContract)

  if (childContract) {
    return (
      <S.Wrapper>
        <S.LeftSideWrapper
          onClick={() => navigateToContract({ contractId: childContract.id })}
        >
          <SvgContract />
          <S.Title>{childContract.name}</S.Title>
        </S.LeftSideWrapper>
        <S.CloseIcon onClick={() => onRemove(childContractId)} />
      </S.Wrapper>
    )
  }

  return null
}
