import styled, { keyframes } from 'styled-components'
import { Card } from 'ui/Card/Card'

const hvrBobFloat = keyframes`
  100% {
    transform: translateY(-8px);
  }
`

const hvrBob = keyframes`
  0% {
    transform: translateY(-8px);
  }
  50% {
      transform: translateY(-4px);
  }
  100% {
      transform: translateY(-8px);
  }
`

export const Icon = styled.div``

export const Hint = styled.div`
  max-width: 300px;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.color.comet};
  transition: visibility 0s, opacity 0.5s linear;
  font-size: 13px;
  ${({ theme: { media, size } }) => media(size.tablet)} {
    display: none;
  }
`

export const Container = styled(Card)`
  width: fit-content;
  height: 156px;
  border-radius: 12px;
  transform: perspective(1px) translateZ(0);
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  &:hover {
    animation-name: ${hvrBobFloat}, ${hvrBob};
    animation-duration: 0.3s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
    cursor: pointer;
    width: fit-content;
    ${Hint} {
      visibility: visible;
      opacity: 1;
    }
  }
  ${({ theme: { media, size } }) => media(size.tablet)} {
    width: 128px;
    height: 128px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
`

export const Main = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Content = styled.div`
  flex-direction: column;
  display: flex;
  gap: 6px;
`

export const Title = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Subtitle = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.comet};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
