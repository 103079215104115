import { FC } from 'react'
import { useParams } from 'react-router-dom'
import {
  useBanksBankBalanceSheetRetrieve,
  useBanksBankRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { RateCard, ReserveForm } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Reserve System',
    url: AppRoutes.ReserveSystem,
  },
]

export const ReserveSystem: FC = () => {
  const { bankId } = useParams()

  const {
    data: bankData,
    isLoading: isBankDataLoading,
    isSuccess: isBankDataLoaded,
    refetch: refetchBankData,
  } = useBanksBankRetrieve(bankId!, {
    query: {
      enabled: !!bankId,
    },
  })

  const {
    data: bankBalanceSheet,
    isLoading: isBankBalanceSheet,
    isSuccess: isBankBalanceSheetLoaded,
  } = useBanksBankBalanceSheetRetrieve(bankId!, {
    query: {
      enabled: Boolean(bankId),
    },
  })

  if (isBankBalanceSheet || isBankDataLoading) {
    return <Loader />
  }

  if (isBankBalanceSheetLoaded && isBankDataLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title="Reserve System">
        <S.Wrapper>
          <S.CardsWrapper>
            {Object.entries(bankBalanceSheet).map(([fieldKey, fieldValue]) => (
              <RateCard
                key={fieldKey}
                name={fieldKey.toUpperCase()}
                rate={fieldValue}
              />
            ))}
          </S.CardsWrapper>
          <S.FormsWrapper>
            <ReserveForm
              bankAddress={bankData.address}
              initialAmount={bankData.depositedToReserveSystem || 0}
              refetchBankData={refetchBankData}
              type="deposit"
            />
            <ReserveForm
              bankAddress={bankData.address}
              initialAmount={bankData.creditedToReserveSystem || 0}
              refetchBankData={refetchBankData}
              type="credit"
            />
          </S.FormsWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
