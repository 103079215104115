import { FC, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { PageWrapper, PageTitle } from 'ui/PageWrapper/PageWrapper'
import { DescriptionBlock, InfoBlock } from './components'
import * as S from './style'

const proposalMock = {
  name: 'Test proposal',
  description: 'Test proposal descripton',
  type: 'Test',
}

export const Voting: FC = () => {
  const { t } = useTranslation('pageVoting')

  const { proposalId } = useParams()

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Votings',
        url: AppRoutes.GovernorVoting,
      },
    ]

    return [
      ...initialBreadcrumbs,
      {
        label: `${proposalMock.name} #${proposalId}`,
        url: AppRoutes.Voting,
      },
    ]
  }, [proposalId])

  const handleVoteClick = useCallback(() => {
    console.log('Vote !')
  }, [])

  return (
    <PageWrapper
      actions={
        <Button onClick={handleVoteClick} variant="red">
          {t('Vote')}
        </Button>
      }
      breadcrumbs={breadcrumbs}
      title={<PageTitle>{`${proposalMock.name} #${proposalId}`}</PageTitle>}
    >
      <S.Wrapper>
        <DescriptionBlock description={proposalMock.description} />
        <InfoBlock type={proposalMock.type} />
      </S.Wrapper>
    </PageWrapper>
  )
}
