import { FC } from 'react'
import { Link } from 'react-router-dom'
import * as S from './style'

export type ItemProps = {
  label: string
  details: string[]
  link?: string
  isAccentMode?: boolean
  isAccentItem?: boolean
  withoutUnderLine?: boolean
}

export const Item: FC<ItemProps> = (props) => {
  const { label, details, link, isAccentMode, isAccentItem, withoutUnderLine } =
    props

  return (
    <S.Item>
      <S.BulletWrapper>
        <S.BulletItem isAccentItem={isAccentItem} isAccentMode={isAccentMode} />
      </S.BulletWrapper>
      <S.Content>
        {link ? (
          <S.Title
            $isAccentItem={isAccentItem}
            $isAccentMode={isAccentMode}
            $withLink
            $withoutUnderLine={withoutUnderLine}
            as={Link}
            to={link}
          >
            {label}
          </S.Title>
        ) : (
          <S.Title $isAccentItem={isAccentItem} $isAccentMode={isAccentMode}>
            {label}
          </S.Title>
        )}

        <S.Details>
          {details.map((detail, index) => (
            <S.DetailItem key={`details_${index}`}>{detail}</S.DetailItem>
          ))}
        </S.Details>
      </S.Content>
    </S.Item>
  )
}
