import { FC } from 'react'
import { Chains } from 'constants/blockchain'
import * as S from './style'

type LeftSideInfoProps = {
  issuerAddress: string
  chain: number
  interestRate: number
  amount: number
}

export const LeftSideInfo: FC<LeftSideInfoProps> = (props) => {
  const { issuerAddress, chain, interestRate, amount } = props

  return (
    <S.Wrapper>
      <S.Label>Issuer</S.Label>
      <S.ValueLabel>{issuerAddress}</S.ValueLabel>

      <S.Label>Chain Type</S.Label>
      <S.ValueLabel>{Chains[chain].name}</S.ValueLabel>

      <S.Label>Interest rate</S.Label>
      <S.ValueLabel>{interestRate}% per year</S.ValueLabel>

      <S.Label>Credit amount Juna</S.Label>
      <S.ValueLabel>{amount} JUNA</S.ValueLabel>
    </S.Wrapper>
  )
}
