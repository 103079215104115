import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAllGood = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#8FD03D" fillOpacity={0.2} height={48} rx={24} width={48} />
    <path
      clipRule="evenodd"
      d="M13 24C13 17.9247 17.9247 13 24 13C30.0753 13 35 17.9247 35 24C35 30.0753 30.0753 35 24 35C17.9247 35 13 30.0753 13 24ZM24 15C19.0293 15 15 19.0293 15 24C15 28.9707 19.0293 33 24 33C28.9707 33 33 28.9707 33 24C33 19.0293 28.9707 15 24 15Z"
      fill="#8FD03D"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M28.169 20.7567C28.5795 21.1262 28.6128 21.7585 28.2433 22.169L23.7433 27.169C23.5653 27.3668 23.3158 27.4854 23.05 27.4988C22.7843 27.5121 22.5242 27.4189 22.3273 27.2399L19.8273 24.9669C19.4187 24.5954 19.3886 23.9629 19.7601 23.5543C20.1316 23.1457 20.7641 23.1156 21.1727 23.4871L22.9291 25.084L26.7567 20.831C27.1262 20.4205 27.7585 20.3873 28.169 20.7567Z"
      fill="#8FD03D"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgAllGood)
export default Memo
