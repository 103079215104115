import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 24%;
  min-width: 260px;
  height: 480px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  background-color: ${({ theme }) => theme.color.white};
`

export const DepositNameBlock = styled.div`
  width: 100%;
  height: 42%;
  padding: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: ${({ theme }) => theme.color.white};
  background-image: linear-gradient(180deg, #ff3592 0%, #fb9057 100%);
  border-radius: 12px 12px 0 0;
  cursor: pointer;
`

export const InfoWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Label = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`

export const ValueLabel = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Chip = styled.div<{ variant: 'gradient' | 'default' }>`
  padding: 2px 12px;
  border-radius: 10px;
  background: ${({ theme, variant }) =>
    variant === 'gradient'
      ? 'linear-gradient(104deg, #728BFF 2.67%, #E557FF 43.38%, #FF634D 76.69%, #FDEF73 109.99%)'
      : theme.color.mirageLight};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme, variant }) =>
    variant === 'gradient' ? theme.color.white : theme.color.black};
`
