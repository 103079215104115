import { FC } from 'react'
import {
  ConstructorAtomTextarea,
  ConstructorVariableBlock,
} from 'libs/constructor'
import { InputState } from '../types'

type TextAtomInputsProps = {
  id: string
  placeholder: string
  canBeVariable?: boolean
  atomName: string
  state: string
}

export const TextAtomInputs: FC<TextAtomInputsProps> = (props) => {
  const { id, placeholder, canBeVariable, atomName, state } = props

  if (state === InputState.HIDDEN) {
    return null
  }

  return (
    <>
      <ConstructorAtomTextarea
        key={id}
        atomId={id}
        disabled={state !== InputState.EDITABLE}
        name={atomName}
        placeholder={placeholder}
      />
      {canBeVariable && state === InputState.EDITABLE && (
        <ConstructorVariableBlock atomFieldName={atomName} atomId={id} />
      )}
    </>
  )
}
