import { FC, useState } from 'react'
import * as S from './style'

type SwitchProps = {
  options: {
    label: string
    value: string
  }[]
  value?: string
  className?: string
  onChange: (value: string) => void
}

export const Switch: FC<SwitchProps> = (props) => {
  const { options, value, onChange, className } = props

  const [activeItemValue, setActiveItemValue] = useState<string>(value || '')

  const onSwitchItemClick = (value: string) => {
    setActiveItemValue(value)
    onChange(value)
  }

  return (
    <S.Wrapper className={className}>
      {options.map((option) => (
        <S.SwitchItem
          key={option.value}
          isActive={activeItemValue === option.value}
          onClick={() => onSwitchItemClick(option.value)}
        >
          {option.label}
        </S.SwitchItem>
      ))}
    </S.Wrapper>
  )
}
