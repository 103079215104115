import { Dispatch, SetStateAction, useCallback, useState } from 'react'

type UseToggle = (
  defaultValue?: boolean
) => [boolean, () => void, Dispatch<SetStateAction<boolean>>]

export const useToggle: UseToggle = (defaultValue) => {
  const [value, setValue] = useState(!!defaultValue)

  const toggle = useCallback(() => setValue((x) => !x), [])

  return [value, toggle, setValue]
}
