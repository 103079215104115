import { FC, ReactElement } from 'react'
import * as S from './style'

type ActionButtonProps = {
  title: string
  subtitle: string
  icon: ReactElement
  hint: string
  onClick?: () => void
}

export const ActionButton: FC<ActionButtonProps> = (props) => {
  const { title, subtitle, icon, hint, onClick } = props

  return (
    <S.Container onClick={onClick}>
      <S.Main>
        {icon}
        <S.Content>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.Content>
      </S.Main>
      <S.Hint>{hint}</S.Hint>
    </S.Container>
  )
}
