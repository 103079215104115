import { FC } from 'react'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { FormInput } from 'features/FormFileds'
import { Chip } from 'ui/Chip/Chip'
import { DatePicker } from 'ui/DatePicker/DatePicker'
import { shortenAddress } from 'utils/shortenAddress'
import { FormValues } from '../../VotingCreate'
import * as S from './style'

export const VotingCreateFormHeader: FC = () => {
  const { t } = useTranslation('pageAddVoting')

  const { address } = useAccount()

  const { values, setFieldValue } = useFormikContext<FormValues>()

  return (
    <S.Wrapper>
      <S.FlexWrapper>
        <S.AthorBlock>
          <S.AuthorAvatarBlock>D</S.AuthorAvatarBlock>
          <Chip variant="gray">{shortenAddress(address!)}</Chip>
        </S.AthorBlock>
        <S.MinVotesBlock>
          <S.MinVotesLabel>{t('min votes to pass')}</S.MinVotesLabel>
          <S.MinVotesLabel>1000</S.MinVotesLabel>
        </S.MinVotesBlock>
      </S.FlexWrapper>
      <S.FlexWrapper>
        <S.FieldWrapper>
          <S.FieldLabel>{t('Name of author')}</S.FieldLabel>
          <FormInput name="name" placeholder="Name" />
        </S.FieldWrapper>
        <S.RightInputsWrapper>
          <S.FieldWrapper>
            <S.FieldLabel>Voting start date</S.FieldLabel>
            <DatePicker
              onChange={(date) =>
                setFieldValue('startDate', dayjs(date).format('YYYY-MM-DD'))
              }
              value={dayjs(values['startDate']).format('DD/MM/YYYY')}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <S.FieldLabel>{t('Date')}</S.FieldLabel>
            <DatePicker
              onChange={(date) =>
                setFieldValue('endDate', dayjs(date).format('YYYY-MM-DD'))
              }
              value={dayjs(values['endDate']).format('DD/MM/YYYY')}
            />
          </S.FieldWrapper>
        </S.RightInputsWrapper>
      </S.FlexWrapper>
    </S.Wrapper>
  )
}
