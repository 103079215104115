import { FC, useMemo, useState } from 'react'
import { useAtomAtomActionContractProcessCreate } from 'api/omni/endpoints'
import { AtomMember } from 'api/omni/model'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import { SelectPlatesBlock } from 'ui/SelectPlatesBlock/SelectPlatesBlock'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type LegalGateProcessFormProps = {
  balance: string
  members: AtomMember[]
}

const LEGAL_GATE_ATOM_ID = '15bb5770-3cd7-4b5a-b078-20730d421662'

export const LegalGateProcessForm: FC<LegalGateProcessFormProps> = (props) => {
  const { balance, members } = props

  const [amountValue, setAmountValue] = useState<string>('')

  const membersOptions = useMemo(() => {
    return members
      .filter(({ type }) => type === 'member')
      .map((member) => ({
        label: shortenAddress(member.address),
        description: '@username not found',
        value: member.address,
      }))
  }, [members])

  const [selectedMemberOption, setSelectedMemberOption] = useState(
    membersOptions[0]
  )

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const handleSubmit = () => {
    callAction({
      id: LEGAL_GATE_ATOM_ID,
      data: {
        action: 'call_transfer_juna',
        inputs: [
          {
            name: 'amount',
            value: amountValue,
          },
          {
            name: 'recepient',
            value: '0xED2dA4A525d93C83Db9AA76432f0311ed2B9A1c8',
          },
        ],
      },
    })
  }

  return (
    <S.Wrapper>
      <S.Form>
        <S.TopBlock>
          <S.Title>Contract Balance</S.Title>
          <S.BalanceWrapper>
            <SvgJunaToken />
            <S.BalanceLabel>{balance}</S.BalanceLabel>
          </S.BalanceWrapper>
        </S.TopBlock>
        <S.FieldWrapper>
          <S.FieldLabel>Members</S.FieldLabel>
          {members.length === 0 && 'No members'}
          <SelectPlatesBlock
            onChange={setSelectedMemberOption}
            options={membersOptions}
            selectedOption={selectedMemberOption}
          />
        </S.FieldWrapper>
        <S.FieldWrapper>
          <S.FieldLabel>Enter Juna amount to transfer</S.FieldLabel>
          <Input
            onChange={(evt) => setAmountValue(evt.target.value)}
            placeholder="Amount"
            value={amountValue}
          />
        </S.FieldWrapper>
      </S.Form>
      <Button onClick={handleSubmit} variant="red">
        Send
      </Button>
    </S.Wrapper>
  )
}
