import { ProjectStatusEnum } from 'api/omni/model'

export const AppRoutes = {
  AdsAdd: '/ads/add',
  AtomContract: '/atom-contract',
  BankDashboard: '/bank-dashboard',
  BankDashboardDeposits: '/bank-dashboard-deposits',
  BankDashboardLoans: '/bank-dashboard-loans',
  Auth: '/auth',
  BankViewCreditContract: '/bank-view-credit-contract',
  BankViewDepositContract: '/bank-view-deposit-contract',
  Catalog: '/catalog',
  ConstructedContract: '/constructed-contract',
  Constructor: '/contract-constructor-v2',
  Contracts: '/contracts',
  ContractCreate: '/create-contract',
  CreditReserve: '/credit-reserve',
  Credit: '/credit',
  CreditCreate: '/credit-create',
  Deposit: '/deposit',
  Deposits: '/deposits',
  DepositCreate: '/create-deposit',
  DepositReserve: '/deposit-reserve',
  DocViewer: '/doc-viewer',
  Faucet: '/faucet',
  Finance: '/finance',
  Launchpad: '/launchpad',
  LegalGate: '/legal-gate',
  LegalGateAuth: '/legal-gate-auth',
  LegalGatesCatalog: '/legal-gates-catalog',
  LegalGateDashboard: '/legal-gate-dashboard',
  Main: '/',
  Marketplace: '/ads',
  MyContacts: '/my-contacts',
  MyLoans: '/my-loans',
  Privacy: '/privacy',
  Project: '/project',
  ProjectAdd: '/add-project',
  ReserveSystem: '/reserve-system',
  Settings: '/settings',
  Simple: '/simple',
  SimpleV2: '/simple-v2',
  SimpleV3: '/simple-v3',
  SimpleV4: '/simple-v4',
  SimpleV5: '/simple-v5',
  TakeALoan: '/take-a-loan',
  Tender: '/tender',
  Transfer: '/transfer',
  Voting: '/voting',
  GovernorVoting: '/votings',
  VotingAdd: '/votings/create',
  VotingResults: '/votings/results',
  Dashboard: '/dashboard',
  SetPin: '/set-pin',
  ResetPin: '/reset-pin',
  UserDashboard: '/user-dashboard',
} as const

export const SettingsPageInnerRoutes = {
  Pin: 'pin',
  PinApproveLanding: 'pin-approve-landing',
  Contacts: 'contacts',
  Profile: 'profile',
} as const

export const TOAST_AUTO_CLOSE_TIME = 3000

export const NETWORK_CONTEXT_NAME = 'NETWORK'

export const StoreNameSpace = {
  Application: 'APPLICATION',
  Authorization: 'AUTHORIZATION',
  Pin: 'PIN',
  User: 'USER',
} as const

export enum ContractStatus {
  Draft = 'draft',
  Assurance = 'assurance',
  Deployment = 'deployment',
  Execution = 'execution',
  Completed = 'completed',
}

export const projectStatusLabels: {
  [key in ProjectStatusEnum]: string
} = {
  not_started: 'Soon',
  in_progress: 'In progress',
  finished: 'Finished',
}

export const MAX_TOKEN_LIFETIME = 168 * 3600000 //hours * ms
