import { getI18n } from 'react-i18next'
import { object, string } from 'yup'

const walletRegex = /^0x[a-fA-F0-9]{40}$/

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    address: string()
      .matches(walletRegex, t('validation.Invalid wallet address'))
      .required(t('validation.Enter wallet address')),
  })
}
