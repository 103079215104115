import { useEffect, useRef } from 'react'

// TODO: Найти способ сделать тоже самое без repaint и reflow

export const useAutosizeTextArea = (value: string | number) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px'
      const scrollHeight = textAreaRef.current.scrollHeight

      textAreaRef.current.style.height = scrollHeight + 'px'
    }
  }, [textAreaRef, value])

  return textAreaRef
}
