import { FC, useCallback, useMemo } from 'react'
import { useConstructorContext } from 'libs/constructor'
import * as S from './style'

export const PublishSwitch: FC = () => {
  const { setFieldValue, getValue } = useConstructorContext()

  const currentSwitchOptions = [
    {
      label: 'Publish',
      value: 'publish',
    },
    {
      label: 'Unpublish',
      value: 'unpublish',
    },
  ]

  const handleSwitchChange = useCallback(
    (value: string) => {
      setFieldValue({
        name: 'isPublishedTemplate',
        value: value === 'publish' ? true : false,
      })
    },
    [setFieldValue]
  )

  const value = useMemo<'publish' | 'unpublish'>(() => {
    return getValue({ name: 'isPublishedTemplate' }) ? 'publish' : 'unpublish'
  }, [getValue])

  return (
    <S.Switch
      onChange={handleSwitchChange}
      options={currentSwitchOptions}
      value={value}
    />
  )
}
