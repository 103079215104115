import { createContext, useCallback } from 'react'
import { ContractItemTypeEnum, ContractAtomRequest } from 'api/omni/model'
import { FCWithChildren } from 'types/app'
import { useConstructorState } from './hooks'
import {
  ConstructorState,
  FieldDataType,
  ContractIdPayload,
  AtomPayload,
  AtomIdPayload,
  Variable,
  ChangeVariablePayload,
  RemoveVariablePayload,
  ContractsPayload,
  ContractFieldNames,
  MemberType,
  SetAtomValuePaylaod,
  ContractType,
} from './hooks/useConstructorState/types'

type GetValueDataType = Omit<FieldDataType, 'value'> & {
  contractId?: string
}

export type ConstructorContextProps = {
  constructorState: ConstructorState
  setFieldValue: (payload: FieldDataType) => void
  getContract: (contractId: string) => ContractType | null
  createChildContract: () => string
  getValue: (fieldData: GetValueDataType) => string | boolean
  navigateToContract: (payload: ContractIdPayload) => void
  removeContract: (payload: ContractIdPayload) => void
  addAtom: (payload: AtomPayload) => void
  removeAtom: (payload: AtomIdPayload) => void
  createVariable: (payload: Variable) => void
  changeVariableName: (payload: ChangeVariablePayload) => void
  removeVariable: (payload: RemoveVariablePayload) => void
  currentContract: ContractType
  changeContractsType: (payload: ContractItemTypeEnum) => void
  loadTemplateContracts: (payload: ContractsPayload) => void
  addMember: (payload: MemberType) => void
  changeMemberValue: (payload: Omit<MemberType, 'type'>) => void
  setAtomValue: (payload: SetAtomValuePaylaod) => void
  loadContracts: (payload: ContractsPayload) => void
  addWorkspace: (workspace: string) => void
  removeWorkspace: (workspaceId: string) => void
  updateAtoms: (atoms: ContractAtomRequest[]) => void
}

export const ConstructorContext = createContext({} as ConstructorContextProps)

export const ConstructorProvider: FCWithChildren = (props) => {
  const { children } = props

  const { state, getContract, ...otherConstructorStateMehods } =
    useConstructorState()

  const getValue = useCallback(
    (fieldData: GetValueDataType) => {
      const currentContractId = fieldData.contractId || state.currentContractId

      const currentContract = getContract(currentContractId)

      if (fieldData.atomId && currentContract) {
        const currentAtom = currentContract.atoms.find(
          ({ id }) => id === fieldData.atomId
        )

        if (fieldData.inputName) {
          const currentAtomInput = currentAtom?.inputs.find(
            ({ name }) => name === fieldData.inputName
          )

          const currentNestedInput = currentAtomInput?.inputs!.find(
            ({ name }) => name === fieldData.name
          )

          return currentNestedInput?.value || ''
        }

        const currentAtomInput = currentAtom?.inputs.find(
          ({ name }) => name === fieldData.name
        )

        return currentAtomInput?.value || ''
      }

      if (currentContract) {
        return currentContract[fieldData.name as ContractFieldNames]
      }

      return ''
    },
    [getContract, state.currentContractId]
  )

  return (
    <ConstructorContext.Provider
      value={{
        constructorState: state,
        getContract,
        getValue,
        ...otherConstructorStateMehods,
      }}
    >
      {children}
    </ConstructorContext.Provider>
  )
}
