import styled from 'styled-components'

export const SubMenu = styled.nav<{
  $isSubmenuOpen: boolean
  top: number
  left: number
}>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  display: ${(props) => (props.$isSubmenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: start;

  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 10;
  min-width: 150px;

  padding: 8px 0;
  border-radius: 8px;
`
