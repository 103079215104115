import { rgba } from 'polished'
import styled, { DefaultTheme } from 'styled-components'
import { Variant } from './types'

const getStatusBgColor = (theme: DefaultTheme, variant: Variant) => {
  switch (variant) {
    case 'danger':
      return rgba(theme.color.red, 0.16)
    case 'info':
      return rgba(theme.color.blue, 0.16)
    case 'success':
      return rgba(theme.color.green, 0.16)
    case 'gradient':
      return theme.gradient.rainbow
    case 'gray':
      return theme.color.lightGray
  }
}

const getStatusColor = (theme: DefaultTheme, variant: Variant) => {
  switch (variant) {
    case 'danger':
      return theme.color.red
    case 'info':
      return theme.color.blue
    case 'success':
      return theme.color.green
    case 'gradient':
      return theme.color.white
    case 'gray':
      return theme.color.gray
  }
}

export const Wrapper = styled.div<{ variant: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  padding: 5px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme, variant }) => getStatusColor(theme, variant)};
  background: ${({ theme, variant }) => getStatusBgColor(theme, variant)};
  width: max-content;
`
