import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgToken = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={14}
    viewBox="0 0 14 14"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask fill="white" id="path-2-inside-1_5195_17861">
      <path d="M5.96185 10C5.22616 10 4.57221 9.8945 4 9.68349V8.11468C4.45413 8.31651 4.98547 8.41743 5.59401 8.41743C6.15713 8.41743 6.55677 8.30275 6.79292 8.0734C7.03815 7.83486 7.16076 7.44954 7.16076 6.91743V3H9V7.33028C9 9.11009 7.98728 10 5.96185 10Z" />
    </mask>
    <path
      d="M4 9.68349H2V11.0776L3.30802 11.56L4 9.68349ZM4 8.11468L4.81227 6.28705L2 5.03717V8.11468H4ZM6.79292 8.0734L8.18635 9.50809L8.18741 9.50706L6.79292 8.0734ZM7.16076 3V1H5.16076V3H7.16076ZM9 3H11V1H9V3ZM5.96185 8C5.41498 8 5.00219 7.92141 4.69198 7.80701L3.30802 11.56C4.14222 11.8676 5.03734 12 5.96185 12V8ZM6 9.68349V8.11468H2V9.68349H6ZM3.18773 9.94231C3.94823 10.2803 4.76695 10.4174 5.59401 10.4174V6.41743C5.20398 6.41743 4.96004 6.35273 4.81227 6.28705L3.18773 9.94231ZM5.59401 10.4174C6.33333 10.4174 7.38716 10.2843 8.18635 9.50809L5.39948 6.6387C5.57168 6.47145 5.72922 6.41911 5.76442 6.40901C5.79464 6.40034 5.75026 6.41743 5.59401 6.41743V10.4174ZM8.18741 9.50706C8.98887 8.72749 9.16076 7.69627 9.16076 6.91743H5.16076C5.16076 7.03874 5.14572 7.05323 5.16297 6.99904C5.18331 6.93513 5.24566 6.78832 5.39842 6.63973L8.18741 9.50706ZM9.16076 6.91743V3H5.16076V6.91743H9.16076ZM7.16076 5H9V1H7.16076V5ZM7 3V7.33028H11V3H7ZM7 7.33028C7 7.5904 6.96248 7.73004 6.94014 7.78893C6.92949 7.81699 6.92183 7.82824 6.92106 7.82936C6.9206 7.83004 6.92124 7.82936 6.92028 7.8302C6.9189 7.83141 6.90802 7.84087 6.88045 7.85541C6.85211 7.87035 6.80384 7.89182 6.72884 7.91379C6.57437 7.95903 6.32822 8 5.96185 8V12C7.22387 12 8.54394 11.7284 9.56065 10.8349C10.6267 9.89815 11 8.60044 11 7.33028H7Z"
      fill="#FF584B"
      mask="url(#path-2-inside-1_5195_17861)"
    />
    <rect
      height={12.5}
      rx={6.25}
      stroke="#FF584B"
      strokeWidth={1.5}
      width={12.5}
      x={0.75}
      y={0.75}
    />
  </svg>
)
const Memo = memo(SvgToken)
export default Memo
