import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgItemFast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#1B1F3B" fillOpacity={0.05} height={48} rx={24} width={48} />
    <path
      clipRule="evenodd"
      d="M22 14C21.2044 14 20.4413 14.3161 19.8787 14.8787C19.3161 15.4413 19 16.2043 19 17V18H16C15.2044 18 14.4413 18.3161 13.8787 18.8787C13.3161 19.4413 13 20.2044 13 21V31C13 31.7957 13.3161 32.5587 13.8787 33.1213C14.4413 33.6839 15.2043 34 16 34H20H28H32C32.7957 34 33.5587 33.6839 34.1213 33.1213C34.6839 32.5587 35 31.7957 35 31V21C35 20.2043 34.6839 19.4413 34.1213 18.8787C33.5587 18.3161 32.7957 18 32 18H29V17C29 16.2043 28.6839 15.4413 28.1213 14.8787C27.5587 14.3161 26.7956 14 26 14H22ZM27 18V17C27 16.7348 26.8946 16.4804 26.7071 16.2929C26.5196 16.1054 26.2652 16 26 16H22C21.7348 16 21.4804 16.1054 21.2929 16.2929C21.1054 16.4804 21 16.7348 21 17V18H27ZM21 20H27V32H21V20ZM19 20H16C15.7348 20 15.4804 20.1054 15.2929 20.2929C15.1054 20.4804 15 20.7348 15 21V31C15 31.2652 15.1054 31.5196 15.2929 31.7071C15.4804 31.8946 15.7348 32 16 32H19V20ZM29 32V20H32C32.2652 20 32.5196 20.1054 32.7071 20.2929C32.8946 20.4804 33 20.7348 33 21V31C33 31.2652 32.8946 31.5196 32.7071 31.7071C32.5196 31.8946 32.2652 32 32 32H29Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgItemFast)
export default Memo
