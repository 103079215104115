import { Request } from 'types/blockchain'

const ForwardRequest = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'data', type: 'bytes' },
]

export const EIP712Domain = (
  verifyingContract?: `0x${string}`,
  chainId?: number
) => ({
  name: 'MinimalForwarder',
  version: '0.0.1',
  chainId,
  verifyingContract,
})

export const buildRequest = (
  nonce: number,
  input: any,
  from?: string
): Request => {
  return { value: 0, gas: 1e6, nonce, from, ...input }
}

export const Types = {
  ForwardRequest,
}
