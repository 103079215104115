import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { SvgCopyAtomContractAddress } from 'ui/icons'

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: row;
`

export const Title = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  flex: 1;
`

export const RightColumn = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.color.mirageLight};
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ActionContainer = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  gap: 10px;
`

export const ActionButton = styled.div<{ isReject?: boolean }>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid
    ${({ theme, isReject }) => (isReject ? theme.color.red : theme.color.line)};
  color: ${({ theme, isReject }) =>
    isReject ? theme.color.red : theme.color.black};
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const WideActionButton = styled(ActionButton)`
  width: 100%;
  justify-content: center;
`

export const RightColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Label = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const RightColumnLabel = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: ${({ theme }) => theme.color.black};
  padding-left: 6px;
`

export const StatusLabel = styled.div<{ isRejected?: boolean }>`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme, isRejected }) =>
    isRejected ? theme.color.red : theme.color.mirageDark};
  padding-left: 6px;
`

export const RightColumnAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CopyIcon = styled(SvgCopyAtomContractAddress)`
  cursor: pointer;
`

export const PublishWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`

export const ReturnCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  max-width: 838px;
  padding: 24px;
  margin-bottom: 24px;
`
