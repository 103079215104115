import { useMemo, useCallback } from 'react'
import { SignTypedDataArgs } from '@wagmi/core'
import {
  useAccount,
  useContract,
  useProvider,
  useSignTypedData as useSignTypedDataNative,
} from 'wagmi'
import ForwarderABI from 'constants/abi/Forwarder.json'

export const useSignTypedData = (forwarder: string) => {
  const { address } = useAccount()
  const provider = useProvider()
  const forwarderContract = useContract({
    address: forwarder,
    abi: ForwarderABI,
    signerOrProvider: provider,
  })

  const { signTypedDataAsync: signTypedDataAsyncNative } =
    useSignTypedDataNative()

  const signTypedData = useCallback(
    async (params: SignTypedDataArgs<any>) => {
      return signTypedDataAsyncNative(params)
    },
    [signTypedDataAsyncNative]
  )

  const getNonce = useCallback(async () => {
    if (address) {
      const result = await forwarderContract?.getNonce(address)
      return result.toNumber()
    }
    return 0
  }, [forwarderContract, address])

  return useMemo(
    () => ({
      getNonce,
      signTypedData,
    }),
    [getNonce, signTypedData]
  )
}
