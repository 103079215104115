import { createListenerMiddleware } from '@reduxjs/toolkit'
import type { TypedStartListening } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from 'types/store'
import { approvePin, uncheckPin } from './pin/pin.slice'
import { fetchUserData } from './user/user.slice'

export const listenerMiddleware = createListenerMiddleware()

type AppStartListening = TypedStartListening<RootState, AppDispatch>

const startAppListening = listenerMiddleware.startListening as AppStartListening

startAppListening({
  actionCreator: approvePin.fulfilled,
  effect: async (_, { dispatch }) => {
    await dispatch(fetchUserData())
    dispatch(uncheckPin())
  },
})
