const AUTH_TOKEN_KEY_NAME = 'auth-token'
const TOKEN_TIMESTAMP_KEY_NAME = 'token-timestamp'

export const getAuthToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY_NAME)
}

export const storeAuthToken = (token: string): void => {
  localStorage.setItem(AUTH_TOKEN_KEY_NAME, token)
}

export const dropAuthToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY_NAME)
}

export const getTokenTimestamp = (): string | null => {
  return localStorage.getItem(TOKEN_TIMESTAMP_KEY_NAME)
}

export const storeTokenTimestamp = (timestamp: string): void => {
  localStorage.setItem(TOKEN_TIMESTAMP_KEY_NAME, timestamp)
}

export const dropTokenTimestamp = (): void => {
  localStorage.removeItem(TOKEN_TIMESTAMP_KEY_NAME)
}
