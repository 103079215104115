import { ReactNode } from 'react'
import { FCWithChildren } from 'types/app'
import { Loader } from 'ui/Loader/Loader'
import * as S from './style'

type OverlayProps = {
  isLoading: boolean
  title?: string
  loader?: ReactNode
}

export const Overlay: FCWithChildren<OverlayProps> = ({
  isLoading,
  children,
  title,
  loader,
}) => {
  return (
    <S.Wrapper>
      <S.Content isDisabled={isLoading}>{children}</S.Content>
      {isLoading && (
        <S.LoaderWrapper>
          {loader || <Loader />}
          {title && <S.Title>{title}</S.Title>}
        </S.LoaderWrapper>
      )}
    </S.Wrapper>
  )
}
