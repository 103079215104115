import { useCallback, useMemo, useRef } from 'react'

import { MAX_TOKEN_LIFETIME } from 'constants/app'
import {
  getTokenTimestamp,
  storeTokenTimestamp,
} from 'libs/auth-storage/auth-storage'

export const useWeb3TokenLifetime = () => {
  const expirationTimeoutRef = useRef<number | null>(null)

  const createWeb3TokenTimestamp = useCallback(() => {
    const timestampInMilliseconds = Date.now() + MAX_TOKEN_LIFETIME

    storeTokenTimestamp(String(timestampInMilliseconds))
  }, [])

  const getRemainingTokenLifetime = useCallback(() => {
    const remainingTokenTime = Number(getTokenTimestamp())

    if (!remainingTokenTime || isNaN(remainingTokenTime)) {
      return 0
    }

    return remainingTokenTime - Date.now()
  }, [])

  const checkIsWeb3TokenExpired = useCallback(() => {
    return getRemainingTokenLifetime() <= 0
  }, [getRemainingTokenLifetime])

  const startWeb3TokenExpirationTimeout = useCallback(
    (onTokenExpired: () => void) => {
      const remainingTokenTime = getRemainingTokenLifetime()

      expirationTimeoutRef.current = window.setTimeout(
        onTokenExpired,
        remainingTokenTime
      )
    },
    [getRemainingTokenLifetime]
  )

  const clearWeb3TokenExpirationTimeout = useCallback(() => {
    if (!expirationTimeoutRef.current) {
      return
    }

    window.clearTimeout(expirationTimeoutRef.current)

    expirationTimeoutRef.current = null
  }, [])

  return useMemo(
    () => ({
      createWeb3TokenTimestamp,
      checkIsWeb3TokenExpired,
      startWeb3TokenExpirationTimeout,
      clearWeb3TokenExpirationTimeout,
    }),
    [
      checkIsWeb3TokenExpired,
      clearWeb3TokenExpirationTimeout,
      createWeb3TokenTimestamp,
      startWeb3TokenExpirationTimeout,
    ]
  )
}
