import { ReactElement } from 'react'
import { FCWithChildren } from 'types/app'
import * as S from './style'
import { VariantType } from './types'

export type ButtonProps = {
  className?: string
  onClick?: () => void
  variant?: VariantType
  type?: 'submit' | 'button'
  form?: string
  disabled?: boolean
  isLoading?: boolean
  icon?: ReactElement
}

export const Button: FCWithChildren<ButtonProps> = (props) => {
  const {
    children,
    className,
    onClick,
    type = 'button',
    variant = 'primary',
    form,
    disabled = false,
    isLoading,
    icon,
  } = props

  return (
    <S.DefaultButton
      className={className}
      disabled={isLoading || disabled}
      form={form}
      onClick={onClick}
      type={type}
      variant={variant}
    >
      <S.FlexWrapper>
        {isLoading ? <S.Spinner /> : icon}
        {children}
      </S.FlexWrapper>
    </S.DefaultButton>
  )
}
