import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SuccessLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.color.green};
`
