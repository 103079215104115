import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgUnion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={25}
    viewBox="0 0 21 25"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M19.4347 6.09477C19.6747 6.20752 19.8601 6.41097 19.9501 6.66038C20.0401 6.90979 20.0274 7.18474 19.9147 7.42477L14.7717 18.3728C14.7026 18.5197 14.5987 18.6476 14.469 18.7454C14.3393 18.8432 14.1878 18.908 14.0275 18.9341C13.8672 18.9602 13.7029 18.9468 13.549 18.8952C13.395 18.8436 13.2559 18.7552 13.1437 18.6378L10.0517 15.4018L7.46469 20.7678C7.39934 20.9031 7.30435 21.0219 7.18676 21.1154C7.06918 21.2089 6.93203 21.2748 6.78551 21.308C6.639 21.3412 6.48687 21.341 6.34044 21.3074C6.194 21.2738 6.05703 21.2076 5.93969 21.1138L3.91569 19.4948L1.90069 23.4538C1.84286 23.5737 1.76167 23.6808 1.66188 23.7689C1.56209 23.857 1.44571 23.9243 1.31957 23.9669C1.19343 24.0094 1.06007 24.0263 0.927302 24.0167C0.794534 24.007 0.665034 23.9709 0.546396 23.9105C0.427759 23.8501 0.322372 23.7667 0.236415 23.665C0.150458 23.5634 0.0856618 23.4456 0.0458255 23.3186C0.00598913 23.1915 -0.00808534 23.0578 0.00442755 22.9253C0.0169404 22.7928 0.0557887 22.6641 0.118694 22.5468L2.68969 17.4938C2.75708 17.3614 2.85309 17.2458 2.97076 17.1552C3.08844 17.0646 3.22481 17.0013 3.36998 16.97C3.51515 16.9387 3.66548 16.9401 3.81003 16.9741C3.95459 17.0082 4.08975 17.074 4.20569 17.1668L6.20869 18.7688L8.87769 13.2328C8.94782 13.0871 9.0522 12.9607 9.18189 12.8642C9.31159 12.7677 9.46273 12.7042 9.62238 12.6789C9.78203 12.6536 9.94542 12.6674 10.0986 12.7191C10.2517 12.7709 10.39 12.8589 10.5017 12.9758L13.5817 16.2018L18.1047 6.57477C18.2174 6.33478 18.4209 6.1494 18.6703 6.05938C18.9197 5.96937 19.1947 5.9821 19.4347 6.09477Z"
      fill="#FF584B"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgUnion)
export default Memo
