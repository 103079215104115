import styled from 'styled-components'
import { ConstructorSelectList } from '../ConstructorSelectList/ConstructorSelectList'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
`

export const Label = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ActionButton = styled.div<{
  disabled?: boolean
  selected?: boolean
}>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  transition: opacity 0.3s;
  cursor: ${({ disabled, selected }) =>
    disabled ? 'now-allowed' : selected ? 'auto' : 'pointer'};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.mirageLight : theme.color.white};

  &:hover {
    opacity: ${({ disabled, selected }) => (disabled || selected ? 1 : 0.5)};
  }
`

export const InputsWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SelectList = styled(ConstructorSelectList)`
  position: absolute;
  top: -25px;
  left: calc(100% + 25px);
  z-index: 100;
`
