import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled.h5`
  font-size: 18px;
  margin: 0;
  font-weight: 800;
  color: ${({ theme }) => theme.color.black};
`

export const InfoOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-size: 15px;
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const DashedLine = styled.div`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.color.line};
`
