import { FC, useCallback } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePlatformAdsCategoryList,
  usePlatformAdsRetrieve,
  usePlatformAdsUpdate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { FormImageUploader } from 'features/FormFileds'
import { notify } from 'libs/notify/notify'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { EditForm, AdsDetails } from './components'
import { FORM_ID } from './constants'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Marketplace',
    url: AppRoutes.Marketplace,
  },
  {
    label: 'Place an ad',
    url: AppRoutes.AdsAdd,
  },
]

export const AddEdit: FC = () => {
  const navigate = useNavigate()

  const { adId } = useParams()

  const { t } = useTranslation('pageAdEdit')

  const {
    data: ad,
    isLoading: isAdLoading,
    isSuccess: isAdSuccess,
  } = usePlatformAdsRetrieve(Number(adId))

  const { mutate, isLoading: isAddCreating } = usePlatformAdsUpdate({
    mutation: {
      onSuccess: (data) => {
        navigate(`${AppRoutes.Marketplace}/${data.id}`)
        notify.success({
          message: t('Ad successfully edited'),
          title: t('Ad edited'),
        })
      },
    },
  })

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = usePlatformAdsCategoryList()

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values) => {
      mutate({
        id: adId! as unknown as number,
        data: {
          ...values,
          category: Number(values.category),
          amountjuna: Number(values.amountjuna),
        },
      })
    },
    [mutate, adId]
  )

  if (isCategoriesLoading || isAdLoading) {
    return <Loader />
  }

  if (isCategoriesSuccess && isAdSuccess) {
    const initialValues: FormValues = {
      category: ad.category.toString(),
      name: ad.name,
      description: ad.description,
      amountjuna: ad.amountjuna!,
    }

    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Edit ad')}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema()}
        >
          <S.Wrapper>
            <S.LeftSideWrapper>
              <FormImageUploader imageValue={ad.image} name="file" />
            </S.LeftSideWrapper>
            <S.RightSideWrapper>
              <EditForm categories={categories} />
              <S.AdsDetailsWrapper>
                <S.Label>{t('Your ad')}</S.Label>
                <AdsDetails address={ad.address} categories={categories} />
                <S.ButtonsWrapper>
                  <S.Button
                    form={FORM_ID}
                    isLoading={isAddCreating}
                    type="submit"
                    variant="red"
                  >
                    {t('Save ad')}
                  </S.Button>
                  <S.Button>{t('Delisting')}</S.Button>
                </S.ButtonsWrapper>
              </S.AdsDetailsWrapper>
            </S.RightSideWrapper>
          </S.Wrapper>
        </Formik>
      </PageWrapper>
    )
  }

  return null
}
