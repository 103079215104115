import { FC } from 'react'
import { Item, ItemProps } from './Item/Item'
import * as S from './style'

type MilestoneListProps = {
  list: ItemProps[]
  activeItem?: number
  withoutUnderLine?: boolean
}

export const MilestoneList: FC<MilestoneListProps> = (props) => {
  const { list, activeItem, withoutUnderLine } = props

  const isAccentMode = activeItem !== undefined

  return (
    <S.MilestoneListCard>
      {list.map(({ details, label, link }, index) => {
        const isAccentItem = activeItem === index + 1

        return (
          <Item
            key={`item_${index}`}
            details={details}
            isAccentItem={isAccentItem}
            isAccentMode={isAccentMode}
            label={label}
            link={link}
            withoutUnderLine={withoutUnderLine}
          />
        )
      })}
    </S.MilestoneListCard>
  )
}
