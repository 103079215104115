import styled from 'styled-components'
import { CategoriesList as UICategoriesList } from 'ui/CategoriesList/CategoriesList'
import { Tabs as UITabs } from 'ui/Tabs/Tabs'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const ContractsWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0px;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const Tabs = styled(UITabs)`
  max-width: 839px;
  width: max-content;
`

export const NoContractsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35vh;
`

export const Label = styled.span`
  font-weight: 500;
  font-size: 24px;
  color: ${({ theme }) => theme.color.gray};
`

export const CategoriesList = styled(UICategoriesList)`
  background-color: ${({ theme }) => theme.color.white};
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.color.line};
  min-width: 280px;
  width: 280px;
`

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
`
