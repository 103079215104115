import { FC, useCallback, useMemo } from 'react'
import * as S from './style'

const PagesToShowAmount = {
  DEFAULT: 9,
  MOBILE: 4,
}

const getPagesRange = (
  currentPage: number,
  totalPages: number,
  pagesToShow: number
): number[] => {
  const halfRange = Math.ceil((pagesToShow - 1) / 2)
  const allPages = Array.from({ length: totalPages }, (_, idx) => idx)

  let from = currentPage - halfRange
  let to = currentPage + halfRange + (pagesToShow % 2)

  if (from < 0) {
    from = 0
    to = pagesToShow
  }

  if (to > totalPages) {
    to = totalPages
  }

  return allPages.slice(from, to)
}

type Props = {
  onChangePage: (page: number) => void
  currentPage: number
  totalPages: number
  className?: string
  disabled?: boolean
  shortVersion?: boolean
}

export const Pagination: FC<Props> = (props): JSX.Element => {
  const {
    currentPage,
    totalPages,
    onChangePage,
    className,
    disabled,
    shortVersion = false,
  } = props

  const pagesToShow = useMemo((): number => PagesToShowAmount.DEFAULT, [])

  const pagesRange = useMemo(
    () => getPagesRange(currentPage, totalPages, pagesToShow),
    [currentPage, totalPages, pagesToShow]
  )

  const moveToPrevPage = useCallback(() => {
    onChangePage(currentPage - 1)
  }, [currentPage, onChangePage])

  const moveToNextPage = useCallback(() => {
    onChangePage(currentPage + 1)
  }, [currentPage, onChangePage])

  const moveToPage = useCallback(
    (pageNum: number) => {
      onChangePage(pageNum)
    },
    [onChangePage]
  )

  return (
    <S.Wrapper className={className}>
      <S.PageBtn
        active={false}
        disabled={currentPage === 1 || disabled}
        onClick={moveToPrevPage}
      >
        <S.PrevBtnIcon disabled={currentPage === 1 || disabled} />
      </S.PageBtn>
      {!shortVersion &&
        pagesRange.map((pageNum: number) => {
          const isActive = currentPage === pageNum + 1
          return (
            <S.PageBtn
              key={pageNum}
              active={isActive}
              disabled={disabled}
              onClick={() => moveToPage(pageNum + 1)}
            >
              {pageNum + 1}
            </S.PageBtn>
          )
        })}
      <S.PageBtn
        active={false}
        disabled={currentPage === totalPages || disabled}
        onClick={moveToNextPage}
      >
        <S.BtnIcon disabled={currentPage === totalPages || disabled} />
      </S.PageBtn>
    </S.Wrapper>
  )
}
