import { FC } from 'react'
import { FormikHelpers, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { FormInput } from 'features/FormFileds'
import { Button } from 'ui/Button/Button'
import { Link } from 'ui/Link/Link'
import * as S from './style'
import { getValidationSchema } from './validationSchema'

export type FormValues = {
  address: string
}

type FaucetFormProps = {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<any>
  isLoading: boolean
}

export const FaucetForm: FC<FaucetFormProps> = (props) => {
  const { t } = useTranslation('pageFaucet')

  const { onSubmit, isLoading } = props

  const { address } = useAccount()

  const initialValues: FormValues = {
    address: '',
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      {({ handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <S.Wrapper>
            <FormInput
              name="address"
              placeholder={t('Enter your wallet address (0x...)')}
            />
            <Link onClick={() => setFieldValue('address', address)}>
              {t('Paste current address')}
            </Link>
            <Button isLoading={isLoading} onClick={handleSubmit}>
              {t('Send me Juna')}
            </Button>
          </S.Wrapper>
        </form>
      )}
    </Formik>
  )
}
