import styled from 'styled-components'

export const Title = styled.h3(
  ({ theme: { media, size, color } }) => `
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${color.black};
  margin: 0 0 48px 0;

  ${media(size.mobile)} {
    padding: 0 16px;
    font-size: 24px;
    line-height: 40px;
  }
`
)

export const ProductsWrapper = styled.div`
  display: flex;
  gap: 20px 80px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto 150px auto;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`
