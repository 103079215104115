import { createContext, useContext } from 'react'
import { useAccount } from 'wagmi'
import { usePlatformLegalgateList } from 'api/omni/endpoints'
import {
  useAuthStatus,
  useDefaultChain,
  useDispatch,
  usePin,
  useUserData,
  useWeb3Token,
} from 'hooks'
import { setIsLegalGate } from 'store/application/application.slice'
import { FCWithChildren } from 'types/app'

type AuthContextType = {
  isWalletConnecting: boolean
  setupWeb3Token: () => void
  deleteWeb3Token: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)

  if (!authContext) {
    throw new Error('authContext has to be used within <AuthProvider />')
  }

  return authContext
}

export const AuthProvider: FCWithChildren = (props) => {
  const { children } = props

  const dispatch = useDispatch()

  const { setAuthStatus } = useAuthStatus()

  const { uncheckPin } = usePin()

  const { fetchUserData, userDataLoadStatus } = useUserData()

  const { setupWeb3Token, deleteWeb3Token } = useWeb3Token({
    onTokenApplied: async () => {
      await fetchUserData()
      setAuthStatus('authorized')
    },
    onTokenDeleted: () => {
      uncheckPin()
      setAuthStatus('wallet-connected')
    },
  })

  const { isConnecting, isReconnecting, address } = useAccount({
    onConnect: async ({ connector }) => {
      connector?.on('change', async ({ account }) => {
        if (account) {
          deleteWeb3Token()
          await setupWeb3Token()
        }
      })

      setAuthStatus('wallet-connected')
      await setupWeb3Token()
    },
    onDisconnect: () => {
      deleteWeb3Token()
      setAuthStatus('not-authorized')
    },
  })

  usePlatformLegalgateList(
    {
      address,
    },
    {
      query: {
        onSuccess: (data) => {
          if (data.results.length > 0) {
            dispatch(setIsLegalGate(true))
          }
        },
      },
    }
  )

  useDefaultChain()

  return (
    <AuthContext.Provider
      value={{
        isWalletConnecting:
          isConnecting || isReconnecting || userDataLoadStatus === 'loading',
        setupWeb3Token,
        deleteWeb3Token,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
