import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const StyledCard = styled(Card)`
  padding: 24px;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px 0px;
`

export const SignatureBlock = styled.div`
  width: 50%;
`

export const NameLabel = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const WalletLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray};
`
