import { FC, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { SvgJunaToken } from 'ui/icons'
import * as S from './style'

type FinanceCardProps = {
  name: string
  amount: number
  interestedRate: number
  finishedAt: string
  status: AtomAtomContractListStatus
  type: string
  id: string
  bankName?: string
}

export const ContractStatusesDictionary: Record<
  AtomAtomContractListStatus,
  string
> = {
  draft: 'Draft',
  on_approval: 'On approval',
  on_deployment: 'On deployment',
  execution: 'Execution',
  finished: 'Completed',
} as const

export const FinanceCard: FC<FinanceCardProps> = (props) => {
  const {
    name,
    amount,
    interestedRate,
    finishedAt,
    status,
    type,
    id,
    bankName,
  } = props

  const { t } = useTranslation('pageDeposits')
  const navigate = useNavigate()

  const currentDate = useMemo(() => dayjs(), [])

  const handleClick = useCallback(() => {
    navigate(
      `${type === 'deposit' ? AppRoutes.Deposit : AppRoutes.Credit}/${id}`
    )
  }, [navigate, id, type])

  return (
    <S.Wrapper>
      <S.DepositNameBlock onClick={handleClick}>{name}</S.DepositNameBlock>
      <S.InfoWrapper>
        <S.InfoItemsWrapper>
          <S.InfoItemWrapper>
            <S.Label>{t('Bank')}</S.Label>
            <S.ValueLabel>{bankName}</S.ValueLabel>
          </S.InfoItemWrapper>
          <S.InfoItemWrapper>
            <S.Label>{type === 'deposit' ? t('Deposit') : t('Loan')}</S.Label>
            <S.ValueLabel>
              <SvgJunaToken /> {amount}
            </S.ValueLabel>
          </S.InfoItemWrapper>
        </S.InfoItemsWrapper>
        <S.InfoItemsWrapper>
          <S.InfoItemWrapper>
            <S.Label>{t('Time')}</S.Label>
            <S.Chip variant="default">
              {dayjs(finishedAt).diff(dayjs(), 'day')} days
            </S.Chip>
          </S.InfoItemWrapper>
          <S.InfoItemWrapper>
            <S.Label>{t('Reward')}</S.Label>
            <S.Chip variant="gradient">{interestedRate}% AER</S.Chip>
          </S.InfoItemWrapper>
        </S.InfoItemsWrapper>
        <S.InfoItemsWrapper>
          <S.InfoItemWrapper>
            <S.Label>{t('Status')}</S.Label>
            <S.Chip variant="default">
              {ContractStatusesDictionary[status]}
            </S.Chip>
          </S.InfoItemWrapper>
        </S.InfoItemsWrapper>
        {status === 'execution' && currentDate >= dayjs(finishedAt) ? (
          <Button onClick={handleClick} variant="red">
            {t('Withdraw deposit')}
          </Button>
        ) : (
          <Button onClick={handleClick} variant="gray">
            {t('View contract')}
          </Button>
        )}
      </S.InfoWrapper>
    </S.Wrapper>
  )
}
