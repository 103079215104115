import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.34 1.99976H7.67C4.28 1.99976 2 4.37976 2 7.91976V16.0898C2 19.6198 4.28 21.9998 7.67 21.9998H16.34C19.73 21.9998 22 19.6198 22 16.0898V7.91976C22 4.37976 19.73 1.99976 16.34 1.99976Z"
      fill="white"
      opacity={0.4}
    />
    <path
      clipRule="evenodd"
      d="M11.1248 8.18921C11.1248 8.67121 11.5158 9.06421 11.9948 9.06421C12.4878 9.06421 12.8798 8.67121 12.8798 8.18921C12.8798 7.70721 12.4878 7.31421 12.0048 7.31421C11.5198 7.31421 11.1248 7.70721 11.1248 8.18921ZM12.8698 11.362C12.8698 10.88 12.4768 10.487 11.9948 10.487C11.5128 10.487 11.1198 10.88 11.1198 11.362V15.782C11.1198 16.264 11.5128 16.657 11.9948 16.657C12.4768 16.657 12.8698 16.264 12.8698 15.782V11.362Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgInfo)
export default Memo
