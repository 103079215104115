import { FC } from 'react'
import {
  ConstructedContractType,
  AtomContractValue,
} from 'pages/ConstructedContract/types'
import { StatusChip } from '../StatusChip/StatusChip'

import * as S from './style'

type AtomDetailProp = {
  label: string
  value: string | ConstructedContractType | AtomContractValue
  onChildContractClick: (currentContract: ConstructedContractType) => void
}

export const AtomDetail: FC<AtomDetailProp> = (props) => {
  const { label, value, onChildContractClick } = props

  const isValueChildContract = typeof value !== 'string'

  const childContract = value as ConstructedContractType

  return (
    <S.Wrapper withButton={isValueChildContract}>
      <S.Label>{label}</S.Label>
      <S.DashedLine />
      {!isValueChildContract ? (
        <S.Label isBold>{value}</S.Label>
      ) : (
        <S.ChildButton
          onClick={() => onChildContractClick(childContract)}
          variant="transparent"
        >
          {childContract.contractName}
          <StatusChip status={childContract.address ? 'active' : 'pending'} />
        </S.ChildButton>
      )}
    </S.Wrapper>
  )
}
