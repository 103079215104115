import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'
import { Select } from 'ui/Select/Select'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 24px;
    align-items: flex-start;

    ${media(size.tablet)} {
      flex-direction: column;
      justify-content:center;
    } 
`
)

export const SearchWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 24px;
    flex-direction: column;
    min-width: 267px;
    
    ${media(size.tablet)} {
      width:100%;
      flex-direction: row;
    }

    ${media(size.mobile)} {
      flex-direction: column;
    }
`
)

export const StyledButton = styled(Button)`
  width: 100%;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
  width: 100%;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 0 0 8px 0;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const StyledSelect = styled(Select)`
  min-width: 224px;
`

export const CategoryContainer = styled(Card)`
  padding: 24px;
`

export const CardTitle = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 133.333%;
  margin-top: 0px;
`

export const NoContractsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35vh;
`

export const Label = styled.span`
  font-weight: 500;
  font-size: 24px;
  color: ${({ theme }) => theme.color.gray};
`
