import { DetailsItem } from './DetailsItem/DetailsItem'
import * as S from './style'

const DETAILS_TITLE = 'Детали контракта'
const LEGAL_GATE_TITLE = 'Legal Gate'

type ContractDetailsProps = {
  title: string
  description: string
  legalGateDescription: string
  details: { label: string; value: string }[][]
  legalGateDetails: { label: string; value: string }[]
}

export const ContractDetails = (props: ContractDetailsProps) => {
  const {
    title,
    description,
    details,
    legalGateDetails,
    legalGateDescription,
  } = props

  return (
    <S.StyledCard>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <S.Line />
        <S.Title>{DETAILS_TITLE}</S.Title>
        {details.map((detail, idx) => (
          <S.DetailsItemWrapper key={`detail #${idx}`}>
            <DetailsItem details={detail} />
            <S.Line />
          </S.DetailsItemWrapper>
        ))}
      </S.Wrapper>
      <S.Title>{LEGAL_GATE_TITLE}</S.Title>
      <S.LegalGateDescripion>{legalGateDescription}</S.LegalGateDescripion>
      <DetailsItem details={legalGateDetails} />
    </S.StyledCard>
  )
}
