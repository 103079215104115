import { FC } from 'react'
import ReacrtDatePicker from 'react-datepicker'

import { Input } from 'ui/Input/Input'

type DatePickerProps = {
  value?: string
  onChange: (date: Date) => void
  name?: string
}

export const DatePicker: FC<DatePickerProps> = (props) => {
  const { value, onChange, name } = props

  return (
    <ReacrtDatePicker
      //   ref={datepickerRef}
      customInput={<Input name={name} />}
      dateFormat="MMMM d, yyyy h:mm aa"
      onChange={onChange}
      placeholderText={'--:--'}
      showTimeSelect
      timeCaption="time"
      timeFormat="HH:mm"
      timeIntervals={1}
      value={value !== 'Invalid Date' ? value : undefined}
      wrapperClassName="datePicker"
    />
  )
}
