import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DashedLine = styled.div(
  ({ theme: { media, size, color } }) => `
    width: 100%;
    height: 1px;
    border: 1px dashed ${color.line};

    ${media(size.mobile)} {
      display: none;
    }
`
)

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DetailWrapper = styled.div(
  ({ theme: { media, size } }) => `
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      ${media(size.mobile)} {
        flex-direction: column;
        align-items: flex-start;
      }
`
)

export const Label = styled.span<{ isBold?: boolean }>(
  ({ theme: { media, size, color }, isBold }) => `
  font-weight: ${isBold ? '700' : '400'};
  font-size: 15px;
  line-height: 24px;
  color: ${color.black};
  white-space: nowrap;

  ${media(size.mobile)} {
    white-space: normal;
    word-break: break-all;
  }
`
)
