import {
  FC,
  useCallback,
  ChangeEvent,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useAccountUsersList, usePlatformContactList } from 'api/omni/endpoints'
import { useDebounce } from 'hooks'
import { useConstructorContext } from 'libs/constructor/hooks'
import { BaseInputProps } from 'ui/BaseInput/BaseInput'
import { SvgAddressAtomVariable } from 'ui/icons'
import { ActionElement } from '../ConstructorInput/ConstructorInput'
import { SelectedVariableBlock, UserAddressDropdownList } from './components'
import * as S from './style'
import { UserInfo } from './types/userInfo'

type ConstructorAddressInputProps = BaseInputProps & {
  actionElements?: ActionElement[]
  isMember?: boolean
  atomId?: string
  atomName: string
  inputName?: string
  isRequired?: boolean
}

export const ConstructorAddressInput: FC<ConstructorAddressInputProps> = (
  props
) => {
  const {
    actionElements,
    placeholder,
    atomId,
    atomName,
    isMember,
    onChange,
    inputName,
    isRequired,
    ...inputBaseProps
  } = props

  const [isSelectListOpen, setIsSelectListOpen] = useState<boolean>(false)

  const [selectedVariableInfo, setSelectedVariableInfo] = useState<{
    label: string
    value: string
  } | null>()

  const [users, setUsers] = useState<UserInfo[]>([])

  const [isUserNameMode, setIsUserNameMode] = useState<boolean>(false)

  const [selectedUser, setSelectedUser] = useState<UserInfo | null>()

  const [typedUsernameValue, setTypedUsernameValue] = useState('')

  const debouncedUsernameValue = useDebounce(typedUsernameValue)

  const { setFieldValue, changeMemberValue, currentContract, getValue } =
    useConstructorContext()

  const { data: usernameUsers = [] } = useAccountUsersList(
    {
      username: debouncedUsernameValue.substring(1),
      size: 100,
    },
    {
      query: {
        enabled: isUserNameMode && debouncedUsernameValue.length > 1,
        select: (data) => data.results,
      },
    }
  )

  const { data: contacts = [] } = usePlatformContactList(
    {
      search: debouncedUsernameValue.substring(1),
    },
    {
      query: {
        enabled: isUserNameMode && debouncedUsernameValue.length > 1,
        select: (data) => data.results,
      },
    }
  )

  useEffect(() => {
    setUsers([
      ...usernameUsers!.map((user) => ({
        address: user.bchAddress!,
        id: user.id.toString(),
        label: user.username!,
      })),
      ...contacts!.map((contact) => ({
        address: contact.address,
        id: contact.id,
        label: contact.name,
      })),
    ])
  }, [usernameUsers, contacts])

  const toggleSelectList = useCallback(() => {
    setIsSelectListOpen((prev) => !prev)
  }, [])

  const handleInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const addressInputValue = evt.target.value

      if (addressInputValue[0] === '@') {
        setIsUserNameMode(true)

        setTypedUsernameValue(addressInputValue)

        return
      }

      if (addressInputValue[0] !== '@' && isUserNameMode) {
        setIsUserNameMode(false)
      }

      if (selectedUser && selectedUser.address !== addressInputValue) {
        setSelectedUser(null)
      }

      if (onChange) {
        onChange(evt)
      }

      if (isMember) {
        changeMemberValue({
          atomId,
          address: addressInputValue,
        })
      }

      if (inputName) {
        setFieldValue({
          atomId,
          name: inputName,
          inputName: atomName,
          value: addressInputValue,
        })

        return
      }

      setFieldValue({
        atomId,
        name: atomName,
        value: addressInputValue,
      })
    },

    [
      isUserNameMode,
      selectedUser,
      onChange,
      isMember,
      inputName,
      setFieldValue,
      atomId,
      atomName,
      changeMemberValue,
    ]
  )

  const handleUserAddressSelect = useCallback(
    (userInfo: UserInfo) => {
      if (!userInfo.address) {
        return
      }

      setSelectedUser(userInfo)

      if (isMember) {
        changeMemberValue({
          atomId,
          address: userInfo.address,
        })
      }

      if (inputName) {
        setFieldValue({
          atomId,
          name: inputName,
          inputName: atomName,
          value: userInfo.address,
        })

        return
      }

      setFieldValue({
        atomId,
        name: atomName,
        value: userInfo.address,
      })

      setIsUserNameMode(false)
    },
    [atomId, atomName, changeMemberValue, inputName, isMember, setFieldValue]
  )

  const currentSelectOptions = useMemo(() => {
    return currentContract.variables.map((variable) => {
      return {
        label: variable.name,
        name: variable.value,
      }
    })
  }, [currentContract.variables])

  const handleSelectVariableClick = useCallback(
    (value: string) => {
      const currentVariable = currentContract.variables.find(
        (variable) => variable.value === value
      )

      if (!currentVariable) {
        return
      }

      setFieldValue({
        atomId,
        name: atomName,
        value,
      })

      setSelectedVariableInfo({
        label: currentVariable.name,
        value,
      })
    },
    [atomId, atomName, currentContract.variables, setFieldValue]
  )

  const handleRemoveSelectedVariableClick = useCallback(() => {
    setSelectedVariableInfo(null)
    toggleSelectList()

    setFieldValue({
      atomId,
      name: atomName,
      value: '',
    })
  }, [atomId, atomName, setFieldValue, toggleSelectList])

  const addressInputValue = useMemo(() => {
    if (isUserNameMode) {
      return typedUsernameValue
    }

    if (inputName) {
      const value = getValue({
        atomId,
        name: inputName,
        inputName: atomName,
        contractId: currentContract.id,
      })

      return typeof value === 'string' ? value : ''
    }

    const value = getValue({
      atomId,
      name: atomName,
      contractId: currentContract.id,
    })

    return typeof value === 'string' ? value : ''
  }, [
    atomId,
    atomName,
    currentContract.id,
    getValue,
    inputName,
    isUserNameMode,
    typedUsernameValue,
  ])

  const currentActionElements = useMemo(() => {
    if (selectedUser) {
      return [
        {
          type: 'BUTTON',
          label: `@${selectedUser.label}`,
        },
      ]
    }

    return actionElements
  }, [actionElements, selectedUser])

  return selectedVariableInfo ? (
    <SelectedVariableBlock
      name={selectedVariableInfo.label}
      onRemove={handleRemoveSelectedVariableClick}
      value={selectedVariableInfo.value}
    />
  ) : (
    <S.Wrapper>
      {isSelectListOpen && (
        <S.ConstructorSelectList
          onChange={handleSelectVariableClick}
          options={currentSelectOptions}
          selectaleItemName="variable"
        />
      )}

      <S.VariableButton
        disabled={inputBaseProps.disabled}
        onClick={toggleSelectList}
      >
        <SvgAddressAtomVariable />
      </S.VariableButton>

      <S.ConstructorInput
        actionElements={currentActionElements}
        isRequired={isRequired}
        onChange={handleInputChange}
        placeholder={placeholder}
        value={addressInputValue}
        {...inputBaseProps}
      />

      {isUserNameMode && (
        <UserAddressDropdownList
          onSelect={handleUserAddressSelect}
          usersInfo={users || []}
        />
      )}
    </S.Wrapper>
  )
}
