import styled from 'styled-components'
import { Card } from 'ui/Card/Card'
import { Chip } from 'ui/Chip/Chip'

export const StyledCard = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 40px 24px 24px;
  width: 267px;
  display: flex;
  flex-direction: column;

  ${media(size.mobile)} {
    max-width: 100%;
    width: 100%;
  }
`
)

export const TitleContainer = styled.div`
  max-width: 219px;
  margin-bottom: 28px;
`

export const Title = styled.h4`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  width: 219px;
  margin: 0;
  padding: 0;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 6px;
`

export const Description = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
  margin: 0;
  padding: 0;
`

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const AmountLabel = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 50px;
`

export const InfoFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UserNameChip = styled(Chip)`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`
