import styled from 'styled-components'
import { Card as UICard } from 'ui/Card/Card'

export const Card = styled(UICard)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 461px;
  width: 100%;
`

export const Title = styled.h4`
  margin: 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
`
