import styled from 'styled-components'
import { Card as UiCard } from 'ui/Card/Card'
import { SvgOptimism } from 'ui/icons'

export const Card = styled(UiCard)(
  ({ theme: { media, size } }) => `
    width: 364px;
    height: 493px;
    display: flex;
    flex-direction: column;

    ${media(size.tablet)} {
        width: 100%;
    }
`
)

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px 12px 0 0;
  padding: 24px;
  border-bottom: none;
  height: 177px;
  background-color: #3ba48b;

  overflow: hidden;
`

export const TagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StatusTag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  padding: 4px 10px;
  height: 28px;
`

export const ProjectIcon = styled(SvgOptimism)`
  width: 50px;
  height: 50px;
  z-index: 1;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  margin: 0;

  color: ${({ theme }) => theme.color.white};

  &:hover {
    cursor: pointer;
  }
`

export const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  top: 2px;
`

export const Image = styled.img`
  object-fit: cover;
`

export const ContentContainer = styled.div`
  padding: 24px;
`

export const DaysContainer = styled.div`
  display: flex;

  margin-bottom: 18px;

  color: ${({ theme }) => theme.color.black};
  font-size: 15px;
  font-weight: 400;
`

export const DaysTag = styled.span`
  font-size: 13px;
  font-weight: 500;

  margin: 0;
  margin-right: 8px;
  padding: 2px 9px;
  border-radius: 12px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.red};
`

export const ContentItem = styled.div`
  display: flex;
  align-items: baseline;

  margin-bottom: 16px;
`

export const PropertyName = styled.span`
  white-space: nowrap;
`

export const PropertyValue = styled.span`
  white-space: nowrap;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
`

export const DashedLine = styled.div`
  width: 100%;
  height: 1px;
  border: 1px dashed ${({ theme }) => theme.color.line};
`
