import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 24px;
  
      ${media(size.mobile)} {
        position: relative;
      }
  `
)

export const NotFoundWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Title = styled.h5`
  font-size: 24px;
  margin: 0;
  font-weight: 800;
  color: ${({ theme }) => theme.color.red};
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-size: 15px;
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  color: ${({ theme }) => theme.color.black};
`

export const Button = styled(UIButton)`
  width: 100%;
`
