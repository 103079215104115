import { useCallback } from 'react'
import { FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNetwork } from 'wagmi'
import { usePlatformTxFaucetCreate } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { notify } from 'libs/notify/notify'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { FaucetForm, FormValues } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Faucet',
    url: AppRoutes.Faucet,
  },
]

export const Faucet = () => {
  const { t } = useTranslation('pageFaucet')

  const { chain } = useNetwork()

  const { mutate, isLoading } = usePlatformTxFaucetCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Funding request accepted'),
          title: t('Funding request accepted'),
        })
      },
    },
  })

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    (values: FormValues) => {
      if (chain) {
        mutate({
          data: {
            address: values.address,
            chainId: chain.id,
          },
        })
      }
    },
    [chain, mutate]
  )

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={t('Faucet JUNA')}>
      <S.FaucetCard>
        <S.Header>
          <S.Title>{t('Get JUNA')}</S.Title>
          <S.Subtitle>{t('Fast and reliable. 1 JUNA/day')}</S.Subtitle>
        </S.Header>
        <FaucetForm isLoading={isLoading} onSubmit={handleSubmit} />
      </S.FaucetCard>
    </PageWrapper>
  )
}
