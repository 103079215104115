import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgTemplate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 15 16"
    width={15}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.83398 0C2.19441 0 1.56464 0.235326 1.09065 0.679898C0.615173 1.12398 0.333984 1.74081 0.333984 2.4V13.6C0.333984 14.2595 0.615447 14.8766 1.09134 15.3208C1.56369 15.7624 2.19248 16 2.83398 16H11.834C12.4754 16 13.1042 15.7625 13.5765 15.3209C14.0525 14.8767 14.334 14.2596 14.334 13.6V6V5.9C14.334 5.63128 14.2258 5.37387 14.0339 5.18579L9.03469 0.286548C8.85425 0.109316 8.60688 0 8.33398 0H2.83398ZM7.33398 2H2.83398C2.67814 2 2.54447 2.05824 2.45862 2.13889L2.45603 2.14132L2.45603 2.14131C2.36828 2.22314 2.33398 2.31779 2.33398 2.4V13.6C2.33398 13.6822 2.36828 13.7769 2.45603 13.8587L2.45723 13.8598C2.54482 13.9418 2.67976 14 2.83398 14H11.834C11.9882 14 12.1232 13.9418 12.2107 13.8598L12.2119 13.8587C12.2997 13.7769 12.334 13.6822 12.334 13.6V7H8.33398C7.7817 7 7.33398 6.55228 7.33398 6V2ZM10.9869 5L9.33398 3.38014V5H10.9869Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgTemplate)
export default Memo
