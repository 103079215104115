import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Container = styled(Card)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 400px;
  ${({ theme: { media, size } }) => media(size.tablet)} {
    flex: none;
    width: 100%;
    max-width: none;
  }
`

export const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
`

export const AllTxLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  text-decoration: none;
  font-size: 15px;
`

export const NotFoundWrapper = styled.div(
  ({ theme: { media, size } }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    ${media(size.tablet)} {
      position: static
    }
  `
)

export const NotFound = styled.div(
  ({ theme: { media, size, color } }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    font-size: 14px;
    color: ${color.comet};
    margin: 32px 20px;

    ${media(size.tablet)} {
      margin: 0px 20px;
    }
  `
)

export const ActionWrapper = styled.div`
  padding: 6px 0;
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
`

export const ActionsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ActionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 300px;
`

export const Label = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const LabelDate = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.mirageLight};
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0px;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`
