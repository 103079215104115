import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import { useClipboard } from 'hooks/useClipboard/useClipboard'
import { useLegalGateBasket } from 'hooks/useLegalGateBasket/useLegalGateBasket'
import { notify } from 'libs/notify/notify'
import * as S from './style'

export type LegalGateItemProps = {
  id: number
  name: string
  description: string
  rating: number
  address?: string
  price: number
  contracts: {
    address: string
    chainId: number
    legalGateId?: number
  }[]
}

export const LegalGateItem = (props: LegalGateItemProps) => {
  const { name, description, rating, address, id } = props
  const { selectLegalGate } = useLegalGateBasket()
  const copyToClipboard = useClipboard()

  const { t } = useTranslation('pageLegalGateCatalog')

  const handleCopyClick = async () => {
    copyToClipboard(address!, () =>
      notify.success({
        title: name,
        message: t('Address has been copied'),
      })
    )
  }

  const handleSelectClick = useCallback(() => {
    selectLegalGate({
      ...props,
    })
    notify.success({
      title: name,
      message: t('Legal Gate has been selected as default'),
    })
  }, [selectLegalGate, props, name, t])

  return (
    <S.Card>
      <S.FlexWrapper>
        <S.LeftSideWrapper>
          <S.Title>{name}</S.Title>
          <S.Description>{description}</S.Description>
        </S.LeftSideWrapper>
        <S.RightSideWrapper>
          <S.Label>
            {t('Rate')}: {rating}
          </S.Label>
          <S.ActionsBlock>
            {address && (
              <S.Label $isLink as="a" onClick={handleCopyClick}>
                {t('Copy')}
              </S.Label>
            )}
            <S.Label $isLink as="a" onClick={handleSelectClick}>
              {t('Select')}
            </S.Label>
            <S.Label
              $isLink
              as={Link}
              to={`${AppRoutes.LegalGatesCatalog}/${id}`}
            >
              {t('Details')}
            </S.Label>
          </S.ActionsBlock>
        </S.RightSideWrapper>
      </S.FlexWrapper>
    </S.Card>
  )
}
