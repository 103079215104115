import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M7.2929 2.6259C7.68342 2.23538 8.31659 2.23538 8.70711 2.6259L13.3741 7.2929C13.7646 7.68342 13.7646 8.31659 13.3741 8.70711L8.70711 13.3741C8.31659 13.7646 7.68342 13.7646 7.2929 13.3741C6.90238 12.9836 6.90238 12.3504 7.2929 11.9599L10.2528 9.00001H3.33301C2.78072 9.00001 2.33301 8.55229 2.33301 8.00001C2.33301 7.44772 2.78072 7.00001 3.33301 7.00001H10.2528L7.2929 4.04011C6.90238 3.64959 6.90238 3.01643 7.2929 2.6259Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgArrowRight)
export default Memo
