import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgContractsFast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#1B1F3B" fillOpacity={0.05} height={48} rx={24} width={48} />
    <path
      clipRule="evenodd"
      d="M17.1967 17.1967C18.6032 15.7902 20.5109 15 22.5 15C24.4891 15 26.3968 15.7902 27.8033 17.1967C29.2098 18.6032 30 20.5109 30 22.5C30 24.1528 29.4545 25.7493 28.4631 27.0489L32.7071 31.2929C33.0976 31.6834 33.0976 32.3166 32.7071 32.7071C32.3166 33.0976 31.6834 33.0976 31.2929 32.7071L27.0489 28.4631C25.7493 29.4545 24.1528 30 22.5 30C20.5109 30 18.6032 29.2098 17.1967 27.8033C15.7902 26.3968 15 24.4891 15 22.5C15 20.5109 15.7902 18.6032 17.1967 17.1967ZM22.5 17C21.0413 17 19.6424 17.5795 18.6109 18.6109C17.5795 19.6424 17 21.0413 17 22.5C17 23.9587 17.5795 25.3576 18.6109 26.3891C19.6424 27.4205 21.0413 28 22.5 28C23.9587 28 25.3576 27.4205 26.3891 26.3891C27.4205 25.3576 28 23.9587 28 22.5C28 21.0413 27.4205 19.6424 26.3891 18.6109C25.3576 17.5795 23.9587 17 22.5 17Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgContractsFast)
export default Memo
