import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNetwork, useAccount } from 'wagmi'
import {
  usePlatformLegalgatecommentList,
  usePlatformLegalgatecommentCreate,
} from 'api/omni/endpoints'
import { PaginatedLegalGateCommentList, LegalGateComment } from 'api/omni/model'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { Input } from 'ui/Input/Input'
import { Loader } from 'ui/Loader/Loader'
import { Comment } from './components'
import * as S from './styles'

type ReviewsProps = {
  legalGateId: number
}

const TEXT_AREA_MAX_LENGTH = 100

export const Reviews: FC<ReviewsProps> = (props) => {
  const { legalGateId } = props

  const [replyToId, setReplyToId] = useState<number | null>(null)
  const [commentText, setCommentText] = useState<string>('')

  const { t } = useTranslation('pageLegalGate')

  const { chain } = useNetwork()

  const { address } = useAccount()

  const {
    data: comments,
    isLoading: isCommentsLoading,
    isSuccess: isCommentsSuccess,
    refetch: refetchLegalGateComments,
  } = usePlatformLegalgatecommentList(
    { legalGateId },
    {
      query: {
        select: useCallback((data: PaginatedLegalGateCommentList) => {
          return data.results.reduce(
            (acc, comment) => {
              if (!comment.replyTo) {
                acc.commentsList.push(comment)
              }
              if (comment.replyTo! > 0) {
                acc.repliesComments.push(comment)
              }

              return acc
            },
            { commentsList: [], repliesComments: [] } as Record<
              string,
              LegalGateComment[]
            >
          )
        }, []),
      },
    }
  )

  const { mutate: addComment, isLoading: isCommentAdding } =
    usePlatformLegalgatecommentCreate({
      mutation: {
        onSuccess: async () => {
          await refetchLegalGateComments()

          notify.success({
            message: t('Comment added'),
            title: t('Comment added'),
          })
        },
      },
    })

  const handleCommentChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCommentText(evt.target.value)
  }

  const handleCloseReplyInput = () => {
    setCommentText('')
    setReplyToId(null)
  }

  const handleAddComment = useCallback(() => {
    if (!chain || !address) {
      return
    }

    addComment({
      data: {
        text: commentText,
        rating: 0,
        replyTo: replyToId,
        chainId: chain.id,
        address: address,
      },
    })

    setCommentText('')
    setReplyToId(null)
  }, [chain, address, addComment, commentText, replyToId])

  return (
    <S.Card>
      <S.Title>{t('Reviews')}</S.Title>
      {isCommentsLoading && <Loader />}
      {isCommentsSuccess &&
        comments.commentsList.map(({ id, createdAt, fromAdr, text }) => (
          <S.CommentWrapper key={id}>
            <Comment
              address={fromAdr}
              comment={text}
              id={id}
              setReplyToId={setReplyToId}
              startedAt={createdAt}
            />

            {replyToId === id && (
              <S.ReplyWrapper>
                <Input
                  onChange={handleCommentChange}
                  placeholder={`Reply ${fromAdr}`}
                  type="text"
                  value={commentText}
                />
                <S.StyledButton onClick={handleAddComment} variant="red">
                  {t('Add comment')}
                </S.StyledButton>
              </S.ReplyWrapper>
            )}
            {comments.repliesComments.map(
              ({ id: replyCommentId, fromAdr, text, createdAt, replyTo }) =>
                replyTo === id && (
                  <S.RepliedComment key={replyCommentId}>
                    <Comment
                      address={fromAdr}
                      comment={text}
                      id={replyCommentId}
                      startedAt={createdAt}
                    />
                  </S.RepliedComment>
                )
            )}
          </S.CommentWrapper>
        ))}
      <div onClick={handleCloseReplyInput}>
        <S.Textarea
          maxLength={TEXT_AREA_MAX_LENGTH}
          onChange={handleCommentChange}
          placeholder={t('Enter your comment')}
          value={!replyToId ? commentText : ''}
        />
        <Button
          isLoading={isCommentAdding}
          onClick={handleAddComment}
          variant="red"
        >
          {t('Add comment')}
        </Button>
      </div>
    </S.Card>
  )
}
