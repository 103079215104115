import { useState, useCallback, useMemo } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useAccount,
  useNetwork,
  useSignTypedData,
  useContractRead,
} from 'wagmi'

import {
  usePlatformContractDeclineCreate,
  usePlatformContractAcceptCreate,
  usePlatformContractDeployCreate,
  platformContractRetrieve,
} from 'api/omni/endpoints'
import { Contract as ContractType } from 'api/omni/model'
import newAction from 'constants/abi/newAction.json'
import { AppRoutes, ContractStatus } from 'constants/app'
import { useMatchMedia, useClipboard } from 'hooks'
import { useDeploy } from 'hooks/useDeploy/useDeploy'
import { getForwarderAddress } from 'libs/addresses/addresses'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { ErrorTextBlock } from 'ui/ErrorTextBlock/ErrorTextBlock'
import { SvgCopy } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Stepper } from 'ui/Stepper/Stepper'
import {
  SignaturesStatus,
  ContractDetails,
  MilestoneWrapper,
  Files,
  AllTransactions,
} from './components'
import * as S from './style'
import { DynamicFieldType, NextContractActionRuntype } from './types'

const EXCLUDE_DETAILS = [
  'legalGates',
  'legalGatesRewards',
  'members',
  'amountJuna',
]

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Catalog',
    url: AppRoutes.Catalog,
  },
  {
    label: 'Create a contract',
    url: AppRoutes.ContractCreate,
  },
]

const buildTypes = (data: ContractType) => {
  const constructorIdx = data.abi.findIndex(
    (element) => element.type === 'constructor'
  )

  if (constructorIdx === -1) {
    throw new Error('Invalid ABI')
  }

  const constructor = data.abi[constructorIdx]

  return (constructor.inputs as []).map(({ name, type }) => ({
    name,
    type,
  }))
}

const buildValues = (
  data: ContractType,
  types: { name: string; type: string }[]
) => {
  return types.reduce((acc, { name, type }) => {
    const valueInData = data.data![name]

    let value

    switch (type) {
      case 'uint256':
        value = Number(valueInData)
        break
      case 'address[]':
        if (name === 'members') {
          const membersAddresses: string[] = []

          if (Array.isArray(valueInData)) {
            valueInData.forEach((member: unknown) => {
              const memberWithType = member as { name: string; address: string }

              membersAddresses.push(memberWithType.address)
            })
          }

          value = membersAddresses
          break
        }

        value = valueInData
        break
      default:
        value = valueInData
    }
    acc[name] = value
    return acc
  }, {} as Record<string, unknown>)
}

export const Contract = () => {
  const [activeStep, setActiveStep] = useState<number>(0)

  const { contractId } = useParams()

  const { signTypedDataAsync } = useSignTypedData()

  const { chain } = useNetwork()

  const { address } = useAccount()

  const deploy = useDeploy()

  const { isMobile } = useMatchMedia()

  const { t } = useTranslation('pageContract')

  const copyToClipboard = useClipboard()

  const navigate = useNavigate()

  const currentUrl = window.location.href

  const steps = useMemo(
    () => [
      {
        label: t('Creation'),
        step: 1,
      },
      {
        label: t('Signing'),
        step: 2,
      },
      {
        label: t('Deployment'),
        step: 3,
      },
      {
        label: t('Execution'),
        step: 4,
      },
    ],
    [t]
  )

  const {
    data: contractData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery(
    ['contract', contractId],
    () =>
      platformContractRetrieve(Number(contractId)).then((contract) => {
        if (!contract.data) {
          throw new Error('Error')
        }

        return contract
      }),
    {
      enabled: !!contractId,
      onSuccess: (data) => {
        switch (data.status) {
          case ContractStatus.Assurance:
            setActiveStep(2)
            break
          case ContractStatus.Deployment:
            setActiveStep(3)
            break
          case ContractStatus.Execution:
            setActiveStep(4)
            break
        }
      },
      onError: (err: AxiosError) => {
        return notify.error({
          message: err.message,
          title: err.name,
        })
      },
    }
  )

  const { data: nextContractAction } = useContractRead({
    address: contractData?.address,
    abi: JSON.parse(newAction.result),
    functionName: 'nextAction',
    enabled: activeStep === 4 && contractData !== undefined,
    select: useCallback((data: unknown) => {
      return NextContractActionRuntype.check(data)
    }, []),
  })

  const { mutate: cancel, isLoading: isContractCancelling } =
    usePlatformContractDeclineCreate({
      mutation: {
        onSuccess: () => {
          notify.success({
            message: t('Sign Contract canceled'),
            title: t('Sign Contract canceled'),
          })
        },
      },
    })

  const { mutate: accept, isLoading: isContractAccepting } =
    usePlatformContractAcceptCreate({
      mutation: {
        onSuccess: () => {
          refetch()
          notify.success({
            message: t('Sign Contract accepted'),
            title: t('Sign Contract accepted'),
          })
        },
      },
    })

  const { mutate: deployContract, isLoading: isContractDeploying } =
    usePlatformContractDeployCreate({
      mutation: {
        onSuccess: () => {
          notify.success({
            message: t('Contract has been deployed'),
            title: t('Contract has been deployed'),
          })
        },
      },
    })

  const handleCopyBtnClick = useCallback(() => {
    copyToClipboard(currentUrl, () => {
      notify.success({
        title: t('Success'),
        message: t('Contract link has been copied'),
      })
    })
  }, [copyToClipboard, currentUrl, t])

  const handleCancelClick = useCallback(() => {
    if (contractData) {
      cancel({ id: contractData.id })
    }
  }, [cancel, contractData])

  const handleAcceptClick = useCallback(async () => {
    if (!contractData || !chain) {
      return
    }

    const types = buildTypes(contractData)
    const values = buildValues(contractData, types)

    const signed = await signTypedDataAsync({
      domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
      types: {
        Form: types,
      },
      value: values,
    })

    accept({
      id: contractData.id,
      data: {
        dataSig: signed,
        dataUnsig: {
          primaryType: 'Form',
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            Form: types,
          },
          message: values,
        },
      },
    })
  }, [contractData, signTypedDataAsync, accept, chain])

  const handleDeployClick = useCallback(async () => {
    if (contractData && contractData.data) {
      try {
        const deployTx = await deploy(contractData, contractData.data)

        deployContract({
          id: contractData.id,
          data: {
            txHash: deployTx.deployTransaction.hash,
          },
        })
      } catch {
        notify.error({
          title: 'Something went wrong!',
          message: 'Something went wrong!',
        })
      }
    }
  }, [contractData, deploy, deployContract])

  const detailsContract = useMemo(() => {
    //TODO: разобраться что тут происходит
    if (!contractData || !contractData.data) {
      return []
    }

    const contractDetailsData = [
      Object.entries(contractData.data)
        .filter(([key]) => !EXCLUDE_DETAILS.includes(key))
        .map(([key, value]) => ({
          label: (
            (contractData.context!['constructor'] as any)[
              key
            ] as DynamicFieldType
          ).name,
          value: value as string,
        })),
    ]

    return contractDetailsData
  }, [contractData])

  const pageWrapperActions = useMemo(() => {
    if (!contractData || !address) {
      return
    }

    const isMember = contractData.members.some(
      ({ address: memberAddress }) => address.toLowerCase() === memberAddress
    )

    const isDeployment =
      contractData.status === ContractStatus.Deployment &&
      contractData.initiator === address.toLocaleLowerCase()

    const isAssurance =
      contractData.status === ContractStatus.Assurance && isMember

    switch (true) {
      case isDeployment:
        return (
          <Button
            isLoading={isContractDeploying}
            onClick={handleDeployClick}
            variant="red"
          >
            {t('Publish a contract')}
          </Button>
        )
      case isAssurance:
        return (
          <>
            <Button
              isLoading={isContractCancelling}
              onClick={handleCancelClick}
              variant="gray"
            >
              {t('Refuse')}
            </Button>
            <Button
              isLoading={isContractAccepting}
              onClick={handleAcceptClick}
              variant="red"
            >
              {t('Sign')}
            </Button>
          </>
        )
    }
  }, [
    contractData,
    address,
    t,
    isContractDeploying,
    handleDeployClick,
    isContractCancelling,
    handleCancelClick,
    isContractAccepting,
    handleAcceptClick,
  ])

  const handleCreateTransactionClick = useCallback(() => {
    if (contractData) {
      navigate(`${AppRoutes.Contracts}/${contractData.id}/transaction-create`)
    }
  }, [contractData, navigate])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <ErrorTextBlock message={error.message} />
  }

  if (isSuccess) {
    const getPageTitle = () => {
      switch (contractData.status) {
        case ContractStatus.Deployment:
          return t('Contract deployment')
        case ContractStatus.Execution:
          return t('Contract execution')
        default:
          return t('Contract signing')
      }
    }

    return (
      <PageWrapper
        actions={
          <>
            {isMobile && (
              <S.CopyButton onClick={handleCopyBtnClick}>
                <SvgCopy />
                {t('Copy link')}
              </S.CopyButton>
            )}
            {pageWrapperActions}
          </>
        }
        breadcrumbs={breadcrumbs}
        title={getPageTitle()}
      >
        <S.Wrapper>
          <S.LeftSideWrapper>
            <Stepper
              activeStep={activeStep}
              steps={steps}
              vertical={isMobile}
            />
            <ContractDetails
              contractAddress={contractData.address}
              description={contractData.description}
              details={detailsContract}
              legalGateDescription={
                contractData.legalGate && contractData.legalGate.address
              }
              legalGateDetails={contractData.legalGate}
              title={contractData.name!}
            />
            {contractData.contractTypeId === 2 && <AllTransactions />}
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <SignaturesStatus
              legalGate={contractData.legalGate}
              signatures={contractData.members}
            />
            {activeStep === 4 && contractData.contractTypeId === 2 && (
              <Button onClick={handleCreateTransactionClick} variant="red">
                {t('Create Transaction')}
              </Button>
            )}
            <MilestoneWrapper
              context={contractData.context!}
              contractId={contractData.id}
              nextAction={nextContractAction}
            />
            {contractData.currentIpfsHash !== '' && (
              <Files files={[contractData.file!]} />
            )}
          </S.RightSideWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
