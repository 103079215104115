import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import MORE from './assets/more.png'

export const Title = styled.div(
  ({ theme: { media, size, color } }) => `
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.02em;
  color: ${color.black};
  margin: 12px 0;

  ${media(size.tablet)} {
    font-size: 32px;
    line-height: 44px;
  }
  `
)

export const ItemsContainer = styled.div(
  ({ theme: { media, size } }) => `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;


  ${media(size.tablet)} {
    grid-template-columns: repeat(1, 1fr);
  }
  `
)

export const ContractsWrapper = styled.div`
  position: relative;
  min-height: 332px;
  background: ${({ theme }) => theme.color.bg};
  padding: 45px 0;
`

export const More = styled.div`
  height: 48px;
  width: 109px;
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: url(${MORE});
  background-repeat: no-repeat;
`

export const MoreTitle = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: -6px;
  margin-left: 1px;
  z-index: 1;
`

export const Link = styled(RouterLink)`
  text-decoration: none;
`

export const SecondTitle = styled.h2(
  ({ theme: { media, size, color } }) => `
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  color: ${color.black};
  margin: 64px 0 40px 0;
  padding: 0;
  text-align: center;

  ${media(size.tablet)} {
    font-size: 24px;
    line-height: 36px;
    padding: 0 16px;
    margin-bottom: 64px;
  }
`
)

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 85px;
`
