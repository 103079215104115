import { FC } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormValues } from 'pages/JunaSimpleV3/types'
import * as S from './style'

export const ContractDetails: FC = () => {
  const { t } = useTranslation('pageJunaSimple')

  const { values, errors } = useFormikContext<FormValues>()

  const { contractName, description } = values

  return (
    <S.Wrapper>
      <S.Title>
        {t('Declare contract')}: {!errors.contractName && `${contractName}`}
      </S.Title>
      <S.Label>{!errors.description && description}</S.Label>
    </S.Wrapper>
  )
}
