import { FC, useState, useCallback, useMemo } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import {
  usePlatformAdsRetrieve,
  usePlatformContractCreate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { useMatchMedia } from 'hooks'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Stepper } from 'ui/Stepper/Stepper'
import { JunaSimpleForm, ContractDetails } from './components'
import { MUTATION_CONTRACT_CREATE_KEY } from './constants'
import contract from './contract.json'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

export const JunaSimpleV3: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(1)
  const { chain } = useNetwork()

  const { isMobile } = useMatchMedia()

  const navigate = useNavigate()

  const { address } = useAccount()

  const [searchParams] = useSearchParams()
  const adId = searchParams.get('adId')

  const { data: ad, isLoading: isAdLoading } = usePlatformAdsRetrieve(
    Number(adId),
    {
      query: {
        enabled: !!adId,
      },
    }
  )

  const { t } = useTranslation('pageJunaSimpleV3')

  const steps = useMemo(
    () => [
      {
        label: t('Contract name'),
        step: 1,
      },
      {
        label: t('Select partner'),
        step: 2,
      },
      {
        label: t('Contract conditions'),
        step: 3,
      },
    ],
    [t]
  )

  const { mutate } = usePlatformContractCreate({
    mutation: {
      onSuccess: (data) => {
        navigate(`${AppRoutes.Contracts}/${data.id}`)
      },
      mutationKey: MUTATION_CONTRACT_CREATE_KEY,
    },
  })

  const getInitialValues = () => {
    const initialValues: FormValues = {
      contractName: 'Simple contract',
      partnerAddress: '',
      description: '',
      amountJUNA: '',
    }

    if (ad) {
      initialValues.contractName = ad.name
      initialValues.partnerAddress = ad.address
      initialValues.description = ad.description
    }

    return initialValues
  }

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: `Juna Simple - Step ${activeStep}`,
        url: AppRoutes.SimpleV3,
      },
    ]

    if (ad) {
      return [
        {
          label: 'Home',
          url: AppRoutes.Main,
        },
        {
          label: 'Marketplace',
          url: AppRoutes.Marketplace,
        },
        {
          label: ad.name,
          url: `${AppRoutes.Marketplace}/${ad.id}`,
        },
        {
          label: 'Juna Simple v3',
          url: AppRoutes.SimpleV3,
        },
      ]
    }

    return initialBreadcrumbs
  }, [activeStep, ad])

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values: FormValues) => {
      if (address) {
        const contractMembers = [
          {
            name: address,
            address: address,
          },
          {
            name: values.partnerAddress,
            address: values.partnerAddress,
          },
        ]

        const reqBody = {
          name: values.contractName,
          members: contractMembers,
          chainId: chain!.id,
          ipfs: undefined,
          data: {
            members: contractMembers,
            legalGates: [],
            legalGatesRewards: [],
            amountJuna: values.amountJUNA,
            nameSoftware: values.contractName,
          },
          code: contract.code.code,
          abi: JSON.parse(contract.code.abi),
          context: contract.code.context,
          description: values.description,
          bytecode: contract.code.bytecode,
          assurance: true,
          legalGateId: undefined,
        }

        mutate({ data: reqBody })
      }
    },
    [address, mutate, chain]
  )

  const handleFormBtnClick = useCallback(() => {
    switch (activeStep) {
      case 1:
        setActiveStep(2)
        break
      case 2:
        setActiveStep(3)
        break
    }
  }, [activeStep])

  if (isAdLoading) {
    return <Loader />
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={t('Juna Simple')}>
      {activeStep === 1 && (
        <S.Label>
          {t(
            'Juna Simple is a short contract between two parties, which will pay the agreed amount automatically once the contract obligations are completed'
          )}
          .
        </S.Label>
      )}
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        <S.Wrapper>
          <S.LeftSideWrapper>
            <Stepper
              activeStep={activeStep}
              steps={steps}
              vertical={isMobile}
            />
            <JunaSimpleForm
              activeStep={activeStep}
              legalGates={contract.formFields.legalGates}
              onClick={handleFormBtnClick}
            />
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <ContractDetails />
          </S.RightSideWrapper>
        </S.Wrapper>
      </Formik>
    </PageWrapper>
  )
}
