import { FC, useCallback } from 'react'
import { BigNumber } from 'ethers'
import { erc20ABI, useAccount, useContractReads } from 'wagmi'
import { ChainId, Chains } from 'constants/blockchain'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { AddressChip } from 'ui/AddressChip/AddressChip'
import { SvgJunaTokenIcon } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { toHumanNumber } from 'utils/toHumanNumber'
import { CollapsedBlock } from './../CollapsedBlock/CollapsedBlock'

import * as S from './style'

const ACCOUNT_CHAINS = [ChainId.ETH, ChainId.BSC, ChainId.BSC_TESTNET]

export const MyAccountBlock: FC = () => {
  const { address } = useAccount()
  const { data, refetch, isSuccess, isLoading } = useContractReads({
    contracts: [
      {
        address: getJunaTokenAddress(ChainId.ETH),
        abi: erc20ABI,
        chainId: ChainId.ETH,
        functionName: 'balanceOf',
        args: [address!],
      },
      {
        address: getJunaTokenAddress(ChainId.BSC),
        abi: erc20ABI,
        chainId: ChainId.BSC,
        functionName: 'balanceOf',
        args: [address!],
      },
      {
        address: getJunaTokenAddress(ChainId.BSC_TESTNET),
        abi: erc20ABI,
        chainId: ChainId.BSC_TESTNET,
        functionName: 'balanceOf',
        args: [address!],
      },
    ],
    enabled: false,
  })

  const handleCollapse = useCallback(
    (isCollapsed: boolean) => {
      if (address && isCollapsed) {
        refetch()
      }
    },
    [refetch, address]
  )

  return (
    <CollapsedBlock
      onCollapse={handleCollapse}
      rightElement={address && <AddressChip address={address} />}
      title="Account"
    >
      {isLoading && <Loader />}
      {isSuccess &&
        ACCOUNT_CHAINS.map((chainId, idx) => {
          return (
            <S.BalanceContainer key={chainId}>
              {Chains[chainId].icon}
              <S.Content>
                <S.BalanceLabelWrapper>
                  {data?.[idx]
                    ? `${toHumanNumber(BigNumber.from(data[idx]))}`
                    : '0.0'}{' '}
                  <SvgJunaTokenIcon />
                </S.BalanceLabelWrapper>
                <S.Label>{Chains[chainId].name}</S.Label>
              </S.Content>
            </S.BalanceContainer>
          )
        })}
    </CollapsedBlock>
  )
}
