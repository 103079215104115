import { rgba } from 'polished'
import styled from 'styled-components'
import { Card as UiCard } from 'ui/Card/Card'
import { SvgOptimism } from 'ui/icons'

export const Card = styled(UiCard)(
  ({ theme: { media, size } }) => `
  width: 364px;
  padding: 24px 24px;
  height: 334px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media(size.tablet)} {
    width: 100%;
  }
`
)

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TenderIcon = styled(SvgOptimism)`
  width: 50px;
  height: 50px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  margin: 0;

  &:hover {
    cursor: pointer;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`

export const Description = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.color.gray};
  line-height: 24px;
  overflow: hidden;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Tag = styled.div`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 100px;
  background-color: ${({ theme }) => rgba(theme.color.red, 0.16)};
  color: ${({ theme }) => theme.color.red};
  width: 91px;
`

export const FooterCard = styled(UiCard)`
  box-shadow: none;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 15px;
`

export const DashedLine = styled.div`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.color.line};
`

export const Value = styled.div`
  white-space: nowrap;
  font-weight: 700;
`
