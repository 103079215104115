import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { SvgJunaLogo as LogoImage } from 'ui/icons'
import { MobileMenu as MobileMenuComponent } from './components'

const HEIGHT_DESKTOP = '80px'
const HEIGHT_MOBILE = '48px'

export const HeaderWrapper = styled.header`
  width: 100%;
  border-bottom: 1px solid #e2e2ee;
  box-shadow: 0 8px 16px rgb(237, 237, 243);
  background-color: ${({ theme }) => theme.color.white};
`

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    height: ${HEIGHT_DESKTOP};
    align-items: center;
    justify-content: space-between;
    position: relative;

    ${media(size.tablet)}{
      gap: 32px;
    }

    ${media(size.mobile)} {
      height: ${HEIGHT_MOBILE};
    }
`
)

export const LeftWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  ${media(size.tablet)} {
    gap: 32px;
  }
`
)

export const Logo = styled.div<{ isHome: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isHome }) => (isHome ? 'default' : 'pointer')};
`

export const SvgLogo = styled(LogoImage)(
  ({ theme: { media, size } }) => `
    ${media(size.mobile)} {
      width: 84px;
      height: 27px;
    }
`
)

export const ConnectWalletButton = styled(Button)(
  ({ theme: { media, size } }) => `
    padding: 14px 50px;
    white-space: nowrap;
    
    ${media(size.tablet)} {
      display: none;
    }
`
)

export const NetworkButton = styled(Button)(
  ({ theme: { media, size } }) => `
    padding: 8px 8px;
    margin-right: 24px;
    font-size: 12px;
    gap: 8px;

    ${media(size.tablet)} {
      display: none;
    }
`
)

export const MobileMenu = styled(MobileMenuComponent)(
  ({ theme: { media, size } }) => `
    position: absolute;
    top: ${HEIGHT_DESKTOP};
    left: 0;
    width: 100%;
    height: calc(100dvh - ${HEIGHT_DESKTOP});
    z-index: 10;

    ${media(size.mobile)} {
      top: ${HEIGHT_MOBILE};
      height: calc(100dvh - ${HEIGHT_MOBILE});
    }
  `
)

export const Hamburger = styled.button(
  ({ theme: { media, size } }) => `
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
`
)

export const HamburgerWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: none;

  ${media(size.tablet)} {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`
)

export const BtnWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    align-items: center;

    ${media(size.tablet)} {
      display: none;
    }
`
)

export const ConnectWalletBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

export const SubMenuLayout = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.mirageDark};
`
