import { useDrag } from 'react-dnd'
import { AtomAction } from 'api/omni/model'
import { FCWithChildren } from 'types/app'

type DragProps = {
  collectItem: AtomAction
}

export const Drag: FCWithChildren<DragProps> = (props) => {
  const { children, collectItem } = props

  const [, drag] = useDrag(() => ({
    type: 'box',
    item: collectItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  return <div ref={drag}>{children}</div>
}
