import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { ContractLG, Member } from 'api/omni/model'
import { ApiOmni } from 'api/types'
import { useClipboard, useMatchMedia } from 'hooks'
import { notify } from 'libs/notify/notify'
import { Chip, ChipProps } from 'ui/Chip/Chip'
import { SvgCopy } from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type SignaturesStatusType = {
  signatures: Member[]
  legalGate?: ContractLG
}

const statusToChipVariant: Record<ApiOmni.Status3db, ChipProps['variant']> = {
  pending: 'info',
  accepted: 'success',
  canceled: 'danger',
}

export const SignaturesStatus: FC<SignaturesStatusType> = (props) => {
  const { signatures, legalGate } = props
  const { address: userAddress } = useAccount()

  const { isDesktop, isTablet } = useMatchMedia()

  const { t } = useTranslation('pageContract')

  const copyToClipboard = useClipboard()

  const currentUrl = window.location.href

  const handleCopyBtnClick = async () => {
    await copyToClipboard(currentUrl, () => {
      notify.success({
        title: t('Success'),
        message: t('Contract link has been copied'),
      })
    })
  }

  return (
    <S.StyledCard>
      <S.Container>
        {isDesktop && (
          <S.Button onClick={handleCopyBtnClick}>
            <SvgCopy />
            {t('Copy link')}
          </S.Button>
        )}
        <S.MembersBlock>
          <S.Title>{t('Members')}</S.Title>
          <S.Wrapper>
            {signatures.map(({ name, address, status }) => (
              <S.SignatureBlock key={`${address}_${name}`}>
                <S.NameLabel>
                  {name?.toLowerCase() === address
                    ? shortenAddress(address)
                    : name}

                  {address === userAddress?.toLowerCase() && '(You)'}
                </S.NameLabel>
                <S.WalletLabel>{shortenAddress(address)}</S.WalletLabel>
                {status && (
                  <Chip variant={statusToChipVariant[status]}>{status}</Chip>
                )}
              </S.SignatureBlock>
            ))}
          </S.Wrapper>
        </S.MembersBlock>
      </S.Container>
      {legalGate && (
        <S.Container>
          <S.Title>{t('Legal Gates')}</S.Title>
          <S.Wrapper>
            <S.SignatureBlock>
              <S.NameLabel>{legalGate.name}</S.NameLabel>
              <S.WalletLabel>{shortenAddress(legalGate.address)}</S.WalletLabel>
              {legalGate.status && (
                <Chip variant={statusToChipVariant[legalGate.status]}>
                  {legalGate.status}
                </Chip>
              )}
            </S.SignatureBlock>
          </S.Wrapper>
        </S.Container>
      )}
      {isTablet && (
        <S.Button onClick={handleCopyBtnClick}>
          <SvgCopy />
          {t('Copy link')}
        </S.Button>
      )}
    </S.StyledCard>
  )
}
