import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: flex-start;
  gap: 32px;

  ${media(size.tablet)} {
    flex-direction: column;
  }
`
)

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  width: 100%;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`
