import { useMemo } from 'react'
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi'

export const useProviderOrSigner = (withSignerIfPossible = true) => {
  const { chain } = useNetwork()
  const provider = useProvider({ chainId: chain?.id })
  const { data: signer } = useSigner()
  const { address, isConnected } = useAccount()

  return useMemo(
    () =>
      withSignerIfPossible && address && isConnected && signer
        ? signer
        : provider,
    [address, isConnected, provider, signer, withSignerIfPossible]
  )
}
