import styled from 'styled-components'
import { Button as UiButton } from 'ui/Button/Button'
import Container from 'ui/Container/Container'
import { SvgLaunch, SvgLaunchTablet } from 'ui/icons'

export const LaunchpadContainer = styled(Container)(
  ({ theme: { media, size } }) => `
    padding: 66px 150px;

  ${media(size.tablet)} {
    padding: 24px 16px;
  }
`
)

export const Banner = styled.div(
  ({ theme: { media, size, color } }) => `
    position: relative;
    width: 1140px;
    height: 489px;
    background-color: ${color.black};
    border-radius: 12px;
    background: linear-gradient(129deg, #411F8A 44.33%, #DB5CFF 80.88%, #FF7653 100.02%);
    padding: 30px;

  ${media(size.tablet)} {
    width: 740px;
    height: 489px;
    padding: 18px;
  }

  ${media(size.mobile)} {
    width: 351px;
    height: 575px;
  }
  `
)

export const BannerImage = styled.img(
  ({ theme: { media, size } }) => `
  position: absolute;
  right: 0;
  top: 0;

  ${media(size.mobile)} {
    display: none;
  }  
  `
)

export const BannerImageTablet = styled.img(
  ({ theme: { media, size } }) => `
    display: none;

  ${media(size.mobile)} {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }  
  `
)

export const LaunchIconContainer = styled.div(
  ({ theme: { media, size } }) => `
  margin-bottom: 28px;

  ${media(size.tablet)} {
    margin-bottom: 192px;
  }

  ${media(size.mobile)} {
    position: relative;
    margin-bottom: 101px;
    z-index: 10;
  }
`
)

export const LaunchIcon = styled(SvgLaunch)(
  ({ theme: { media, size } }) => `

    ${media(size.tablet)} {
      display: none;
    }
  `
)

export const LaunchIconTablet = styled(SvgLaunchTablet)(
  ({ theme: { media, size } }) => `
    display: none;

    ${media(size.tablet)} {
      display: block;
    }
  `
)

export const BannerTitle = styled.h2(
  ({ theme: { media, size, color } }) => `
    color: ${color.white};
    font-size: 82px;
    font-weight: 800;
    line-height: 130%;
    margin: 0;

    ${media(size.tablet)} {
      font-size: 48px;
    }

    ${media(size.mobile)} {
      position: relative;
      z-index: 10;
      line-height: 120%;
    }
  `
)

export const BannerText = styled.p(
  ({ theme: { media, size, color } }) => `
    color: ${color.white};
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
    margin-bottom: 24px;
  
    ${media(size.tablet)} {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
      z-index: 10;
    }

    ${media(size.mobile)} {
      width: 289px;
    }
  `
)

export const BannerCardContainer = styled.div(
  ({ theme: { media, size, color } }) => `
    position: relative;
    display: flex;
    gap: 12px;
    z-index: 10;

    ${media(size.tablet)} {
      gap: 8px;
    }

    ${media(size.mobile)} {
      flex-direction: column;
    }
  `
)

export const BannerCard = styled.div(
  ({ theme: { media, size, color } }) => `
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: ${color.white};
    padding: 48px 50px 42px;
    border-radius: 12px;
    border: 1px solid #E2E2EE; 

    ${media(size.tablet)} {
      width: 230px;
      padding: 24px 0;
      align-items: center;
    }

    ${media(size.mobile)} {
      width: 315px;
      height: 82px;
      padding: 12px 0;
    }
  `
)

export const BannerCardTitle = styled.h3(
  ({ theme: { media, size, color } }) => `
    color: ${color.black};
    font-size: 42px;
    font-weight: 800;
    line-height: 120%;
    margin: 0;

    ${media(size.tablet)} {
      font-size: 28px;
    }
  `
)

export const BannerCardText = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 15px;
  font-weight: 700;
  margin: 0;
`

export const Wrapper = styled.div`
  margin-top: 24px;
`

export const ButtonWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    justify-content: space-between;
    align-items: end;

    ${media(size.mobile)} {
      flex-direction: column;
      gap: 24px;
      justify-content: start;
      align-items: start;
    }
  `
)

export const FilterButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`
export const FilterButton = styled(UiButton)`
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  width: fit-content;
  color: ${({ theme }) => theme.color.gray};
  font-size: 13px;
`

export const ApplyButton = styled(UiButton)(
  ({ theme: { media, size } }) => `
    width: 270px;
    font-size: 15px;
    font-weight: 700;

  ${media(size.mobile)} {
    width: 100%;
  }
  `
)

export const Title = styled.div(
  ({ theme: { media, size } }) => `
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 24px;
    line-height: 111.111%;
  
    ${media(size.mobile)} {
      width: 100%;
      font-size: 24px;
    }
  `
)

export const Projects = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
