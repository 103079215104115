import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNetwork, useSwitchNetwork } from 'wagmi'
import { Chains, ChainId } from 'constants/blockchain'
import { Modal } from 'ui/Modal/Modal'
import * as S from './style'

type NetworkModalProps = {
  onClose: () => void
}

type NetworkMenuItem = {
  name: string
  icon: JSX.Element
  chainId: number
}

const networks: NetworkMenuItem[] = Object.entries(ChainId).map(
  ([chain, chainId]) => {
    return {
      name: Chains[chainId].name,
      icon: Chains[chainId].icon,
      chainId,
    }
  }
)

export const NetworkModal: FC<NetworkModalProps> = ({ onClose }) => {
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  const { t } = useTranslation('modalNetworkModal')

  const handleWalletClick = (chainId: number) => () => {
    switchNetwork?.(chainId)
    onClose()
  }

  return (
    <Modal onClose={onClose} title={t('Select network')}>
      <S.Wrapper>
        <S.Body>
          {networks.map(({ name, icon, chainId }) => (
            <S.Network
              key={name}
              active={chain?.id === chainId}
              onClick={handleWalletClick(chainId)}
            >
              <S.Content>
                {icon}
                <S.Wallet>{name}</S.Wallet>
              </S.Content>
            </S.Network>
          ))}
        </S.Body>
      </S.Wrapper>
    </Modal>
  )
}
