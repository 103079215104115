import { FC, useEffect, useRef, useState } from 'react'
import { SvgChevronDown } from 'ui/icons'
import { SubmenuLink } from '../../../../types'
import { MenuLink } from '../MenuLink/MenuLink'
import { SubmenuLinks } from '../SubmenuLinks/SubmenuLinks'
import * as S from './style'

type SubmenuProps = {
  navLink: SubmenuLink
}

export const Submenu: FC<SubmenuProps> = (props) => {
  const { navLink } = props
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [submenuLinksCoords, setSubmenuLinksCoords] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })

  const linkRef = useRef<HTMLButtonElement | null>(null)
  const submenuLinkRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handler = (event: any) => {
      if (!submenuLinkRef.current || !linkRef.current) {
        return
      }
      if (
        submenuLinkRef.current.contains(event.target) ||
        linkRef.current.contains(event.target)
      ) {
        return
      }
      setIsSubmenuOpen(false)
    }

    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [isSubmenuOpen])

  const updateNavGroupCoords = () => {
    if (!linkRef.current) {
      return
    }
    const { top, left } = linkRef.current.getBoundingClientRect()
    setSubmenuLinksCoords({
      x: left,
      y: top + linkRef.current.clientHeight,
    })
  }

  const onMouseEnter = () => {
    setIsSubmenuOpen(true)
    updateNavGroupCoords()
  }

  const onMouseLeave = () => {
    setIsSubmenuOpen(false)
  }

  const toggleSubmenu = () => {
    setIsSubmenuOpen((prevSubmenuOpen) => !prevSubmenuOpen)
    updateNavGroupCoords()
  }

  return (
    <>
      <MenuLink
        ref={linkRef}
        aria-expanded={isSubmenuOpen}
        aria-haspopup="menu"
        as="button"
        onClick={toggleSubmenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {navLink.label} {isSubmenuOpen ? <S.ChevronUp /> : <SvgChevronDown />}
      </MenuLink>
      <SubmenuLinks
        ref={submenuLinkRef}
        $isSubmenuOpen={isSubmenuOpen}
        left={submenuLinksCoords.x}
        onClick={toggleSubmenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sublinks={navLink.sublinks}
        top={submenuLinksCoords.y}
      />
    </>
  )
}
