import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card as CardComponent } from 'ui/Card/Card'
import { Textarea as UITextArea } from 'ui/Textarea/Textarea'

export const Card = styled(CardComponent)`
  width: 100%;
  padding: 24px;
`

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.black};
`

export const CommentWrapper = styled.div`
  margin-bottom: 32px;
`

export const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;
  padding-left: 60px;
`

export const StyledButton = styled(Button)`
  font-size: 14px;
  flex: 1;
  padding: 12px 24px;
`

export const RepliedComment = styled.div(
  ({ theme: { media, size } }) => `
    margin-left: 60px;
  
    ${media(size.mobile)} {
      margin-left: 0;
    }
  `
)

export const Textarea = styled(UITextArea)`
  margin-bottom: 24px;
`
