import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgLaunch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={80}
    viewBox="0 0 80 80"
    width={80}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="url(#paint0_linear_5450_26655)"
      height={80}
      rx={20}
      width={80}
    />
    <mask
      height={48}
      id="mask0_5450_26655"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={48}
      x={16}
      y={16}
    >
      <rect fill="#D9D9D9" height={48} width={48} x={16} y={16} />
    </mask>
    <g mask="url(#mask0_5450_26655)">
      <path
        d="M40 60C37.8 60 35.675 59.6583 33.625 58.975C31.575 58.2917 29.7 57.3 28 56L30.9 53.1C32.3 54.0667 33.7667 54.7917 35.3 55.275C36.8333 55.7583 38.4 56 40 56C44.4333 56 48.2083 54.4417 51.325 51.325C54.4417 48.2083 56 44.4333 56 40C56 35.5667 54.4417 31.7917 51.325 28.675C48.2083 25.5583 44.4333 24 40 24C35.5667 24 31.7917 25.5583 28.675 28.675C25.5583 31.7917 24 35.5667 24 40H20C20 37.2333 20.525 34.6333 21.575 32.2C22.625 29.7667 24.05 27.65 25.85 25.85C27.65 24.05 29.7667 22.625 32.2 21.575C34.6333 20.525 37.2333 20 40 20C42.7667 20 45.3583 20.525 47.775 21.575C50.1917 22.625 52.3083 24.0583 54.125 25.875C55.9417 27.6917 57.375 29.8083 58.425 32.225C59.475 34.6417 60 37.2333 60 40C60 42.7333 59.475 45.3167 58.425 47.75C57.375 50.1833 55.9417 52.3083 54.125 54.125C52.3083 55.9417 50.1917 57.375 47.775 58.425C45.3583 59.475 42.7667 60 40 60ZM23.95 51.85L32.1 43.7L38.1 48.7L48 38.8V44H52V32H40V36H45.2L37.9 43.3L31.9 38.3L21.85 48.35C22.2167 49.1167 22.5417 49.7417 22.825 50.225C23.1083 50.7083 23.4833 51.25 23.95 51.85Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_5450_26655"
        x1={-0.00000434995}
        x2={134.142}
        y1={-34.5}
        y2={38.1858}
      >
        <stop stopColor="#728BFF" />
        <stop offset={0.34375} stopColor="#E557FF" />
        <stop offset={0.625} stopColor="#FF634D" />
        <stop offset={0.90625} stopColor="#FDEF73" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgLaunch)
export default Memo
