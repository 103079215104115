import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  gap: 24px;

  ${media(size.mobile)} {
    flex-direction: column;
  }
`
)

export const NewProjectCard = styled(Card)(
  ({ theme: { media, size, color } }) => `
  max-width: 364px;
  background: none;
  width: 100%;
  border: 1px dashed ${color.line};
  padding: 135px 20px;

  ${media(size.tablet)} {
    max-width: 100%;
  }
`
)
