import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccountUsersPartialUpdate } from 'api/omni/endpoints'
import { useDebounce, useUserData } from 'hooks'
import { Input } from 'ui/Input/Input'
import * as S from './styles'

export type FormValues = {
  login: string
}

export const MyProfile: FC = () => {
  const { t } = useTranslation('pageSettings')

  const [loginValue, setLoginValue] = useState<string>('')

  const debauncedLoginValue = useDebounce(loginValue)

  const { userData } = useUserData()

  const { mutate, data: updatedUserData } = useAccountUsersPartialUpdate()

  const handleLoginInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setLoginValue(evt.target.value)
    },
    []
  )

  useEffect(() => {
    if (userData?.id && debauncedLoginValue.length > 0) {
      mutate({
        id: userData.id,
        data: {
          username: debauncedLoginValue,
        },
      })
    }
  }, [debauncedLoginValue, mutate, userData])

  return (
    <S.Wrapper>
      <S.Title>{t('My profile')}</S.Title>
      <S.FieldWrapper>
        <Input
          name="login"
          onChange={handleLoginInputChange}
          placeholder={t('Create a login')}
          type="text"
          value={loginValue ? loginValue : userData?.username || loginValue}
        />
        {updatedUserData?.username && (
          <S.SuccessLabel>{t('Username saved')}!</S.SuccessLabel>
        )}
      </S.FieldWrapper>
    </S.Wrapper>
  )
}
