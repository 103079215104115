import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PageContent = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    align-items: start;
    gap: 26px;
  
    ${media(size.mobile)} {
      flex-direction: column;
    }
  `
)

export const ButtonWrapper = styled.div(
  ({ theme: { media, size, color } }) => `
    display: flex;
    align-items: center;
    gap: 10px;
    background-color:${color.white};
    border-radius: 12px;
    border: 1px solid ${color.line};
    padding: 20px;
    margin-top: -25px;

  ${media(size.tablet)} {
    margin-top: -15px;
  }
  `
)

export const ActionText = styled.p`
  max-width: 131px;
  font-size: 13px;
  font-weight: 500;
  line-height: 130.769%;
  color: ${({ theme }) => theme.color.gray};
  margin: 0;
`

export const Button = styled(UIButton)`
  height: 44px;
`

export const Sort = styled.div(
  ({ theme: { media, size, color } }) => `
    width: fit-content;
    display: flex;
    background-color:${color.white};
    border-radius: 12px;
    border: 1px solid ${color.line};
    padding: 24px;

  ${media(size.mobile)} {
    width: 100%;
  }
  `
)

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const SortText = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 133.333%;
  margin: 0;
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ContentWrapper = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

export const ItemsWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: grid;
    grid-template-columns: repeat(3, 267px);
    gap: 25px;

    ${media(size.tablet)} {
      grid-template-columns: repeat(auto-fit, minmax(267px, 1fr));
    }
  `
)

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const Label = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const SortsWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  ${media(size.mobile)} {
    margin: 0;
  }
`
)

export const ResetButton = styled(UIButton)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.black};
  opacity: 0.5;
`
