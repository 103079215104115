import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const ErrorTextMock = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
`

export const Message = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-size: 16px;
  font-wight: 500;
`

export const Title = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.color.red};
  font-size: 18px;
  font-weight: 700;
`

export const Button = styled(UIButton)`
  margin-top: 10px;
`
