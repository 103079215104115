import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractTransaction } from 'api/omni/model'
import { InfoBlock } from '../InfoBlock/InfoBlock'
import * as S from './style'

type TransactionsProps = {
  transactions: ContractTransaction[]
}

export const Transactions: FC<TransactionsProps> = (props) => {
  const { transactions } = props

  const { t } = useTranslation('pageMyLoans')

  return (
    <S.Wrapper>
      <S.Title>{t('Last Transactions')}</S.Title>
      <div>
        {transactions.map(({ name, txHash }) => (
          <InfoBlock
            key={name}
            amount="1000"
            isTransaction
            title={name!}
            wallet={txHash}
          />
        ))}
      </div>
    </S.Wrapper>
  )
}
