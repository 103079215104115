import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AddressChip } from 'ui/AddressChip/AddressChip'
import { SvgJunaToken } from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import * as S from './style'

type InfoBlockProps = {
  amount?: number
  title: string
  category?: string
  address: string
  description: string
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
  const { amount, category, title, address, description } = props

  const { t } = useTranslation('pageAd')

  return (
    <S.Wrapper>
      <S.AdHeader>
        <S.Title>{title}</S.Title>
        <AddressChip address={address} />
      </S.AdHeader>

      <S.DescriptionWrapper>
        <S.Label isBold>{t('Description')}</S.Label>
        <S.Label>{description}</S.Label>
      </S.DescriptionWrapper>

      <S.BottomBlock>
        {amount && (
          <S.BottomItemWrapper>
            <S.Label isBold>{t('Amount')}</S.Label>
            <S.AmountWrapper>
              <SvgJunaToken />
              <S.Label>{formatNumberWithSeparators(amount)}</S.Label>
            </S.AmountWrapper>
          </S.BottomItemWrapper>
        )}
        {category && (
          <S.BottomItemWrapper>
            <S.Label isBold>{t('Category')}</S.Label>
            <S.Label>{category}</S.Label>
          </S.BottomItemWrapper>
        )}
      </S.BottomBlock>
    </S.Wrapper>
  )
}
