import styled from 'styled-components'
import { FormInput } from 'features/FormFileds'

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`

export const AmountWrapper = styled.div`
  display: flex;
  gap: 24px;
`

export const ShortenedInput = styled(FormInput)`
  max-width: 387px;
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 46px;
`

export const CountInUsdLabel = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
