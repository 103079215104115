import { FC, useState, useCallback, useMemo } from 'react'
import { utils } from 'ethers'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import {
  useNetworkTxSendFrCreate,
  usePlatformContractRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { useMatchMedia, useSignTypedData } from 'hooks'
import { getForwarderAddress } from 'libs/addresses/addresses'
import {
  buildRequest,
  EIP712Domain,
  Types,
} from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Stepper } from 'ui/Stepper/Stepper'
import { TransactionForm } from './components'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

const SUBMIT_TX_SEELCTOR = '0xc6427474'

export const TransactionCreate: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(1)

  const { isMobile } = useMatchMedia()

  const { t } = useTranslation('pageTransactionCreate')

  const { chain } = useNetwork()

  const { address } = useAccount()

  const { getNonce, signTypedData } = useSignTypedData(
    getForwarderAddress(chain?.id)
  )

  const { contractId } = useParams()

  const {
    data: contractData,
    isLoading: isContractLoading,
    isSuccess,
  } = usePlatformContractRetrieve(Number(contractId), {
    query: {
      enabled: !!contractId,
    },
  })

  const { mutate } = useNetworkTxSendFrCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Transaction has been sended'),
          title: t('Transaction has been sended'),
        })
      },
    },
  })

  const steps = useMemo(
    () => [
      {
        label: t('Create Transaction'),
        step: 1,
      },
      {
        label: t('Select action'),
        step: 2,
      },
      {
        label: t('Accept and sign'),
        step: 3,
      },
    ],
    [t]
  )

  const initialValues: FormValues = {
    contractAddress: '',
    action: '',
  }

  const breadcrumbs = useMemo(() => {
    if (contractData) {
      const initialBreadcrumbs = [
        {
          label: 'Home',
          url: AppRoutes.Main,
        },
        {
          label: 'My Contracts',
          url: AppRoutes.Contracts,
        },
        {
          label: contractData.name!,
          url: `${AppRoutes.Contracts}/${contractId}`,
        },
        {
          label: `${t('Create Transaction')} - Step ${activeStep}`,
          url: AppRoutes.SimpleV3,
        },
      ]

      return initialBreadcrumbs
    }
  }, [activeStep, contractData, contractId, t])

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values: FormValues) => {
      const { action, contractAddress } = values

      if (chain?.id === contractData!.chainId) {
        const coder = new utils.AbiCoder()
        const fnData = action
        const txData = coder
          .encode(['address', 'uint', 'bytes'], [contractAddress, 0, fnData])
          .slice(2)

        const input = {
          data: SUBMIT_TX_SEELCTOR + txData,
          to: contractData!.address,
        }

        const nonce = await getNonce()
        const request = buildRequest(nonce, input, address)
        const signed = await signTypedData({
          value: request,
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: Types,
        })

        mutate({
          data: {
            blockchain: chain.id,
            data: request,
            dataSig: signed,
          },
        })

        return
      }
    },
    [contractData, address, chain?.id, getNonce, signTypedData, mutate]
  )

  const handleFormBtnClick = useCallback(() => {
    switch (activeStep) {
      case 1:
        setActiveStep(2)
        break
      case 2:
        setActiveStep(3)
        break
    }
  }, [activeStep])

  if (isContractLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <PageWrapper
        breadcrumbs={breadcrumbs || []}
        title={t('Create Transaction')}
      >
        {activeStep === 1 && (
          <S.Label>
            {t('This tool will allow you to simply create a transaction')}.
          </S.Label>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnMount={true}
          validationSchema={getValidationSchema()}
        >
          <S.Wrapper>
            <Stepper
              activeStep={activeStep}
              steps={steps}
              vertical={isMobile}
            />
            <TransactionForm
              activeStep={activeStep}
              onClick={handleFormBtnClick}
            />
          </S.Wrapper>
        </Formik>
      </PageWrapper>
    )
  }

  return null
}
