import { FC } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  usePlatformLegalgatecategoryList,
  usePlatformJurisdictionList,
} from 'api/omni/endpoints'
import { Lg } from 'api/omni/model'
import { FormInput, FormTextarea, FormSelect } from 'features/FormFileds'
import { FORM_ID } from 'pages/LegalGateAuth/constants'
import { FormValues } from 'pages/LegalGateAuth/types'
import * as S from './style'
import { getValidationSchema } from './validationSchema'

type LegalGateFormProps = {
  isSignUpMode: boolean
  handleSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<any>
  legalGate: Lg[]
}

const NETWORKS = [
  { label: 'BSC Testnet', value: '97' },
  { label: 'Ethereum Goerli', value: '5' },
]

export const LegalGateForm: FC<LegalGateFormProps> = (props) => {
  const { isSignUpMode, handleSubmit, legalGate } = props

  const { t } = useTranslation('pageLegalGateAuth')

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = usePlatformLegalgatecategoryList()

  const {
    data: jurisdictions,
    isLoading: isJurisdictionLoading,
    isSuccess: isJurisdictionSuccess,
  } = usePlatformJurisdictionList()

  const getInitialValies = () => {
    const initialValues: FormValues = {
      name: '',
      address: '',
      description: '',
      amountJUNA: 0,
      country: '',
      category: '',
      network: '',
    }

    if (legalGate.length > 0) {
      const {
        name,
        contracts,
        description,
        price,
        jurisdictions: legalGateJurisdictions,
        categories: legalGateCategories,
      } = legalGate[0]

      const currentInitialValues: FormValues = {
        name,
        address: contracts[0].address,
        description: description!,
        amountJUNA: price!,
        country: legalGateJurisdictions[0].id.toString(),
        category: legalGateCategories[0].id.toString(),
        network: contracts[0].chainId.toString(),
      }

      return currentInitialValues
    }

    return initialValues
  }

  if (isCategoriesSuccess && isJurisdictionSuccess) {
    return (
      <Formik
        enableReinitialize
        initialValues={getInitialValies()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <S.Form id={FORM_ID} onSubmit={handleSubmit}>
            <FormInput
              editorProps={{ disabled: !isSignUpMode }}
              name="name"
              placeholder={t('Name')}
            />
            <FormInput
              editorProps={{ disabled: !isSignUpMode }}
              name="address"
              placeholder={t('Address')}
            />
            <FormTextarea
              editorProps={{ disabled: !isSignUpMode }}
              name="description"
              placeholder={t('Description')}
            />
            <FormSelect
              editorProps={{
                isDisabled: !isSignUpMode,
                options: NETWORKS,
                placeholder: t('Select Network'),
                onChange: (option) => setFieldValue('network', option?.value),
                getOptionLabel: (option) => option.label,
                getOptionValue: (option) => `${option.value}`,
                value: NETWORKS.find(
                  (network) => network.value === values.network
                ),
              }}
              name="network"
            />

            <FormSelect
              editorProps={{
                isDisabled: !isSignUpMode,
                isLoading: isCategoriesLoading,
                options: categories,
                placeholder: t('Select Category'),
                onChange: (option) => setFieldValue('category', option?.id),
                getOptionLabel: (option) => option.name,
                getOptionValue: (option) => `${option.id}`,
                value: categories.find(
                  (category) => category.id === Number(values.category)
                ),
              }}
              name="category"
            />
            <S.AmountWrapper>
              <S.ShortenedInput
                editorProps={{ disabled: !isSignUpMode }}
                name="amountJUNA"
                placeholder={t('Contract amount in JUNA tokens')}
              />
              <S.FlexWrapper>
                <S.CountInUsdLabel>{t('In USD')}</S.CountInUsdLabel>
                <S.CountInUsdLabel isBold>
                  {Number(values.amountJUNA) * 1}$
                </S.CountInUsdLabel>
              </S.FlexWrapper>
            </S.AmountWrapper>
            <FormSelect
              editorProps={{
                isDisabled: !isSignUpMode,
                isLoading: isJurisdictionLoading,
                options: jurisdictions,
                placeholder: t('Select country'),
                onChange: (option) =>
                  setFieldValue('country', option?.id.toString()),
                getOptionLabel: (option) => option.name,
                getOptionValue: (option) => `${option.id}`,
                value: jurisdictions.find(
                  (jurisdiction) => jurisdiction.id === Number(values.country)
                ),
              }}
              name="country"
            />
          </S.Form>
        )}
      </Formik>
    )
  }

  return null
}
