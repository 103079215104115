import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Input } from 'ui/Input/Input'

export const Wrapper = styled.div(
  ({ theme: { media, size, color } }) => `
      margin: 24px 0px;
      border-radius: 12px;
      background: ${color.white};
      width: 100%;

      ${media(size.mobile)} {
        margin: 24px 0 0;
      }
`
)

export const Content = styled.div`
  padding: 24px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  margin-bottom: 5px;
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray};
`
export const StyledInput = styled(Input)`
  input {
    margin: 20px 0px 24px 0px;
  }
`

export const FieldsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;
`

export const SwitchButton = styled(Button)`
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
export const BtnsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const DetailsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
`
