import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgTelegram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1425_15922)">
      <path
        d="M6.278 10.1217L6.01333 13.8444C6.392 13.8444 6.556 13.6817 6.75266 13.4864L8.528 11.7897L12.2067 14.4837C12.8813 14.8597 13.3567 14.6617 13.5387 13.863L15.9533 2.54838L15.954 2.54771C16.168 1.55038 15.5933 1.16038 14.936 1.40504L0.742663 6.83904C-0.226003 7.21504 -0.211337 7.75504 0.577996 7.99971L4.20666 9.12838L12.6353 3.85438C13.032 3.59171 13.3927 3.73704 13.096 3.99971L6.278 10.1217Z"
        fill="#8388AA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1425_15922">
        <rect
          fill="white"
          height={16}
          transform="translate(0 0.00109863)"
          width={16}
        />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgTelegram)
export default Memo
