import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type DescriptionBlockProps = {
  description: string
}

export const DescriptionBlock: FC<DescriptionBlockProps> = (props) => {
  const { description } = props

  const { t } = useTranslation('pageProject')

  return (
    <S.Wrapper>
      <S.Title>{t('Description')}</S.Title>
      <S.DescriptionText>{description}</S.DescriptionText>
    </S.Wrapper>
  )
}
