export const votingParticipantsMock = [
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
  {
    participantName: 'John Doe',
    address: '0x05Bd7d681856b62402b21ee0b13825143628ae49',
  },
]
