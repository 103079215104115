import { configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { supportedChains } from 'constants/blockchain'

export const configuredChains = configureChains(supportedChains, [
  jsonRpcProvider({
    rpc: (chain) => {
      return { http: chain.rpcUrls.default.http[0] }
    },
  }),
])
