import { FC } from 'react'
import { ActionButton } from 'api/omni/model'
import { ConstructorButtons } from 'libs/constructor'

type PaymentAtomInputsProps = {
  id: string
  label: string
  buttons: ActionButton[]
  atomName: string
  inputStatus: string
}

export const PaymentAtomInputs: FC<PaymentAtomInputsProps> = (props) => {
  const { id, label, buttons, atomName, inputStatus } = props

  return (
    <ConstructorButtons
      atomId={id}
      atomName={atomName}
      buttons={buttons}
      label={label}
      state={inputStatus}
    />
  )
}
