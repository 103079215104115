import { FCWithChildren } from 'types/app'
import * as S from './style'
import { Variant } from './types'

export type ChipProps = {
  variant: Variant
  className?: string
}

export const Chip: FCWithChildren<ChipProps> = (props) => {
  const { children, variant, className } = props

  return (
    <S.Wrapper className={className} variant={variant}>
      {children}
    </S.Wrapper>
  )
}
