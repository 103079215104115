import { FC, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type FilePickerProps = {
  accepts?: string
  isPicked: boolean
  onChange: (file: File) => void
  fileName?: string | null
  fileTypeLabel: string
  description?: string
  notSelectedText?: string
}

export const FilePicker: FC<FilePickerProps> = (props) => {
  const {
    isPicked,
    onChange,
    fileName,
    fileTypeLabel,
    description,
    accepts,
    notSelectedText,
  } = props

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const { t } = useTranslation('uiFilePicker')

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files === null) {
      return
    }

    onChange(evt.target.files[0])
  }

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }

  const notSelectedLabel =
    notSelectedText || t('Not selected', { fileTypeLabel })

  return (
    <S.Wrapper>
      <S.LabelsWrapper>
        <S.Label>{isPicked ? fileName : notSelectedLabel}</S.Label>
        {description && <S.Label isDescription>{description}</S.Label>}
      </S.LabelsWrapper>

      <S.Button onClick={handleClick} variant="red">
        {isPicked
          ? t('Change', { fileTypeLabel })
          : t('Upload', { fileTypeLabel })}
      </S.Button>
      <S.HiddenFileInput
        ref={hiddenFileInput}
        accept={accepts + '/*'}
        onChange={handleChange}
        type="file"
      />
    </S.Wrapper>
  )
}
