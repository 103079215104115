import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { type SearchFiltersType } from 'pages/Contracts/types'
import * as S from './style'

type FiltersProps = {
  onClick: (filter: keyof SearchFiltersType) => void
  searchFilters: SearchFiltersType
}

const filters = [
  {
    label: 'Status',
    name: 'status',
    value: '',
  },
  {
    label: 'Member',
    name: 'member',
    value: '',
  },
  {
    label: 'Legal gate',
    name: 'legalGate',
    value: '',
  },
]

export const Filters: FC<FiltersProps> = (props) => {
  const { onClick, searchFilters } = props

  const { t } = useTranslation('pageContracts')

  return (
    <S.Wrapper>
      <S.Label>{t('Filters')}: </S.Label>
      {/* TODO: refacotring */}
      {filters.map((filter) => {
        const currentActiveFilter = Object.entries(searchFilters).find(
          ([filterKey, filterValue]) => filterKey === filter.name && filterValue
        )

        return (
          <S.Button
            key={filter.name}
            onClick={() => onClick(filter.name as keyof SearchFiltersType)}
            selected={Boolean(currentActiveFilter)}
          >
            {filter.label}
          </S.Button>
        )
      })}
    </S.Wrapper>
  )
}
