import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 24px;
`

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 32px 0;

    ${media(size.tablet)} {
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
    }
`
)

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ActionsWrapper = styled.div(
  ({ theme: { media, size } }) => `
      display: flex;
      align-items: center;
      gap: 16px;

      ${media(size.mobile)} {
        flex-direction: column;
        max-width: 100%;
        width: 100%;

        button {
          width: 100%;
        }
      }
`
)
