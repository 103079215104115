import { FC, useCallback } from 'react'
import { usePin, useUserData } from 'hooks'
import { PinReset, PinSetupForm, EmailSentMessage } from './components'

export const Pin: FC = () => {
  const { pinSet } = useUserData()

  const {
    resendConfirmationEmail,
    resendConfirmationEmailStatus,
    sessionId,
    setupPin,
    setupPinStatus,
    setupPinReset,
    setupPinResetStatus,
  } = usePin()

  const handlePinSetup = useCallback(
    (values: { email: string; pin: string }) => {
      setupPin(values)
    },
    [setupPin]
  )

  const handleEmailResend = useCallback(() => {
    if (!sessionId) {
      throw new Error('Resend email failed. SessionId does not exists')
    }
    resendConfirmationEmail({ data: { session: sessionId } })
  }, [resendConfirmationEmail, sessionId])

  if (setupPinStatus === 'success' || setupPinResetStatus === 'success') {
    return (
      <EmailSentMessage
        isLoading={resendConfirmationEmailStatus === 'loading'}
        onResend={handleEmailResend}
      />
    )
  }

  if (!pinSet) {
    return (
      <PinSetupForm
        isLoading={setupPinStatus === 'loading'}
        onSubmit={handlePinSetup}
      />
    )
  }

  return (
    <PinReset
      isLoading={setupPinResetStatus === 'loading'}
      onReset={setupPinReset}
    />
  )
}
