import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const Button = styled(UIButton)(
  ({ theme: { media, size } }) => `
  width: max-content;
  margin-left: auto;

  ${media(size.tablet)} {
    width: 100%;
    margin: 0;
  }
`
)
