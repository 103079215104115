import { ChangeEvent, FC, useCallback, useState, useMemo } from 'react'
import { ethers } from 'ethers'
import { useContract, useNetwork, useSigner } from 'wagmi'
import { useAtomAtomActionContractProcessCreate } from 'api/omni/endpoints'
import ERC20 from 'constants/abi/ERC20.json'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { Chip } from 'ui/Chip/Chip'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import * as S from './style'

type DepositBlockProps = {
  status: string
  interestedRate: number
  amount: number
  daysLeft: number
  issuerAddress: string
  atomId: string
}

const DEFAULT_DAYS_PER_YEAR = 365

const calculateInterestValue = (
  interestRate: number,
  amount: number,
  days: number
) => {
  return (1 + (days / DEFAULT_DAYS_PER_YEAR) * (interestRate / 100)) * amount
}

export const DepositBlock: FC<DepositBlockProps> = (props) => {
  const { status, interestedRate, amount, daysLeft, issuerAddress, atomId } =
    props

  const [transferAmountValue, setTransferAmountValue] = useState<string>('')

  const { chain } = useNetwork()

  const { data: signer } = useSigner()

  const contract = useContract({
    address: getJunaTokenAddress(chain?.id),
    abi: ERC20,
    signerOrProvider: signer,
  })

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const handleTransferInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setTransferAmountValue(evt.target.value)
    },
    []
  )

  const earningAmount = useMemo(() => {
    return calculateInterestValue(interestedRate, amount, 7)
  }, [amount, interestedRate])

  const currentButtonText = useMemo(() => {
    switch (status) {
      case 'waiting_deposit':
        return 'Transfer'
      case 'in_progress':
        return daysLeft > 0
          ? `${daysLeft} days before withdrawal`
          : 'Request to withdraw deposit'
      case 'claim_requested':
        return 'Deposit withdrawal requested. Wait please'
      case 'claimed':
        return 'Transfer to customer'
    }
  }, [daysLeft, status])

  const isButtonDisabled = useMemo(() => {
    return (
      (status === 'in_progress' && daysLeft > 0) || status === 'claim_requested'
    )
  }, [daysLeft, status])

  const handleBtnClick = useCallback(async () => {
    switch (status) {
      case 'waiting_deposit':
      case 'claimed':
        const parsedAmount = ethers.utils.parseEther(
          transferAmountValue.toString()
        )

        let tx

        try {
          tx = await contract?.transfer(issuerAddress, parsedAmount)
        } catch {
          notify.error({
            title: 'Something went wrong',
            message: 'Something went wrong',
          })
        }

        callAction({
          id: atomId,
          data: {
            action:
              status === 'waiting_deposit'
                ? 'call_transfer_juna'
                : 'call_return_juna',
            inputs: [
              {
                name: 'tx_hash',
                value: tx.hash,
              },
              {
                name: 'to',
                value: issuerAddress,
              },
              {
                name: 'amount',
                value: transferAmountValue,
              },
            ],
          },
        })
        break
      case 'in_progress':
        callAction({
          id: atomId,
          data: {
            action: 'call_request_claim',
            inputs: [],
          },
        })
    }
  }, [atomId, callAction, contract, issuerAddress, status, transferAmountValue])

  return (
    <S.Wrapper>
      <S.HeaderBlock>
        <S.ColWrapper>
          <S.Title>
            {status === 'waiting_deposit' ? 'You should deposit' : 'Deposited'}
          </S.Title>
          <S.AmountWrapper>
            <SvgJunaToken />
            <S.AmountLabel>{formatNumberWithSeparators(amount)}</S.AmountLabel>
          </S.AmountWrapper>
        </S.ColWrapper>
        {status !== 'waiting_deposit' && (
          <S.ColWrapper>
            <S.Title>Earning</S.Title>
            <Chip variant="gradient">
              {formatNumberWithSeparators(earningAmount)} JUNA
            </Chip>
          </S.ColWrapper>
        )}
      </S.HeaderBlock>

      {(status === 'waiting_deposit' || status === 'claimed') && (
        <S.FieldWrapper>
          <S.FieldLabel>Enter Juna amount to transfer deposit</S.FieldLabel>
          <Input
            onChange={handleTransferInputChange}
            placeholder="Amount"
            value={transferAmountValue}
          />
        </S.FieldWrapper>
      )}
      {status !== 'finished' && (
        <Button
          disabled={isButtonDisabled}
          onClick={handleBtnClick}
          variant="red"
        >
          {currentButtonText}
        </Button>
      )}
    </S.Wrapper>
  )
}
