import { FC, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Lg } from 'api/omni/model'
import { ContactsModalContext } from 'context'
import { ChooseLegalGate } from 'features/ChooseLegalGate/ChooseLegalGate'
import { FormInput, FormTextarea } from 'features/FormFileds'
import { FormValues } from 'pages/JunaSimple/types'
import { FORM_ID } from 'pages/JunaSimpleV2/constants'
import { SvgContacts, SvgPlus } from 'ui/icons'
import * as S from './style'

type JunaSimpleFormTypes = {
  selectLegalGate: (idx: number, legalGate: Lg) => void
  legalGates: {
    data: {
      name: string
      context: string
    }[]
    count: number
  }
  selectFile: (file: File) => void
}

export const JunaSimpleForm: FC<JunaSimpleFormTypes> = (props) => {
  const { selectLegalGate, legalGates } = props

  const { t } = useTranslation('pageJunaSimple')

  const { values, handleSubmit, setFieldValue } = useFormikContext<FormValues>()

  const { openContactModal } = useContext(ContactsModalContext)

  return (
    <S.Form id={FORM_ID} onSubmit={handleSubmit}>
      <S.MembersWrapper>
        <S.MemberWrapper>
          <FormInput
            editorProps={{
              buttons: [
                {
                  icon: values.partnerAddress ? <SvgPlus /> : <SvgContacts />,
                  onClick: () =>
                    openContactModal({
                      onAddressSelect: (address) =>
                        setFieldValue('partnerAddress', address),
                      displayMode: values.partnerAddress
                        ? 'addContact'
                        : 'selectContact',
                      contactInputValue: values.partnerAddress,
                    }),
                  hoverText: values.partnerAddress
                    ? t('Add to contacts')
                    : t('Select contact'),
                },
              ],
            }}
            name="partnerAddress"
            placeholder={t('Partner address')}
          />
        </S.MemberWrapper>
      </S.MembersWrapper>
      <FormTextarea
        name="agreementText"
        placeholder={t('Type text of agreement')}
      />
      <S.Line />
      <S.AmountInputWrapper>
        <S.ShortenedFormInput
          editorProps={{
            type: 'number',
          }}
          name="amountJUNA"
          placeholder={t('Contract amount in JUNA tokens')}
        />

        <S.FlexWrapper>
          <S.CountInUsdLabel>{t('In USD')}:</S.CountInUsdLabel>
          <S.CountInUsdLabel isBold>
            {values.amountJUNA ? Number(values.amountJUNA) * 1 : '0'}$
          </S.CountInUsdLabel>
        </S.FlexWrapper>
      </S.AmountInputWrapper>
      <S.Line />
      {legalGates.count > 0 &&
        legalGates.data.map((legalGate, idx) => (
          <ChooseLegalGate
            key={legalGate.name}
            legalGateForm={legalGate}
            legalGateIndex={idx}
            onLegalGateSelect={selectLegalGate}
          />
        ))}
    </S.Form>
  )
}
