import { useCallback, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { DEFAULT_CHAIN, Chains as supportedChains } from 'constants/blockchain'

export const useDefaultChain = (): void => {
  const { isConnected } = useAccount()
  const { chain } = useNetwork()
  const { switchNetworkAsync } = useSwitchNetwork()

  const suggestDefaultChain = useCallback(async () => {
    switchNetworkAsync?.(DEFAULT_CHAIN)
  }, [switchNetworkAsync])

  useEffect(() => {
    if (isConnected && chain && !supportedChains[chain.id]) {
      suggestDefaultChain()
    }
  }, [suggestDefaultChain, isConnected, chain])
}
