import { Chip, ChipProps } from 'ui/Chip/Chip'
import * as S from './style'

type SignaturesStatusType = {
  signatures: {
    name: string
    wallet: string
    status: 'expected' | 'refused' | 'signed'
  }[]
}

const statusToChipVariant: Record<
  'expected' | 'refused' | 'signed',
  ChipProps['variant']
> = {
  expected: 'info',
  refused: 'danger',
  signed: 'success',
}

export const SignaturesStatus = (props: SignaturesStatusType) => {
  const { signatures } = props

  return (
    <S.StyledCard>
      <S.Wrapper>
        {signatures.map(({ name, wallet, status }) => (
          <S.SignatureBlock key={wallet}>
            <S.NameLabel>{name}</S.NameLabel>
            <S.WalletLabel>{wallet}</S.WalletLabel>
            <Chip variant={statusToChipVariant[status]}>{status}</Chip>
          </S.SignatureBlock>
        ))}
      </S.Wrapper>
    </S.StyledCard>
  )
}
