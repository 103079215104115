import { FC, useState, useMemo, useCallback } from 'react'
import { useAtomAtomActionContractProcessCreate } from 'api/omni/endpoints'
import { ActionButton } from 'api/omni/model'
import { useConstructorContext } from 'libs/constructor/hooks'
import { notify } from 'libs/notify/notify'
import { ConstructorAddressInput } from '../ConstructorAddressInput/ConstructorAddressInput'
import { ConstructorInput } from '../ConstructorInput/ConstructorInput'
import { ConstructorSelectListProps } from '../ConstructorSelectList/ConstructorSelectList'
import * as S from './style'

type ConstructorButtonsProps = {
  label: string
  atomId: string
  buttons: ActionButton[]
  atomName: string
  state: string
}

export const ConstructorButtons: FC<ConstructorButtonsProps> = (props) => {
  const { label, atomId, buttons, atomName, state } = props

  const { currentContract, setFieldValue, getValue, addMember, setAtomValue } =
    useConstructorContext()

  const getInitialCurrentButtonInfo = () => {
    const currentSectedButtonValue = getValue({
      atomId,
      name: atomName,
    })

    const currentButtonInfo = buttons.find(
      (button) => button.name === currentSectedButtonValue
    )

    if (currentButtonInfo) {
      return currentButtonInfo
    }

    return null
  }

  const { mutate: callAction } = useAtomAtomActionContractProcessCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success',
          message: 'The request was successfully sent',
        })
      },
    },
  })

  const [currentButtonInfo, setCurrentButtonInfo] =
    useState<ActionButton | null>(getInitialCurrentButtonInfo())

  const [currentSelectListInfo, setCurrentSelectListInfo] =
    useState<ConstructorSelectListProps | null>()

  const handleSelectListItem = useCallback(
    (name: string, value: string) => {
      setFieldValue({
        atomId,
        inputName: atomName,
        name,
        value,
      })

      setCurrentSelectListInfo(null)
    },
    [atomId, atomName, setFieldValue]
  )

  const currentInputsContent = useMemo(() => {
    if (!currentButtonInfo) {
      return null
    }

    if (!currentButtonInfo.inputs) {
      return null
    }

    switch (currentButtonInfo.action) {
      case 'RENDER_INPUTS':
        return currentButtonInfo.inputs.map((input) => {
          //TODO: дождаться обновления правки типа со стороны бека и убрать any
          const { label, actionElements, type, name, buttons } = input as any

          const getCurrentActionElements = (type: 'EVENT' | 'ADDRESS') => {
            if (state === 'READONLY' || !actionElements) {
              return []
            }

            return actionElements.map(
              //TODO: поправить в соответствии с комментом выше
              (actionElement: any) => {
                const onActionElementClick = () => {
                  if (currentSelectListInfo) {
                    setCurrentSelectListInfo(null)

                    return
                  }

                  if (!currentContract.members) {
                    return
                  }

                  const transformedMemberOptions = currentContract.members.map(
                    (member) => {
                      return {
                        name: member.address,
                        label: member.address,
                      }
                    }
                  )

                  setCurrentSelectListInfo({
                    options:
                      type === 'EVENT'
                        ? currentContract.events
                        : transformedMemberOptions,
                    selectaleItemName: type.toLowerCase(),
                    onChange: (value) => handleSelectListItem(name, value),
                  })
                }

                return {
                  ...actionElement,
                  onClick: onActionElementClick,
                }
              }
            )
          }

          switch (type) {
            case 'EVENT':
              const getEventInputValue = () => {
                const eventValueInInput = getValue({
                  atomId,
                  inputName: atomName,
                  name,
                })

                const currentEvent = currentContract.events.find(
                  (event) => event.name === eventValueInInput
                )

                return currentEvent?.label || ''
              }

              return (
                <S.InputsWrapper key={name}>
                  <ConstructorInput
                    actionElements={getCurrentActionElements('EVENT')}
                    disabled={state === 'READONLY'}
                    placeholder={label}
                    value={getEventInputValue()}
                  />
                  {currentSelectListInfo && (
                    <S.SelectList {...currentSelectListInfo} />
                  )}
                </S.InputsWrapper>
              )
            case 'ADDRESS':
              const getMemberInputValue = () => {
                const memberValueInInput = getValue({
                  atomId,
                  inputName: atomName,
                  name,
                })

                if (typeof memberValueInInput === 'string') {
                  return memberValueInInput
                }

                return ''
              }

              return (
                <S.InputsWrapper key={name}>
                  <ConstructorAddressInput
                    actionElements={getCurrentActionElements('ADDRESS')}
                    atomId={atomId}
                    atomName={atomName}
                    disabled={state === 'READONLY'}
                    inputName={name}
                    isMember
                    placeholder={label}
                    value={getMemberInputValue()}
                  />
                  {currentSelectListInfo && (
                    <S.SelectList {...currentSelectListInfo} />
                  )}
                </S.InputsWrapper>
              )
            case 'EMPTY':
              const handleClick = (actionName: string) => {
                const currentAtom = currentContract.atoms.find(
                  ({ id }) => atomId === id
                )

                if (!currentAtom) {
                  return
                }

                callAction({
                  id: atomId,
                  data: {
                    inputs: currentAtom.inputs,
                    action: actionName,
                  },
                })
              }

              return (
                <S.ButtonsWrapper>
                  {buttons?.map((button: ActionButton) => {
                    return (
                      <S.ActionButton
                        key={button.label}
                        onClick={() => handleClick(button.action)}
                      >
                        {button.label}
                      </S.ActionButton>
                    )
                  })}
                </S.ButtonsWrapper>
              )
            default:
              return null
          }
        })
    }
  }, [
    atomId,
    atomName,
    callAction,
    currentButtonInfo,
    currentContract,
    currentSelectListInfo,
    getValue,
    handleSelectListItem,
    state,
  ])

  const handleButtonClick = useCallback(
    (buttonInfo: ActionButton) => {
      if (state === 'READONLY') {
        return
      }

      const isHaveMemberInput = buttonInfo.inputs?.some(
        (input: any) => input.isMember
      )

      if (isHaveMemberInput) {
        addMember({
          atomId,
          address: '',
          type: 'member',
        })
      }

      setFieldValue({
        atomId,
        name: atomName,
        value: buttonInfo.name,
      })

      setAtomValue({
        atomId,
        inputName: atomName,
        name: 'inputs',
        value: [],
      })

      setCurrentButtonInfo(buttonInfo)

      setCurrentSelectListInfo(null)
    },
    [addMember, atomId, atomName, setAtomValue, setFieldValue, state]
  )

  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.ButtonsWrapper>
        {buttons.map((button) => (
          <S.ActionButton
            key={button.label}
            disabled={state === 'READONLY'}
            onClick={() => handleButtonClick(button)}
            selected={button.name === currentButtonInfo?.name}
          >
            {button.label}
          </S.ActionButton>
        ))}
      </S.ButtonsWrapper>
      {currentInputsContent}
    </S.Wrapper>
  )
}
