import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
        display: flex;
        justify-content: space-between;
  
        ${media(size.tablet)} {
          justify-content: center;
          flex-direction: column;
          gap: 24px;
        }
  `
)

export const LeftSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
        max-width: 655px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
  
        ${media(size.tablet)} {
          max-width: 100%;
        }
  
        ${media(size.mobile)} {
          gap: 24px;
        }
  `
)

export const RightSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
        display: flex;
        flex-direction: column;
        max-width: 461px;
        width: 100%;
        gap: 24px;
  
        ${media(size.tablet)} {
          max-width: 100%;
          margin-bottom: 32px;
        }
  `
)
