import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router'
import { AuthStepper } from 'features/AuthStepper/AuthStepper'
import { useAuthStatus } from 'hooks'
import * as S from './style'

type LocationProps = {
  state: {
    from: Location
  }
}

export const Auth: FC = () => {
  const { authStatus } = useAuthStatus()

  const location = useLocation() as LocationProps

  const { t } = useTranslation('pageAuth')

  if (authStatus === 'authorized') {
    const { pathname, search } = location.state.from

    return (
      <Navigate
        to={{
          pathname,
          search,
        }}
      />
    )
  }

  return (
    <S.Wrapper>
      <S.AuthBlockWrapper>
        <S.Header>
          <S.Title>{t('Authorization')}</S.Title>
        </S.Header>
        <S.Line />
        <S.StepperWrapper>
          <AuthStepper authStatus={authStatus} />
        </S.StepperWrapper>
        <S.Line />
      </S.AuthBlockWrapper>
    </S.Wrapper>
  )
}
