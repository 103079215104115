import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type PropductProps = {
  products: {
    id: number
    src: string
  }[]
}

export const Products: FC<PropductProps> = ({ products }) => {
  const { t } = useTranslation('pageHome')

  return (
    <S.Wrapper>
      <S.Title>{t('Our other products')}</S.Title>
      <S.ProductsWrapper>
        {products.map(({ id, src }) => (
          <img key={id} alt="#" src={src} />
        ))}
      </S.ProductsWrapper>
    </S.Wrapper>
  )
}
