import { FC } from 'react'
import { NavLink } from '../../types'
import { MenuLink } from './components/MenuLink/MenuLink'
import { Submenu } from './components/Submenu/Submenu'
import * as S from './style'

type DesktopMenuProps = {
  navLinks: NavLink[]
}

export const DesktopMenu: FC<DesktopMenuProps> = (props) => {
  const { navLinks } = props

  return (
    <S.Nav>
      {navLinks.map((navLink) => {
        if (navLink.hidden) {
          return null
        }
        switch (navLink.role) {
          case 'link': {
            return (
              <MenuLink key={navLink.label} to={navLink.to}>
                {navLink.label}
              </MenuLink>
            )
          }
          case 'submenu': {
            return <Submenu key={navLink.label} navLink={navLink} />
          }
          default: {
            throw new Error()
          }
        }
      })}
    </S.Nav>
  )
}
