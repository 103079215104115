import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  useAtomAtomActionContractCallRetrieve,
  useFinanceContractRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { LeftSideInfo, ContractInfo, DepositBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Bank Dashboard',
    url: AppRoutes.BankDashboard,
  },
  {
    label: 'Deposit',
    url: AppRoutes.Deposit,
  },
]

export const BankViewDepositContract: FC = () => {
  const { depositId } = useParams()

  const {
    data: deposit,
    isLoading: isDepositLoadig,
    isSuccess: isDepositLoded,
  } = useFinanceContractRetrieve(depositId!, {
    query: {
      enabled: Boolean(depositId),
    },
  })

  const {
    data: depositStatusAction,
    isLoading: isDepositStatusLoading,
    isSuccess: isDepositStatusLoaded,
  } = useAtomAtomActionContractCallRetrieve(
    deposit?.atomContract.atoms[0].id!,
    {
      action: 'deposit_status',
    },
    {
      query: {
        enabled: !!deposit,
      },
    }
  )

  const depositStatus = useMemo(() => {
    if (!deposit || !depositStatusAction?.data) {
      return
    }

    const depositAtomContractStatus = deposit.atomContract.status

    return depositAtomContractStatus !== 'execution'
      ? depositAtomContractStatus
      : (depositStatusAction.data.output as string)
  }, [deposit, depositStatusAction])

  if (isDepositLoadig || isDepositStatusLoading) {
    return <Loader />
  }

  if (isDepositLoded && isDepositStatusLoaded && depositStatus) {
    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={
          depositStatus !== 'on_approval'
            ? 'Incoming deposit request'
            : 'Withdrawal: Transfer to client'
        }
      >
        <S.Wrapper>
          {depositStatus === 'on_approval' ? (
            <LeftSideInfo
              amount={deposit.amount}
              chain={deposit.atomContract.chainId}
              contractId={deposit.atomContract.id}
              interestRate={deposit.interestedRate}
              issuerAddress={deposit.atomContract.initiatorAddress}
            />
          ) : (
            <DepositBlock
              amount={deposit.amount}
              atomId={deposit.atomContract.atoms[0].id}
              depositStatus={depositStatus}
              interestedRate={deposit.interestedRate}
              issuerAddress={deposit.atomContract.initiatorAddress}
            />
          )}

          {depositStatus === 'on_approval' ? (
            <ContractInfo
              contractId={deposit.atomContract.id}
              createdAt={deposit.createdAt}
              finishedAt={deposit.finishedAt}
              status={depositStatus}
            />
          ) : (
            <ContractInfo
              amount={deposit.amount}
              chain={deposit.atomContract.chainId}
              contractId={deposit.atomContract.id}
              createdAt={deposit.createdAt}
              finishedAt={deposit.finishedAt}
              interestedRate={deposit.interestedRate}
              status={depositStatus}
            />
          )}
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
