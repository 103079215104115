import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  gap: 20px;
  justify-content: space-between;

  ${media(size.mobile)} {
    flex-direction: column;
  }
`
)

export const Image = styled.img<{ isMock?: boolean }>`
  border-radius: 12px;
  max-height: 363px;
  object-fit: ${({ isMock }) => (isMock ? 'scale-down' : 'cover')};
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.black};
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  transition: 0.3s;
  width: max-content;

  &:hover {
    opacity: 0.6;
  }
`

export const Button = styled(UIButton)`
  width: max-content;
`

export const ColumnWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;

  ${media(size.mobile)} {
    width: 100%;
  }
`
)
