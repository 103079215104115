import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { AppRoutes, SettingsPageInnerRoutes } from 'constants/app'
import { useUserData } from 'hooks'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Settings',
    url: AppRoutes.Settings,
  },
]

export const Settings: FC = () => {
  const { t } = useTranslation('pageSettings')

  const { userDataLoadStatus } = useUserData()

  const navLinks = useMemo(
    () => [
      {
        label: t('My profile'),
        to: `${AppRoutes.Settings}/${SettingsPageInnerRoutes.Profile}`,
      },
      {
        label: t('My contacts'),
        to: `${AppRoutes.Settings}/${SettingsPageInnerRoutes.Contacts}`,
      },
      {
        label: t('PIN code'),
        to: `${AppRoutes.Settings}/${SettingsPageInnerRoutes.Pin}`,
      },
    ],
    [t]
  )

  if (userDataLoadStatus === 'loading') {
    return <Loader />
  }

  if (userDataLoadStatus === 'success') {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Settings')}>
        <S.Wrapper>
          <S.Ul>
            <S.MenuWrapper>
              {navLinks.map(({ label, to }) => (
                <S.NavLink key={to} to={to}>
                  {({ isActive }) => <S.Li isActive={isActive}>{label}</S.Li>}
                </S.NavLink>
              ))}
            </S.MenuWrapper>
          </S.Ul>
          <S.CardsWrapper>
            <Outlet />
          </S.CardsWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
