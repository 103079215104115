import styled from 'styled-components'

export const CommentWrapper = styled.div`
  margin-bottom: 12px;
`

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
`

export const CommentFlexWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`

export const Content = styled.div`
  flex: 1;
`

export const AutorAddress = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`

export const StartedAt = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.color.gray};
`

export const Comment = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.black};
`

export const ReplyButton = styled.div`
  color: ${({ theme }) => theme.color.gray};
  font-size: 12px;
  margin-top: 12px;
  cursor: pointer;
`
