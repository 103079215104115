import { FC, useCallback, useMemo } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import {
  usePlatformAdsRetrieve,
  useAtomConstructCreate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { generateUUID } from 'libs/constructor/context/utils/generateContractUUID'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { JunaSimpleForm, ContractDetails } from './components'
import { MUTATION_CONTRACT_CREATE_KEY } from './constants'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

const SIMPLE_ATOM_ACTION_ID = '389b027c-8bc7-4f5c-bd4c-d8098f0aeaca'
const LEGAL_GATE_ATOM_ACTION_ID = '304364d3-3b73-478e-bdf1-67d028fea9ac'

export const JunaSimpleV5: FC = () => {
  const { chain } = useNetwork()

  const navigate = useNavigate()

  const { address } = useAccount()

  const [searchParams] = useSearchParams()
  const adId = searchParams.get('adId')

  const { data: ad, isLoading: isAdLoading } = usePlatformAdsRetrieve(
    Number(adId),
    {
      query: {
        enabled: !!adId,
      },
    }
  )

  const { t } = useTranslation('pageJunaSimpleV3')

  const { mutate: constructContract } = useAtomConstructCreate({
    mutation: {
      onSuccess: ({ rootContractId }) => {
        navigate(`${AppRoutes.AtomContract}/${rootContractId}`)
      },
      mutationKey: MUTATION_CONTRACT_CREATE_KEY,
    },
  })

  const getInitialValues = () => {
    const initialValues: FormValues = {
      contractName: 'Simple contract',
      partnerAddress: '',
      description: '',
      amountJUNA: '',
      legalGateAddress: '',
    }

    if (ad) {
      initialValues.contractName = ad.name
      initialValues.partnerAddress = ad.address
      initialValues.description = ad.description
    }

    return initialValues
  }

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: `Juna Simple`,
        url: AppRoutes.SimpleV5,
      },
    ]

    if (ad) {
      return [
        {
          label: 'Home',
          url: AppRoutes.Main,
        },
        {
          label: 'Marketplace',
          url: AppRoutes.Marketplace,
        },
        {
          label: ad.name,
          url: `${AppRoutes.Marketplace}/${ad.id}`,
        },
        {
          label: 'Juna Simple v3',
          url: AppRoutes.SimpleV3,
        },
      ]
    }

    return initialBreadcrumbs
  }, [ad])

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values: FormValues) => {
      if (address && chain) {
        const reqAtomContractBody = {
          id: generateUUID(),
          name: values.contractName,
          chainId: chain.id,
          parentId: null,
          members: [
            {
              address,
              type: 'member',
              status: 'pending',
              sig: '',
            },
            {
              address: values.partnerAddress,
              type: 'member',
              status: 'pending',
              sig: '',
            },
            values.legalGateAddress.length > 0 && {
              address: values.legalGateAddress,
              type: 'legalgate',
              status: 'pending',
              sig: '',
            },
          ],
          variables: [],
          events: [],
          atoms: [
            {
              id: generateUUID(),
              atomActionId: SIMPLE_ATOM_ACTION_ID,
              inputs: [
                {
                  name: 'member1',
                  value: address,
                },
                {
                  name: 'member2',
                  value: values.partnerAddress,
                },
                {
                  name: 'conditions',
                  value: values.description,
                },
                {
                  name: 'amount',
                  value: values.amountJUNA,
                },
              ],
            },
            values.legalGateAddress.length > 0 && {
              id: generateUUID(),
              atomActionId: LEGAL_GATE_ATOM_ACTION_ID,
              inputs: [
                {
                  name: 'textfield',
                  value: '',
                },
                {
                  name: 'memberfield',
                  value: values.legalGateAddress,
                },
              ],
            },
          ],
          type: 'contract',
        }

        constructContract({
          data: {
            //TODO: Поправить после правок на беке
            contracts: [reqAtomContractBody as any],
          },
        })
      }
    },
    [address, chain, constructContract]
  )

  if (isAdLoading) {
    return <Loader />
  }

  return (
    <PageWrapper breadcrumbs={breadcrumbs} title={t('Juna Simple')}>
      <S.Label>
        {t(
          'Juna Simple is a short contract between two parties, which will pay the agreed amount automatically once the contract obligations are completed'
        )}
        .
      </S.Label>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        <S.Wrapper>
          <S.LeftSideWrapper>
            <JunaSimpleForm />
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <ContractDetails />
          </S.RightSideWrapper>
        </S.Wrapper>
      </Formik>
    </PageWrapper>
  )
}
