import { FC, useCallback } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePlatformProjectCreate } from 'api/omni/endpoints'
import { ProjectCreateRequest } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { FormInput, FormTextarea } from 'features/FormFileds'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { FilePicker } from 'ui/FilePicker/FilePicker'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import * as S from './style'
import { getValidationSchema } from './validationSchema'

const NOT_STARTED_STATUS = 'not_started'

const FORM_ID = 'add-project-form'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Launchpad',
    url: AppRoutes.Launchpad,
  },
  {
    label: 'Add a project',
    url: AppRoutes.ProjectAdd,
  },
]

type FormValues = Omit<ProjectCreateRequest, 'status'>

export const AddProject: FC = () => {
  const [searchParams] = useSearchParams()

  const { t } = useTranslation('pageAddProject')

  const tenderId = searchParams.get('tenderId')

  const navigate = useNavigate()

  const initialValues: FormValues = {
    name: '',
    description: '',
    amountJuna: 0,
    logo: undefined,
  }

  const { mutate: addProject, isLoading: isProjectAdding } =
    usePlatformProjectCreate({
      mutation: {
        onSuccess: () => {
          notify.success({
            message: t('Project successfully added'),
            title: t('Project added'),
          })
          navigate(AppRoutes.Launchpad)
        },
      },
    })

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    (values) =>
      addProject({
        data: {
          ...values,
          status: NOT_STARTED_STATUS,
          request: Number(tenderId),
        },
      }),
    [addProject, tenderId]
  )

  return (
    <PageWrapper
      actions={
        <Button
          form={FORM_ID}
          isLoading={isProjectAdding}
          type="submit"
          variant="red"
        >
          {t('Add project')}
        </Button>
      }
      breadcrumbs={breadcrumbs}
      title={t('Add project')}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <S.Form id={FORM_ID} onSubmit={handleSubmit}>
            <FormInput name="name" placeholder={t('Enter project name')} />
            <FormTextarea
              name="description"
              placeholder={t('Enter project description')}
            />
            <FilePicker
              //TODO: поправить компонент file picker
              fileName={values.logo && (values.logo as File).name}
              fileTypeLabel={t('Logo')}
              isPicked={values.logo !== undefined}
              onChange={(file) => {
                setFieldValue('logo', file)
              }}
            />
            {errors.logo && (
              <S.ErrorMessageText>{errors.logo}</S.ErrorMessageText>
            )}
            <S.AmountInputWrapper>
              <S.ShortenedInput
                editorProps={{
                  type: 'number',
                }}
                name="amountJUNA"
                placeholder={t('Amount in JUNA tokens')}
              />
              <S.FlexWrapper>
                <S.CountInUsdLabel>{t('In USD')}:</S.CountInUsdLabel>
                <S.CountInUsdLabel isBold>
                  {values.amountJuna}$
                </S.CountInUsdLabel>
              </S.FlexWrapper>
            </S.AmountInputWrapper>
          </S.Form>
        )}
      </Formik>
    </PageWrapper>
  )
}
