import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { Chains } from 'constants/blockchain'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type TableItemProps = {
  createdAt: string
  finishedAt: string
  amount: number
  address: string
  interestRate: number
  status: AtomAtomContractListStatus
  id: string
  chainId: number
}

type BankTableProps = {
  tableTitle: string
  tableData: TableItemProps[]
  hasStatus?: boolean
  onClick: (id: string) => () => void
}

export const BankTable: FC<BankTableProps> = (props) => {
  const { tableData, hasStatus, onClick } = props

  const { t } = useTranslation('pageBankDashboardDeposits')

  const tableHeaderData = useMemo(
    () => [
      t('ID'),
      t('Chain Type'),
      t('Date of approval'),
      t('Wallet'),
      t('Amount'),
      t('Loan term'),
      t('Interest Rate'),
      t('Status'),
    ],
    [t]
  )

  const ContractStatusesDictionary: Record<AtomAtomContractListStatus, string> =
    useMemo(
      () => ({
        draft: t('Draft'),
        on_approval: t('On approval'),
        on_deployment: t('On deployment'),
        execution: t('Execution'),
        finished: t('Completed'),
      }),
      [t]
    )

  return (
    <S.TableCard>
      <S.TableWrapper>
        <S.TableContent>
          <thead>
            <tr>
              {tableHeaderData.map((data, idx) =>
                data === 'Status' && !hasStatus ? null : (
                  <S.TableHeader key={idx}>{data}</S.TableHeader>
                )
              )}
            </tr>
          </thead>

          <S.TableBody>
            {tableData.map((data, idx) => (
              <S.TableRow key={idx} onClick={onClick(data.id)}>
                <S.TableData>{shortenAddress(data.id)}</S.TableData>
                <S.TableData>{Chains[data.chainId].name}</S.TableData>
                <S.TableData>
                  {dayjs(data.createdAt).format('DD.MM.YYYY')}
                </S.TableData>
                <S.TableData>
                  <S.TableDataAddress>
                    {shortenAddress(data.address)}
                  </S.TableDataAddress>
                </S.TableData>
                <S.TableData>
                  {formatNumberWithSeparators(data.amount)} JUNA
                </S.TableData>
                <S.TableData>
                  {dayjs(data.finishedAt).diff(dayjs(data.createdAt), 'day')}{' '}
                  {t('days')}
                </S.TableData>
                <S.TableData>{data.interestRate}% AER</S.TableData>
                {hasStatus && (
                  <S.ChipWrapper>
                    <S.Chip
                      variant={
                        data.status === 'on_approval'
                          ? 'info'
                          : data.status === 'execution'
                          ? 'success'
                          : 'gray'
                      }
                    >
                      {ContractStatusesDictionary[data.status]}
                    </S.Chip>
                  </S.ChipWrapper>
                )}
              </S.TableRow>
            ))}
          </S.TableBody>
        </S.TableContent>
        {!tableData.length && (
          <S.NotFoundItem>{t('No deposits')}</S.NotFoundItem>
        )}
      </S.TableWrapper>
    </S.TableCard>
  )
}
