import styled from 'styled-components'
import { SvgBackArrrow } from 'ui/icons'

export const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const BackBtn = styled(SvgBackArrrow)`
  cursor: pointer;
`
