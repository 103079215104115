import { FC } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { usePlatformContactCreate } from 'api/omni/endpoints'
import { ContactCreate } from 'api/omni/model'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type AddContactProps = {
  contactInputValue: string
  onClose: () => void
  onAddressSelect: (address: string) => void
}

export const AddContact: FC<AddContactProps> = (props) => {
  const { contactInputValue, onClose, onAddressSelect } = props

  const { t } = useTranslation('modalContactsModal')

  const initialValues = {
    name: '',
    address: contactInputValue,
  }

  const { mutate: addContact } = usePlatformContactCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: t('Contact added'),
          title: t('Contact added'),
        })
      },
    },
  })

  const handleSubmit = (values: ContactCreate) => {
    addContact({ data: values })
    onAddressSelect(values.address)
    onClose()
  }

  return (
    <S.Wrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, handleChange }) => (
          <S.Form onSubmit={handleSubmit}>
            <Input
              name="name"
              onChange={handleChange}
              placeholder={t('Enter contact name')}
              type="text"
              value={values.name}
            />
            <Input
              name="address"
              onChange={handleChange}
              placeholder={t('Enter wallet address')}
              type="text"
              value={values.address}
            />
            <Button type="submit" variant="red">
              {t('Save')}
            </Button>
          </S.Form>
        )}
      </Formik>
    </S.Wrapper>
  )
}
