import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const ContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContentCard = styled(Card)`
  width: 163px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  background-color: #edf5fd;
  padding: 28px 20px;
  box-shadow: none;
`

export const ContentCardTitleWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
`

export const ContentCardTitle = styled.h4`
  color: ${({ theme }) => theme.color.black};
  font-size: 20px;
  font-weight: 800;
  margin: 0;
`
export const ContentCardSubtitle = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
`

export const ContentCardText = styled.p`
  max-width: 92px;
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`

export const ButtonsBlocksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReserveSystemBlock = styled(Card)`
  width: max-content;
  padding: 14px 18px;
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
  margin-bottom: 24px;
`

export const ReserveLabel = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.color.gray};
`

export const ReserveButton = styled(Button)`
  border-radius: 12px;
  padding: 8px 16px;
`

export const VerticalLine = styled.div`
  width: 1px;
  height: 35px;
  background-color: ${({ theme }) => theme.color.line};
`

export const ProgressBlocksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
`
