import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-direction: column;
  padding-bottom: 40px;
`

export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  color: ${({ theme }) => theme.color.gray};
`

export const Button = styled(UIButton)`
  padding: 13px 20px;
  border-radius: 38px;
`
