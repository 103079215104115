import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useAccountIsNeedActionRetrieve } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import {
  SvgActionRequired,
  SvgContractsFast,
  SvgItemFast,
  SvgJunaSimpleFast,
  SvgAllGood,
} from 'ui/icons'
import { Overlay } from 'ui/Overlay/Overlay'
import { Spinner } from 'ui/Spinner/Spinner'
import { ActionButton } from './components/ActionButton/ActionButton'
import * as S from './style'

export const FastActions: FC = () => {
  const navigate = useNavigate()

  const handleClickJunaSimple = useCallback(() => {
    navigate(AppRoutes.SimpleV5)
  }, [navigate])

  const handleClickContracts = useCallback(() => {
    navigate(AppRoutes.Catalog)
  }, [navigate])

  const handleClickMarketplace = useCallback(() => {
    navigate(AppRoutes.Marketplace)
  }, [navigate])

  const handleClickStatus = useCallback(() => {
    // TODO: добавить обработчик негативного статуса
  }, [])

  const {
    data: isAccountNeedsAction,
    isLoading: isAccountNeedsActionChecking,
  } = useAccountIsNeedActionRetrieve()

  return (
    <S.Container>
      <S.Wrapper>
        <Overlay isLoading={isAccountNeedsActionChecking} loader={<Spinner />}>
          {isAccountNeedsAction?.needAction ? (
            <ActionButton
              hint=""
              icon={<SvgActionRequired />}
              onClick={handleClickStatus}
              subtitle="Attention!"
              title="Action required"
            />
          ) : (
            <ActionButton
              hint="All contracts work automatically. You are not required to take any action now"
              icon={<SvgAllGood />}
              onClick={handleClickStatus}
              subtitle="No action is required"
              title="It's all good"
            />
          )}
        </Overlay>

        <ActionButton
          hint="The service is designed to provide one-time transactions between two participants with the ability to involve an arbitrator. Safe deals via smart contracts"
          icon={<SvgJunaSimpleFast />}
          onClick={handleClickJunaSimple}
          subtitle="Create"
          title="Juna Simple"
        />
        <ActionButton
          hint="Find templates categorised by industry and explore detailed descriptions, logic, parameters, and program codes."
          icon={<SvgContractsFast />}
          onClick={handleClickContracts}
          subtitle="Find"
          title="Contracts"
        />
        <ActionButton
          hint="Juna's OmniMarket is the central trading platform for our community"
          icon={<SvgItemFast />}
          onClick={handleClickMarketplace}
          subtitle="List on marketplace"
          title="Trade"
        />
      </S.Wrapper>
    </S.Container>
  )
}
