import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import {
  accountPinSetInitCreate,
  accountPinResetInitCreate,
  useAccountPinSetResendCreate,
} from 'api/omni/endpoints'
import { AppRoutes, SettingsPageInnerRoutes } from 'constants/app'
import { notify } from 'libs/notify/notify'
import {
  selectApprovePinStatus,
  selectPinChecked,
  selectPinChecking,
  selectPinCheckFailed,
} from 'store/pin/pin.selectors'
import { approvePin, checkPin, uncheckPin } from 'store/pin/pin.slice'
import { useDispatch } from '../useDispatch/useDispatch'
import { useSelector } from '../useSelector/useSelector'
import { useUserData } from '../useUserData/useUserData'

const getCallbackUrl = (pinActionType: 'create' | 'reset') => {
  const { origin } = window.location
  const path = `${AppRoutes.Settings}/${SettingsPageInnerRoutes.PinApproveLanding}`
  const params = `/${pinActionType}/{session}/{code}`
  return origin + path + params
}

// todo: more job needed on this hook, maybe all/some methods should be moved to store
export const usePin = () => {
  // todo: remove unused translations from json file
  const { t } = useTranslation('pageSettings')

  const dispatch = useDispatch()

  const approvePinStatus = useSelector(selectApprovePinStatus)

  const pinCheckFailed = useSelector(selectPinCheckFailed)

  const pinChecked = useSelector(selectPinChecked)

  const pinChecking = useSelector(selectPinChecking)

  const { pinSet } = useUserData()

  const pinNeedsCheck = pinSet && !pinChecked

  const [sessionId, setSessionId] = useState<string | null>(null)

  const {
    mutate: resendConfirmationEmail,
    status: resendConfirmationEmailStatus,
  } = useAccountPinSetResendCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: t('PIN successfully resended'),
          message: t('PIN was resended'),
        })
      },
    },
  })

  const { mutate: setupPin, status: setupPinStatus } = useMutation({
    mutationFn: (data: { email: string; pin: string }) => {
      const { email, pin } = data
      return accountPinSetInitCreate({
        email,
        pin,
        callbackUrlTempl: getCallbackUrl('create'),
      })
    },
    onSuccess: (data) => {
      setSessionId(data.session)
    },
  })

  const { mutate: setupPinReset, status: setupPinResetStatus } = useMutation({
    mutationFn: () => {
      return accountPinResetInitCreate({
        callbackUrlTempl: getCallbackUrl('reset'),
      })
    },
    onSuccess: (data) => {
      setSessionId(data.session)
    },
  })

  return {
    approvePin: (params: {
      approveType: 'create' | 'reset'
      code: string
      session: string
    }) => dispatch(approvePin(params)),
    approvePinStatus,
    checkPin: (pin: string) => dispatch(checkPin(pin)),
    pinCheckFailed,
    pinChecking,
    pinNeedsCheck,
    resendConfirmationEmail,
    resendConfirmationEmailStatus,
    sessionId,
    setupPin,
    setupPinStatus,
    setupPinReset,
    setupPinResetStatus,
    uncheckPin: () => dispatch(uncheckPin()),
  }
}
