import styled from 'styled-components'
import { Card as CardComponent } from 'ui/Card/Card'
import { Textarea as UITextArea } from 'ui/Textarea/Textarea'

export const Card = styled(CardComponent)`
  width: 100%;
  padding: 24px;
`

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;

  ${media(size.mobile)} {
    flex-direction: column;
  }
`
)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.black};
`

export const Description = styled.div`
  min-width: 655px;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Textarea = styled(UITextArea)`
  margin-bottom: 24px;
`

export const InfoCardWrapper = styled.div`
  width: 100%;
`

export const InfoCard = styled(Card)`
  margin-bottom: 24px;
`

export const Adresses = styled.div`
  display: flex;
  gap: 74px;
  margin-bottom: 24px;
`

export const Jutisdictions = styled(Adresses)`
  margin-bottom: 12px;
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const DashedLine = styled.div`
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.color.line};
`

export const Price = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
`

export const UserAddress = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray};
`

export const PageTitleWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray};
`
