import { FC } from 'react'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { Button } from 'ui/Button/Button'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type ContractInfoProps = {
  address: string
  chain: number
  balance: string
  createdAt: string
}

export const ContractInfo: FC<ContractInfoProps> = (props) => {
  const { address, chain, balance, createdAt } = props

  const navigate = useNavigate()

  const { contractId } = useParams()

  return (
    <S.Wrapper>
      <S.Title>Contract Information</S.Title>

      <S.InfoList>
        {address && (
          <S.InfoListItem>
            <S.ListLabel>Address</S.ListLabel>
            <S.ListLine />
            <S.ListLabel isBold>{shortenAddress(address)}</S.ListLabel>
          </S.InfoListItem>
        )}

        <S.InfoListItem>
          <S.ListLabel>Chain</S.ListLabel>
          <S.ListLine />
          <S.ListLabel isBold>{Chains[chain].name}</S.ListLabel>
        </S.InfoListItem>

        <S.InfoListItem>
          <S.ListLabel>Balance</S.ListLabel>
          <S.ListLine />
          <S.ListLabel isBold>{balance} JUNA</S.ListLabel>
        </S.InfoListItem>

        <S.InfoListItem>
          <S.ListLabel>Created At</S.ListLabel>
          <S.ListLine />
          <S.ListLabel isBold>{dayjs(createdAt).toString()}</S.ListLabel>
        </S.InfoListItem>
      </S.InfoList>

      <Button
        onClick={() => navigate(`${AppRoutes.AtomContract}/${contractId}`)}
      >
        View contract
      </Button>
    </S.Wrapper>
  )
}
