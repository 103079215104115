import { FC, MouseEventHandler, useCallback } from 'react'
import { useClipboard } from 'hooks'
import { notify } from 'libs/notify/notify'
import { SvgWallet } from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type AddressChipProps = {
  address: string
}

export const AddressChip: FC<AddressChipProps> = (props) => {
  const { address } = props

  const copyToClipboard = useClipboard()

  const handleCopyAddressClick = useCallback<MouseEventHandler<HTMLElement>>(
    (evt) => {
      evt.stopPropagation()
      copyToClipboard(address, () => {
        notify.success({
          title: 'Success',
          message: 'Address copied to clipboard',
        })
      })
    },
    [copyToClipboard, address]
  )

  return (
    <S.Wrapper onClick={handleCopyAddressClick}>
      <SvgWallet />
      <S.Label>{shortenAddress(address)}</S.Label>
    </S.Wrapper>
  )
}
