import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ProposalParams } from 'api/omni/model'
import * as S from './style'

type ResultsHeaderProps = {
  votingParams: ProposalParams
}

export const ResultsHeader: FC<ResultsHeaderProps> = (props) => {
  const { votingParams } = props

  const { t } = useTranslation('pageVotingResults')

  return (
    <S.Wrapper>
      <S.LeftSideWrapper>
        <S.DateWrapper>
          <S.Label>{t('Date')}</S.Label>
        </S.DateWrapper>
        <S.VotesWrapper>
          <S.Label>{t('Votes')}</S.Label>
        </S.VotesWrapper>
      </S.LeftSideWrapper>
      <S.ParamSymbolsWrapper>
        {Object.keys(votingParams).map((paramSymbol) => (
          <S.Chip key={paramSymbol} variant="gray">
            {paramSymbol.toUpperCase()}
          </S.Chip>
        ))}
      </S.ParamSymbolsWrapper>
      <S.ResultWrapper>
        <S.Label>{t('Result')}</S.Label>
      </S.ResultWrapper>
    </S.Wrapper>
  )
}
