import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FORM_ID } from 'pages/LegalGateAuth/constants'
import { Button } from 'ui/Button/Button'
import * as S from './style'

const ADDING_LEGAL_GATE_AMOUNT = 200

export const SummaryBlock: FC = () => {
  const { t } = useTranslation('pageLegalGateAuth')

  return (
    <S.Card>
      <S.Title>{t('Summary')}</S.Title>
      <S.SummaryDetailWrapper>
        <S.Label>{t('Adding Legal Gate')}</S.Label>
        <S.DashedLine />
        <S.Label isBold>{ADDING_LEGAL_GATE_AMOUNT} JUNA</S.Label>
      </S.SummaryDetailWrapper>
      <Button form={FORM_ID} type="submit" variant="red">
        {t('Register')}
      </Button>
    </S.Card>
  )
}
