import { forwardRef } from 'react'
import { BaseInputProps } from 'ui/BaseInput/BaseInput'

import * as S from './style'

export type ActionElementTypeEnum = 'BUTTON'

export type ActionElement = {
  type: string
  label: string
  onClick?: () => void
}

export type ConstructorInputProps = BaseInputProps & {
  actionElements?: ActionElement[]
  className?: string
  isRequired?: boolean
}

export const ConstructorInput = forwardRef<
  HTMLInputElement,
  ConstructorInputProps
>(({ actionElements, ...props }, ref) => {
  return (
    <S.InputContainer ref={ref} {...props}>
      <S.ActionElementsContainer>
        {actionElements?.map(({ label, onClick }: ActionElement, idx) => (
          <S.ActionElementButton key={idx} onClick={onClick}>
            {label}
          </S.ActionElementButton>
        ))}
      </S.ActionElementsContainer>
    </S.InputContainer>
  )
})
