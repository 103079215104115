import { FC } from 'react'
import { StepProps } from 'ui/Stepper/types'
import * as S from './style'

export const Step: FC<StepProps> = (props) => {
  const {
    label,
    step,
    isCurrent,
    isCompleted,
    vertical,
    onClick,
    title,
    withButtons,
  } = props

  const handleStepClick = () => {
    if (isCurrent && onClick) {
      onClick()

      return
    }
  }

  return (
    <S.StepContainer
      isCompleted={isCompleted}
      isCurrent={isCurrent}
      onClick={handleStepClick}
    >
      <S.StepContent
        isCurrent={isCurrent}
        vertical={vertical}
        withButtons={withButtons}
      >
        <S.StepNumber isCompleted={isCompleted} isCurrent={isCurrent}>
          {isCompleted ? <S.TickIcon /> : step}
        </S.StepNumber>
        <S.TitleWrapper>
          {title && <S.Title>{title}</S.Title>}
          {label}
        </S.TitleWrapper>
      </S.StepContent>
    </S.StepContainer>
  )
}
