import { useContext, useMemo } from 'react'
import { ConstructorContext } from 'libs/constructor'

export const useConstructorContext = () => {
  const constructorContext = useContext(ConstructorContext)

  return useMemo(
    () => ({
      ...constructorContext,
    }),
    [constructorContext]
  )
}
