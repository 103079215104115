import { useEffect } from 'react'
import { BigNumber } from 'ethers'
import { useContractRead } from 'wagmi'
import ERC20 from 'constants/abi/ERC20.json'

export const useTokenBalance = (
  token: string,
  address?: string,
  chainId?: number
): BigNumber => {
  const { data, refetch } = useContractRead({
    address: token,
    abi: ERC20,
    functionName: 'balanceOf',
    args: [address],
    enabled: false,
    chainId,
  })

  useEffect(() => {
    if (address) {
      refetch()
    }
  }, [address, refetch])

  if (!data) return BigNumber.from(0)

  return BigNumber.from(data)
}
