import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgLaunchTablet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={64}
    viewBox="0 0 64 64"
    width={64}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="url(#paint0_linear_5491_8702)" height={64} rx={16} width={64} />
    <mask
      height={44}
      id="mask0_5491_8702"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={43}
      x={12}
      y={10}
    >
      <rect
        fill="#D9D9D9"
        height={42.6667}
        width={42.6667}
        x={12}
        y={10.6666}
      />
    </mask>
    <g mask="url(#mask0_5491_8702)">
      <path
        d="M33.3333 49.7777C31.3778 49.7777 29.4889 49.474 27.6667 48.8666C25.8444 48.2592 24.1778 47.3777 22.6667 46.2222L25.2444 43.6444C26.4889 44.5036 27.7926 45.1481 29.1555 45.5777C30.5185 46.0074 31.9111 46.2222 33.3333 46.2222C37.2741 46.2222 40.6296 44.837 43.4 42.0666C46.1704 39.2962 47.5555 35.9407 47.5555 31.9999C47.5555 28.0592 46.1704 24.7036 43.4 21.9333C40.6296 19.1629 37.2741 17.7777 33.3333 17.7777C29.3926 17.7777 26.037 19.1629 23.2667 21.9333C20.4963 24.7036 19.1111 28.0592 19.1111 31.9999H15.5555C15.5555 29.5407 16.0222 27.2296 16.9555 25.0666C17.8889 22.9036 19.1555 21.0222 20.7555 19.4222C22.3555 17.8222 24.237 16.5555 26.4 15.6222C28.5629 14.6888 30.8741 14.2222 33.3333 14.2222C35.7926 14.2222 38.0963 14.6888 40.2444 15.6222C42.3926 16.5555 44.2741 17.8296 45.8889 19.4444C47.5037 21.0592 48.7778 22.9407 49.7111 25.0888C50.6444 27.237 51.1111 29.5407 51.1111 31.9999C51.1111 34.4296 50.6444 36.7259 49.7111 38.8888C48.7778 41.0518 47.5037 42.9407 45.8889 44.5555C44.2741 46.1703 42.3926 47.4444 40.2444 48.3777C38.0963 49.3111 35.7926 49.7777 33.3333 49.7777ZM19.0667 42.5333L26.3111 35.2888L31.6444 39.7333L40.4444 30.9333V35.5555H44V24.8888H33.3333V28.4444H37.9555L31.4667 34.9333L26.1333 30.4888L17.2 39.4222C17.5259 40.1036 17.8148 40.6592 18.0667 41.0888C18.3185 41.5185 18.6518 41.9999 19.0667 42.5333Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_5491_8702"
        x1={-0.00000347996}
        x2={107.314}
        y1={-27.6}
        y2={30.5487}
      >
        <stop stopColor="#728BFF" />
        <stop offset={0.34375} stopColor="#E557FF" />
        <stop offset={0.625} stopColor="#FF634D" />
        <stop offset={0.90625} stopColor="#FDEF73" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgLaunchTablet)
export default Memo
