import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useTheme } from 'styled-components'
import { Modal } from 'ui/Modal/Modal'
import * as S from './style'

const QR_SIZE = 302

type QrModalProps = {
  onClose: () => void
  qrValue: string
}

export const QrModal: FC<QrModalProps> = (props) => {
  const { onClose, qrValue } = props

  const { t } = useTranslation('modalQrModal')

  const theme = useTheme()

  return (
    <Modal onClose={onClose} title={`QR ${t('Code')}`}>
      <S.Wrapper>
        <S.Container>
          <QRCode
            bgColor={theme.color.lightGray}
            fgColor={theme.color.darkPurple}
            size={QR_SIZE}
            value={qrValue}
          />
        </S.Container>
      </S.Wrapper>
    </Modal>
  )
}
