import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
`

export const Title = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.black};
`

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InfoListItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const ListLabel = styled.span<{ isBold?: boolean }>`
  font-size: 15px;
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const ListLine = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.color.line};
`
