import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Lg } from 'api/omni/model'
import { useLegalGateBasket } from 'hooks'
import { DetailsItem } from 'ui/DetailsItem/DetailsItem'
import { SearchModal } from './components'
import * as S from './style'

type ChooseLegalGateProps = {
  legalGateForm: {
    name: string
    context: string
  }
  onLegalGateSelect: (idx: number, legalGate: Lg) => void
  legalGateIndex: number
}

export const ChooseLegalGate: FC<ChooseLegalGateProps> = (props) => {
  const { legalGateForm, onLegalGateSelect, legalGateIndex } = props

  const [legalGate, setLegalGate] = useState<Lg>()
  const { legalGate: legalGateBasket } = useLegalGateBasket()
  const [isSearchModalVisible, setSearchModalIsVisible] =
    useState<boolean>(false)

  const { t } = useTranslation('featureChooseLegalGate')

  const { name: legalGateName, context } = legalGateForm

  const handlePasteLegalGate = useCallback(() => {
    if (legalGateBasket) {
      setLegalGate(legalGateBasket)
      onLegalGateSelect(legalGateIndex, legalGateBasket)
    }
  }, [legalGateBasket, legalGateIndex, onLegalGateSelect])

  const handleCancelLegalGate = useCallback(() => {
    setLegalGate(undefined)
  }, [])

  const toggleSearchModal = useCallback(() => {
    setSearchModalIsVisible((prev) => !prev)
  }, [])

  const handleSelectLegalGate = useCallback(
    (selectedLegalGate: Lg) => {
      setLegalGate(selectedLegalGate)
      toggleSearchModal()
      onLegalGateSelect(legalGateIndex, selectedLegalGate)
    },
    [toggleSearchModal, legalGateIndex, onLegalGateSelect]
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          {legalGate ? legalGateName : `${t('Select')} ${legalGateName}`}
        </S.Title>
        <S.Text>{context}</S.Text>
        {legalGate ? (
          <>
            <S.DetailsItemWrapper>
              <DetailsItem
                details={[
                  {
                    label: t('Name'),
                    value: legalGate.name,
                  },
                  {
                    label: t('Price'),
                    value: `${legalGate.price} JUNA`,
                  },
                ]}
              />
            </S.DetailsItemWrapper>
            <S.SwitchButton onClick={handleCancelLegalGate}>
              {t('Delete')}
            </S.SwitchButton>
          </>
        ) : (
          <S.BtnsContainer>
            <S.SwitchButton onClick={toggleSearchModal}>
              {t('Search')}
            </S.SwitchButton>
            <S.SwitchButton
              disabled={!legalGateBasket}
              onClick={handlePasteLegalGate}
              variant="red"
            >
              {t('Paste selected Legal Gate')}
            </S.SwitchButton>
          </S.BtnsContainer>
        )}
      </S.Content>
      {!legalGate && isSearchModalVisible && (
        <SearchModal
          onClose={toggleSearchModal}
          onLegalGateSelect={handleSelectLegalGate}
        />
      )}
    </S.Wrapper>
  )
}
