import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from 'react-router-dom'
import { usePlatformLaunchpadRequestsRetrieve } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { DescriptionBlock, InfoBlock } from './components'
import * as S from './style'

export const Tender: FC = () => {
  const { t } = useTranslation('pageTender')

  const [searchParams] = useSearchParams()
  const tenderId = searchParams.get('tenderId')

  const navigate = useNavigate()

  const { data, isLoading, isSuccess } = usePlatformLaunchpadRequestsRetrieve(
    Number(tenderId)
  )

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Launchpad',
        url: AppRoutes.Launchpad,
      },
    ]

    if (data) {
      return [
        ...initialBreadcrumbs,
        {
          label: data.name,
          url: AppRoutes.Tender,
        },
      ]
    }

    return initialBreadcrumbs
  }, [data])

  const hanleApplyBtnClick = () => {
    navigate({
      pathname: AppRoutes.ProjectAdd,
      search: `${createSearchParams({
        tenderId: tenderId!,
      })}`,
    })
  }

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <PageWrapper
        actions={
          <Button onClick={hanleApplyBtnClick} variant="red">
            {t('Apply a proposal')}
          </Button>
        }
        breadcrumbs={breadcrumbs}
        title={data.name}
      >
        <S.Wrapper>
          <DescriptionBlock description={data.description} />
          <InfoBlock amount={1000} />
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
