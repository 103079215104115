import { ChangeEvent, FC, useCallback, useState } from 'react'
import { ethers } from 'ethers'
import { useParams } from 'react-router-dom'
import { useContract, useNetwork, useSigner } from 'wagmi'
import {
  useBanksBankClaimDepositFromRsCreate,
  useBanksBankCreditFromRsCreate,
  useBanksBankCreditReturnToRsCreate,
  useBanksBankDepositToRsCreate,
} from 'api/omni/endpoints'
import ERC20 from 'constants/abi/ERC20.json'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type ReserveFormProps = {
  type: 'deposit' | 'credit'
  initialAmount: number
  bankAddress: string
  refetchBankData: () => void
}

export const ReserveForm: FC<ReserveFormProps> = (props) => {
  const { type, initialAmount, bankAddress, refetchBankData } = props

  const [transferAmount, setTransferAmount] = useState<string>('')
  const [claimAmount, setClaimAmount] = useState<string>('')

  const { bankId } = useParams()

  const { data: signer } = useSigner()

  const { chain } = useNetwork()

  const contract = useContract({
    address: getJunaTokenAddress(chain?.id),
    abi: ERC20,
    signerOrProvider: signer,
  })

  const { mutate: depositToRS } = useBanksBankDepositToRsCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message:
            'You have successfully created a deposit in the reserve system',
          title: 'Success!',
        })
        refetchBankData()
      },
    },
  })

  const { mutate: claimDepositFromRS } = useBanksBankClaimDepositFromRsCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message: 'You have successfully claimed deposit from reserve system',
          title: 'Success!',
        })
        refetchBankData()
      },
    },
  })

  const { mutate: creditFromRS } = useBanksBankCreditFromRsCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message:
            'You have successfully taken out a loan from the reserve system',
          title: 'Success!',
        })
        refetchBankData()
      },
    },
  })

  const { mutate: creditReturnToRS } = useBanksBankCreditReturnToRsCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          message:
            'You have successfully repaid the loan to the reserve system',
          title: 'Success!',
        })
        refetchBankData()
      },
    },
  })

  const handleTransferSubmit = useCallback(async () => {
    if (!bankId) {
      return
    }

    const parsedAmount = ethers.utils.parseEther(transferAmount.toString())

    let tx

    try {
      tx = await contract?.transfer(bankAddress, parsedAmount)
    } catch {
      notify.error({
        title: 'Something went wrong',
        message: 'Something went wrong',
      })

      return
    }

    if (type === 'deposit') {
      depositToRS({
        id: bankId,
        data: {
          txHash: tx.hash,
          amount: Number(transferAmount),
        },
      })

      return
    }

    creditReturnToRS({
      id: bankId,
      data: {
        txHash: tx.hash,
        amount: Number(transferAmount),
      },
    })
  }, [
    bankAddress,
    bankId,
    contract,
    creditReturnToRS,
    depositToRS,
    transferAmount,
    type,
  ])

  const handleClaimSubmit = () => {
    if (!bankId) {
      return
    }

    if (type === 'deposit') {
      claimDepositFromRS({
        id: bankId,
        data: {
          amount: Number(claimAmount),
        },
      })

      return
    }

    creditFromRS({
      id: bankId,
      data: {
        amount: Number(transferAmount),
      },
    })
  }

  const handleInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue, name: inputName } = evt.target

      if (inputName === 'transfer') {
        setTransferAmount(inputValue)

        return
      }

      console.log(inputName, inputValue)

      setClaimAmount(inputValue)
    },
    []
  )

  return (
    <S.Wrapper>
      <S.Title>{type === 'deposit' ? 'Deposited' : 'Credited'}</S.Title>
      <S.AmountWrapper>
        <SvgJunaToken />
        <S.AmountLabel>{initialAmount}</S.AmountLabel>
      </S.AmountWrapper>
      <S.FieldWrapper>
        <S.FieldLabel>
          Enter Juna amount to{' '}
          {type === 'deposit' ? 'transfer deposit' : 'get credit'}
        </S.FieldLabel>
        <Input
          name={type === 'deposit' ? 'transfer' : 'claim'}
          onChange={handleInputChange}
          value={type === 'deposit' ? transferAmount : claimAmount}
        />
      </S.FieldWrapper>
      <Button
        onClick={type === 'deposit' ? handleTransferSubmit : handleClaimSubmit}
        variant="red"
      >
        {type === 'deposit' ? 'Deposit' : 'Credit'}
      </Button>
      <S.FieldWrapper>
        <S.FieldLabel>
          Enter Juna amount to{' '}
          {type === 'deposit' ? 'claim deposit' : 'return credit'}
        </S.FieldLabel>
        <Input
          name={type === 'deposit' ? 'claim' : 'transfer'}
          onChange={handleInputChange}
          value={type === 'deposit' ? claimAmount : transferAmount}
        />
      </S.FieldWrapper>
      <Button
        onClick={type === 'deposit' ? handleClaimSubmit : handleTransferSubmit}
        variant="red"
      >
        {type === 'deposit' ? 'Claim deposit' : 'Return credit'}
      </Button>
    </S.Wrapper>
  )
}
