import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  AtomAtomContractListStatus,
  ContractItemTypeEnum,
} from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { FieldsDataType } from 'pages/Contracts/mock'
import { SvgEdit, SvgDelete } from 'ui/icons'
import * as S from './style'

type ContractCardProps = {
  title: string
  fieldsData: FieldsDataType[]
  status: AtomAtomContractListStatus
  onRemove: (id: string) => void
  id: string
  isInitiator: boolean
  type: ContractItemTypeEnum
}

const statusesDictionary = {
  draft: 'Draft',
  on_approval: 'On approval',
  on_deployment: 'On deployment',
  execution: 'Execution',
  finished: 'Finished',
} as const

export const ContractCard = (props: ContractCardProps) => {
  const { title, fieldsData, status, id, onRemove, isInitiator, type } = props

  const navigate = useNavigate()

  const { t } = useTranslation('pageContracts')

  const handleOpenClick = () => {
    navigate(`${AppRoutes.AtomContract}/${id}`)
  }

  return (
    <S.StyledCard isTemplate={type === 'template'}>
      <S.FlexWrapper>
        <S.Title>{title}</S.Title>
        <S.StatusBlock>{statusesDictionary[status]}</S.StatusBlock>
      </S.FlexWrapper>
      <S.FieldsWrapper>
        {fieldsData.map(({ label, value, isRefusal, refusalName }) => {
          if (isRefusal) {
            return (
              <S.RefusalWrapper key={label}>
                <S.FieldWrapper>
                  <S.Label isRefusal={isRefusal}>{label}</S.Label>
                  <S.DashedLine />
                  <S.Label isRefusal={isRefusal}>{value}</S.Label>
                </S.FieldWrapper>
                <S.Label isRefusal={isRefusal}>
                  {t('Refusal')}: {refusalName}
                </S.Label>
              </S.RefusalWrapper>
            )
          }

          return (
            <S.FieldWrapper key={label}>
              <S.Label>{label}</S.Label>
              <S.DashedLine />
              <S.Label isBold>{value}</S.Label>
            </S.FieldWrapper>
          )
        })}
      </S.FieldsWrapper>
      <S.BtnsWrapper>
        <S.StyledBtn onClick={handleOpenClick} variant="red">
          {t('Open')}
        </S.StyledBtn>
        {status === 'draft' && isInitiator && (
          <S.StyledSmallBtn variant="gray">
            <SvgEdit />
          </S.StyledSmallBtn>
        )}
        {status === 'draft' && (
          <S.StyledSmallBtn onClick={() => onRemove(id)} variant="gray">
            <SvgDelete />
          </S.StyledSmallBtn>
        )}
      </S.BtnsWrapper>
    </S.StyledCard>
  )
}
