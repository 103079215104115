import dayjs from 'dayjs'
import { Proposal } from 'api/omni/model'
import { useToggle } from 'hooks/useToggle/useToggle'
import { SvgDownArrow } from 'ui/icons'
import { votingParticipantsMock } from '../../mockData'
import { Participants } from '../Participants/Participants'
import * as S from './styles'

type ResultItemProps = {
  result: Proposal
}

// todo: add i18n and move statuses to translation file
const statusDictionary: Record<string, string> = {
  in_progress: 'In Progress',
  finished: 'Finished',
} as const

export const ResultItem = (props: ResultItemProps) => {
  const [value, toggle] = useToggle(false)
  const { result } = props

  return (
    <>
      <S.Line />

      <S.RowWrapper onClick={toggle}>
        <S.LeftSideWrapper>
          <S.DateWrapper>
            <S.Text>{dayjs(result.endDate).format('DD.MM.YYYY')}</S.Text>
          </S.DateWrapper>
          <S.VotesWrapper>
            <S.Text>1000 / {result.votesCount}</S.Text>
          </S.VotesWrapper>
        </S.LeftSideWrapper>
        <S.ParamValuesWrapper>
          {result.params &&
            Object.entries(result.params).map(([objKey, value]) => (
              <S.Chip key={objKey} variant="gray">
                {value as string}
              </S.Chip>
            ))}
        </S.ParamValuesWrapper>
        <S.StatusWrapper>
          <S.ResultChip
            variant={result.status === 'in_progress' ? 'success' : 'danger'}
          >
            {statusDictionary[result.status]}
          </S.ResultChip>
          {value ? <S.SvgUpArrow /> : <SvgDownArrow />}
        </S.StatusWrapper>
      </S.RowWrapper>
      {value && <Participants participants={votingParticipantsMock} />}
    </>
  )
}
