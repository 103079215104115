import { FC } from 'react'
import * as S from './style'
import { TabType } from './types'

type TabsProps = {
  className?: string
  tabs: TabType[]
  onTabSelect: (tab: string) => void
  selectedTab: string
}

export const Tabs: FC<TabsProps> = (props) => {
  const { className, tabs, onTabSelect, selectedTab } = props

  const handleTabClick = (tab: string) => {
    onTabSelect(tab)
  }

  return (
    <S.Wrapper className={className}>
      {tabs.map(({ label, value, count, color }) => (
        <S.TabWrapper
          key={value}
          isSelected={selectedTab === value}
          onClick={() => handleTabClick(value)}
        >
          <S.Label>{label}</S.Label>
          {count !== undefined && count > 0 && (
            <S.CountBlock color={color}>{count}</S.CountBlock>
          )}
        </S.TabWrapper>
      ))}
    </S.Wrapper>
  )
}
