import { FC } from 'react'
import { useAccount } from 'wagmi'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type UserInfoProps = {
  onClick: () => void
  className?: string
}

export const UserInfo: FC<UserInfoProps> = (props) => {
  const { onClick, className } = props

  const { address } = useAccount()

  return (
    <S.Wrapper className={className}>
      <S.Account>{shortenAddress(address!)}</S.Account>
      <S.DisconnectButton onClick={onClick} />
    </S.Wrapper>
  )
}
