export const STORE_PIN_KEY_NAME = 'pin-checked'

export const storePinChecked = (): void => {
  localStorage.setItem(STORE_PIN_KEY_NAME, JSON.stringify(true))
}

export const getPinChecked = (): boolean => {
  return Boolean(localStorage.getItem(STORE_PIN_KEY_NAME))
}

export const dropPinChecked = (): void => {
  localStorage.removeItem(STORE_PIN_KEY_NAME)
}
