import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 24px;
    align-items: flex-start;

    ${media(size.tablet)} {
      flex-direction: column;
      justify-content:center;
    } 
`
)

export const MenuWrapper = styled(Card)(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 6px;
    flex-direction: column;
    min-width: 267px;
    padding: 12px;
    
    ${media(size.tablet)} {
      width:100%;
      flex-direction: row;
    }

    ${media(size.mobile)} {
      flex-direction: column;
    }
`
)

export const NotFoundText = styled.h5`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
`

export const StyledButton = styled(Button)`
  width: 100%;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`
