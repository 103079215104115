import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlatformContractList } from 'api/omni/endpoints'
import { useDebounce } from 'hooks'
import { Input } from 'ui/Input/Input'
import { Loader } from 'ui/Loader/Loader'
import { Modal } from 'ui/Modal/Modal'
import { Pagination } from 'ui/Pagination/Pagination'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

const ITEMS_ON_PAGE = 12

const CONTRACT_STATUS = 'execution'

type ContractModalProps = {
  onClose: () => void
  onContractAddressSelect: (address: string) => void
}

export const ContractsModal: FC<ContractModalProps> = (props) => {
  const { onClose, onContractAddressSelect } = props

  const [searchName, setSearchName] = useState<string>('')

  const { t } = useTranslation('modalContractsModal')

  const debouncedSearchName = useDebounce(searchName)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const { data, isLoading, isSuccess } = usePlatformContractList({
    page: currentPage,
    size: ITEMS_ON_PAGE,
    status: CONTRACT_STATUS,
    ...(debouncedSearchName && { name: debouncedSearchName }),
  })

  const handleSearchName = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(evt.target.value)
    },
    [setSearchName]
  )

  const handleSelectAddress = useCallback(
    (address: string) => {
      onContractAddressSelect?.(address)
      onClose()
    },
    [onClose, onContractAddressSelect]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    const totalPages = Math.ceil(data.results.length / ITEMS_ON_PAGE)

    return (
      <Modal onClose={onClose} title={t('Search Contract')}>
        <S.Wrapper>
          <Input
            onChange={handleSearchName}
            placeholder={t('Enter contract name')}
            type="text"
            value={searchName}
          />

          {!data.results.length && (
            <S.EmptyContractsList>
              {t('Contracts not found')}
            </S.EmptyContractsList>
          )}
          <S.ContractsContainer>
            <S.Contracts>
              {data.results.map(({ id, name, address }) => (
                <S.ContractWrapper
                  key={id}
                  onClick={() => handleSelectAddress(address!)}
                >
                  <S.ContractName>{name}</S.ContractName>
                  {`(${shortenAddress(address!)})`}
                </S.ContractWrapper>
              ))}
            </S.Contracts>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                totalPages={totalPages}
              />
            )}
          </S.ContractsContainer>
        </S.Wrapper>
      </Modal>
    )
  }

  return null
}
