import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 22 20"
    width={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3 2.00098C2.73478 2.00098 2.48043 2.10633 2.29289 2.29387C2.10536 2.48141 2 2.73576 2 3.00098V17.001C2 17.2662 2.10536 17.5205 2.29289 17.7081C2.48043 17.8956 2.73478 18.001 3 18.001H19C19.2652 18.001 19.5196 17.8956 19.7071 17.7081C19.8946 17.5205 20 17.2662 20 17.001V6.00098C20 5.73576 19.8946 5.48141 19.7071 5.29387C19.5196 5.10633 19.2652 5.00098 19 5.00098H10C9.66565 5.00098 9.35342 4.83388 9.16795 4.55568L7.46482 2.00098H3ZM0.87868 0.879656C1.44129 0.317047 2.20435 0.000976562 3 0.000976562H8C8.33435 0.000976562 8.64658 0.168078 8.83205 0.446276L10.5352 3.00098H19C19.7957 3.00098 20.5587 3.31705 21.1213 3.87966C21.6839 4.44227 22 5.20533 22 6.00098V17.001C22 17.7966 21.6839 18.5597 21.1213 19.1223C20.5587 19.6849 19.7957 20.001 19 20.001H3C2.20435 20.001 1.44129 19.6849 0.87868 19.1223C0.31607 18.5597 0 17.7966 0 17.001V3.00098C0 2.20533 0.31607 1.44227 0.87868 0.879656Z"
      fill="#1B1F3B"
      fillOpacity={0.4}
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgFolder)
export default Memo
