import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/style'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 12px;
    flex-direction: column;
    background-color: transparent;
    border: none;
    box-shadow: none;

    ${media(size.tablet)} {
        width: 100%;
    }

    ${media(size.mobile)} {
        flex-direction: column;
    }
  `
)

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
`

export const SubcategoryLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left: 12px;
  color: ${({ theme }) => theme.color.gray};
  cursor: pointer;
`

export const Button = styled(UIButton)`
  transform: rotate(90deg);
  width: max-content;
  padding: 0;
`
