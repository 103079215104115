import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Container from 'ui/Container/Container'
import { SvgTelegram, SvgTwitter } from 'ui/icons'
import * as S from './style'

const socialLinks = [
  {
    icon: <SvgTwitter />,
    label: 'Twitter',
    href: 'https://twitter.com/Juna_Ecosystem',
  },
  {
    icon: <SvgTelegram />,
    label: 'Telegram',
    href: 'https://t.me/juna_news',
  },
]

export const Footer = memo(() => {
  const { t } = useTranslation('featureLayout')

  const aboutLinks = useMemo(
    () => [
      {
        label: t('Privacy policy'),
        to: '/privacy',
        type: 'NAV_LINK',
      },
      {
        label: t('Whitepaper'),
        to: '/juna_wp.pdf',
        type: 'FILE',
      },
    ],
    [t]
  )

  return (
    <S.FooterWrapper>
      <Container>
        <S.Wrapper>
          <S.SvgLogo />
          <S.Line />
          <S.ColumnsWrapper>
            <S.CategoryWrapper>
              <S.Title>{t('About')}</S.Title>
              <S.LinksWrapper>
                {aboutLinks.map(({ label, to, type }) => (
                  <S.StyledLink
                    key={to}
                    as={type === 'NAV_LINK' ? Link : 'a'}
                    href={to}
                    to={to}
                  >
                    {label}
                  </S.StyledLink>
                ))}
              </S.LinksWrapper>
            </S.CategoryWrapper>
            <S.Line />
            <S.CategoryWrapper>
              <S.Title>{t('Community')}</S.Title>
              <S.LinksWrapper>
                {socialLinks.map(({ icon, label, href }) => (
                  <S.SocialLinkWrapper key={label}>
                    {icon}
                    <S.SocialLinkItem href={href}>{label}</S.SocialLinkItem>
                  </S.SocialLinkWrapper>
                ))}
              </S.LinksWrapper>
            </S.CategoryWrapper>
          </S.ColumnsWrapper>
        </S.Wrapper>
      </Container>
    </S.FooterWrapper>
  )
})
