import { FC } from 'react'
import * as S from './style'

type ErrorType = {
  message?: string
}

export const Error: FC<ErrorType> = (props) => {
  const { message } = props

  return <S.ErrorMessageText>{message}</S.ErrorMessageText>
}
