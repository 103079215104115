import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAtomVariableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={10}
    viewBox="0 0 16 10"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5.70708 1.70718C6.09762 1.31656 6.09762 0.683502 5.70708 0.292877C5.31658 -0.0976257 4.6834 -0.0976257 4.2929 0.292877L0.2929 4.29288C-0.0976334 4.6835 -0.0976334 5.31656 0.2929 5.70718L4.2929 9.70718C4.6834 10.0977 5.31658 10.0977 5.70708 9.70718C6.09762 9.31656 6.09762 8.6835 5.70708 8.29288L2.41421 5.00003L5.70708 1.70718ZM11.0401 0.292877C10.6496 -0.0976257 10.0164 -0.0976257 9.62591 0.292877C9.23537 0.683502 9.23537 1.31656 9.62591 1.70718L12.9188 5.00003L9.62591 8.29288C9.23537 8.6835 9.23537 9.31656 9.62591 9.70718C10.0164 10.0977 10.6496 10.0977 11.0401 9.70718L15.0401 5.70718C15.4306 5.31656 15.4306 4.6835 15.0401 4.29288L11.0401 0.292877Z"
      fill="#372D4C"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgAtomVariableIcon)
export default Memo
