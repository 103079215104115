import { FC } from 'react'
import { NormalizedCategory } from 'pages/Ads/types'
import { Category } from './components'
import * as S from './style'

type CategoriesListProps = {
  className?: string
  categories: NormalizedCategory[]
  onCategoryChange: (categoryId: number | string) => void
  activeCategory?: number | string
}

export const CategoriesList: FC<CategoriesListProps> = (props) => {
  const { categories, onCategoryChange, activeCategory, className } = props

  return (
    <S.Wrapper className={className}>
      {categories.map(({ name, id, subcategories }) => (
        <Category
          key={id}
          activeCategory={activeCategory}
          id={id}
          name={name}
          onCategoryChange={onCategoryChange}
          subcategories={subcategories}
        />
      ))}
    </S.Wrapper>
  )
}
