import { FC, useCallback, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { usePlatformContractList } from 'api/omni/endpoints'
import { PaginatedContractList } from 'api/omni/model'
import { ContractsModalContext } from 'context'
import { FormInput, FormSelect } from 'features/FormFileds'
import {
  PathRuntype,
  ContractContextRuntype,
} from 'pages/ContractProcess/components/MilestoneWrapper/types'
import {
  ContextDynamicFieldRuntype,
  InputRuntype,
} from 'pages/ContractProcess/components/ProcessForm/types'
import { FORM_ID } from 'pages/TransactionCreate/constants'
import { FormValues } from 'pages/TransactionCreate/types'
import { Button } from 'ui/Button/Button'
import { SvgFolder } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { TransactionDetails } from './components/TransactionDetails/TransactionDetails'
import * as S from './style'

type TransactionFormProps = {
  activeStep: number
  onClick: () => void
}

export const TransactionForm: FC<TransactionFormProps> = (props) => {
  const { activeStep, onClick } = props

  const { t } = useTranslation('pageTransactionCreate')

  const { values, handleSubmit, setFieldValue, errors } =
    useFormikContext<FormValues>()

  const { openContractsModal } = useContext(ContractsModalContext)

  const {
    data: contract,
    isLoading: isContractLoading,
    isSuccess: isContractLoaded,
  } = usePlatformContractList(
    {
      address: values.contractAddress,
    },
    {
      query: {
        enabled: errors.contractAddress === undefined,
        select: useCallback(
          (data: PaginatedContractList) => {
            const contractContext = ContractContextRuntype.check(
              data.results[0].context
            )

            const actionOptions = contractContext.path.map((item) => {
              const { name } = PathRuntype.check(contractContext[item])

              return {
                label: name,
                value: item,
              }
            })

            if (values.action) {
              const actionContextInputs = ContextDynamicFieldRuntype.check(
                contractContext[values.action]
              ).inputs

              setFieldValue('inputs', actionContextInputs)

              const actionInputs =
                actionContextInputs &&
                Object.entries(actionContextInputs).map(
                  ([fieldName, fieldData]) => {
                    const { name: label } = InputRuntype.check(fieldData)

                    return {
                      label: label,
                      name: fieldName,
                    }
                  }
                )

              return {
                data: data.results[0],
                actionOptions: actionOptions,
                actionInputs,
                name: data.results[0].name,
              }
            }

            return {
              data: data.results[0],
              actionOptions: actionOptions,
              name: data.results[0].name,
            }
          },
          [setFieldValue, values.action]
        ),
      },
    }
  )

  if (isContractLoading) {
    return <Loader />
  }

  const getFormContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <S.FieldWrapper>
              <S.Label>
                {t(
                  'The contract address is needed so that you can identify it among other contracts'
                )}
              </S.Label>
              <FormInput
                editorProps={{
                  buttons: [
                    {
                      icon: <SvgFolder />,
                      onClick: () =>
                        openContractsModal({
                          onContractAddressSelect: (contractAddress) =>
                            setFieldValue('contractAddress', contractAddress),
                        }),
                      visible: !values.contractAddress,
                    },
                  ],
                }}
                name="contractAddress"
                placeholder={t('Contract address')}
              />
            </S.FieldWrapper>

            <Button
              disabled={errors.contractAddress !== undefined}
              onClick={onClick}
              variant="red"
            >
              {t('Next step')}
            </Button>
          </>
        )
      case 2:
        return (
          isContractLoaded && (
            <>
              <S.FieldWrapper>
                <S.Label>{t('Select the action of your contract')}</S.Label>
                <FormSelect
                  editorProps={{
                    placeholder: t('Select or type action'),
                    options: contract.actionOptions,
                    onChange: (option) =>
                      setFieldValue('action', option?.value),
                  }}
                  name="action"
                />
              </S.FieldWrapper>

              {values.action &&
                contract.actionInputs?.map(({ label, name }) => {
                  return (
                    <S.FieldWrapper key={name}>
                      <S.Label>{label}:</S.Label>
                      <FormInput name={name} placeholder={label} />
                    </S.FieldWrapper>
                  )
                })}

              <Button onClick={onClick} variant="red">
                {t('Next step')}
              </Button>
            </>
          )
        )

      case 3:
        return (
          isContractLoaded && (
            <>
              <TransactionDetails
                actionInputs={contract.actionInputs}
                actionOptions={contract.actionOptions}
                contractName={contract.name}
              />
              <Button form={FORM_ID} type="submit" variant="red">
                {t('Finish and send for approval to a partner')}
              </Button>
            </>
          )
        )
    }
  }

  return (
    <S.Form id={FORM_ID} onSubmit={handleSubmit}>
      {getFormContent()}
    </S.Form>
  )
}
