import { rgba } from 'polished'
import styled, { DefaultTheme } from 'styled-components'
import { Card } from 'ui/Card/Card'
import { ProgressBlockType } from './types'

const getStatChipBgColor = (
  theme: DefaultTheme,
  progressBlockType: ProgressBlockType,
  isActive?: boolean
) => {
  if (isActive) {
    switch (progressBlockType) {
      case 'bcet1':
        return theme.color.red
      case 'brr':
        return theme.color.green
    }
  }

  switch (progressBlockType) {
    case 'bcet1':
      return rgba(theme.color.red, 0.5)
    case 'brr':
      return theme.color.mirageDark
  }
}

export const Wrapper = styled(Card)`
  padding: 8px 18px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`

export const InfoBlocksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const Label = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const StatChip = styled.div<{
  progressBlockType: ProgressBlockType
  isActive?: boolean
}>`
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme, progressBlockType, isActive }) =>
    getStatChipBgColor(theme, progressBlockType, isActive)};
`

export const ProgressBar = styled.div<{ progressBlockType: ProgressBlockType }>`
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme, progressBlockType }) =>
    progressBlockType === 'bcet1'
      ? rgba(theme.color.red, 0.5)
      : theme.color.mirageDark};
  height: 6px;

  div {
    background-color: ${({ theme, progressBlockType }) =>
      progressBlockType === 'bcet1' ? theme.color.red : theme.color.green};
  }
`

export const ProgressLine = styled.div<{ progressPercent: number }>`
  width: ${({ progressPercent }) => `${progressPercent}%`};
  height: 100%;
  border-radius: 12px;
`
