import { FC, useState, useCallback, useMemo } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import {
  usePlatformAdsRetrieve,
  usePlatformContractCreate,
} from 'api/omni/endpoints'
import { Lg } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { ChainId } from 'constants/blockchain'
import { useMatchMedia } from 'hooks'
import { Button } from 'ui/Button/Button'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Stepper } from 'ui/Stepper/Stepper'
import { JunaSimpleForm, ContractDetails } from './components'
import { FORM_ID } from './constants'
import contract from './contract.json'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

const TEST_ADDRESS = '0xED2dA4A525d93C83Db9AA76432f0311ed2B9A1c8'

export const JunaSimpleV2: FC = () => {
  const [activeStep] = useState<number>(1)
  const [pickedFile, setPickedFile] = useState<File>()
  const [legalGatesValues, setLegalGatesValues] = useState<Lg[]>([])

  const { isMobile } = useMatchMedia()

  const navigate = useNavigate()

  const { address } = useAccount()

  const [searchParams] = useSearchParams()
  const adId = searchParams.get('adId')

  const { data: ad, isLoading: isAdLoading } = usePlatformAdsRetrieve(
    Number(adId),
    {
      query: {
        enabled: !!adId,
      },
    }
  )

  const { t } = useTranslation('pageJunaSimple')

  const steps = useMemo(
    () => [
      {
        label: t('Creation'),
        step: 1,
      },
      {
        label: t('Signing'),
        step: 2,
      },
      {
        label: t('Deployment'),
        step: 3,
      },
      {
        label: t('Execution'),
        step: 4,
      },
    ],
    [t]
  )

  const { mutate, isLoading: isContractCreating } = usePlatformContractCreate({
    mutation: {
      onSuccess: (data) => {
        navigate(`${AppRoutes.Contracts}/${data.id}`)
      },
    },
  })

  const getInitialValues = () => {
    const initialValues: FormValues = {
      contractName: 'Simple contract',
      ownName: '',
      ownAddress: '',
      partnerName: '',
      partnerAddress: '',
      agreementText: '',
      amountJUNA: '',
      amountUSD: '',
    }

    if (ad) {
      initialValues.contractName = ad.name
      initialValues.partnerAddress = ad.address
      initialValues.agreementText = ad.description
    }

    return initialValues
  }

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Juna Simple v2',
        url: AppRoutes.SimpleV2,
      },
    ]

    if (ad) {
      return [
        {
          label: 'Home',
          url: AppRoutes.Main,
        },
        {
          label: 'Marketplace',
          url: AppRoutes.Marketplace,
        },
        {
          label: ad.name,
          url: `${AppRoutes.Marketplace}/${ad.id}`,
        },
        {
          label: 'Juna Simple v2',
          url: AppRoutes.SimpleV2,
        },
      ]
    }

    return initialBreadcrumbs
  }, [ad])

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values: FormValues) => {
      const stringAddress = address?.toString()

      if (!stringAddress) {
        return
      }

      const contractMembers = [
        {
          name: stringAddress,
          address: stringAddress,
        },
        {
          name: values.partnerAddress,
          address: values.partnerAddress,
        },
      ]

      const reqBody = {
        name: values.contractName,
        members: contractMembers,
        chainId: ChainId.BSC_TESTNET,
        ipfs: pickedFile,
        data: {
          members: contractMembers,
          legalGates: legalGatesValues.length > 0 ? [TEST_ADDRESS] : [],
          legalGatesRewards:
            legalGatesValues.length > 0 ? [legalGatesValues[0].price] : [],
          amountJuna: values.amountJUNA && values.amountJUNA,
        },
        code: contract.code.code,
        abi: JSON.parse(contract.code.abi),
        context: contract.code.context,
        description: values.agreementText,
        bytecode: contract.code.bytecode,
        assurance: true,
        legalGateId:
          legalGatesValues.length > 0 ? legalGatesValues[0].id : undefined,
      }

      mutate({ data: reqBody })
    },
    [address, legalGatesValues, mutate, pickedFile]
  )

  const handleSelectLegalGate = useCallback((idx: number, legalGate: Lg) => {
    const legalGates = contract.formFields.legalGates.data

    setLegalGatesValues((prev) =>
      legalGates.map((_, index) => {
        if (index === idx) {
          return legalGate
        } else {
          return prev[index]
        }
      })
    )
  }, [])

  const handleFilePickerChange = useCallback((file: File) => {
    setPickedFile(file)
  }, [])

  if (isAdLoading) {
    return <Loader />
  }

  return (
    <PageWrapper
      actions={
        <Button
          form={FORM_ID}
          isLoading={isContractCreating}
          type="submit"
          variant="red"
        >
          {t('Sign, Declare')}
        </Button>
      }
      breadcrumbs={breadcrumbs}
      title={t('Juna Simple v2')}
    >
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
      >
        <S.Wrapper>
          <S.LeftSideWrapper>
            <Stepper
              activeStep={activeStep}
              steps={steps}
              vertical={isMobile}
            />
            <JunaSimpleForm
              legalGates={contract.formFields.legalGates}
              selectFile={handleFilePickerChange}
              selectLegalGate={handleSelectLegalGate}
            />
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <ContractDetails
              selectedLegalGateName={
                legalGatesValues[0] && legalGatesValues[0].name
              }
            />
          </S.RightSideWrapper>
        </S.Wrapper>
      </Formik>
    </PageWrapper>
  )
}
