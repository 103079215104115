import styled, { css } from 'styled-components'

export const StepperWrapper = styled.div<{ vertical: boolean }>(
  ({ theme: { media, size }, vertical }) => `
    max-width: 100%;
    display: flex;
    flex-direction: ${vertical ? 'column' : 'row'};
    justify-content: space-between;
    height: ${vertical ? '100%' : '32px'};
    gap: 16px;

    ${media(size.mobile)} {
      flex-direction: column;
    }
`
)

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 1 auto;
`

export const StepLine = styled.div<{
  isCompleted: boolean
  isCurrent: boolean
}>`
  max-width: 162px;
  min-width: 10px;
  width: 100%;
  height: 1px;

  background-color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.color.red : theme.color.line};

  ${({ isCompleted, theme }) => {
    if (isCompleted) {
      return css`
        background-color: ${theme.color.green};
      `
    }
  }}
`
