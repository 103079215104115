import { FC, useState, useCallback, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlatformLegalgateList } from 'api/omni/endpoints'
import { Lg } from 'api/omni/model'
import { Button } from 'ui/Button/Button'
import { Input } from 'ui/Input/Input'
import { Loader } from 'ui/Loader/Loader'
import { Modal } from 'ui/Modal/Modal'
import * as S from './style'

const MODAL_TITLE = 'Search Legal Gate'
const MAX_LENGTH_DESC = 25

type SearchModalProps = {
  onClose: () => void
  onLegalGateSelect: (selectedLegalGate: Lg) => void
}

export const SearchModal: FC<SearchModalProps> = (props) => {
  const { onClose, onLegalGateSelect } = props
  const [search, setSearch] = useState<string>('')

  const { t } = useTranslation('featureChooseLegalGate')

  const {
    data: legalGates,
    isLoading,
    isSuccess,
  } = usePlatformLegalgateList({
    name: search,
  })

  const handleSearchInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setSearch(evt.target.value)
    },
    [setSearch]
  )

  const handleSelectLegalGateClick = useCallback(
    (idx: number) => () => {
      if (legalGates) {
        onLegalGateSelect(legalGates.results[idx])
      }
    },
    [onLegalGateSelect, legalGates]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <Modal onClose={onClose} title={MODAL_TITLE}>
        <S.Wrapper>
          <Input
            name="company"
            onChange={handleSearchInputChange}
            placeholder={t('Enter company name')}
            type="text"
            value={search}
          />
          <S.List>
            {legalGates.results.map(({ name, description, id }, idx) => (
              <Button
                key={id}
                onClick={handleSelectLegalGateClick(idx)}
                variant="transparent"
              >
                <S.BtnContainer>
                  <S.Title>{name}</S.Title>
                  <S.Text>
                    {description!.substring(0, MAX_LENGTH_DESC)}
                    {description!.length > MAX_LENGTH_DESC && '...'}
                  </S.Text>
                </S.BtnContainer>
              </Button>
            ))}
          </S.List>
        </S.Wrapper>
      </Modal>
    )
  }

  return null
}
