/**
 * Count sigs in contract and generate string (ex. 3/4)
 * @param contract - object of contract
 * @example
 * formatSigs(contract)
 * @returns {string} sigs/count (ex. 3/4)
 */
export const formatSigs = (contract: any) => {
  let sigs = 0
  let count = 0

  contract.members.forEach((member: any) => {
    if (member.sig) {
      sigs++
    }
    count++
  })

  if (contract.legalGate) {
    if (contract.legalGate.sig) {
      sigs++
    }
    count++
  }

  return `${sigs}/${count}`
}
