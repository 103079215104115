import { FC } from 'react'
import { ConstructorField, ConstructorVariableBlock } from 'libs/constructor'

type NumberAtomInputsProps = {
  id: string
  placeholder: string
  canBeVariable?: boolean
  atomName: string
}

export const NumberAtomInputs: FC<NumberAtomInputsProps> = (props) => {
  const { id, placeholder, canBeVariable, atomName } = props

  return (
    <>
      <ConstructorField
        key={id}
        atomId={id}
        name={atomName}
        placeholder={placeholder}
      />
      {canBeVariable && (
        <ConstructorVariableBlock atomFieldName={atomName} atomId={id} />
      )}
    </>
  )
}
