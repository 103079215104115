import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import * as S from './style'

export const NotFound: FC = () => {
  const { t } = useTranslation('pageNotFound')

  const navigate = useNavigate()

  const handleBackBtnClick = () => {
    navigate(AppRoutes.Main)
  }

  return (
    <S.Wrapper>
      <S.NotFoundWrapper>
        <S.TextWrapper>
          <S.Title>
            {t('Oops')}! {t('Not found')} 404
          </S.Title>
          <S.Label>
            {t(
              'The page you are looking for may have been deleted, its name may have changed, or it may not exist'
            )}
          </S.Label>
        </S.TextWrapper>
        <S.Button onClick={handleBackBtnClick} variant="red">
          {t('Back to homepage')}
        </S.Button>
      </S.NotFoundWrapper>
    </S.Wrapper>
  )
}
