import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { transactionsMock } from 'pages/Contract/mock'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

const EXECUTE_FN_SELECTOR = '0xee22610b'

const getProcessLink = (contractId: number, selector: string) => {
  return `/contracts/${contractId}/process/${selector}`
}

export const AllTransactions = () => {
  const { t } = useTranslation('pageContract')
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate({
      pathname: getProcessLink(130, EXECUTE_FN_SELECTOR),
    })
  }, [navigate])

  return (
    <S.Wrapper>
      <S.Label>{t('All transactions')}</S.Label>
      {transactionsMock.map(({ id, contractName, address, functionName }) => (
        <S.ContractField key={id} onClick={handleClick}>
          <S.Title>
            <S.ContractName>{contractName}</S.ContractName>
            <S.Address>{shortenAddress(address)}</S.Address>
          </S.Title>
          <S.FunctionName>{functionName}</S.FunctionName>
        </S.ContractField>
      ))}
    </S.Wrapper>
  )
}
