import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={32}
    viewBox="0 0 32 32"
    width={32}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.0005 6.66675H23.0493C24.3107 6.66675 25.334 7.69892 25.334 8.97225V12.0466C25.334 13.3189 24.3107 14.3521 23.0493 14.3521H20.0005C18.7381 14.3521 17.7148 13.3189 17.7148 12.0466V8.97225C17.7148 7.69892 18.7381 6.66675 20.0005 6.66675Z"
      stroke="#8388AA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M8.95265 6.66675H12.0005C13.2629 6.66675 14.2862 7.69892 14.2862 8.97225V12.0466C14.2862 13.3189 13.2629 14.3521 12.0005 14.3521H8.95265C7.69028 14.3521 6.66699 13.3189 6.66699 12.0466V8.97225C6.66699 7.69892 7.69028 6.66675 8.95265 6.66675Z"
      stroke="#8388AA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M8.95265 17.6482H12.0005C13.2629 17.6482 14.2862 18.6804 14.2862 19.9547V23.028C14.2862 24.3014 13.2629 25.3335 12.0005 25.3335H8.95265C7.69028 25.3335 6.66699 24.3014 6.66699 23.028V19.9547C6.66699 18.6804 7.69028 17.6482 8.95265 17.6482Z"
      stroke="#8388AA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M20.0005 17.6482H23.0493C24.3107 17.6482 25.334 18.6804 25.334 19.9547V23.028C25.334 24.3014 24.3107 25.3335 23.0493 25.3335H20.0005C18.7381 25.3335 17.7148 24.3014 17.7148 23.028V19.9547C17.7148 18.6804 18.7381 17.6482 20.0005 17.6482Z"
      stroke="#8388AA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)
const Memo = memo(SvgHamburger)
export default Memo
