import { ChangeEvent, FC, useCallback, useState } from 'react'
import { Button } from 'ui/Button/Button'
import { SvgJunaToken } from 'ui/icons'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type DepositReserveFormProps = {
  amount: number
}

export const DepositReserveForm: FC<DepositReserveFormProps> = (props) => {
  const { amount } = props

  const [transferAmountValue, setTransferAmountValue] = useState<string>('')

  const handleTransferInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setTransferAmountValue(evt.target.value)
    },
    []
  )

  return (
    <S.Wrapper>
      <S.ColWrapper>
        <S.Title>Deposited</S.Title>
        <S.AmountWrapper>
          <SvgJunaToken />
          <S.AmountLabel>{amount}</S.AmountLabel>
        </S.AmountWrapper>
      </S.ColWrapper>
      <S.FieldWrapper>
        <S.FieldLabel>Enter Juna amount to transfer deposit</S.FieldLabel>
        <Input
          onChange={handleTransferInputChange}
          placeholder="Amount"
          value={transferAmountValue}
        />
      </S.FieldWrapper>

      <Button variant="red">Deposit</Button>
    </S.Wrapper>
  )
}
