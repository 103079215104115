import { FC } from 'react'
import { AppRoutes } from 'constants/app'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { DepositReserveForm, InfoBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Deposit ',
    url: '',
  },
]

export const DepositReserve: FC = () => {
  return (
    <PageWrapper breadcrumbs={breadcrumbs} title="Deposit to reserve system">
      <S.Wrapper>
        <DepositReserveForm amount={5000} />
        <InfoBlock amount={23000} />
      </S.Wrapper>
    </PageWrapper>
  )
}
