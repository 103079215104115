import { keyframes, css, DefaultTheme } from 'styled-components'

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; } 
`

export const theme: DefaultTheme = {
  animation: {
    fadeIn: css`0.5s linear ${fadeInAnimation}`,
  },
  color: {
    black: `#372D4C`,
    gray: `#8388AA`,
    yellow: `#FFD265`,
    white: `#FFFFFF`,
    bg: `#F3F3F8`,
    line: `#E2E2EE`,
    blue: `#4D9BFF`,
    purple: `#C83EF3`,
    red: `#FF584B`,
    green: `#3EA331`,
    lightGray: `#F6F6F9`,
    whisper: `#ECECF4`,
    valencia: `#D15147`,
    gallery: `#F0F0F0`,
    cobalt: `#004EB2`,
    comet: `#5B6185`,
    darkPurple: `#5D556F`,
    orange: '#FFD265',
    mirageDark: 'rgba(27, 31, 59, 0.2)',
    mirageLight: 'rgba(27, 31, 59, 0.05)',
    lightYellow: '#FFFCE1',
  },
  common: {
    boxShadow: `0px 2px 3px 0px rgba(129, 129, 182, 0.12)`,
  },
  gradient: {
    g1: `linear-gradient(88.5deg, #C83EF3 17.43%, #FF584B 82.38%)`,
    g2: `conic-gradient(from 153.19deg at 59.91% 55.84%, #FF574A 0deg, #FF695E 60deg, #FFD361 138.75deg, #E755FF 236.25deg, #4D9BFF 360deg)`,
    g3: `linear-gradient(239.24deg, #6092FF 18.58%, #C83EF3 81.88%)`,
    rainbow: `linear-gradient(104deg, #728BFF 2.67%, #E557FF 43.38%, #FF634D 76.69%, #FDEF73 109.99%)`,
  },
  media: (size: number) => `@media screen and (max-width: ${size}px)`,
  size: {
    mobile: 767,
    tablet: 1439,
  },
}
