import { StoreNameSpace } from 'constants/app'
import { RootState } from 'types/store'

export const selectApprovePinStatus = (state: RootState) =>
  state[StoreNameSpace.Pin].approvePinStatus

export const selectPinChecked = (state: RootState) =>
  state[StoreNameSpace.Pin].pinChecked

export const selectPinChecking = (state: RootState) =>
  state[StoreNameSpace.Pin].pinChecking

export const selectPinCheckFailed = (state: RootState) =>
  state[StoreNameSpace.Pin].pinCheckFailed
