import { FC } from 'react'
import { MyAccountBlock } from './components/MyAccountBlock/MyAccountBlock'
import { MyContractsBlock } from './components/MyContractsBlock/MyContractsBlock'
import * as S from './style'

export const AccountBlock: FC = () => {
  return (
    <S.Wrapper>
      <MyAccountBlock />
      <MyContractsBlock />
    </S.Wrapper>
  )
}
