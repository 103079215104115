import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const StyledCard = styled(Card)`
  padding: 24px;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px 0px;
`

export const SignatureBlock = styled.div`
  width: 50%;
`

export const NameLabel = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const WalletLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray};
`
export const Title = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  &:not(:nth-last-child(1)) {
    margin-bottom: 24px;
  }
`

export const MembersBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled(UIButton)`
  width: 100%;

  svg path {
    transition: 0.5s;
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`
