import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgMetamask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="white"
    />
    <path
      d="M19.4399 4.229L12.9854 9.01255L14.1844 6.19345L19.4399 4.229Z"
      fill="#E17726"
    />
    <path
      d="M4.56641 4.229L10.9572 9.0572L9.81555 6.19345L4.56641 4.229Z"
      fill="#E27625"
    />
    <path
      d="M17.119 15.3203L15.4033 17.9481L19.0771 18.9622L20.1231 15.3777L17.119 15.3203Z"
      fill="#E27625"
    />
    <path
      d="M3.88379 15.3777L4.92341 18.9622L8.59719 17.9481L6.88786 15.3203L3.88379 15.3777Z"
      fill="#E27625"
    />
    <path
      d="M8.3999 10.8811L7.37305 12.4246L11.0149 12.5905L10.8873 8.66797L8.3999 10.8811Z"
      fill="#E27625"
    />
    <path
      d="M15.6067 10.8814L13.0747 8.62354L12.9854 12.5907L16.6272 12.4248L15.6067 10.8814Z"
      fill="#E27625"
    />
    <path
      d="M8.59668 17.9482L10.7907 16.883L8.90281 15.4033L8.59668 17.9482Z"
      fill="#E27625"
    />
    <path
      d="M13.208 16.883L15.4021 17.9482L15.0959 15.4033L13.208 16.883Z"
      fill="#E27625"
    />
    <path
      d="M15.4021 17.948L13.208 16.8828L13.3802 18.3115L13.3611 18.911L15.4021 17.948Z"
      fill="#D5BFB2"
    />
    <path
      d="M8.59668 17.948L10.6377 18.911L10.6249 18.3115L10.7907 16.8828L8.59668 17.948Z"
      fill="#D5BFB2"
    />
    <path
      d="M10.6767 14.4595L8.85254 13.9237L10.1409 13.3306L10.6767 14.4595Z"
      fill="#233447"
    />
    <path
      d="M13.3232 14.4595L13.859 13.3306L15.1601 13.9237L13.3232 14.4595Z"
      fill="#233447"
    />
    <path
      d="M8.59702 17.9481L8.91592 15.3203L6.8877 15.3777L8.59702 17.9481Z"
      fill="#CC6228"
    />
    <path
      d="M15.084 15.3203L15.4029 17.9481L17.1186 15.3777L15.084 15.3203Z"
      fill="#CC6228"
    />
    <path
      d="M16.6272 12.4248L12.9854 12.5906L13.3234 14.4594L13.8592 13.3305L15.1603 13.9237L16.6272 12.4248Z"
      fill="#CC6228"
    />
    <path
      d="M8.85275 13.9237L10.1411 13.3305L10.6769 14.4594L11.0149 12.5906L7.37305 12.4248L8.85275 13.9237Z"
      fill="#CC6228"
    />
    <path
      d="M7.37305 12.4248L8.90377 15.4034L8.85275 13.9237L7.37305 12.4248Z"
      fill="#E27525"
    />
    <path
      d="M15.1605 13.9237L15.0967 15.4034L16.6274 12.4248L15.1605 13.9237Z"
      fill="#E27525"
    />
    <path
      d="M11.0148 12.5903L10.6768 14.4591L11.1041 16.6659L11.1934 13.7575L11.0148 12.5903Z"
      fill="#E27525"
    />
    <path
      d="M12.9857 12.5903L12.8135 13.7511L12.8964 16.6659L13.3237 14.4591L12.9857 12.5903Z"
      fill="#E27525"
    />
    <path
      d="M13.3228 14.4591L12.8955 16.6659L13.208 16.8828L15.0959 15.4031L15.1597 13.9233L13.3228 14.4591Z"
      fill="#F5841F"
    />
    <path
      d="M8.85254 13.9233L8.90356 15.4031L10.7915 16.8828L11.104 16.6659L10.6767 14.4591L8.85254 13.9233Z"
      fill="#F5841F"
    />
    <path
      d="M13.3611 18.9113L13.3802 18.3118L13.2208 18.1715H10.778L10.6249 18.3118L10.6377 18.9113L8.59668 17.9482L9.311 18.535L10.7588 19.5364H13.2399L14.6877 18.535L15.4021 17.9482L13.3611 18.9113Z"
      fill="#C0AC9D"
    />
    <path
      d="M13.2091 16.8829L12.8966 16.666H11.1043L10.7918 16.8829L10.626 18.3115L10.7791 18.1712H13.2219L13.3813 18.3115L13.2091 16.8829Z"
      fill="#161616"
    />
    <path
      d="M19.7072 9.32508L20.2494 6.68455L19.4394 4.229L13.208 8.8531L15.6062 10.8813L18.9865 11.8699L19.7328 10.9961L19.4075 10.7601L19.9305 10.2882L19.535 9.97563L20.0517 9.58658L19.7072 9.32508Z"
      fill="#763E1A"
    />
    <path
      d="M3.75 6.68455L4.2985 9.32508L3.94773 9.58658L4.46435 9.97563L4.0689 10.2882L4.5919 10.7601L4.26662 10.9961L5.01285 11.8699L8.3996 10.8813L10.7914 8.8531L4.5664 4.229L3.75 6.68455Z"
      fill="#763E1A"
    />
    <path
      d="M18.9873 11.8699L15.6069 10.8813L16.6274 12.4248L15.0967 15.4034L17.1185 15.3779H20.1226L18.9873 11.8699Z"
      fill="#F5841F"
    />
    <path
      d="M8.39946 10.8813L5.01271 11.8699L3.88379 15.3779H6.88786L8.90331 15.4034L7.37259 12.4248L8.39946 10.8813Z"
      fill="#F5841F"
    />
    <path
      d="M12.9853 12.5906L13.2085 8.85301L14.1844 6.19336H9.81543L10.7913 8.85301L11.0145 12.5906L11.0974 13.7641L11.1038 16.6661H12.896L12.9024 13.7641L12.9853 12.5906Z"
      fill="#F5841F"
    />
  </svg>
)
const Memo = memo(SvgMetamask)
export default Memo
