import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import {
  useProviderModal,
  useNetworkModal,
  useTokenBalance,
  useAuthStatus,
} from 'hooks'
import { useLegalGateBasket } from 'hooks/useLegalGateBasket/useLegalGateBasket'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { selectRoles } from 'store/user/user.selectors'
import { Avatar } from 'ui/Avatar/Avatar'
import Container from 'ui/Container/Container'
import { SvgHamburger } from 'ui/icons'
import { toHumanNumber } from 'utils/toHumanNumber'
import { ProfileDropdown, useProfileDropdown } from '../'
import { BalanceBadge } from './components/BalanceBadge/BalanceBadge'
import { DesktopMenu } from './components/DesktopMenu/DesktopMenu'
import { LegalGateButton } from './components/LegalGateButton/LegalGateButton'

import * as S from './style'
import { NavLink } from './types'

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const { t } = useTranslation('featureLayout')

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { address, isConnected } = useAccount()

  const { chain } = useNetwork()

  const { toggleProviderModal } = useProviderModal()

  const { toggleNetworkModal } = useNetworkModal()

  const { toggleProfileModal, isProfileModalVisible } = useProfileDropdown()

  const { legalGate, reset } = useLegalGateBasket()

  const balance = useTokenBalance(getJunaTokenAddress(chain?.id), address)

  const roles = useSelector(selectRoles)

  const { authStatus } = useAuthStatus()

  const isHomePage = pathname === '/'

  const isPrivacyPolicyPage = pathname === '/privacy'

  const navLinks: NavLink[] = useMemo(
    () => [
      {
        role: 'link',
        label: t('Home'),
        to: AppRoutes.Main,
      },
      {
        role: 'link',
        label: t('Contracts'),
        to: AppRoutes.Catalog,
      },
      {
        role: 'link',
        label: t('Marketplace'),
        to: AppRoutes.Marketplace,
      },
      {
        role: 'link',
        label: t('Juna Simple'),
        to: AppRoutes.SimpleV5,
      },
      {
        role: 'submenu',
        label: t('All services'),
        sublinks: [
          {
            role: 'link',
            label: t('Legal Gates'),
            to: AppRoutes.LegalGatesCatalog,
          },
          {
            role: 'link',
            label: t('Launchpad'),
            to: AppRoutes.Launchpad,
          },
          {
            role: 'link',
            label: t('Governor Voting'),
            to: AppRoutes.GovernorVoting,
            hidden: !roles.includes('GOVERNOR'),
          },
          {
            role: 'link',
            label: t('Finance'),
            to: AppRoutes.Finance,
          },
        ],
      },
    ],
    [roles, t]
  )

  const handleLogoClick = useCallback(
    () => !isHomePage && navigate('/'),
    [isHomePage, navigate]
  )

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev)
  }, [])

  return (
    <>
      <S.HeaderWrapper>
        <Container>
          <S.Wrapper>
            <S.LeftWrapper>
              <S.Logo isHome={isHomePage} onClick={handleLogoClick}>
                <S.SvgLogo />
              </S.Logo>
              {!isPrivacyPolicyPage && authStatus === 'authorized' && (
                <DesktopMenu navLinks={navLinks} />
              )}
            </S.LeftWrapper>
            <S.BtnWrapper>
              {isConnected && legalGate && (
                <LegalGateButton onClick={reset}>
                  {legalGate.name}
                </LegalGateButton>
              )}
              {chain && (
                <S.NetworkButton onClick={toggleNetworkModal}>
                  {Chains[chain.id] ? (
                    <>
                      {Chains[chain.id]?.icon}
                      {Chains[chain.id]?.name}
                      <BalanceBadge balance={toHumanNumber(balance)} />
                    </>
                  ) : (
                    <>{t('Unsupported chain')}</>
                  )}
                </S.NetworkButton>
              )}
              {address ? (
                <Avatar address={address} onClick={toggleProfileModal} />
              ) : (
                <S.ConnectWalletBtnWrapper>
                  <S.ConnectWalletButton onClick={toggleProviderModal}>
                    {t('Connect wallet')}
                  </S.ConnectWalletButton>
                </S.ConnectWalletBtnWrapper>
              )}
            </S.BtnWrapper>
            <S.HamburgerWrapper onClick={toggleMobileMenu}>
              <S.Hamburger>
                <SvgHamburger />
              </S.Hamburger>
            </S.HamburgerWrapper>
            {isProfileModalVisible && (
              <ProfileDropdown onClose={toggleProfileModal} />
            )}
          </S.Wrapper>
        </Container>
      </S.HeaderWrapper>
      {isMobileMenuOpen && (
        <S.MobileMenu navLinks={navLinks} toggleMobileMenu={toggleMobileMenu} />
      )}
    </>
  )
}
