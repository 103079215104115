import { AccountBlock } from './components/AccountBlock/AccountBlock'
import { EcosystemBlock } from './components/EcosystemBlock/EcosystemBlock'
import { FastActions } from './components/FastActions/FastActions'
import { LastTxBlock } from './components/LastTxBlock/LastTxBlock'

import * as S from './style'

export const UserDashboard = () => {
  return (
    <S.Container>
      <FastActions />
      <S.Main>
        <AccountBlock />
        <LastTxBlock />
        <EcosystemBlock />
      </S.Main>
    </S.Container>
  )
}
