import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { useOutsideClick } from 'hooks'
import { ContractStatusesDictionary } from '../../SearchBlock'
import * as S from './style'

const ContractStatuses: Record<AtomAtomContractListStatus, string> = {
  draft: 'draft',
  on_approval: 'on_approval',
  on_deployment: 'on_deployment',
  execution: 'execution',
  finished: 'finished',
} as const

type SelectProps = {
  onSelect: (status: AtomAtomContractListStatus) => void
  onDelete: () => void
  onClose: () => void
}

export const StatusSelectDropdown: FC<SelectProps> = (props) => {
  const { onSelect, onDelete, onClose } = props

  const { t } = useTranslation('pageContracts')

  const statusSelectRef = useRef<HTMLDivElement>(null)

  useOutsideClick(statusSelectRef, onClose)

  const handleOptionSelect = (status: AtomAtomContractListStatus) => {
    onSelect(status)
    onClose()
  }

  return (
    <S.Wrapper ref={statusSelectRef}>
      <S.Label>{t('Select status')}</S.Label>
      {Object.keys(ContractStatuses).map((status, idx) => (
        <S.Option
          key={idx}
          onClick={() =>
            handleOptionSelect(status as AtomAtomContractListStatus)
          }
        >
          {ContractStatusesDictionary[status as AtomAtomContractListStatus]}
        </S.Option>
      ))}
      <S.DeleteButton onClick={onDelete}>{t('Delete filter')}</S.DeleteButton>
    </S.Wrapper>
  )
}
