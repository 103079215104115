import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  useFinanceContractList,
  useFinanceContractSummaryRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'

import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import createDepositImage from './assets/createDepositImage.png'
import { FinanceCard, BalanceInfoBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Finance',
    url: AppRoutes.Deposits,
  },
]

export const Finance: FC = () => {
  const { t } = useTranslation('pageFinance')

  const navigate = useNavigate()

  const {
    data: finances,
    isLoading: isFinancesLoading,
    isSuccess: isFinancesLoaded,
  } = useFinanceContractList()

  const {
    data: summary,
    isLoading: isSummaryLoading,
    isSuccess: isSummaryLoaded,
  } = useFinanceContractSummaryRetrieve()

  if (isFinancesLoading || isSummaryLoading) {
    return <Loader />
  }

  if (isFinancesLoaded && isSummaryLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Finance')}>
        <S.Wrapper>
          <S.TopBlockWrapper>
            <BalanceInfoBlock
              creditTotal={summary.creditTotal}
              depositTotal={summary.depositTotal}
            />
            <S.OperationsBlock>
              <S.OperationsLabel>{t('Available operations')}</S.OperationsLabel>
              <S.OperationButton
                onClick={() => navigate(AppRoutes.CreditCreate)}
                variant="red"
              >
                {t('Take a credit')}
              </S.OperationButton>
              <S.OperationButton
                onClick={() => navigate(AppRoutes.DepositCreate)}
                variant="red"
              >
                {t('Make a deposit')}
              </S.OperationButton>
            </S.OperationsBlock>
          </S.TopBlockWrapper>
          <S.CardsWrapper>
            {!finances.results.length && (
              <S.DepositCardBorder>
                <S.CreateBlock>
                  <S.CreateDepositImage
                    alt="create_deposit_image"
                    src={createDepositImage}
                  />
                  <S.CreateBlockBottomWrapper>
                    <S.Label>{t('You dont have any active contracts')}</S.Label>
                    <Button
                      onClick={() => navigate(AppRoutes.DepositCreate)}
                      variant="red"
                    >
                      {t('Create a contact')}
                    </Button>
                  </S.CreateBlockBottomWrapper>
                </S.CreateBlock>
              </S.DepositCardBorder>
            )}
            {finances.results.map(
              ({
                id,
                amount,
                finishedAt,
                interestedRate,
                atomContract,
                type,
                bank,
              }) => (
                <FinanceCard
                  key={id}
                  amount={amount}
                  bankName={bank.name}
                  finishedAt={finishedAt}
                  id={id!}
                  interestedRate={interestedRate}
                  name={atomContract.name}
                  status={atomContract.status}
                  type={type}
                />
              )
            )}
          </S.CardsWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
