import styled from 'styled-components'

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 1px;
  &:hover {
    background: linear-gradient(#c83ef3, #ff584b);
  }
`

export const Container = styled.a<{ background: string }>`
  border-radius: 40px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 20px;
  background-color: ${(props) => props.background};
  border: 2px solid ${({ theme }) => theme.color.white};
  cursor: pointer;
`
