import { useCallback, useMemo } from 'react'
import { Props as SelectProps, GroupBase, SingleValue } from 'react-select'
import { Select } from 'ui/Select/Select'
import { useConstructorContext } from '../../hooks/useConstructorContext/useConstructorContext'

type ConstructorSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  name: string
  editorProps: SelectProps<Option, IsMulti, Group>
}

export const ConstructorSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: ConstructorSelectProps<Option, IsMulti, Group>
) => {
  const { name, editorProps } = props

  const { setFieldValue, getValue } = useConstructorContext()

  const handleChange = useCallback(
    (item: SingleValue<any>) => {
      if (!item?.id) {
        return
      }

      setFieldValue({
        name,
        value: item.id,
      })
    },
    [name, setFieldValue]
  )

  const value = useMemo(() => {
    const id = getValue({
      name,
    })

    return editorProps.options?.find((item: any) => item.id === id)
  }, [getValue, name, editorProps.options])

  return (
    <Select
      name={name}
      onChange={handleChange}
      value={value as Option}
      {...editorProps}
    />
  )
}
