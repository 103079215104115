import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgDepositDownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={25}
    viewBox="0 0 24 25"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#FF665B"
      fillOpacity={0.2}
      height={24}
      rx={8}
      width={24}
      y={0.5}
    />
    <mask
      height={25}
      id="mask0_187_1091"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={24} width={24} y={0.5} />
    </mask>
    <g mask="url(#mask0_187_1091)">
      <path
        d="M12 15.7L15.2 12.5L14.08 11.38L12.8 12.66V9.3H11.2V12.66L9.92 11.38L8.8 12.5L12 15.7ZM12 20.5C10.8933 20.5 9.85333 20.29 8.88 19.87C7.90667 19.45 7.06 18.88 6.34 18.16C5.62 17.44 5.05 16.5933 4.63 15.62C4.21 14.6467 4 13.6067 4 12.5C4 11.3933 4.21 10.3533 4.63 9.38C5.05 8.40667 5.62 7.56 6.34 6.84C7.06 6.12 7.90667 5.55 8.88 5.13C9.85333 4.71 10.8933 4.5 12 4.5C13.1067 4.5 14.1467 4.71 15.12 5.13C16.0933 5.55 16.94 6.12 17.66 6.84C18.38 7.56 18.95 8.40667 19.37 9.38C19.79 10.3533 20 11.3933 20 12.5C20 13.6067 19.79 14.6467 19.37 15.62C18.95 16.5933 18.38 17.44 17.66 18.16C16.94 18.88 16.0933 19.45 15.12 19.87C14.1467 20.29 13.1067 20.5 12 20.5Z"
        fill="#FF665B"
      />
    </g>
  </svg>
)
const Memo = memo(SvgDepositDownArrow)
export default Memo
