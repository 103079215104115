import Container from 'ui/Container/Container'

export const PrivacyPolicy = () => {
  return (
    <Container>
      <h2>PRIVACY POLICY</h2>
      <h3>Last updated April 20, 2023</h3>

      <p>
        This privacy notice for Juna (<b>'Company'</b>, <b>'we'</b>, <b>'us'</b>
        , or <b>'our'</b>), describes how and why we might collect, store, use,
        and/or share (<b>'process'</b>) your information when you use our
        services (<b>'Services'</b>), such as when you:
      </p>

      <ul>
        <li>
          Visit our website at <a href="http://juna.space">juna.space</a>, or
          any website of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>

      <p>
        <b>Questions or concerns?</b> Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at{' '}
        <a href="mailto:policy@juna.space">policy@juna.space</a> .
      </p>

      <h2>SUMMARY OF KEY POINTS</h2>
      <p>
        <b>
          <i>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </i>
        </b>
      </p>

      <p>
        <b>What personal information do we process?</b> When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with Juna and the Services, the choices you make, and
        the products and features you use. Learn more about personal information
        you disclose to us.
      </p>

      <p>
        <b>Do we process any sensitive personal information?</b> We do not
        process sensitive personal information.
      </p>

      <p>
        <b>Do we receive any information from third parties?</b> We do not
        receive any information from third parties.
      </p>

      <p>
        <b>How do we process your information?</b> We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about how we process your information.
      </p>

      <p>
        <b>
          In what situations and with which types of parties do we share
          personal information?
        </b>{' '}
        We may share information in specific situations and with specific
        categories of third parties. Learn more about when and with whom we
        share your personal information.
      </p>

      <p>
        <b>How do we keep your information safe?</b> We have organisational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorised third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about how we keep your information safe.
      </p>

      <p>
        <b>What are your rights?</b> Depending on where you are located
        geographically, the applicable privacy law may mean you have certain
        rights regarding your personal information. Learn more about your
        privacy rights.
      </p>

      <p>
        <b>How do you exercise your rights?</b> The easiest way to exercise your
        rights is by submitting a data subject access request, or by contacting
        us. We will consider and act upon any request in accordance with
        applicable data protection laws.
      </p>

      <p>
        Want to learn more about what Juna does with any information we collect?
        Review the privacy notice in full.
      </p>

      <h2>TABLE OF CONTENTS</h2>

      <ol>
        <li>WHAT INFORMATION DO WE COLLECT?</li>
        <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
        <li>
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
        </li>
        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
        <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        <li>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>
      </ol>

      <p>
        <b>
          1. WHAT INFORMATION DO WE COLLECT? Personal information you disclose
          to us
        </b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We collect personal information that you provide to
          us.
        </i>
      </p>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>

      <p>
        <b>Personal Information Provided by You.</b> The personal information
        that we collect depends on the context of your interactions with us and
        the Services, the choices you make, and the products and features you
        use. The personal information we collect may include the following:
      </p>

      <ul>
        <li>contact or authentication data.</li>
      </ul>

      <p>
        <b>Sensitive Information</b> We do not process sensitive information.
      </p>

      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <p>
        <b>Information automatically collected</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> Some information — such as your Internet Protocol
          (IP) address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
        </i>
      </p>

      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>

      <p>The information we collect includes:</p>

      <ul>
        <li>
          <i>Log and Usage Data.</i> Log and usage data is service-related,
          diagnostic, usage, and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type,
          and settings and information about your activity in the Services (such
          as the date/time stamps associated with your usage, pages and files
          viewed, searches, and other actions you take such as which features
          you use), device event information (such as system activity, error
          reports (sometimes called 'crash dumps'), and hardware settings).
        </li>
        <br />
        <li>
          <i>Device Data.</i> We collect device data such as information about
          your computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </li>
        <br />
        <li>
          <i>Location Data.</i> We collect location data such as information
          about your device's location, which can be either precise or
          imprecise. How much information we collect depends on the type and
          settings of the device you use to access the Services. For example, we
          may use GPS and other technologies to collect geolocation data that
          tells us your current location (based on your IP address). You can opt
          out of allowing us to collect this information either by refusing
          access to the information or by disabling your Location setting on
          your device. However, if you choose to opt out, you may not be able to
          use certain aspects of the Services.
        </li>
      </ul>

      <p>
        <b>2. HOW DO WE PROCESS YOUR INFORMATION?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </i>
      </p>

      <p>
        <b>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </b>
      </p>

      <ul>
        <li>
          <b>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </b>{' '}
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </li>
        <li>
          <b>To save or protect an individual's vital interest.</b> We may
          process your information when necessary to save or protect an
          individual’s vital interest, such as to prevent harm.
        </li>
      </ul>

      <p>
        <b>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We only process your personal information when we
          believe it is necessary and we have a valid legal reason (i.e. legal
          basis) to do so under applicable law, like with your consent, to
          comply with laws, to provide you with services to enter into or fulfil
          our contractual obligations, to protect your rights, or to fulfil our
          legitimate business interests.
        </i>
      </p>

      <p>
        <i>
          <b>
            <u>
              If you are located in the EU or UK, this section applies to you.
            </u>
          </b>
        </i>
      </p>

      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
      </p>

      <ul>
        <li>
          <b>Consent.</b> We may process your information if you have given us
          permission (i.e. consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time. Learn
          more about withdrawing your consent.
        </li>
        <br />
        <li>
          <b>Legal Obligations.</b> We may process your information where we
          believe it is necessary for compliance with our legal obligations,
          such as to cooperate with a law enforcement body or regulatory agency,
          exercise or defend our legal rights, or disclose your information as
          evidence in litigation in which we are involved.
        </li>
        <br />
        <li>
          <b>Vital Interests.</b> We may process your information where we
          believe it is necessary to protect your vital interests or the vital
          interests of a third party, such as situations involving potential
          threats to the safety of any person.
        </li>
      </ul>

      <p>
        <i>
          <b>
            <u>If you are located in Canada, this section applies to you.</u>
          </b>
        </i>
      </p>

      <p>
        We may process your information if you have given us specific permission
        (i.e. express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.
        implied consent). You can withdraw your consent at any time.
      </p>

      <p>
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including, for
        example:
      </p>

      <ul>
        <li>
          If collection is clearly in the interests of an individual and consent
          cannot be obtained in a timely way
        </li>
        <li>For investigations and fraud detection and prevention</li>
        <li>For business transactions provided certain conditions are met</li>
        <li>
          If it is contained in a witness statement and the collection is
          necessary to assess, process, or settle an insurance claim
        </li>
        <li>
          For identifying injured, ill, or deceased persons and communicating
          with next of kin
        </li>
        <li>
          If we have reasonable grounds to believe an individual has been, is,
          or may be victim of financial abuse
        </li>
        <li>
          If it is reasonable to expect collection and use with consent would
          compromise the availability or the accuracy of the information and the
          collection is reasonable for purposes related to investigating a
          breach of an agreement or a contravention of the laws of Canada or a
          province
        </li>
        <li>
          If disclosure is required to comply with a subpoena, warrant, court
          order, or rules of the court relating to the production of records
        </li>
        <li>
          If it was produced by an individual in the course of their employment,
          business, or profession and the collection is consistent with the
          purposes for which the information was produced
        </li>
        <li>
          If the collection is solely for journalistic, artistic, or literary
          purposes
        </li>
        <li>
          If the information is publicly available and is specified by the
          regulations
        </li>
      </ul>

      <p>
        <b>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We may share information in specific situations
          described in this section and/or with the following categories of
          third parties.
        </i>
      </p>

      <p>
        <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We
        may share your data with third-party vendors, service providers,
        contractors, or agents (<b>'third parties'</b>) who perform services for
        us or on our behalf and require access to such information to do that
        work. The categories of third parties we may share personal information
        with are as follows:
      </p>

      <ul>
        <li>Ad Networks</li>
        <li>Cloud Computing Services</li>
        <li>Data Analytics Services</li>
        <li>User Account Registration & Authentication Services</li>
        <li>Product Engineering & Design Tools</li>
        <li>Performance Monitoring Tools</li>
      </ul>

      <p>
        We also may need to share your personal information in the following
        situations:
      </p>

      <ul>
        <li>
          <b>Business Transfers.</b> We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
      </ul>

      <p>
        <b>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We may use cookies and other tracking technologies to
          collect and store your information.
        </i>
      </p>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>

      <p>
        <b>6. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We keep your information for as long as necessary to
          fulfil the purposes outlined in this privacy notice unless otherwise
          required by law.
        </i>
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than twelve (12)
        months past the termination of the user's account. When we have no
        ongoing legitimate business need to process your personal information,
        we will either delete or anonymise such information, or, if this is not
        possible (for example, because your personal information has been stored
        in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>

      <p>
        <b>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We aim to protect your personal information through a
          system of organisational and technical security measures.
        </i>
      </p>

      <p>
        We have implemented appropriate and reasonable technical and
        organisational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorised third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>

      <p>
        <b>8. DO WE COLLECT INFORMATION FROM MINORS?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> We do not knowingly collect data from or market to
          minors.
        </i>
      </p>

      <p>
        The website is not designed to use by the minor but does not have a
        facility to check the age, therefore, parental guidance is advised. No
        materials on the website can be harmful to minors.
      </p>

      <p>
        <b>9. WHAT ARE YOUR PRIVACY RIGHTS?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> In some regions, such as the European Economic Area
          (EEA), United Kingdom (UK), and Canada, you have rights that allow you
          greater access to and control over your personal information. You may
          review, change, or terminate your account at any time.
        </i>
      </p>

      <p>
        In some regions (like the EEA, UK, and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section 'HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
      </p>

      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>

      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your Member State data protection authority or UK data
        protection authority.
      </p>

      <p>
        If you are located in Switzerland, you may contact the Federal Data
        Protection and Information Commissioner.
      </p>

      <p>
        <b>Withdrawing your consent:</b> If we are relying on your consent to
        process your personal information, which may be express and/or implied
        consent depending on the applicable law, you have the right to withdraw
        your consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section 'HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?' below. However, please note that
        this will not affect the lawfulness of the processing before its
        withdrawal nor, when applicable law allows, will it affect the
        processing of your personal information conducted in reliance on lawful
        processing grounds other than consent.
      </p>

      <p>
        <b>Account Information</b>
      </p>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can: <br />
        &nbsp;Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>

      <p>
        <b>Cookies and similar technologies:</b> Most Web browsers are set to
        accept cookies by default. If you prefer, you can usually choose to set
        your browser to remove cookies and to reject cookies. If you choose to
        remove cookies or reject cookies, this could affect certain features or
        services of our Services. You may also opt out of interest-based
        advertising by advertisers on our Services.
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at <a href="mailto:policy@juna.space">policy@juna.space</a>.
      </p>

      <p>
        <b>10. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
      </p>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ('DNT') feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognising and implementing DNT signals
        has been finalised. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <p>
        <b>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> Yes, if you are a resident of California, you are
          granted specific rights regarding access to your personal information.
        </i>
      </p>

      <p>
        California Civil Code Section 1798.83, also known as the 'Shine The
        Light' law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g. backups, etc.).
      </p>

      <p>
        <b>12. DO WE MAKE UPDATES TO THIS NOTICE?</b>
      </p>

      <p>
        <i>
          <b>In Short:</b> Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </i>
      </p>

      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated 'Revised' date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <p>
        <b>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
      </p>

      <p>
        If you have questions or comments about this notice, you may email us at{' '}
        <a href="mailto:policy@juna.space">policy@juna.space</a>.
      </p>

      <p>
        <b>
          14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </b>
      </p>

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </p>
    </Container>
  )
}
