import { NavLink as UiNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Button as UiButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'
import { Select } from 'ui/Select/Select'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 24px;
    align-items: flex-start;

    ${media(size.tablet)} {
      flex-direction: column;
      justify-content:center;
    } 
`
)

export const Ul = styled.ul(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 24px;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    ${media(size.tablet)} {
      flex-direction: column;
      justify-content:center;
    } 
`
)

export const NavLink = styled(UiNavLink)`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
`

export const Li = styled.li<{ isActive?: boolean }>`
  width: 100%;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  padding: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.black};

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background-color: ${({ theme }) => theme.color.bg};
      `
    }
  }}

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.bg};
    color: ${({ theme }) => theme.color.black};
  }
`

export const MenuWrapper = styled(Card)(
  ({ theme: { media, size } }) => `
    display: flex;
    gap: 12px;
    flex-direction: column;
    min-width: 267px;
    padding: 12px;
    
    ${media(size.tablet)} {
      width:100%;
      flex-direction: row;
    }

    ${media(size.mobile)} {
      flex-direction: column;
    }
`
)

export const Button = styled(UiButton)`
  width: 100%;
`

export const CardsWrapper = styled(Card)`
  width: 100%;
  padding: 24px;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`

export const StyledSelect = styled(Select)`
  min-width: 224px;
`
export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`
