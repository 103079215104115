import { rgba } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ActionButton = styled.div<{ isReject?: boolean }>`
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid
    ${({ theme, isReject }) => (isReject ? theme.color.red : theme.color.line)};
  color: ${({ theme, isReject }) =>
    isReject ? theme.color.red : theme.color.black};
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export const RejectDropButton = styled.div<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => rgba(theme.color.red, isActive ? 1 : 0.5)};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 1px dashed
    ${({ theme, isActive }) => rgba(theme.color.red, isActive ? 1 : 0.5)};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.color.red};
    border-bottom: 1px dashed ${({ theme }) => theme.color.red};
  }
`
