import { FC } from 'react'
import { useField } from 'formik'
import { Textarea, TextareaProps } from 'ui/Textarea/Textarea'
import { FieldWrapper, Error } from '../components'

type TextAreaProps = {
  name: string
  className?: string
  error?: boolean
  placeholder?: string
  editorProps?: Omit<
    TextareaProps,
    'name' | 'onChange' | 'value' | 'placeholder'
  >
}

export const FormTextarea: FC<TextAreaProps> = (props) => {
  const { name, error, editorProps, className, placeholder } = props

  const [field, meta] = useField(name)

  return (
    <FieldWrapper className={className}>
      <Textarea
        {...field}
        {...editorProps}
        error={error || meta.error !== undefined}
        placeholder={placeholder}
      />
      <Error message={meta.error} />
    </FieldWrapper>
  )
}
