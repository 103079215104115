import { useMemo } from 'react'
import { Whoami } from 'api/omni/model'
import {
  selectPinSet,
  selectUserDataLoadStatus,
  selectUserData,
} from 'store/user/user.selectors'
import { fetchUserData } from 'store/user/user.slice'
import { RequestStatus } from 'types/app'
import { useDispatch } from '../useDispatch/useDispatch'
import { useSelector } from '../useSelector/useSelector'

type UseUserData = () => {
  fetchUserData: () => Promise<void>
  pinSet: boolean
  userDataLoadStatus: RequestStatus
  userData: Whoami | null
}

export const useUserData: UseUserData = () => {
  const dispatch = useDispatch()

  const pinSet = useSelector(selectPinSet)
  const userDataLoadStatus = useSelector(selectUserDataLoadStatus)
  const userData = useSelector(selectUserData)

  return useMemo(
    () => ({
      fetchUserData: async () => {
        await dispatch(fetchUserData())
      },
      pinSet,
      userDataLoadStatus,
      userData,
    }),
    [dispatch, userData, pinSet, userDataLoadStatus]
  )
}
