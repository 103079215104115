import styled from 'styled-components'
import { Chip as UIChip } from 'ui/Chip/Chip'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 12px;
`

export const ParticipantContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

export const ParticipantName = styled.div`
  width: 177px;

  color: ${({ theme }) => theme.color.gray};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  text-align: right;
`

export const Title = styled.h6`
  color: ${({ theme }) => theme.color.gray};
  text-align: right;
  font-size: 13px;
  font-weight: 400;

  margin: 12px 0;
  padding: 0;
`

export const Chip = styled(UIChip)`
  padding: 4px 10px;
  font-weight: 400;
  border-radius: 6px;
`
