import { DefaultTheme } from 'styled-components'

export const colourStyles = (
  theme: DefaultTheme,
  isDisabled?: boolean,
  isValid?: boolean
) => ({
  container: (styles: any) => ({
    ...styles,
    width: '100%',
    outline: 'none',
    border: `none`,
  }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: 12,
    backgroundColor: isDisabled ? theme.color.whisper : theme.color.white,
    border: `1px solid ${isValid ? theme.color.line : theme.color.red}`,
    outline: 'none',
    padding: '8px',
    fontSize: '15px',
    '&:hover': {
      borderColor: theme.color.gray,
    },
  }),
  option: (styles: any) => {
    return {
      ...styles,
    }
  },
  placeholder: (styles: any) => ({
    ...styles,
    color: theme.color.gray,
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
  }),
})
