import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.color.lightGray};
  padding-bottom: 24px;
`
