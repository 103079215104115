import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgTokenBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={17}
    viewBox="0 0 16 17"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="url(#paint0_linear_5339_584)"
      height={16}
      rx={8}
      width={16}
      y={0.5}
    />
    <path
      d="M7.23451 12.5545C5.98785 12.5545 5.11518 12.0521 4.61652 11.0472L5.96518 10.2765C6.21452 10.7752 6.63763 11.0245 7.23451 11.0245C7.64251 11.0245 7.94851 10.9301 8.15251 10.7412C8.36407 10.5447 8.46985 10.2727 8.46985 9.92519V4.46252H10.0225V9.92519C10.0225 10.7563 9.76185 11.4023 9.24051 11.8632C8.72674 12.3241 8.05807 12.5545 7.23451 12.5545Z"
      fill="white"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_5339_584"
        x1={8}
        x2={8}
        y1={0.5}
        y2={16.5}
      >
        <stop stopColor="#3A41EB" />
        <stop offset={1} stopColor="#D66FF6" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgTokenBlue)
export default Memo
