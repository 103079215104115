import styled from 'styled-components'
import { FormInput } from 'features/FormFileds'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  min-width: 100%;
`

export const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  width: 100%;
`

export const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  widhth
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.line};
`

export const AmountInputWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`

export const ShortenedFormInput = styled(FormInput)`
  max-width: 387px;
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 46px;
`

export const CountInUsdLabel = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '500')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
