import { useCallback, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useIsMutating, useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { api } from 'api'
import { AppRoutes } from 'constants/app'
import { useMatchMedia } from 'hooks'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import { ErrorTextBlock } from 'ui/ErrorTextBlock/ErrorTextBlock'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Stepper } from 'ui/Stepper/Stepper'
import {
  SignaturesStatus,
  ContractDetails,
  ContractForm,
  MilestoneWrapper,
} from './components'
import { FORM_ID, MUTATION_CONTRACT_SAVE_KEY } from './constants'
import { signaturesMock, detailsMock } from './mock'
import * as S from './style'

const ERROR_MESSAGE = 'Wrong template format'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Catalog',
    url: AppRoutes.Catalog,
  },
  {
    label: 'Create a contract',
    url: AppRoutes.ContractCreate,
  },
]

export const ContractCreate = () => {
  const [activeStep] = useState(1)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const createId = searchParams.get('createId')

  const { isMobile } = useMatchMedia()

  const { t } = useTranslation('pageContractCreate')

  const steps = useMemo(
    () => [
      {
        label: t('Creation'),
        step: 1,
      },
      {
        label: t('Signing'),
        step: 2,
      },
      {
        label: t('Deployment'),
        step: 3,
      },
      {
        label: t('Execution'),
        step: 4,
      },
    ],
    [t]
  )

  const { isLoading, error } = useQuery(
    ['contract', createId],
    () =>
      api.contracts.getContractById(Number(createId)).then((contract) => {
        if (contract.code === null) {
          throw new Error(ERROR_MESSAGE)
        }
        return contract
      }),
    {
      enabled: !!createId,
      staleTime: 1000,
      onError: (err: AxiosError) => {
        return notify.error({
          message: err.message,
          title: err.name,
        })
      },
    }
  )

  const handleSubmit = useCallback(
    (id: number) => navigate(`${AppRoutes.Contracts}/${id}`),
    [navigate]
  )

  const isContractSaving = Boolean(
    useIsMutating({ mutationKey: MUTATION_CONTRACT_SAVE_KEY })
  )

  if (isLoading) {
    return <Loader />
  }

  if (error instanceof Error) {
    return <ErrorTextBlock message={error.message} />
  }

  return (
    <PageWrapper
      actions={
        <Button
          form={FORM_ID}
          isLoading={isContractSaving}
          type="submit"
          variant="red"
        >
          {t('Sign')}
        </Button>
      }
      breadcrumbs={breadcrumbs}
      title={t('Create a Contract')}
    >
      <S.Wrapper>
        <S.LeftSideWrapper>
          <Stepper activeStep={activeStep} steps={steps} vertical={isMobile} />
          {activeStep === 1 && (
            <ContractForm createId={createId} onSubmit={handleSubmit} />
          )}
          {activeStep === 2 && (
            <ContractDetails
              description={detailsMock.description}
              details={detailsMock.details}
              legalGateDescription={detailsMock.legalGateDescription}
              legalGateDetails={detailsMock.legalGate}
              title={detailsMock.title}
            />
          )}
        </S.LeftSideWrapper>
        <S.RightSideWrapper>
          {activeStep !== 1 && <SignaturesStatus signatures={signaturesMock} />}
          <MilestoneWrapper createId={createId} />
        </S.RightSideWrapper>
      </S.Wrapper>
    </PageWrapper>
  )
}
