import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepsWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  justify-content: center;
  gap: 157px;
  align-items: end;

   ${media(size.mobile)} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  `
)

export const Step = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  flex-direction: column;

  ${media(size.tablet)} {
    margin-bottom: 60px;
  }
  `
)

export const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.line};
  margin: 0 auto;
`

export const Label = styled.p`
  margin: 32px 0 8px 0;
  padding: 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
`

export const Description = styled.p`
  margin: 0 auto;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.gray};
  max-width: 240px;
  text-align: center;
`

export const Title = styled.h2(
  ({ theme: { media, size, color } }) => `
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  color: ${color.black};
  margin: 0;
  padding: 0;
  text-align: center;

  ${media(size.mobile)} {
    padding: 0 16px;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;
  }
`
)
