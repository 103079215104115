import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 105px;
  padding-bottom: 133px;
`

export const Label = styled.p`
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0;
  max-width: 207px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.color.black};
`
