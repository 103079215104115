import styled from 'styled-components'
import { Input } from 'ui/Input/Input'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const AmountInput = styled(Input)`
  width: 102px;

  input {
    height: 46px;
  }
`
