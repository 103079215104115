import styled from 'styled-components'
import { Input } from 'ui/Input/Input'
import { Select as UISelect } from 'ui/Select/Select'

export const Wrapper = styled.div`
  display: flex;
`

export const SearchInput = styled(Input)(
  ({ theme: { media, size } }) => `
    width: 267px;
    height: 46px;
    box-shadow: none;

  ${media(size.mobile)} {
    max-width: 200px;
  }
`
)

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const SortSelect = styled(UISelect)`
  .react-select__indicator {
    padding: 0;
  }

  .react-select__control {
    padding: 0 8px;
    font-size: 15px;
    width: max-content;
    height: 46px;
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.color.gray};
  }
` as typeof UISelect
