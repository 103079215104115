import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { usePlatformLegalgateRetrieve } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { useClipboard, useLegalGateBasket } from 'hooks'
import { notify } from 'libs/notify/notify'
import { QrModal } from 'modals/QrModal'
import { Button } from 'ui/Button/Button'
import { Loader } from 'ui/Loader/Loader'
import { PageTitle, PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { shortenAddress } from 'utils/shortenAddress'
import { Reviews } from './components'
import * as S from './style'

export const LegalGate: FC = () => {
  const [isQrModalVisible, setIsQrModalVisible] = useState<boolean>(false)

  const { t } = useTranslation('pageLegalGate')

  const { selectLegalGate } = useLegalGateBasket()
  const copyToClipboard = useClipboard()

  const { legalGateId } = useParams()

  const { data, isLoading, isSuccess } = usePlatformLegalgateRetrieve(
    Number(legalGateId),
    {
      query: {
        enabled: !!legalGateId,
      },
    }
  )

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Catalog Legal Gate',
        url: AppRoutes.LegalGatesCatalog,
      },
    ]

    if (data) {
      return [
        ...initialBreadcrumbs,
        {
          label: data.name,
          url: `${AppRoutes.LegalGatesCatalog}/${legalGateId}`,
        },
      ]
    }

    return initialBreadcrumbs
  }, [data, legalGateId])

  const handleCopyClick = async () => {
    if (!data) {
      return
    }

    copyToClipboard(data.contracts[0].address, () =>
      notify.success({
        title: data.name,
        message: t('Address has been copied'),
      })
    )
  }

  const handleSelectClick = useCallback(() => {
    if (!data) {
      return
    }

    selectLegalGate({
      id: Number(legalGateId),
      name: data.name,
      rating: data.rating!,
      address: data.contracts[0].address,
      description: data.description!,
      price: data.price!,
      contracts: data.contracts,
    })

    notify.success({
      title: data.name,
      message: t('Legal Gate has been selected as default'),
    })
  }, [data, legalGateId, selectLegalGate, t])

  const toggleQrModal = useCallback(() => {
    setIsQrModalVisible((prev) => !prev)
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <>
        <PageWrapper
          actions={
            <>
              <Button onClick={toggleQrModal} variant="primary">
                {t('Show me QR')}
              </Button>
              <Button onClick={handleCopyClick} variant="primary">
                {t('Copy address')}
              </Button>
              <Button onClick={handleSelectClick} variant="red">
                {t('Add to cart')}
              </Button>
            </>
          }
          breadcrumbs={breadcrumbs}
          title={
            <S.PageTitleWrapper>
              <PageTitle>{data.name}</PageTitle>
              <S.SubTitle>{t('Details about Legal oracle')}</S.SubTitle>
            </S.PageTitleWrapper>
          }
        >
          <S.FlexWrapper>
            <S.Wrapper>
              <S.Card>
                <S.Title>{t('Description')}</S.Title>
                <S.Description>{data.description}</S.Description>
              </S.Card>
              <Reviews legalGateId={Number(legalGateId)} />
            </S.Wrapper>
            <S.InfoCardWrapper>
              <S.InfoCard>
                <S.Title>{t('Information')}</S.Title>
                <S.Adresses>
                  {data.contracts.map(({ address, chainId }) => (
                    <div key={chainId}>
                      <S.InfoTitle>{Chains[chainId].name}</S.InfoTitle>
                      <S.UserAddress>{shortenAddress(address)}</S.UserAddress>
                    </div>
                  ))}
                </S.Adresses>
                <S.PriceWrapper>
                  <div>{t('Price')}</div>
                  <S.DashedLine />
                  <S.Price>{data.price} JUNA</S.Price>
                </S.PriceWrapper>
              </S.InfoCard>
              <S.InfoCard>
                <S.Title>{t('Jurisdictions')}</S.Title>
                <S.Jutisdictions>
                  {data.jurisdictions.map(({ id, name }) => (
                    <div key={id}>
                      <S.InfoTitle>{name}</S.InfoTitle>
                    </div>
                  ))}
                </S.Jutisdictions>
              </S.InfoCard>
            </S.InfoCardWrapper>
          </S.FlexWrapper>
        </PageWrapper>
        {isQrModalVisible && (
          <QrModal
            onClose={toggleQrModal}
            qrValue={data.contracts[0].address}
          />
        )}
      </>
    )
  }

  return null
}
