import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={17}
    viewBox="0 0 16 17"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.29289 5.79289C3.68342 5.40237 4.31658 5.40237 4.70711 5.79289L8 9.08579L11.2929 5.79289C11.6834 5.40237 12.3166 5.40237 12.7071 5.79289C13.0976 6.18342 13.0976 6.81658 12.7071 7.20711L8.70711 11.2071C8.31658 11.5976 7.68342 11.5976 7.29289 11.2071L3.29289 7.20711C2.90237 6.81658 2.90237 6.18342 3.29289 5.79289Z"
      fill="#1B1F3B"
      fillOpacity={0.2}
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgChevronDown)
export default Memo
