import { FC } from 'react'
import { SvgLogo } from 'ui/icons'
import * as S from './style'

export const Loader: FC = () => {
  return (
    <S.Wrapper>
      <S.SvgBars height="60" viewBox="0 0 100 60" width="135">
        <rect fill="#df63ca" height="60" rx="6" width="10" x="0" y="0">
          <animate
            attributeName="height"
            attributeType="CSS"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
            values="60;20;60;"
          />
          <animate
            attributeName="y"
            attributeType="CSS"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
            values="0;20;0;"
          />
        </rect>
        <rect fill="#f75a6a" height="60" rx="6" width="10" x="20" y="0">
          <animate
            attributeName="height"
            attributeType="CSS"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
            values="60;20;60"
          />
          <animate
            attributeName="y"
            attributeType="CSS"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
            values="0;20;0"
          />
        </rect>
        <rect fill="#ffbba3" height="60" rx="6" width="10" x="40" y="0">
          <animate
            attributeName="height"
            attributeType="CSS"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
            values="60;20;60"
          />
          <animate
            attributeName="y"
            attributeType="CSS"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
            values="0;20;0"
          />
        </rect>
        <rect fill="#df63ca" height="60" rx="6" width="10" x="60" y="0">
          <animate
            attributeName="height"
            attributeType="CSS"
            begin="0.6s"
            dur="1s"
            repeatCount="indefinite"
            values="60;20;60"
          />
          <animate
            attributeName="y"
            attributeType="CSS"
            begin="0.6s"
            dur="1s"
            repeatCount="indefinite"
            values="0;20;0"
          />
        </rect>
      </S.SvgBars>
      <SvgLogo />
    </S.Wrapper>
  )
}
