import * as S from './style'

type StatCardProps = {
  logo: JSX.Element
  amount: string
  label: string
}

export const StatCard = (props: StatCardProps) => {
  const { amount, label, logo } = props

  return (
    <S.StatCardContainer>
      <S.StatAmountContainer>
        {logo}
        <S.StatAmount>{amount}</S.StatAmount>
      </S.StatAmountContainer>
      <S.StatLabel>{label}</S.StatLabel>
    </S.StatCardContainer>
  )
}
