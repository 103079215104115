import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 24px;
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-bottom 48px;
`

export const ContractField = styled.div`
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.bg};
    border-radius: 12px;
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const ContractName = styled.div`
  font-size: 15px;
`

export const Address = styled.div`
  font-size: 12px;
`

export const FunctionName = styled.div`
  font-size: 15px;
  font-weight: 700;
`
