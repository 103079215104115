import styled, { DefaultTheme } from 'styled-components'

const getTitleColor = (
  isAccentItem: boolean | undefined,
  isAccentMode: boolean | undefined,
  theme: DefaultTheme
) => {
  switch (true) {
    case isAccentItem:
      return theme.color.red
    case isAccentMode:
      return theme.color.gray
    default:
      return theme.color.black
  }
}

export const BulletWrapper = styled.div`
  display: flex;
`

export const BulletItem = styled.div<{
  isAccentMode?: boolean
  isAccentItem?: boolean
}>`
  width: 10px;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    border: 2px solid
      ${({ theme, isAccentMode, isAccentItem }) =>
        isAccentItem
          ? theme.color.red
          : isAccentMode
          ? theme.color.line
          : theme.color.red};
    background-color: ${({ theme, isAccentItem }) =>
      isAccentItem ? theme.color.red : theme.color.white};
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    display: block;
    top: 4px;
  }

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.color.line};
    position: relative;
    display: block;
    top: 4px;
    left: 4.5px;
    bottom: 10px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;

  &:last-child {
    ${BulletItem} {
      &:after {
        display: none;
      }
    }
  }
`

export const Title = styled.h6<{
  $withLink?: boolean
  $isAccentItem?: boolean
  $isAccentMode?: boolean
  $withoutUnderLine?: boolean
}>`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, $isAccentItem, $isAccentMode }) =>
    getTitleColor($isAccentItem, $isAccentMode, theme)};
  text-decoration: none;
  border-bottom: ${({ $isAccentItem, $withLink, theme, $withoutUnderLine }) =>
    $isAccentItem &&
    $withLink &&
    !$withoutUnderLine &&
    `1px dashed ${theme.color.red}`};
`

export const Content = styled.div`
  margin-left: 22px;
`

export const Details = styled.div`
  color: ${({ theme }) => theme.color.gray};
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const DetailItem = styled.p`
  display: inline-block;
  font-size: 14px;
  margin-block: 2px 0;
  &:before {
    content: '•';
    margin-right: 8px;
  }
`
