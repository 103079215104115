import styled from 'styled-components'

export const StatCardContainer = styled.div`
  padding: 16px 18px;
  width: 270px;
  height: 78px;

  background-color: #edf5fd;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
`

export const StatAmountContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 6px;
`

export const StatAmount = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.black};
  font-size: 20px;
  font-weight: 800;
`

export const StatLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
`
