import styled, { css } from 'styled-components'
import { Spinner as UISpinner } from '../Spinner/Spinner'
import { VariantType } from './types'

type DefaultButtonType = {
  variant: VariantType
  disabled: boolean
}

export const DefaultButton = styled.button<DefaultButtonType>`
  font-size: 15px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 18px 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.red};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: ${({ theme }) => theme.color.red};
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${theme.color.white};
          border: 1px solid ${theme.color.red};
          color: inherit;
          &:hover {
            background-color: ${theme.color.red};
            color: ${theme.color.white};
          }
          &:disabled {
            border: 1px solid ${theme.color.line};
            color: ${theme.color.line};
            cursor: not-allowed;
            &:hover {
              background-color: ${theme.color.white};
            }
          }
        `
      case 'gray':
        return css`
          background-color: ${theme.color.whisper};
          border: none;
          color: ${theme.color.black};
          &:hover {
            background-color: ${theme.color.line};
            color: ${theme.color.black};
          }
        `
      case 'red':
        return css`
          background-color: ${theme.color.red};
          border: none;
          color: ${theme.color.white};
          &:hover {
            background-color: ${theme.color.valencia};
            color: ${theme.color.white};
          }
          &:disabled {
            border: none;
            color: ${theme.color.black};
            background-color: ${theme.color.line};
            font-weight: 700;
            cursor: not-allowed;
          }
        `
      case 'transparent':
        return css`
          background-color: ${theme.color.white};
          border: none;
          padding: 12px;
          color: ${theme.color.black};
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${theme.color.bg};
            color: ${theme.color.black};
          }
        `
      case 'white':
        return css`
          background-color: ${theme.color.white};
          border: 1px solid ${theme.color.line};
          padding: 10px 16px;
          color: ${theme.color.black};
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${theme.color.bg};
            color: ${theme.color.black};
          }
        `
    }
  }}
`

export const FlexWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Spinner = styled(UISpinner)`
  .path {
    stroke: ${({ theme }) => theme.color.line};
  }
`
