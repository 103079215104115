import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/app'
import * as S from './style'

export type CatalogCardProps = {
  title: string
  description: string
  contractId: string
  countReferences?: number
}

export const CatalogCard: FC<CatalogCardProps> = (props: CatalogCardProps) => {
  const { title, description, contractId, countReferences } = props

  const { t } = useTranslation('pageCatalog')

  const navigate = useNavigate()

  const handleSelectBtnClick = () => {
    navigate(`${AppRoutes.AtomContract}/${contractId}`)
  }

  return (
    <S.StyledCard onClick={handleSelectBtnClick}>
      <S.TopBlock>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.TopBlock>
      <S.BottomBlock>
        <S.UsedCountWrapper>
          <S.Label>{t('Used')}</S.Label>
          <S.UsedCount>{countReferences} times</S.UsedCount>
        </S.UsedCountWrapper>
      </S.BottomBlock>
    </S.StyledCard>
  )
}
