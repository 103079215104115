import styled, { css } from 'styled-components'
import { Button } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`

export const LabelButton = styled.span`
  max-width: 0;
  overflow: hidden;
  margin-left: -8px;
`

export const FocusIconButton = styled(Button)<{ error?: boolean }>`
  width: auto;
  padding: 8px;
  white-space: nowrap;
  display: flex;

  border: ${({ error, theme }) => error && `1px solid ${theme.color.red}`};

  ${({ disabled }) => {
    if (disabled) {
      return css`
        background: ${({ theme }) => theme.color.lightGray};
        position: absolute;
        right: 16px;

        &:hover {
          cursor: default;
        }
      `
    } else {
      return css`
        &:hover ${LabelButton} {
          max-width: 300px;
          margin-left: 16px;
        }
      `
    }
  }};
`
