import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgStatsRemaining = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="white" height={24} rx={8} width={24} />
    <mask
      height={16}
      id="mask0_6437_19121"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={16}
      x={4}
      y={4}
    >
      <rect fill="#D9D9D9" height={16} width={16} x={4} y={4} />
    </mask>
    <g mask="url(#mask0_6437_19121)">
      <path
        d="M7.33333 18C6.96667 18 6.65278 17.8694 6.39167 17.6083C6.13056 17.3472 6 17.0333 6 16.6667V7.33333C6 6.96667 6.13056 6.65278 6.39167 6.39167C6.65278 6.13056 6.96667 6 7.33333 6H16.6667C17.0333 6 17.3472 6.13056 17.6083 6.39167C17.8694 6.65278 18 6.96667 18 7.33333H12.6667C11.8778 7.33333 11.2361 7.58056 10.7417 8.075C10.2472 8.56944 10 9.21111 10 10V14C10 14.7889 10.2472 15.4306 10.7417 15.925C11.2361 16.4194 11.8778 16.6667 12.6667 16.6667H18C18 17.0333 17.8694 17.3472 17.6083 17.6083C17.3472 17.8694 17.0333 18 16.6667 18H7.33333ZM12.6667 15.3333C12.3 15.3333 11.9861 15.2028 11.725 14.9417C11.4639 14.6806 11.3333 14.3667 11.3333 14V10C11.3333 9.63333 11.4639 9.31944 11.725 9.05833C11.9861 8.79722 12.3 8.66667 12.6667 8.66667H17.3333C17.7 8.66667 18.0139 8.79722 18.275 9.05833C18.5361 9.31944 18.6667 9.63333 18.6667 10V14C18.6667 14.3667 18.5361 14.6806 18.275 14.9417C18.0139 15.2028 17.7 15.3333 17.3333 15.3333H12.6667ZM14.6667 13C14.9556 13 15.1944 12.9056 15.3833 12.7167C15.5722 12.5278 15.6667 12.2889 15.6667 12C15.6667 11.7111 15.5722 11.4722 15.3833 11.2833C15.1944 11.0944 14.9556 11 14.6667 11C14.3778 11 14.1389 11.0944 13.95 11.2833C13.7611 11.4722 13.6667 11.7111 13.6667 12C13.6667 12.2889 13.7611 12.5278 13.95 12.7167C14.1389 12.9056 14.3778 13 14.6667 13Z"
        fill="#4D9BFF"
      />
    </g>
  </svg>
)
const Memo = memo(SvgStatsRemaining)
export default Memo
