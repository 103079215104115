export const CONTRACTS_BASE_URL = 'https://marketplace-dev-api.junona.net/'

export const OMNI_BASE_URL = 'https://dev-api.junona.net/'

export const OPENAI_BASE_URL = 'https://api.openai.com/v1/'

export const ApiOpenAIRoutes = {
  Completions: '/completions',
} as const

export const ApiContractsRoutes = {
  CategoryList: '/api/contracts/category/',
  ContractList: '/api/contracts/contract/',
} as const
