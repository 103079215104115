import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'
import { SvgChevronDown } from 'ui/icons'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
`

export const ChevronDown = styled(SvgChevronDown)``

export const ChevronUp = styled(SvgChevronDown)`
  transform: rotate(180deg);
`

export const HintContainer = styled(Tooltip)`
  text-transform: none;
  max-width: 250px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  img {
    margin-bottom: 6px;
  }
`
