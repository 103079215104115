import { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import {
  useAtomAtomContractDestroy,
  useAtomWorkspaceList,
  useAtomAtomContractList,
  useAtomWorkspaceAmountStatsRetrieve,
} from 'api/omni/endpoints'
import { Workspace, WorkspaceAmountStats } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { useDebounce } from 'hooks'
import { notify } from 'libs/notify/notify'
import {
  SvgStatsJunaLogo,
  SvgStatsPaid,
  SvgStatsReceived,
  SvgStatsRemainder,
  SvgStatsRemaining,
} from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import { formatRole } from 'utils/formatRole'
import { formatSigs } from 'utils/formatSigs'
import { ContractCard, SearchBlock, WorkspacesList } from './components'
import { StatCard } from './components/StatCard/StatCard'
import * as S from './style'
import { type SearchFiltersType } from './types'

const ITEMS_ON_PAGE = 12

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Contracts',
    url: AppRoutes.Contracts,
  },
]

export const Contracts = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(
    null
  )

  const [searchFilters, setSearchFilters] = useState<SearchFiltersType>({
    search: '',
    member: '',
    legalGate: '',
    status: null,
  })

  const debouncedSearchFilters = useDebounce(searchFilters)

  const { t } = useTranslation('pageContracts')

  const { address } = useAccount()

  const {
    data: atomContracts,
    isRefetching: isAtomContractsRefetching,
    isSuccess: isAtomContractsLoaded,
    refetch,
  } = useAtomAtomContractList(
    {
      search: debouncedSearchFilters.search,
      members: [
        debouncedSearchFilters.member,
        debouncedSearchFilters.legalGate,
      ],
      status: searchFilters.status || undefined,
      workspaces: [currentWorkspace?.id || ''],
    },
    {
      query: {
        keepPreviousData: true,
        onError: (error) => {
          console.log(error)
        },
      },
    }
  )

  const {
    data: workspaces,
    isLoading: isWorkspacesLoading,
    isSuccess: isWorkspacesLoaded,
  } = useAtomWorkspaceList({
    query: {
      keepPreviousData: true,
    },
  })

  const {
    data: currentWorkspaceAmountStats,
    isLoading: isCurrentWorkspaceAmountStatsLoading,
  } = useAtomWorkspaceAmountStatsRetrieve(currentWorkspace?.id || '', {
    query: {
      enabled: Boolean(currentWorkspace?.id),
      select: useCallback(
        (amountStatsData: WorkspaceAmountStats) => {
          const { totalAmount, receivedJuna, remainingAmount, sentJuna } =
            amountStatsData

          return [
            {
              logo: <SvgStatsJunaLogo />,
              label: t('Total liabilty'),
              amount: formatNumberWithSeparators(totalAmount),
            },
            {
              logo: <SvgStatsReceived />,
              label: t('Received funds'),
              amount: formatNumberWithSeparators(receivedJuna),
            },
            {
              logo: <SvgStatsRemaining />,
              label: t('Remaining balance'),
              amount: formatNumberWithSeparators(totalAmount - remainingAmount),
            },
            {
              logo: <SvgStatsPaid />,
              label: t('Paid to counter party'),
              amount: formatNumberWithSeparators(sentJuna),
            },
            {
              logo: <SvgStatsRemainder />,
              label: t('Remainder to pay'),
              amount: formatNumberWithSeparators(remainingAmount),
            },
          ]
        },
        [t]
      ),
    },
  })

  const { mutate: deleteContract } = useAtomAtomContractDestroy({
    mutation: {
      onSuccess: () => {
        refetch()
        notify.success({
          message: t('Contract successfully deleted'),
          title: t('Contract deleted'),
        })
      },
    },
  })

  const handleRemoveClick = useCallback(
    (id: string) => {
      deleteContract({ id })
    },
    [deleteContract]
  )

  const totalPages = useMemo(() => {
    if (!atomContracts) {
      return
    }

    return Math.ceil(atomContracts?.results.length / ITEMS_ON_PAGE)
  }, [atomContracts])

  if (isWorkspacesLoading) {
    return <Loader />
  }

  if (isWorkspacesLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Contracts list')}>
        <S.Wrapper>
          <WorkspacesList
            activeWorkspace={currentWorkspace}
            onClick={setCurrentWorkspace}
            workspaces={workspaces}
          />
          <S.ContentWrapper>
            <SearchBlock
              searchFilters={searchFilters}
              updateSearchFilters={setSearchFilters}
            />
            {isAtomContractsRefetching ||
            isCurrentWorkspaceAmountStatsLoading ? (
              <Loader />
            ) : (
              <>
                {currentWorkspace && currentWorkspaceAmountStats ? (
                  <S.StatsContainer>
                    {currentWorkspaceAmountStats.map((stat) => (
                      <StatCard
                        key={stat.label}
                        amount={stat.amount}
                        label={stat.label}
                        logo={stat.logo}
                      />
                    ))}
                  </S.StatsContainer>
                ) : null}
                <S.ContractsWrapper>
                  {atomContracts && atomContracts.results.length > 0 ? (
                    atomContracts.results.map((contract) => (
                      <ContractCard
                        key={contract.id}
                        fieldsData={[
                          {
                            label: t('Created'),
                            value: new Date(contract.createdAt).toUTCString(),
                          },
                          {
                            label: t('Signatures'),
                            value: formatSigs(contract),
                          },
                          {
                            label: t('Network'),
                            value: Chains[contract.chainId].name,
                          },
                          {
                            label: t('Roles'),
                            value: formatRole(contract, address!),
                          },
                          {
                            label: t('Type'),
                            value: contract.type!,
                          },
                          {
                            label: t('Total liabilty'),
                            value: formatNumberWithSeparators(
                              contract.totalAmount
                            ),
                          },
                          {
                            label: t('Received funds'),
                            value: formatNumberWithSeparators(
                              contract.receivedJuna
                            ),
                          },
                          {
                            label: t('Remaining balance'),
                            value: formatNumberWithSeparators(
                              contract.totalAmount - contract.remainingAmount
                            ),
                          },
                          {
                            label: t('Paid to counter party'),
                            value: formatNumberWithSeparators(
                              contract.sentJuna
                            ),
                          },
                          {
                            label: t('Remainder to pay'),
                            value: formatNumberWithSeparators(
                              contract.remainingAmount
                            ),
                          },
                        ]}
                        id={contract.id}
                        isInitiator={true}
                        onRemove={handleRemoveClick}
                        status={contract.status}
                        title={contract.name!}
                        type={contract.type!}
                      />
                    ))
                  ) : (
                    <S.NoContractsWrapper>
                      <S.Label>No contracts</S.Label>
                    </S.NoContractsWrapper>
                  )}
                </S.ContractsWrapper>
              </>
            )}

            {isAtomContractsLoaded &&
              totalPages !== undefined &&
              totalPages > 1 && (
                <S.PaginationWrapper>
                  <S.TotalItemsLabel>
                    {t('Total items', { count: atomContracts.results.length })}
                  </S.TotalItemsLabel>
                  <Pagination
                    currentPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    totalPages={totalPages!}
                  />
                </S.PaginationWrapper>
              )}
          </S.ContentWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
