import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgActionRequired = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={48}
    viewBox="0 0 48 48"
    width={48}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#FFBE40" fillOpacity={0.2} height={48} rx={24} width={48} />
    <path
      clipRule="evenodd"
      d="M13 24C13 17.9247 17.9247 13 24 13C30.0753 13 35 17.9247 35 24C35 30.0753 30.0753 35 24 35C17.9247 35 13 30.0753 13 24ZM24 15C19.0293 15 15 19.0293 15 24C15 28.9707 19.0293 33 24 33C28.9707 33 33 28.9707 33 24C33 19.0293 28.9707 15 24 15ZM24 19C24.5523 19 25 19.4477 25 20V24C25 24.5523 24.5523 25 24 25C23.4477 25 23 24.5523 23 24V20C23 19.4477 23.4477 19 24 19ZM23 28C23 27.4477 23.4477 27 24 27H24.01C24.5623 27 25.01 27.4477 25.01 28C25.01 28.5523 24.5623 29 24.01 29H24C23.4477 29 23 28.5523 23 28Z"
      fill="#FFBE40"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgActionRequired)
export default Memo
