import { FC } from 'react'
import { AppRoutes } from 'constants/app'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { CreditReserveForm, InfoBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Credit',
    url: '',
  },
]

export const CreditReserve: FC = () => {
  return (
    <PageWrapper breadcrumbs={breadcrumbs} title="Credit from reserve system">
      <S.Wrapper>
        <CreditReserveForm amount={5000} />
        <InfoBlock amount={23000} />
      </S.Wrapper>
    </PageWrapper>
  )
}
