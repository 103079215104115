import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type InfoCardProps = {
  bankName: string
}

export const InfoCard: FC<InfoCardProps> = (props) => {
  const { bankName } = props

  const { t } = useTranslation('pageBankDashboard')

  return (
    <S.InfoCardWrapper>
      <S.InfoCardTitle>{t('Basic info')}</S.InfoCardTitle>
      <S.InfoCardContent>
        <S.InfoCardText>
          <S.InfoCardTextHighlighted>{t('Name')}: </S.InfoCardTextHighlighted>
          {bankName}
        </S.InfoCardText>
        <S.InfoCardText>
          <S.InfoCardTextHighlighted>
            {t('Bank opened')}:{' '}
          </S.InfoCardTextHighlighted>{' '}
          04 Sep 2023
        </S.InfoCardText>
      </S.InfoCardContent>
    </S.InfoCardWrapper>
  )
}
