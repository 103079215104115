import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 117 40"
    width={117}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      fill="url(#paint0_linear_1945_77168)"
      rx={17.0581}
      ry={8.53171}
      transform="matrix(0.865885 0.500242 -0.499758 0.866165 19.0339 19.9951)"
    />
    <ellipse
      fill="url(#paint1_linear_1945_77168)"
      rx={17.0636}
      ry={8.52896}
      transform="matrix(-0.499758 0.866165 -0.865885 -0.500242 19.0892 20.0002)"
    />
    <path
      d="M56.815 29.7119C54.8292 29.7119 53.082 29.282 51.5736 28.4221C50.0842 27.5432 48.9194 26.3204 48.0793 24.7536C47.2582 23.1869 46.8477 21.3622 46.8477 19.2795C46.8477 17.6936 47.0768 16.2702 47.5351 15.0091C48.0124 13.729 48.6903 12.6399 49.5686 11.7418C50.447 10.8247 51.4972 10.1177 52.7192 9.62097C53.9604 9.12419 55.3256 8.8758 56.815 8.8758C58.839 8.8758 60.5957 9.30571 62.0851 10.1655C63.5745 11.0253 64.7297 12.2386 65.5508 13.8054C66.3909 15.353 66.811 17.1682 66.811 19.2508C66.811 20.8367 66.5723 22.2697 66.095 23.5499C65.6176 24.8301 64.9397 25.9287 64.0614 26.8458C63.183 27.763 62.1328 28.4699 60.9108 28.9667C59.6887 29.4635 58.3235 29.7119 56.815 29.7119ZM56.815 25.9287C57.9416 25.9287 58.8963 25.6708 59.6792 25.1549C60.4812 24.6199 61.0922 23.8556 61.5123 22.862C61.9514 21.8494 62.171 20.6552 62.171 19.2795C62.171 17.1777 61.7032 15.5537 60.7676 14.4072C59.8319 13.2417 58.5144 12.659 56.815 12.659C55.7075 12.659 54.7528 12.9169 53.9508 13.4328C53.1489 13.9487 52.5378 14.7034 52.1178 15.697C51.6977 16.6905 51.4876 17.8847 51.4876 19.2795C51.4876 21.3622 51.9554 22.9958 52.8911 24.1804C53.8267 25.3459 55.1347 25.9287 56.815 25.9287Z"
      fill="#372D4C"
    />
    <path
      d="M72.1068 29.6545C71.4003 29.6545 70.8561 29.4635 70.4742 29.0813C70.1114 28.6992 69.93 28.1546 69.93 27.4477V17.2733C69.93 16.5663 70.1114 16.0313 70.4742 15.6683C70.8561 15.2862 71.3812 15.0951 72.0495 15.0951C72.7369 15.0951 73.262 15.2862 73.6248 15.6683C73.9876 16.0313 74.169 16.5663 74.169 17.2733V19.1075L73.854 17.9611C74.2359 17.0822 74.8278 16.3753 75.6298 15.8403C76.4317 15.3053 77.3769 15.0378 78.4653 15.0378C79.5537 15.0378 80.4607 15.2957 81.1863 15.8116C81.9119 16.3275 82.4274 17.1204 82.7329 18.1904H82.332C82.752 17.216 83.3917 16.4517 84.251 15.8976C85.1293 15.3244 86.1318 15.0378 87.2583 15.0378C88.3467 15.0378 89.2346 15.2575 89.922 15.697C90.6285 16.1173 91.1536 16.7669 91.4973 17.6459C91.841 18.5057 92.0129 19.6043 92.0129 20.9418V27.4477C92.0129 28.1546 91.8219 28.6992 91.4401 29.0813C91.0773 29.4635 90.5522 29.6545 89.8648 29.6545C89.1583 29.6545 88.6141 29.4635 88.2322 29.0813C87.8694 28.6992 87.688 28.1546 87.688 27.4477V21.0851C87.688 20.1489 87.5352 19.4706 87.2297 19.0502C86.9433 18.6108 86.4468 18.391 85.7403 18.391C84.9193 18.391 84.2796 18.6776 83.8213 19.2508C83.3631 19.824 83.1339 20.6361 83.1339 21.687V27.4477C83.1339 28.1546 82.943 28.6992 82.5611 29.0813C82.1983 29.4635 81.6732 29.6545 80.9858 29.6545C80.2984 29.6545 79.7637 29.4635 79.3818 29.0813C79 28.6992 78.809 28.1546 78.809 27.4477V21.0851C78.809 20.1489 78.6563 19.4706 78.3507 19.0502C78.0643 18.6108 77.5679 18.391 76.8614 18.391C76.0403 18.391 75.4006 18.6776 74.9424 19.2508C74.4841 19.824 74.255 20.6361 74.255 21.687V27.4477C74.255 28.9189 73.5389 29.6545 72.1068 29.6545Z"
      fill="#372D4C"
    />
    <path
      d="M97.5027 29.6545C96.7962 29.6545 96.252 29.4635 95.8701 29.0813C95.5073 28.6992 95.3259 28.1546 95.3259 27.4477V17.2733C95.3259 16.5663 95.5073 16.0313 95.8701 15.6683C96.252 15.2862 96.7771 15.0951 97.4454 15.0951C98.1328 15.0951 98.6579 15.2862 99.0207 15.6683C99.3835 16.0313 99.5649 16.5663 99.5649 17.2733V18.9069L99.2499 17.9611C99.689 17.0249 100.338 16.3084 101.198 15.8116C102.076 15.2957 103.069 15.0378 104.176 15.0378C105.303 15.0378 106.229 15.2575 106.955 15.697C107.68 16.1173 108.224 16.7669 108.587 17.6459C108.95 18.5057 109.131 19.6043 109.131 20.9418V27.4477C109.131 28.1546 108.94 28.6992 108.558 29.0813C108.196 29.4635 107.661 29.6545 106.955 29.6545C106.267 29.6545 105.732 29.4635 105.351 29.0813C104.988 28.6992 104.806 28.1546 104.806 27.4477V21.1424C104.806 20.168 104.625 19.4706 104.262 19.0502C103.918 18.6108 103.384 18.391 102.658 18.391C101.742 18.391 101.007 18.6776 100.453 19.2508C99.9182 19.824 99.6509 20.5883 99.6509 21.5437V27.4477C99.6509 28.9189 98.9348 29.6545 97.5027 29.6545Z"
      fill="#372D4C"
    />
    <path
      d="M114.537 29.6259C113.83 29.6259 113.286 29.4253 112.904 29.024C112.541 28.6037 112.36 28.0209 112.36 27.2757V17.4739C112.36 16.7096 112.541 16.1269 112.904 15.7256C113.286 15.3053 113.83 15.0951 114.537 15.0951C115.224 15.0951 115.749 15.3053 116.112 15.7256C116.494 16.1269 116.685 16.7096 116.685 17.4739V27.2757C116.685 28.0209 116.504 28.6037 116.141 29.024C115.778 29.4253 115.243 29.6259 114.537 29.6259ZM114.537 12.7449C113.735 12.7449 113.114 12.5634 112.675 12.2004C112.255 11.8183 112.045 11.2833 112.045 10.5954C112.045 9.88847 112.255 9.35347 112.675 8.99044C113.114 8.60831 113.735 8.41724 114.537 8.41724C115.339 8.41724 115.95 8.60831 116.37 8.99044C116.79 9.35347 117 9.88847 117 10.5954C117 11.2833 116.79 11.8183 116.37 12.2004C115.95 12.5634 115.339 12.7449 114.537 12.7449Z"
      fill="#372D4C"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1945_77168"
        x1={17.0836}
        x2={17.0836}
        y1={-0.025512}
        y2={17.0634}
      >
        <stop stopColor="#E858FB" />
        <stop offset={1} stopColor="#4D9BFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1945_77168"
        x1={17.0891}
        x2={17.0891}
        y1={-0.0255038}
        y2={17.0579}
      >
        <stop stopColor="#4D9BFF" stopOpacity={0} />
        <stop offset={1} stopColor="#4D9BFF" />
      </linearGradient>
    </defs>
  </svg>
)
const Memo = memo(SvgLogo)
export default Memo
