import { FC, useMemo, useRef } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import { AtomAction, ContractAtomRequest } from 'api/omni/model'
import {
  DatetimeAtomInputs,
  FileAtomInputs,
  NumberAtomInputs,
  TextAtomInputs,
  PaymentAtomInputs,
  AddressAtomInputs,
} from 'features/AtomInputs'
import { SignatureDocumentsInputs } from 'features/AtomInputs/SignatureDocumentsInputs/SignatureDocumentsInputs'
import { useConstructorContext } from 'libs/constructor'
import * as S from './style'

type AtomProps = {
  id: string
  atomInfo: AtomAction
  inputs: ContractAtomRequest['inputs']
  index: number
  moveAtom: (dragIndex: number, hoverIndex: number) => void
}

type DragItem = {
  id: string
  index: number
}

export const Atom: FC<AtomProps> = (props) => {
  const { id, inputs, atomInfo, index, moveAtom } = props

  const { removeAtom } = useConstructorContext()

  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'atom',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveAtom(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'atom',
    item: () => {
      return { id, index }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const currentAtomInputs = useMemo(() => {
    return inputs.map((input) => {
      const { name } = input

      if (!name) {
        return null
      }

      const { label, canBeVariable, type, actionElements, buttons, state } =
        atomInfo.inputs.find((atom) => atom.name === name)!

      if (!name) {
        return null
      }

      switch (type) {
        case 'STRING':
          return (
            <TextAtomInputs
              key={name}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
              state={state}
            />
          )
        case 'NUMBER':
          return (
            <NumberAtomInputs
              key={name}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
            />
          )
        case 'FILE':
          return (
            <FileAtomInputs
              key={name}
              atomName={name}
              id={id}
              placeholder={label}
              state={state}
            />
          )
        case 'DATETIME':
          return (
            <DatetimeAtomInputs
              key={name}
              actionElements={actionElements}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
            />
          )
        case 'BUTTONS_TOGGLE':
          return (
            <PaymentAtomInputs
              key={name}
              atomName={name}
              buttons={buttons!}
              id={id}
              inputStatus={state}
              label={label}
            />
          )
        case 'ADDRESS':
          return (
            <AddressAtomInputs
              key={name}
              actionElements={actionElements}
              atomId={id}
              atomName={name}
              canBeVariable={canBeVariable}
              placeholder={label}
              state={state}
            />
          )
        case 'SIGNATURE_DOCUMENTS' as any:
          return (
            <SignatureDocumentsInputs
              key={name}
              atomName={name}
              id={id}
              inputStatus={state}
              placeholder={label}
            />
          )
      }

      return null
    })
  }, [atomInfo, id, inputs])

  drag(drop(ref))

  return (
    <S.Wrapper ref={ref} data-handler-id={handlerId} isDragging={isDragging}>
      <S.BottomBlock>
        <S.DragElement>
          <S.DragIcon />
        </S.DragElement>
        <S.Label>{atomInfo.name}</S.Label>
        <S.RemoveIcon onClick={() => removeAtom({ atomId: id })} />
      </S.BottomBlock>
      {currentAtomInputs}
    </S.Wrapper>
  )
}
