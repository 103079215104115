import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgContractDeployedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F6F9" height={40} rx={20} width={40} />
    <mask
      height={24}
      id="mask0_5575_16718"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={8}
      y={8}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={8} y={8} />
    </mask>
    <g mask="url(#mask0_5575_16718)">
      <path
        d="M16 26L10 20L16 14L17.425 15.425L12.825 20.025L17.4 24.6L16 26ZM24 26L22.575 24.575L27.175 19.975L22.6 15.4L24 14L30 20L24 26Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
)
const Memo = memo(SvgContractDeployedIcon)
export default Memo
