import { FC } from 'react'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'constants/app'
import { SvgUnion } from 'ui/icons'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

const UNTILL_DATE = 'until 03/12/2033'
const LAST_ACTION = 'Payment 2 november 23 814 JUNA'

type InfoBlockProps = {
  title: string
  amount: string
  date?: string
  isTransaction?: boolean
  until?: string
  wallet?: string
  loanId?: number
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
  const { title, amount, isTransaction, wallet, loanId } = props

  const navigate = useNavigate()

  const handleClick = () => {
    if (!isTransaction) {
      navigate(`${AppRoutes.Contracts}/${loanId}`)

      return
    }
  }

  return (
    <S.Wrapper onClick={handleClick}>
      <S.FlexWrapper>
        {!isTransaction && <SvgUnion />}
        <S.InfoBlock>
          <S.Title>{title}</S.Title>
          <S.Title isBold>{amount} JUNA</S.Title>
          <S.Label>{LAST_ACTION}</S.Label>
        </S.InfoBlock>
      </S.FlexWrapper>
      <S.Label>{isTransaction ? shortenAddress(wallet!) : UNTILL_DATE}</S.Label>
    </S.Wrapper>
  )
}
