import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useNetwork } from 'wagmi'
import { usePlatformContractRetrieve } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { ProcessForm, MilestoneWrapper } from './components'
import { FORM_ID } from './constants'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Catalog',
    url: AppRoutes.Catalog,
  },
  {
    label: 'Create a contract',
    url: AppRoutes.ContractCreate,
  },
]

export const ContractProcess = () => {
  const { t } = useTranslation('pageContractProcess')

  const { contractId, selectorFn } = useParams()
  const { chain } = useNetwork()

  const { data, isLoading, isSuccess } = usePlatformContractRetrieve(
    Number(contractId),
    {
      query: {
        enabled: !!contractId,
      },
    }
  )

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess && chain && selectorFn) {
    return (
      <PageWrapper
        actions={
          <Button form={FORM_ID} type="submit" variant="red">
            {t(
              data.chainId === chain.id ? 'Call a function' : 'Switch network'
            )}
          </Button>
        }
        breadcrumbs={breadcrumbs}
        title={t('Execution')}
      >
        <S.Wrapper>
          <S.LeftSideWrapper>
            <ProcessForm data={data} selectorFn={selectorFn} />
          </S.LeftSideWrapper>
          <S.RightSideWrapper>
            <MilestoneWrapper context={data.context!} />
          </S.RightSideWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
