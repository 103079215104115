import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAdBackArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      height={24}
      id="mask0_5237_15931"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={24} width={24} />
    </mask>
    <g mask="url(#mask0_5237_15931)">
      <path
        d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgAdBackArrow)
export default Memo
