import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled.h5<{ isActive: boolean }>`
  margin: 0;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
`

export const SubcategoryLabel = styled.span<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  font-size: 14px;
  line-height: 22px;
  padding-left: 12px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
`

export const Button = styled(UIButton)<{ isSubcategoriesDisplayed: boolean }>`
  width: max-content;
  background-color: transparent;
  padding: 0;
  transform: ${({ isSubcategoriesDisplayed }) =>
    isSubcategoriesDisplayed && 'rotate(180deg)'};
`
