import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  usePlatformProjectList,
  usePlatformLaunchpadRequestsList,
} from 'api/omni/endpoints'
import {
  PaginatedProjectList,
  PaginatedLaunchpadRequestList,
} from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import LAUNCHPAD from './assets/launchpad.png'
import LAUNCHPADTABLET from './assets/launchpadTablet.png'
import { Projects, Tenders } from './components'
import * as S from './style'

const bannerCardData = [
  {
    title: '100 000',
    text: 'Funds Raised',
  },
  {
    title: '25',
    text: 'Projects Launched',
  },
  {
    title: '5000',
    text: 'All-time Unique Participants',
  },
]

export const Launchpad: FC = () => {
  const { t } = useTranslation('pageLaunchpad')

  const navigate = useNavigate()

  const {
    data: projects,
    isLoading: isProjectsLoading,
    isSuccess: isProjectsSuccess,
  } = usePlatformProjectList(undefined, {
    query: {
      select: useCallback(
        (data: PaginatedProjectList) => data.results.slice(0, 2),
        []
      ),
    },
  })

  const {
    data: tenders,
    isLoading: isTendersLoading,
    isSuccess: isTendersSuccess,
  } = usePlatformLaunchpadRequestsList(undefined, {
    query: {
      select: useCallback(
        (data: PaginatedLaunchpadRequestList) => data.results.slice(0, 3),
        []
      ),
    },
  })

  console.log(projects)

  if (isProjectsLoading || isTendersLoading) {
    return <Loader />
  }

  const handleApplyBtnClick = () => {
    navigate(AppRoutes.ProjectAdd)
  }

  if (isProjectsSuccess && isTendersSuccess) {
    return (
      <S.LaunchpadContainer>
        <S.Banner>
          <S.BannerImage alt="launchpad" src={LAUNCHPAD} />
          <S.BannerImageTablet alt="launchpad" src={LAUNCHPADTABLET} />
          <S.LaunchIconContainer>
            <S.LaunchIcon />
            <S.LaunchIconTablet />
          </S.LaunchIconContainer>
          <S.BannerTitle>Launchpad</S.BannerTitle>
          <S.BannerText>
            Create a project for the Juna ecosystem and get a grant
          </S.BannerText>
          <S.BannerCardContainer>
            {bannerCardData.map((data) => (
              <S.BannerCard>
                <S.BannerCardTitle>{data.title}</S.BannerCardTitle>
                <S.BannerCardText>{data.text}</S.BannerCardText>
              </S.BannerCard>
            ))}
          </S.BannerCardContainer>
        </S.Banner>
        <S.Wrapper>
          <S.Title>{t('Projects')}</S.Title>
        </S.Wrapper>
        <S.ButtonWrapper>
          <S.FilterButtonWrapper>
            <S.FilterButton variant="white">{t('All')}</S.FilterButton>
            <S.FilterButton variant="white">
              {t('Upcoming Projects')}
            </S.FilterButton>
            <S.FilterButton variant="white">
              {t('Ended Projects')}
            </S.FilterButton>
          </S.FilterButtonWrapper>
          <S.ApplyButton onClick={handleApplyBtnClick} variant="red">
            {t('Apply Project')}
          </S.ApplyButton>
        </S.ButtonWrapper>
        <S.Wrapper>
          <Projects projects={projects} />
        </S.Wrapper>
        {tenders.length > 0 && (
          <S.Wrapper>
            <S.Title>{t('Upcoming Tenders')}</S.Title>
            <Tenders tenders={tenders} />
          </S.Wrapper>
        )}
      </S.LaunchpadContainer>
    )
  }

  return null
}
