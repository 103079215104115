import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useWalletConnection } from 'hooks'
import { SvgMetamask } from 'ui/icons'
import { Modal } from 'ui/Modal/Modal'
import * as S from './style'

type ProviderModalProps = {
  onClose: () => void
}

type WalletMenuItem = {
  name: string
  icon: JSX.Element
}

const WALLETS: WalletMenuItem[] = [{ name: 'Metamask', icon: <SvgMetamask /> }]

export const ProviderModal: FC<ProviderModalProps> = (props) => {
  const { onClose } = props

  const { t } = useTranslation('modalProviderModal')

  const { connect } = useWalletConnection()

  const handleWalletClick = () => {
    connect()
    onClose()
  }

  return (
    <Modal onClose={onClose} title={t('Connect wallet')}>
      <S.Wrapper>
        <S.Body>
          {WALLETS.map(({ name, icon }) => (
            <S.Provider key={name} onClick={handleWalletClick}>
              <S.Content>
                {icon}
                <S.Wallet>{name}</S.Wallet>
              </S.Content>
            </S.Provider>
          ))}
        </S.Body>
      </S.Wrapper>
    </Modal>
  )
}
