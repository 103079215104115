import { FC } from 'react'
import * as S from './style'

type SelectItemProps = {
  label: string
  onSelect: () => void
}

export const SelectItem: FC<SelectItemProps> = (props) => {
  const { label, onSelect } = props

  return (
    <S.Wrapper onClick={onSelect}>
      <S.Label>{label}</S.Label>
    </S.Wrapper>
  )
}
