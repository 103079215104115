import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgCircleClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={15}
    viewBox="0 0 15 15"
    width={15}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0398 5.18349C10.0398 5.11903 9.9871 5.0663 9.92265 5.0663L8.95585 5.07069L7.49979 6.80653L6.0452 5.07216L5.07694 5.06776C5.01249 5.06776 4.95975 5.11903 4.95975 5.18495C4.95975 5.21278 4.97001 5.23915 4.98759 5.26112L6.89335 7.53163L4.98759 9.80067C4.96989 9.82214 4.96006 9.84902 4.95975 9.87685C4.95975 9.9413 5.01249 9.99403 5.07694 9.99403L6.0452 9.98964L7.49979 8.2538L8.95438 9.98817L9.92118 9.99257C9.98563 9.99257 10.0384 9.9413 10.0384 9.87538C10.0384 9.84755 10.0281 9.82118 10.0105 9.79921L8.1077 7.53017L10.0135 5.25966C10.031 5.23915 10.0398 5.21132 10.0398 5.18349Z"
      fill="#372D4C"
    />
    <path
      d="M7.5 0.9375C3.87598 0.9375 0.9375 3.87598 0.9375 7.5C0.9375 11.124 3.87598 14.0625 7.5 14.0625C11.124 14.0625 14.0625 11.124 14.0625 7.5C14.0625 3.87598 11.124 0.9375 7.5 0.9375ZM7.5 12.9492C4.49121 12.9492 2.05078 10.5088 2.05078 7.5C2.05078 4.49121 4.49121 2.05078 7.5 2.05078C10.5088 2.05078 12.9492 4.49121 12.9492 7.5C12.9492 10.5088 10.5088 12.9492 7.5 12.9492Z"
      fill="#372D4C"
    />
  </svg>
)
const Memo = memo(SvgCircleClose)
export default Memo
