import { FC, Fragment, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useAtomCtrLogsList } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { SvgActionMockIcon } from 'ui/icons'
import { Overlay } from 'ui/Overlay/Overlay'
import { Pagination } from 'ui/Pagination/Pagination'
import { Spinner } from 'ui/Spinner/Spinner'
import { getActionLabelDataDictionary } from './getActionLabelDataDictionary'
import * as S from './style'

export const LastActionsBlock: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()

  const { contractId } = useParams()

  const { t } = useTranslation('pageUserDashboard')

  const {
    data: actions,
    isLoading: isActionsLoading,
    isSuccess: isActionsLoaded,
  } = useAtomCtrLogsList({
    size: 10,
    contract_id: contractId,
    page: currentPage,
  })

  const actionLabelDataDictionary = useMemo(
    () => getActionLabelDataDictionary(t),
    [t]
  )

  const totalPages = useMemo(() => {
    if (!actions) {
      return 1
    }

    return actions.count / 10
  }, [actions])

  return (
    <Overlay isLoading={isActionsLoading} loader={<Spinner />}>
      {isActionsLoaded && (
        <S.Container>
          <S.Title>{t('History of operations')}</S.Title>
          {!actions.results.length && (
            <S.NotFoundWrapper>
              <S.NotFound>
                {t(
                  'No actions found yet. Here you will see all your activities in the Juna ecosystem'
                )}
              </S.NotFound>
            </S.NotFoundWrapper>
          )}

          <S.ActionsWrapper>
            {actions.results.map((action, idx) => {
              const actionType =
                action.type as keyof typeof actionLabelDataDictionary

              if (action.type in actionLabelDataDictionary) {
                const { description, icon } =
                  actionLabelDataDictionary[actionType]

                return (
                  <Fragment key={idx}>
                    <S.ActionWrapper
                      onClick={() =>
                        navigate(
                          `${AppRoutes.AtomContract}/${action.contractId}`
                        )
                      }
                    >
                      {icon ? icon : <SvgActionMockIcon />}
                      <S.ActionContentWrapper>
                        <S.Title>{action.contractName}</S.Title>
                        <S.Label>
                          {action.data && description(action.data)}
                        </S.Label>
                        <S.LabelDate>
                          {dayjs(action.createdAt).toString()}
                        </S.LabelDate>
                      </S.ActionContentWrapper>
                    </S.ActionWrapper>
                    {idx !== actions.results.length - 1 && <S.Line />}
                  </Fragment>
                )
              } else {
                throw new Error(`Invalid action type: ${actionType}`)
              }
            })}
          </S.ActionsWrapper>
          {totalPages > 1 && (
            <S.PaginationWrapper>
              <S.TotalItemsLabel>
                {t('Total items')} {actions?.count}
              </S.TotalItemsLabel>
              <Pagination
                currentPage={currentPage}
                onChangePage={(page) => setCurrentPage(page)}
                totalPages={totalPages}
              />
            </S.PaginationWrapper>
          )}
        </S.Container>
      )}
    </Overlay>
  )
}
