import { FC, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePlatformProjectRetrieve } from 'api/omni/endpoints'
import { AppRoutes, projectStatusLabels } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { DescriptionBlock, InfoBlock } from './components'
import * as S from './style'

export const Project: FC = () => {
  const [searchParams] = useSearchParams()
  const projectId = searchParams.get('projectId')

  const { data, isLoading, isSuccess } = usePlatformProjectRetrieve(
    Number(projectId)
  )

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Launchpad',
        url: AppRoutes.Launchpad,
      },
    ]

    if (data) {
      return [
        ...initialBreadcrumbs,
        {
          label: data.name,
          url: AppRoutes.Project,
        },
      ]
    }

    return initialBreadcrumbs
  }, [data])

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={data.name}>
        <S.Wrapper>
          <DescriptionBlock description={data.description} />
          <InfoBlock amount={1000} status={projectStatusLabels[data.status]} />
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
