import { bscTestnet, bsc, mainnet } from '@wagmi/chains'
import { goerli } from 'wagmi'
import { SvgEthLogo, SvgBscLogo } from 'ui/icons'

type ChainIdType = {
  ETH: number
  GOERLI: number
  BSC: number
  BSC_TESTNET: number
}

type ChainsType = {
  [id: number]: {
    name: string
    icon: JSX.Element
  }
}

export const ChainId: ChainIdType = {
  ETH: 1,
  GOERLI: 5,
  BSC: 56,
  BSC_TESTNET: 97,
}

export const DEFAULT_CHAIN = ChainId.ETH

export const Chains: ChainsType = {
  [ChainId.ETH]: {
    name: 'Ethereum',
    icon: <SvgEthLogo />,
  },
  [ChainId.GOERLI]: {
    name: 'Ethereum Goerli',
    icon: <SvgEthLogo />,
  },
  [ChainId.BSC]: {
    name: 'Binance Smart Chain',
    icon: <SvgBscLogo />,
  },
  [ChainId.BSC_TESTNET]: {
    name: 'BSC Testnet',
    icon: <SvgBscLogo />,
  },
}

export const supportedChains = [bscTestnet, goerli, bsc, mainnet]

export enum ApprovalState {
  UNKNOWN = 'unknown',
  NOT_APPROVED = 'not_approved',
  APPROVED = 'approved',
}
