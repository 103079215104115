import {
  MouseEvent,
  ChangeEvent,
  FC,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { Tooltip } from 'react-tooltip'
import {
  useAtomDocumentCreate,
  useAtomDocumentRetrieve,
} from 'api/omni/endpoints'
import { Document } from 'api/omni/model'
import { useConstructorContext } from 'libs/constructor'
import { SvgUpload } from 'ui/icons'

import { InputState } from '../types'
import * as S from './style'

type FileListAgreementAtomInputsProps = {
  id: string
  placeholder: string
  atomName: string
  state: string
}

export const FileListAgreementAtomInputs: FC<
  FileListAgreementAtomInputsProps
> = (props) => {
  const { id: atomId, placeholder, atomName, state } = props
  const fileInput = useRef<HTMLInputElement | null>(null)
  const { setFieldValue, getValue } = useConstructorContext()

  const value = useMemo(() => {
    const fileListAgreementAtomValue = getValue({
      name: atomName,
      atomId,
    })

    if (typeof fileListAgreementAtomValue === 'string') {
      return fileListAgreementAtomValue
    }

    return ''
  }, [getValue, atomId, atomName])

  const { data: currentFile, isSuccess: isCurrentFileSuccess } =
    useAtomDocumentRetrieve(value, {
      query: {
        enabled: Boolean(value),
        select: useCallback((data: Document) => {
          const currentFileName = data.file.split('/').pop()

          return {
            ...data,
            name: currentFileName,
          }
        }, []),
      },
    })

  const { mutate, isLoading: isFileUploading } = useAtomDocumentCreate({
    mutation: {
      onSuccess: ({ id }) => {
        setFieldValue({
          atomId,
          name: atomName,
          value: id,
        })
      },
    },
  })

  const handleClick = () => {
    if (state === InputState.EDITABLE) {
      fileInput.current?.click()
    } else {
      window.open(currentFile?.file)
    }
  }

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const file = evt.target.files?.[0]
      if (file) {
        mutate({
          data: {
            file,
          },
        })
      }
    },
    [mutate]
  )

  const handleReset = useCallback(
    (evt: MouseEvent<SVGElement>) => {
      evt.stopPropagation()

      fileInput.current!.value = ''
      setFieldValue({
        atomId,
        name: atomName,
        value: '',
      })
    },
    [setFieldValue, atomId, atomName]
  )

  if (state === InputState.HIDDEN) {
    return null
  }

  return (
    <>
      <Tooltip id="file-tooltip" />
      <S.FileInputContainer
        key={atomId}
        ref={fileInput}
        name={atomName}
        onChange={handleChange}
        placeholder={placeholder}
        type="file"
      />
      <S.FileContainer
        data-tooltip-content="Open file in new tab"
        data-tooltip-id="file-tooltip"
        data-tooltip-place="left"
        onClick={handleClick}
      >
        <SvgUpload />
        <S.InfoContainer>
          {isCurrentFileSuccess ? currentFile.name : placeholder}
          {isFileUploading && <S.Label>Uploading...</S.Label>}
        </S.InfoContainer>
        {isCurrentFileSuccess && state === InputState.EDITABLE && (
          <S.CloseIcon onClick={handleReset} />
        )}
      </S.FileContainer>
    </>
  )
}
