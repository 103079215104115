import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;
`

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
`
