import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

export const InfoBlock: FC = () => {
  const { t } = useTranslation('pageCreditCreate')

  return (
    <S.Wrapper>
      <S.Title>{t('Important')}</S.Title>
      <S.ListItem>
        <S.ListDot />
        <S.Label>
          {t(
            'Select a bank from the list, send a credit request on the selected conditions, send for approval'
          )}
        </S.Label>
      </S.ListItem>
    </S.Wrapper>
  )
}
