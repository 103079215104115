import { FC, Fragment, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAtomCtrLogsList } from 'api/omni/endpoints'
import { AtomAtomContractListStatus, ContractLogData } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import {
  SvgAadocSignedIcon,
  SvgAadocUploadedIcon,
  SvgActionContractCreatedIcon,
  SvgActionMemberAddedIcon,
  SvgActionMockIcon,
  SvgContractDeployedIcon,
  SvgMemberAddedIcon,
  SvgMemberRejectedIcon,
  SvgStatusChangedIcon,
} from 'ui/icons'
import { Overlay } from 'ui/Overlay/Overlay'
import { Pagination } from 'ui/Pagination/Pagination'
import { Spinner } from 'ui/Spinner/Spinner'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

const ContractStatusesDictionary: Record<AtomAtomContractListStatus, string> = {
  draft: 'Draft',
  on_approval: 'On approval',
  on_deployment: 'On deployment',
  execution: 'Execution',
  finished: 'Completed',
} as const

export const LastTxBlock: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()

  const { t } = useTranslation('pageUserDashboard')

  const { data: actions, isLoading: isActionsLoading } = useAtomCtrLogsList({
    size: 10,
    page: currentPage,
  })

  const actionLabelDataDictionary = useMemo(
    () => ({
      aadoc_rejected: {
        label: t('You rejected to sign the document'),
        description: (args: ContractLogData) =>
          t('Document has been rejected by member'),
        icon: null,
      },
      aadoc_signed: {
        label: t('You signed the document'),
        description: (args: ContractLogData) =>
          t('Document has been signed by member'),
        icon: <SvgAadocSignedIcon />,
      },
      aadoc_uploaded: {
        label: t("You've uploaded a document"),
        description: (args: ContractLogData) =>
          t('Document has been uploaded by member'),
        icon: <SvgAadocUploadedIcon />,
      },
      contract_created: {
        label: t('You created a contract'),
        description: (args: ContractLogData) =>
          t('Contract has been created by member', {
            initiatorAddress: args.initiatorAddress
              ? shortenAddress(args.initiatorAddress as string)
              : '',
          }),
        icon: <SvgActionContractCreatedIcon />,
      },
      contract_deployed: {
        label: t('You deployed the contract'),
        description: (args: ContractLogData) =>
          t('Contract has been deployed by member'),
        icon: <SvgContractDeployedIcon />,
      },
      member_added: {
        label: t('You added to the contract as member'),
        description: (args: ContractLogData) =>
          t('Added a new member to the contract', {
            addedMemberAddress: args.addedMemberAddress
              ? shortenAddress(args.addedMemberAddress as string)
              : '',
          }),
        icon: <SvgActionMemberAddedIcon />,
      },
      member_accepted: {
        label: t('You signed a contract'),
        description: (args: ContractLogData) =>
          t('Contract has been signed by member'),
        icon: <SvgMemberAddedIcon />,
      },
      member_rejected: {
        label: t('You rejected to sign the contract'),
        description: (args: ContractLogData) =>
          t('Contract has been rejected by member'),
        icon: <SvgMemberRejectedIcon />,
      },
      status_changed: {
        label: t('You change the status of the contract'),
        description: (args: ContractLogData) =>
          t('Contract status has been changed', {
            statusBefore:
              ContractStatusesDictionary[
                args.statusBefore as AtomAtomContractListStatus
              ],
            statusAfter:
              ContractStatusesDictionary[
                args.statusAfter as AtomAtomContractListStatus
              ],
          }),
        icon: <SvgStatusChangedIcon />,
      },
      bank_deposit_claimed: {
        description: (args: ContractLogData) => t('Bank deposit claimed'),
        icon: null,
      },
      bank_deposit_claim_requested: {
        description: (args: ContractLogData) => t('Deposit refund requested'),
        icon: null,
      },
      bank_deposit: {
        description: (args: ContractLogData) => t('Deposited'),
        icon: null,
      },
      execution_status_changed: {
        description: (args: ContractLogData) => {
          if (args.newExecutionStatus === 'dispute') {
            return t('The user started a dispute', {
              changedBy: shortenAddress(args.changedBy as string),
            })
          } else {
            return t('The user closed a dispute', {
              changedBy: shortenAddress(args.changedBy as string),
            })
          }
        },
        icon: null,
      },
      bank_credit: {
        description: (args: ContractLogData) => {
          return t('The bank issued a loan', args)
        },
        icon: null,
      },
    }),
    [t]
  )

  const totalPages = useMemo(() => {
    if (!actions) {
      return 1
    }

    return actions.count / 10
  }, [actions])

  return (
    <Overlay isLoading={isActionsLoading} loader={<Spinner />}>
      <S.Container>
        <S.Title>{t('Last actions')}</S.Title>
        {!actions?.results.length && (
          <S.NotFoundWrapper>
            <S.NotFound>
              {t(
                'No actions found yet. Here you will see all your activities in the Juna ecosystem'
              )}
            </S.NotFound>
          </S.NotFoundWrapper>
        )}

        <S.ActionsWrapper>
          {actions?.results.map((action, idx) => {
            const { description, icon } =
              actionLabelDataDictionary[
                action.type as keyof typeof actionLabelDataDictionary
              ]

            return (
              <Fragment key={idx}>
                <S.ActionWrapper
                  onClick={() =>
                    navigate(`${AppRoutes.AtomContract}/${action.contractId}`)
                  }
                >
                  {icon ? icon : <SvgActionMockIcon />}
                  <S.ActionContentWrapper>
                    <S.Title>{action.contractName}</S.Title>
                    <S.Label>{action.data && description(action.data)}</S.Label>
                    <S.LabelDate>
                      {dayjs(action.createdAt).toString()}
                    </S.LabelDate>
                  </S.ActionContentWrapper>
                </S.ActionWrapper>
                {idx !== actions?.results.length - 1 && <S.Line />}
              </Fragment>
            )
          })}
        </S.ActionsWrapper>
        {totalPages > 1 && (
          <S.PaginationWrapper>
            <S.TotalItemsLabel>
              {t('Total items')} {actions?.count}
            </S.TotalItemsLabel>
            <Pagination
              currentPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              shortVersion
              totalPages={totalPages}
            />
          </S.PaginationWrapper>
        )}
      </S.Container>
    </Overlay>
  )
}
