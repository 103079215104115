import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

type PinResetProps = {
  isLoading: boolean
  onReset: () => void
}

export const PinReset: FC<PinResetProps> = (props) => {
  const { isLoading, onReset } = props

  const { t } = useTranslation('pageSettings')

  return (
    <S.Wrapper>
      <S.Label>{t('PIN is active')}</S.Label>
      <S.Button isLoading={isLoading} onClick={onReset} variant="red">
        {t('Reset PIN code')}
      </S.Button>
    </S.Wrapper>
  )
}
