import { rgba } from 'polished'
import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

type LabelType = {
  isBold?: boolean
  isRefusal?: boolean
}

export const StyledCard = styled(Card)<{ isTemplate: boolean }>(
  ({ theme: { media, size, color }, isTemplate }) => `
  padding: 16px 24px;
  max-width: 364px;
  width: 100%;
  background-color: ${!isTemplate ? color.white : color.lightYellow};

  ${media(size.mobile)} {
    max-width: 100%;
  }
`
)

export const Title = styled.h4`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

export const StatusBlock = styled.div`
  border-radius: 52px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.blue};
  background-color: ${({ theme }) => rgba(theme.color.blue, 0.16)};
  padding: 4px 12px;
`

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 24px;
`

export const RefusalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const Label = styled.span<LabelType>`
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme, isRefusal }) =>
    isRefusal ? theme.color.red : theme.color.black};
  white-space: nowrap;
  margin-left: ${({ isRefusal }) => isRefusal && 'auto'};
`

export const DashedLine = styled.div`
  border: 1px dashed #e2e2ee;
  width: 100%;
`

export const BtnsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-height: 35px;
`

export const StyledBtn = styled(Button)`
  width: 100%;
  padding: 8px 0px;
`

export const StyledSmallBtn = styled(Button)`
  padding: 8px;
`
