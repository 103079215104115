import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type TErrorInfo = {
  componentStack: string
  error: string
}

export const ErrorInfo: FC<TErrorInfo> = (props) => {
  const { error, componentStack } = props

  const { t } = useTranslation('pageErrorInfo')

  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const reloadPage = useCallback(() => {
    window.location.reload()
  }, [])

  const toggleDetails = useCallback(() => {
    setIsDetailsOpen((prevState) => !prevState)
  }, [setIsDetailsOpen])

  return (
    <S.Wrapper>
      <S.TextWrapper>
        <S.Title>
          {t('Oops')} ! {t('Error')} :(
        </S.Title>
        <S.Label>
          {t('An unexpected error occurred while the application was running')}.
        </S.Label>
        <S.Label>{t('Try')}</S.Label>
        <S.Button onClick={reloadPage} variant="red">
          {t('Refresh')}
        </S.Button>
        <S.Label>
          {t('the page')}.
          <br />
          {t(
            'If the error persists, please contact technical support with following details'
          )}
          :
        </S.Label>
        <S.Button onClick={toggleDetails} variant="red">
          {t(isDetailsOpen ? 'Hide error details' : 'Show error details')}
        </S.Button>
        {isDetailsOpen && (
          <S.DetailsWrapper>
            <S.Title>{error}</S.Title>
            <S.Label>{componentStack}</S.Label>
          </S.DetailsWrapper>
        )}
      </S.TextWrapper>
    </S.Wrapper>
  )
}
