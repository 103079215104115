import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 21 20"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.7189 18.3109L17.0089 14.6309C18.449 12.8353 19.1464 10.5562 18.9577 8.2622C18.7691 5.9682 17.7086 3.83368 15.9945 2.29754C14.2803 0.761407 12.0427 -0.0595894 9.74186 0.00337096C7.44098 0.0663314 5.25166 1.00846 3.62408 2.63604C1.9965 4.26362 1.05437 6.45294 0.991408 8.75382C0.928448 11.0547 1.74944 13.2923 3.28558 15.0064C4.82172 16.7206 6.95624 17.781 9.25024 17.9697C11.5442 18.1584 13.8233 17.461 15.6189 16.0209L19.2989 19.7009C19.3919 19.7946 19.5025 19.869 19.6244 19.9198C19.7462 19.9706 19.8769 19.9967 20.0089 19.9967C20.1409 19.9967 20.2717 19.9706 20.3935 19.9198C20.5154 19.869 20.626 19.7946 20.7189 19.7009C20.8992 19.5144 20.9999 19.2652 20.9999 19.0059C20.9999 18.7466 20.8992 18.4974 20.7189 18.3109ZM10.0089 16.0209C8.62447 16.0209 7.27109 15.6104 6.11995 14.8412C4.9688 14.072 4.07159 12.9788 3.54178 11.6997C3.01197 10.4206 2.87334 9.01314 3.14344 7.65527C3.41354 6.2974 4.08022 5.05012 5.05919 4.07115C6.03816 3.09219 7.28544 2.4255 8.64331 2.1554C10.0012 1.88531 11.4086 2.02393 12.6877 2.55374C13.9668 3.08356 15.0601 3.98076 15.8292 5.13191C16.5984 6.28305 17.0089 7.63643 17.0089 9.0209C17.0089 10.8774 16.2714 12.6579 14.9587 13.9706C13.6459 15.2834 11.8655 16.0209 10.0089 16.0209Z"
      fill="#1B1F3B"
      fillOpacity={0.4}
    />
  </svg>
)
const Memo = memo(SvgSearch)
export default Memo
