import { FC, useState, useCallback, useContext } from 'react'
import { FormikConfig, Formik, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { api } from 'api'
import { usePlatformContractCreate } from 'api/omni/endpoints'
import {
  Lg,
  CreateContractRequest,
  CreateContractRequestAbiItem,
} from 'api/omni/model'
import { ApiContracts, ApiOmni } from 'api/types'
import { ChainId } from 'constants/blockchain'
import { ContractsModalContext } from 'context'
import { ChooseLegalGate } from 'features/ChooseLegalGate/ChooseLegalGate'
import { FormInput, FormTextarea } from 'features/FormFileds'
import { notify } from 'libs/notify/notify'
import {
  FORM_ID,
  MUTATION_CONTRACT_SAVE_KEY,
} from 'pages/ContractCreate/constants'
import { DynamicFieldType } from 'pages/ContractCreate/types'
import { FilePicker } from 'ui/FilePicker/FilePicker'
import { SvgFolder } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import * as S from './style'
import { getValidationSchema } from './validationSchema'

const CONTRACT_TEMPLATE_LINK = 'https://dev-contracts.junona.net/contracts/'

const TEST_ADDRESS = '0xED2dA4A525d93C83Db9AA76432f0311ed2B9A1c8'

type ContractFormProps = {
  createId: string | null
  onSubmit?: (id: number) => void
}

export type FormValues = Omit<
  CreateContractRequest,
  'data' | 'chain' | 'context' | 'abi' | 'bytecode' | 'code' | 'chainId'
> &
  Record<string, unknown>

export const ContractForm: FC<ContractFormProps> = (props) => {
  const { createId, onSubmit } = props

  const { openContractsModal } = useContext(ContractsModalContext)

  const [pickedFile, setPickedFile] = useState<File>()
  const [legalGatesValues, setLegalGatesValues] = useState<Lg[]>([])

  const { t } = useTranslation('pageContractCreate')

  const { data, isLoading, isSuccess } = useQuery(
    ['contract', createId],
    () => api.contracts.getContractById(Number(createId)),
    {
      select: useCallback((data: ApiContracts.Contract) => {
        const parsedContextData = JSON.parse(
          data.code!.context
        ) as ApiOmni.ContractContext

        const {
          members,
          amountJUNA,
          legalGates,
          legalGatesRewards,
          ...dynamicFields
        } = parsedContextData.constructor

        return {
          code: {
            ...data.code,
            abi: JSON.parse(data.code!.abi) as CreateContractRequestAbiItem[],
            context: parsedContextData,
          },
          id: data.id,
          jurisdiction: data.jurisdiction,
          formFields: {
            amountJuna: amountJUNA,
            legalGates,
            legalGatesRewards,
            dynamicFields,
            members: members.data,
          },
        }
      }, []),
    }
  )

  const handleSelectLegalGate = useCallback(
    (idx: number, legalGate: Lg) => {
      if (data) {
        setLegalGatesValues((prevLegalGatesValues) =>
          data.formFields.legalGates.data.reduce((acc, _, localIdx) => {
            if (localIdx === idx) {
              acc.push(legalGate)
            } else {
              acc.push(prevLegalGatesValues[localIdx])
            }

            return acc
          }, [] as Lg[])
        )
      }
    },
    [data]
  )

  const getInitialValues = () => {
    const initialValues: FormValues = {
      amountUSD: '0',
      amountJuna: '0',
      name: '',
      description: '',
      file: '',
      members: [],
    }

    if (data) {
      Object.entries(data.formFields.dynamicFields).forEach(([fieldName]) => {
        initialValues[fieldName] = ''
      })

      data.formFields.members.forEach(() => {
        initialValues.members.push({ name: '', address: '' })
      })
    }

    return initialValues
  }

  const { mutate } = usePlatformContractCreate({
    mutation: {
      onSuccess: (data) => {
        onSubmit?.(data.id)
        notify.success({
          message: 'Contract successfully created',
          title: 'Contract created',
        })
      },
      mutationKey: MUTATION_CONTRACT_SAVE_KEY,
    },
  })

  const handleFilePickerChange = useCallback((file: File) => {
    setPickedFile(file)
  }, [])

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values) => {
      if (data) {
        const {
          name,
          amountJuna,
          description,
          amountUSD,
          file,
          members,
          ...dynamicFields
        } = values

        const reqBody = {
          name,
          members,
          chainId: ChainId.BSC_TESTNET,
          ipfs: pickedFile,
          data: {
            members,
            legalGates: legalGatesValues.length > 0 ? [TEST_ADDRESS] : [],
            legalGatesRewards:
              legalGatesValues.length > 0 ? [legalGatesValues[0].price] : [],
            amountJuna,
            ...dynamicFields,
          },
          code: data.code.code || '',
          abi: data.code.abi,
          context: data.code.context,
          description: description,
          bytecode: data.code.bytecode || '',
          assurance: true,
          legalGateId:
            legalGatesValues.length > 0 ? legalGatesValues[0].id : undefined,
        }

        mutate({
          data: reqBody,
        })
      }
    },
    [data, pickedFile, legalGatesValues, mutate]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isSuccess) {
    return (
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(data.formFields.dynamicFields)}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form id={FORM_ID} onSubmit={handleSubmit}>
            <S.FieldsContainer>
              <FormInput name="name" placeholder={t('Name')} />
              <S.Sample
                href={CONTRACT_TEMPLATE_LINK + data.id}
                rel="noreferrer"
                target="_blank"
              >
                {t('Blockchain contract template')}
              </S.Sample>
              <FormTextarea name="description" placeholder={t('Description')} />
            </S.FieldsContainer>
            <S.UsersFieldsContainer>
              <FieldArray
                name="members"
                render={() =>
                  data.formFields.members.map(({ name }, index) => (
                    <S.UserFidelsWrapper key={name}>
                      <FormInput
                        name={`members.${index}.name`}
                        placeholder={name}
                      />
                      <FormInput
                        editorProps={{
                          buttons: [
                            {
                              icon: <SvgFolder />,
                              onClick: () =>
                                openContractsModal({
                                  onContractAddressSelect: (contractAddress) =>
                                    setFieldValue(
                                      `members.${index}.address`,
                                      contractAddress
                                    ),
                                }),
                              visible: !values.members[index].address,
                            },
                          ],
                        }}
                        name={`members.${index}.address`}
                        placeholder="0x00...00"
                      />
                    </S.UserFidelsWrapper>
                  ))
                }
              />
            </S.UsersFieldsContainer>
            <S.ExtendedFieldsContainer>
              {Object.entries(data.formFields.dynamicFields).map(
                ([fieldName, fieldData]) => {
                  const { name: placeholder } = fieldData as DynamicFieldType

                  return (
                    <FormInput
                      key={fieldName}
                      name={fieldName}
                      placeholder={placeholder}
                    />
                  )
                }
              )}
            </S.ExtendedFieldsContainer>
            <S.ExtendedFieldsContainer>
              {data.formFields.amountJuna && (
                <S.FieldWrapper>
                  <S.NumberInputs>
                    <S.ShortenedFormInput
                      editorProps={{
                        type: 'number',
                        min: 1,
                      }}
                      name="amountJuna"
                      placeholder={t('Contract amount in Juna')}
                    />

                    <S.FlexWrapper>
                      <S.CountInUsdLabel>In USD:</S.CountInUsdLabel>
                      <S.CountInUsdLabel isBold>
                        {values.amountJuna
                          ? Number(values.amountJuna) * 1
                          : '0'}
                        $
                      </S.CountInUsdLabel>
                    </S.FlexWrapper>
                  </S.NumberInputs>
                </S.FieldWrapper>
              )}
              <S.UploadFileField>
                <FilePicker
                  description={t('The document will be uploaded to IPFS')}
                  fileName={pickedFile && pickedFile.name}
                  fileTypeLabel={t('Document')}
                  isPicked={pickedFile !== undefined}
                  onChange={handleFilePickerChange}
                />
              </S.UploadFileField>
            </S.ExtendedFieldsContainer>
            <S.ExtendedFieldsContainer>
              <S.FlexWrapper>
                <S.Label>{t('Country')}</S.Label>
                <S.Label isBold>{data.jurisdiction.name}</S.Label>
              </S.FlexWrapper>
            </S.ExtendedFieldsContainer>
            {data.formFields.legalGates.count > 0 &&
              data.formFields.legalGates.data.map((legalGate, idx) => (
                <ChooseLegalGate
                  key={legalGate.name}
                  legalGateForm={legalGate}
                  legalGateIndex={idx}
                  onLegalGateSelect={handleSelectLegalGate}
                />
              ))}
          </form>
        )}
      </Formik>
    )
  }

  return null
}
