import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInput } from 'features/FormFileds'
import { Input } from 'ui/Input/Input'
import * as S from './style'

type VotingParamProps = {
  params: {
    name: string
    value: string
    symbol: string
  }[]
  title: string
}

export const VotingParam: FC<VotingParamProps> = (props) => {
  const { title, params } = props

  const { t } = useTranslation('pageAddVoting')

  return (
    <S.Wrapper>
      <S.LeftSideWrapper>
        <S.Label>{t('paramentr name')}</S.Label>
        <S.Title>{title}</S.Title>
      </S.LeftSideWrapper>
      <S.RightSideWrapper>
        {params.map((param, idx) => (
          <S.ParamRowWrapper key={idx}>
            <S.Chip variant="gray">{param.symbol}</S.Chip>
            <S.ParamRowLabel>{param.name}</S.ParamRowLabel>
            <S.FieldWrapper>
              <Input disabled value={param.value} />
            </S.FieldWrapper>
            <S.FieldWrapper>
              <FormInput name={`params[${param.symbol}]`} />
            </S.FieldWrapper>
          </S.ParamRowWrapper>
        ))}
      </S.RightSideWrapper>
    </S.Wrapper>
  )
}
