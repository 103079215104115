import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 24px;
  min-width: 705px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media(size.tablet)} {
    min-width: 100%;
  }
`
)

export const Title = styled.h5`
  font-size: 18px;
  margin: 0;
  font-weight: 800;
  color: ${({ theme }) => theme.color.black};
`

export const DescriptionText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
