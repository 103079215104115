import { FC } from 'react'
import { ConstructorProvider } from 'libs/constructor/context/ConstructorContext'
import { ConstructedContractV2 } from './ConstructedContractV2'

export const ConstructedContractPageV2: FC = () => {
  return (
    <ConstructorProvider>
      <ConstructedContractV2 />
    </ConstructorProvider>
  )
}
