import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={14}
    viewBox="0 0 14 14"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L11.0401 3.62589C11.4306 4.01642 11.4306 4.64958 11.0401 5.04011C10.6496 5.43063 10.0164 5.43063 9.62589 5.04011L8 3.41421V9C8 9.55229 7.55228 10 7 10C6.44772 10 6 9.55229 6 9V3.41421L4.37411 5.04011C3.98358 5.43063 3.35042 5.43063 2.95989 5.04011C2.56937 4.64958 2.56937 4.01642 2.95989 3.62589L6.29289 0.292893ZM1 8C1.55228 8 2 8.44772 2 9V11.6665C2.00014 11.7549 2.03531 11.8397 2.09783 11.9022C2.1604 11.9648 2.24525 11.9999 2.33375 12H11.6663C11.7547 11.9999 11.8396 11.9648 11.9022 11.9022C11.9648 11.8396 11.9999 11.7547 12 11.6663V9C12 8.44772 12.4477 8 13 8C13.5523 8 14 8.44772 14 9V11.667C13.9995 12.2854 13.7537 12.8791 13.3164 13.3164C12.8791 13.7537 12.2862 13.9995 11.6677 14H2.333C1.71459 13.9995 1.12089 13.7537 0.683612 13.3164C0.246331 12.8791 0.000463665 12.2862 2.98023e-07 11.6677L0 9C0 8.44772 0.447715 8 1 8Z"
      fill="#1B1F3B"
      fillOpacity={0.2}
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgUpload)
export default Memo
