import { FC } from 'react'
import { SelectItem } from './components'
import * as S from './style'

export type ConstructorSelectListProps = {
  selectaleItemName: string
  options: {
    label: string
    name: string
    atomId?: string
  }[]
  onChange: (value: string) => void
  className?: string
  value?: string
}

export const ConstructorSelectList: FC<ConstructorSelectListProps> = (
  props
) => {
  const { selectaleItemName, options, onChange, className } = props

  return (
    <S.Wrapper className={className}>
      <S.TopBlock>Select an {selectaleItemName}</S.TopBlock>
      {options.map(({ label, name, atomId }) => (
        <SelectItem
          key={atomId || name}
          label={label}
          onSelect={() => onChange(name)}
        />
      ))}
    </S.Wrapper>
  )
}
