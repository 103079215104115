import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { useProviderModal } from 'hooks'
import { FCWithChildren } from 'types/app'
import { Button, ButtonProps } from 'ui/Button/Button'

export const GuardedButton: FCWithChildren<ButtonProps> = (props) => {
  const { children, className, variant, onClick, type, isLoading } = props

  const { t } = useTranslation('featureGuardedButton')

  const { toggleProviderModal } = useProviderModal()
  const { isDisconnected } = useAccount()

  return (
    <Button
      className={className}
      isLoading={isLoading}
      onClick={isDisconnected ? toggleProviderModal : onClick}
      type={type}
      variant={isDisconnected ? 'primary' : variant}
    >
      {isDisconnected ? t('Connect wallet') : children}
    </Button>
  )
}
