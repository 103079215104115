import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgRedPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={17}
    viewBox="0 0 16 17"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 7.5V3.5C7 3.23478 7.10536 2.98043 7.29289 2.79289C7.48043 2.60536 7.73478 2.5 8 2.5C8.26522 2.5 8.51957 2.60536 8.70711 2.79289C8.89464 2.98043 9 3.23478 9 3.5V7.5H13C13.2652 7.5 13.5196 7.60536 13.7071 7.79289C13.8946 7.98043 14 8.23478 14 8.5C14 8.76522 13.8946 9.01957 13.7071 9.20711C13.5196 9.39464 13.2652 9.5 13 9.5H9V13.5C9 13.7652 8.89464 14.0196 8.70711 14.2071C8.51957 14.3946 8.26522 14.5 8 14.5C7.73478 14.5 7.48043 14.3946 7.29289 14.2071C7.10536 14.0196 7 13.7652 7 13.5V9.5H3C2.73478 9.5 2.48043 9.39464 2.29289 9.20711C2.10536 9.01957 2 8.76522 2 8.5C2 8.23478 2.10536 7.98043 2.29289 7.79289C2.48043 7.60536 2.73478 7.5 3 7.5H7Z"
      fill="#FF665B"
    />
  </svg>
)
const Memo = memo(SvgRedPlus)
export default Memo
