import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, disabled }) => (!disabled ? theme.color.red : theme.color.gray)};
  border-radius: 12px;
  display: flex;
`

export const Item = styled.div<{
  isActive?: boolean
  isRight?: boolean
  disabled?: boolean
}>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.white : theme.color.red};
  padding: 12px 0;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.red : theme.color.white};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isActive }) => (!isActive ? 'pointer' : 'auto')};

  &:first-child {
    border-radius: 12px 0px 0px 12px;
  }

  &:last-child {
    border-radius: 0px 12px 12px 0px;
  }

  ${({ theme, isActive, disabled }) => {
    if (disabled) {
      return css`
        color: ${theme.color.black};
        background-color: ${isActive ? theme.color.whisper : theme.color.white};
        cursor: not-allowed;
      `
    }
  }};
`
