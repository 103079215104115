import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
`

export const Title = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
  padding: 0;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const ListItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`

export const ListItemLabel = styled.span<{ isBold?: boolean }>`
  font-size: 15px;
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
`

export const DashedLine = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.color.mirageDark};
`
