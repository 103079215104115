import { rgba } from 'polished'
import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const InfoCardWrapper = styled(Card)`
  width: 232px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  background-color: #eef;
  padding: 28px 20px;
  box-shadow: none;
`

export const InfoCardTitle = styled.h4`
  color: ${({ theme }) => theme.color.black};
  font-size: 20px;
  font-weight: 800;
  margin: 0;
`

export const InfoCardContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoCardText = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`

export const InfoCardTextHighlighted = styled.span`
  color: ${({ theme }) => rgba(theme.color.red, 0.5)};
`
