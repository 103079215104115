import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAadocSignedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F6F9" height={40} rx={20} width={40} />
    <mask
      height={24}
      id="mask0_5575_16709"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={8}
      y={8}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={8} y={8} />
    </mask>
    <g mask="url(#mask0_5575_16709)">
      <path
        d="M17.55 26L11.85 20.3L13.275 18.875L17.55 23.15L26.725 13.975L28.15 15.4L17.55 26Z"
        fill="black"
      />
    </g>
  </svg>
)
const Memo = memo(SvgAadocSignedIcon)
export default Memo
