import { FC, useCallback } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import {
  usePlatformAdsCategoryList,
  usePlatformAdsCreate,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { FormImageUploader } from 'features/FormFileds'
import { notify } from 'libs/notify/notify'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { AddAdsForm, AdsDetails } from './components'
import { FORM_ID } from './constants'
import * as S from './style'
import { FormValues } from './types'
import { getValidationSchema } from './validationSchema'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Marketplace',
    url: AppRoutes.Marketplace,
  },
  {
    label: 'Place an ad',
    url: AppRoutes.AdsAdd,
  },
]

export const AddAds: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('pageAddAds')

  const { address } = useAccount()

  const { mutate, isLoading: isAddCreating } = usePlatformAdsCreate({
    mutation: {
      onSuccess: (data) => {
        navigate(`${AppRoutes.Marketplace}/${data.id}`)
        notify.success({
          message: t('Ad successfully added'),
          title: t('Ad added'),
        })
      },
    },
  })

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = usePlatformAdsCategoryList()

  const initialValues: FormValues = {
    category: '',
    name: '',
    description: '',
    amountjuna: 0,
  }

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values) => {
      mutate({ data: { ...values, category: Number(values.category) } })
    },
    [mutate]
  )

  if (isCategoriesLoading) {
    return <Loader />
  }

  if (isCategoriesSuccess && address) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Place an ad')}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema()}
        >
          <S.Wrapper>
            <S.LeftSideWrapper>
              <FormImageUploader name="image" />
            </S.LeftSideWrapper>
            <S.RightSideWrapper>
              <AddAdsForm categories={categories} />
              <S.AdsDetailsWrapper>
                <S.Label>{t('Your ad')}</S.Label>
                <AdsDetails address={address} categories={categories} />
                <S.ButtonsWrapper>
                  <S.Button
                    form={FORM_ID}
                    isLoading={isAddCreating}
                    type="submit"
                    variant="red"
                  >
                    {t('Place an ad')}
                  </S.Button>
                </S.ButtonsWrapper>
              </S.AdsDetailsWrapper>
            </S.RightSideWrapper>
          </S.Wrapper>
        </Formik>
      </PageWrapper>
    )
  }

  return null
}
