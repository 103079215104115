import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useBanksOwnTransactionList } from 'api/omni/endpoints'
import { PaginatedOwnBankTransactionList } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'

import { BankTable } from './components/BankTable/BankTable'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: `Owner's Dashboard`,
    url: AppRoutes.BankDashboard,
  },
  {
    label: `Loans`,
    url: AppRoutes.BankDashboardLoans,
  },
]

export const BankDashboardLoans: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('pageBankDashboardLoans')

  const {
    data: loanTransactions,
    isLoading: isLoanTransactionsLoading,
    isSuccess: isLoanTransactionsLoaded,
  } = useBanksOwnTransactionList(
    {},
    {
      query: {
        select: useCallback((data: PaginatedOwnBankTransactionList) => {
          const loanTransactions = data.results.filter(
            ({ type }) => type === 'credit'
          )

          return loanTransactions.map(
            ({
              createdAt,
              finishedAt,
              amount,
              atomContract,
              interestedRate,
              id,
            }) => ({
              createdAt,
              finishedAt,
              amount,
              address: atomContract.initiatorAddress,
              interestRate: interestedRate,
              status: atomContract.status,
              id: id!,
              chainId: atomContract.chainId,
            })
          )
        }, []),
      },
    }
  )

  const handleCreditClick = useCallback(
    (id: string) => () => {
      navigate(`${AppRoutes.BankViewCreditContract}/${id}`)
    },
    [navigate]
  )

  if (isLoanTransactionsLoading) {
    return <Loader />
  }

  if (isLoanTransactionsLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Loans')}>
        <BankTable
          hasStatus
          onClick={handleCreditClick}
          tableData={loanTransactions}
          tableTitle={t('Loans issues')}
        />
      </PageWrapper>
    )
  }

  return null
}
