import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ theme: { media, size } }) => media(size.tablet)} {
    overflow: auto;
  }
`

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  min-weight: min-content;
`
