import { StrictMode } from 'react'
import { InjectedConnector } from '@wagmi/core'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import AdvancedFormant from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { WagmiConfig, createClient } from 'wagmi'
import { GlobalStyle } from 'assets/style/GlobalStyle'
import { theme } from 'assets/style/theme'
import { TOAST_AUTO_CLOSE_TIME } from 'constants/app'
import {
  AuthProvider,
  ContactsModalProvider,
  ContractsModalProvider,
} from 'context'
import { ErrorBoundaryWithRouter } from 'features/ErrorBoundary/ErrorBoundary'
import { notify } from 'libs/notify/notify'
import { store } from 'store'
import * as S from 'ui/Toast/style'
import { configuredChains } from 'utils/configureChains'
import { App } from './App'
import './i18n'
import 'react-calendar/dist/Calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'

const { provider, webSocketProvider, chains } = configuredChains

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err instanceof AxiosError) {
          switch (err.response?.status) {
            case 401:
              notify.error({
                message: 'Sign In to the platform',
                title: 'Auth Error',
              })
              break
            default:
              notify.error({
                message: err.message,
                title: err.name,
              })
          }
        }
      },
    },
    mutations: {
      onError: (err) => {
        if (err instanceof AxiosError) {
          switch (err.response?.status) {
            case 401:
              notify.error({
                message: 'Sign In to the platform',
                title: 'Auth Error',
              })
              break
            default:
              notify.error({
                message: err.message,
                title: err.name,
              })
          }
        }
      },
    },
  },
})

dayjs.extend(relativeTime)
dayjs.extend(AdvancedFormant)

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={client}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <AuthProvider>
              <BrowserRouter>
                <ErrorBoundaryWithRouter>
                  <ContactsModalProvider>
                    <ContractsModalProvider>
                      <App />
                    </ContractsModalProvider>
                  </ContactsModalProvider>
                </ErrorBoundaryWithRouter>
              </BrowserRouter>
            </AuthProvider>
          </Provider>
        </ThemeProvider>
      </WagmiConfig>
    </QueryClientProvider>

    <S.StyledToastContainer
      autoClose={TOAST_AUTO_CLOSE_TIME}
      hideProgressBar={true}
    />
    <GlobalStyle />
  </StrictMode>
)
