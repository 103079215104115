import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  useBanksBankBalanceSheetRetrieve,
  useBanksOwnBankRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { BalanceCard, InfoCard, ProgressBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: `Owner's Dashboard`,
    url: AppRoutes.BankDashboard,
  },
]

export const BankDashboard: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('pageBankDashboard')

  const {
    data: bankData,
    isLoading: isBankDataLoading,
    isSuccess: isBankDataLoaded,
  } = useBanksOwnBankRetrieve()

  const {
    data: balanceSheet,
    isLoading: isBalanceSheetLoading,
    isSuccess: isBalanceSheetLoaded,
  } = useBanksBankBalanceSheetRetrieve(
    'ca740c78-4af7-47a0-8f08-e62cdefabba2',
    {}
  )

  if (isBankDataLoading || isBalanceSheetLoading) {
    return <Loader />
  }

  if (isBankDataLoaded && isBalanceSheetLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={''}>
        <S.ContentInfoWrapper>
          <S.ContentWrapper>
            <BalanceCard
              depositsAmount={bankData.depositTotalInProgress}
              loansAmount={bankData.creditTotalInProgress}
              ownFunds={balanceSheet.BOWN}
            />
            <S.ContentCard>
              <S.ContentCardTitle>{balanceSheet.WC}</S.ContentCardTitle>
              <S.ContentCardText>{t('Working capital')}</S.ContentCardText>
            </S.ContentCard>
            <S.ContentCard>
              <S.ContentCardTitle>
                {bankData.creditInterestRate}% AER
              </S.ContentCardTitle>
              <S.ContentCardText>
                {t('Bank credit interest rate')}
              </S.ContentCardText>
            </S.ContentCard>
            <S.ContentCard>
              <S.ContentCardTitle>
                {bankData.depositInterestRate}% AER
              </S.ContentCardTitle>
              <S.ContentCardText>
                {t('Bank deposit interest rate')}
              </S.ContentCardText>
            </S.ContentCard>

            <InfoCard bankName={bankData.name!} />
          </S.ContentWrapper>

          <S.ProgressBlocksWrapper>
            <ProgressBlock
              amount={3400}
              maxAmount={10000}
              progressBlockType={'bcet1'}
            />
            <ProgressBlock
              amount={balanceSheet.BRR}
              maxAmount={balanceSheet.CBRR}
              progressBlockType={'brr'}
            />
          </S.ProgressBlocksWrapper>
        </S.ContentInfoWrapper>
        <S.ButtonsBlocksWrapper>
          <S.ReserveSystemBlock>
            <S.ReserveLabel>
              {t('Operations in the reserve system')}
            </S.ReserveLabel>
            <S.ReserveButton
              onClick={() =>
                navigate(`${AppRoutes.ReserveSystem}/${bankData.id}`)
              }
              variant="red"
            >
              {t('Take a loan')}
            </S.ReserveButton>
            <S.VerticalLine />
            <S.ReserveButton
              onClick={() =>
                navigate(`${AppRoutes.ReserveSystem}/${bankData.id}`)
              }
              variant="red"
            >
              {t('Make a deposit')}
            </S.ReserveButton>
          </S.ReserveSystemBlock>

          <S.ReserveSystemBlock>
            <S.ReserveLabel>
              {t('Check out the active loans issues and deposits')}
            </S.ReserveLabel>
            <S.ReserveButton
              onClick={() => navigate(AppRoutes.BankDashboardLoans)}
              variant="red"
            >
              {t('Loans issues')}
            </S.ReserveButton>
            <S.VerticalLine />
            <S.ReserveButton
              onClick={() => navigate(AppRoutes.BankDashboardDeposits)}
              variant="red"
            >
              {t('Deposits')}
            </S.ReserveButton>
          </S.ReserveSystemBlock>
        </S.ButtonsBlocksWrapper>
      </PageWrapper>
    )
  }

  return null
}
