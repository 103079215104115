import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import {
  useAtomAtomActionContractCallRetrieve,
  useFinanceContractRetrieve,
} from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { LeftSideInfo, ContractInfo, DepositBlock } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Finance',
    url: AppRoutes.Finance,
  },
  {
    label: 'Deposit',
    url: AppRoutes.Deposit,
  },
]

export const Deposit: FC = () => {
  const { depositId } = useParams()

  const {
    data: deposit,
    isLoading: isDepositLoadig,
    isSuccess: isDepositLoded,
  } = useFinanceContractRetrieve(depositId!, {
    query: {
      enabled: Boolean(depositId),
    },
  })

  const {
    data: depositStatusAction,
    isLoading: isDepositStatusLoading,
    isSuccess: isDepositStatusLoaded,
  } = useAtomAtomActionContractCallRetrieve(
    deposit?.atomContract.atoms[0].id!,
    {
      action: 'deposit_status',
    },
    {
      query: {
        enabled: !!deposit,
      },
    }
  )

  const depositStatus = useMemo(() => {
    if (!deposit || !depositStatusAction?.data) {
      return
    }

    const depositAtomContractStatus = deposit.atomContract.status

    return depositAtomContractStatus !== 'execution'
      ? depositAtomContractStatus
      : (depositStatusAction.data.output as string)
  }, [deposit, depositStatusAction])

  const daysLeft = useMemo(() => {
    if (!deposit) {
      return null
    }

    return dayjs(deposit.finishedAt).diff(dayjs(), 'day')
  }, [deposit])

  const currentPageTitle = useMemo(() => {
    if (!deposit || daysLeft === null) {
      return
    }

    return {
      on_approval: 'Awaiting deposit decision',
      on_deployment: 'Deposit approved',
      waiting_deposit: 'Transfer tokens to deposit',
      in_progress:
        daysLeft > 0
          ? `Active: deposit ${daysLeft}d ${deposit.interestedRate}% AER`
          : 'Request withdrawal from deposit',
      claimed: 'Withdrawal: Transfer to client',
      claim_requested: 'Awaiting withdrawal approval',
      finished: 'Deposit withdrawn',
    }
  }, [deposit, daysLeft])

  if (isDepositLoadig || isDepositStatusLoading) {
    return <Loader />
  }

  if (
    isDepositLoded &&
    daysLeft !== null &&
    isDepositStatusLoaded &&
    depositStatus
  ) {
    const isInfoBlockShowed =
      depositStatus === 'on_approval' || depositStatus === 'on_deployment'

    return (
      <PageWrapper
        breadcrumbs={breadcrumbs}
        title={
          currentPageTitle![depositStatus as keyof typeof currentPageTitle]
        }
      >
        <S.Wrapper>
          {isInfoBlockShowed ? (
            <LeftSideInfo
              amount={deposit.amount}
              chain={deposit.atomContract.chainId}
              interestRate={deposit.interestedRate}
              issuerAddress={deposit.atomContract.initiatorAddress}
            />
          ) : (
            <DepositBlock
              amount={deposit.amount}
              atomId={deposit.atomContract.atoms[0].id}
              daysLeft={daysLeft}
              interestedRate={deposit.interestedRate}
              issuerAddress={deposit.atomContract.initiatorAddress}
              status={depositStatus}
            />
          )}

          {isInfoBlockShowed ? (
            <ContractInfo
              contractId={deposit.atomContract.id}
              createdAt={deposit.createdAt}
              finishedAt={deposit.finishedAt}
              status={depositStatus}
            />
          ) : (
            <ContractInfo
              amount={deposit.amount}
              chain={deposit.atomContract.chainId}
              contractId={deposit.atomContract.id}
              createdAt={deposit.createdAt}
              finishedAt={deposit.finishedAt}
              interestedRate={deposit.interestedRate}
              status={depositStatus}
            />
          )}
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
