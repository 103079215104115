import styled from 'styled-components'
import { BaseInput } from 'ui/BaseInput/BaseInput'
import { SvgAtomClose } from 'ui/icons'

export const Wrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  box-shadow: none;

  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  cursor: ${({ isLoading }) => isLoading && 'not-allowed'};
`

export const InputContainer = styled(BaseInput)`
  border-radius: 6px;
  border: none;
  box-shadow: none;
  outline: none;

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }

  input {
    color: ${({ error, theme }) =>
      error ? theme.color.red : theme.color.black};
    padding: 7px 0;
    outline: none;
    border: none;

    &::placeholder {
      color: ${({ theme }) => theme.color.mirageDark};
    }
  }
`

export const WorkspacesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
`

export const WorkspaceBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.mirageLight};
  width: max-content;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  transition: 0.3s;
  path {
    fill: ${({ theme }) => theme.color.black};
  }

  &:hover {
    opacity: 0.7;
  }
`

export const WorkspaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  z-index: 100;
`

export const Label = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
`
