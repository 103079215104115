import { FC } from 'react'
import { SignBlock, ContractInfo } from './components'
import * as S from './style'

type ApprovalContentProps = {
  createdAt: string
  contractChainId: number
}

export const ApprovalContent: FC<ApprovalContentProps> = (props) => {
  const { createdAt, contractChainId } = props

  return (
    <S.Wrapper>
      <SignBlock />
      <ContractInfo contractChainId={contractChainId} createdAt={createdAt} />
    </S.Wrapper>
  )
}
