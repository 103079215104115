import { getI18n } from 'react-i18next'
import { number, string, object, mixed } from 'yup'

export const getValidationSchema = () => {
  const { t } = getI18n()

  return object().shape({
    name: string().required(t('validation.Required to fill out')),
    description: string().required(t('validation.Required to fill out')),
    amountJUNA: number()
      .required(t('validation.Required to fill out'))
      .positive(t('validation.Number cannot be negative')),
    logo: mixed().required(t('validation.Upload your project logo')),
  })
}
