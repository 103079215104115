import { FC, useCallback } from 'react'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { usePlatformContactPartialUpdate } from 'api/omni/endpoints'
import { type Contact } from 'api/omni/model'
import { FormInput } from 'features/FormFileds'
import { notify } from 'libs/notify/notify'

import * as S from './style'

type FormValues = {
  name: string
  address: string
}

type ContactsFormProps = {
  selectedContact: Contact
}

export const ContactsForm: FC<ContactsFormProps> = (props) => {
  const { selectedContact } = props

  const { t } = useTranslation('pageMyContracts')

  const initialValues: FormValues = {
    name: selectedContact.name,
    address: selectedContact.address,
  }

  const { mutate: updateContact, isLoading: isContactLoading } =
    usePlatformContactPartialUpdate({
      mutation: {
        onSuccess: () => {
          notify.success({
            message: t('Contact successfully updated'),
            title: t('Contract updated'),
          })
        },
      },
    })

  const handleSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values) => {
      updateContact({
        id: selectedContact.id,
        data: values,
      })
    },
    [selectedContact.id, updateContact]
  )

  return (
    <S.Wrapper>
      <S.Title>{t('Contract details')}</S.Title>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <S.Form onSubmit={handleSubmit}>
            <FormInput name="name" placeholder={t('Contract name')} />
            <FormInput name="address" placeholder={t('Contract address')} />
            <S.Button isLoading={isContactLoading} type="submit">
              {t('Save')}
            </S.Button>
          </S.Form>
        )}
      </Formik>
    </S.Wrapper>
  )
}
