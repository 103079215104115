import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.common.boxShadow};
  border: 1px solid ${({ theme }) => theme.color.mirageLight};
  background-color: ${({ theme }) => theme.color.white};
`

export const TopBlock = styled.div`
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const Label = styled.span`
  font-size: 12px;
  font-family: Nunito;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.gray};
`
