import styled from 'styled-components'

export const AddressLabel = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: 12px;
`
export const ContractContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: background 0.5s ease-out;
  &:hover {
    background: ${({ theme }) => theme.color.bg};
  }
`

export const NoContracts = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CreateOneLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  text-decoration: none;
  font-size: 15px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.black};
  font-size: 16px;
  font-weight: 600;
`

export const Label = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.black};
`

export const StatusTag = styled.div`
  border-radius: 6px;
  padding: 3px 12px;
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.lightGray};

  font-weight: 400;
  font-size: 12px;
`

export const RedPlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  transition: background 0.5s ease-out;
  &:hover {
    background-color: rgba(255, 102, 91, 0.2);
  }
`

export const HintContainer = styled.div`
  max-width: 240px;
  text-align: center;
`
