import { useMemo } from 'react'
import { useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { notify } from 'libs/notify/notify'

type UseWalletConnection = () => {
  connect: () => void
}

export const useWalletConnection: UseWalletConnection = () => {
  const { connect } = useConnect({
    connector: new InjectedConnector(),

    onError: (error) => {
      notify.error({
        message: error.message,
        title: error.name,
      })
    },
  })

  return useMemo(
    () => ({
      connect,
    }),
    [connect]
  )
}
