import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Content = styled.div<{ isDisabled: boolean }>`
  height: 100%;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.1;
        pointer-events: none;
      `
    }
  }}
`

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  margin-top: 89px;
  z-index: 10;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  color: ${({ theme }) => theme.color.darkPurple};
`
