import { FC, useCallback } from 'react'
import { ContractItemTypeEnum } from 'api/omni/model'
import { useConstructorContext } from 'libs/constructor'
import * as S from './style'

export const ContractTypeSwitch: FC = () => {
  const { changeContractsType, currentContract } = useConstructorContext()

  const currentSwitchOptions = [
    {
      label: 'Contract',
      value: 'contract',
    },
    {
      label: 'Template',
      value: 'template',
    },
  ]

  const handleSwitchChange = useCallback(
    (value: string | boolean) => {
      if (typeof value === 'string') {
        changeContractsType(value as ContractItemTypeEnum)
      }
    },
    [changeContractsType]
  )

  return (
    <S.Switch
      onChange={handleSwitchChange}
      options={currentSwitchOptions}
      value={currentContract.type}
    />
  )
}
