import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgStatsPaid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="white" height={24} rx={8} width={24} />
    <mask
      height={16}
      id="mask0_6437_19129"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={16}
      x={4}
      y={4}
    >
      <rect fill="#D9D9D9" height={16} width={16} x={4} y={4} />
    </mask>
    <g mask="url(#mask0_6437_19129)">
      <path
        d="M7.89998 15.4C8.46665 14.9667 9.09998 14.625 9.79998 14.375C10.5 14.125 11.2333 14 12 14C12.7666 14 13.5 14.125 14.2 14.375C14.9 14.625 15.5333 14.9667 16.1 15.4C16.4889 14.9445 16.7916 14.4278 17.0083 13.85C17.225 13.2722 17.3333 12.6556 17.3333 12C17.3333 10.5222 16.8139 9.2639 15.775 8.22501C14.7361 7.18612 13.4778 6.66668 12 6.66668C10.5222 6.66668 9.26387 7.18612 8.22498 8.22501C7.18609 9.2639 6.66665 10.5222 6.66665 12C6.66665 12.6556 6.77498 13.2722 6.99165 13.85C7.20831 14.4278 7.51109 14.9445 7.89998 15.4ZM12 12.6667C11.3444 12.6667 10.7916 12.4417 10.3416 11.9917C9.89165 11.5417 9.66665 10.9889 9.66665 10.3333C9.66665 9.67779 9.89165 9.12501 10.3416 8.67501C10.7916 8.22501 11.3444 8.00001 12 8.00001C12.6555 8.00001 13.2083 8.22501 13.6583 8.67501C14.1083 9.12501 14.3333 9.67779 14.3333 10.3333C14.3333 10.9889 14.1083 11.5417 13.6583 11.9917C13.2083 12.4417 12.6555 12.6667 12 12.6667ZM12 18.6667C11.0778 18.6667 10.2111 18.4917 9.39998 18.1417C8.58887 17.7917 7.88331 17.3167 7.28331 16.7167C6.68331 16.1167 6.20831 15.4111 5.85831 14.6C5.50831 13.7889 5.33331 12.9222 5.33331 12C5.33331 11.0778 5.50831 10.2111 5.85831 9.40001C6.20831 8.5889 6.68331 7.88334 7.28331 7.28334C7.88331 6.68334 8.58887 6.20834 9.39998 5.85834C10.2111 5.50834 11.0778 5.33334 12 5.33334C12.9222 5.33334 13.7889 5.50834 14.6 5.85834C15.4111 6.20834 16.1166 6.68334 16.7166 7.28334C17.3166 7.88334 17.7916 8.5889 18.1416 9.40001C18.4916 10.2111 18.6666 11.0778 18.6666 12C18.6666 12.9222 18.4916 13.7889 18.1416 14.6C17.7916 15.4111 17.3166 16.1167 16.7166 16.7167C16.1166 17.3167 15.4111 17.7917 14.6 18.1417C13.7889 18.4917 12.9222 18.6667 12 18.6667Z"
        fill="#4D9BFF"
      />
    </g>
  </svg>
)
const Memo = memo(SvgStatsPaid)
export default Memo
