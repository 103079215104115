import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { useAccount, useNetwork, useSignTypedData } from 'wagmi'
import {
  useAtomAtomDocumentList,
  useAtomAtomDocumentCreate,
  useAtomAtomDocumentDestroy,
  useAtomAtomDocumentRejectCreate,
  useAtomAtomDocumentSignCreate,
} from 'api/omni/endpoints'
import { PaginatedAACDocList } from 'api/omni/model'
import { getForwarderAddress } from 'libs/addresses/addresses'
import { generateUUID } from 'libs/constructor/context/utils/generateContractUUID'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { SvgUpload } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { InputState } from '../types'
import * as S from './style'

type SignatureDocumentsInputsProps = {
  id: string
  placeholder: string
  atomName: string
  inputStatus: string
}

export const SignatureDocumentsInputs: FC<SignatureDocumentsInputsProps> = (
  props
) => {
  const { id, placeholder, atomName, inputStatus } = props

  const { signTypedDataAsync } = useSignTypedData()

  const { address } = useAccount()

  const { chain } = useNetwork()

  const fileInput = useRef<HTMLInputElement | null>(null)

  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isSuccess: isDocumentsLoaded,
    refetch: refetchDocuments,
  } = useAtomAtomDocumentList(
    {},
    {
      query: {
        select: useCallback((data: PaginatedAACDocList) => {
          return {
            ...data,
            results: data.results.map((document) => ({
              ...document,
              file: document.file.split('/').pop()?.split('?')[0],
            })),
          }
        }, []),
      },
    }
  )

  const { mutate: uploadDocument } = useAtomAtomDocumentCreate({
    mutation: {
      onSuccess: () => {
        refetchDocuments()
      },
    },
  })

  const { mutate: removeDocument } = useAtomAtomDocumentDestroy({
    mutation: {
      onSuccess: () => {
        refetchDocuments()
      },
    },
  })

  const { mutate: documentReject } = useAtomAtomDocumentRejectCreate({
    mutation: {
      onSuccess: () => {
        refetchDocuments()
      },
    },
  })

  const { mutate: documentSign } = useAtomAtomDocumentSignCreate({
    mutation: {
      onSuccess: () => {
        refetchDocuments()
      },
    },
  })

  const handleUploadChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const file = evt.target.files?.[0]

      if (file) {
        uploadDocument({
          data: {
            id: generateUUID(),
            nameField: atomName,
            file,
            atomActionContract: id,
          },
        })
      }
    },
    [atomName, id, uploadDocument]
  )

  const handleUploadClick = () => {
    if (inputStatus === InputState.EDITABLE) {
      fileInput.current?.click()
    }
  }

  const handleDeleteDocumentClick = useCallback(
    (id: string) => {
      removeDocument({ id })
    },
    [removeDocument]
  )

  const handleSignDocumentClick = async (documentId: string) => {
    if (!chain?.id) {
      return
    }

    const signed = await signTypedDataAsync({
      domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
      types: {
        Form: [],
      },
      value: {},
    })

    documentSign({
      id: documentId,
      data: {
        dataSig: signed,
        dataUnsig: {
          primaryType: 'Form',
          domain: EIP712Domain(getForwarderAddress(chain.id), chain.id),
          types: {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            Form: [],
          },
          message: {},
        },
      },
    })
  }

  if (inputStatus === InputState.HIDDEN) {
    return null
  }

  if (isDocumentsLoading) {
    return <Loader />
  }

  if (isDocumentsLoaded) {
    return (
      <S.Wrapper>
        {documents.results.map((document) => {
          const isAccepted =
            document.sigs.length > 0 &&
            document.sigs.every((sig) => sig.status === 'ACCEPTED')

          const isRejected =
            document.sigs.length > 0 &&
            document.sigs.every((sig) => sig.status === 'REJECTED')

          const getStatusLabel = () => {
            switch (true) {
              case isAccepted:
                return 'Accepted'
              case isRejected:
                return 'Rejected'
              default:
                return null
            }
          }

          const currentSig = document.sigs.find(
            (sig) => sig.address === address
          )

          const isSigsButtonsDisplayed =
            currentSig?.status === 'PENDING' || !document.sigs.length

          return (
            <S.UploadedSignatureFileWrapper key={document.id}>
              <S.UploadedFileInfoWrapper>
                <S.FileIcon />
                <S.UploadedFileNameWrapper>
                  <S.Label isBold>{document.file}</S.Label>
                  <S.Label>
                    {(isAccepted || isRejected) && getStatusLabel()}
                  </S.Label>
                </S.UploadedFileNameWrapper>
                {isSigsButtonsDisplayed && (
                  <>
                    <S.ActionButton
                      onClick={() => handleSignDocumentClick(document.id!)}
                    >
                      Accept and sign
                    </S.ActionButton>
                    <S.ActionButton
                      isReject
                      onClick={() => documentReject({ id: document.id! })}
                    >
                      Reject
                    </S.ActionButton>
                  </>
                )}
              </S.UploadedFileInfoWrapper>
              <S.CloseIcon
                onClick={() => handleDeleteDocumentClick(document.id!)}
              />
            </S.UploadedSignatureFileWrapper>
          )
        })}

        {inputStatus === InputState.EDITABLE && (
          <>
            <S.FileInputContainer
              ref={fileInput}
              name={atomName}
              onChange={handleUploadChange}
              placeholder={placeholder}
              type="file"
            />
            <S.FileWrapper
              data-tooltip-content="Open file in new tab"
              data-tooltip-id="file-tooltip"
              data-tooltip-place="left"
              onClick={handleUploadClick}
            >
              <SvgUpload />
              <S.InfoWrapper>{placeholder}</S.InfoWrapper>
            </S.FileWrapper>
          </>
        )}
      </S.Wrapper>
    )
  }

  return null
}
