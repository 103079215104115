import { useField } from 'formik'
import { Props as SelectProps, GroupBase } from 'react-select'
import { Select } from 'ui/Select/Select'
import { FieldWrapper, Error } from '../components'

export function FormSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  editorProps,
}: {
  name: string
  editorProps: SelectProps<Option, IsMulti, Group>
}) {
  const [field, meta] = useField(name)

  return (
    <FieldWrapper>
      <Select {...editorProps} isValid={!meta.error ?? !field.value} />
      <Error message={meta.error} />
    </FieldWrapper>
  )
}
