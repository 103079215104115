import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const TransferCard = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  max-width: 50%;

  & > * {
    margin: 6px 0;
  }

  ${media(size.mobile)} {
    max-width: 100%;
  }
`
)

export const Header = styled.div`
  text-align: center;
  & > * {
    margin: 4px 0;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: 18px;
  font-weight: 700;
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.gray};
  font-size: 15px;
`
