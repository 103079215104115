import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 32px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 42px;

  ${media(size.tablet)} {
    width: 100%;
  }

  ${media(size.mobile)} {
    padding: 16px;
    gap: 32px;
  }
`
)

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`

export const ColumnFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled.h4`
  font-size: 24px;
  font-weight: 800;
  line-height: 40px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.black};
`

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const BalanceLabel = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
`

export const FieldLabel = styled.label`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`

export const FormFielsWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  ${media(size.mobile)} {
    flex-direction: column;
  }
`
)

export const FormFieldWrapper = styled(ColumnFlexWrapper)(
  ({ theme: { media, size } }) => `
  width: 50%;
  
  ${media(size.mobile)} {
    width: 100%;
  }
`
)
