import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 21 20"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.5478 6.98483H19.25C19.25 4.15383 17.4689 2.5 14.4511 2.5H6.54889C3.53111 2.5 1.75 4.15383 1.75 6.94872V13.0513C1.75 15.8462 3.53111 17.5 6.54889 17.5H14.4511C17.4689 17.5 19.25 15.8462 19.25 13.0513V12.7913H15.5478C13.8296 12.7913 12.4367 11.4979 12.4367 9.9025C12.4367 8.30707 13.8296 7.01372 15.5478 7.01372V6.98483ZM15.5478 8.22701H18.5967C18.9575 8.22701 19.25 8.49861 19.25 8.83365V10.9425C19.2458 11.2759 18.9557 11.5452 18.5967 11.5491H15.6178C14.7479 11.56 13.9873 11.007 13.79 10.2203C13.6912 9.73191 13.8299 9.22797 14.1689 8.84351C14.508 8.45906 15.0127 8.2334 15.5478 8.22701ZM15.68 10.4441H15.9678C16.3372 10.4441 16.6367 10.1661 16.6367 9.82306C16.6367 9.48004 16.3372 9.20197 15.9678 9.20197H15.68C15.5033 9.20004 15.3332 9.26387 15.2075 9.3792C15.0818 9.49453 15.0111 9.65177 15.0111 9.81584C15.0111 10.16 15.3093 10.4402 15.68 10.4441ZM5.89556 6.98483H10.8344C11.2039 6.98483 11.5033 6.70676 11.5033 6.36375C11.5033 6.02073 11.2039 5.74266 10.8344 5.74266H5.89556C5.52915 5.74263 5.23093 6.01633 5.22667 6.35652C5.22664 6.70073 5.52488 6.98088 5.89556 6.98483Z"
      fill="#FFD265"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgWallet)
export default Memo
