import styled from 'styled-components'
import { SvgJunaLogo as Logo } from 'ui/icons'

export const FooterWrapper = styled.footer(
  ({ theme: { media, size, color } }) => `
    background-color: ${color.white};
    padding: 53px 0px 60px 0px;

    ${media(size.tablet)} {
      padding: 32px 0px;
    }
`
)

export const SvgLogo = styled(Logo)`
  width: 117px;
  height: 38px;
`

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    align-items: flex-start;
    gap: 210px;

    ${media(size.tablet)} {
      gap: 0; 
      justify-content: space-between;
    }

    ${media(size.mobile)} {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
`
)

export const Title = styled.h6(
  ({ theme: { media, size, color } }) => `
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${color.black};
    margin-bottom: 8px;

    ${media(size.tablet)} {
      margin-bottom: 12px;
    }
`
)

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.gray};
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
`

export const ColumnsWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  gap: 95px;

  ${media(size.mobile)} {
    gap: 0 64px;
  }

  ${media(size.mobile)} {
    gap: 0;
    flex-wrap: wrap;
    width: 100%;
  }
`
)

export const CategoryWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media(size.tablet)} {
      gap: 12px;
    }

    ${media(size.mobile)} {
      width: 100%;
      gap: 8px;
      align-items: center;
      justify-content: center;
    }
`
)

export const LinksWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media(size.tablet)} {
      gap: 12px;
    }

    ${media(size.mobile)} {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 32px;
    }
`
)

export const SocialLinkItem = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.gray};
  font-size: 14px;
  font-weight: 600;
`

export const SocialLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Line = styled.div(
  ({ theme: { media, size, color } }) => `
    width: 100%;
    background-color: ${color.line};
    height: 1px;
    margin: 24px 0px;
    display: none;

    ${media(size.mobile)} {
      display: block;
    }
`
)
