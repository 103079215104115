import { FC, useRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAccount, useDisconnect, useNetwork } from 'wagmi'
import { AppRoutes } from 'constants/app'
import { AuthStepper } from 'features/AuthStepper/AuthStepper'
import { PinGuard } from 'features/PinGuard/PinGuard'
import {
  useAuthStatus,
  usePin,
  useTokenBalance,
  useOutsideClick,
  useSelector,
} from 'hooks'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { isLegalGate } from 'store/application/application.selectors'
import { AddressChip } from 'ui/AddressChip/AddressChip'
import { Avatar } from 'ui/Avatar/Avatar'
import { Button } from 'ui/Button/Button'
import { toHumanNumber } from 'utils/toHumanNumber'
import * as S from './style'

type ProfileDropdownProps = {
  onClose: () => void
}

export type FormValues = {
  email: string
  pin: string
}

const profileLinks = [
  {
    label: 'My contracts',
    link: AppRoutes.Contracts,
  },
  {
    label: 'My contacts',
    link: AppRoutes.MyContacts,
  },
  {
    label: 'Settings',
    link: AppRoutes.Settings,
  },
]

export const ProfileDropdown: FC<ProfileDropdownProps> = (props) => {
  const { onClose } = props

  const { t } = useTranslation('featureLayout')

  const navigate = useNavigate()

  const { address } = useAccount()

  const { chain } = useNetwork()

  const balance = useTokenBalance(getJunaTokenAddress(chain?.id), address)

  const { disconnect } = useDisconnect()

  const { authStatus } = useAuthStatus()

  const { pinNeedsCheck } = usePin()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const isUserLegalGate = useSelector(isLegalGate)

  useOutsideClick(wrapperRef, onClose)

  const handleDisconnectClick = useCallback(() => {
    onClose()
    disconnect()
  }, [disconnect, onClose])

  const handleNavigateBtnClick = useCallback(
    (link: string) => {
      navigate(link)
      onClose()
    },
    [navigate, onClose]
  )

  const bodyContent = useMemo(() => {
    if (authStatus !== 'authorized') {
      return <AuthStepper authStatus={authStatus} />
    }

    if (pinNeedsCheck) {
      return <PinGuard />
    }

    return (
      <>
        {profileLinks.map(({ label, link }) => (
          <Button
            key={label}
            onClick={() => handleNavigateBtnClick(link)}
            variant="transparent"
          >
            {label}
          </Button>
        ))}
        {isUserLegalGate && (
          <Button
            onClick={() => handleNavigateBtnClick(AppRoutes.LegalGateDashboard)}
            variant="transparent"
          >
            Legal Gate Dashboard
          </Button>
        )}
      </>
    )
  }, [authStatus, handleNavigateBtnClick, isUserLegalGate, pinNeedsCheck])

  return (
    <S.Container ref={wrapperRef}>
      <S.Header>
        <Avatar address={address!} />
        <S.Title>{toHumanNumber(balance)} JUNA</S.Title>
        {address && <AddressChip address={address} />}
      </S.Header>
      <S.Body>{bodyContent}</S.Body>
      <S.LanguageButton />
      <S.Footer>
        <Button onClick={handleDisconnectClick} variant="transparent">
          {t('Disconnect')}
        </Button>
      </S.Footer>
    </S.Container>
  )
}
