import i18n, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import common from 'locales/en/common.json'
import * as features from 'locales/en/features'
import * as modals from 'locales/en/modals'
import * as pages from 'locales/en/pages'
import * as ui from 'locales/en/ui'

export const resources = {
  en: {
    ...pages,
    ...features,
    ...modals,
    ...ui,
    common,
  },
} as const

export const defaultNS = 'common'

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    ns: Object.keys(resources.en),
    defaultNS,
    resources,
  })

export default i18n
