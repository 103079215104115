import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Button } from 'ui/Button/Button'
import { Chip } from 'ui/Chip/Chip'
import { shortenAddress } from 'utils/shortenAddress'
import * as S from './style'

type ContractCardProps = {
  title: string
  status: AtomAtomContractListStatus
  agreementAmount: number
  executionStatus: string | null
  id: string
  date: string
  initiatorAddress: string
  initiatorName: string | null
}

const StatusDictionary: Record<string, string> = {
  on_approval: 'Awaiting approval',
  draft: 'Draft',
  finished: 'Finished',
  active: 'Active',
  paused: 'Dispute',
  dispute: 'Dispute',
} as const

export const ContractCard = (props: ContractCardProps) => {
  const {
    title,
    executionStatus = 'active',
    id,
    status,
    date,
    initiatorAddress,
  } = props

  const { t } = useTranslation('pageLegalGateDashboard')

  const navigate = useNavigate()

  const onApproveHandler = () => {
    if (status !== 'on_approval') {
      return
    } else {
      return navigate(`${AppRoutes.LegalGate}/${id}`)
    }
  }

  const isDispute = useMemo(
    () => executionStatus === 'dispute' || executionStatus === 'paused',
    [executionStatus]
  )

  const currentStatus = status === 'execution' ? executionStatus : status
  const isStatusNull = currentStatus === null ? 'active' : currentStatus

  const isActive = useMemo(() => isStatusNull === 'active', [isStatusNull])

  return (
    <S.StyledCard onClick={onApproveHandler}>
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
      </S.TitleContainer>
      <S.InfoWrapper>
        <S.DescriptionWrapper>
          <S.Description>{t('Status')}</S.Description>
          {isDispute || status === 'on_approval' ? (
            <Chip variant="danger">{StatusDictionary[isStatusNull]}</Chip>
          ) : (
            <Chip variant="gray">{StatusDictionary[isStatusNull]}</Chip>
          )}
          <S.Description>{t('Date of creation')}</S.Description>
          {dayjs(date).format('DD.MM.YYYY')}
          <S.Description>{t('Issuer')}</S.Description>
          <S.InfoFieldWrapper>
            <Chip variant="gray">{shortenAddress(initiatorAddress)}</Chip>
          </S.InfoFieldWrapper>
        </S.DescriptionWrapper>
      </S.InfoWrapper>
      {isDispute ? (
        <S.ButtonsWrapper>
          <Button
            onClick={() => navigate(`${AppRoutes.LegalGate}/${id}`)}
            variant="red"
          >
            {t('To dispute')}
          </Button>
        </S.ButtonsWrapper>
      ) : null}
      {isActive ? (
        <S.ButtonsWrapper>
          <Button
            onClick={() => navigate(`${AppRoutes.LegalGate}/${id}`)}
            variant="gray"
          >
            {t('All good')}
          </Button>
        </S.ButtonsWrapper>
      ) : null}
    </S.StyledCard>
  )
}
