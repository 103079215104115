import React, { MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAtomAtomContractList } from 'api/omni/endpoints'
import { AtomAtomContractListStatus, ContractLogData } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { SvgRedPlus } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { shortenAddress } from 'utils/shortenAddress'
import { CollapsedBlock } from '../CollapsedBlock/CollapsedBlock'

import * as S from './style'

const ContractStatusesDictionary: Record<AtomAtomContractListStatus, string> = {
  draft: 'Draft',
  on_approval: 'On approval',
  on_deployment: 'On deployment',
  execution: 'Execution',
  finished: 'Completed',
} as const

export const MyContractsBlock = () => {
  const navigate = useNavigate()

  const {
    data: atomContracts,
    isLoading: isAtomContractsLoading,
    isSuccess: isAtomContractsLoaded,
    refetch,
  } = useAtomAtomContractList(
    {
      status: 'execution',
    },
    {
      query: {
        keepPreviousData: true,
        enabled: false,
      },
    }
  )

  const { t } = useTranslation('pageUserDashboard')

  const actionLabelDataDictionary = useMemo(
    () => ({
      aadoc_rejected: {
        label: t('You rejected to sign the document'),
        description: (args: ContractLogData) =>
          t('Document has been rejected by member'),
      },
      aadoc_signed: {
        label: t('You signed the document'),
        description: (args: ContractLogData) =>
          t('Document has been signed by member'),
      },
      aadoc_uploaded: {
        label: t("You've uploaded a document"),
        description: (args: ContractLogData) =>
          t('Document has been uploaded by member'),
      },
      contract_created: {
        label: t('You created a contract'),
        description: (args: ContractLogData) =>
          t('Contract has been created by member', {
            initiatorAddress: args.initiatorAddress
              ? shortenAddress(args.initiatorAddress as string)
              : '',
          }),
      },
      contract_deployed: {
        label: t('You deployed the contract'),
        description: (args: ContractLogData) =>
          t('Contract has been deployed by member'),
        icon: null,
      },
      member_added: {
        label: t('You added to the contract as member'),
        description: (args: ContractLogData) =>
          t('Added a new member to the contract', {
            addedMemberAddress: args.addedMemberAddress
              ? shortenAddress(args.addedMemberAddress as string)
              : '',
          }),
      },
      member_accepted: {
        label: t('You signed a contract'),
        description: (args: ContractLogData) =>
          t('Contract has been signed by member'),
      },
      member_rejected: {
        label: t('You rejected to sign the contract'),
        description: (args: ContractLogData) =>
          t('Contract has been rejected by member'),
      },
      status_changed: {
        label: t('You change the status of the contract'),
        description: (args: ContractLogData) =>
          t('Contract status has been changed', {
            statusBefore:
              ContractStatusesDictionary[
                args.statusBefore as AtomAtomContractListStatus
              ],
            statusAfter:
              ContractStatusesDictionary[
                args.statusAfter as AtomAtomContractListStatus
              ],
          }),
      },
      bank_deposit_claimed: {
        description: (args: ContractLogData) => t('Bank deposit claimed'),
      },
      bank_deposit_claim_requested: {
        description: (args: ContractLogData) => t('Deposit refund requested'),
      },
      bank_deposit: {
        description: (args: ContractLogData) => t('Deposited'),
      },
      execution_status_changed: {
        description: (args: ContractLogData) => {
          if (args.newExecutionStatus === 'dispute') {
            return t('The user started a dispute', {
              changedBy: shortenAddress(args.changedBy as string),
            })
          } else {
            return t('The user closed a dispute', {
              changedBy: shortenAddress(args.changedBy as string),
            })
          }
        },
      },
      bank_credit: {
        description: (args: ContractLogData) => {
          return t('The bank issued a loan', args)
        },
      },
    }),
    [t]
  )

  const handleCollapse = useCallback(
    (isCollapsed: boolean) => {
      if (isCollapsed) {
        refetch()
      }
    },
    [refetch]
  )

  const handleClickPlus = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      navigate('/contract-constructor-v2')
    },
    [navigate]
  )

  const handleClickContract = useCallback(
    (atomContractId: string) => () => {
      navigate(`${AppRoutes.AtomContract}/${atomContractId}`)
    },
    [navigate]
  )

  return (
    <CollapsedBlock
      onCollapse={handleCollapse}
      rightElement={
        <S.RedPlusButton onClick={handleClickPlus}>
          <SvgRedPlus />
        </S.RedPlusButton>
      }
      title="My active contracts"
    >
      {isAtomContractsLoading && <Loader />}
      {isAtomContractsLoaded && atomContracts.results.length === 0 && (
        <S.NoContracts>
          You have no contracts yet.
          <S.CreateOneLink href="#" onClick={handleClickPlus}>
            Create your first contract
          </S.CreateOneLink>
        </S.NoContracts>
      )}
      {isAtomContractsLoaded &&
        atomContracts.results.map((atomContract) => {
          const { description } =
            actionLabelDataDictionary[
              atomContract.logs[0]
                .type as keyof typeof actionLabelDataDictionary
            ]
          return (
            <S.ContractContainer
              key={atomContract.id}
              onClick={handleClickContract(atomContract.id)}
            >
              <S.Content>
                {atomContract.name}
                <S.Label>
                  {atomContract.logs[0].data &&
                    description(atomContract.logs[0].data)}
                </S.Label>
              </S.Content>
              <S.StatusTag>
                {atomContract.executionStatus === 'paused' ||
                atomContract.executionStatus === 'dispute'
                  ? 'Dispute'
                  : 'Active'}
              </S.StatusTag>
            </S.ContractContainer>
          )
        })}
    </CollapsedBlock>
  )
}
