import { createSlice } from '@reduxjs/toolkit'
import { accountUsersWhoamiRetrieve } from 'api/omni/endpoints'
import { Whoami } from 'api/omni/model'
import { StoreNameSpace } from 'constants/app'
import { createAppAsyncThunk } from 'store/createAppAsyncThunk'
import { Roles } from 'types/roles'

type InitialState = {
  pinSet: boolean
  roles: Roles[]
  userDataLoadStatus: 'idle' | 'loading' | 'success' | 'error'
  userData: Whoami | null
}

export const fetchUserData = createAppAsyncThunk(
  `${StoreNameSpace.User}/fetchUserData`,
  async () => await accountUsersWhoamiRetrieve()
)

const initialState: InitialState = {
  pinSet: false,
  roles: [],
  userDataLoadStatus: 'idle',
  userData: null,
}

export const userSlice = createSlice({
  name: StoreNameSpace.User,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.userDataLoadStatus = 'loading'
    })
    builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
      state.userDataLoadStatus = 'success'
      state.pinSet = payload.pinSet
      state.roles = payload.role
      state.userData = payload
    })
    builder.addCase(fetchUserData.rejected, (state) => {
      state.userDataLoadStatus = 'error'
    })
  },
})
