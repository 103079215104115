import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ApiOmni } from 'api/types'
import { useSelector } from 'hooks/useSelector/useSelector'
import {
  dropLegalGate,
  getLegalGate,
  storeLegalGate,
} from 'libs/legal-gate-storage/legal-gate-storage'
import { selectLegalGate as selector } from 'store/application/application.selectors'
import {
  resetLegalGate,
  setLegalGate,
} from 'store/application/application.slice'

export const useLegalGateBasket = () => {
  const legalGate = useSelector(selector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLegalGate(getLegalGate()))
  }, [dispatch])

  const selectLegalGate = useCallback(
    (legalGate: ApiOmni.LegalGate) => {
      dispatch(setLegalGate(legalGate))
      storeLegalGate(legalGate)
    },
    [dispatch]
  )

  const reset = useCallback(() => {
    dispatch(resetLegalGate())
    dropLegalGate()
  }, [dispatch])

  return useMemo(
    () => ({
      legalGate,
      selectLegalGate,
      reset,
    }),
    [legalGate, selectLegalGate, reset]
  )
}
