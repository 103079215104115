import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size, color } }) => `
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: flext-start;
  justify-content: space-between;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${color.bg};
  }

  ${media(size.mobile)} {
    flex-direction: column;
    gap: 16px;
  }
`
)

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: flex-start;
  gap: 12px;

  ${media(size.mobile)} {
    align-items: center;
  }
`
)

export const InfoBlock = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
)

export const Title = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.black};
`
