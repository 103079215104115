import { BigNumber, ethers, FixedNumber } from 'ethers'
import { formatNumberWithSeparators } from './formatNumberWithSeparators'

export const toHumanNumber = (value: BigNumber) => {
  return formatNumberWithSeparators(
    FixedNumber.from(value)
      .divUnsafe(FixedNumber.from(ethers.constants.WeiPerEther))
      .round(2)
      .toUnsafeFloat()
  )
}
