import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgMemberRejectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={40}
    viewBox="0 0 40 40"
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#F6F6F9" height={40} rx={20} width={40} />
    <mask
      height={24}
      id="mask0_5575_16691"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={8}
      y={8}
    >
      <rect fill="#D9D9D9" height={24} width={24} x={8} y={8} />
    </mask>
    <g mask="url(#mask0_5575_16691)">
      <path
        d="M25.4 21L24 19.6L26.075 17.5L24 15.425L25.4 14L27.5 16.1L29.575 14L31 15.425L28.9 17.5L31 19.6L29.575 21L27.5 18.925L25.4 21ZM17 20C15.9 20 14.9583 19.6083 14.175 18.825C13.3917 18.0417 13 17.1 13 16C13 14.9 13.3917 13.9583 14.175 13.175C14.9583 12.3917 15.9 12 17 12C18.1 12 19.0417 12.3917 19.825 13.175C20.6083 13.9583 21 14.9 21 16C21 17.1 20.6083 18.0417 19.825 18.825C19.0417 19.6083 18.1 20 17 20ZM9 28V25.2C9 24.6333 9.14583 24.1125 9.4375 23.6375C9.72917 23.1625 10.1167 22.8 10.6 22.55C11.6333 22.0333 12.6833 21.6458 13.75 21.3875C14.8167 21.1292 15.9 21 17 21C18.1 21 19.1833 21.1292 20.25 21.3875C21.3167 21.6458 22.3667 22.0333 23.4 22.55C23.8833 22.8 24.2708 23.1625 24.5625 23.6375C24.8542 24.1125 25 24.6333 25 25.2V28H9ZM11 26H23V25.2C23 25.0167 22.9542 24.85 22.8625 24.7C22.7708 24.55 22.65 24.4333 22.5 24.35C21.6 23.9 20.6917 23.5625 19.775 23.3375C18.8583 23.1125 17.9333 23 17 23C16.0667 23 15.1417 23.1125 14.225 23.3375C13.3083 23.5625 12.4 23.9 11.5 24.35C11.35 24.4333 11.2292 24.55 11.1375 24.7C11.0458 24.85 11 25.0167 11 25.2V26ZM17 18C17.55 18 18.0208 17.8042 18.4125 17.4125C18.8042 17.0208 19 16.55 19 16C19 15.45 18.8042 14.9792 18.4125 14.5875C18.0208 14.1958 17.55 14 17 14C16.45 14 15.9792 14.1958 15.5875 14.5875C15.1958 14.9792 15 15.45 15 16C15 16.55 15.1958 17.0208 15.5875 17.4125C15.9792 17.8042 16.45 18 17 18Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgMemberRejectedIcon)
export default Memo
