import { FC } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { LaunchpadRequest } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import * as S from './style'

type TenderCardProps = Omit<
  LaunchpadRequest,
  'createdAt' | 'updatedAt' | 'file'
>

export const TenderCard: FC<TenderCardProps> = (props) => {
  const { name, description, id } = props

  const navigate = useNavigate()

  const handleTitleClick = () => {
    navigate({
      pathname: AppRoutes.Tender,
      search: `${createSearchParams({
        tenderId: `${id}`,
      })}`,
    })
  }

  return (
    <S.Card>
      <S.HeaderWrapper>
        <S.Header>
          <S.TenderIcon />
          <S.Title onClick={handleTitleClick}>{name}</S.Title>
        </S.Header>
        <S.Description>{description}</S.Description>
      </S.HeaderWrapper>
      <S.FooterWrapper>
        <S.Tag>Open Tender</S.Tag>
        <S.FooterCard>
          <S.FooterContent>
            <div>Investment</div>
            <S.DashedLine />
            <S.Value>10,000 JUNA</S.Value>
          </S.FooterContent>
        </S.FooterCard>
      </S.FooterWrapper>
    </S.Card>
  )
}
