import { FC, useMemo } from 'react'
import { useAtomAtomActionRetrieve } from 'api/omni/endpoints'
import { ContractAtomRequest } from 'api/omni/model'
import {
  DatetimeAtomInputs,
  FileAtomInputs,
  NumberAtomInputs,
  TextAtomInputs,
  PaymentAtomInputs,
  AddressAtomInputs,
  FileListAgreementAtomInputs,
  EmptyAtomInputs,
} from 'features/AtomInputs'
import { SignatureDocumentsInputs } from 'features/AtomInputs/SignatureDocumentsInputs/SignatureDocumentsInputs'
import { Loader } from 'ui/Loader/Loader'
import * as S from './style'

type AtomProps = Omit<ContractAtomRequest, 'id'> & {
  contractId: string
  id: string
}

export const Atom: FC<AtomProps> = (props) => {
  const { atomActionId, contractId, id } = props

  const {
    data: atomInfo,
    isLoading: isAtomLoading,
    isSuccess: isAtomLoaded,
  } = useAtomAtomActionRetrieve(
    atomActionId,
    {
      contract: contractId,
    },
    {
      query: {
        enabled: Boolean(atomActionId),
      },
    }
  )

  const atomContent = useMemo(() => {
    if (!atomInfo) {
      return null
    }

    return atomInfo.inputs.map((input) => {
      const { name } = input

      const inputInfo = atomInfo.inputs.find(
        (atom) => atom.name === name
      ) as any

      if (!inputInfo || !name) {
        return null
      }

      const { label, canBeVariable, type, actionElements, buttons, state } =
        inputInfo

      switch (type) {
        case 'STRING':
          return (
            <TextAtomInputs
              key={name}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
              state={state}
            />
          )
        case 'NUMBER':
          return (
            <NumberAtomInputs
              key={name}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
            />
          )
        case 'FILE':
          return (
            <FileAtomInputs
              key={name}
              atomName={name}
              id={id}
              placeholder={label}
              state={state}
            />
          )
        case 'DATETIME':
          return (
            <DatetimeAtomInputs
              key={name}
              actionElements={actionElements}
              atomName={name}
              canBeVariable={canBeVariable}
              id={id}
              placeholder={label}
            />
          )
        case 'BUTTONS_TOGGLE':
          return (
            <PaymentAtomInputs
              key={name}
              atomName={name}
              buttons={buttons!}
              id={id}
              inputStatus={state}
              label={label}
            />
          )
        case 'ADDRESS':
          return (
            <AddressAtomInputs
              key={name}
              actionElements={actionElements}
              atomId={id}
              atomName={name}
              canBeVariable={canBeVariable}
              placeholder={label}
              state={state}
            />
          )
        case 'FILELISTAGREEMENT':
          return (
            <FileListAgreementAtomInputs
              key={name}
              atomName={name}
              id={id}
              placeholder={label}
              state={state}
            />
          )
        case 'EMPTY':
          return (
            <EmptyAtomInputs
              key={name}
              atomName={name}
              buttons={buttons}
              id={id}
            />
          )
        case 'SIGNATURE_DOCUMENTS':
          return (
            <SignatureDocumentsInputs
              key={name}
              atomName={name}
              id={id}
              inputStatus={state}
              placeholder={label}
            />
          )
        default:
          return null
      }
    })
  }, [atomInfo, id])

  if (isAtomLoading) {
    return <Loader />
  }

  if (isAtomLoaded) {
    return (
      <S.Wrapper>
        {atomContent}
        <S.BottomBlock>
          <S.Label>{atomInfo.name}</S.Label>
        </S.BottomBlock>
      </S.Wrapper>
    )
  }

  return null
}
