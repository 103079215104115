import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useProposalsProposalList } from 'api/omni/endpoints'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { ResultsBody, ResultsHeader } from './components'
import * as S from './style'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Voting Proposal for RS',
    url: AppRoutes.GovernorVoting,
  },
]

export const VotingResults: FC = () => {
  const { t } = useTranslation('pageVotingResults')

  const {
    data: votings,
    isLoading: isVotingLoading,
    isSuccess: isVotingLoaded,
  } = useProposalsProposalList({
    status: 'finished',
  })

  if (isVotingLoading) {
    return <Loader />
  }

  if (isVotingLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Voting results')}>
        {votings.results.length > 0 && (
          <S.Wrapper>
            <ResultsHeader votingParams={votings.results[0].params!} />
            <ResultsBody votings={votings.results} />
          </S.Wrapper>
        )}
      </PageWrapper>
    )
  }

  return null
}
