import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MobileMenuLink = styled(Link)<{ $isSubmenuLink?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  font-size: ${(props) => (props.$isSubmenuLink ? '12px' : '15px')};
  font-weight: ${(props) => (props.$isSubmenuLink ? '500' : '700')};
  line-height: ${(props) => (props.$isSubmenuLink ? '200%' : '160%')};
  color: ${({ theme }) => theme.color.black};
  background-color: transparent;
  text-decoration: none;
  padding: ${(props) => (props.$isSubmenuLink ? '12px 40px' : '12px')};
  border: 0;
  cursor: pointer;

  &:hover {
    color: rgba(55, 45, 76, 0.7);
  }

  &:focus {
    outline: none;
  }

  svg path {
    fill: ${({ theme }) => theme.color.black};
  }

  &:hover {
    svg path {
      fill: rgba(55, 45, 76, 0.7);
    }
  }
`
