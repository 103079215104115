import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgStatsRemainder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="white" height={24} rx={8} width={24} />
    <mask
      height={16}
      id="mask0_6437_19137"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={16}
      x={4}
      y={4}
    >
      <rect fill="#D9D9D9" height={16} width={16} x={4} y={4} />
    </mask>
    <g mask="url(#mask0_6437_19137)">
      <path
        d="M14 17.3333C12.5111 17.3333 11.25 16.8167 10.2167 15.7833C9.18335 14.75 8.66669 13.4889 8.66669 12C8.66669 10.5111 9.18335 9.24999 10.2167 8.21666C11.25 7.18332 12.5111 6.66666 14 6.66666C15.4889 6.66666 16.75 7.18332 17.7834 8.21666C18.8167 9.24999 19.3334 10.5111 19.3334 12C19.3334 13.4889 18.8167 14.75 17.7834 15.7833C16.75 16.8167 15.4889 17.3333 14 17.3333ZM8.66669 17.1667C7.48891 16.8555 6.5278 16.2222 5.78335 15.2667C5.03891 14.3111 4.66669 13.2222 4.66669 12C4.66669 10.7778 5.03891 9.68888 5.78335 8.73332C6.5278 7.77777 7.48891 7.14443 8.66669 6.83332V8.23332C7.86669 8.5111 7.22224 8.99443 6.73335 9.68332C6.24446 10.3722 6.00002 11.1444 6.00002 12C6.00002 12.8555 6.24446 13.6278 6.73335 14.3167C7.22224 15.0055 7.86669 15.4889 8.66669 15.7667V17.1667Z"
        fill="#4D9BFF"
      />
    </g>
  </svg>
)
const Memo = memo(SvgStatsRemainder)
export default Memo
