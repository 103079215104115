import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAtomAtomContractList,
  useAtomStatsStatusRetrieve,
} from 'api/omni/endpoints'
import { AtomAtomContractListStatus } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Pagination } from 'ui/Pagination/Pagination'
import { TabType } from 'ui/Tabs/types'
import { ContractCard } from './components'
import * as S from './style'

const ITEMS_ON_PAGE = 12

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: 'Legal Gate Dashboard',
    url: AppRoutes.LegalGateDashboard,
  },
]

const AtomContractStatuses = {
  Draft: 'draft',
  OnApproval: 'on_approval',
  Execution: 'execution',
  Finished: 'finished',
} as const

export const LegalGateDashboard = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { t } = useTranslation('pageLegalGateDashboard')

  const [selectedTab, setSelectedTab] = useState<AtomAtomContractListStatus>(
    AtomContractStatuses.OnApproval
  )

  const {
    data: statuses,
    isLoading: isStatusesLoading,
    isSuccess: isStatusesLoaded,
  } = useAtomStatsStatusRetrieve()

  const {
    data: atomContracts,
    isLoading: isAtomContractsLoading,
    isRefetching: isAtomContractsRefetching,
    isSuccess: isAtomContractsLoaded,
  } = useAtomAtomContractList(
    {
      asLegalGate: true,
      status: selectedTab,
    },
    {
      query: {
        keepPreviousData: true,
      },
    }
  )

  const tabs: TabType[] = useMemo(
    () => [
      {
        label: t('On approval'),
        value: AtomContractStatuses.OnApproval,
        count: statuses?.onApproval,
        color: 'blue',
      },
      {
        label: t('Execution'),
        value: AtomContractStatuses.Execution,
        count: statuses?.execution,
        color: 'green',
      },

      {
        label: t('Completed'),
        value: AtomContractStatuses.Finished,
        count: statuses?.finished,
        color: 'gray',
      },
    ],

    [statuses, t]
  )

  const handleSelectTab = useCallback(
    (status: string) => {
      setSelectedTab(status as AtomAtomContractListStatus)
    },
    [setSelectedTab]
  )

  if (isAtomContractsLoading || isStatusesLoading) {
    return <Loader />
  }

  if (isAtomContractsLoaded && isStatusesLoaded) {
    const totalPages = Math.ceil(atomContracts.results.length / ITEMS_ON_PAGE)

    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Legal Gate Dashboard')}>
        <S.Wrapper>
          <S.ContentWrapper>
            <S.Tabs
              onTabSelect={handleSelectTab}
              selectedTab={selectedTab}
              tabs={tabs}
            />
            {isAtomContractsRefetching ? (
              <Loader />
            ) : (
              <S.ContractsWrapper>
                {atomContracts.results.length > 0 ? (
                  atomContracts.results.map((contract) => (
                    <ContractCard
                      key={contract.id}
                      agreementAmount={contract.agreementAmount}
                      date={contract.createdAt}
                      executionStatus={contract.executionStatus}
                      id={contract.id}
                      initiatorAddress={contract.initiatorAddress}
                      initiatorName={contract.initiatorName}
                      status={contract.status}
                      title={contract.name}
                    />
                  ))
                ) : (
                  <S.NoContractsWrapper>
                    <S.Label>No contracts</S.Label>
                  </S.NoContractsWrapper>
                )}
              </S.ContractsWrapper>
            )}

            {totalPages > 1 && (
              <S.PaginationWrapper>
                <S.TotalItemsLabel>
                  {t('Total items', { count: atomContracts.results.length })}
                </S.TotalItemsLabel>
                <Pagination
                  currentPage={currentPage}
                  onChangePage={(page) => setCurrentPage(page)}
                  totalPages={totalPages}
                />
              </S.PaginationWrapper>
            )}
          </S.ContentWrapper>
        </S.Wrapper>
      </PageWrapper>
    )
  }

  return null
}
