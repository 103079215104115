import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

type EmailSentMessageProps = {
  isLoading: boolean
  onResend: () => void
}

export const EmailSentMessage: FC<EmailSentMessageProps> = (props) => {
  const { isLoading, onResend } = props

  const { t } = useTranslation('pageSettings')

  return (
    <S.Wrapper>
      <S.Title>{t('Check your email')}</S.Title>
      <S.Button isLoading={isLoading} onClick={onResend} variant="gray">
        {t('Resend email message')}
      </S.Button>
    </S.Wrapper>
  )
}
