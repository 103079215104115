import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  gap: 25px;

  ${media(size.tablet)} {
    flex-direction: column;
  }
`
)
