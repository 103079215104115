import { FC } from 'react'
import { Project } from 'api/omni/model'
// import { ProjectCard } from './components'
import { ProjectCardV2 } from './components/ProjectCardV2/ProjectCardV2'
import * as S from './style'

type ProjectsProps = {
  projects: Project[]
}

export const Projects: FC<ProjectsProps> = (props) => {
  const { projects } = props

  return (
    <S.Wrapper>
      {projects.map(({ id, name, description, status, amountJuna, type }) => (
        <ProjectCardV2
          key={id}
          amountJuna={amountJuna}
          description={description}
          id={id}
          name={name}
          status={status}
          type={type}
        />
      ))}
    </S.Wrapper>
  )
}
