import { FC } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'constants/app'
import { Chains } from 'constants/blockchain'
import { Button } from 'ui/Button/Button'
import * as S from './style'

type ContractInfoProps = {
  status: string
  createdAt: string
  chain?: number
  interestedRate?: number
  amount?: number
  contractId: string
}

const StatusDictionary: Record<string, string> = {
  on_approval: 'On Approval',
  on_deployment: 'Deploying',
} as const

export const ContractInfo: FC<ContractInfoProps> = (props) => {
  const { createdAt, chain, interestedRate, amount, contractId, status } = props

  const navigate = useNavigate()

  const handleViewBtnClick = () => {
    navigate(`${AppRoutes.AtomContract}/${contractId}`)
  }

  return (
    <S.Wrapper>
      <S.Title>Contract Information</S.Title>
      <S.ListWrapper>
        <S.ListItem>
          <S.ListItemLabel>Status</S.ListItemLabel>
          <S.DashedLine />
          <S.ListItemLabel>
            {StatusDictionary[status] || status === 'finished'
              ? 'Closed'
              : 'Active'}
          </S.ListItemLabel>
        </S.ListItem>

        <S.ListItem>
          <S.ListItemLabel>Created At</S.ListItemLabel>
          <S.DashedLine />
          <S.ListItemLabel>
            {dayjs(createdAt).format('DD.MM.YYYY')}
          </S.ListItemLabel>
        </S.ListItem>

        {chain && (
          <S.ListItem>
            <S.ListItemLabel>Chain</S.ListItemLabel>
            <S.DashedLine />
            <S.ListItemLabel>{Chains[chain].name}</S.ListItemLabel>
          </S.ListItem>
        )}

        {interestedRate && (
          <S.ListItem>
            <S.ListItemLabel>Interested Rate</S.ListItemLabel>
            <S.DashedLine />
            <S.ListItemLabel>{interestedRate}% per year</S.ListItemLabel>
          </S.ListItem>
        )}

        {amount && (
          <S.ListItem>
            <S.ListItemLabel>Deposit amount Juna</S.ListItemLabel>
            <S.DashedLine />
            <S.ListItemLabel>{amount} Juna</S.ListItemLabel>
          </S.ListItem>
        )}
      </S.ListWrapper>
      <Button onClick={handleViewBtnClick}>View contract</Button>
    </S.Wrapper>
  )
}
