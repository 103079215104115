import styled, { css } from 'styled-components'
import { SvgTick } from 'ui/icons'

export const StepContainer = styled.div<{
  isCompleted: boolean
  isCurrent: boolean
  withButtons?: boolean
}>(
  ({ theme: { media, size, color }, isCompleted, isCurrent }) => `
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 0;

    color: ${isCurrent || isCompleted ? color.black : color.line};

    &:not(:first-child) {
      flex: 1;

      &:before {
        display: block;
      }
    }

    ${media(size.mobile)} {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:not(:first-child) {
        flex: 1;
        margin-left: 0;

        &:before {
          display: none;
        }
      }
    }
`
)

export const StepContent = styled.div<{
  vertical: boolean
  withButtons?: boolean
  isCurrent?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  transition: background-color 0.3s;

  ${({ vertical }) =>
    vertical &&
    css`
      width: 100%;
    `}

  ${({ withButtons, isCurrent }) =>
    withButtons &&
    css`
      padding: 6px 12px;

      &:hover {
        cursor: ${isCurrent ? 'pointer' : 'auto'};
        background-color: ${({ theme }) =>
          isCurrent ? theme.color.bg : 'none'};
      }
    `}
`

export const StepNumber = styled.div<{
  isCompleted: boolean
  isCurrent: boolean
}>`
  border-radius: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.color.white : theme.color.line};

  background-color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.color.red : ''};

  border: 1px solid
    ${({ isCurrent, theme }) =>
      isCurrent ? theme.color.red : theme.color.line};

  ${({ isCompleted, theme }) => {
    if (isCompleted) {
      return css`
        border: 1px solid ${theme.color.green};
      `
    }
  }}
`

export const TickIcon = styled(SvgTick)`
  path {
    fill: ${({ theme }) => theme.color.green};
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
`

export const Title = styled.h6`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`
