import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'
import { Select as UISelect } from 'ui/Select/Select'

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

export const Label = styled.span`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
`

export const SelectBlock = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${media(size.tablet)} {
        flex-direction: row;
    }
`
)

export const Select = styled(UISelect)(
  ({ theme: { media, size } }) => `
    ${media(size.tablet)} {
        width: 50%;   
    }

    ${media(size.mobile)} {
        width: 100%;   
    }
`
) as typeof UISelect

export const Button = styled(UIButton)(
  ({ theme: { media, size } }) => `
    ${media(size.tablet)} {
        width: 50%;
    }

    ${media(size.mobile)} {
        width: 100%;
    }
`
)
