import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 450px;
  padding: 24px 12px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
