import { useMemo } from 'react'
import { useDispatch, useSelector } from 'hooks'
import { isWalletProviderModalVisible } from 'store/application/application.selectors'
import { toggleProviderModal } from 'store/application/application.slice'

type UseProviderModal = () => {
  isProviderModalVisible: boolean
  toggleProviderModal: () => void
}

export const useProviderModal: UseProviderModal = () => {
  const dispatch = useDispatch()
  const isVisible = useSelector(isWalletProviderModalVisible)

  return useMemo(
    () => ({
      isProviderModalVisible: isVisible,
      toggleProviderModal: () => dispatch(toggleProviderModal()),
    }),
    [dispatch, isVisible]
  )
}
