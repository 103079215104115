import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  width: 100%;
  border-radius: 0px 0px 6px 6px;
  position: absolute;
  top: 90%;
  left: 0;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.line};
  box-shadow: ${({ theme }) => theme.common.boxShadow};
`

export const Label = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
  padding: 6px;
`

export const Option = styled.div`
  padding: 6px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.mirageLight};
  }
`

export const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.color.white};
  outline: none;
  border: none;
  padding: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
  cursor: pointer;
  transition: color 0.3s;
  width: max-content;

  &:hover {
    color: ${({ theme }) => theme.color.mirageLight};
  }
`
