import {
  useCallback,
  ChangeEvent,
  useMemo,
  MouseEvent,
  useRef,
  FC,
} from 'react'
import DatePicker from 'react-datepicker'
import { ActionInputElement } from 'api/omni/model'
import {
  ConstructorInput,
  ConstructorInputProps,
} from 'libs/constructor/components'
import { FieldDataType } from '../../context/hooks/useConstructorState/types'
import { useConstructorContext } from '../../hooks/useConstructorContext/useConstructorContext'
import { ActionElement } from '../ConstructorInput/ConstructorInput'

export type ConstructorDatetimeProps = Omit<FieldDataType, 'value'> & {
  placeholder?: string
  editorProps?: Omit<
    ConstructorInputProps,
    'name' | 'onChange' | 'value' | 'placeholder'
  >
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  className?: string
  onClick?: (evt: MouseEvent<HTMLInputElement>) => void
  actionElements?: ActionInputElement[]
  atomId?: string
}

export const ConstructorDatetime: FC<ConstructorDatetimeProps> = (props) => {
  const { name, placeholder, onChange, className, actionElements, atomId } =
    props
  const datepickerRef = useRef<DatePicker | null>(null)

  const { setFieldValue, getValue } = useConstructorContext()

  const handleChange = useCallback(
    (date: Date | null, evt: ChangeEvent<HTMLInputElement>) => {
      setFieldValue({
        name,
        value: date ? date.toUTCString() : '',
        atomId,
      })

      onChange?.(evt)
    },
    [name, setFieldValue, onChange, atomId]
  )

  const value = useMemo(() => {
    return getValue({
      name,
      atomId,
    })
  }, [atomId, getValue, name])

  const actionsMap: { [key: string]: () => void } = useMemo(
    () => ({
      datetimeselect: () => {
        datepickerRef.current?.setOpen(true)
      },
    }),
    []
  )

  const actionElementsFn: ActionElement[] = useMemo(() => {
    if (!actionElements) {
      return []
    }

    return actionElements.map((actionElement) => ({
      ...actionElement,
      onClick: actionElement.action
        ? actionsMap[actionElement.action]
        : () => {},
    }))
  }, [actionElements, actionsMap])

  return (
    <DatePicker
      ref={datepickerRef}
      customInput={
        <ConstructorInput
          actionElements={actionElementsFn}
          className={className}
          name={name}
        />
      }
      dateFormat="MMMM d, yyyy h:mm aa"
      onChange={handleChange}
      placeholderText={placeholder}
      showTimeSelect
      timeCaption="time"
      timeFormat="HH:mm"
      timeIntervals={1}
      value={value as string}
      wrapperClassName="datePicker"
    />
  )
}
