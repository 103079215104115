import { FC, useState } from 'react'
import * as S from './style'

type Option = {
  value: string
  label: string
}

type ToggleButtonProps = {
  options: Option[]
  onChange: (value: string) => void
  value: string
  className?: string
  isDisabled?: boolean
}

export const ToggleButton: FC<ToggleButtonProps> = (props) => {
  const { options, onChange, value, className, isDisabled } = props

  const [activeItemValue, setActiveItemValue] = useState(value)

  const handleItemClick = (itemValue: string) => {
    if (isDisabled) {
      return
    }

    if (itemValue !== activeItemValue) {
      setActiveItemValue(itemValue)
      onChange(itemValue)
    }
  }

  return (
    <S.Wrapper className={className} disabled={isDisabled}>
      {options.map(({ label, value }) => {
        return (
          <S.Item
            key={value}
            disabled={isDisabled}
            isActive={activeItemValue === value}
            onClick={() => handleItemClick(value)}
          >
            {label}
          </S.Item>
        )
      })}
    </S.Wrapper>
  )
}
