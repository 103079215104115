import { String } from 'runtypes'

export type PathType = {
  name: string
  context: string
  access: {
    type: string
    idx: number
  }[]
  inputs: {}
}

export type DynamicFieldType = {
  name: string
  context: string
  type: string
}

export const NextContractActionRuntype = String
