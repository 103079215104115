import { rgba } from 'polished'
import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const BalanceCard = styled(Card)`
  width: 366px;
  padding: 19px 28px;
  box-shadow: none;
`

export const BalanceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const BalanceCardContent = styled.div`
  display: flex;
  align-items: baseline;
`

export const BalanceCardTitle = styled.h4`
  color: ${({ theme }) => rgba(theme.color.black, 0.5)};
  font-size: 32px;
  font-weight: 800;
  margin: 0;
  margin-right: 38px;
`

export const BalanceCardSubtitle = styled.h3`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${({ theme }) => theme.color.black};
  font-size: 20px;
  font-weight: 800;
  margin: 0;
`

export const BalanceCardTextWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;

  &:not(:last-child) {
    margin-right: 63px;
  }
`
export const BalanceCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const BalanceCardTextTitle = styled.p`
  color: ${({ theme }) => theme.color.black};
  opacity: 0.5;
  font-size: 11px;
  margin: 0;
`

export const BalanceCardTextSubtitle = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`
