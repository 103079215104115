import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgJunaTokenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={12}
    viewBox="0 0 10 12"
    width={10}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.39765 1.20819C2.39765 0.608781 2.39765 1.8076 2.39765 1.20819C2.39765 0.608781 2.99706 0.00979063 3.59647 0.0157018C5.39471 0.00936584 7.97368 0 8.39177 0C8.99118 0 9.5906 0.597073 9.5906 1.19648C9.5906 1.79589 9.5906 10.1877 9.5906 10.7871C9.5906 11.3865 8.99118 11.9859 8.39177 11.9965C7.94031 12.0044 3.48762 11.9965 1.19882 11.9965C0.599412 11.9965 1.18821e-08 11.3865 1.18821e-08 10.7871C1.18821e-08 8.9144 9.74995e-05 9.00055 1.18821e-08 8.40114C-9.74758e-05 7.80173 0.599705 7.20231 1.19912 7.20231C1.79853 7.20231 2.39736 7.80173 2.39765 8.40114C2.39794 9.00055 2.39765 9.59881 2.39765 9.59881H7.19295V2.40587C7.19295 2.40587 4.7953 2.40701 3.59647 2.40701C2.99706 2.40701 2.39765 1.8076 2.39765 1.20819Z"
      fill="#372D4C"
    />
  </svg>
)
const Memo = memo(SvgJunaTokenIcon)
export default Memo
