import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from 'context'
import { useProviderModal } from 'hooks'
import { AuthStatus } from 'types/app'
import { Stepper } from 'ui/Stepper/Stepper'

type AuthStepperProps = {
  activeStep?: number
  authStatus: Exclude<AuthStatus, 'authorized'>
}

const AuthStatusToStepNum: Record<AuthStepperProps['authStatus'], number> = {
  'not-authorized': 1,
  'wallet-connected': 2,
} as const

export const AuthStepper: FC<AuthStepperProps> = (props) => {
  const { activeStep, authStatus } = props
  const { setupWeb3Token } = useAuthContext()

  const { toggleProviderModal } = useProviderModal()

  const { t } = useTranslation('featureAuthStepper')

  const steps = useMemo(
    () => [
      {
        label: t('Connect your wallet'),
        step: 1,
        title: t('Connect wallet'),
        onClick: toggleProviderModal,
      },
      {
        label: t('Sign In to the platform'),
        step: 2,
        onClick: setupWeb3Token,
        title: t('Sign In'),
      },
    ],
    [toggleProviderModal, setupWeb3Token, t]
  )

  return (
    <Stepper
      activeStep={activeStep || AuthStatusToStepNum[authStatus]}
      steps={steps}
      vertical
    />
  )
}
