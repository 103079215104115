import { FC, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { AdsCategory } from 'api/omni/model'
import { FormValues } from 'pages/AddAds/types'
import { AddressChip } from 'ui/AddressChip/AddressChip'
import { SvgJunaToken } from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import * as S from './style'

type AdsDetailsProps = {
  categories: AdsCategory[]
  address: string
}

export const AdsDetails: FC<AdsDetailsProps> = (props) => {
  const { categories, address } = props

  const { t } = useTranslation('pageAdEdit')

  const { values } = useFormikContext<FormValues>()

  const { name, description, amountjuna, category } = values

  //TODO получать текущую категорию из апи
  const currentCategory = useMemo(() => {
    return categories.filter(
      (categoryItem) => categoryItem.id === Number(category)
    )
  }, [categories, category])

  return (
    <S.Wrapper>
      <S.AdHeader>
        <S.Title>{name || '...'}</S.Title>
        <AddressChip address={address} />
      </S.AdHeader>

      <S.DescriptionWrapper>
        <S.Label isBold>{t('Description')}</S.Label>
        <S.Label>{description || '...'}</S.Label>
      </S.DescriptionWrapper>

      <S.BottomBlock>
        <S.BottomItemWrapper>
          <S.Label isBold>{t('Amount')}</S.Label>
          <S.AmountWrapper>
            <SvgJunaToken />

            <S.Label>
              {Number(amountjuna) > 0
                ? formatNumberWithSeparators(amountjuna)
                : '...'}
            </S.Label>
          </S.AmountWrapper>
        </S.BottomItemWrapper>

        <S.BottomItemWrapper>
          <S.Label isBold>{t('Category')}</S.Label>
          <S.Label>{category ? currentCategory[0].name : '...'}</S.Label>
        </S.BottomItemWrapper>
      </S.BottomBlock>
    </S.Wrapper>
  )
}
