import { createContext, useCallback, useState } from 'react'
import { ContractsModal } from 'modals/ContractsModal/ContractsModal'
import { FCWithChildren } from 'types/app'

type OpenContractsModal = (args: {
  onContractAddressSelect: (contractAddress: string) => void
}) => void

type ContractsContextProps = {
  openContractsModal: OpenContractsModal
}

export const ContractsModalContext = createContext({} as ContractsContextProps)

export const ContractsModalProvider: FCWithChildren = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const [handleAddressSelect, setHandleAddressSelect] =
    useState<(contractAddress: string) => void>()

  const openModal = useCallback<OpenContractsModal>(
    ({ onContractAddressSelect }) => {
      setIsVisible(true)
      setHandleAddressSelect(() => onContractAddressSelect)
    },
    []
  )

  const closeModal = useCallback(() => {
    setIsVisible(false)
  }, [])

  return (
    <ContractsModalContext.Provider value={{ openContractsModal: openModal }}>
      {children}
      {isVisible && (
        <ContractsModal
          onClose={closeModal}
          onContractAddressSelect={handleAddressSelect!}
        />
      )}
    </ContractsModalContext.Provider>
  )
}
