import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgDanger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.72276 21.1672C4.70976 21.1672 4.69776 21.1672 4.68376 21.1662C4.36876 21.1502 4.05976 21.0822 3.76576 20.9632C2.31876 20.3752 1.62076 18.7222 2.20776 17.2762L9.52876 4.45025C9.78076 3.99425 10.1628 3.61225 10.6288 3.35425C11.9938 2.59825 13.7198 3.09525 14.4748 4.45925L21.7478 17.1872C21.9098 17.5682 21.9788 17.8782 21.9958 18.1942C22.0348 18.9502 21.7768 19.6752 21.2708 20.2362C20.7648 20.7972 20.0698 21.1282 19.3148 21.1662L4.79476 21.1672H4.72276Z"
      fill="white"
      opacity={0.4}
    />
    <path
      clipRule="evenodd"
      d="M11.1245 10.0208C11.1245 9.53875 11.5175 9.14575 11.9995 9.14575C12.4815 9.14575 12.8745 9.53875 12.8745 10.0208V12.8488C12.8745 13.3318 12.4815 13.7238 11.9995 13.7238C11.5175 13.7238 11.1245 13.3318 11.1245 12.8488V10.0208ZM11.1245 16.2699C11.1245 15.7849 11.5175 15.3899 11.9995 15.3899C12.4815 15.3899 12.8745 15.7799 12.8745 16.2589C12.8745 16.7519 12.4815 17.1449 11.9995 17.1449C11.5175 17.1449 11.1245 16.7519 11.1245 16.2699Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)
const Memo = memo(SvgDanger)
export default Memo
