import { FC } from 'react'
import { ActionInputElement } from 'api/omni/model'
import { ConstructorVariableBlock } from 'libs/constructor'
import { ConstructorDatetime } from 'libs/constructor/components'

import * as S from './style'

type DatetimeAtomInputsProps = {
  id: string
  placeholder: string
  canBeVariable?: boolean
  actionElements?: ActionInputElement[]
  atomName: string
}

export const DatetimeAtomInputs: FC<DatetimeAtomInputsProps> = (props) => {
  const { id, placeholder, canBeVariable, actionElements, atomName } = props

  return (
    <S.Container>
      <ConstructorDatetime
        actionElements={actionElements}
        atomId={id}
        name={atomName}
        placeholder={placeholder}
      />
      {canBeVariable && (
        <ConstructorVariableBlock atomFieldName={atomName} atomId={id} />
      )}
    </S.Container>
  )
}
