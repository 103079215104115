import styled from 'styled-components'
import { Button as UIButton } from 'ui/Button/Button'
import { Select as UISelect } from 'ui/Select/Select'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
      display: flex;
      gap: 24px;
      align-items: flex-start;
  
      ${media(size.tablet)} {
        flex-direction: column;
        justify-content:center;
      } 
  `
)

export const SearchWrapper = styled.div(
  ({ theme: { media, size } }) => `
      display: flex;
      gap: 24px;
      flex-direction: column;
      min-width: 267px;
      
      ${media(size.tablet)} {
        width:100%;
        flex-direction: row;
      }
  
      ${media(size.mobile)} {
        flex-direction: column;
      }
  `
)

export const Select = styled(UISelect)`
  min-width: 224px;
` as typeof UISelect

export const Button = styled(UIButton)`
  width: 100%;
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

export const RatingSelect = styled(UISelect)`
  display: flex;
  justify-content: end;
  margin-bottom: 13px;

  .react-select__indicator {
    color: ${({ theme }) => theme.color.gray};
    padding: 0;
  }

  .react-select__control {
    padding: 0 8px;
    font-size: 15px;
    width: max-content;
    height: 28px;
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.color.black};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.color.black};
  }

  .react-select__menu {
    width: fit-content;
    box-shadow: 0px 2px 3px 0px rgba(129, 129, 182, 0.12);
    border-radius: 12px;
    border: ${({ theme }) => `1px solid ${theme.color.line}`};
  }

  .react-select__menu-list {
    padding: 0;
    border-radius: 12px;
  }

  .react-select__option {
    color: ${({ theme }) => theme.color.gray};

    &.react-select__option--is-selected {
      background-color: transparent;
      color: ${({ theme }) => theme.color.black};
    }

    &.react-select__option--is-focused {
      background-color: ${({ theme }) => theme.color.bg};
      color: ${({ theme }) => theme.color.black};
    }

    &:not(:last-child) {
      border-bottom: ${({ theme }) => `1px solid ${theme.color.line}`};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.bg};
      color: ${({ theme }) => theme.color.black};
    }
  }
` as typeof UISelect

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
`

export const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.black};
`

export const SortSelect = styled(UISelect)`
  max-width: 119px;

  .react-select__indicator {
    padding: 0;
  }

  .react-select__control {
    padding: 4px 16px;
    font-size: 12px;
    width: 119px;
    height: 28px;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: end;
  margin: 32px 0;
`

export const TotalItemsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black};
`
