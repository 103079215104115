import styled from 'styled-components'
import { Button } from 'ui/Button/Button'

export const Wrapper = styled.div<{ withButton?: boolean }>`
  display: flex;
  align-items: ${({ withButton }) => (withButton ? 'center' : 'flex-end')};
  justify-content: space-between;
  padding: 24px 12px;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  font-size: 15px;
  line-height: 24px;
  white-space: nowrap;
`

export const DashedLine = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.color.line};
`

export const ChildButton = styled(Button)`
  width: max-content;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.color.line};
`
