import { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useNetwork } from 'wagmi'
import { useAtomAtomContractRetrieve } from 'api/omni/endpoints'
import { GetContractTree } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { useTokenBalance } from 'hooks'
import { getJunaTokenAddress } from 'libs/addresses/addresses'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { toHumanNumber } from 'utils/toHumanNumber'
import {
  LegalGateProcessForm,
  ContractInfo,
  LastActionsBlock,
  ApprovalContent,
} from './components'
import * as S from './style'

export const LegalGate2: FC = () => {
  const { chain } = useNetwork()

  const { contractId } = useParams()

  const {
    data: contract,
    isLoading: isContractLoading,
    isSuccess: isContractLoaded,
  } = useAtomAtomContractRetrieve(contractId as string, {
    query: {
      select: useCallback((data: GetContractTree) => {
        return data.contracts[0]
      }, []),
      enabled: Boolean(contractId),
    },
  })

  const breadcrumbs = useMemo(() => {
    const initialBreadcrumbs = [
      {
        label: 'Home',
        url: AppRoutes.Main,
      },
      {
        label: 'Legal Gate Dashboard',
        url: AppRoutes.LegalGateDashboard,
      },
    ]

    if (contract) {
      return [
        ...initialBreadcrumbs,
        {
          label: contract.name,
          url: `${AppRoutes.LegalGate}/${contract.id}`,
        },
      ]
    }

    return initialBreadcrumbs
  }, [contract])

  const contractBalance = useTokenBalance(
    getJunaTokenAddress(chain?.id),
    contract?.address,
    chain?.id
  )

  if (isContractLoading) {
    return <Loader />
  }

  if (isContractLoaded && contractBalance) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={contract.name}>
        {contract.status === 'on_approval' ? (
          <ApprovalContent
            contractChainId={contract.chainId}
            createdAt={contract.createdAt}
          />
        ) : (
          <S.Wrapper>
            <LegalGateProcessForm
              balance={toHumanNumber(contractBalance)}
              members={contract.members}
            />
            <S.RightSideWrapper>
              <ContractInfo
                address={contract.address}
                balance={toHumanNumber(contractBalance)}
                chain={contract.chainId}
                createdAt={contract.createdAt}
              />
              <LastActionsBlock />
            </S.RightSideWrapper>
          </S.Wrapper>
        )}
      </PageWrapper>
    )
  }

  return null
}
