import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const HighlightLine = styled.div`
  background-color: rgba(91, 166, 255, 0.3);
  height: 3px;
  width: 100%;
`
