import {
  Record,
  String,
  Array,
  Number,
  Union,
  Unknown,
  Dictionary,
  Static,
} from 'runtypes'

export const InputRuntype = Record({
  name: String,
  context: String,
  type: String,
})

export type Input = Static<typeof InputRuntype>

export const ContextDynamicFieldRuntype = Record({
  access: Array(Record({ idx: Number, type: String })),
  name: String,
  context: String,
  inputs: Union(Array(InputRuntype), Dictionary(Unknown, String).optional()),
})
