import { FC } from 'react'
import * as S from './style'

export type PlateOption = {
  label: string
  value: string
  description: string
}

type SelectPlatesBlockProps = {
  className?: string
  options: PlateOption[]
  selectedOption: PlateOption
  onChange: (selectedOption: PlateOption) => void
}

export const SelectPlatesBlock: FC<SelectPlatesBlockProps> = (props) => {
  const { className, options, selectedOption, onChange } = props

  return (
    <S.Wrapper className={className}>
      {options.map((option, idx) => {
        const { label, value, description } = option

        const isSelected = selectedOption.value === value

        return (
          <S.PlateOption
            key={idx}
            isSelected={isSelected}
            onClick={() => onChange(option)}
          >
            <S.SelectPoint isSelected={isSelected} />
            <S.InfoWrapper>
              <S.Title>{label}</S.Title>
              <S.Label>{description}</S.Label>
            </S.InfoWrapper>
          </S.PlateOption>
        )
      })}
    </S.Wrapper>
  )
}
