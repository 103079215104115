import styled from 'styled-components'
import { SvgAtomClose } from 'ui/icons'

export const Wrapper = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  width: 100%;
`

export const VariableBlock = styled.div`
  border-radius: 6px 0 0 6px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-right: 1px solid ${({ theme }) => theme.color.mirageDark};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const ValueBlock = styled.div`
  background-color: ${({ theme }) => theme.color.mirageLight};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ccc;
  flex: 1;
`

export const CloseIcon = styled(SvgAtomClose)`
  cursor: pointer;
  transition: 0.3s;
  path {
    fill: ${({ theme }) => theme.color.black};
  }

  &:hover {
    opacity: 0.7;
  }
`
