import styled from 'styled-components'
import { Chip as UIChip } from 'ui/Chip/Chip'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1090px;
`

export const DateWrapper = styled.div`
  width: 100px;
`

export const VotesWrapper = styled.div`
  width: 70px;
`

export const Label = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
`

export const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ParamSymbolsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Chip = styled(UIChip)`
  font-weight: 400;
  border-radius: 6px;
  width: 60px;
`

export const ResultWrapper = styled(DateWrapper)`
  text-align: center;
`
