import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractLG } from 'api/omni/model'
import { DetailsItem } from './DetailsItem/DetailsItem'
import * as S from './style'

type ContractDetailsProps = {
  title: string
  description?: string
  legalGateDescription: string
  details: { label: string; value: string }[][]
  legalGateDetails: ContractLG
  contractAddress?: string
}

export const ContractDetails = (props: ContractDetailsProps) => {
  const { title, description, details, contractAddress } = props

  const { t } = useTranslation('pageContract')

  const otherDetails = useMemo(
    () =>
      contractAddress && [
        {
          label: t('Contract address'),
          value: contractAddress,
        },
      ],
    [contractAddress, t]
  )

  return (
    <S.StyledCard>
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <S.Line />
        <S.Title>{t('Contract details')}</S.Title>
        {details.map((detail, idx) => (
          <S.DetailsItemWrapper key={`detail #${idx}`}>
            <DetailsItem details={detail} />
            <S.Line />
          </S.DetailsItemWrapper>
        ))}
        {otherDetails && <DetailsItem details={otherDetails} />}
      </S.Wrapper>
    </S.StyledCard>
  )
}
