import { FC, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useIsMutating } from 'react-query'
import { ContactsModalContext } from 'context'
import { FormInput, FormTextarea } from 'features/FormFileds'
import { FormValues } from 'pages/JunaSimple/types'
import { FORM_ID } from 'pages/JunaSimpleV2/constants'
import { MUTATION_CONTRACT_CREATE_KEY } from 'pages/JunaSimpleV3/constants'
import { Button } from 'ui/Button/Button'
import { SvgContacts, SvgPlus } from 'ui/icons'
import * as S from './style'

type JunaSimpleFormProps = {
  legalGates: {
    data: {
      name: string
      context: string
    }[]
    count: number
  }
  activeStep: number
  onClick: () => void
}

export const JunaSimpleForm: FC<JunaSimpleFormProps> = (props) => {
  const { activeStep, onClick } = props

  const { t } = useTranslation('pageJunaSimpleV3')

  const { values, handleSubmit, setFieldValue } = useFormikContext<FormValues>()

  const { openContactModal } = useContext(ContactsModalContext)

  const isContractCreating = Boolean(
    useIsMutating({
      mutationKey: MUTATION_CONTRACT_CREATE_KEY,
    })
  )

  const getFormContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <S.Label>
              {t(
                'The contract name is needed so that you can identify it among other contracts'
              )}
            </S.Label>
            <FormInput name="contractName" placeholder={t('Contract name')} />

            <Button onClick={onClick} variant="red">
              {t('Next step')}
            </Button>
          </>
        )
      case 2:
        return (
          <>
            <FormInput
              editorProps={{
                buttons: [
                  {
                    icon: values.partnerAddress ? <SvgPlus /> : <SvgContacts />,
                    onClick: () =>
                      openContactModal({
                        onAddressSelect: (address) =>
                          setFieldValue('partnerAddress', address),
                        displayMode: values.partnerAddress
                          ? 'addContact'
                          : 'selectContact',
                        contactInputValue: values.partnerAddress,
                      }),
                    hoverText: values.partnerAddress
                      ? t('Add to contacts')
                      : t('Select contact'),
                  },
                ],
              }}
              name="partnerAddress"
              placeholder={t('Select counter part /or/ the other party')}
            />
            <Button onClick={onClick} variant="red">
              {t('Next step')}
            </Button>
          </>
        )

      case 3:
        return (
          <>
            <S.DescriptionWrapper>
              <S.Label>
                {t(
                  'Type the terms of your contract. Use simple and actionable terms that are easy to check'
                )}
              </S.Label>
              <S.Label>
                {t(
                  'For example: Seller agrees to sell and deliver one hundred coffee bags within one month of signing the contract. Buyes agrees to pay for the reasonable quality goods on the day of the delivery to the buyer'
                )}
              </S.Label>
            </S.DescriptionWrapper>
            <FormTextarea
              name="description"
              placeholder={t('Contract conditions')}
            />
            <S.AmountInputWrapper>
              <S.ShortenedFormInput
                editorProps={{
                  type: 'number',
                }}
                name="amountJUNA"
                placeholder={t('Buyer pays on completion')}
              />

              <S.FlexWrapper>
                <S.CountInUsdLabel>{t('In USD')}:</S.CountInUsdLabel>
                <S.CountInUsdLabel isBold>
                  {values.amountJUNA ? Number(values.amountJUNA) * 1 : '0'}$
                </S.CountInUsdLabel>
              </S.FlexWrapper>
            </S.AmountInputWrapper>
            <Button
              form={FORM_ID}
              isLoading={isContractCreating}
              type="submit"
              variant="red"
            >
              {t('Finish and send for approval to a partner')}
            </Button>
          </>
        )
    }
  }

  return (
    <S.Form id={FORM_ID} onSubmit={handleSubmit}>
      {getFormContent()}
    </S.Form>
  )
}
