import { FC } from 'react'
import {
  SvgDepositDownArrow,
  SvgDepositUpArrow,
  SvgDepositTotal,
} from 'ui/icons'
import { formatNumberWithSeparators } from 'utils/formatNumberWithSeparators'
import * as S from './style'

type BalanceInfoBlockProps = {
  depositTotal: number
  creditTotal: number
}

export const BalanceInfoBlock: FC<BalanceInfoBlockProps> = ({
  depositTotal,
  creditTotal,
}) => {
  return (
    <S.Wrapper>
      <S.StatWrapper>
        <SvgDepositDownArrow />
        <S.StatInfoWrapper>
          <S.StatLabel>Deposits</S.StatLabel>
          <S.StatValueLabel>
            {formatNumberWithSeparators(depositTotal)}
          </S.StatValueLabel>
        </S.StatInfoWrapper>
      </S.StatWrapper>
      <S.StatWrapper>
        <SvgDepositUpArrow />
        <S.StatInfoWrapper>
          <S.StatLabel>Loans</S.StatLabel>
          <S.StatValueLabel>
            {formatNumberWithSeparators(creditTotal)}
          </S.StatValueLabel>
        </S.StatInfoWrapper>
      </S.StatWrapper>
      <S.StatWrapper>
        <SvgDepositTotal />
        <S.StatInfoWrapper>
          <S.StatLabel>Total</S.StatLabel>
          <S.StatValueLabel>
            {formatNumberWithSeparators(depositTotal - creditTotal)}
          </S.StatValueLabel>
        </S.StatInfoWrapper>
      </S.StatWrapper>
    </S.Wrapper>
  )
}
