import { useMemo } from 'react'
import { useDispatch, useSelector } from 'hooks'
import { isWalletProfileModalVisible } from 'store/application/application.selectors'
import { toggleProfileModal } from 'store/application/application.slice'

type UseProfileDropdown = () => {
  isProfileModalVisible: boolean
  toggleProfileModal: () => void
}

export const useProfileDropdown: UseProfileDropdown = () => {
  const dispatch = useDispatch()
  const isVisible = useSelector(isWalletProfileModalVisible)

  return useMemo(
    () => ({
      isProfileModalVisible: isVisible,
      toggleProfileModal: () => dispatch(toggleProfileModal()),
    }),
    [dispatch, isVisible]
  )
}
