import { useContext } from 'react'
import ReactSelect, { Props as SelectProps, GroupBase } from 'react-select'
import { ThemeContext } from 'styled-components'
import { colourStyles } from './style'

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group> & { isValid?: boolean }) {
  const theme = useContext(ThemeContext)

  return (
    <ReactSelect
      classNamePrefix="react-select"
      styles={colourStyles(theme, props.isDisabled, props.isValid ?? true)}
      {...props}
    />
  )
}
