import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      height={24}
      id="mask0_5692_28025"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={24}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={24} width={24} />
    </mask>
    <g mask="url(#mask0_5692_28025)">
      <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" fill="white" />
    </g>
  </svg>
)
const Memo = memo(SvgAddIcon)
export default Memo
