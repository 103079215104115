import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
  width: 48%;

  ${media(size.tablet)} {
    width: 100%;
  }

  ${media(size.mobile)} {
    padding: 24px;
  }
`
)

export const Title = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: ${({ theme }) => theme.color.black};
  margin: 0;
  padding: 0;
`

export const ListItem = styled.div`
  display: flex;
  gap: 12px;
`

export const Label = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.color.black};
`

export const ListDot = styled.div`
  min-width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.red};
  margin-top: 5px;
`
