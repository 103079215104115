import styled from 'styled-components'

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  height: max-content;
  min-height: ${({ disabled, value }) => (!disabled && !value ? 28 : 36)}px;
  height: ${({ disabled, value }) => (!disabled && !value ? 28 : 36)}px;
  resize: none;
  background: ${({ theme, disabled, value }) =>
    disabled
      ? 'transparent'
      : !!value
      ? 'transparent'
      : theme.color.lightYellow};

  &::placeholder {
    color: ${({ theme }) => theme.color.mirageDark};
  }
`
