import dayjs from 'dayjs'
import { ContractItemRequest } from 'api/omni/model'
import { generateUUID } from 'libs/constructor/context/utils/generateContractUUID'

type DepositContractArgs = {
  chainId: number
  bankAddress: string
  userAddress: string
  amountJuna: string
  interestedRate: number
  finishedAt: string
}

export const getDepositContract = (
  args: DepositContractArgs
): ContractItemRequest => {
  const {
    chainId,
    bankAddress,
    userAddress,
    amountJuna,
    interestedRate,
    finishedAt,
  } = args

  return {
    id: generateUUID(),
    categoryId: null,
    description: null,
    isPublishedTemplate: false,
    chainId,
    parentId: null,
    name: `Deposit Contract ${dayjs().format(
      'DD/MM/YYYY'
    )} ${interestedRate.toString()}AER`,
    variables: [],
    events: [],
    atoms: [
      {
        id: generateUUID(),
        atomActionId: '7537b0fc-f9ee-4eae-99c6-27652ab0827b',
        inputs: [
          {
            name: 'textfield',
            value: 'Deposit Atom',
            inputs: [],
          },
          {
            name: 'client_address',
            value: userAddress,
            inputs: [],
          },
          {
            name: 'bank_address',
            value: bankAddress,
            inputs: [],
          },
          {
            name: 'amount',
            value: amountJuna,
            inputs: [],
          },
          {
            name: 'finishedAt',
            value: finishedAt,
            inputs: [],
          },
          {
            name: 'interestedRate',
            value: interestedRate.toString(),
            inputs: [],
          },
        ],
      },
    ],
    workspaces: [],
    type: 'contract',
    metaType: 'deposit',
  }
}
