import styled from 'styled-components'
import { SvgArrow } from 'ui/icons'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PageBtn = styled.button<{ active: boolean }>`
  border: 1px solid ${(props) =>
    props.active ? `${props.theme.color.red} ` : `${props.theme.color.line}`}};
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.color.white};
  color: ${(props) =>
    props.active ? `${props.theme.color.red} ` : `${props.theme.color.black}`};
  &:hover {
    border: 1px solid ${({ theme }) => theme.color.gray}
  }
  :disabled {
    cursor: default;
    &:hover {
      border: 1px solid ${({ theme }) => theme.color.line};
    }
  }
`

export const BtnIcon = styled(SvgArrow)<{ disabled: boolean | undefined }>`
  fill: ${(props) =>
    props.disabled ? props.theme.color.line : props.theme.color.gray};
`

export const PrevBtnIcon = styled(BtnIcon)`
  transform: rotateY(180deg) translateX(2px);
`
