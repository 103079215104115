import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  border-radius: 0 0 6px 6px;
  box-shadow: ${({ theme }) => theme.common.boxShadow};
  background-color: ${({ theme }) => theme.color.white};
  padding: 6px;
  position: absolute;
  top: calc(100% - 2px);
  z-index: 50;
`

export const Label = styled.span`
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const Option = styled.div`
  border-radius: 4px;
  padding: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.mirageLight};
  }
`
