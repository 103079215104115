import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)`
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media(size.tablet)} {
    flex-direction: column;
    gap: 12px;
  }

  ${media(size.mobile)} {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`
)

export const AthorBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const AuthorAvatarBlock = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightGray};
  color: ${({ theme }) => theme.color.gray};
`

export const MinVotesBlock = styled.div(
  ({ theme: { media, size } }) => `
  width: max-content;
  border-radius: 12px;
  padding: 6px 12px;
  background: linear-gradient(91deg, #ca48f4 6.95%, #6f74ef 93.18%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${media(size.mobile)} {
    width: 100%;
    justify-content: center;
  }
`
)

export const MinVotesLabel = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.color.white};
`

export const FieldWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${media(size.mobile)} {
    width: 100%;
  }
`
)

export const FieldLabel = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray};
  max-width: 212px;
`

export const RightInputsWrapper = styled(AthorBlock)(
  ({ theme: { media, size } }) => `
  ${media(size.tablet)} {
    flex-direction: column;
    width: 100%;
  }
`
)
