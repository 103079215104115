import styled from 'styled-components'
import { Button as UiButton } from 'ui/Button/Button'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Button = styled(UiButton)`
  padding: 18px 56px;
`

export const Label = styled.span<{ isDescription?: boolean }>`
  font-weight: ${({ isDescription }) => (isDescription ? '500' : '700')};
  font-size: 15px;
  line-height: 24px;
  color: ${({ isDescription, theme }) =>
    isDescription ? theme.color.gray : theme.color.black};
`

export const HiddenFileInput = styled.input`
  display: none;
`
