import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { api } from 'api'
import { ApiContracts } from 'api/types'
import { Loader } from 'ui/Loader/Loader'
import { MilestoneList } from 'ui/MilestoneList/MilestoneList'

type MilestoneWrapperProps = {
  createId: string | null
}

export const MilestoneWrapper = (props: MilestoneWrapperProps) => {
  const { createId } = props

  const { data, isLoading } = useQuery(
    ['contract', createId],
    () => api.contracts.getContractById(Number(createId)),
    {
      select: useCallback((data: ApiContracts.Contract) => {
        const contractContext = JSON.parse(data.code!.context)

        const generatedList = contractContext.path.map((item: string) => {
          const { name, context } = contractContext[item]

          return {
            label: name,
            details: [context],
          }
        })

        return generatedList
      }, []),
    }
  )

  if (isLoading) {
    return <Loader />
  }

  return <MilestoneList list={data} />
}
