import * as S from './styles'

type ParticipantsProps = {
  participants: {
    participantName: string
    address: string
  }[]
}

export const Participants = (props: ParticipantsProps) => {
  const { participants } = props

  return (
    <>
      <S.Title>Voting participants</S.Title>
      <S.Container>
        {participants.map(({ participantName, address }, idx) => (
          <S.ParticipantContainer key={idx}>
            <S.ParticipantName>{participantName}</S.ParticipantName>
            <S.Chip variant="gray">{address}</S.Chip>
          </S.ParticipantContainer>
        ))}
      </S.Container>
    </>
  )
}
