import { FC } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { FormValues } from 'pages/JunaSimple/types'
import * as S from './style'

type ContractDetailsProps = {
  selectedLegalGateName?: string
}

export const ContractDetails: FC<ContractDetailsProps> = (props) => {
  const { selectedLegalGateName } = props

  const { t } = useTranslation('pageJunaSimple')

  const { values, errors } = useFormikContext<FormValues>()

  const {
    contractName,
    ownName,
    partnerName,
    partnerAddress,
    agreementText,
    amountJUNA,
  } = values

  const { address } = useAccount()

  return (
    <S.Wrapper>
      <S.Title>
        {t('Declare contract')}: {!errors.contractName && `${contractName}`}
        {!errors.amountJUNA && amountJUNA && ` - ${amountJUNA} JUNA`}
      </S.Title>
      {!errors.ownName &&
        ownName &&
        !errors.ownAddress &&
        !errors.partnerName &&
        partnerName &&
        !errors.partnerAddress &&
        partnerAddress && (
          <S.Label>
            {t('Make a deal', {
              ownName,
              partnerName,
              partnerAddress,
            })}
          </S.Label>
        )}
      <S.Label>{!errors.agreementText && agreementText}</S.Label>
      {!errors.amountJUNA && amountJUNA && (
        <S.Label>{t('For reward', { amountJUNA })}</S.Label>
      )}
      {selectedLegalGateName && (
        <S.Label>
          <b>{!errors.ownAddress && `(${address})`}</b> {t('and')}{' '}
          <b>
            {!errors.partnerName && partnerName}
            {!errors.partnerAddress && `(${partnerAddress})`}
          </b>{' '}
          {t('to be')} <b>{selectedLegalGateName}</b>{' '}
          {t('between us and i accept the rules of this Judge')}
        </S.Label>
      )}
    </S.Wrapper>
  )
}
