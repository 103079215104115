import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      height={20}
      id="mask0_1425_16101"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={20}
      x={0}
      y={0}
    >
      <rect fill="#D9D9D9" height={20} width={20} />
    </mask>
    <g mask="url(#mask0_1425_16101)">
      <path
        d="M4.16667 18.3341C3.70833 18.3341 3.31583 18.1711 2.98917 17.845C2.66306 17.5183 2.5 17.1258 2.5 16.6675V5.00081H4.16667V16.6675H13.3333V18.3341H4.16667ZM7.5 15.0008C7.04167 15.0008 6.64944 14.8378 6.32333 14.5116C5.99667 14.185 5.83333 13.7925 5.83333 13.3341V3.33415C5.83333 2.87581 5.99667 2.48331 6.32333 2.15665C6.64944 1.83054 7.04167 1.66748 7.5 1.66748H15C15.4583 1.66748 15.8508 1.83054 16.1775 2.15665C16.5036 2.48331 16.6667 2.87581 16.6667 3.33415V13.3341C16.6667 13.7925 16.5036 14.185 16.1775 14.5116C15.8508 14.8378 15.4583 15.0008 15 15.0008H7.5ZM7.5 13.3341H15V3.33415H7.5V13.3341Z"
        fill="#372D4C"
      />
    </g>
  </svg>
)
const Memo = memo(SvgCopy)
export default Memo
