import styled from 'styled-components'
import { SvgJunaTokenIcon } from 'ui/icons'

export const Container = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.red};
  padding: 4px;
  border-radius: 6px;
`

export const JunaIcon = styled(SvgJunaTokenIcon)`
  path {
    fill: ${({ theme }) => theme.color.white};
  }
  width: 12px;
  height: 8px;
`
