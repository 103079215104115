export const transactionsMock = [
  {
    id: 1,
    contractName: 'Juna Simple',
    address: '0xB7DD44C1ff609AD55963B91E1A306fEfEa2cBB2f',
    functionName: 'Completion confirmation',
  },
  {
    id: 2,
    contractName: 'Juna Simple',
    address: '0xB7DD44C1ff609AD55963B91E1A306fEfEa2cBB2f',
    functionName: 'Signing of acceptance certificate and money transfer',
  },
]
