import styled from 'styled-components'
import { SvgAtomClose, SvgDragSmall } from 'ui/icons'

export const DragIcon = styled(SvgDragSmall)`
  fill: ${({ theme }) => theme.color.mirageDark};
`

export const DragElement = styled.span`
  position: absolute;
  left: -16px;
  display: flex;
  cursor: move;
  opacity: 0;
  padding: 12px;
  &:hover ${DragIcon} {
    fill: ${({ theme }) => theme.color.black};
  }
`

export const Wrapper = styled.div<{ isDragging?: boolean }>`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  border-radius: 6px;
  padding: 12px;

  &:hover ${DragElement} {
    animation: ${({ theme }) => theme.animation.fadeIn} 0.5s ease-in-out;
    opacity: 1;
  }
`

export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding-left: 16px;
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.mirageDark};
`

export const RemoveIcon = styled(SvgAtomClose)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.mirageDark};
  &:hover {
    fill: ${({ theme }) => theme.color.black};
  }
`
