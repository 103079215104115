import { forwardRef } from 'react'
import * as S from './style'

export type TextareaProps = {
  className?: string
  disabled?: boolean
  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  error?: boolean
  placeholder?: string
  name?: string
  maxLength?: number
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      onChange,
      className,
      disabled,
      value,
      error,
      placeholder,
      name,
      maxLength,
    } = props

    return (
      <S.Textarea
        ref={ref}
        className={className}
        disabled={disabled}
        error={error}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    )
  }
)
