import styled, { css } from 'styled-components'
import { Button } from 'ui/Button/Button'

export const Input = styled.input<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, theme }) => (error ? theme.color.red : theme.color.gray)};
  font-size: 15px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.color.whisper : theme.color.white};
  width: 100%;
  outline: none;
  border: none;
  padding: 12px 0;

  &::placeholder {
    color: ${({ theme }) => theme.color.gray};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
    -webkit-text-fill-color: ${({ theme }) => theme.color.gray};
  }
`

const DynamicLabel = css`
  label {
    font-size: 15px;
    transform: translate(8px, -16px) scale(0.9);
    color: ${({ theme }) => theme.color.gray};
    padding: 2px 5px;
    border-radius: 16px;
  }
`

export const InputContainer = styled.div<{
  error?: boolean
  disabled?: boolean
  hasValue?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.color.red : theme.color.line)};
  outline: none;
  width: 100%;
  position: relative;
  box-shadow: ${({ theme }) => theme.common.boxShadow};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.color.whisper : theme.color.white};

  ${({ hasValue }) => hasValue && DynamicLabel}

  &:hover, &:focus-within {
    ${DynamicLabel}
  }

  &:hover {
    ${({ disabled, error }) => {
      if (!disabled) {
        return css`
          border: 1px solid
            ${({ theme }) => (error ? theme.color.red : theme.color.gray)};
        `
      }
    }};
  }

  &:focus-within {
    ${({ disabled, error }) => {
      if (!disabled) {
        return css`
          border: 1px solid
            ${({ theme }) => (error ? theme.color.red : theme.color.gray)};
        `
      }
    }};
  }
`

export const InputWrapper = styled.div`
  padding: 0 12px;
  flex: 1;
`

export const InputLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(16px, 11px);
  cursor: text;
  transition: 0.2s ease-in;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.color.white};
`

export const IconButton = styled(Button)`
  width: auto;
  padding: 8px;
`

export const LabelButton = styled.span`
  max-width: 0;
  overflow: hidden;
`

export const FocusIconButton = styled(Button)`
  width: auto;
  padding: 8px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 16px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        background: ${({ theme }) => theme.color.lightGray};
        position: absolute;
        right: 16px;

        &:hover {
          cursor: default;
        }
      `
    } else {
      return css`
        &:hover ${LabelButton} {
          max-width: 300px;
          margin-left: 6px;
        }
      `
    }
  }};
`
