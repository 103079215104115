import { FC, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { AppRoutes, SettingsPageInnerRoutes } from 'constants/app'
import { usePin } from 'hooks'

export const PinApproveLanding: FC = () => {
  const { approveType, code, session } = useParams()

  const { approvePin, approvePinStatus } = usePin()

  useEffect(() => {
    if (!session || !code) {
      throw new Error(
        'Error while confirming pin from email. Session or code params does not exists'
      )
    }
    if (approveType !== 'create' && approveType !== 'reset') {
      throw new Error(
        `Error while confirming pin from email. Unknown approve type ${approveType}`
      )
    }

    approvePin({ approveType, code, session })
  }, [approvePin, approveType, code, session])

  if (approvePinStatus === 'success') {
    return (
      <Navigate to={`${AppRoutes.Settings}/${SettingsPageInnerRoutes.Pin}`} />
    )
  }

  if (approvePinStatus === 'error') {
    return <p>Error while confirming pin from email. Please try again</p>
  }

  return <p>Approving pin</p>
}
