import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FIRST_STEP from './assets/first.png'
import JUNA from './assets/juna.png'
import SECOND_STEP from './assets/secondStep.png'
import SUPER_APP from './assets/superapp.png'
import THIRD_STEP from './assets/thirdStep.png'
import { Products, Steps, ConnectWalletBlock } from './components'
import * as S from './style'

const products = [
  { id: 1, src: JUNA },
  { id: 2, src: SUPER_APP },
]

export const Home = () => {
  const { t } = useTranslation('pageHome')

  const steps = useMemo(
    () => [
      {
        label: t('1 step'),
        description: t(
          'Choose a contract template that best fits your needs and enter block editing mode'
        ),
        icon: FIRST_STEP,
      },
      {
        label: t('2 step'),
        description: t(
          'Fill in all the important information for you - parties involved, terms, deadlines, amount'
        ),
        icon: SECOND_STEP,
      },
      {
        label: t('3 step'),
        description: t(
          'Go ahead and sign the contract. Once all parties meet the conditions, your deal will be successfully completed'
        ),
        icon: THIRD_STEP,
      },
    ],
    [t]
  )

  return (
    <S.Wrapper>
      <Steps steps={steps} />
      <ConnectWalletBlock />
      <Products products={products} />
    </S.Wrapper>
  )
}
