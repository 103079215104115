import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type StepProps = {
  steps: {
    label: string
    description: string
    icon: string
  }[]
}

export const Steps: FC<StepProps> = (props) => {
  const { steps } = props

  const { t } = useTranslation('pageHome')

  return (
    <S.Wrapper>
      <S.Title>{t('Create your first universal contract')}</S.Title>
      <S.StepsWrapper>
        {steps.map(({ label, description, icon }) => (
          <S.Step key={label}>
            <img alt="#" src={icon} />
            <S.Label>{label}</S.Label>
            <S.Description>{description}</S.Description>
          </S.Step>
        ))}
      </S.StepsWrapper>
    </S.Wrapper>
  )
}
