import { FC } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { AdsCategory } from 'api/omni/model'
import { FormInput, FormSelect, FormTextarea } from 'features/FormFileds'
import { FORM_ID } from 'pages/AddAds/constants'
import { FormValues } from 'pages/AddAds/types'
import * as S from './style'

type EditFormProps = {
  categories: AdsCategory[]
}

export const EditForm: FC<EditFormProps> = (props) => {
  const { categories } = props

  const { values, handleSubmit, setFieldValue } = useFormikContext<FormValues>()

  const { t } = useTranslation('pageAdEdit')

  return (
    <S.Wrapper>
      <S.Form id={FORM_ID} onSubmit={handleSubmit}>
        <FormSelect
          editorProps={{
            placeholder: t('Category'),
            options: categories,
            getOptionLabel: (option) => option.name,
            getOptionValue: (option) => option.id.toString(),
            onChange: (option) => setFieldValue('category', option?.id),
          }}
          name="category"
        />
        <FormInput name="name" placeholder={t('Name')} />
        <FormTextarea name="description" placeholder={t('Description')} />
        <S.AmountInputWrapper>
          <S.ShortenedFormInput
            editorProps={{ type: 'number' }}
            name="amountjuna"
            placeholder={t('Contract amount in JUNA tokens')}
          />
          <S.FlexWrapper>
            <S.CountInUsdLabel>{t('In USD')}:</S.CountInUsdLabel>
            <S.CountInUsdLabel isBold>{values.amountjuna}$</S.CountInUsdLabel>
          </S.FlexWrapper>
        </S.AmountInputWrapper>
      </S.Form>
    </S.Wrapper>
  )
}
