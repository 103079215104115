import styled from 'styled-components'
import { LanguageButton as UILanguageButton } from 'ui/LanguageButton/LanguageButton'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.line};
  box-shadow: ${({ theme }) => theme.common.boxShadow};
  width: 250px;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
`

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gallery};
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 16px 24px 0px;
`

export const Subtitle = styled.div`
  font-size: 12px;
  padding: 0px 24px;
`

export const Body = styled.div`
  width: 100%;
  padding: 12px;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.line};
`

export const Footer = styled.div`
  width: 100%;
  padding: 10px 12px;
  border-top: 1px solid ${({ theme }) => theme.color.line};
`

export const Provider = styled.a`
  width: 100%;
  display: block;
  font-size: 14px;
  cursor: pointer;
  padding: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.red};
    border-radius: 12px;
    color: ${({ theme }) => theme.color.white};
    box-shadow: 0px 2px 3px rgba(129, 129, 182, 0.25);
  }
`

export const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Wallet = styled.div`
  font-size: 15px;
  font-weight: 300;
`

export const LanguageButton = styled(UILanguageButton)`
  width: 100%;
  padding: 24px;
`
