import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { SvgCircleClose } from 'ui/icons'

export const LegalGateButton = styled(Button)`
  padding: 10px 10px;
  border: 1px solid ${({ theme }) => theme.color.bg};
  margin-right: 24px;
  position: relative;
  &:hover {
    border: 1px solid ${({ theme }) => theme.color.gray};
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.black};
  }
`

export const CloseIcon = styled(SvgCircleClose)`
  position: absolute;
  top: -6px;
  right: -6px;
  background: ${({ theme }) => theme.color.white};
`
