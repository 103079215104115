import styled from 'styled-components'
import { Card } from 'ui/Card/Card'

export const Wrapper = styled(Card)(
  ({ theme: { media, size } }) => `
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 394px;
  width: 100%;

  ${media(size.tablet)} {
    max-width: 100%;
    padding: 16px;
  }
`
)

export const Title = styled.h5`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
  margin: 0 0 0 12px;
`

export const LoansWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
