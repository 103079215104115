import styled from 'styled-components'

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  font-feature-settings: 'liga' off;
  color: ${({ theme }) => theme.color.black};
`
