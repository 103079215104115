import { Link } from 'react-router-dom'
import * as S from './style'

export type BreadcrumbsProps = {
  breadcrumbs: { label: string; url: string }[]
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { breadcrumbs } = props

  return (
    <S.Wrapper>
      {breadcrumbs.map(({ label, url }, idx) => {
        const isLastBreadcrumb = idx === breadcrumbs.length - 1

        return (
          <S.Label key={url} as={isLastBreadcrumb ? 'span' : Link} to={url}>
            {label}
            {!isLastBreadcrumb && ' / '}
          </S.Label>
        )
      })}
    </S.Wrapper>
  )
}
