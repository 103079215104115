import { FC, useCallback } from 'react'
import { useSignTypedData } from 'wagmi'
import {
  useAtomAtomContractAcceptCreate,
  useAtomAtomContractRejectCreate,
} from 'api/omni/endpoints'
import { Chains } from 'constants/blockchain'
import { getForwarderAddress } from 'libs/addresses/addresses'
import { EIP712Domain } from 'libs/forwarder-relayer/forwarder-relayer'
import { notify } from 'libs/notify/notify'
import { Button } from 'ui/Button/Button'
import * as S from './style'

type LeftSideInfoProps = {
  issuerAddress: string
  chain: number
  interestRate: number
  amount: number
  contractId: string
}

export const LeftSideInfo: FC<LeftSideInfoProps> = (props) => {
  const { issuerAddress, chain, interestRate, amount, contractId } = props

  const { signTypedDataAsync } = useSignTypedData()

  const { mutate: accept } = useAtomAtomContractAcceptCreate({
    mutation: {
      onSuccess: (data) => {
        notify.success({
          title: 'Success!',
          message: 'You have successfully approved the deposit !',
        })
      },
    },
  })

  const { mutate: reject } = useAtomAtomContractRejectCreate({
    mutation: {
      onSuccess: () => {
        notify.success({
          title: 'Success!',
          message: 'You have successfully rejected the deposit !',
        })
      },
    },
  })

  const handleAccept = async () => {
    if (!chain) {
      return
    }

    const signed = await signTypedDataAsync({
      domain: EIP712Domain(getForwarderAddress(chain), chain),
      types: {
        Form: [],
      },
      value: {},
    })

    accept({
      id: contractId,
      data: {
        dataSig: signed,
        dataUnsig: {
          primaryType: 'Form',
          domain: EIP712Domain(getForwarderAddress(chain), chain),
          types: {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            Form: [],
          },
          message: {},
        },
      },
    })
  }

  const handleReject = useCallback(() => {
    reject({
      id: contractId,
      data: {
        comment: '',
      },
    })
  }, [contractId, reject])

  return (
    <S.Wrapper>
      <S.Label>Issuer</S.Label>
      <S.ValueLabel>{issuerAddress}</S.ValueLabel>

      <S.Label>Chain Type</S.Label>
      <S.ValueLabel>{Chains[chain].name}</S.ValueLabel>

      <S.Label>Interest rate</S.Label>
      <S.ValueLabel>{interestRate}% per year</S.ValueLabel>

      <S.Label>Deposit amount Juna</S.Label>
      <S.ValueLabel>{amount} JUNA</S.ValueLabel>

      <S.ButtonsWrapper>
        <Button onClick={handleAccept} variant="red">
          Accept
        </Button>
        <Button onClick={handleReject}>Reject</Button>
      </S.ButtonsWrapper>
    </S.Wrapper>
  )
}
