import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useBanksOwnTransactionList } from 'api/omni/endpoints'
import { PaginatedOwnBankTransactionList } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'

import { BankTable } from './components/BankTable/BankTable'

const breadcrumbs = [
  {
    label: 'Home',
    url: AppRoutes.Main,
  },
  {
    label: `Owner's Dashboard`,
    url: AppRoutes.BankDashboard,
  },
  {
    label: `Deposits`,
    url: AppRoutes.BankDashboardDeposits,
  },
]

export const BankDashboardDeposits: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation('pageBankDashboardDeposits')

  const {
    data: depositTransactions,
    isLoading: isDepositTransactionsLoading,
    isSuccess: isDepositTransactionsLoaded,
  } = useBanksOwnTransactionList(
    {},
    {
      query: {
        select: useCallback((data: PaginatedOwnBankTransactionList) => {
          const depositTransactions = data.results.filter(
            ({ type }) => type === 'deposit'
          )

          return depositTransactions.map(
            ({
              createdAt,
              finishedAt,
              amount,
              atomContract,
              interestedRate,
              id,
            }) => ({
              createdAt,
              finishedAt,
              amount,
              address: atomContract.initiatorAddress,
              interestRate: interestedRate,
              status: atomContract.status,
              id: id!,
              chainId: atomContract.chainId,
            })
          )
        }, []),
      },
    }
  )

  const handleDepositClick = useCallback(
    (id: string) => () => {
      navigate(`${AppRoutes.BankViewCreditContract}/${id}`)
    },
    [navigate]
  )

  if (isDepositTransactionsLoading) {
    return <Loader />
  }

  if (isDepositTransactionsLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Deposits')}>
        <BankTable
          hasStatus
          onClick={handleDepositClick}
          tableData={depositTransactions}
          tableTitle={t('Deposits accepted')}
        />
      </PageWrapper>
    )
  }

  return null
}
