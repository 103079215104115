import { createSlice, createAction } from '@reduxjs/toolkit'
import {
  accountPinCheckCreate,
  accountPinSetFinishCreate,
  accountPinResetFinishCreate,
} from 'api/omni/endpoints'
import { StoreNameSpace } from 'constants/app'
import {
  dropPinChecked,
  getPinChecked,
  storePinChecked,
} from 'libs/pin-storage/pin-storage'
import { createAppAsyncThunk } from 'store/createAppAsyncThunk'
import { RequestStatus } from 'types/app'
import { AppThunk } from 'types/store'

type InitialState = {
  approvePinStatus: RequestStatus
  pinChecking: boolean
  pinChecked: boolean
  pinCheckFailed: boolean
}

const initialState: InitialState = {
  approvePinStatus: 'idle',
  pinChecking: false,
  pinChecked: getPinChecked(),
  pinCheckFailed: false,
}

export const uncheckPinAction = createAction(`${StoreNameSpace.Pin}/uncheckPin`)

export const approvePin = createAppAsyncThunk(
  `${StoreNameSpace.Pin}/approvePin`,
  async (params: {
    approveType: 'create' | 'reset'
    code: string
    session: string
  }) => {
    const { approveType, code, session } = params

    const approveRequest =
      approveType === 'create'
        ? accountPinSetFinishCreate
        : accountPinResetFinishCreate

    try {
      await approveRequest({ code, session })
    } catch (err) {
      // todo: provide error text from API
      throw new Error()
    }
  },
  {
    condition: (_, { getState }) => {
      const { approvePinStatus } = getState()[StoreNameSpace.Pin]
      if (approvePinStatus !== 'idle') {
        return false
      }
    },
  }
)

export const checkPin = createAppAsyncThunk(
  `${StoreNameSpace.Pin}/checkPin`,
  async (pin: string) => {
    try {
      const { isValid } = await accountPinCheckCreate({ pin })
      if (!isValid) {
        return Promise.reject(Error)
      }
      storePinChecked()
    } catch (err) {
      throw err
    }
  }
)

export const uncheckPin = (): AppThunk => (dispatch) => {
  dispatch(uncheckPinAction())
  dropPinChecked()
}

export const pinSlice = createSlice({
  name: StoreNameSpace.Pin,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(approvePin.pending, (state) => {
      state.approvePinStatus = 'loading'
    })
    builder.addCase(approvePin.fulfilled, (state) => {
      state.approvePinStatus = 'success'
    })
    builder.addCase(approvePin.rejected, (state) => {
      state.approvePinStatus = 'error'
    })

    builder.addCase(checkPin.pending, (state) => {
      state.pinChecking = true
      state.pinCheckFailed = false
    })
    builder.addCase(checkPin.fulfilled, (state) => {
      state.pinChecking = false
      state.pinChecked = true
    })
    builder.addCase(checkPin.rejected, (state) => {
      state.pinChecking = false
      state.pinCheckFailed = true
    })

    builder.addCase(uncheckPinAction, (state) => {
      state.pinChecked = false
    })
  },
})
