import styled from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%:

    // justify-content: space-between;

    // ${media(size.tablet)} {
    // justify-content: center;
    // flex-direction: column;
    // gap: 24px;
    // }
`
)

export const LeftSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
    max-width: 655px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    ${media(size.tablet)} {
    max-width: 100%;
    }

    ${media(size.mobile)} {
    gap: 24px;
    }
`
)

export const RightSideWrapper = styled.div(
  ({ theme: { media, size } }) => `
    display: flex;
    flex-direction: column;
    max-width: 461px;
    width: 100%;
    gap: 14px;

    ${media(size.tablet)} {
      max-width: 100%;
      margin-bottom: 32px;
    }
`
)

export const Label = styled.span`
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.black};
`
