import { ChangeEvent, FC, useCallback, useState, useMemo } from 'react'
import { SvgAtomVariableIcon } from 'ui/icons'
import { useConstructorContext } from '../../hooks'
import * as S from './style'

type ConstructorVariableBlockProps = {
  atomId: string
  atomFieldName: string
}

export const ConstructorVariableBlock: FC<ConstructorVariableBlockProps> = (
  props
) => {
  const { atomId, atomFieldName } = props

  const [isVariableInputDisplayed, setIsVariableInputDisplayed] =
    useState<boolean>(false)

  const {
    createVariable,
    changeVariableName,
    removeVariable,
    getValue,
    currentContract,
  } = useConstructorContext()

  const currentVariableRef = `${atomId}@${atomFieldName}`

  const currentVariableValue = useMemo(() => {
    return getValue({
      atomId,
      name: atomFieldName,
    })
  }, [atomFieldName, atomId, getValue])

  const handleCreateVariableClick = () => {
    if (isVariableInputDisplayed) {
      return
    }

    createVariable({
      ref: currentVariableRef,
      name: '',
      value: currentVariableValue as string,
    })

    setIsVariableInputDisplayed(true)
  }

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      changeVariableName({
        variableRef: currentVariableRef,
        value: evt.target.value,
      })
    },
    [changeVariableName, currentVariableRef]
  )

  const handleRemoveVariableClick = () => {
    setIsVariableInputDisplayed(false)

    removeVariable({
      variableRef: currentVariableRef,
    })
  }

  const currentVariableInputValue = useMemo(() => {
    const currentVariable = currentContract.variables.find(
      ({ ref }) => ref === currentVariableRef
    )

    return currentVariable?.name || ''
  }, [currentContract.variables, currentVariableRef])

  return (
    <S.Wrapper
      isButton={!isVariableInputDisplayed}
      onClick={handleCreateVariableClick}
    >
      <SvgAtomVariableIcon />
      {isVariableInputDisplayed ? (
        <>
          <S.VariableInput
            autoFocus
            onChange={handleChange}
            placeholder="Type name of variable"
            value={currentVariableInputValue}
          />
          <S.DeleteIcon onClick={handleRemoveVariableClick} />
        </>
      ) : (
        <S.Label>Create Variable</S.Label>
      )}
    </S.Wrapper>
  )
}
