import { ChainId } from 'constants/blockchain'
import { Address, Addresses } from 'constants/contracts'

export const getAddress = (address: Address, chainId?: number) => {
  if (!chainId) return address[ChainId.BSC_TESTNET] as `0x${string}`

  return (
    address[chainId] ? address[chainId] : address[ChainId.BSC_TESTNET]
  ) as `0x${string}`
}

export const getJunaTokenAddress = (chainId?: number) => {
  return getAddress(Addresses.junaToken, chainId)
}

export const getForwarderAddress = (chainId?: number) => {
  return getAddress(Addresses.forwarder, chainId)
}

export const getRelayerAddress = (chainId?: number) => {
  return getAddress(Addresses.relayer, chainId)
}
