import { ApiOmni } from 'api/types'

const LEGAL_GATE_KEY_NAME = 'legal-gate'

export const getLegalGate = (): ApiOmni.LegalGate => {
  const legalGate = localStorage.getItem(LEGAL_GATE_KEY_NAME)
  return legalGate ? JSON.parse(legalGate) : null
}

export const storeLegalGate = (legalGate: ApiOmni.LegalGate): void => {
  localStorage.setItem(LEGAL_GATE_KEY_NAME, JSON.stringify(legalGate))
}

export const dropLegalGate = (): void => {
  localStorage.removeItem(LEGAL_GATE_KEY_NAME)
}
