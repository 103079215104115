import styled from 'styled-components'
import { Card as UICard } from 'ui/Card/Card'

export const Card = styled(UICard)`
  padding: 16px 24px;
`

export const FlexWrapper = styled.div(
  ({ theme: { media, size } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  ${media(size.mobile)} {
    flex-direction: column;
    text-align: center;
  }
`
)

export const LeftSideWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`

export const Title = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const Description = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 131%;
  color: ${({ theme }) => theme.color.gray};
`

export const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Label = styled.span<{ $isLink?: boolean }>`
  font-weight: ${({ $isLink }) => ($isLink ? '400' : '500')};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme, $isLink }) =>
    $isLink ? theme.color.blue : theme.color.black};
  text-decoration: none;
  width: ${({ $isLink }) => ($isLink ? 'auto' : '81px')};
  cursor: pointer;
`

export const ActionsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 147px;
  justify-content: flex-end;
`
