import { ApiContractsRoutes } from 'constants/api'
import { AXIOS_MARKETPLACE_INSTANCE } from 'libs/axios-instance/axios-instance'
import { ApiContracts, PaginatedResponse } from '../types'

export const getContracts = async (params: {
  category?: number
  jurisdiction?: number
  page: number
  size: number
}) => {
  const { data } = await AXIOS_MARKETPLACE_INSTANCE.get<
    PaginatedResponse<ApiContracts.Contract>
  >(ApiContractsRoutes.ContractList, { params })
  return data
}

export const getContractById = async (contractId: number) => {
  const { data } = await AXIOS_MARKETPLACE_INSTANCE.get<ApiContracts.Contract>(
    `${ApiContractsRoutes.ContractList}${contractId}`
  )
  return data
}

export const getCategories = async () => {
  const { data } = await AXIOS_MARKETPLACE_INSTANCE.get<
    ApiContracts.Category[]
  >(ApiContractsRoutes.CategoryList)
  return data
}
