import styled from 'styled-components'
import { ConstructorInput as ConstructorSimpleInput } from '../ConstructorInput/ConstructorInput'
import { ConstructorSelectList as SelectList } from '../ConstructorSelectList/ConstructorSelectList'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const VariableButton = styled.button<{ disabled?: boolean }>`
  dislpay: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.mirageDark};
  outline: none;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.whisper : theme.color.white};
  border-radius: 6px 0px 0px 6px;
  padding: 0 12px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.color.mirageLight};
  }
`

export const ConstructorInput = styled(ConstructorSimpleInput)`
  border-radius: 0 6px 6px 0;
  border-left: none;
  background-color: ${({ theme, disabled, value }) =>
    disabled
      ? theme.color.whisper
      : !!value
      ? theme.color.white
      : theme.color.lightYellow};

  input {
    background-color: ${({ theme, disabled, value }) =>
      disabled
        ? theme.color.whisper
        : !!value
        ? theme.color.white
        : theme.color.lightYellow};
  }
`

export const ConstructorSelectList = styled(SelectList)`
  position: absolute;
  right: calc(100% + 12px);
  width: 250px;
`
