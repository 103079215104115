import { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  useAtomAtomActionList,
  useAtomAtomContractRetrieve,
  atomConstructCreate,
} from 'api/omni/endpoints'
import { AtomAction, ContractItemRequest } from 'api/omni/model'
import { AppRoutes } from 'constants/app'
import {
  useConstructorContext,
  ConstructorField,
  ConstructorWorkSpacesInput,
} from 'libs/constructor'
import { notify } from 'libs/notify/notify'
import { SvgBackArrrow, SvgDrag, SvgCheckCircle, SvgTemplate } from 'ui/icons'
import { Loader } from 'ui/Loader/Loader'
import { PageWrapper } from 'ui/PageWrapper/PageWrapper'
import { Drag, Drop, Atom, ContractTypeSwitch } from './components'
import * as S from './style'

const HEIGHT_ACTIONS_CONTAINER = 82

export const ContractConstructorV2: FC = () => {
  const { t } = useTranslation('pageContractConstructor')

  const [isSticked, setSticked] = useState<boolean>(false)

  const actionContainerElement = useRef<HTMLDivElement>(null)

  const { contractId } = useParams()

  const navigate = useNavigate()

  const {
    constructorState,
    navigateToContract,
    getContract,
    addAtom,
    currentContract,
    loadTemplateContracts,
    changeContractsType,
    updateAtoms,
  } = useConstructorContext()

  useAtomAtomContractRetrieve(contractId!, {
    query: {
      enabled: Boolean(contractId),
      onSuccess: (data) => {
        data.contracts[0] = {
          ...data.contracts[0],
          templateReferenceId: contractId,
        }

        console.log(data.contracts)

        loadTemplateContracts({
          contracts: data.contracts,
          currentContractId: contractId!,
        })

        changeContractsType('contract')
      },
    },
  })

  const breadcrumbs = useMemo(
    () => [
      {
        label: t('Home'),
        url: AppRoutes.Main,
      },
      {
        label: t('Contract Constructor'),
        url: AppRoutes.Constructor,
      },
      {
        label: t('Value Chain'),
        url: '',
      },
    ],
    [t]
  )

  const {
    data: atoms,
    isLoading: isAtomsLoading,
    isSuccess: isAtomsLoaded,
  } = useAtomAtomActionList({
    query: {
      keepPreviousData: true,
    },
  })

  const { mutate: constructContract } = useMutation(
    ['constructContract'],
    () =>
      atomConstructCreate({
        contracts: constructorState.contracts as ContractItemRequest[],
      }),
    {
      onSuccess: ({ rootContractId }) => {
        notify.success({
          message: 'Contract successfully created',
          title: 'Contract created',
        })
        navigate(`${AppRoutes.AtomContract}/${rootContractId}`)
      },
    }
  )

  const parentContract = useMemo(() => {
    if (!currentContract) {
      return null
    }

    const { parentId } = currentContract

    if (parentId) {
      return getContract(parentId)
    }

    return null
  }, [currentContract, getContract])

  const handleReturnToParentContract = () => {
    const { parentId } = currentContract

    if (parentId) {
      navigateToContract({ contractId: parentId })
    }
  }

  const handleAddAtom = useCallback(
    (atom: AtomAction) => {
      const transformedAtomInputs = atom.inputs.map((input) => {
        return {
          name: input.name as string,
          value: '',
          inputs: [],
        }
      })

      addAtom({
        name: atom.name,
        atomActionId: atom.id,
        inputs: transformedAtomInputs,
        events: atom.events,
      })
    },
    [addAtom]
  )

  const handleMoveAtom = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedAtoms = [...currentContract.atoms]
      const draggedAtom = updatedAtoms[dragIndex]

      updatedAtoms.splice(dragIndex, 1)

      updatedAtoms.splice(hoverIndex, 0, draggedAtom)

      updateAtoms(updatedAtoms)
    },
    [currentContract.atoms, updateAtoms]
  )

  const handleConstructContract = useCallback(() => {
    constructContract()
  }, [constructContract])

  const handleScroll = () => {
    const elem = actionContainerElement.current!
    const { top } = elem.getBoundingClientRect()
    setSticked(top - window.innerHeight + HEIGHT_ACTIONS_CONTAINER >= 0)
  }

  // TODO: Сделать через IntrersectionObserver

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (isAtomsLoading) {
    return <Loader />
  }

  if (isAtomsLoaded) {
    return (
      <PageWrapper breadcrumbs={breadcrumbs} title={t('Value Chain')}>
        <S.Wrapper>
          <DndProvider backend={HTML5Backend}>
            <S.LeftSideWrapper>
              {parentContract && (
                <S.ReturnCard onClick={handleReturnToParentContract}>
                  <SvgBackArrrow />
                  <S.Title>{parentContract.name}</S.Title>
                </S.ReturnCard>
              )}
              <S.Card>
                <Drop setBoardElement={handleAddAtom}>
                  <ConstructorField name="name" placeholder="Contract name" />

                  <ConstructorWorkSpacesInput />

                  {currentContract.atoms.map(
                    ({ id, atomActionId, inputs }, index) => {
                      const currentAtomInfo = atoms.find(
                        (atom) => atom.id === atomActionId
                      )

                      return (
                        <Atom
                          key={id}
                          atomInfo={currentAtomInfo!}
                          id={id}
                          index={index}
                          inputs={inputs}
                          moveAtom={handleMoveAtom}
                        />
                      )
                    }
                  )}
                  {currentContract.atoms.length === 0 && (
                    <S.DragDropBlock>
                      {t('Drag&Drop Atom Action')}
                    </S.DragDropBlock>
                  )}
                </Drop>
                <S.ActionContainer ref={actionContainerElement}>
                  <ContractTypeSwitch />

                  <S.ActionButton onClick={handleConstructContract}>
                    {currentContract.type === 'contract' ? (
                      <>
                        <SvgCheckCircle />
                        Approve and sign contract
                      </>
                    ) : (
                      <>
                        <SvgTemplate />
                        Save as Template
                      </>
                    )}
                  </S.ActionButton>
                </S.ActionContainer>
              </S.Card>
            </S.LeftSideWrapper>
            <S.RightSideWrapper>
              <S.AtomsCard>
                {atoms.map((action) => (
                  <Drag key={action.id} collectItem={action}>
                    <S.ActionCard>
                      <S.DragElement>
                        <SvgDrag />
                      </S.DragElement>
                      <S.Action>
                        <S.ActionTitle>{action.name}</S.ActionTitle>
                        <S.ActionDescription>
                          {action.description}
                        </S.ActionDescription>
                      </S.Action>
                    </S.ActionCard>
                  </Drag>
                ))}
              </S.AtomsCard>
            </S.RightSideWrapper>
          </DndProvider>
        </S.Wrapper>
        <S.StickyActions isSticked={isSticked}>
          <S.WrapperActions>
            <S.ActionButton onClick={handleConstructContract}>
              {currentContract.type === 'contract' ? (
                <>
                  <SvgCheckCircle />
                  Approve and sign contract
                </>
              ) : (
                <>
                  <SvgTemplate />
                  Save as Template
                </>
              )}
            </S.ActionButton>
          </S.WrapperActions>
        </S.StickyActions>
      </PageWrapper>
    )
  }

  return null
}
