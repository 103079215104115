import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type InfoBlockProps = {
  type: string
}

export const InfoBlock: FC<InfoBlockProps> = (props) => {
  const { type } = props

  const { t } = useTranslation('pageVoting')

  return (
    <S.Wrapper>
      <S.Title>{t('Information')}</S.Title>
      <S.InfoOption>
        <S.Label>{t('Type')}</S.Label>
        <S.DashedLine />
        <S.Label isBold>{type}</S.Label>
      </S.InfoOption>
    </S.Wrapper>
  )
}
