import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'
import { Button } from 'ui/Button/Button'
import { Card } from 'ui/Card/Card'

export const Container = styled(Card)`
  padding: 16px;
  height: fit-content;
  ${({ theme: { media, size } }) => media(size.tablet)} {
    flex: none;
    width: 100%;
    max-width: none;
  }
`

export const Header = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
`

export const MoreLink = styled.a`
  color: ${({ theme }) => theme.color.red};
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
`

export const MoreButton = styled(Button)`
  justify-content: center;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  opacity: 0.7;
  transition: opacity 0.5s ease-out;
  padding: 12px 0;
  &:hover {
    opacity: 1;
  }
  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.mirageLight};
  }
`

export const Label = styled.div``

export const CustomTooltip = styled(Tooltip)`
  text-transform: none;
  max-width: 250px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  img {
    margin-bottom: 6px;
  }
`
