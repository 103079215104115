// todo: custom form data instance won't be needed after this issue will be resolved:
// https://github.com/anymaniax/orval/issues/652

export const customFormData = <Body extends Record<string, unknown>>(
  body: Body
): FormData => {
  const formData = new FormData()

  Object.entries(body).forEach(([key, value]) => {
    if (!value) {
      return
    }

    if (typeof value === 'string' || value instanceof Blob) {
      formData.append(key, value)

      return
    }

    formData.append(key, JSON.stringify(value))
  })

  return formData
}
