import styled from 'styled-components'
import { FormInput } from 'features/FormFileds'
import { Button as UIButton } from 'ui/Button/Button'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  min-width: 100%;
`

export const ShortenedFormInput = styled(FormInput)`
  max-width: 387px;
  width: 100%;
`

export const Label = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  font-size: ${({ isBold }) => (isBold ? '16px' : '15px')}
  line-height: 24px;
  color: ${({ theme }) => theme.color.black};
`

export const DescriptionWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const Button = styled(UIButton)`
  white-space: nowrap;
  max-height: 44px;
`
