import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'constants/app'
import * as S from './style'

type FilesProps = {
  files: string[]
}

export const Files: FC<FilesProps> = ({ files }) => {
  const navigate = useNavigate()

  const { t } = useTranslation('pageContract')

  return (
    <S.StyledCard>
      <S.Wrapper>
        <S.Title>{t('Contract files')}</S.Title>
        <S.FilesContainer>
          {files.map((file) => (
            <S.FileBlock>
              <S.FileTitle
                onClick={() =>
                  navigate({
                    pathname: AppRoutes.DocViewer,
                    search: `?url=${file}`,
                  })
                }
              >
                sample4.docx
              </S.FileTitle>
              <S.Label>Qmdv...aTwR</S.Label>
            </S.FileBlock>
          ))}
        </S.FilesContainer>
      </S.Wrapper>
    </S.StyledCard>
  )
}
