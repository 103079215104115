import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgSuccessfully = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3402 1.99976H7.67024C4.28024 1.99976 2.00024 4.37976 2.00024 7.91976V16.0898C2.00024 19.6198 4.28024 21.9998 7.67024 21.9998H16.3402C19.7302 21.9998 22.0002 19.6198 22.0002 16.0898V7.91976C22.0002 4.37976 19.7302 1.99976 16.3402 1.99976Z"
      fill="white"
      opacity={0.4}
    />
    <path
      d="M10.8133 15.248C10.5893 15.248 10.3653 15.163 10.1943 14.992L7.82132 12.619C7.47932 12.277 7.47932 11.723 7.82132 11.382C8.16332 11.04 8.71632 11.039 9.05832 11.381L10.8133 13.136L14.9413 9.00796C15.2833 8.66596 15.8363 8.66596 16.1783 9.00796C16.5203 9.34996 16.5203 9.90396 16.1783 10.246L11.4323 14.992C11.2613 15.163 11.0373 15.248 10.8133 15.248Z"
      fill="white"
    />
  </svg>
)
const Memo = memo(SvgSuccessfully)
export default Memo
